import React, { Component } from 'react';
import { Input, FormGroup, Label } from 'reactstrap'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import moment from 'moment'

class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            open: false,
            width: 300
        }
    }
    openClose = () => {
        this.setState({ open: !this.state.open })
    }
    handleChange = (e, val) => {
        let width = val ? val.name ? (val.name).length > 15 ? ((val.name).length * 12) : 300 : 300 : 300
        this.props.handleDataChange(val)
        // if(this.props.standard){
        //     this.setState({width:width})
        // }
    }
    onInputChange = (e, val) => {

        if (e) {
            let width = e.target.value ? (e.target.value).length > 15 ? ((e.target.value).length * 12) : 300 : 300
            this.props.handleChange(e, val)
            // if(this.props.standard){
            //     this.setState({width:width})
            // }
        }

    }
    render() {
        const { loading, open } = this.state;
        const { options, input, label, type, props, meta, value, disabled, standard } = this.props
        let tempVal = !this.props.standard ? value.split('(') : [value]

        return (
            <FormGroup style={{ display: standard ? 'inline-flex' : '' }}>
                <Label for="name">{label}</Label>
                <Autocomplete

                    id="combo-box-demo"
                    autoHighlight={true}
                    options={options}
                    disabled={disabled}
                    inputValue={tempVal[0]}
                    getOptionLabel={option => {
                        return option ? option.Products ? option.Products.name : !this.props.standard ? (option.name + '(' + option.company_id.name + " \u007C " + option.GST.name + ' )') : option.name : ''
                    }}
                    // style={{ width:300,height:100 }}
                    onChange={(event, val) => this.handleChange(event, val)}
                    onInputChange={(event, val) => this.onInputChange(event, val)}
                    style={{ width: this.props.standard ? `250px` : '100%' }}
                    renderInput={params => {

                        return <TextField {...params} label="" variant={standard ? 'standard' : "outlined"} fullWidth placeholder="Select product" />
                    }
                    }
                />

            </FormGroup>
        );
    }
}

export default AutoComplete;