import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import helper from 'constants/helperFunction';
import { ThemeProvider } from '@mui/material/styles';
import { Label, Input, Col, Row, } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
import { getlowStockSellerwise } from 'actions/admin';
import AppConfig from "constants/config";
import axios from "axios";
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

const Sellerwise = (props) => {
  const perPage = 100
  const [seller, setSeller] = useState('')
  const [searchedText, setSearchedText] = useState('')
  const [page, setPage] = useState(0)
  const [filterData, setFilterData] = useState('')

  const changePage = (page) => {
    let data = {
      page: page + 1,
      perPage,
      searchText: searchedText,
      seller: seller,
    }
    props.getlowStockSellerwise(data)
    setPage(page)
  };

  useEffect(() => {
    let data = {
      page: page + 1,
      perPage,
      searchText: searchedText,
      seller: seller,
    }
    if (props.tab === 1) {
      props.getlowStockSellerwise(data)
      axios({
        method: 'get',
        url: `${AppConfig.baseUrl}inventory/get_filters`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('admin_token')
        }
      }).then(result => {
        if (result.data.error) {
          console.log("error", result.data)
        } else {
          setFilterData(result.data.detail.sellers)
        }
      }).catch(error => {
        console.log("error catch", error)
      });
    }
  }, [])

  const handleSearch = (searchText) => {
    let data = { searchText: searchText, page: 1, perPage, seller }
    props.getlowStockSellerwise(data)
    setPage(0)
    setSearchedText(searchText)
  };

  const resetFilter = (filter) => {
    let data = {
      page: 1,
      perPage,
      searchText: searchedText,
      seller: '',
    }
    props.getlowStockSellerwise(data)
    setSeller('')
    setPage(0)
  }

  const applyFilter = (filter) => {
    let data = {
      page: 1,
      perPage,
      searchText: searchedText ? searchedText : '',
      seller: seller ? seller : '',
    }
    props.getlowStockSellerwise(data)
    setPage(0)
  }

  const handleChanged = (e, key) => {
    setSeller(e.target.value);
  }

  const handleSettelMent = async () => {
    window.open(`${AppConfig.baseUrl}inventory/lowStockSellerwiseCSV?token=${localStorage.getItem('admin_token')}&seller=${seller ? seller : ''}`, '_blank');
  }

  const { seller_arr, loading } = props;
  let data = [];
  seller_arr && seller_arr.data && seller_arr.data.listData && seller_arr.data.listData.map((dataM) => {
    data.push([
      <div style={{ marginLeft: '15px', cursor: 'pointer' }}>  <h4>{(dataM.name)}</h4></div>,
      <div><h4>{(dataM && dataM.company_name ? dataM.company_name : "")}</h4></div>,
      <div><h4>{(dataM && dataM.quantity_sold ? dataM.quantity_sold : 0)}</h4></div>,
      <div><h4>{(dataM && dataM.quantity_available ? dataM.quantity_available : 0)}</h4></div>,
      <div><h4>{(dataM && dataM.min_order_quantity ? dataM.min_order_quantity : 0)}</h4></div>,
      <div><h4>{(dataM && dataM.discount ? dataM.discount : "")}</h4></div>,
    ])
  })

  const columns = [
    {
      name: "Product Name",
      label: <span>&nbsp;&nbsp;&nbsp;&nbsp;Product Name</span>,
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "company name",
      label: "Company Name",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "quantity_sold",
      label: `Quantity Sold`,
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "quantity_available",
      label: "Quantity Available",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "min_order_quantity",
      label: "Min Order Quantity",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "discount",
      label: "Discount",
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          display: () => (
            <React.Fragment>
              <Row form style={{ maxWidth: 300 }}>
                <Col md={12} xl={12} xs={12} lg={12}>
                  <FormControl className="w-100 mb-2 categoryFilter">
                    <Label for="exampleSelect">Sellers</Label>
                    <Input type="select" className="form-control" disableUnderline={true} name="seller" id="seller"
                      value={seller}
                      onChange={handleChanged}
                    >
                      <option selected={seller === ''} value={''}>Select Seller</option>
                      {
                        filterData && filterData.map((val) => {
                          return (<option selected={val._id === seller} value={val.company_name}>{val.company_name}</option>)
                        })
                      }
                    </Input>
                  </FormControl>
                </Col>
              </Row>
            </React.Fragment>
          ),
        },
      }
    },
  ];

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: true,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: true,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: seller_arr && seller_arr.data && seller_arr.data.count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: searchedText,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onDownload: () => { handleSettelMent(); return false },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button type="button" variant="contained" className='filterButton' onClick={() => { applyFilter(); applyNewFilters() }}>Apply</Button>
            </Grid>
            <Grid item md={6}>
              <Button type="button" variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton'>Reset</Button>
            </Grid>
          </Grid>
        </div>
      );
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  return (
    <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
      {loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}
      {
        loading ?
          null :
          <div>
            <div style={{ width: '100%', float: "left" }}>
              <ThemeProvider theme={helper.getMuiTheme()}>
                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={options}
                  style={{ borderRadius: '0px !important' }}
                />
              </ThemeProvider>
            </div>
          </div>
      }
    </div>
  );
}

const mapStateToProps = ({ admin }) => {
  const { seller_arr } = admin;
  return { seller_arr }
};

export default connect(mapStateToProps, { getlowStockSellerwise })(Sellerwise)

