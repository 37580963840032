import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import moment from 'moment';
import customHead from 'constants/customHead'
import { connect } from 'react-redux';
import { getAllOrderList } from 'actions/admin';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import helper from '../../../../constants/helperFunction';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css';
import { useNavigate } from "react-router";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";


function StaleOrder(props) {
  const history = useNavigate()
  let url = window.location.search
  let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''

  const [state, setState] = useState({
    perPage: 100,
    loader: false,
    seller_id: json ? json.seller_idA ? json.seller_idA : '' : '',
    buyer_id: json ? json.buyer_idA ? json.buyer_idA : '' : '',
    page: json ? json.pageA ? Number(json.pageA) : 0 : 0,
    status: 'New',
    from: moment().subtract(6, 'months').format(),
    to: moment().subtract(10, 'days').format(),

  });


  const button = (orderId, status) => {
    return (<React.Fragment>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button variant="outlined" onClick={(e) => handleTrackOrderClick(orderId)} className={'text-success'}>
          Track
        </Button>
      </ButtonGroup>
    </React.Fragment>)
  }
  const handleTrackOrderClick = (data) => {
    history(`/admin/orders/trackOrder/${data.order_id}`);
  }

  function fetchApi() {
    let dat = state.status.split(",")
    const fromDate = moment().subtract(6, 'months').format()
    const toDate = moment().subtract(10, 'days').format()
    let data = {
      page: json ? json.pageA ? Number(json.pageA) + 1 : 1 : 1,
      perPage: 100,
      from_date: moment(fromDate).format('YYYY-MM-DD'),
      to_date: moment(toDate).format('YYYY-MM-DD'),
      status: dat,
      tab: 'Stale',
    }
    if (props.tab == 1) {
      props.getAllOrderList({ data, history: history })
    }
  }
  useEffect(() => {
    if (props.tab == 1) {
      fetchApi()
    }
  }, [window.location.search])

  const statusStyle = (status) => {
    return status.includes("New") ? "text-white bg-danger" : "text-white bg-info";
  }

  const changePage = (page) => {
    setState((prev) => ({ ...prev, page: page }))
    let obj = { from_dateA: state.from, to_dateA: state.to, pageA: page, statusA: state.status, seller_idA: state.seller_id, buyer_idA: state.buyer_id }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)
    setState((prev) => ({ ...prev, loader: true }))
    const t = setTimeout(() => {
      setState((prev) => ({ ...prev, loader: false }))
    }, 3000);
  };

  const { allOrder } = props;

  const columns = [
    {
      name: "buyerName",
      label: " ",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "orderId",
      label: "Order ID",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Latest Date",
      options: {
        filter: true,
        filterType: 'custom',
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
      }
    }
  ];


  let data = [];
  allOrder && allOrder.detail && allOrder.detail.length > 0 && allOrder.detail.map((data1, index) => {
    const ShortExp = data1.products.filter(data => data.expiry_status === 'Short Expiry') 
    let local = moment(data1.createdAt).format(' D MMM, YYYY h:mm a');
    let local1 = moment(data1.order_status[data1.order_status.length - 1].date).format(' D MMM, YYYY h:mm a');
    data.push([
      <div style={helper.divFlex}>
        {ShortExp.length > 0 && <Tooltip title={`Short expiry`} placement="top-start">
          <span style={helper.styleForDiv} className={`${helper.styleDivLine('Short Expiry')}`}>&nbsp;&nbsp;</span>
        </Tooltip>}
        {data1.orderType && <Tooltip title={`${data1.orderType}`} placement="top-start">
          <div style={helper.styleForDiv} className={`${helper.styleDivLine(data1.orderType)}`}>&nbsp;&nbsp;</div>
        </Tooltip>}
        {data1.serviceName ? <Tooltip title={`${data1.serviceName}`} placement="top-start">
          <div style={helper.styleForDiv} className={`${helper.styleDivLine2(data1.serviceName)}`}>&nbsp;&nbsp;</div>
        </Tooltip> :
          <div style={helper.styleForDiv}>&nbsp;&nbsp;</div>
        }
      </div>,
      <div>
        <h4>
          <a style={{ color: 'inherit' }} href={`/admin/orders/orderDetails/${data1.order_id}`} onClick={(e) => { e.preventDefault(); history(`/admin/orders/orderDetails/${data1.order_id}`) }}>
            <span className="font-weight-bolder">{data1.products.length}
              &nbsp;{data1.products.length > 1 ? 'items' : 'item'} by </span>{data1.user_id && data1.user_id.company_name}
          </a>
          <a target='_blank' href={`/admin/buyers/details/${data1.user_id._id}`}><span className='h6'>({data1.user_id && data1.user_id.buyerId})</span></a>
        </h4>
        {
          data1.seller_id &&
            (data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '').length > 25 ?
            <span className="small font-weight-bolder">{(data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '').slice(0, 25) + "..."} | </span>
            :
            <span className="small font-weight-bolder">{(data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '')} | </span>
        }
        < span className="small font-weight-bolder" > {data1.order_id} | {data1.isBulk ? 'Bulk Prepaid' : data1.paymentType == 'Online' ? 'Prepaid' : data1.paymentType} | {moment(data1.createdAt).format(' D MMM, YYYY h:mm a')
        } </span >
        {(moment().diff(moment(data1.createdAt), 'hours')) <= 48 ?
          <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'green' }}></span> :
          (moment().diff(moment(local), 'hours')) >= 48 && (moment().diff(moment(local), 'hours')) <= 72 ?
            <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'orange' }}></span> :
            (moment().diff(moment(local), 'hours')) >= 72 && data1.order_status[data1.order_status.length - 1].status === 'New' ?
              <span className="size-8 rounded-circle d-inline-block ml-1 blink_me" style={{ backgroundColor: 'red' }}></span> : ''
        }
      </div >,
      <div><p className={'ptrPriceFont1 priceColorInventory m-0'}>
        ₹{((data1.total_amount).toFixed(2))}
      </p></div>,
      <div key={'recent'} className={` badge text-uppercase ${statusStyle(data1.requested !== "Requested" ? data1.order_status[data1.order_status.length - 1].status : 'Requested')}`}>{data1.requested === "Requested" ? 'Requested' : data1.order_status[data1.order_status.length - 1].status}</div>,
      (local1),
      button(data1, data1.requested)
    ])
  })

  const options = {
    responsive: 'scroll',
    viewColumns: false,
    search: false,
    filter: false,
    selectableRows: false,
    rowsPerPage: 100,
    page: state.page,
    fixedHeader: false,
    print: false,
    download: false,
    sort: false,
    count: allOrder && allOrder.count,
    serverSide: true,
    server: true,
    selectableRowsOnClick: false,
    selectableRows: 'none',
    fixedHeader: false,
    rangePage: true,
    rowsPerPageOptions: [],
    onTableChange: (action, tableState) => {
      console.log('action=', action, tableState)
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }
  const { loading } = props;
  const theme = createTheme({
    components: {

      MuiTableHead: {
        styleOverrides: {
          root: {
            paddingLeft: '0px'
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontSize: '1rem',
            paddingLeft: '0px !important',
          },
          root: {
            paddingLeft: '0px',
          },
        }
      },
    }
  });
  return (
    <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
      {loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}
      {
        loading ?
          null :
          <ThemeProvider theme={theme}>
            <MUIDataTable
              data={data}
              columns={columns}
              options={options}
              style={{ borderRadius: '0px !important' }}
            />
          </ThemeProvider>
      }
    </div>
  );
}

const mapStateToProps = ({ admin }) => {
  const { allOrder, loading } = admin;
  return { allOrder, loading }
};

export default connect(mapStateToProps, { getAllOrderList })(StaleOrder);

