// import React from 'react';
// import { connect } from 'react-redux';
// // import IconButton from '@material-ui/core/IconButton';
// import { TextField,CircularProgress , IconButton} from '@mui/material';
// import Button from '@mui/material/Button';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
// import { Col, Row, Card } from 'reactstrap';
// import {
//   hideMessage,
//   showAuthLoader,
//   userSignIn,
// } from 'actions/Auth';

// class VerifyToken extends React.Component {
//   constructor() {
//     super();
//     this.state = {}
//   }

//   componentDidUpdate() {
//     if (this.props.showMessage) {
//       setTimeout(() => {
//         this.props.hideMessage();
//       }, 100);
//     }
//     if (this.props.authUser !== null) {
//       this.props.history.push('/');
//     }
//   }

//   render() {
//     const { } = this.state;
//     const { showMessage, loader, alertMessage } = this.props;
//     return (
//       <div className="col-xl-12 col-lg-12 verifyTokenContainer" >
//         <div className="jr-card verifyTokenCard">
//           <div className="animated slideInUpTiny animation-duration-3">
//             {/* <div className="login-header">
//               <a className="app-logo" href='javascript:void(0);' title="Jambo">
//                 <h2>Medimny</h2>
//               </a>
//             </div> */}
//             <div className="mb-4">
//               <h3>Verify Otp</h3>
//             </div>
//             <form method="get" action="">
//               <TextField
//                 type="password"
//                 id="required"
//                 label={'OTP'}
//                 fullWidth={true}
//                 defaultValue=""
//                 margin="normal"
//                 className="mt-0 mb-4"
//               />
//               <div className="mb-2">
//                 <Button color="primary" variant="contained" className="text-white"
//                 >
//                   Verify
//                    </Button>
//               </div>
//             </form>
//             <div>
//               {
//                 loader &&
//                 <div className="loader-view">
//                   <CircularProgress />
//                 </div>
//               }
//               {showMessage && NotificationManager.error(alertMessage)}
//               <NotificationContainer />
//             </div>
//           </div>
//         </div>
//       </div>

//     );
//   }
// }

// const mapStateToProps = ({ auth }) => {
//   const { loader, alertMessage, showMessage, authUser } = auth;
//   return { loader, alertMessage, showMessage, authUser }
// };

// export default connect(mapStateToProps, {
//   userSignIn,
//   hideMessage,
//   showAuthLoader,
// })(VerifyToken);



import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, CircularProgress, Button } from '@mui/material';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { showAuthLoader, userSignIn, hideMessage } from 'actions/Auth';
import { Col, Row, Card } from 'reactstrap';
import { useNavigate } from 'react-router';

const VerifyToken = (props) => {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate()
  useEffect(() => {
    if (props.showMessage) {
      setTimeout(() => {
        props.hideMessage();
      }, 100);
    }
    if (props.authUser !== null) {
      navigate('/')
    }
  }, [props.showMessage, props.authUser, props.history]);

  const handleVerify = () => {
    // Add logic to handle OTP verification
    // You can use 'otp' state to get the entered OTP
  };

  const { showMessage, loader, alertMessage } = props;

  return (
    <div className="col-xl-12 col-lg-12 verifyTokenContainer">
      <div className="jr-card verifyTokenCard">
        <div className="animated slideInUpTiny animation-duration-3">
          <div className="mb-4">
            <h3>Verify Otp</h3>
          </div>
          <form method="get" action="">
            <TextField
            variant='standard'
              type="password"
              id="required"
              label={'OTP'}
              fullWidth={true}
              defaultValue=""
              margin="normal"
              className="mt-0 mb-4"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <div className="mb-2">
              <Button
                color="primary"
                variant="contained"
                className="text-white"
                onClick={handleVerify}
              >
                Verify
              </Button>
            </div>
          </form>
          <div>
            {loader && (
              <div className="loader-view">
                <CircularProgress />
              </div>
            )}
            {showMessage && NotificationManager.error(alertMessage)}
            <NotificationContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
})(VerifyToken);
