import React from "react";
import FullWidthTabForManifest from "../components/routes/tabs/fixed/FullWidthTabForManifest";
import ManifestHeader from './routes/ManifestHeader';
import AdminContainer from "containers/AdminContainer";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          padding: '0 !important',
          fontSize: "1rem !important"
        }
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          paddingLeft: '0 !important'
        }
      }
    }
  }
});

const ManifestTab = (props) => {
  return (
    <AdminContainer>
      <ManifestHeader />
      <ThemeProvider theme={theme}>
        <FullWidthTabForManifest tabFor={'manifest'} />
      </ThemeProvider>
    </AdminContainer>
  );
}

export default ManifestTab;