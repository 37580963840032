import { all, call, fork, put, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import {
  GET_ORDER_LIST,
  GET_ALL_ORDER_LIST,
  GET_ALL_MANIFEST_LIST,
  GET_ORDER_HISTORY,
  GET_INVENTORY,
  GET_INVENTORY_POPUP,
  GET_BUYER_SELLER_LIST,
  GET_BUYER_SELLER_LIST_ADMIN,
  GET_ORDER_DETAILS,
  GET_USER_APPROVE,
  GET_PRODUCT_LIST,
  GET_META_DATA,

  LIST_PERMISSION_MODULES,
  ADD_GROUP,
  LIST_GROUP,
  DELETE_GROUP,
  ADD_STAFF,
  LIST_STAFF,
  DELETE_STAFF,

  GET_PRODUCT_REQUEST_LIST,
  GET_APPROVE_PRODUCT_REQUEST_LIST,
  GET_PROMOTIONS_LIST,
  GET_SCHEDULE_NOTIFICATION_LIST,
  GET_SCHEDULE_NOTIFICATION_LIST_SUCCESS,
  GET_LOW_STOCK_SELLERWISE_LIST,
  GET_LOW_STOCK_OVERALL_LIST,
  GET_LOW_STOCK_SELLERWISE_LIST_SUCCESS,
  GET_ADD_PROMOTIONS_LIST,
  GET_EDIT_PROMOTIONS_LIST,
  GET_DELETE_PROMOTIONS_LIST,

  GET_CHANGE_USER_STATUS,

  // --------ADD/DEL GST-------------

  GET_ADD_GST,
  GET_ADD_GST_SUCCESS,
  GET_DELETE_GST,
  GET_DELETE_GST_SUCCESS,


  // -------------ADD/DEL PRODUCT TYPE---------------

  GET_ADD_PRODUCT_TYPE,
  GET_ADD_PRODUCT_TYPE_SUCCESS,
  GET_DELETE_PRODUCT_TYPE,
  GET_DELETE_PRODUCT_TYPE_SUCCESS,

  // ----------ADD/DEL PRODUCT CATEGORY-----------------

  GET_ADD_PRODUCT_CATEGORY,
  GET_ADD_PRODUCT_CATEGORY_SUCCESS,
  GET_DELETE_PRODUCT_CATEGORY,
  GET_DELETE_PRODUCT_CATEGORY_SUCCESS,

  // -----------------ADD/DEL COMPANY---------------

  GET_ADD_COMPANY,
  GET_ADD_COMPANY_SUCCESS,
  GET_DELETE_COMPANY,
  GET_DELETE_COMPANY_SUCCESS,

  // -------------ADD PRODUCT-----------------

  GET_DELETE_PRODUCT,
  GET_DELETE_PRODUCT_SUCCESS,

  GET_DASHBOARD_CARD,

  ADMIN_DASHBOARD_STATS,
  ADMIN_SALES_STATS,
  //-------ForMonthlyStatsData
  ADMIN_MONTHLY_SALES_STATS,
  // ------------- SELLER SALES STATS-----------------

  GET_SALES_STATISTICS,
  GET_SALES_STATISTICS_SUCCESS,

  GET_TOP_FIVE_LEAST_FIVE,
  GET_TOP_FIVE_LEAST_FIVE_SUCCESS,

  // ------------- SETTLEMENTS-----------------

  GET_SETTLEMENTS,
  GET_SETTLEMENTS_SUCCESS,

  GET_SIDEBAR,

  GET_COD_ON_OFF,

  GET_GLOBAL_COD,

  GET_CHANGE_GLOBAL_COD,

  // -----------------------------

  GET_CHANGE_PROMOTION_SUCCESS,
  GET_CHANGE_PROMOTION,

  GET_CHANGE_DEAL_OF_THE_DAY,

  GET_VERIFY_PHONE,

  GET_ALL_SETTLEMENT,
  GET_ALL_SETTLEMENT_SUCCESS,

  GET_SETTLEMENT_LIST,
  GET_SETTLEMENT_LIST_SUCCESS,
  GET_PENDING_SETTLEMENT,
  GET_PENDING_SETTLEMENT_SUCCESS,

  GET_GROUP_SETTLEMENT,
  GET_GROUP_SETTLEMENT_SUCCESS,

  GET_LIST_GROUP_SETTLEMENT,
  GET_LIST_GROUP_SETTLEMENT_SUCCESS,

  GET_DELIVERY_ON_OFF,
  GET_BNPL_ON_OFF,

  GET_TRANSACTION_LIST,
  GET_TRANSACTION_LIST_SUCCESS,

  GET_BUYER_TRANSACTION_LIST,
  GET_BUYER_TRANSACTION_LIST_SUCCESS,
  GET_PROMOTIONS_LISTING,
  GET_JUMBO_DEAL,
  GET_JUMBO_DEAL_SUCCESS,

  GET_TICKET_LIST,
  GET_TICKET_HISTORY_LIST,
  GET_ADD_TICKET,
  GET_BULK_LIST,
  GET_BULK_ON_OFF,
  GET_FAST_MOVING,

  GET_PREPAIDORDER_LIST,
  GET_MANIFEST_VERIFIED_LIST,
  GET_MANIFEST_DISCREPANCY_LIST


} from "constants/ActionTypes";

import {

  apiFailed,
 getAllManifestListSuccess,
  getOrderList,
  getOrderListSuccess,
  getInventoryList,
  getInventoryListPopUpSuccess,
  getInventoryListSuccess,
  getOrderHistorySuccess,
  getBuyerSellerListSuccess,
  getBuyerSellerListSuccessAdmin,
  getBuyerSellerList,
  getOrderDetails,
  getOrderDetailsSuccess,
  getUserApproveSuccess,
  getProductList,
  getProductListSuccess,
  getMetaDataSuccess,
  getMetaData,

  listPermissionModulesSuccess,
  addGroupSuccess,
  deleteGroupSuccess,
  listGroup,
  listGroupSuccess,
  addStaffSuccess,
  deleteStaffSuccess,
  listStaff,
  listStaffSuccess,

  // getProductRequestList,
  // getRequestedProductList,
  getProductRequestListSuccess,
  getApproveProductRequestListSuccess,
  getProductRequestList,
  getPromotionsList,
  getPromotionsListSuccess,
  getScheduleNotificationsList,
  getScheduleNotificationsListSuccess,
  getlowStockSellerwiseSuccess,
  getlowStockOverAllSuccess,

  getEditPromotionsList,
  getEditPromotionsListSuccess,
  getAddPromotionsListSuccess,
  getDeletePromotionsListSuccess,

  getChangeUserStatusSuccess,

  getAddGstSuccess,
  getDeleteGstSuccess,

  getAddProductTypeSuccess,
  getDeleteProductTypeSuccess,

  getAddProductCategorySuccess,
  getDeleteProductCategorySuccess,
  getAddCompanySuccess,
  getDeleteCompanySuccess,

  getDeleteProductSuccess,

  getDashboardCardSuccess,

  getAdminDashboardStatsSuccess,
  getAdminSalesStatsSuccess,

  getAdminmonthlySalesStatsSuccess,
  getSalesStatisticsSuccess,

  getTopFiveLeastFiveSuccess,

  getSettlements,
  getSettlementsSuccess,

  getSidebarSuccess,

  getCodOnOff,
  getCodOnOffSuccess,

  getGlobalCod,
  getGlobalCodSuccess,

  getChangeGlobalCod,
  getChangeGlobalCodSuccess,

  getChangePromotion,
  getChangePromotionSuccess,

  getChangeDealOfTheDay,
  getChangeDealOfTheDaySuccess,

  getVerifyPhone,
  getVerifyPhoneSuccess,

  getAllSettlement,
  getAllSettlementSuccess,

  getSettlementList,
  getSettlementListSuccess,

  getPendingSettlement,
  getPendingSettlementSuccess,

  getAllOrderList,
  getAllOrderListSuccess,

  getGroupSettlement,
  getGroupSettlementSuccess,

  getListGroupSettlement,
  getListGroupSettlementSuccess,

  getDeliveryOnOff,
  getDeliveryOnOffSuccess,

  getBnplOnOffSuccess,

  getTransactionList,
  getTransactionListSuccess,

  getBuyerTransactionList,
  getBuyerTransactionListSuccess,
  getPromotionsListingSuccess,
  getChangeJumboDealSuccess,
  getChangeFastMovingSuccess,

  getTicketList,
  getTicketListSuccess,
  getTicketHistoryList,
  getTicketHistoryListSuccess,
  getAddTicketSuccess,
  getBulkListSuccess,
  getBulkOnOffSuccess,

  getPrepaidOrder,
  getPrepaidOrderSuccess,
  getDiscrepancyManifestListSuccess,
  getVarifiedManifestListSuccess

} from "actions/admin";

import { showAuthMessage } from "actions/Auth";
import { NotificationManager } from 'react-notifications';
import { Redirect, Route, Switch } from 'react-router-dom';
import Axios from './axiosRequest'


//---------------------------------------------------------------------------------------------------


function* getOrderListFunction({ payload }) {
  try {
    const getOrderListResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/allOrderListingForAdmin', '', payload.data);
    if (getOrderListResponse && getOrderListResponse.data && getOrderListResponse.data.error) {
      yield put(apiFailed(getOrderListResponse.data.title));
    } else if(getOrderListResponse && getOrderListResponse.data) {
      yield put(getOrderListSuccess(getOrderListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getOrderListDispatcher() {
  yield takeLatest(GET_ORDER_LIST, getOrderListFunction);
}


//---------------------------------------------------------------------------------------------------

function* getOrderHistoryFunction({ payload }) {
  try {
    const getOrderHistoryResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/allOrderListingForAdmin', '', payload.data);
    if (getOrderHistoryResponse && getOrderHistoryResponse.data && getOrderHistoryResponse.data.error) {
      yield put(apiFailed(getOrderHistoryResponse.data.title));
    } else if(getOrderHistoryResponse && getOrderHistoryResponse.data) {
      yield put(getOrderHistorySuccess(getOrderHistoryResponse.data));
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getOrderHistoryDispatcher() {
  yield takeLatest(GET_ORDER_HISTORY, getOrderHistoryFunction);
}

//-----------------------------------------------------------------------------------------//

function* getInventoryFunction({ payload }) {
  try {
    const getInventoryRequest = yield call(Axios.axiosHelperFunc, 'post', 'inventory_V2/listingInventory_V2', '', payload.data);
    if (getInventoryRequest && getInventoryRequest.data && getInventoryRequest.data.error) {
      yield put(apiFailed(getInventoryRequest.data.title));
    } else if(getInventoryRequest && getInventoryRequest.data) {
      yield put(getInventoryListSuccess(getInventoryRequest.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getInventoryDispatcher() {
  yield takeEvery(GET_INVENTORY, getInventoryFunction);
}

// -----------------------------------------------------------------------------------------------

function* getInventoryPopUpFunction({ payload }) {
  try {
    const getInventoryPopUpRequest = yield call(Axios.axiosHelperFunc, 'post', 'inventory_V2/inventoryDetailPopup_V2', '', payload);
    if (getInventoryPopUpRequest && getInventoryPopUpRequest.data && getInventoryPopUpRequest.data.error) {
      yield put(apiFailed(getInventoryPopUpRequest.data.title));
    } else if(getInventoryPopUpRequest && getInventoryPopUpRequest.data) {
      yield put(getInventoryListPopUpSuccess(getInventoryPopUpRequest.data.Detail))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getInventoryPopUpDispatcher() {
  yield takeEvery(GET_INVENTORY_POPUP, getInventoryPopUpFunction);
}


//---------------------------------------------------------------------------------------------------


function* getBuyerSellerListFunction({ payload }) {
  try {
    const getBuyerSellerListResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/getUsers', '', payload.data);
    if (getBuyerSellerListResponse && getBuyerSellerListResponse.data && getBuyerSellerListResponse.data.error) {
      yield put(apiFailed(getBuyerSellerListResponse.data.title));
    } else if(getBuyerSellerListResponse && getBuyerSellerListResponse.data) {
      yield put(getBuyerSellerListSuccess(getBuyerSellerListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
function* getBuyerSellerListFunctionAdmin({ payload }) {
  try {
    const getBuyerSellerListResponseAdmin = yield call(Axios.axiosHelperFunc, 'post', 'admin/getUsersAdminList', '', payload.data);
    if (getBuyerSellerListResponseAdmin && getBuyerSellerListResponseAdmin.data && getBuyerSellerListResponseAdmin.data.error) {
      yield put(apiFailed(getBuyerSellerListResponseAdmin.data.title));
    } else if (getBuyerSellerListResponseAdmin && getBuyerSellerListResponseAdmin.data) {
      yield put(getBuyerSellerListSuccessAdmin(getBuyerSellerListResponseAdmin.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getBuyerSellerListDispatcher() {
  yield takeLatest(GET_BUYER_SELLER_LIST, getBuyerSellerListFunction);
}
export function* getBuyerSellerListDispatcherAdmin() {
  yield takeLatest(GET_BUYER_SELLER_LIST_ADMIN, getBuyerSellerListFunctionAdmin);
}
// -----------------------------------------------------------------------------------//


function* getOrderDetailsFunction({ payload }) {
  try {
    const getOrderDetailsResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/getOrderDetails', '', payload.data);
    if (getOrderDetailsResponse && getOrderDetailsResponse.data && getOrderDetailsResponse.data.error) {
      yield put(apiFailed(getOrderDetailsResponse.data.title));
    } else if(getOrderDetailsResponse && getOrderDetailsResponse.data) {
      yield put(getOrderDetailsSuccess(getOrderDetailsResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getOrderDetailsDispatcher() {
  yield takeLatest(GET_ORDER_DETAILS, getOrderDetailsFunction);
}

// -------------------------------------------------------------------------------------

function* getUserApproveFunction({ payload }) {
  try {
    const getUserApproveResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/approve', '', payload);
    if (getUserApproveResponse && getUserApproveResponse.data && getUserApproveResponse.data.error) {
      yield put(apiFailed(getUserApproveResponse.data.title));
    } else if(getUserApproveResponse && getUserApproveResponse.data) {
      yield put(getUserApproveSuccess(getUserApproveResponse.data))
      if (payload.fromDetail) {
        yield put(getBuyerSellerList({ data: { filter: { user_type: payload.user_type, _id: payload.user_id } } }))
      } else
        if (payload.user_type == 'buyer') {
          yield put(getBuyerSellerList({ data: { filter: { user_type: 'buyer', user_status: { '$ne': 'denied' } } } }))
        } else {
          yield put(getBuyerSellerList({ data: { filter: { user_type: 'seller', mainUser: null, user_status: { '$ne': 'denied' } } } }))
        }
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getUserApproveDispatcher() {
  yield takeLatest(GET_USER_APPROVE, getUserApproveFunction);
}

// -------------------------------------------------------------------
function* getProductListFunction({ payload }) {
  try {
    const getProductListResponse = yield call(Axios.axiosHelperFunc, 'post', 'product/productList', '', payload);
    if (getProductListResponse && getProductListResponse.data && getProductListResponse.data.error) {
      yield put(apiFailed(getProductListResponse.data.title));
    } else if(getProductListResponse && getProductListResponse.data) {
      yield put(getProductListSuccess(getProductListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getProductListDispacher() {
  yield takeLatest(GET_PRODUCT_LIST, getProductListFunction);
}

// --------------------------------------------------------------------------------------

function* getMetaDataFunction({ payload }) {
  try {
    const getMetaDataResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/getMetadata', '', payload);
    if (getMetaDataResponse && getMetaDataResponse.data && getMetaDataResponse.data.error) {
      yield put(apiFailed(getMetaDataResponse.data.title));
    } else if(getMetaDataResponse && getMetaDataResponse.data) {
      yield put(getMetaDataSuccess(getMetaDataResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getMetaDataDispacher() {
  yield takeLatest(GET_META_DATA, getMetaDataFunction);
}

// ----------------STAFF/GROUPS------------------

function* listPermissionModules({ payload }) {
  try {
    const listPermissionModuleResponse = yield call(Axios.axiosRequest, 'post', 'seller/getPermissionModule', '', payload);
    if (listPermissionModuleResponse && listPermissionModuleResponse.data && listPermissionModuleResponse.data.error) {
      yield put(apiFailed(listPermissionModuleResponse.data.title));
    } else if(listPermissionModuleResponse && listPermissionModuleResponse.data) {
      yield put(listPermissionModulesSuccess(listPermissionModuleResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getPermissionModuleDispatcher() {
  yield takeEvery(LIST_PERMISSION_MODULES, listPermissionModules);
}

function* addGroupFunction({ payload }) {
  try {
    const addGroupResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/addEditGroupModule', '', payload.data);
    if (addGroupResponse && addGroupResponse.data && addGroupResponse.data.error) {
      yield put(apiFailed(addGroupResponse.data.title));
    } else if(addGroupResponse && addGroupResponse.data) {
      yield put(addGroupSuccess(addGroupResponse.data));
      yield put(listGroup(payload.listGroup))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* addGroupDispatcher() {
  yield takeLatest(ADD_GROUP, addGroupFunction);
}

function* listGroupFunction({ payload }) {
  try {
    const listGroupResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/getGroupModule', '', payload);
    if (listGroupResponse && listGroupResponse.data && listGroupResponse.data.error) {
      yield put(apiFailed(listGroupResponse.data.title));
    } else if(listGroupResponse && listGroupResponse.data) {
      yield put(listGroupSuccess(listGroupResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* listGroupDispatcher() {
  yield takeEvery(LIST_GROUP, listGroupFunction);
}

function* deleteGroupFunction({ payload }) {
  try {
    const deleteGroupResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/deleteGroupModule', '', payload);
    if (deleteGroupResponse && deleteGroupResponse.data && deleteGroupResponse.data.error) {
      yield put(apiFailed(deleteGroupResponse.data.title));
    } else if(deleteGroupResponse && deleteGroupResponse.data) {
      yield put(deleteGroupSuccess(deleteGroupResponse.data))
      yield put(listGroup(payload.listGroup))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* deleteGroupDispatcher() {
  yield takeLatest(DELETE_GROUP, deleteGroupFunction);
}

function* addStaffFunction({ payload }) {
  try {
    const addStaffResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/addEditStaffMember', '', payload.data);
    if (addStaffResponse && addStaffResponse.data && addStaffResponse.data.error) {
      yield put(apiFailed(addStaffResponse.data.title));
    } else if(addStaffResponse && addStaffResponse.data) {
      yield put(addStaffSuccess(addStaffResponse.data));
      yield put(listStaff(payload.listStaff))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* addStaffDispatcher() {
  yield takeLatest(ADD_STAFF, addStaffFunction);
}

function* listStaffFunction({ payload }) {
  try {
    const listStaffResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/getStaffList', '', payload);
    if (listStaffResponse && listStaffResponse.data && listStaffResponse.data.error) {
      yield put(apiFailed(listStaffResponse.data.title));
    } else if(listStaffResponse && listStaffResponse.data) {
      yield put(listStaffSuccess(listStaffResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* listStaffDispatcher() {
  yield takeEvery(LIST_STAFF, listStaffFunction);
}

function* deleteStaffFunction({ payload }) {
  try {
    const deleteStaffResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/deleteStaff', '', payload);
    if (deleteStaffResponse && deleteStaffResponse.data && deleteStaffResponse.data.error) {
      yield put(apiFailed(deleteStaffResponse.data.title));
    } else if(deleteStaffResponse && deleteStaffResponse.data) {
      yield put(deleteStaffSuccess(deleteStaffResponse.data))
      yield put(listStaff(payload.listStaff))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* deleteStaffDispatcher() {
  yield takeLatest(DELETE_STAFF, deleteStaffFunction);
}


//---------------------------------------------------------------------------------------------------


function* getProductRequestListFunction({ payload }) {
  try {
    const getProductRequestListResponse = yield call(Axios.axiosHelperFunc, 'post', 'seller/getRequestedProductList', '', payload);
    if (getProductRequestListResponse && getProductRequestListResponse.data && getProductRequestListResponse.data.error) {
      yield put(apiFailed(getProductRequestListResponse.data.title));
    } else if(getProductRequestListResponse && getProductRequestListResponse.data) {
      yield put(getProductRequestListSuccess(getProductRequestListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getProductRequestDispatcher() {
  yield takeLatest(GET_PRODUCT_REQUEST_LIST, getProductRequestListFunction);
}

// --------------------------------------------------------------------------------------------

function* getApproveProductRequestListFunction({ payload }) {
  try {
    const getApproveProductRequestListResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/processProductRequest', '', payload);
    if (getApproveProductRequestListResponse && getApproveProductRequestListResponse.data && getApproveProductRequestListResponse.data.error) {
      yield put(apiFailed(getApproveProductRequestListResponse.data.title));
    } else if(getApproveProductRequestListResponse && getApproveProductRequestListResponse.data) {
      yield put(getApproveProductRequestListSuccess(getApproveProductRequestListResponse.data.title))
      yield put(getProductRequestList({ page: 1, perPage: 50 }))
      if (getApproveProductRequestListResponse.data.title == 'Product rejected.') {
        NotificationManager.success('Product rejected successfully')
      } else {
        NotificationManager.success('Product accepted successfully')
      }
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getApproveProductRequestDispatcher() {
  yield takeLatest(GET_APPROVE_PRODUCT_REQUEST_LIST, getApproveProductRequestListFunction);
}

//---------------------------------------------------------------------------------------------------



function* getPromotionsListFunction({ payload }) {
  try {
    const getPromotionsListResponse = yield call(Axios.axiosHelperFunc, 'post', 'promotions/listing', '', payload);
    if (getPromotionsListResponse && getPromotionsListResponse.data && getPromotionsListResponse.data.error) {
      yield put(apiFailed(getPromotionsListResponse.data.title));
    } else if(getPromotionsListResponse && getPromotionsListResponse.data) {
      yield put(getPromotionsListSuccess(getPromotionsListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getPromotionsDispatcher() {
  yield takeLatest(GET_PROMOTIONS_LIST, getPromotionsListFunction);
}

// ----------------------------------------------------------schedule----------------------


function* getScheduleListFunction({ payload }) {
  try {
    const getScheduleListResponse = yield call(Axios.axiosHelperFunc, 'post', 'scheduleNotification/schedulelisting', '', payload);
    if (getScheduleListResponse && getScheduleListResponse.data && getScheduleListResponse.data.error) {
      yield put(apiFailed(getScheduleListResponse.data.title));
    } else if(getScheduleListResponse && getScheduleListResponse.data) {
      yield put(getScheduleNotificationsListSuccess(getScheduleListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getScheduleNotificationDispatcher() {
  yield takeLatest(GET_SCHEDULE_NOTIFICATION_LIST, getScheduleListFunction);
}


function* lowStockSellerwiseFunction({ payload }) {
  try {
    const lowStockSellerwiseResponse = yield call(Axios.axiosHelperFunc, 'post', 'inventory/lowStockSellerwise', '', payload);
    if (lowStockSellerwiseResponse && lowStockSellerwiseResponse.data && lowStockSellerwiseResponse.data.error) {
      yield put(apiFailed(lowStockSellerwiseResponse.data.title));
    } else if(lowStockSellerwiseResponse && lowStockSellerwiseResponse.data) {
      yield put(getlowStockSellerwiseSuccess(lowStockSellerwiseResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}


export function* lowStockSellerwiseDispatcher() {
  yield takeLatest(GET_LOW_STOCK_SELLERWISE_LIST, lowStockSellerwiseFunction);
}


// ------------------------------------------------------------------------------

function* lowStockOverAllFunction({ payload }) {
  try {
    const lowStockOverAllResponse = yield call(Axios.axiosHelperFunc, 'post', 'inventory/lowStockOverAll', '', payload);
    if (lowStockOverAllResponse && lowStockOverAllResponse.data && lowStockOverAllResponse.data.error) {
      yield put(apiFailed(lowStockOverAllResponse.data.title));
    } else if(lowStockOverAllResponse && lowStockOverAllResponse.data) {
      yield put(getlowStockOverAllSuccess(lowStockOverAllResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}


export function* lowStockOverAllDispatcher() {
  yield takeLatest(GET_LOW_STOCK_OVERALL_LIST, lowStockOverAllFunction);
}




// ------------------------------------------------------------------------------
function* getEditPromotionsListFunction({ payload }) {
  try {
    const getEditPromotionsListResponse = yield call(Axios.axiosHelperFunc, 'post', 'promotions/edit', '', payload);
    if (getEditPromotionsListResponse && getEditPromotionsListResponse.data && getEditPromotionsListResponse.data.error) {
      yield put(apiFailed(getEditPromotionsListResponse.data.title));
    } else if(getEditPromotionsListResponse && getEditPromotionsListResponse.data) {
      yield put(getEditPromotionsListSuccess(getEditPromotionsListResponse.data))
      NotificationManager.success("Promotion edited successfully")
      yield put(getPromotionsList({ page: 1, perPage: 50, type: 'admin' }))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getEditPromotionsDispatcher() {
  yield takeLatest(GET_EDIT_PROMOTIONS_LIST, getEditPromotionsListFunction);
}

// ---------------------------------------------------------------------------------------

function* getAddPromotionsListFunction({ payload }) {
  try {
    const getAddPromotionsListResponse = yield call(Axios.axiosHelperFunc, 'post', 'promotions/add', '', payload);
    if (getAddPromotionsListResponse && getAddPromotionsListResponse.data && getAddPromotionsListResponse.data.error) {
      yield put(apiFailed(getAddPromotionsListResponse.data.title));
    } else if(getAddPromotionsListResponse && getAddPromotionsListResponse.data) {
      yield put(getAddPromotionsListSuccess(getAddPromotionsListResponse.data.title))
      NotificationManager.success("Promotion added successfully")
      yield put(getPromotionsList({ page: 1, perPage: 50, type: 'admin' }))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getAddPromotionsDispatcher() {
  yield takeLatest(GET_ADD_PROMOTIONS_LIST, getAddPromotionsListFunction);
}

// ---------------------------------------------------------------------------------------

function* getDeletePromotionsListFunction({ payload }) {
  try {
    const getDeletePromotionsListResponse = yield call(Axios.axiosHelperFunc, 'post', 'promotions/delete', '', payload);
    if (getDeletePromotionsListResponse && getDeletePromotionsListResponse.data && getDeletePromotionsListResponse.data.error) {
      yield put(apiFailed(getDeletePromotionsListResponse.data.title));
    } else if(getDeletePromotionsListResponse && getDeletePromotionsListResponse.data) {
      yield put(getDeletePromotionsListSuccess(getDeletePromotionsListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getDeletePromotionsDispatcher() {
  yield takeLatest(GET_DELETE_PROMOTIONS_LIST, getDeletePromotionsListFunction);
}

// ---------------------------------------------------------------------------------------

function* getChangeUserStatusFunction({ payload }) {
  try {
    const getChangeUserStatusResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/changeUserStatus', '', payload);
    if (getChangeUserStatusResponse && getChangeUserStatusResponse.data && getChangeUserStatusResponse.data.error) {
      yield put(apiFailed(getChangeUserStatusResponse.data.title));
    } else if(getChangeUserStatusResponse && getChangeUserStatusResponse.data) {
      yield put(getChangeUserStatusSuccess(getChangeUserStatusResponse.data.title))
      if (payload.user_type) {
        yield put(getBuyerSellerList({ data: { filter: { user_type: payload.user_type, _id: payload.id } } }))
      } else
        if (payload.type == 'buyer') {
          yield put(getBuyerSellerList({ data: { filter: { user_type: 'buyer', user_status: { '$ne': 'denied' } } } }))
        } else {
          yield put(getBuyerSellerList({ data: { filter: { user_type: 'seller', mainUser: null, user_status: { '$ne': 'denied' } } } }))
        }
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getChangeUserStatusDispatcher() {
  yield takeLatest(GET_CHANGE_USER_STATUS, getChangeUserStatusFunction);
}

// --------------------------------------------------------------------------------------------

function* getAddGstFunction({ payload }) {
  try {
    const getAddGstResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/addGST', '', payload);
    if (getAddGstResponse && getAddGstResponse.data && getAddGstResponse.data.error) {
      yield put(apiFailed(getAddGstResponse.data.title));
    } else if(getAddGstResponse && getAddGstResponse.data) {
      yield put(getAddGstSuccess(getAddGstResponse.data.title))
      NotificationManager.success(getAddGstResponse.data.title);
      yield put(getMetaData({}))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getAddGstDispatcher() {
  yield takeLatest(GET_ADD_GST, getAddGstFunction);
}

// --------------------------------------------------------------------------------------------

function* getDeleteGstFunction({ payload }) {
  try {
    const getDeleteGstResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/deleteGST', '', payload);
    if (getDeleteGstResponse && getDeleteGstResponse.data && getDeleteGstResponse.data.error) {
      yield put(apiFailed(getDeleteGstResponse.data.title));
    } else if(getDeleteGstResponse && getDeleteGstResponse.data) {
      yield put(getDeleteGstSuccess(getDeleteGstResponse.data.title))
      NotificationManager.success(getDeleteGstResponse.data.title);
      yield put(getMetaData({}))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getDeleteGstDispatcher() {
  yield takeLatest(GET_DELETE_GST, getDeleteGstFunction);
}

// --------------------------------------------------------------------------------------------

function* getAddProductTypeFunction({ payload }) {
  try {
    const getAddProductTypeResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/addProductType', '', payload);
    if (getAddProductTypeResponse && getAddProductTypeResponse.data && getAddProductTypeResponse.data.error) {
      yield put(apiFailed(getAddProductTypeResponse.data.title));
    } else if(getAddProductTypeResponse && getAddProductTypeResponse.data) {
      yield put(getAddProductTypeSuccess(getAddProductTypeResponse.data.title))
      NotificationManager.success(getAddProductTypeResponse.data.title);
      yield put(getMetaData({}))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getAddProductTypeDispatcher() {
  yield takeLatest(GET_ADD_PRODUCT_TYPE, getAddProductTypeFunction);
}

// --------------------------------------------------------------------------------------------

function* getDeleteProductTypeFunction({ payload }) {
  try {
    const getDeleteProductTypeResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/deleteProductType', '', payload);
    if (getDeleteProductTypeResponse && getDeleteProductTypeResponse.data && getDeleteProductTypeResponse.data.error) {
      yield put(apiFailed(getDeleteProductTypeResponse.data.title));
    } else if(getDeleteProductTypeResponse && getDeleteProductTypeResponse.data) {
      yield put(getDeleteProductTypeSuccess(getDeleteProductTypeResponse.data.title))
      NotificationManager.success(getDeleteProductTypeResponse.data.title);
      yield put(getMetaData({}))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getDeleteProductTypeDispatcher() {
  yield takeLatest(GET_DELETE_PRODUCT_TYPE, getDeleteProductTypeFunction);
}

// --------------------------------------------------------------------------------------------

function* getAddProductCategoryFunction({ payload }) {
  try {
    const getAddProductCategoryResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/addCategory', '', payload);
    if (getAddProductCategoryResponse && getAddProductCategoryResponse.data && getAddProductCategoryResponse.data.error) {
      yield put(apiFailed(getAddProductCategoryResponse.data.title));
    } else if(getAddProductCategoryResponse && getAddProductCategoryResponse.data) {
      yield put(getAddProductCategorySuccess(getAddProductCategoryResponse.data.title))
      NotificationManager.success('Category added successfully');
      yield put(getMetaData({}))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getAddProductCategoryDispatcher() {
  yield takeLatest(GET_ADD_PRODUCT_CATEGORY, getAddProductCategoryFunction);
}

// --------------------------------------------------------------------------------------------

function* getDeleteProductCategoryFunction({ payload }) {
  try {
    const getDeleteProductCategoryResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/deleteCategory', '', payload);
    if (getDeleteProductCategoryResponse && getDeleteProductCategoryResponse.data && getDeleteProductCategoryResponse.data.error) {
      yield put(apiFailed(getDeleteProductCategoryResponse.data.title));
    } else if(getDeleteProductCategoryResponse && getDeleteProductCategoryResponse.data) {
      yield put(getDeleteProductCategorySuccess(getDeleteProductCategoryResponse.data))
      NotificationManager.success(getDeleteProductCategoryResponse.data.title);
      yield put(getMetaData({}))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getDeleteProductCategoryDispatcher() {
  yield takeLatest(GET_DELETE_PRODUCT_CATEGORY, getDeleteProductCategoryFunction);
}

function* getAddCompanyFunction({ payload }) {
  try {
    const getAddCompanyResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/addCompany', '', payload);
    if (getAddCompanyResponse && getAddCompanyResponse.data && getAddCompanyResponse.data.error) {
      yield put(apiFailed(getAddCompanyResponse.data.title));
    } else if(getAddCompanyResponse && getAddCompanyResponse.data) {
      yield put(getAddCompanySuccess(getAddCompanyResponse.data.title))
      NotificationManager.success(getAddCompanyResponse.data.title);
      yield put(getMetaData({}))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getAddCompanyDispatcher() {
  yield takeLatest(GET_ADD_COMPANY, getAddCompanyFunction);
}

// --------------------------------------------------------------------------------------------

function* getDeleteCompanyFunction({ payload }) {
  try {
    const getDeleteCompanyResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/deleteCompany', '', payload);
    if (getDeleteCompanyResponse && getDeleteCompanyResponse.data && getDeleteCompanyResponse.data.error) {
      yield put(apiFailed(getDeleteCompanyResponse.data.title));
    } else if(getDeleteCompanyResponse && getDeleteCompanyResponse.data) {
      yield put(getDeleteCompanySuccess(getDeleteCompanyResponse.data.title))
      NotificationManager.success(getDeleteCompanyResponse.data.title);
      yield put(getMetaData({}))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getDeleteCompanyDispatcher() {
  yield takeLatest(GET_DELETE_COMPANY, getDeleteCompanyFunction);
}

// ---------------------------------------------------------------------------------------

function* getDeleteProductFunction({ payload }) {
  try {
    const getDeleteProductResponse = yield call(Axios.axiosHelperFunc, 'post', 'product/deleteProduct', '', payload);
    if (getDeleteProductResponse && getDeleteProductResponse.data && getDeleteProductResponse.data.error) {
      yield put(apiFailed(getDeleteProductResponse.data.title));
    } else if(getDeleteProductResponse && getDeleteProductResponse.data) {
      yield put(getDeleteProductSuccess(getDeleteProductResponse.data.title))
      NotificationManager.success('Product deleted successfully')
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getDeleteProductDispatcher() {
  yield takeLatest(GET_DELETE_PRODUCT, getDeleteProductFunction);
}

// ----------------------------------------------------------------------------------

function* getDashboardCardFunction({ payload }) {
  try {
    const getDashboardCardRequest = yield call(Axios.axiosHelperFunc, 'get', 'seller/getDashBoardCardDetails', '', payload.data);    
    if (getDashboardCardRequest && getDashboardCardRequest.data && getDashboardCardRequest.data.error) {
      yield put(apiFailed(getDashboardCardRequest.data.title));
    } else if(getDashboardCardRequest && getDashboardCardRequest.data) {
      yield put(getDashboardCardSuccess(getDashboardCardRequest.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getDashboardCardDispatcher() {
  yield takeEvery(GET_DASHBOARD_CARD, getDashboardCardFunction);
}

// --------------------------------------------------------------------------------------------

function* getAdminDashboardStatsFunction({ payload }) {
  try {
    const getAdminDashboardStatsResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/getStats', '', payload);
    if (getAdminDashboardStatsResponse && getAdminDashboardStatsResponse.data && getAdminDashboardStatsResponse.data.error) {
      yield put(apiFailed(getAdminDashboardStatsResponse.data));
    } else if(getAdminDashboardStatsResponse && getAdminDashboardStatsResponse.data) {
      yield put(getAdminDashboardStatsSuccess(getAdminDashboardStatsResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getAdminDashboardStatsDispatcher() {
  yield takeLatest(ADMIN_DASHBOARD_STATS, getAdminDashboardStatsFunction);
}



function* getAdminSalesStatsFunction({ payload }) {
  try {
    const getAdminDashboardStatsResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/dashboardSalesStatsAdmin', '', payload);
    if (getAdminDashboardStatsResponse && getAdminDashboardStatsResponse.data && getAdminDashboardStatsResponse.data.error) {
      yield put(apiFailed(getAdminDashboardStatsResponse.data));
    } else if(getAdminDashboardStatsResponse && getAdminDashboardStatsResponse.data) {
      yield put(getAdminSalesStatsSuccess(getAdminDashboardStatsResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getAdminSalesStatsDispatcher() {
  yield takeLatest(ADMIN_SALES_STATS, getAdminSalesStatsFunction);
}
//--------------------------------------------------------------------------------------------------------------------//


function* getAdminMonthlyStatsFunction({ payload }) {
  try {
    const getAdminDashboardMonthlyStatsResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/getDailyStats', '', payload);
    if (getAdminDashboardMonthlyStatsResponse && getAdminDashboardMonthlyStatsResponse.data && getAdminDashboardMonthlyStatsResponse.data.error) {
      yield put(apiFailed(getAdminDashboardMonthlyStatsResponse.data));
    } else if(getAdminDashboardMonthlyStatsResponse && getAdminDashboardMonthlyStatsResponse.data) {
      yield put(getAdminmonthlySalesStatsSuccess(getAdminDashboardMonthlyStatsResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getAdminMonthlyStatsDispatcher() {
  yield takeLatest(ADMIN_MONTHLY_SALES_STATS, getAdminMonthlyStatsFunction);
}


//-------------------------------------------------------------------------------------------------------------------//
function* getSalesStatisticsFunction({ payload }) {
  try {
    const getSalesStatisticsResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/dashboardSalesStats', '', payload.data);
    if (getSalesStatisticsResponse && getSalesStatisticsResponse.data && getSalesStatisticsResponse.data.error) {
      yield put(apiFailed(getSalesStatisticsResponse.data.title));
    } else if(getSalesStatisticsResponse && getSalesStatisticsResponse.data) {
      yield put(getAdminSalesStatsSuccess(getSalesStatisticsResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getSalesStatistics() {
  yield takeLatest(GET_SALES_STATISTICS, getSalesStatisticsFunction);
}

//--------------------------------------------------------------------------------------------------------------------//

function* getTopFiveLeastFiveFunction({ payload }) {
  try {
    const getTopFiveLeastFiveResponse = yield call(Axios.axiosHelperFunc, 'get', 'product/top5least5', '', payload);
    if (getTopFiveLeastFiveResponse && getTopFiveLeastFiveResponse.data && getTopFiveLeastFiveResponse.data.error) {
      yield put(apiFailed(getTopFiveLeastFiveResponse.data.title));
    } else if(getTopFiveLeastFiveResponse && getTopFiveLeastFiveResponse.data) {
      yield put(getTopFiveLeastFiveSuccess(getTopFiveLeastFiveResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getTopFiveLeastFive() {
  yield takeLatest(GET_TOP_FIVE_LEAST_FIVE, getTopFiveLeastFiveFunction);
}

// --------------------------------------------------------------------------------//

function* getSettlementsFunction({ payload }) {
  try {
    const getSettlementsResponse = yield call(Axios.axiosHelperFunc, 'post', 'settlements/list', '', payload.data);
    if (getSettlementsResponse && getSettlementsResponse.data && getSettlementsResponse.data.error) {
      yield put(apiFailed(getSettlementsResponse.data.title));
    } else if(getSettlementsResponse && getSettlementsResponse.data) {
      yield put(getSettlementsSuccess(getSettlementsResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getSettlementsDispacher() {
  yield takeLatest(GET_SETTLEMENTS, getSettlementsFunction);
}

// ----------------------------------------------------------------------------------

function* getSideFunction({ payload }) {
  const { history,location } = payload
  try {
    const getSidebarResponse = yield call(Axios.axiosRequest, 'post', 'users/getSidbarContent', '', payload);
    if (getSidebarResponse && getSidebarResponse.data && getSidebarResponse.data.error) {
      yield put(apiFailed(getSidebarResponse.data.title));
    } else if(getSidebarResponse && getSidebarResponse.data) {
      if (getSidebarResponse.data.detail && getSidebarResponse.data.detail.length > 0) {
        let path = location.pathname.split('/')
        let index = path[2] ? getSidebarResponse.data.detail.findIndex((val) => val && val.pathname && val.pathname.includes(path[2])) : -1
        if (index === -1 && path[2] !== 'sellerPerformance') {
          history(`${getSidebarResponse.data.detail[0].pathname}`);
        }
      }
      yield put(getSidebarSuccess(getSidebarResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getSideBarDispatcher() {
  yield takeLeading(GET_SIDEBAR, getSideFunction);
}

// -----------------------------------------------------------------------------------

function* getCodOnOffFunction({ payload }) {
  try {
    const getCodOnOffResponse = yield call(Axios.axiosHelperFunc, 'post', 'users/codOnOff', '', payload.data);
    if (getCodOnOffResponse && getCodOnOffResponse.data && getCodOnOffResponse.data.error) {
      yield put(apiFailed(getCodOnOffResponse.data.message));
    } else if(getCodOnOffResponse && getCodOnOffResponse.data) {
      yield put(getCodOnOffSuccess(getCodOnOffResponse.data.message))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getCodOnOffDispatcher() {
  yield takeLatest(GET_COD_ON_OFF, getCodOnOffFunction);
}

// ------------------------------------------------------------------------

function* getGlobalCodFunction({ payload }) {
  try {
    const getGlobalCodResponse = yield call(Axios.axiosHelperFunc, 'post', 'systemConfig/getAllSystemConfigList', '', payload.data);
    if (getGlobalCodResponse && getGlobalCodResponse.data && getGlobalCodResponse.data.error) {
      yield put(apiFailed(getGlobalCodResponse.data.title));
    } else if(getGlobalCodResponse && getGlobalCodResponse.data) {
      yield put(getGlobalCodSuccess(getGlobalCodResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getGlobalCodDispatcher() {
  yield takeLatest(GET_GLOBAL_COD, getGlobalCodFunction);
}

// ------------------------------------------------------------------------

function* getChangeGlobalCodFunction({ payload }) {
  try {
    const getChangeGlobalCodResponse = yield call(Axios.axiosHelperFunc, 'post', 'systemConfig/getchangeGlobalCod', '', payload.data);
    if (getChangeGlobalCodResponse && getChangeGlobalCodResponse.data && getChangeGlobalCodResponse.data.error) {
      yield put(apiFailed(getChangeGlobalCodResponse.data.title));
    } else if(getChangeGlobalCodResponse && getChangeGlobalCodResponse.data) {
      yield put(getChangeGlobalCodSuccess(getChangeGlobalCodResponse.data.title))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getChangeGlobalCodDispatcher() {
  yield takeLatest(GET_CHANGE_GLOBAL_COD, getChangeGlobalCodFunction);
}

// ------------------------------------------------------------------------

function* getChangePromotionFunction({ payload }) {
  try {
    const getChangePromotionResponse = yield call(Axios.axiosHelperFunc, 'post', 'promotions/active', '', payload.data);
    if (getChangePromotionResponse && getChangePromotionResponse.data && getChangePromotionResponse.data.error) {
      yield put(apiFailed(getChangePromotionResponse.data.title));
    } else if(getChangePromotionResponse && getChangePromotionResponse.data) {
      yield put(getChangePromotionSuccess(getChangePromotionResponse.data))
      yield put(getPromotionsList({ page: 1, perPage: 50, type: 'admin' }))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getChangePromotionDispatcher() {
  yield takeLatest(GET_CHANGE_PROMOTION, getChangePromotionFunction);
}

// ------------------------------------------------------------------------

function* getChangeDealOfTheDayFunction({ payload }) {
  try {
    const getChangeDealOfTheDayResponse = yield call(Axios.axiosHelperFunc, 'post', 'inventory/dealOfTheDay', '', payload.data);
    if (getChangeDealOfTheDayResponse && getChangeDealOfTheDayResponse.data && getChangeDealOfTheDayResponse.data.error) {
      yield put(apiFailed(getChangeDealOfTheDayResponse.data.title));
    } else if(getChangeDealOfTheDayResponse && getChangeDealOfTheDayResponse.data) {
      yield put(getChangeDealOfTheDaySuccess(getChangeDealOfTheDayResponse.data.title))
      NotificationManager.success('Deals of the day set successfully')
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getChangeDealOfTheDayDispatcher() {
  yield takeLatest(GET_CHANGE_DEAL_OF_THE_DAY, getChangeDealOfTheDayFunction);
}
// ------------------------------------------------------------------------

function* getVerifyPhoneFunction({ payload }) {
  try {
    const getVerifyPhoneResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/verifyPhoneUser', '', payload.data);
    if (getVerifyPhoneResponse && getVerifyPhoneResponse.data && getVerifyPhoneResponse.data.error) {
      yield put(apiFailed(getVerifyPhoneResponse.data.title));
    } else if(getVerifyPhoneResponse && getVerifyPhoneResponse.data) {
      yield put(getVerifyPhoneSuccess(getVerifyPhoneResponse.data.title))
      NotificationManager.success('Status changed successfully')
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getVerifyPhoneDispatcher() {
  yield takeLatest(GET_VERIFY_PHONE, getVerifyPhoneFunction);
}
// ------------------------------------------------------------------------

function* getAllSettlementFunction({ payload }) {
  try {
    const getAllSettlementResponse = yield call(Axios.axiosHelperFunc, 'post', 'settlements/getOverallDetails', '', payload.data);
    if (getAllSettlementResponse && getAllSettlementResponse.data && getAllSettlementResponse.data.error) {
      yield put(apiFailed(getAllSettlementResponse.data.title));
    } else if(getAllSettlementResponse && getAllSettlementResponse.data) {
      yield put(getAllSettlementSuccess(getAllSettlementResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getAllSettlementDispacher() {
  yield takeLatest(GET_ALL_SETTLEMENT, getAllSettlementFunction);
}
// ------------------------------------------------------------------------

function* getSettlementListFunction({ payload }) {
  try {
    const getSettlementListResponse = yield call(Axios.axiosHelperFunc, 'post', 'settlements/settlementList', '', payload.data);
    if (getSettlementListResponse && getSettlementListResponse.data && getSettlementListResponse.data.error) {
      yield put(apiFailed(getSettlementListResponse.data.title));
    } else if(getSettlementListResponse && getSettlementListResponse.data) {
      yield put(getSettlementListSuccess(getSettlementListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getSettlementListDispacher() {
  yield takeLatest(GET_SETTLEMENT_LIST, getSettlementListFunction);
}
// ------------------------------------------------------------------------

function* getPendingSettlementFunction({ payload }) {
  try {
    const getPendingSettlementResponse = yield call(Axios.axiosHelperFunc, 'post', 'settlements/getPendingSettlement', '', payload.data);
    if (getPendingSettlementResponse && getPendingSettlementResponse.data && getPendingSettlementResponse.data.error) {
      yield put(apiFailed(getPendingSettlementResponse.data.title));
    } else if(getPendingSettlementResponse && getPendingSettlementResponse.data) {
      yield put(getPendingSettlementSuccess(getPendingSettlementResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getPendingSettlementDispacher() {
  yield takeLatest(GET_PENDING_SETTLEMENT, getPendingSettlementFunction);
}

// ----------------------------------------------------------------------------------------

function* getAllOrderListFunction({ payload }) {
  try {
    const getAllOrderListResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/allOrderListingForAdmin', '', payload.data);
    if (getAllOrderListResponse && getAllOrderListResponse.data && getAllOrderListResponse.data.error) {
      yield put(apiFailed(getAllOrderListResponse.data.title));
    } else if(getAllOrderListResponse && getAllOrderListResponse.data) {
      yield put(getAllOrderListSuccess(getAllOrderListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getAllOrderListDispatcher() {
  yield takeLatest(GET_ALL_ORDER_LIST, getAllOrderListFunction);
}
// ------------------------------------------------Menifest----------------------------------------
function* manifestListFunction({ payload }) {
  try {
    const manifestListResponse = yield call(Axios.axiosHelperFunc, 'post', 'manifest/newManifestList', '', payload.data);
    if (manifestListResponse && manifestListResponse.data && manifestListResponse.data.error) {
      yield put(apiFailed(manifestListResponse.data.title));
    } else if(manifestListResponse && manifestListResponse.data) {
      yield put(getAllManifestListSuccess(manifestListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* manifestListDispatcher() {
  yield takeLatest(GET_ALL_MANIFEST_LIST, manifestListFunction);
}


function* manifestVerifiedListFunction({ payload }) {
  try {
    const manifestVListResponse = yield call(Axios.axiosHelperFunc, 'post', 'manifest/newManifestList', '', payload.data);
    if (manifestVListResponse && manifestVListResponse.data && manifestVListResponse.data.error) {
      yield put(apiFailed(manifestVListResponse.data.title));
    } else if(manifestVListResponse && manifestVListResponse.data) {
      yield put(getVarifiedManifestListSuccess(manifestVListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* manifestVerifiedListDispatcher() {
  yield takeLatest(GET_MANIFEST_VERIFIED_LIST, manifestVerifiedListFunction);
}


function* manifestdiscrepancyListFunction({ payload }) {
  try {
    const manifestDListResponse = yield call(Axios.axiosHelperFunc, 'post', 'manifest/newManifestList', '', payload.data);
    if (manifestDListResponse && manifestDListResponse.data && manifestDListResponse.data.error) {
      yield put(apiFailed(manifestDListResponse.data.title));
    } else if(manifestDListResponse && manifestDListResponse.data) {
      yield put(getDiscrepancyManifestListSuccess(manifestDListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* manifestDiscrepancyListDispatcher() {
  yield takeLatest(GET_MANIFEST_DISCREPANCY_LIST, manifestdiscrepancyListFunction);
}


// ----------------------------------------------------------------------------------------

function* getGroupSettlementFunction({ payload }) {
  try {
    const getGroupSettlementResponse = yield call(Axios.axiosHelperFunc, 'post', 'settlements/groupSettle', '', payload.data);
    if (getGroupSettlementResponse && getGroupSettlementResponse.data && getGroupSettlementResponse.data.error) {
      yield put(apiFailed(getGroupSettlementResponse.data.title));
    } else if(getGroupSettlementResponse && getGroupSettlementResponse.data) {
      yield put(getGroupSettlementSuccess(getGroupSettlementResponse.data))
      NotificationManager.success('Settlement settled successfully.')
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getGroupSettlementDispatcher() {
  yield takeLatest(GET_GROUP_SETTLEMENT, getGroupSettlementFunction);
}

// ----------------------------------------------------------------------------------------

function* getListGroupSettlementFunction({ payload }) {
  try {
    const getListGroupSettlementResponse = yield call(Axios.axiosHelperFunc, 'post', 'groupSettlements/listGroupSettlement', '', payload.data);
    if (getListGroupSettlementResponse && getListGroupSettlementResponse.data && getListGroupSettlementResponse.data.error) {
      yield put(apiFailed(getListGroupSettlementResponse.data.title));
    } else if(getListGroupSettlementResponse && getListGroupSettlementResponse.data) {
      yield put(getListGroupSettlementSuccess(getListGroupSettlementResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getListGroupSettlementDispatcher() {
  yield takeLatest(GET_LIST_GROUP_SETTLEMENT, getListGroupSettlementFunction);
}

// =========================================================================-----------------

function* getDeliveryOnOffFunction({ payload }) {
  try {
    const getDeliveryOnOffResponse = yield call(Axios.axiosHelperFunc, 'post', 'users/deliveryChargeOnOff', '', payload.data);
    if (getDeliveryOnOffResponse && getDeliveryOnOffResponse.data && getDeliveryOnOffResponse.data.error) {
      yield put(apiFailed(getDeliveryOnOffResponse.data));
    } else if(getDeliveryOnOffResponse && getDeliveryOnOffResponse.data) {
      yield put(getDeliveryOnOffSuccess(getDeliveryOnOffResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getDeliveryOnOffDispatcher() {
  yield takeLatest(GET_DELIVERY_ON_OFF, getDeliveryOnOffFunction);
}
// =========================================================================-----------------

function* getTransactionListFunction({ payload }) {
  try {
    const getTransactionListResponse = yield call(Axios.axiosHelperFunc, 'post', 'transactions/listingTransactions', '', payload.data);
    if (getTransactionListResponse && getTransactionListResponse.data && getTransactionListResponse.data.error) {
      yield put(apiFailed(getTransactionListResponse.data.title));
    } else if(getTransactionListResponse && getTransactionListResponse.data) {
      yield put(getTransactionListSuccess(getTransactionListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getTransactionListDispatcher() {
  yield takeLatest(GET_TRANSACTION_LIST, getTransactionListFunction);
}
// =========================================================================-----------------

function* getBuyerTransactionListFunction({ payload }) {
  try {
    const getBuyerTransactionListResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/getBuyers', '', payload.data);
    if (getBuyerTransactionListResponse && getBuyerTransactionListResponse.data && getBuyerTransactionListResponse.data.error) {
      yield put(apiFailed(getBuyerTransactionListResponse.data.title));
    } else if(getBuyerTransactionListResponse && getBuyerTransactionListResponse.data) {
      yield put(getBuyerTransactionListSuccess(getBuyerTransactionListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getBuyerTransactionListDispatcher() {
  yield takeLatest(GET_BUYER_TRANSACTION_LIST, getBuyerTransactionListFunction);
}

function* getPromotionsListingFunction({ payload }) {
  try {
      const getPromotionsListingResponse = yield call(Axios.axiosHelperFunc, 'post', 'promotions/listing', '', payload);
      if (getPromotionsListingResponse && getPromotionsListingResponse.data && getPromotionsListingResponse.data.error) {
          yield put(apiFailed(getPromotionsListingResponse.data.title));
      } 
      else if(getPromotionsListingResponse && getPromotionsListingResponse.data) {
          yield put(getPromotionsListingSuccess(getPromotionsListingResponse.data))
      }
  } 
  catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}
export function* getPromotionsListing() {
  yield takeLatest(GET_PROMOTIONS_LISTING, getPromotionsListingFunction);
}

function* getJumboDealFunction({ payload }) {
  try {
    const getJumboDealResponse = yield call(Axios.axiosHelperFunc, 'post', 'inventory/jumboDeal', '', payload.data);
    if (getJumboDealResponse && getJumboDealResponse.data && getJumboDealResponse.data.error) {
      yield put(apiFailed(getJumboDealResponse.data.title));
    } else if(getJumboDealResponse && getJumboDealResponse.data) {
      yield put(getChangeJumboDealSuccess(getJumboDealResponse.data.title))
      NotificationManager.success('Jumbo deal set successfully')
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getJumboDealDispatcher() {
  yield takeLatest(GET_JUMBO_DEAL, getJumboDealFunction);
}

// ==============================================================================================================================


function* getFastMovingFunction({ payload }) {
  try {
    const getFastMovingResponse = yield call(Axios.axiosHelperFunc, 'post', 'inventory/fastMoving', '', payload.data);
    if (getFastMovingResponse && getFastMovingResponse.data && getFastMovingResponse.data.error) {
      yield put(apiFailed(getFastMovingResponse.data.title));
    } else if(getFastMovingResponse && getFastMovingResponse.data) {
      yield put(getChangeFastMovingSuccess(getFastMovingResponse.data.title))
      NotificationManager.success('Fast Moving set successfully')
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getFastMovingDispatcher() {
  yield takeLatest(GET_FAST_MOVING, getFastMovingFunction);
}
// ===============================================================================================================================

function* getBnplFunction({ payload }) {
  try {
    const getBnplResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/changeUserBnpl', '', payload.data);
    if (getBnplResponse && getBnplResponse.data && getBnplResponse.data.error) {
      yield put(apiFailed(getBnplResponse.data.title));
    } else if(getBnplResponse && getBnplResponse.data) {
      yield put(getBnplOnOffSuccess(getBnplResponse.data))
      NotificationManager.success(getBnplResponse.data.title)
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getBnplDispatcher() {
  yield takeLatest(GET_BNPL_ON_OFF, getBnplFunction);
}

//--------------------------------------------------------------------------------------------------------------------//


function* getTicketListFunction({ payload }) {
  try {
      const getTicketListResponse = yield call(Axios.axiosHelperFunc, 'post', 'ticket/ticketListingForAdmin', '', payload.data);
      if (getTicketListResponse && getTicketListResponse.data && getTicketListResponse.data.error) {
          yield put(apiFailed(getTicketListResponse.data.title));
      } else if(getTicketListResponse && getTicketListResponse.data) {
          yield put(getTicketListSuccess(getTicketListResponse.data))
      }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getTicketListDispatcher() {
  yield takeLatest(GET_TICKET_LIST, getTicketListFunction);
}


//--------------------------------------------------------------------------------------------------------------------//


// function* getTicketHistoryListFunction({ payload }) {
//   const { history } = payload
//   try {
//       const getTicketHistoryListResponse = yield call(Axios.axiosBuyerHelperFunc, 'post', 'order/orderHistoryBuyers', '', payload.data);


//       if (getTicketHistoryListResponse.data.error) {
//           yield put(apiFailed(getTicketHistoryListResponse.data.title));
//           yield put(logoutUser(getTicketHistoryListResponse.data,history))
//       } else {
//           yield put(getTicketHistoryListSuccess(getTicketHistoryListResponse.data))
//       }
//   } catch (error) {
//      yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
//       // yield put(apiFailed(error));
//   }
// }

// export function* getTicketHistoryListDispatcher() {
//   yield takeLatest(GET_TICKET_HISTORY_LIST, getTicketHistoryListFunction);
// }
//--------------------------------------------------------------------------------------------------------------------//


function* getAddTicketFunction({ payload }) {
  try {
      const getAddTicketResponse = yield call(Axios.axiosHelperFunc, 'post', 'ticket/addTicket', '', payload.data);
      if (getAddTicketResponse && getAddTicketResponse.data && getAddTicketResponse.data.error) {
          yield put(apiFailed(getAddTicketResponse.data.title));
      } else if(getAddTicketResponse && getAddTicketResponse.data) {
          yield put(getAddTicketSuccess(getAddTicketResponse.data))
          NotificationManager.success(getAddTicketResponse.data.title)
          let data = {}
          yield put(getTicketList(data))
      }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getAddTicketDispatcher() {
  yield takeLatest(GET_ADD_TICKET, getAddTicketFunction);
}

//--------------------------------------------------------------------------------------------------------------------//


function* getBulkListFunction({ payload }) {
  try {
      const getBulkListResponse = yield call(Axios.axiosHelperFunc, 'post', 'bulkRequest/buyerBulkListingForAdmin', '', payload.data);
      if (getBulkListResponse && getBulkListResponse.data && getBulkListResponse.data.error) {
          yield put(apiFailed(getBulkListResponse.data.title));
      } else if(getBulkListResponse && getBulkListResponse.data) {
          yield put(getBulkListSuccess(getBulkListResponse.data))
      }
    } catch (error) {
      yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
    }
  }

export function* getBulkListDispatcher() {
  yield takeLatest(GET_BULK_LIST, getBulkListFunction);
}

//--------------------------------------------------------------------------------------------------------------------//


function* getBulkOnOffFunction({ payload }) {
  try {
      const getBulkListResponse = yield call(Axios.axiosHelperFunc, 'post', 'admin/updateBuyerBulkFlag', '', payload.data);
      if (getBulkListResponse && getBulkListResponse.data && getBulkListResponse.data.error) {
          yield put(apiFailed(getBulkListResponse.data.title));
      } else if(getBulkListResponse && getBulkListResponse.data) {
          yield put(getBulkOnOffSuccess(getBulkListResponse.data))
          NotificationManager.success(getBulkListResponse.data.title)
      }
    } catch (error) {
      yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
    }
  }

export function* getBulkOnOffDispatcher() {
  yield takeLatest(GET_BULK_ON_OFF, getBulkOnOffFunction);
}

function* getPrepaidOrderListFunction({ payload }) {
  try {
    const getPrepaidOrderListResponse = yield call(Axios.axiosHelperFunc, 'post', 'order/OrderListingForSettle', '', payload.data);
    if (getPrepaidOrderListResponse && getPrepaidOrderListResponse.data && getPrepaidOrderListResponse.data.error) {
      yield put(apiFailed(getPrepaidOrderListResponse.data.title));
    } else if(getPrepaidOrderListResponse && getPrepaidOrderListResponse.data) {
      yield put(getPrepaidOrderSuccess(getPrepaidOrderListResponse.data))
    }
  } catch (error) {
    yield put(apiFailed(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

export function* getPrepaidOrderListDispatcher() {
  yield takeLatest(GET_PREPAIDORDER_LIST, getPrepaidOrderListFunction);
}

export default function* rootSaga() {
  yield all([
    fork(getOrderListDispatcher),
    fork(getInventoryDispatcher),
    fork(getInventoryPopUpDispatcher),
    fork(getOrderHistoryDispatcher),
    fork(getBuyerSellerListDispatcher),
    fork(getBuyerSellerListDispatcherAdmin),
    fork(getOrderDetailsDispatcher),
    fork(getUserApproveDispatcher),
    fork(getProductListDispacher),
    fork(getMetaDataDispacher),

    fork(getPermissionModuleDispatcher),
    fork(addGroupDispatcher),
    fork(listGroupDispatcher),
    fork(deleteGroupDispatcher),
    fork(addStaffDispatcher),
    fork(listStaffDispatcher),
    fork(deleteStaffDispatcher),

    fork(getProductRequestDispatcher),
    fork(getPromotionsDispatcher),
    fork(getScheduleNotificationDispatcher),
    fork(lowStockOverAllDispatcher),
    fork(lowStockSellerwiseDispatcher),
    fork(getEditPromotionsDispatcher),
    fork(getAddPromotionsDispatcher),
    fork(getDeletePromotionsDispatcher),
    fork(getChangeUserStatusDispatcher),
    fork(getApproveProductRequestDispatcher),
    fork(getAddGstDispatcher),
    fork(getDeleteGstDispatcher),
    fork(getAddProductTypeDispatcher),
    fork(getDeleteProductTypeDispatcher),
    fork(getAddProductCategoryDispatcher),
    fork(getDeleteProductCategoryDispatcher),
    fork(getAddCompanyDispatcher),
    fork(getDeleteCompanyDispatcher),
    fork(getDeleteProductDispatcher),
    fork(getDashboardCardDispatcher),
    fork(getAdminDashboardStatsDispatcher),
    fork(getAdminSalesStatsDispatcher),
    fork(getAdminMonthlyStatsDispatcher),
    fork(getSalesStatistics),
    fork(getTopFiveLeastFive),
    fork(getSettlementsDispacher),
    fork(getSideBarDispatcher),
    fork(getCodOnOffDispatcher),
    fork(getGlobalCodDispatcher),
    fork(getChangeGlobalCodDispatcher),
    fork(getChangePromotionDispatcher),
    fork(getChangeDealOfTheDayDispatcher),
    fork(getVerifyPhoneDispatcher),
    fork(getAllSettlementDispacher),
    fork(getSettlementListDispacher),
    fork(getPendingSettlementDispacher),
    fork(getAllOrderListDispatcher),
    fork(manifestListDispatcher),
    fork(getGroupSettlementDispatcher),
    fork(getListGroupSettlementDispatcher),
    fork(getDeliveryOnOffDispatcher),
    fork(getTransactionListDispatcher),
    fork(getBuyerTransactionListDispatcher),
    fork(getPromotionsListing),
    fork(getJumboDealDispatcher),
    fork(getFastMovingDispatcher),
    fork(getBnplDispatcher),
    fork(getTicketListDispatcher),
    fork(getAddTicketDispatcher),
    fork(getBulkListDispatcher),
    fork(getBulkOnOffDispatcher),
    fork(getPrepaidOrderListDispatcher),
    fork(manifestVerifiedListDispatcher),
    fork(manifestDiscrepancyListDispatcher)
  ]);
}