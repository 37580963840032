import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormGroup, Label, Input } from 'reactstrap';
import { ChevronRight } from '@mui/icons-material';
import AppConfig from 'constants/config'
import { connect } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AxiosRequest from 'sagas/axiosRequest';
import './index.css'
import { NotificationManager } from 'react-notifications';
import axios from 'axios'
import { getChangeUserStatus, getUserApprove } from 'actions/admin'
import ReactstrapTextField from '../components/ReactStrapTextField';
import ReactStrapSelectField from '../components/reactstrapSelectField';
import { Field, Form } from 'react-final-form'
import { required, number, required0, numberZero } from 'constants/validations'
import Radio from '@mui/material/Radio';
import moment from 'moment';
import { getBuyerSellerList } from 'actions/admin';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import Switch from '@mui/material/Switch';
function BuyerDetailsHeader(props) {
  const { userDetails } = props;
  // console.log(userDetails, "userdddddddddddddd")
  const [state, setState] = useState({
    openConfig: false,
    codCourier_id: '',
    prepaidCourier_id: '',
    comm: 0,
    shippingData: '',
    selectedValue: '',
    userData: '',
    open: false,
    name: '',
    vacation: '',
    datePopup: false,
    selectedDate: dayjs(),
    bulkReq: '',
    info: '',
    title: '',
    deny: false,
    status: '',
    staffListed: [],
    relationalManager1: '',
    relationalManager2: '',
    shiprocketState: false, 
    delhiveryState: false

  })

  const componentdidMount = async () => {
    await axios({
      method: 'get',
      url: `${AppConfig.baseUrl}shipping/listShippingPartner`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.message)
      } else {
        setState((prev) => ({ ...prev, shippingData: result.data.data }))
      }
    }).catch(error => {
      console.log("error catch", error)
    })
    setState((prev) => ({ ...prev, userData: props.userDetails }))
    staffListData()
  }

  useEffect(() => {
    componentdidMount()
  }, [])

  const reset = async () => {
    await axios({
      method: 'post',
      url: `${AppConfig.baseUrl}users/resetPassAdmin`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      },
      data: { user_id: props.userDetails && props.userDetails._id }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.message)
      } else {
        NotificationManager.success(result.data.message)
      }
    }).catch(error => {
      console.log("error catch", error)
    })
  }
  const handleDenyApprove = (key, data) => {
    setState((prev) => ({
      ...prev,
      title: data,
      id: key
    }))
    props.getUserApprove({ fromDetail: true, user_id: key, user_type: 'seller' });
  }
  const handleDeny = (data) => {
    setState((prev) => ({
      ...prev,
      deny: true,
      info: data,
      title: "deny",

    }))
  }
  const handleChange1 = (e, manager) => {
    if (e.target.value != '') {
      if (manager == 'rMId1') {
        setState((prev) => ({
          ...prev, relationalManager1: e.target.value
        }))
      } else {
        setState((prev) => ({
          ...prev, relationalManager2: e.target.value
        }))
      }

      handleSubmitUSerData1(e.target.value, manager)
    }
  }
  const handleSubmitUSerData1 = async (val, rMId) => {
    let seller_id = props.userDetails._id
    let data = { [rMId]: val, Id: seller_id }

    await axios.post(`${AppConfig.baseUrl}users/addRelationshipManager`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      },
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      } else {
        NotificationManager.success(result.data.title);
        let data = {
          filter: { user_type: 'seller', _id: seller_id }
        }
        props.getBuyerSellerList({ data })
      }

    })
  }

  const staffListData = async () => {
    let data = {}
    await axios.post(`${AppConfig.baseUrl}seller/getStaffListForRm`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      },
    }).then(result => {
      setState((prev) => ({
        ...prev, staffListed: result.data.detail
      }))
    })
  }
  const handleDenied = () => {
    if (state.title == 'deny') {
      props.getChangeUserStatus({ id: props.userDetails._id, status: 'denied', user_type: 'seller', _id: props.userDetails._id })
    } else {
      props.getUserApprove({ fromDetail: true, user_id: props.userDetails._id, user_type: 'seller', _id: props.userDetails._id });
    }
    setState((prev) => ({
      ...prev,
      deny: false,
    }))
  }
  const handleRequestClose = () => {
    setState((prev) => ({
      ...prev,
      delete: false,
      openConfig: false,
      deny: false,
      open: false,
      name: '',
      datePopup: false
    }))
  }
  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val == key)
    return index > -1 ? true : false
  }
  const openCon = () => {
    let { userDetails } = props;
    setState((prev) => ({
      ...prev,
      openConfig: true,
      comm: userDetails.additionalComm ? userDetails.additionalComm : 0,
      codCourier_id: userDetails.preferredCourierCod ? userDetails.preferredCourierCod.courier_id : '',
      prepaidCourier_id: userDetails.preferredCourierPrepaid ? userDetails.preferredCourierPrepaid.courier_id : '',
      selectedValue: userDetails.productNotification && userDetails.productNotification === true ? 'yes' : 'no',
      vacation: userDetails.vaccation && userDetails.vaccation.vaccationMode === true ? 'yes' : 'no',
      bulkReq: userDetails.bulkRequest && userDetails.bulkRequest === true ? 'yes' : 'no',
      selectedDate: userDetails && userDetails.vaccation && userDetails.vaccation.tillDate ? dayjs(userDetails.vaccation.tillDate) : dayjs(),
      shiprocketState: userDetails.shiprocketService && userDetails.shiprocketService === true ? true : false,
      delhiveryState: userDetails.delhiveryService && userDetails.delhiveryService === true ? true : false,
    }))

  }
  const handleChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
  }

  const handleRadioChange = event => {
    setState((prev) => ({ ...prev, selectedValue: event.target.value }));
  };
  const handleRadioChange1 = event => {
    setState((prev) => ({ ...prev, vacation: event.target.value, datePopup: true }));
  };
  const handleRadioChange2 = event => {
    setState((prev) => ({ ...prev, bulkReq: event.target.value }));
  };
  const handleChangeShiprocket = (e) => {
    // console.log(e.target.checked, "userddddddddddddddship")
    setState((prev) => ({ ...prev, shiprocketState: e.target.checked }));
  };
  const handleChangeDelhivery = (e) => {
    // console.log(e.target.checked, "userdddddddddddddddelivery")
    setState((prev) => ({ ...prev, delhiveryState: e.target.checked }));
  };
  const handleDateChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e }));
  }
  function componentWillReceiveProps(nextProps) {
    const { history, userDetails } = nextProps;
    if (nextProps.userDetails !== props.userDetails) {

    }
  }

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      relationalManager1: userDetails ? userDetails.manager_1 && userDetails.manager_1._id : '',
      relationalManager2: userDetails ? userDetails.manager_2 && userDetails.manager_2._id : ''
    }))
  }, [userDetails])

  const handleRequestSave = () => {
    axios({
      method: 'post',
      url: `${AppConfig.baseUrl}seller/enableDisableVaccation`,
      headers: {
        token: localStorage.getItem('admin_token')
      },
      data: {
        vacationMode: state.vacation === 'yes' ? true : false,
        tillDate: dayjs(state.selectedDate).format(),
        sellerId: props.userDetails._id
      }
    }).then((result) => {
      if (result.data.error === false) {
        NotificationManager.success(result.data.title)
      } else {
        NotificationManager.error(result.data.title)
      }
      let data = { filter: { user_type: 'seller', _id: props.userDetails._id } }
      props.getBuyerSellerList({ data })
      handleRequestClose();
    })
  }
  const onSave = async (values) => {
    await axios({
      method: 'post',
      url: `${AppConfig.baseUrl}users/sellerConfig`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      },
      data: {
        userId: props.userDetails && props.userDetails._id,
        codCourier_id: state.codCourier_id && state.codCourier_id != 'Select courier' ? state.codCourier_id : '',
        prepaidCourier_id: state.prepaidCourier_id && state.prepaidCourier_id != 'Select courier' ? state.prepaidCourier_id : '',
        additionalComm: Number(values.comm),
        productNotification: state.selectedValue == 'yes' ? true : state.selectedValue == 'no' ? false : true,
        bulkRequest: state.bulkReq == 'yes' ? true : state.bulkReq == 'no' ? false : true,
        shiprocketService: state.shiprocketState,
        delhiveryService: state.delhiveryState
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        setState((prev) => ({ ...prev, openConfig: false, userData: result.data.data }));
        NotificationManager.success(result.data.title)
        console.log("error asdasdcatch", props.match)
        let data = { filter: { user_type: 'seller', _id: props.userDetails._id } }
        props.getBuyerSellerList({ data })
      }
    }).catch(error => {
      console.log("error catch", error)
    })
  }
  const editCompname = (data) => {
    setState((prev) => ({ ...prev, open: true, name: data && data.company_name ? data.company_name : '' }))
  }
  const onUpdateName = async (values) => {
    let data = { user_id: props.userDetails._id, name: values.name, filter: { user_type: 'seller', _id: props.userDetails._id } }
    let result = await AxiosRequest.axiosHelperFunc('post', 'admin/updateCompanyName', '', data)
    if (result.data.error) {
      NotificationManager.error(result.data.title);
    } else {
      NotificationManager.success(result.data.title);
      props.getBuyerSellerList({ data })
    }
    handleRequestClose();
  }
  let { codCourier_id, prepaidCourier_id, shippingData, comm, name, vacation, selectedDate, delhiveryState, shiprocketState } = state;

  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  return (
    <div className="page-heading buyerDetailsHeader d-sm-flex justify-content-sm-between align-items-sm-center">
      <div style={{ width: "50%" }}>
        <h2 className="title mb-3 mb-sm-0">Seller <ChevronRight /> {userDetails && userDetails.company_name}
          {isButtonAvailable('editCompanyName') && <> <i class="zmdi zmdi-edit zmdi-hc-fw" onClick={() => editCompname(userDetails)} /> <Tooltip title="Ticket" placement='bottom'><a target='_blank' rel="noreferrer" href={`/admin/tickets?pageT=0&searchT=&statusTB=&statusTS=&statusTR=&buyerT=&sellerT=&sort=&seller_id=${userDetails?._id}`}> < i style={{ fontSize: "24px" }} className="zmdi zmdi-ticket-star zmdi-hc-fw" /></a></Tooltip></>}</h2>
      </div>
      <div>
        {
          userDetails && (userDetails.user_status == 'inactive' || userDetails.user_status == 'hold') && <>
            {isButtonAvailable('approve') && <Button onClick={(e) => handleDenyApprove(userDetails._id)} variant="outlined" className={'text-success mr-2'} >Allow</Button>}
            {isButtonAvailable('changeUserStatus') && <Button onClick={(e) => handleDeny(userDetails._id)} variant="outlined" className={'text-danger mr-2'} >Deny</Button>}
          </>
        }
        {isButtonAvailable('resetPassAdmin') && <Button onClick={(e) => reset(e)} variant="outlined" className={'text-primary mr-2'} >Reset Pass</Button>}
        {userDetails && isButtonAvailable('sellerConfig') && <Button onClick={(e) => openCon(e)} variant="outlined" className={'text-warning'} >Config</Button>}
      </div>
      <Dialog Con={state.deny} onClose={handleRequestClose} fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle>
          {state.title == 'deny' ? 'Deny User' : 'Allow User'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to ${state.title == 'deny' ? 'deny' : 'allow'} user ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color='secondary' >
            Cancel
          </Button>
          <Button color='primary' onClick={handleDenied} >
            {state.title == 'deny' ? 'Deny' : 'Allow'}
          </Button>
        </DialogActions>
      </Dialog>
      {/*-------------------------Config-------------------------*/}
      <Dialog open={state.openConfig} onClose={handleRequestClose} fullWidth={true}
        maxWidth={'sm'}>
        <Form
          onSubmit={onSave}
          initialValues={{ comm: comm || 0 }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle>
                Seller Config
              </DialogTitle>
              <DialogContent>
                <Row>
                  <Col >
                    <FormGroup>
                      <Input type="select" name="codCourier_id" label={'Preferred Cod Courier'} id="codCourier_id" value={codCourier_id}
                        onChange={(e) => handleChange(e, 'codCourier_id')} >
                        <option value={''}>Select courier</option>
                        {
                          shippingData && shippingData.map((val) => {
                            return (<option value={val._id}>
                              {(val.name).toUpperCase()}
                            </option>)
                          })
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col >
                    <FormGroup>
                      <Input type="select" name="prepaidCourier_id" label={'Preferred Prepaid Courier'} id="prepaidCourier_id" value={prepaidCourier_id}
                        onChange={(e) => handleChange(e, 'prepaidCourier_id')}>
                        <option value={''}>Select courier</option>
                        {
                          shippingData && shippingData.map((val) => {
                            return (<option value={val._id}>
                              {(val.name).toUpperCase()}
                            </option>)
                          })
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                    <FormGroup>
                      <Field id="comm" name="comm" type="number"
                        component={ReactstrapTextField} label={"Additional Commission %"}
                        validate={composeValidators(number, required0)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                    <FormGroup>
                      <h5 className='mb-0'>Product Notification</h5>
                      <Row>
                        <Col md={2} xl={2} lg={2} sm={2} xs={2} className="mt-2">
                          <h4>Yes:</h4>
                        </Col>
                        <Col md={3} xl={3} lg={3} sm={3} xs={3}>
                          <Radio color="primary" checked={state.selectedValue === 'yes'} onChange={handleRadioChange} value="yes" name="radio button demo" aria-label="A" />
                        </Col>
                        <Col md={2} xl={2} lg={2} sm={2} xs={2} className="mt-2">
                          <h4>No:</h4>
                        </Col>
                        <Col md={3} xl={3} lg={3} sm={3} xs={3}>
                          <Radio color="primary" checked={state.selectedValue === 'no'} onChange={handleRadioChange} value="no" name="radio button demo" aria-label="B" />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                    <FormGroup>
                      <h5 className='mb-0'>Vacation {userDetails?.vaccation?.vaccationMode === true && `(${moment(userDetails?.vaccation?.tillDate).format('DD/MM/YYYY')})`}</h5>
                      <Row>
                        <Col md={2} xl={2} lg={2} sm={2} xs={2} className="mt-2">
                          <h4>Yes:</h4>
                        </Col>
                        <Col md={3} xl={3} lg={3} sm={3} xs={3}>
                          <Radio color="primary" checked={state.vacation === 'yes'} onChange={handleRadioChange1} value="yes" name="radio button demo" aria-label="A" />
                        </Col>

                        <Col md={2} xl={2} lg={2} sm={2} xs={2} className="mt-2">
                          <h4>No:</h4>
                        </Col>
                        <Col md={3} xl={3} lg={3} sm={3} xs={3}>
                          <Radio color="primary" checked={state.vacation === 'no'} onChange={handleRadioChange1} value="no" name="radio button demo" aria-label="B" />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                    <FormGroup>
                      <h5 className='mb-0'>Bulk request</h5>
                      <Row>
                        <Col md={2} xl={2} lg={2} sm={2} xs={2} className="mt-2">
                          <h4>Yes:</h4>
                        </Col>
                        <Col md={3} xl={3} lg={3} sm={3} xs={3}>
                          <Radio color="primary" checked={state.bulkReq === 'yes'} onChange={handleRadioChange2} value="yes" name="radio button demo" aria-label="A" />
                        </Col>
                        <Col md={2} xl={2} lg={2} sm={2} xs={2} className="mt-2">
                          <h4>No:</h4>
                        </Col>
                        <Col md={3} xl={3} lg={3} sm={3} xs={3}>
                          <Radio color="primary" checked={state.bulkReq === 'no'} onChange={handleRadioChange2} value="no" name="radio button demo" aria-label="B" />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <h5 className='mb-1'>Delivery Services:</h5>
                  <Col>
                    {
                      // userDetails && userDetails.user_status === 'active' &&
                      <span
                        style={{ fontWeight: 600, fontSize: '20px', textAlignLast: 'right' }}
                      >Shiprocket
                        <Switch
                          color='primary'
                          checked={shiprocketState}
                          onChange={(e) => handleChangeShiprocket(e)}
                          aria-label="shiprocketState"
                          className="codToggle"
                        />
                      </span>}
                  </Col>
                  <Col>
                    {
                      // userDetails && userDetails.user_status === 'active' &&
                      <span
                        style={{ fontWeight: 600, fontSize: '20px', textAlignLast: 'right' }}
                      >Delhivery
                        <Switch
                          color='primary'
                          checked={delhiveryState}
                          onChange={(e) => handleChangeDelhivery(e)}
                          aria-label="delhiveryState"
                          className="codToggle"
                        />
                      </span>}
                  </Col>
                </Row>
                <Row style={{ marginTop: '30px' }}>
                  <Col xs='5'>
                    <span
                      style={{ fontWeight: 600, fontSize: '20px', textAlignLast: 'right' }}
                    >Relational&nbsp;Manager 1 &nbsp;:</span>
                  </Col>

                  <Col xs='6' >
                    <FormGroup style={{ textAlignLast: 'left' }} >
                      <Input
                        name="orderStatus"
                        label="Status" id="orderStatus"
                        value={state.relationalManager1}
                        type="select"
                        onChange={(event) => handleChange1(event, 'rMId1')}
                      >
                        <option value="">Select Manager</option>
                        {state.staffListed && state.staffListed && state.staffListed.map((val, i) => {
                          return <option key={i} value={val._id}>{val.first_name
                          }</option>
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs='5'>
                    <span
                      style={{ fontWeight: 600, fontSize: '20px', textAlignLast: 'right' }}
                    >Relational&nbsp;Manager 2 &nbsp;:</span>
                  </Col>
                  <Col xs='6' >
                    <FormGroup style={{ textAlignLast: 'left' }} >
                      <Input
                        name="orderStatus"
                        label="Status" id="orderStatus"
                        value={state.relationalManager2}
                        type="select"
                        onChange={(event, newValue, previousValue) => handleChange1(event, 'rMId2')}
                      >
                        <option value="">Select Manager</option>
                        {state.staffListed && state.staffListed && state.staffListed.map((val, i) => {
                          return <option key={i} value={val._id}>{val.first_name
                          }</option>
                        })}
                      </Input>
                    </FormGroup>

                  </Col>

                </Row>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                <Button color='primary' type='submit' >
                  Save
                </Button>
              </DialogActions>
            </form>)}
        />
      </Dialog>
      <Dialog open={state.open} onClose={handleRequestClose} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>
          Update Company name
        </DialogTitle>
        <Form
          onSubmit={onUpdateName}
          initialValues={{
            name: state.name || ''
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogContent className='pt-0'>
                <Row>
                  <Col>
                    <FormGroup>
                      <Field id="name" name="name" type="text"
                        component={ReactstrapTextField} label={"Company name"}
                        validate={required}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                <Button color='primary' type='submit' >
                  Save
                </Button>
              </DialogActions>
            </form>)} />
      </Dialog>
      {/* ----------------date model------------- */}
      <Dialog open={state.datePopup} onClose={handleRequestClose}
        fullWidth={true}
        scroll='paper'
        className='sdfsfsf'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={'sm'}>
        <DialogTitle id="scroll-dialog-title">
          {
            vacation == 'no' && 'End your vacation'
          }
          {
            vacation == 'yes' && 'On Vacation until'
          }
          <DialogContentText className="mt-3">
            {
              vacation == 'no' ? 'Are you sure you want to end your vacation?' : `Please select vacation end date`
            }

          </DialogContentText>
        </DialogTitle>
        <DialogContent className="mb-1">
          <DialogContentText id="scroll-dialog-description" className="mt-3 mb-1">
            {vacation == 'yes' &&
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
              >
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    name='vacation'
                    selected={selectedDate}
                    onChange={(date) => handleDateChange(date, 'selectedDate')}
                    dateFormat="DD/MM/YYYY"
                    className="form-control"
                    format="DD-MM-YYYY"
                    value={selectedDate}
                    minDate={dayjs()} />
                </DemoContainer>
              </LocalizationProvider>
            }
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleRequestClose} color='secondary' >
            {vacation == 'no' ? 'No' : 'Cancel'}
          </Button>
          <Button onClick={handleRequestSave} color='primary'>
            {vacation == 'no' ? 'Yes' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={state.deny} onClose={handleRequestClose} fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle>
          Deny Seller
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Are you sure you want to deny user ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color='secondary' >
            Cancel
          </Button>
          <Button color='primary' onClick={handleDenied} >
            Deny
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = ({ admin }) => {
  let { actionList } = admin
  return { actionList }
};

export default connect(mapStateToProps, { getBuyerSellerList, getChangeUserStatus, getUserApprove })(BuyerDetailsHeader);