import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { Row, Col, Label, } from "reactstrap"
import { FormControl, TextField } from "@mui/material"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment"


const CustomeFilterForDate = ({ handleDateChange, from, to }) => {

    return (<>
        <Col md={6} xl={6} xs={6} sm={6} lg={6}>
            <FormControl className="w-100 mb-2 ">
                <Label for="fromDate">FROM</Label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        slotProps={{ textField: { variant: 'standard' } }}
                        onChange={(date) => handleDateChange(date, 'from')}
                        name='from'
                        id="from"
                        value={moment(from)}
                        renderInput={(params) => <TextField {...params} />}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        format="DD/MM/YYYY"
                    />
                </LocalizationProvider>
            </FormControl>
        </Col>

        <Col md={6} xl={6} xs={6} sm={6} lg={6}>
            <FormControl >
                <Label for="toDate">TO</Label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        slotProps={{ textField: { variant: 'standard' } }}
                        type="date"
                        variant="standard"
                        onChange={(date) => handleDateChange(date, 'to')}
                        name='to'
                        id="to"
                        value={moment(to)}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        format="DD/MM/YYYY"
                    />
                </LocalizationProvider>
            </FormControl>
        </Col>

    </>)
}

export default CustomeFilterForDate