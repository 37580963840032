import React, { Component, memo, useEffect, useState } from "react";
import FullWidthTabsForTicket from '../../components/routes/tabs/fixed/FullWidthTabsForTicket';
import TicketHeader from './TicketHeader'
import AxiosRequest from 'sagas/axiosRequest';
import AdminContainer from "containers/AdminContainer";
function TicketsTab(props) {
  const [sellerData, setSellerData] = useState()
  const [buyerData, setBuyerData] = useState()

  const ApiCall = async () => {
    if (props.tabValue > 1) {
      props.changeTab(0)
    }
    let result = await AxiosRequest.axiosHelperFunc1('get', 'admin/getSellers', '', '');
    let result1 = await AxiosRequest.axiosHelperFunc1('get', 'admin/getBuyers1', '', '')
    if (result && result.data?.error === false) {
      setSellerData({ sellerData: result.data.data });
    }
    if (result1 && result1.data.error === false) {
      setBuyerData({ buyerData: result1.data.data });
    }
  }
  useEffect(() => {
    ApiCall()
  }, [])

  return (
    <AdminContainer>
      <React.Fragment>
        <TicketHeader />
        <FullWidthTabsForTicket tabFor={'ticket'}  tabValueParent={0} buyerData={buyerData} sellerData={sellerData}/>
      </React.Fragment>
    </AdminContainer>
  );
}

export default memo(TicketsTab);