import {
    API_FAILED,
    GET_ORDER_LIST,
    GET_ORDER_LIST_SUCCESS,
    GET_ORDER_HISTORY,
    GET_ORDER_HISTORY_SUCCESS,
    GET_INVENTORY,
    GET_INVENTORY_SUCCESS,
    GET_INVENTORY_POPUP,
    GET_INVENTORY_POPUP_SUCCESS,
    GET_BUYER_SELLER_LIST,
    GET_BUYER_SELLER_LIST_SUCCESS,
    GET_BUYER_SELLER_LIST_ADMIN,
    GET_BUYER_SELLER_LIST_SUCCESS_ADMIN,
    GET_ORDER_DETAILS,
    GET_ORDER_DETAILS_SUCCESS,
    GET_USER_APPROVE,
    GET_USER_APPROVE_SUCCESS,
    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_SUCCESS,
    GET_META_DATA,
    GET_META_DATA_SUCCESS,

    // ------STAFF/GROUPS------

    ADD_GROUP,
    ADD_GROUP_SUCCESS,
    DELETE_GROUP,
    DELETE_GROUP_SUCCESS,
    LIST_GROUP,
    LIST_GROUP_SUCCESS,
    LIST_PERMISSION_MODULES,
    LIST_PERMISSION_MODULES_SUCCESS,
    // GET_SIDEBAR,
    // GET_SIDEBAR_SUCCESS,
    ADD_STAFF,
    ADD_STAFF_SUCCESS,
    DELETE_STAFF,
    DELETE_STAFF_SUCCESS,
    LIST_STAFF,
    LIST_STAFF_SUCCESS,

    //PRODUCT-REQUEST

    GET_PRODUCT_REQUEST_LIST,
    GET_PRODUCT_REQUEST_LIST_SUCCESS,
    GET_APPROVE_PRODUCT_REQUEST_LIST,
    GET_APPROVE_PRODUCT_REQUEST_LIST_SUCCESS,

    GET_PROMOTIONS_LIST,
    GET_PROMOTIONS_LIST_SUCCESS,
    GET_ADD_PROMOTIONS_LIST,
    GET_ADD_PROMOTIONS_LIST_SUCCESS,
    GET_EDIT_PROMOTIONS_LIST,
    GET_EDIT_PROMOTIONS_LIST_SUCCESS,
    GET_DELETE_PROMOTIONS_LIST,
    GET_DELETE_PROMOTIONS_LIST_SUCCESS,

    GET_CHANGE_USER_STATUS,
    GET_CHANGE_USER_STATUS_SUCCESS,

    GET_SCHEDULE_NOTIFICATION_LIST,
    GET_SCHEDULE_NOTIFICATION_LIST_SUCCESS,
    GET_LOW_STOCK_SELLERWISE_LIST,
    GET_LOW_STOCK_SELLERWISE_LIST_SUCCESS,
    GET_LOW_STOCK_OVERALL_LIST,
    GET_LOW_STOCK_OVERALL_LIST_SUCCESS,

    // --------ADD/DEL GST-------------

    GET_ADD_GST,
    GET_ADD_GST_SUCCESS,
    GET_DELETE_GST,
    GET_DELETE_GST_SUCCESS,

    // -------------ADD/DEL PRODUCT TYPE---------------

    GET_ADD_PRODUCT_TYPE,
    GET_ADD_PRODUCT_TYPE_SUCCESS,
    GET_DELETE_PRODUCT_TYPE,
    GET_DELETE_PRODUCT_TYPE_SUCCESS,

    // ----------ADD/DEL PRODUCT CATEGORY-----------------

    GET_ADD_PRODUCT_CATEGORY,
    GET_ADD_PRODUCT_CATEGORY_SUCCESS,
    GET_DELETE_PRODUCT_CATEGORY,
    GET_DELETE_PRODUCT_CATEGORY_SUCCESS,

    // -----------------ADD/DEL COMPANY---------------

    GET_ADD_COMPANY,
    GET_ADD_COMPANY_SUCCESS,
    GET_DELETE_COMPANY,
    GET_DELETE_COMPANY_SUCCESS,

    // -------------ADD PRODUCT-----------------

    GET_DELETE_PRODUCT,
    GET_DELETE_PRODUCT_SUCCESS,

    GET_DASHBOARD_CARD,
    GET_DASHBOARD_CARD_SUCCESS,

    ADMIN_DASHBOARD_STATS,
    ADMIN_DASHBOARD_STATS_SUCCESS,

    // ------------- SELLER SALES STATS-----------------
    ADMIN_SALES_STATS,
    GET_SALES_STATISTICS,
    GET_SALES_STATISTICS_SUCCESS,

    GET_TOP_FIVE_LEAST_FIVE,
    GET_TOP_FIVE_LEAST_FIVE_SUCCESS,

    // ------------- SETTLEMENTS-----------------

    GET_SETTLEMENTS,
    GET_SETTLEMENTS_SUCCESS,

    // ------------ADMIN SIDEBAR--------------

    GET_SIDEBAR,
    GET_SIDEBAR_SUCCESS,

    GET_COD_ON_OFF,
    GET_COD_ON_OFF_SUCCESS,

    // ------------------------------------

    GET_GLOBAL_COD,
    GET_GLOBAL_COD_SUCCESS,

    GET_CHANGE_GLOBAL_COD,
    GET_CHANGE_GLOBAL_COD_SUCCESS,

    // --------------------------------

    GET_CHANGE_PROMOTION,
    GET_CHANGE_PROMOTION_SUCCESS,

    GET_CHANGE_DEAL_OF_THE_DAY,
    GET_CHANGE_DEAL_OF_THE_DAY_SUCCESS,

    GET_VERIFY_PHONE,
    GET_VERIFY_PHONE_SUCCESS,

    // ========================
    GET_ALL_SETTLEMENT,
    GET_ALL_SETTLEMENT_SUCCESS,

    GET_SETTLEMENT_LIST,
    GET_SETTLEMENT_LIST_SUCCESS,

    GET_PENDING_SETTLEMENT,
    GET_PENDING_SETTLEMENT_SUCCESS,

    GET_ALL_ORDER_LIST,
    GET_ALL_ORDER_LIST_SUCCESS,

    GET_ALL_MANIFEST_LIST,
    GET_ALL_MANIFEST_SUCCESS,

    GET_GROUP_SETTLEMENT,
    GET_GROUP_SETTLEMENT_SUCCESS,

    GET_LIST_GROUP_SETTLEMENT,
    GET_LIST_GROUP_SETTLEMENT_SUCCESS,

    GET_DELIVERY_ON_OFF,
    GET_DELIVERY_ON_OFF_SUCCESS,

    GET_TRANSACTION_LIST,
    GET_TRANSACTION_LIST_SUCCESS,

    GET_BUYER_TRANSACTION_LIST,
    GET_BUYER_TRANSACTION_LIST_SUCCESS,

    GET_PROMOTIONS_LISTING,
    GET_PROMOTIONS_LISTING_SUCCESS,

    GET_JUMBO_DEAL,
    GET_JUMBO_DEAL_SUCCESS,

    GET_FAST_MOVING,
    GET_FAST_MOVING_SUCCESS,

    GET_BNPL_ON_OFF,
    GET_BNPL_ON_OFF_SUCCESS,

    GET_TICKET_LIST,
    GET_TICKET_LIST_SUCCESS,

    GET_TICKET_HISTORY_LIST,
    GET_TICKET_HISTORY_LIST_SUCCESS,

    GET_ADD_TICKET,
    GET_ADD_TICKET_SUCCESS,

    GET_BULK_LIST,
    GET_BULK_LIST_SUCCESS,

    GET_BULK_ON_OFF,
    GET_BULK_ON_OFF_SUCCESS,
    ADMIN_SALES_STATS_SUCCESS,
    ADMIN_MONTHLY_SALES_STATS_SUCCESS,

    // PREPAID ORDER
    GET_PREPAIDORDER_LIST,
    GET_PREPAIDORDER_LIST_SUCCESS,
    GET_MANIFEST_VERIFIED_LIST,
    GET_MANIFEST_VERIFIED_LIST_SUCCESS,
  
    GET_MANIFEST_DISCREPANCY_LIST,
    GET_MANIFEST_DISCREPANCY_LIST_SUCCESS

} from "constants/ActionTypes";
import { NotificationManager } from 'react-notifications';



const INIT_STATE = {
    loader: false,
    loadingDetails: true,
    manifestVerified:'',
    manifestDiscrepancy:'',
    orderList: '',
    orderHistoryList: [],
    listInventory: [],
    buyerSellerList: [],
    orderDetails: '',
    userApprove: '',
    productList: '',
    metaData: '',
    manifestOrder:'',
    listGroupData: [],
    listpermissionModulesdata: [],
    sidebar: [],
    staffList: [],
    requestedProducts: [],
    approveRequestedProducts: [],
      arr:'',
      seller_arr:'',
    productRequestList: '',
    promotionsList: '',
    addPromotionsList: '',
    editPromotionsList: '',
    deletePromotionsList: '',
    changeUserStatus: '',
    addGst: '',
    deleteGst: '',
    addProductType: '',
    deleteProductType: '',
    addProductCategory: '',
    deleteProductCategory: '',
    addCompany: '',
    deleteCompany: '',
    deleteProduct: '',

    sellerStats: '',

    adminStats: '',
    saleStats:[],
    lastWeekRevenueOrders: '',
    lastThirtyDaysRevenueOrders: '',
    totalRevenueOrders: '',
    montlyRevenueOrders: '',
    salesStatistics: '',

    topFive: '',
    leastFive: '',

    adminSettlements: '',

    codOnOff: '',

    globalCod: '',
    changeCod: '',

    changePromotion: '',

    changeDealOfTheDay: '',
    changeJumboDeal:'',
    changeFastMoving:'',

    verifyPhoneUser: '',
    allSettlement: '',
    settlementList: '',
    pendingSettlement: '',
    Detail:{},
    allOrder: '',
    groupSettle: '',
    listGroupSettlement: '',

    deliveryOnOff: '',
    transactionList: '',

    allbuyer: '',
    allbuyerWallet:'',
    actionList: [],
    promotionsList: '',
    bnpl: '',
    ticketList: '',

    ticketHistoryList: '',
    addTicket: '',
    bulkList: '',
    bulkOnOff: '',
    prepaidOrderList:'',
    notificationList:""
};


export default (state = INIT_STATE, action) => {
    

    switch (action.type) {

        case API_FAILED: {
            NotificationManager.error(action.payload)
            return {
                ...state,
                loader: false,
                loading: false,
            }
        }

        case GET_ORDER_LIST: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_ORDER_LIST_SUCCESS: {

            return {
                ...state,
                loading: false,
                // orderList: action.payload.detail[0],
                orderList: action.payload.data,
            }
        }

        case GET_ORDER_HISTORY: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_ORDER_HISTORY_SUCCESS: {
            return {
                ...state,
                loading: false,
                // orderHistoryList: action.payload.detail,
                orderHistoryList: action.payload.data,
            }
        } 
        case GET_PREPAIDORDER_LIST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_PREPAIDORDER_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                // orderHistoryList: action.payload.detail,
                prepaidOrderList : action.payload.data,
            }
        } 
        case GET_INVENTORY:
            return {
                ...state,
                listInventory: [],
                loading: true,
               
            }
        case GET_INVENTORY_SUCCESS:
            return {
                ...state,
                listInventory: action.payload.detail,
                productCategories: action.payload.productCategories,
                loading: false
            }


            case GET_INVENTORY_POPUP:
                return {
                    ...state,
                    loading: true,
                   
                }
            case GET_INVENTORY_POPUP_SUCCESS:
                return {
                    ...state,
                    Detail: action.payload,
                    loading: false
                }





        case GET_BUYER_SELLER_LIST:
            return {
                ...state,
                loading: true,
                loadingDetails: true
            }
        case GET_BUYER_SELLER_LIST_SUCCESS:
            return {
                ...state,
                buyerSellerList: action.payload,
                loading: false,
                loadingDetails: false
            }

        case GET_BUYER_SELLER_LIST_ADMIN:
            return {
                ...state,
                loading: true
            }
        case GET_BUYER_SELLER_LIST_SUCCESS_ADMIN:
            return {
                ...state,
                buyerSellerList: action.payload,
                loading: false
            }
        case GET_ORDER_DETAILS: {
            return {
                ...state,
                loader: false,
            }
        }
        case GET_ORDER_DETAILS_SUCCESS: {
            return {
                ...state,
                loading: false,
                orderDetails: action.payload.detail,
                // orderDetails: action.payload.data,
                totalValue: action.payload.totalValue,
                userName: action.payload.userName
            }
        }
        case GET_USER_APPROVE: {
            return {
                ...state,
                loader: false,
            }
        }
        case GET_USER_APPROVE_SUCCESS: {
            NotificationManager.success(action.payload.title)
            return {
                ...state,
                loading: false,
                userApprove: action.payload,

            }
        }
        case GET_PRODUCT_LIST: {
            return {
                ...state,
                loader: true,
            }
        }
        case GET_PRODUCT_LIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                productList: action.payload,
            }
        }
        case GET_META_DATA: {
            return {
                ...state,
                loader: false,
            }
        }
        case GET_META_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                metaData: action.payload,
            }
        }

        // ----------STAFF/GROUPS----------

        case ADD_GROUP:
            return {
                ...state,

                loading: true
            }
        case ADD_GROUP_SUCCESS:
            NotificationManager.success(action.payload.title)
            return {
                ...state,
                loading: false
            }
        case DELETE_GROUP:
            return {
                ...state,
                loading: true
            }
        case DELETE_GROUP_SUCCESS:
            NotificationManager.success(action.payload.title)
            return {
                ...state,
                loading: false
            }
        case LIST_GROUP:
            return {
                ...state,
                loading: true
            }
        case LIST_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                listGroupData: action.payload.detail
            }
        case LIST_PERMISSION_MODULES:
            return {
                ...state,
                loading: true
            }
        case LIST_PERMISSION_MODULES_SUCCESS:
            return {
                ...state,
                listpermissionModulesdata: action.payload.detail,
                loading: false
            }

        // case GET_SIDEBAR:
        //     return {
        //         ...state,
        //         loading: true
        //     }
        // case GET_SIDEBAR_SUCCESS:
        // if(action.payload.message){
        //     NotificationManager.error(action.payload.message)
        // }
        //     return {
        //         ...state,
        //         sidebar: action.payload.detail,
        //         userDetails: action.payload.user_details,
        //         isVaccatioMode:action.payload.isVaccatioMode,
        //         loading: false
        //     }
        case ADD_STAFF:
            return {
                ...state,
                loading: true
            }
        case ADD_STAFF_SUCCESS:
            NotificationManager.success(action.payload.title)
            return {
                ...state,
                loading: false
            }
        case DELETE_STAFF:
            return {
                ...state,
                loading: true
            }
        case DELETE_STAFF_SUCCESS:
            NotificationManager.success(action.payload.title)
            return {
                ...state,
                loading: false
            }
        case LIST_STAFF:
            return {
                ...state,
                loading: true
            }
        case LIST_STAFF_SUCCESS:
            return {
                ...state,
                loading: false,
                staffList: action.payload.detail
            }

        case GET_PRODUCT_REQUEST_LIST:
            return {
                ...state,
                loading: true
            }

        case GET_PRODUCT_REQUEST_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                productRequestList: action.payload.detail[0]
            }

        case GET_APPROVE_PRODUCT_REQUEST_LIST:
            return {
                ...state,
                loading: true
            }

        case GET_APPROVE_PRODUCT_REQUEST_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                approveRequestedProducts: action.payload
            }

        case GET_PROMOTIONS_LIST:
            return {
                ...state,
                loading: true
            }

        case GET_PROMOTIONS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                promotionsList: action.payload
            }

            case GET_SCHEDULE_NOTIFICATION_LIST:
                return {
                    ...state,
                    loading: true
                }
    
            case GET_SCHEDULE_NOTIFICATION_LIST_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    notificationList: action.payload
                }

        case GET_LOW_STOCK_SELLERWISE_LIST:
            return {
                ...state,
                loading: true,
            }
        case GET_LOW_STOCK_SELLERWISE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                seller_arr: action.payload
            }


            case GET_LOW_STOCK_OVERALL_LIST:
                return {
                    ...state,
                    loading: true,
                }
            case GET_LOW_STOCK_OVERALL_LIST_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    arr: action.payload
                }

        case GET_ADD_PROMOTIONS_LIST:
            return {
                ...state,
                loading: true
            }

        case GET_ADD_PROMOTIONS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                addPromotionsList: action.payload
            }

        case GET_EDIT_PROMOTIONS_LIST:
            return {
                ...state,
                loading: true
            }

        case GET_EDIT_PROMOTIONS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                editPromotionsList: action.payload
            }

        case GET_DELETE_PROMOTIONS_LIST:
            return {
                ...state,
                loading: true
            }

        case GET_DELETE_PROMOTIONS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                deletePromotionsList: action.payload
            }

        case GET_CHANGE_USER_STATUS:
            return {
                ...state,
                loading: true
            }

        case GET_CHANGE_USER_STATUS_SUCCESS:
            
            NotificationManager.success('User status changed successfully')
            return {
                ...state,
                loading: false,
                changeUserStatus: action.payload
            }

        case GET_ADD_GST:
            return {
                ...state,
                loading: true
            }

        case GET_ADD_GST_SUCCESS:
            return {
                ...state,
                loading: false,
                addGst: action.payload
            }

        case GET_DELETE_GST:
            return {
                ...state,
                loading: true
            }

        case GET_DELETE_GST_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteGst: action.payload
            }

        case GET_ADD_PRODUCT_TYPE:
            return {
                ...state,
                loading: true
            }

        case GET_ADD_PRODUCT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                addProductType: action.payload
            }

        case GET_DELETE_PRODUCT_TYPE:
            return {
                ...state,
                loading: true
            }

        case GET_DELETE_PRODUCT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteProductType: action.payload
            }

        case GET_ADD_PRODUCT_CATEGORY:
            return {
                ...state,
                loading: true
            }

        case GET_ADD_PRODUCT_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                addProductCategory: action.payload
            }

        case GET_DELETE_PRODUCT_CATEGORY:
            return {
                ...state,
                loading: true
            }

        case GET_DELETE_PRODUCT_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteProductCategory: action.payload
            }
        case GET_ADD_COMPANY:
            return {
                ...state,
                loading: true
            }

        case GET_ADD_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                addCompany: action.payload
            }

        case GET_DELETE_COMPANY:
            return {
                ...state,
                loading: true
            }

        case GET_DELETE_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteCompany: action.payload
            }

        case GET_DELETE_PRODUCT:
            return {
                ...state,
                loading: true
            }

        case GET_DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteProduct: action.payload
            }

        case GET_DASHBOARD_CARD:
            return {
                ...state,
                loading: true
            }

        case GET_DASHBOARD_CARD_SUCCESS:
            return {
                ...state,
                sellerStats: action.payload,
                loading: false
            }

        case ADMIN_DASHBOARD_STATS:
            return {
                ...state,
                loading: true
            }
        case ADMIN_DASHBOARD_STATS_SUCCESS:
            return {
                ...state,
                adminStats: action.payload.detail,
                loading: false
            }
        case ADMIN_SALES_STATS:
            return{
               
                ...state,
                loading: true
            }
        case ADMIN_SALES_STATS_SUCCESS:
            return {
                ...state,
                saleStats: action.payload.montlyRevenueOrders,
                loading: false
            }
        case ADMIN_MONTHLY_SALES_STATS_SUCCESS:
            return {
                ...state,
                monthlysaleStats: action.payload.EveryDaysale,
                loading: false
            }

        case GET_SALES_STATISTICS:
            return {
                ...state,
                loading: true
            }

        case GET_SALES_STATISTICS_SUCCESS:
            return {
                ...state,
                lastWeekRevenueOrders: action.payload.lastWeekRevenueOrders,
                lastThirtyDaysRevenueOrders: action.payload.lastThirtyDaysRevenueOrders,
                totalRevenueOrders: action.payload.totalRevenueOrders,
                montlyRevenueOrders: action.payload.montlyRevenueOrders,
                salesStatistics: action.payload,
                loading: false,
            }

        case GET_TOP_FIVE_LEAST_FIVE:
            return {
                ...state,
                loading: true
            }

        case GET_TOP_FIVE_LEAST_FIVE_SUCCESS:
            return {
                ...state,
                topFive: action.payload.top5,
                leastFive: action.payload.least5,

                loading: false,
            }

        case GET_SETTLEMENTS:
            return {
                ...state,
                loading: true
            }

        case GET_SETTLEMENTS_SUCCESS:
            return {
                ...state,
                // lastWeekRevenueOrders: action.payload.lastWeekRevenueOrders,
                // lastThirtyDaysRevenueOrders: action.payload.lastThirtyDaysRevenueOrders,
                // totalRevenueOrders: action.payload.totalRevenueOrders,
                // montlyRevenueOrders: action.payload.montlyRevenueOrders,
                adminSettlements: action.payload,
                loading: false,
            }

        // --------admin sidebar----------------

        case GET_SIDEBAR:
            return {
                ...state,
                loading: true
            }
        case GET_SIDEBAR_SUCCESS:
            if (action.payload.message) {
                NotificationManager.error(action.payload.message)
            }
            return {
                ...state,
                sidebar: action.payload.detail,
                actionList: action.payload.actions,
                // userDetails: action.payload.user_details,
                // isVaccatioMode:action.payload.isVaccatioMode,
                loading: false
            }

        case GET_COD_ON_OFF:
            return {
                ...state,
                loading: true
            }
        case GET_COD_ON_OFF_SUCCESS:
            return {
                ...state,
                codOnOff: action.payload,
                loading: false
            }

        case GET_GLOBAL_COD:
            return {
                ...state,
                loading: true
            }
        case GET_GLOBAL_COD_SUCCESS:
            return {
                ...state,
                globalCod: action.payload,
                loading: false
            }
        case GET_CHANGE_GLOBAL_COD:
            return {
                ...state,
                loading: true
            }
        case GET_CHANGE_GLOBAL_COD_SUCCESS:
            return {
                ...state,
                changeCod: action.payload,
                loading: false
            }
        case GET_CHANGE_PROMOTION:
            return {
                ...state,
                loading: true
            }
        case GET_CHANGE_PROMOTION_SUCCESS:
            return {
                ...state,
                changePromotion: action.payload,
                loading: false
            }
        case GET_CHANGE_DEAL_OF_THE_DAY:
            return {
                ...state,
                loading: true
            }
        case GET_CHANGE_DEAL_OF_THE_DAY_SUCCESS:
            return {
                ...state,
                changeDealOfTheDay: action.payload,
                loading: false
            }
        case GET_JUMBO_DEAL:
            return {
                ...state,
                loading: true
            }
        case GET_JUMBO_DEAL_SUCCESS:
            return {
                ...state,
                changeJumboDeal: action.payload,
                loading: false
            }
            case GET_FAST_MOVING:
            return {
                ...state,
                loading: true
            }
        case GET_FAST_MOVING_SUCCESS:
            return {
                ...state,
                changeFastMoving: action.payload,
                loading: false
            }
        case GET_VERIFY_PHONE:
            return {
                ...state,
                loading: true
            }
        case GET_VERIFY_PHONE_SUCCESS:
            return {
                ...state,
                verifyPhoneUser: action.payload,
                loading: false
            }
        case GET_ALL_SETTLEMENT:
            return {
                ...state,
                loading: true
            }
        case GET_ALL_SETTLEMENT_SUCCESS:
            
            return {
                ...state,
                allSettlement: action.payload,
                loading: false
            }
        case GET_SETTLEMENT_LIST:
            return {
                ...state,
                loading: true
            }
        case GET_SETTLEMENT_LIST_SUCCESS:
            return {
                ...state,
                settlementList: action.payload,
                loading: false
            }
        case GET_PENDING_SETTLEMENT:
            return {
                ...state,
                loading: true
            }
        case GET_PENDING_SETTLEMENT_SUCCESS:
            return {
                ...state,
                pendingSettlement: action.payload,
                loading: false
            }
        case GET_ALL_ORDER_LIST: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_ALL_ORDER_LIST_SUCCESS: {

            return {
                ...state,
                loading: false,
                allOrder: action.payload.data,
            }
        }

        case GET_ALL_MANIFEST_LIST: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_ALL_MANIFEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                manifestOrder: action.payload.data,
            }
        }

        case GET_GROUP_SETTLEMENT: {
            return {
                ...state,
                loader: false,
            }
        }

        case GET_GROUP_SETTLEMENT_SUCCESS: {

            return {
                ...state,
                loading: false,
                groupSettle: action.payload,
            }
        }
        case GET_LIST_GROUP_SETTLEMENT: {
            return {
                ...state,
                loader: false,
            }
        }

        case GET_LIST_GROUP_SETTLEMENT_SUCCESS: {

            return {
                ...state,
                loading: false,
                listGroupSettlement: action.payload,
            }
        }
        case GET_DELIVERY_ON_OFF:
            return {
                ...state,
                loading: true
            }
        case GET_DELIVERY_ON_OFF_SUCCESS:
            return {
                ...state,
                deliveryOnOff: action.payload,
                loading: false
            }
        case GET_TRANSACTION_LIST:
            return {
                ...state,
                loading: true
            }
        case GET_TRANSACTION_LIST_SUCCESS:
            return {
                ...state,
                transactionList: action.payload.details[0],
                loading: false
            }
        case GET_BUYER_TRANSACTION_LIST:
            return {
                ...state,
                loading: true
            }
        case GET_BUYER_TRANSACTION_LIST_SUCCESS:
            return {
                ...state,
                allbuyer: action.payload.details[0],
                allbuyerWallet: action.payload.total_amt,
                loading: false
            }

        case GET_PROMOTIONS_LISTING: {
            return {
                ...state,
                loader: true,
            }
        }

        case GET_PROMOTIONS_LISTING_SUCCESS: {
            return {
                ...state,
                loading: false,
                promotionsList: action.payload.detail,
            }
        }
        case GET_BNPL_ON_OFF:
            return {
                ...state,
                loading: true
            }
        case GET_BNPL_ON_OFF_SUCCESS:
            return {
                ...state,
                bnpl: action.payload,
                loading: false
            }

        case GET_TICKET_LIST: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_TICKET_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                ticketList: action.payload.data,
            }
        }

        case GET_TICKET_HISTORY_LIST: {
            return {
                ...state,
                loading: true,
            }
        }
        case GET_TICKET_HISTORY_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                ticketHistoryList: action.payload,
            }
        }

        case GET_ADD_TICKET: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_ADD_TICKET_SUCCESS: {
            return {
                ...state,
                loading: false,
                addTicket: action.payload,
            }
        }
        //----------------------------------------------//

        case GET_BULK_LIST: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_BULK_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                bulkList: action.payload.data,
            }
        }
        //----------------------------------------------Manifest----------------------//
          
        case GET_MANIFEST_VERIFIED_LIST: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_MANIFEST_VERIFIED_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                manifestVerified: action.payload.data,
            }
        }


        case GET_MANIFEST_DISCREPANCY_LIST: {
            return {
                ...state,
                loading: true,
            }
        }

        case GET_MANIFEST_DISCREPANCY_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                manifestDiscrepancy: action.payload.data,
            }
        }
    
        default:
            return state;
    }
}
