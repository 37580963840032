import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import { Col, Row, Label, Input } from 'reactstrap';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './index.css'
import AppConfig from "constants/config";
import Popover from '@mui/material/Popover';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getBulkList } from 'actions/admin';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

const BulkList = (props) => {
  const perPage = 100
  const [page, setPage] = useState(0)
  const [searchedText, setSearchedText] = useState('')
  const [popUp, setPopUp] = useState(false)
  const [viewRequestPopup, setViewRequestPopup] = useState(false)
  const [dialogData, setDialogData] = useState('')
  const [closePopup, setClosePopup] = useState(false)
  const [showPopUP, setShowPopUP] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [offerId, setOfferId] = useState(null)

  const showPopUp = () => {
    setClosePopup(true)
  }

  const handleLogClose1 = () => {
    setClosePopup(false)
  }

  useEffect(() => {
    let data = {
      page: page + 1,
      perPage: perPage,
      searchText: searchedText,
    }
    if (props.tab === 0) {
      props.getBulkList({ data })
    }
  }, [])

  const changePage = (page) => {
    let data = {
      page: page + 1,
      perPage: perPage,
      searchText: searchedText,
    }
    props.getBulkList({ data })
    setPage(page)
  };

  const handleSearch = (searchText) => {
    setSearchedText(searchText)
    setPage(0)
    let data = {
      searchText: searchText,
      page: 1,
      perPage,
    }
    props.getBulkList({ data })
  };

  const handleLogClose = () => {
    setViewRequestPopup(false)
    setDialogData('')
    setClosePopup(false)
    setPopUp(false)
  }

  const actions = (d, offers) => {
    if (d.status === 'REQUESTED') {
      return <> <span className='text-primary' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { setViewRequestPopup(true); setDialogData(d) }}>
        VIEW
      </span> {pop(d)} </>
    } else if (offers.length > 0) {
      return <> <span className='text-primary' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { setPopUp(true); setDialogData(d) }}>
        DETAILS
      </span> {pop(d)} </>
    } else {
      return <span className='text-muted' style={{ cursor: 'pointer' }} >
        N/A
      </span>
    }
  }

  const pop = (d) => {
    return <div>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
        sx={{ ml: 2 }}
        aria-haspopup="true"
      >
        <KeyboardArrowDownIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ sx: { boxShadow: '0px 5px 5px -3px #dee2e6' } }}
      >
        <span onClick={() => setAnchorEl(null)} className='text-danger m-2' style={{ cursor: 'pointer' }}>
          <span onClick={(e) => { setClosePopup(true); setDialogData(d) }}>{`CLOSE`}</span>
        </span>
      </Popover>
    </div>
  }

  const handleBulkAccept = () => {
    let data = { requestId: dialogData._id, status: 'confirmed', offerId }
    axios.post(`${AppConfig.baseUrl}bulkRequest/adminConfirm`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title);
      }
      handleLogClose();
      let data = { page: page + 1, perPage: perPage }
      props.getBulkList({ data })
    })
    handleLogCloseReq(false)
  }

  const handleLogCloseReq = (val) => {
    setShowPopUP(val)
  }

  const handleCloseRequest = () => {
    let data = { requestId: dialogData._id }
    axios.post(`${AppConfig.baseUrl}bulkRequest/closeRequest`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title);
      }
      handleLogClose();
      let data = { page: page + 1, perPage: perPage }
      props.getBulkList({ data })
    })
  }

  const handleBulk = (req_id) => {
    setOfferId(req_id)
    handleLogCloseReq(true)
  }

  let { bulkList } = props;

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: bulkList && bulkList.count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: searchedText,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  const columns = [
    {
      name: "product",
      label: "Product name",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "buyer",
      label: "Buyer",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "discount",
      label: "Additional expected discount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "quantity",
      label: "Expected quantity",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
      }
    }
  ];

  const statusStyle = (data) => {
    let status = data.toLowerCase();
    return status.includes("supplied") ? "#B45F06" : status.includes("requested") ? '#2B78E4' : status.includes("confirmed") ? "#38761D" : status.includes("offered") ? "#990000" : status.includes("expired") ? "#ea9a99" : status.includes("fulfilled") ? "#b6d8a8" : status.includes("closed") ? "#9fc4f7" : '';
  }

  let data = []
  bulkList && bulkList.data1.length > 0 && bulkList.data1.map(d => {
    data.push({
      product: d.product_name && (d.product_name).toUpperCase(),
      buyer: <div> {d.buyer_name && (d.buyer_name).toUpperCase()} <a rel="noreferrer" target='_blank' href={`/admin/buyers/details/${d.user_id._id}`}>({d.user_id && d.user_id.buyerId})</a></div>,
      discount: `${d.discount}%`,
      quantity: d.quantity,
      status: <div key={'recent'} className={`badge text-uppercase text-white`} style={{ backgroundColor: statusStyle(d.status) }}>{d.status}</div>,
      date: moment(d.createdAt).format('DD/MM/YYYY'),
      actions: actions(d, d.offers),
    })
  })

  const theme1 = createTheme({
    overrides: {
      MuiTableCell: {
        sizeSmall: {
          padding: '15px',
        },
      }
    }
  });
  let { loading } = props;
  return (
    <div>
      <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
        {loading ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> :
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
            style={{ borderRadius: '0px !important' }}
          />
        }
      </div>
      <Dialog open={popUp} onClose={handleLogClose} fullWidth={true} maxWidth={'md'}>
        <DialogTitle className='pb-0'>
          DETAILS
        </DialogTitle>
        <DialogContent>
          <Paper className={'tableRoot MuiPaper-rounded '} elevation={0}>
            <ThemeProvider theme={theme1}>
              <Table className={'tableMain MuiPaper-rounded'} aria-label="spanning table" >
                <TableHead>
                  <TableRow style={{ backgroundColor: '#dedede', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                    <TableCell align="center" size='small'>Seller Name</TableCell>
                    <TableCell align="center" size='small'>Quantity</TableCell>
                    <TableCell align="center" size='small'>Discount</TableCell>
                    <TableCell align="center" size='small'>Final cost</TableCell>
                    <TableCell align="center" size='small'>Status</TableCell>
                    <TableCell align="center" size='small'>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dialogData && dialogData.offers.length > 0 && dialogData.offers.map((eachStatus, index) => (
                    <TableRow key={index} style={{ marginBottom: '5px' }}>
                      <TableCell align="center" size='small' style={{ fontSize: '12px' }}>{(eachStatus.name).toUpperCase()}</TableCell>
                      <TableCell align="center" size='small' style={{ fontSize: '12px' }}>{eachStatus.quantity}</TableCell>
                      <TableCell align="center" size='small' style={{ fontSize: '12px' }}>{eachStatus.discount}%</TableCell>
                      <TableCell align="center" size='small' style={{ fontSize: '12px' }}>₹{(eachStatus.amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                      {((dialogData?.status).toLowerCase() === 'offered') ? <TableCell align="center" size='small' className='text-primary' style={{ textDecoration: 'underline', fontSize: '12px' }} >
                        OFFERED
                      </TableCell> :
                        ((dialogData?.status).toLowerCase() === 'supplied' && eachStatus.isSupplied === true) ? <TableCell align="center" size='small' className={'text-primary'} style={{ textDecoration: 'underline', fontSize: '12px' }} >
                          SUPPLIED
                        </TableCell> :
                          ((dialogData?.status).toLowerCase() === 'confirmed' && eachStatus.isConfirmed === true) ? <TableCell align="center" size='small' className={'text-primary'} style={{ textDecoration: 'underline', fontSize: '12px' }}>
                            CONFIRMED
                          </TableCell> : <TableCell align="center" size='small' className={'text-muted'} style={{ fontSize: '12px' }}>
                            {'N/A'}
                          </TableCell>

                      }
                      <TableCell align="center" size='medium' style={{ fontSize: '14px' }}>{<Button className={((dialogData?.status).toLowerCase() === 'offered') ? 'text-green' : 'text-gray'} disabled={((dialogData?.status).toLowerCase() === 'offered') ? false : true} onClick={() => handleBulk(eachStatus._id)} style={{ cursor: 'pointer' }}><span>{`ACCEPT`}</span></Button>}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ThemeProvider>
          </Paper>
        </DialogContent>
        <DialogActions className='mr-2'>
          <span onClick={showPopUp} className='text-danger m-2' style={{ cursor: 'pointer' }}><span>{`REJECT`}</span></span>
          <Button onClick={handleLogClose} color='secondary' >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={viewRequestPopup} onClose={handleLogClose} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle className='pb-0'>
          DETAILS
        </DialogTitle>
        <DialogContent>
          <Row>
            <Col sm={12} lg={12} md={12} xl={12} xs={12} className="mb-2">
              <Label>Product Name</Label>
              <Input value={dialogData.product_name} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
              <Label>Quantity</Label>
              <Input value={dialogData.quantity} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6} className="mb-2">
              <Label>Discount</Label>
              <Input value={`${dialogData.discount}%`} readOnly />
            </Col>
            <Col sm={6} lg={6} md={6} xl={6} xs={6}>
              <Label>Final Cost  (W/O GST)</Label>
              <Input type="text" name='value' readOnly value={dialogData && `₹${(dialogData.amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`} />
            </Col>
          </Row>
        </DialogContent>
        <DialogActions className='mr-2'>
          <Button onClick={handleLogClose} color='secondary' >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={closePopup} onClose={handleLogClose1} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle className='pb-0'>
          CLOSE REQUEST
        </DialogTitle>
        <DialogContent>
          Are you sure you want to close this request ?
        </DialogContent>
        <DialogActions className='mr-2'>
          <Button onClick={() => handleLogClose1()} color='secondary' >
            Close
          </Button>
          <Button onClick={() => handleCloseRequest()} color='primary' >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showPopUP} onClose={() => handleLogCloseReq(false)} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle className='pb-0'>
          ACCEPT REQUEST
        </DialogTitle>
        <DialogContent>
          Are you sure you want to accept this request ?
        </DialogContent>
        <DialogActions className='mr-2'>
          <Button onClick={() => handleLogCloseReq(false)} color='secondary' >
            Close
          </Button>
          <Button onClick={() => handleBulkAccept()} color='primary' >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ admin }) => {
  const { bulkList, loading } = admin;
  return { bulkList, loading }
};

export default connect(mapStateToProps, { getBulkList })(BulkList);