import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './store';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './firebase/firebase';
import '../src/Seller.css'
import './App.css'
import 'react-notifications/lib/notifications.css';
import AdminSignIn from 'containers/AdminSignIn';
import { NotificationContainer } from 'react-notifications';
import "assets/vendors/style"
import SignUp from 'containers/SignUp';
import VerifyToken from 'containers/verifyToken';
import ForgotPassword from 'containers/forgotPassword';
import UnderMaintenance from 'containers/UnderMaintenance';
import Dashboard from 'app/routes/adminDashboard/routes/Dashboard';
import Inventory from 'app/routes/adminInventory/routes/inventory';
import BuyerList from 'app/routes/buyers/routes/buyerList';
import BuyerDetails from 'app/routes/buyerDetails';
import Promotions from 'app/routes/promotions/routes/promotionList';
import ScheduleNotification from 'app/routes/scheduleNotification/routes/scheduleListing';
import ProductCategoryList from 'app/routes/productCategories/routes/productCategoryList';
import ManufacturerList from 'app/routes/manufacturers/routes/manufacturerList';
import GstValuesList from 'app/routes/gstValues/routes/gstValuesList';
import TicketsTab from 'app/routes/ticket/routes/TicketsTab';
import TicketDetails from 'app/routes/ticketDetails/TicketDetails';
import ProductTypeList from 'app/routes/productTypes/routes/productTypeList';
import SellerList from 'app/routes/sellers/sellerList';
import SellerDetails from 'app/routes/sellerDetails';
import StaffList from 'app/routes/adminStaff/routes/staffList';
import Groups from 'app/routes/adminGroups/routes/groups';
import MedbankList from 'app/routes/medbank/routes/medbankList';
import ProductRequestTabs from 'app/routes/productRequests/routes/productRequestTabs';
import SettlemetsTab from 'app/routes/settlement/routes/SettlementTab';
import SettlementDetailsTab from 'app/routes/settlementDetails/routes/SettlementDetailsTab';
import Transaction from 'app/routes/transaction/routes/AllBuyers';
import TransactionDetails from 'app/routes/transaction/routes/Transaction';
import OrdersTab from 'app/routes/orders/routes/OrdersTab';
import TrackOrder from 'app/routes/trackOrder/routes/TrackOrder';
import OrderDetails from './app/routes/orderDetails/routes/OrderDetails';
import Manifest from 'app/routes/manifest';
import BulkRequests from 'app/routes/bulkRequests';
import ShortBook from 'app/routes/Shortbook';
import Performance from 'app/routes/sellerPerformance'
import PerformanceOrders from 'app/routes/sellerPerformance/SellerOrderList'
import OutOfStock from 'app/routes/outOfStock';
import SellerSalesReport from 'app/routes/sellerSalesReport';
import ChangePassword from 'app/routes/changePassword';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MUIDataTableFilter: {
      styleOverrides: {
        reset: {
          display: "none"
        }
      }
    }
  }
});

export const store = configureStore();

const MainApp = () => {
  const authUser = !!localStorage.getItem('admin_token');

  return (<Provider store={store}>
    <BrowserRouter>
      <NotificationContainer />
      <ThemeProvider theme={theme}>
        <Routes>
          {!authUser && <Route path='/signin' element={<AdminSignIn />} />}
          <Route path='/signup' element={<SignUp />} />
          <Route path='/verifyToken' element={<VerifyToken />} />
          <Route path='/forgotPassword' element={<ForgotPassword />} />
          <Route path='/underMaintenance' element={<UnderMaintenance />} />
          {authUser && <>
            <Route path='/admin/dashboard' element={<Dashboard />} />
            <Route path='/admin/inventory' element={<Inventory />} />
            <Route path={`/admin/buyers/details/:id`} element={<BuyerDetails />} />
            <Route path='/admin/buyers' element={<BuyerList />} />
            <Route path={`/admin/promotions`} element={<Promotions />} />
            <Route path={`/admin/scheduleNotification`} element={<ScheduleNotification />} />
            <Route path={`/admin/productCategories`} element={<ProductCategoryList />} />
            <Route path={`/admin/manufacturers`} element={<ManufacturerList />} />
            <Route path={`/admin/gstValues`} element={<GstValuesList />} />
            <Route path={`/admin/tickets/ticketDetails/:id`} element={<TicketDetails />} />
            <Route path={`/admin/tickets`} element={<TicketsTab />} />
            <Route path={`/admin/productTypes`} element={<ProductTypeList />} />
            <Route path={`/admin/sellers/details/:id`} element={<SellerDetails />} />
            <Route path={`/admin/sellers`} element={<SellerList />} />
            <Route path={`/admin/staffs`} element={<StaffList />} />
            <Route path={`/admin/groups`} element={<Groups />} />
            <Route path={`/admin/medbank`} element={<MedbankList />} />
            <Route path={`/admin/productRequests`} element={<ProductRequestTabs />} />
            <Route path={`/admin/settlement/settlementDetails/:id`} element={<SettlementDetailsTab />} />
            <Route path={`/admin/settlement`} element={<SettlemetsTab />} />
            <Route path={`/admin/transaction/details/:id`} element={<TransactionDetails />} />
            <Route path={`/admin/transaction`} element={<Transaction />} />
            <Route path={`/admin/orders/orderDetails/:id`} element={<OrderDetails />} />
            <Route path={`/admin/orders/trackOrder/:id`} element={<TrackOrder />} />
            <Route path={`/admin/orders`} element={<OrdersTab />} />
            <Route path={`/admin/manifest`} element={<Manifest />} />
            <Route path={`/admin/bulkrequests`} element={<BulkRequests />} />
            <Route path={`/admin/shortBook`} element={<ShortBook />} />
            <Route path={`/admin/sellerPerformance/orders`} element={<PerformanceOrders />} />
            <Route path={`/admin/sellerPerformance`} element={<Performance />} />
            <Route path={`/admin/OutOfStock`} element={<OutOfStock />} />
            <Route path={`/admin/salesReport`} element={<SellerSalesReport />} />
            <Route path={`/admin/change-Password`} element={<ChangePassword />} />
          </>}
          <Route
            path="*"
            element={authUser ? <Navigate to="/admin/dashboard" replace /> : <Navigate to="/signin" replace />}
          />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>)
}


export default MainApp;