import React, { Component } from 'react'
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';

const renderReactStrapField = ({classNameField='', input, hide, hidden,disabled, label, type, rows, props, meta: {asyncValidate, touched, error, warning } }) => {
    if(input.name !== undefined && input.name === "otherName") {
        
    }
    return <FormGroup style={{marginTop:hidden?'-37px':'0px',display:hide===true?'none':''}}  className={asyncValidate ? `async-validating ${classNameField}` : classNameField}>
        {input.name == 'otp' || input.name == 'narration1' ? '' : <Label className={props?.marginBottomZero?'mb-0':'' }for={label}>{label}</Label>}
        <Input  disabled={disabled} hidden={hidden} invalid={(touched && error) || (warning)} type={type} rows={rows} {...props} {...input}  />
        <FormFeedback>{error}</FormFeedback>
    </FormGroup>
}
export default renderReactStrapField