import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { FormGroup } from '@mui/material';
import { Col, Row, Form, Badge, Label, Input } from 'reactstrap';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { getAdminmonthlySalesStats } from 'actions/admin';
import { connect } from 'react-redux';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

let date = new Date();
let monthNamesForHEad = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

class MonthlySalesStatistic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: [],
      open: false,
      anchorEl: null,
      year: date.getFullYear(), // date.getMonth() <= 2 ? date.getFullYear() - 1 : date.getFullYear(),
      month: date.getMonth() + 1,
      renderYear: date.getFullYear(), //date.getMonth() <= 2 ? date.getFullYear() - 1 : date.getFullYear()
      renderMonth: monthNamesForHEad[Number(date.getMonth())],
      yearList: []
    };

  }



  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleCloseMenu = (e) => {
    e.preventDefault();
    this.setState({
      anchorEl: null
    })
  }
  handleClose = async (e) => {
    e.preventDefault();
    this.setState({ anchorEl: null, year: new Date().getFullYear(), renderYear: new Date().getFullYear() })
    this.setState({ anchorEl: null, renderMonth: monthNamesForHEad[Number(date.getMonth())], month:( (new Date().getMonth())+ 1) })
    this.props.renderYearf({ renderYear: '' })
    this.props.renderMonthf({ renderMonth: '' })
    let data = {
      year: date.getFullYear(),
      month: date.getMonth()+1
    }
    this.props.getAdminmonthlySalesStats(data)

  }

  handleApplyFilter = async (e) => {
    e.preventDefault();
    let data = { year: this.state.year, month: Number(this.state.month) }
    this.setState({ anchorEl: null, renderYear: Number(this.state.year), renderMonth: monthNamesForHEad[Number(this.state.month) - 1] })
    this.props.renderYearf({ renderYear: '' })
    this.props.renderMonthf({ renderMonth: '' })
    this.props.getAdminmonthlySalesStats(data)

  }


  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value })
    this.props.renderYearf({ renderYear: '' })
    this.props.renderMonthf({ renderMonth: '' })
  }
  componentDidMount = async () => {
    let data = {
      year: this.state.year,
      month: this.state.month
    }


    let yearArr = [];
    for (let i = date.getFullYear(); i >= 2019; i--) {
      yearArr.push({ year: i, string: `${i}` });
    }
    this.setState({ yearList: yearArr })
    for (let i = date.getMonth(); i >= 2019; i--) {
      yearArr.push({ year: i, string: `${i}-${i + 1}` });
    }
    this.setState({ yearList: yearArr })

    this.props.getAdminmonthlySalesStats(data)

  }

  render() {
    const { graphData, year, anchorEl, yearList, renderYear, renderMonth } = this.state;
    let data = [
      { Month: '1', Orders: 0, Amount: 0 },
      { Month: '2', Orders: 0, Amount: 0 },
      { Month: '3', Orders: 0, Amount: 0 },
      { Month: '4', Orders: 0, Amount: 0 },
      { Month: '5', Orders: 0, Amount: 0 },
      { Month: '6', Orders: 0, Amount: 0 },
      { Month: '7', Orders: 0, Amount: 0 },
      { Month: '8', Orders: 0, Amount: 0 },
      { Month: '9', Orders: 0, Amount: 0 },
      { Month: '10', Orders: 0, Amount: 0 },
      { Month: '11', Orders: 0, Amount: 0 },
      { Month: '12', Orders: 0, Amount: 0 },
      { Month: '13', Orders: 0, Amount: 0 },
      { Month: '14', Orders: 0, Amount: 0 },
      { Month: '15', Orders: 0, Amount: 0 },
      { Month: '16', Orders: 0, Amount: 0 },
      { Month: '17', Orders: 0, Amount: 0 },
      { Month: '18', Orders: 0, Amount: 0 },
      { Month: '19', Orders: 0, Amount: 0 },
      { Month: '20', Orders: 0, Amount: 0 },
      { Month: '21', Orders: 0, Amount: 0 },
      { Month: '22', Orders: 0, Amount: 0 },
      { Month: '23', Orders: 0, Amount: 0 },
      { Month: '24', Orders: 0, Amount: 0 },
      { Month: '25', Orders: 0, Amount: 0 },
      { Month: '26', Orders: 0, Amount: 0 },
      { Month: '27', Orders: 0, Amount: 0 },
      { Month: '28', Orders: 0, Amount: 0 },
      { Month: '29', Orders: 0, Amount: 0 },
      { Month: '30', Orders: 0, Amount: 0 },
      { Month: '31', Orders: 0, Amount: 0 },
    ];

    const monthNames = [
      { label: "January", value: 1 },
      { label: "February", value: 2 },
      { label: "March", value: 3 },
      { label: "April", value: 4 },
      { label: "May", value: 5 },
      { label: "June", value: 6 },
      { label: "July", value: 7 },
      { label: "August", value: 8 },
      { label: "September", value: 9 },
      { label: "October", value: 10 },
      { label: "November", value: 11 },
      { label: "December", value: 12 }, ,
    ];

    (this.props.monthlysaleStats && this.props.monthlysaleStats.length > 0 ? this.props.monthlysaleStats : graphData && graphData).sort(function (a, b) {
      return parseFloat(a._id) - parseFloat(b._id);
    }).map((dataOne, index) => {
      if (dataOne._id == '1') {
        data[0] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '2') {
        data[1] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '3') {
        data[2] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '4') {
        data[3] = { Month: dataOne._id, Orders: dataOne.orders, Amount: ((dataOne.amount).toFixed(2)) }
      }
      if (dataOne._id == '5') {
        data[4] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '6') {
        data[5] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '7') {
        data[6] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '8') {
        data[7] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '9') {
        data[8] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '10') {
        data[9] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '11') {
        data[10] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '12') {
        data[11] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '13') {
        data[12] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '14') {
        data[13] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '15') {
        data[14] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '16') {
        data[15] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '17') {
        data[16] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '18') {
        data[17] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '19') {
        data[18] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '20') {
        data[19] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '21') {
        data[20] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '22') {
        data[21] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '23') {
        data[22] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '24') {
        data[23] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '25') {
        data[24] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '26') {
        data[25] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '27') {
        data[26] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '28') {
        data[27] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '29') {
        data[28] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '30') {
        data[29] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '31') {
        data[30] = { Month: dataOne._id, Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
    })
    //  toLocaleString('en-IN')
    let n1;
    let maxValue;
    (this.props.monthlysaleStats && this.props.monthlysaleStats.length > 0 ? this.props.monthlysaleStats : graphData && graphData).sort(function (a, b) {
      return parseFloat(a.amount) - parseFloat(b.amount);
    }).map((dataOne, index) => {
      n1 = dataOne.amount / 2;
      maxValue = parseInt(dataOne.amount + n1);
    })
    return (
      <React.Fragment>
        <div className="jr-card">
          <div className="row">

            <div className="jr-card-header d-flex align-items-center col-6 col-sm-6 col-md-6 col-lg-6">
              <h3 className="mb-0">Sales Statistics For {renderMonth} - {renderYear}</h3>
            </div>
            <div className="jr-card-header d-flex col-6 col-sm-6 col-md-6 col-lg-6">
              <IconButton className="icon-btn" style={{ marginRight: "0px", marginLeft: "auto" }} onClick={(e) => this.handleClick(e)}>
                <i className="zmdi  zmdi-filter-list" />
              </IconButton>
            </div>

          </div>

          <div className="row">
            <div className="col-lg-12 col-12 mb-12 mb-lg-12">
              <ResponsiveContainer width="100%" height={350}>


                <BarChart
                  width={500}
                  height={350}
                  data={data}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Month" />
                  <YAxis yAxisId="left" domain={[0, 1000]} orientation="left" stroke="#193298" />
                  <YAxis yAxisId="right" domain={[0, maxValue]} orientation="right" stroke="#f0a500" />
                  <Tooltip formatter={(value) => new Intl.NumberFormat('en-IN').format(value)} />
                  <Legend />
                  <Bar yAxisId="left" dataKey="Orders" fill="#193298" />
                  <Bar yAxisId="right" dataKey="Amount" fill="#f0a500" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={(e) => this.handleCloseMenu(e)}
        >
          <MenuItem className="filterBody">
            <div className="flex">
              <Row form>
                <Col sm={12} md={12} xl={12} xs={12} lg={12}>
                  <FormGroup>
                    <Label for="exampleSelect">Select Year</Label>
                    <Input type="select" value={this.props.renderYear ? this.props.renderYear : year} onChange={(e) => this.handleChange(e, 'year')} name="year" id="year">
                      {
                        yearList.map((value, key) => {
                          return <option value={parseInt(value.year)} >{value.string}</option>
                        })
                      }
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12} xl={12} xs={12} lg={12}>
                  <FormGroup>
                    <Label for="exampleSelect">Select Month</Label>
                    <Input type="select" value={this.state.month} onChange={(e) => this.handleChange(e, 'month')} name="month" id="month">
                      {
                        monthNames.map((val) => {
                          return <option value={parseInt(val.value)} >{val.label}</option>
                        })
                      }
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <div style={{ paddingTop: 15 }} >
                <Button variant="contained" className='filterButton' onClick={(e) => this.handleApplyFilter(e)} color='primary'>Apply Filter</Button>
                <Button variant="contained" className='filterButton' onClick={(e) => this.handleClose(e)} color='primary'>Reset Filter</Button>
              </div>
            </div>
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
};
const mapStateToProps = ({ admin }) => {
  const { monthlysaleStats } = admin;
  return { monthlysaleStats }
};
export default connect(mapStateToProps, { getAdminmonthlySalesStats })(MonthlySalesStatistic);
