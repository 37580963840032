import React, {Component} from 'react';
import { Popover } from 'reactstrap';

class Pop extends Component{
 constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { popoverOpen: false } 
 };
 
 toggle(){
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }
 render(){
    let { data } = this.props;

     return (
        <>
            <i class="zmdi zmdi-info" variant="contained" color="primary" id={"ust" + data.ticketNo} onClick={this.toggle} />
            <Popover placement={'bottom'} isOpen={this.state.popoverOpen} trigger="legacy" target={"ust" + data.ticketNo} toggle={this.toggle} >
               Order Id: {data?.order?.orderNo},<br></br>Buyer: {data?.buyer?.buyerName}
            </Popover>
        </>
     )
 }
}

export default Pop;