import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { FormGroup, Label, Input } from 'reactstrap';
import ReactStrapTextField from '../../components/ReactStrapTextField';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';

function ViewProduct(props) {
  const { value, sellerData } = props;
  return (
    <React.Fragment>
      <form>
        <Row>
          <Col xs={12} md={4} sm={4} xl={4} lg={4}>
            <Label>Product Name</Label>
            <Input
              id="name"
              name="name"
              type="text"
              component={ReactStrapTextField}
              label={"Name"}
              readOnly
              value={value && value.name}
            />
          </Col>
          <Col xs={12} md={4} sm={4} xl={4} lg={4}>
            <Label>Chemical Combination</Label>
            <Input
              id="chemicalComb"
              name="chemicalComb"
              type="text"
              component={ReactStrapTextField}
              label={"Chemical Combination"}
              readOnly
              value={value && value.chem_combination}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={4} sm={4} xl={4} lg={4}>
            <Label>Surcharge</Label>
            <Input
              id="surcharge"
              name="surcharge"
              type="text"
              component={ReactStrapTextField}
              label={"Surcharge"}
              readOnly
              value={value && value.surcharge ? value.surcharge : 0}
            />
          </Col>
          <Col xs={6} md={4} sm={4} xl={4} lg={4}>
            <Label>Product Category</Label>
            <Input
              id="category"
              name="category"
              type="text"
              component={ReactStrapTextField}
              label={"Product Category"}
              readOnly
              value={value && value.ProductCategory && value.ProductCategory.name}
            />
          </Col>
          <Col xs={6} md={4} sm={4} xl={4} lg={4}>
            <Label>Company Name</Label>
            <Input
              id="companyName"
              name="companyName"
              type="text"
              component={ReactStrapTextField}
              label={"Company Name"}
              readOnly
              value={value && value.Company && value.Company.name}
            />
          </Col>
          <Col xs={6} md={4} sm={4} xl={4} lg={4}>
            <Label>GST</Label>
            <Input
              id="gst"
              name="gst"
              type="text"
              component={ReactStrapTextField}
              label={"GST"}
              readOnly
              value={value && value.GST && value.GST.value}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={3} sm={3} xl={3} lg={3}>
            <Label>Medicine Type</Label>
            <Input
              id="medType"
              name="medType"
              type="text"
              component={ReactStrapTextField}
              label={"Medicine Type"}
              readOnly
              value={value && value.MedicineType && value.MedicineType.name}
            />
          </Col>
          <Col xs={6} md={3} sm={3} xl={3} lg={3}>
            <Label>Country Of Origin</Label>
            <Input
              id="country"
              name="country"
              type="text"
              component={ReactStrapTextField}
              label={"Country Of Origin"}
              readOnly
              value={value && value.country_of_origin}
            />
          </Col>
          <Col xs={6} md={3} sm={3} xl={3} lg={3}>
            <Label>Prepaid Only</Label>
            <Input
              id="prepaid"
              name="prepaid"
              type="text"
              component={ReactStrapTextField}
              label={"Prepaid Only"}
              readOnly
              value={value && value.isPrepaid ? value.isPrepaid : "FALSE"}
            />
          </Col>
          <Col xs={6} md={3} sm={3} xl={3} lg={3}>
            <Label>Type</Label>
            <Input
              id="type"
              name="type"
              type="text"
              component={ReactStrapTextField}
              label={"Type"}
              readOnly
              value={value && value.Type && value.Type.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Product description</Label>
            <Input
              name="type"
              type="text"
              component={ReactStrapTextField}
              label={"description"}
              readOnly
              value={value && value.description}
              id="description"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="Filters">Sellers</Label>
              <Autocomplete
                limitTags={4}
                multiple
                disabled={true}
                id="checkboxes-tags-demo"
                options={sellerData && sellerData}
                disableCloseOnSelect
                getOptionLabel={(option) => option.company_name}
                defaultValue={value && value.sellerList && value.sellerList}
                renderOption={(option) => (
                  <React.Fragment>
                    {option?.company_name && option?.company_name}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search"
                  />
                )}
                size="small"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Minimum order amount</Label>
            <Input
              name="type"
              type="text"
              component={ReactStrapTextField}
              label={"Minimum order amount"}
              readOnly
              value={value && value.minOrder}
              id="minOrder"
            />
          </Col>
          <Col>
            <Label>Discount</Label>
            <Input
              name="type"
              type="text"
              component={ReactStrapTextField}
              label={"Discount (Comma seperated values only)"}
              readOnly
              value={value && value.discount && value.discount.toString()}
              id="discount"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Discount cap(%)</Label>
            <Input
              name="type"
              type="number"
              component={ReactStrapTextField}
              label={"Discount cap(%)"}
              readOnly
              value={value && value.discountCap}
              id="discountCap"
            />
          </Col>
          <Col>
            <Label>Short expiry month</Label>
            <Input
              name="type"
              type="text"
              component={ReactStrapTextField}
              label={"Short expiry month"}
              readOnly
              value={value && value.shortExpiry ? value.shortExpiry : 9}
              id="shortExpiry"
            />
          </Col>
          <Col>
            <Label>Short expiry surcharge</Label>
            <Input
              name="type"
              type="text"
              component={ReactStrapTextField}
              label={"Short expiry surcharge"}
              readOnly
              value={value && value.shortSurcharge ? value.shortSurcharge: 0}
              id="shortSurcharge"
            />
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
}

export default ViewProduct 