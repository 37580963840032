import React from 'react';
import { Col, Row } from 'reactstrap';
import { Label, Input } from 'reactstrap';
import ReactStrapTextField from '../../components/ReactStrapTextField';

function ViewProduct (props) {
    const { value } = props;
    return (
      <React.Fragment>


        <form>
          <Row>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <Label>Product Name</Label>
              <Input id="name" name="name" type="text"
                component={ReactStrapTextField} label={"Name"}
                readOnly
                value={value && value.name}
              />


            </Col>
            <Col xs={12} md={12} sm={12} xl={6} lg={6} style={{ paddingBottom: '15px' }}>
              <Label>Chemical Combination</Label>
              <Input id="status" name="status" type="text"
                component={ReactStrapTextField} label={"Status"}
                readOnly
                value={value && value.chemicalCombination}
              />

            </Col>
          </Row>
          
          <Row>
            <Col xs={12} md={12} sm={12} xl={6} lg={6} style={{ paddingBottom: '15px' }}>
              <Label>Product Type</Label>
              <Input id="status" name="status" type="text"
                component={ReactStrapTextField} label={"Status"}
                readOnly
                value={value && value.productType.name}
              />

            </Col>
            <Col xs={12} md={12} sm={12} xl={6} lg={6} style={{ paddingBottom: '15px' }}>
              <Label>Product Category</Label>
              <Input id="status" name="status" type="text"
                component={ReactStrapTextField} label={"Status"}
                readOnly
                value={value && value.productCategory.name}
              />

            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} sm={12} xl={6} lg={6} style={{ paddingBottom: '15px' }}>
              <Label>Gst %</Label>
              <Input id="status" name="status" type="text"
                component={ReactStrapTextField} label={"Status"}
                readOnly
                value={value && value.gst.value}
              />

            </Col>
            <Col xs={12} md={12} sm={12} xl={6} lg={6} style={{ paddingBottom: '15px' }}>
              <Label>Company Name</Label>
              <Input id="companyName" name="companyName" type="text"
                component={ReactStrapTextField} label={"Company Name"}
                readOnly
                value={value && value.companyName}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <Label>Seller</Label>
              <Input id="status" name="status" type="text"
                component={ReactStrapTextField} label={"Status"}
                readOnly
                value={value && value.addedBy.company_name}
              />

            </Col>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <Label>Status</Label>
              <Input id="companyName" name="companyName" type="text"
                component={ReactStrapTextField} label={"Company Name"}
                readOnly
                value={value && value.status}
              />
            </Col>
          </Row>
        </form>

      </React.Fragment>
    );
  }

export default ViewProduct