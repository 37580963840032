import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ContainerHeader from '../../../../components/ContainerHeader';
import { Col, Row, Label, Input } from 'reactstrap';
import { FormGroup } from '@mui/material';
import AddPromotions from '../component/addPromotions';
import EditPromotions from '../component/editPromotions';
import DeleteInventory from 'app/routes/adminGroups/routes/component/deleteInventory';
import ButtonGroup from '@mui/material/ButtonGroup';
import customHead from 'constants/customHead'
import { getPromotionsList, getChangePromotion } from 'actions/admin';
import { connect } from 'react-redux'
import AdminContainer from "containers/AdminContainer";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Field, Form } from 'react-final-form';
import appConfig from '../../../../constants/config';
import { NotificationManager } from 'react-notifications';
import { required } from 'constants/validations';
import TextArea from 'components/TextArea';
import CircularProgress from '@mui/material/CircularProgress';

const columns = [
  {
    name: "image",
    label: "Image",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "title",
    label: "Title",
    options: {
      filter: false,
      sort: false,
      // search: true,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "Redirect URL",
    label: "Redirect URL",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "isActive",
    label: "Active",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  // {
  //   name: "quantity",
  //   label: "Quantity",
  //   options: {
  //     filter: false,
  //     sort: false,
  //     customHeadRender: (o, updateDirection) => {
  //       return customHead(o, updateDirection);
  //     }
  //   }
  // },
  {
    name: "action",
    label: "Action",
    options: {
      filter: true,
      filterType: 'custom',
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      },
      customFilterListRender: v => {
        return false;
      },
      filterOptions: {
        names: [],
        logic() {

        },
        display: () => (
          <React.Fragment>
            <Row form>
              <Col md={6} xl={6} xs={6} lg={6}>
                <FormGroup>
                  <Label for="exampleSelect">Select Month</Label>
                  <Input type="select" name="select" id="exampleSelect">
                    <option>January</option>
                    <option>February</option>
                    <option>March</option>
                    <option>April</option>
                    <option>May</option>
                    <option>June</option>
                    <option>July</option>
                    <option>August</option>
                    <option>September</option>
                    <option>October</option>
                    <option>November</option>
                    <option>December</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6} xl={6} xs={6} lg={6}>
                <FormGroup>
                  <Label for="exampleSelect">Select Month</Label>
                  <Input type="select" name="select" id="exampleSelect">
                    <option>2015</option>
                    <option>2016</option>
                    <option>2017</option>
                    <option>2018</option>
                    <option>2019</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <div style={{ paddingTop: 15 }} >
              <Button variant="contained" className='filterButton' color='primary'>Apply Filter</Button>
              <Button variant="contained" className='filterButton' color='primary'>Reset Filter</Button>
            </div>
          </React.Fragment>
        ),
        onFilterChange: () => {
          console.log('onFilterChange')
        }
      },
    },
  }
];

const options = {
  filterType: 'dropdown',
  print: false,
  filter: false,
  download: false,
  selectableRows: 'none',
  selectableRowsOnClick: false,
  selectableRowsHeader: false,
  responsive: 'scrollMaxHeight',
  search: true,
  viewColumns: false,
  rowHover: false,
  rowsPerPageOptions: [],
  customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
    return <div className="p-4 d-flex justify-content-end">
      <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count} />
      <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
    </div>
  }
};


function Promotions(props) {

  const [state, setState] = useState({
    edit: false,
    hide: false,
    delete: false,
    add: false,
    val: '',
    selectedData: {},
    checkedA: false,
  })
  const [checkedA, setCheckedA] = useState(false)
  const [marquee, setMarquee] = useState('')
  const [dialogopen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const button = (data) => (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button className={'text-primary'} onClick={() => handleClick('edit', data)} > Edit</Button>
      <Button className={'text-danger'} onClick={() => handleClick('delete', data)}> Delete</Button>
    </ButtonGroup>
  )
  const handleClick = (key, value) => {
    setState({ [key]: !state[key], selectedData: value })
  }

  useEffect(() => {
    if (props.promotionsList !== checkedA) {
      setCheckedA(props.promotionsList ? props.promotionsList.isActive : false);
    }
  }, [props.promotionsList, checkedA]);

  const getMarquee = async () => {
    try {
      const { data } = await axios.get(`${appConfig.baseUrl}promotions/get-marquee`);
      if (data && data.marqueeData && data.marqueeData.marquee) {
        setMarquee(data.marqueeData.marquee);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    props.getPromotionsList({ page: 1, perPage: 50, type: 'admin' });
    getMarquee();
  }, [])

  const handleActive = (name, dataOne) => {
    let data;
    if (name === 'enable') {
      data = {
        id: dataOne._id,
        active: true
      }
      props.getChangePromotion({ data })
    } else if (name === 'disable') {
      data = {
        id: dataOne._id,
        active: false
      }
      props.getChangePromotion({ data })
    }
  }

  const { promotionsList } = props;
  let { selectedData } = state;

  let data = [];
  promotionsList && promotionsList.detail && promotionsList.detail.map((dataOne, index) => {
    data.push(
      {
        'image': <Avatar src={dataOne.image_banner} />,
        'title': dataOne.name,
        'Redirect URL': dataOne.redirect_url,
        'isActive':
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            {dataOne.isActive === false ?
              <Button className={'text-success'} onClick={(e) => handleActive('enable', dataOne)}>Enable</Button>
              :
              <Button className={'text-danger'} onClick={(e) => handleActive('disable', dataOne)}>Disable</Button>
            }
          </ButtonGroup>,
        'action': button(dataOne, index)
      }
    )
  })

  const handleRequestClose = () => {
    {
      setDialogOpen(false);
    }
  }

  const onSubmit = async (values) => {
    const { marqueeValue } = values;
    setLoading(true)
    try {
      const response = await axios.post(`${appConfig.baseUrl}promotions/edit-marquee`, { marquee: marqueeValue, id: marquee._id }, {
        headers: {
          'token': localStorage.getItem('admin_token')
        }
      });
      if (response.data.error) {
        NotificationManager.error(response.data.title);
      }
      else {
        NotificationManager.success(response.data.title);
        handleRequestClose();
        getMarquee();
      }
      setLoading(false)
    } catch (error) {
      console.error('Error:', error);
      NotificationManager.error('Something went wrong, Please try again');
      handleRequestClose();
    }
  };

  return (
    <AdminContainer>
      <React.Fragment>
        <ContainerHeader match={props.match} title={"Promotions"} setDialogOpen={setDialogOpen} />
        <Dialog open={dialogopen} onClose={handleRequestClose}
          fullWidth={true}
          maxWidth={'md'}>
          <Form
            onSubmit={onSubmit}
            initialValues={{ marqueeValue: marquee ? marquee : '' }}
            render={({ handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle>
                  Add Marquee
                </DialogTitle>
                <DialogContent>
                  <Row>
                    <Col xs={12}>
                      <Field
                        id="marqueeValue" name="marqueeValue" type="text"
                        component={TextArea} label={''}
                        validate={required}
                        multiline
                        rows={4}
                      />
                    </Col>
                  </Row>
                </DialogContent>
                <DialogActions className="pr-4">
                  <Button onClick={handleRequestClose} color='secondary' >
                    Cancel
                  </Button>
                  <Button type='submit' color='primary' disabled={loading}
                  >
                    Update
                  </Button>
                </DialogActions>
              </form>
            )} />
        </Dialog>
        {props.loading ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}
        {
          props.loading === true ?
            null :
            <MUIDataTable
              title={<Button className={'text-primary'} selectedData={selectedData} onClick={() => handleClick('add')}> Add Promotions +</Button>}
              data={data}
              columns={columns}
              options={options}
            />
        }
        <EditPromotions buttonType={'edit'} edit={state.edit} selectedData={selectedData} title={'Edit Promotion'} handleClick={handleClick} />
        <AddPromotions buttonType={'add'} add={state.add} selectedData={selectedData} title={'Add Promotion'} handleClick={handleClick} />
        <DeleteInventory buttonType={'delete'} delete={state.delete} deleteFor={'promotions'} selectedData={selectedData} handleClick={handleClick} />
      </React.Fragment>
    </AdminContainer>
  );
}

const mapStateToProps = ({ admin }) => {
  const { promotionsList, loading } = admin;
  return { promotionsList, loading }
};

Promotions = connect(
  mapStateToProps,
  {
    getPromotionsList,
    getChangePromotion
  }               // bind account loading action creator
)(Promotions)

export default Promotions;