import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@mui/material/Button";
import ContainerHeader from "../../../../components/ContainerHeader";
import ButtonGroup from "@mui/material/ButtonGroup";
import customHead from "constants/customHead";
import AddBank from "../component/addBank";
import { getProductList, getDeleteProduct } from "actions/admin";
import CircularProgress from "@mui/material/CircularProgress";
import AxiosRequest from "sagas/axiosRequest";
import { connect } from "react-redux";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import AppConfig from "constants/config";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import AdminContainer from "containers/AdminContainer";
import ViewProduct from "./viewProduct";
import EditBank from "../component/editBank";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

const columns = [
  {
    name: "title",
    label: "Product Name",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      },
    },
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      },
    },
  },
];
function StaffList(props) {
  const [state, setState] = useState({
    page: 0,
    perPage: 50,
    edit: false,
    permission: false,
    delete: false,
    add: false,
    addGroup: false,
    images: false,
    image: false,
    searchedText: "",
    showFilter: false,
    mediCategory: "",
    view: false,
    values: {},
    deleteValue: "",
    sellerData: "",
    viewloader: false
  });

  const getProdDetails = async (id) => {
    setState((prev) => ({ ...prev, viewloader: true }));
    try {
      const result = await axios.post(`${AppConfig.baseUrl}product/productDetails`, { prodID: id }, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('admin_token')
        },
      });
      if (result.data.error === false) {
        const resultobj = result && result.data ? { ...result.data } : {}
        console.log(resultobj, "types && types.")
        setState((prev) => ({ ...prev, ...resultobj}));
      } else {
        NotificationManager.error("Error while fetching details !");
      }
    } catch (error) {
      console.log("error catch", error);
      NotificationManager.error("Error while fetching details !");
    }
    setState((prev) => ({ ...prev, viewloader: false }));
  };

  const button = (dataOne) => (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button className={"text-primary"} onClick={() => handleView(dataOne)}>
        {" "}
        View{" "}
      </Button>
      <Button
        className={"text-warning"}
        onClick={() => handleEdit(dataOne)}
      >
        {" "}
        Edit{" "}
      </Button>
      <Button className={"text-success"} onClick={() => handleReport(dataOne)}>
        {" "}
        Report{" "}
      </Button>
      <Button
        className={"text-danger"}
        onClick={() => handleDelete("delete", dataOne._id)}
      >
        {" "}
        Delete
      </Button>
    </ButtonGroup>
  );

  const handleView = (data) => {
    getProdDetails(data._id);
    setState((prev) => ({ ...prev, view: true }));
  };

  const handleClose = () => {
    setState((prev) => ({ ...prev, view: false, value: "" }));
  };

  const handleReport = async (data) => {
    await axios
      .request({
        url: `${AppConfig.baseUrl}reports/generateReports`,
        data: { product: data.name },
        method: "POST",
        responseType: "blob",
      })
      .then(({ data }) => {
        if (data.size != 0) {
          NotificationManager.success("Report generated successfully !");
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "report.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          NotificationManager.error("Error while generating report !");
        }
      })
      .catch((error) => {
        console.log("error catch", error);
        NotificationManager.error("Error while generating report !");
      });
  };

  const handleClick = (key, data) => {
    setState((prev) => ({
      ...prev,
      [key]: !state[key],
      value: data ? data : "",
    }));
  };
  const handleEdit = (data) => {
    getProdDetails(data._id);
    setState((prev) => ({ ...prev, edit: true }));
  }

  const handleDelete = (key, data) => {
    setState((prev) => ({ ...prev, delete: true, deleteValue: data }));
  };

  const handleDeleteConfirm = async () => {
    await props.getDeleteProduct({ id: state.deleteValue });
    props.getProductList({
      page: state.page + 1,
      perPage: state.perPage,
      filter: state.searchedText
        ? { name: state.searchedText.toUpperCase() }
        : {},
    });
    setState((prev) => ({ ...prev, delete: false }));
  };

  const handleDeleteClose = () => {
    setState((prev) => ({ ...prev, delete: false, deleteValue: "" }));
  };

  const handleAddGroupClick = (key) => {
    setState((prev) => ({ ...prev, [key]: !state[key] }));
  };

  const changePage = (page) => {
    let pages = page;
    props.getProductList({
      page: pages + 1,
      perPage: state.perPage,
      filter: state.searchedText
        ? { name: state.searchedText.toUpperCase() }
        : {},
      history: props.history,
    });

    setState((prev) => ({ ...prev, page }));
  };

  const handleSearch = (searchText) => {
    props.getProductList({
      page: 1,
      perPage: state.perPage,
      filter: searchText ? { name: searchText.toUpperCase() } : {},
      history: props.history,
    });

    setState((prev) => ({ ...prev, searchedText: searchText, page: 0 }));
  };
  const handleSearched = (searchText) => {
    setState((prev) => ({
      ...prev,
      searchedText: "",
      page: 0,
    }));
    props.getProductList({
      page: 1,
      perPage: state.perPage,
      filter: {},
      history: props.history,
    });
  };

  const FetchApi = async () => {
    await props.getProductList({
      page: 1,
      perPage: 50,
      filter: {},
    });
    await axios
      .request({
        url: `${AppConfig.baseUrl}medicine/listMediCategory`,
        method: "GET",
        headers: { token: localStorage.getItem("admin_token") },
      })
      .then(({ data }) => {
        if (data.error) {
          NotificationManager.error(
            "Something went wrong in medicine category."
          );
        } else {
          setState((prev) => ({ ...prev, mediCategory: data.data }));
        }
      })
      .catch((error) => { });
    let result = await AxiosRequest.axiosHelperFunc1(
      "get",
      "admin/getSellers",
      "",
      ""
    );
    if (result && result.data.error === false) {
      setState((prev) => ({ ...prev, sellerData: result.data.data }));
    }
  };

  const getProductMetadata = async () => {
    try {
      const result = await axios({
        method: 'get',
        url: `${AppConfig.baseUrl}product/getProductMetadata`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('admin_token')
        }
      });

      if (result.data.error) {
        console.log("error", result.data);
      } else {
        setState((prev) => ({
          ...prev,
          productMetaData : result.data
        }));
      }
    } catch (error) {
      console.log("error catch", error);
    }
  }

  useEffect(() => {
    FetchApi();
    getProductMetadata();
  }, []);

  const { productList } = props;

  let data = [];
  {
    productList &&
      productList.products &&
      productList.products[0] &&
      productList.products[0].data && 
      productList.products[0].data.map((dataOne) => {
        data.push({
          title: dataOne.name,
          action: button(dataOne),
        });
      });
  }

  const options = {
    filterType: "dropdown",
    responsive: "scroll",
    viewColumns: false,
    selectableRows: false,
    rowsPerPage: 50,
    page: state.page,
    fixedHeader: false,
    print: false,
    download: false,
    filter: false,
    sort: false,
    count:
      props.productList && props.productList.products && 
      props.productList.products[0] &&
      props.productList.products[0].metadata && props.productList.products[0].metadata[0] &&
      props.productList.products[0].metadata[0].total,
    serverSide: true,
    server: true,
    selectableRowsOnClick: false,
    selectableRows: "none",
    fixedHeader: false,
    search: true,
    searchText: state.searchedText,
    rowsPerPageOptions: [],
    rangePage: true,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          changePage(tableState.page);
          break;
        case "search":
          onkeypress = (e) => {
            if (e.key == "Enter") {
              handleSearch(tableState.searchText);
            }
          };
          break;
        case "onSearchClose":
          handleSearched(tableState.searchText);
          break;
        case "onFilterDialogClose":
          break;
        case "onFilterDialogOpen":
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count} />
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  };
  
  const { loader } = props;

  return (
    <AdminContainer>
      <React.Fragment>
        <ContainerHeader match={props.match} title={"Products"} />
        {loader ? (
          <div
            className="loader-view"
            style={{
              height:
                props.width >= 1200
                  ? "calc(100vh - 259px)"
                  : "calc(100vh - 238px)",
            }}
          >
            <CircularProgress />
          </div>
        ) : null}
        {loader == true ? null : (
          <MUIDataTable
            title={
              <Button
                className={"text-primary"}
                onClick={() => handleClick("add")}
              >
                {" "}
                Add Product+
              </Button>
            }
            data={data}
            columns={columns}
            options={options}
          />
        )}
          <EditBank
            handleClick={handleClick}
            open={state.edit}
            product={state.productDetail && state.productDetail[0] || {}}
            companies={state.companies || []}
            GST={state.GST || []}
            types={state.types || []}
            categories={state.categories || []}
            mediCategory={state.mediCategory}
            page={state.page}
            perPage={state.perPage}
            searchText={state.searchedText}
            sellerData={state.sellerData}
            viewloader={state.viewloader}
            productMetaData={state.productMetaData}
          />
        {state.add && (
          <AddBank
            buttonType={"add"}
            add={state.add}
            title={"Add Product"}
            handleClick={handleClick}
            dataFromParent={props.productList}
            mediCategory={state.mediCategory}
            sellerData={state.sellerData}
            companies={state.companies || []}
            GST={state.GST || []}
            types={state.types || []}
            categories={state.categories || []}
            productMetaData={state.productMetaData}
          />
        )}

        <Dialog
          open={state.view == true}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle>View Product</DialogTitle>
          <DialogContent>
            {state.viewloader ?
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                <CircularProgress disableShrink />
              </div>
              :
              <ViewProduct value={state.productDetail && state.productDetail[0] || {}} sellerData={state.sellerData} />}
          </DialogContent>
          <DialogActions className="pr-4 pt-0">
            <Button type="submit" color="primary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={state.delete}
          onClose={handleDeleteClose}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle>Delete Product ?</DialogTitle>
          <DialogContent>
            <form noValidate autoComplete="off">
              <DialogContentText className="mt-3">
                Are you sure, you want to delete product ?
              </DialogContentText>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </AdminContainer>
  );
}

const mapStateToProps = ({ admin }) => {
  const { productList, deleteProduct, loader } = admin;
  return { productList, deleteProduct, loader };
};
export default connect(mapStateToProps, { getProductList, getDeleteProduct })(
  StaffList
);
