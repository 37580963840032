// import React, { Component } from 'react';
// // import logo from '../../../../assets/images/default.png'
// import logo from '../assets/images/medimny.png'
// import {Route, Switch, withRouter} from 'react-router-dom';
// import { connect } from 'react-redux';
// import { getPromotionsListing } from 'actions/admin'

// class UnderMaintenance extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {  }
//     }
//     componentDidMount(){
//         this.interval = setInterval(() => {
//             this.props.getPromotionsListing({ history: this.props.history })
//             if(this.props.promotionsList.length > 0 ){
//                 window.location.href = "/";
//             }
//         }, 4000) 
//     }
//     componentWillUnmount() {
//         clearInterval(this.interval);
//       }
//     render() { 
//         console.log('frogmse',this.props)
//         let online = window.navigator.onLine;
//         return ( 
//             online ?
//             <React.Fragment>
//                 <div className="col-xl-8 col-lg-8 signinContainer" >
//                     <div className="jr-card UnderMaintenance" style={{width:'60%'}}>
//                         <div className="animated slideInUpTiny animation-duration-3 ">

//                             <div className="login-header mb-0">
//                             <img src={logo} className="maintenanceImage" style={{width:'30%',marginBottom: '10px'}}/>
//                                 <h2 style={{ textAlign: "center", color: "#5b5b5b"}}>
//                                     We are upgrading our system to enhance user experience, we will be back soon.
//                                 </h2>                                
//                             </div>

//                         </div>
//                     </div>
//                 </div>
//             </React.Fragment>
//             :
//             <React.Fragment>
//                 <div className="col-xl-8 col-lg-8 signinContainer" >
//                     <div className="jr-card UnderMaintenance" style={{width:'60%'}}>
//                         <div className="animated slideInUpTiny animation-duration-3 ">

//                             <div className="login-header mb-0">
//                             <img src={logo} className="maintenanceImage" style={{width:'30%',marginBottom: '10px'}}/>
//                                 <h2 style={{ textAlign: "center", color: "#5b5b5b"}}>
//                                     Check your internet connection & try again.
//                                 </h2>                                
//                             </div>

//                         </div>
//                     </div>
//                 </div>
//             </React.Fragment>

//          );
//     }
// }
// const mapStateToProps = ({ admin }) => {
//     const {  promotionsList } = admin;
//     return { promotionsList }
//   };
// export default connect(mapStateToProps, { getPromotionsListing})(UnderMaintenance);


import React, { useEffect, useRef } from 'react';
import logo from '../assets/images/medimny.png';
import { connect } from 'react-redux';
import { getPromotionsListing } from 'actions/admin';

const UnderMaintenance = (props) => {
  const onlineRef = useRef(window.navigator.onLine);

  useEffect(() => {
    const interval = setInterval(() => {
      props.getPromotionsListing({ history: props.history });
      if (props.promotionsList.length > 0) {
        window.location.href = '/';
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [props]);

  console.log('props', props);

  const online = onlineRef.current;

  return (
    <React.Fragment>
      <div className="col-xl-8 col-lg-8 signinContainer">
        <div className="jr-card UnderMaintenance" style={{ width: '60%' }}>
          <div className="animated slideInUpTiny animation-duration-3 ">
            <div className="login-header mb-0">
              <img src={logo} className="maintenanceImage" style={{ width: '30%', marginBottom: '10px' }} />
              <h2 style={{ textAlign: 'center', color: '#5b5b5b' }}>
                {online ? (
                  'We are upgrading our system to enhance user experience, we will be back soon.'
                ) : (
                  'Check your internet connection & try again.'
                )}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ admin }) => {
  const { promotionsList } = admin;
  return { promotionsList };
};

export default connect(mapStateToProps, { getPromotionsListing })(UnderMaintenance);
