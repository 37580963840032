import AxiosFunction from '../sagas/axiosRequest'
export const required = value => {
  
  return value ? undefined : 'This field is required'
}
export const requiredddd = value => {
  return value ?value.trim()==''?"This field is required":undefined:undefined
}
export const required0 = value => {
 
  // return (value || value === 0)? undefined : 'Please enter number.'
  return (isNaN(value) ? 'Please enter valid number' :(value === undefined || value === '' ) ? 'This field is required' : Number(value) < 0 ? 'Please enter valid number' : '')
}
export const requiredImage= value => {
  
  return value ? undefined : 'Please upload image'
}
export const validatePincode = value=>{
  let pin = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/
 return value? !pin.test(value)?"Invalid pincode":'':'This field is required'
}
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const dateReg = value => {
  // let date = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/
  // let dDate = /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{4}$/
  let dDate =  /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/
 return (value ? !dDate.test(value) ? `Must be DD/MM/YYYY format` : '' : 'This field is required')
}

export const maxLength15 = maxLength(15)
export const maxLength4 = maxLength(4)
export const maxNum = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const maxNum4 = maxNum(4) 
export const number0 = value => value && isNaN(Number(value)) ? 'Must be a number' : Number(value) <= -1 ? 'Value cannot be negative' : Number(value) == 0 ? "Can't be zero" : undefined
export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : Number(value) <= -1 ? 'Value cannot be negative' : undefined
export const numberCanBeNega = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const numberZero = value => value && isNaN(Number(value)) ? 'Must be a number' : Number(value) <= -1 ? 'Value cannot be negative' : ''
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
export const minValue18 = minValue(18)
export const minValue4 = minValue(4)
export const minValue10 = minValue(10)
export const minLength = min => value =>
  value && value.length < min ? `Must contain at least ${min} characters.` : undefined;
export const minLength6 = minLength(6);

export const validatePhone = value => {
  return value !== undefined ? value.length < 10 ? 'Please enter valid Mobile number' : '' : 'This field is required'
}
export const lessThenMrp = (value,allValues,props)=>Number(allValues.PTR)>Number(allValues.MRP)? 
'PTR should not be greater than MRP':''

export const maxPurchase = (value, allValues, props)=>Number(allValues?.minimumOrderQty)>=Number(allValues?.maximumOrderQty)?'':''

export const minLessAvail = (value,allValues,props)=>Number(allValues?.minimumOrderQty)>Number(allValues?.totalQuantity)?'Lot size should not be greater than Total Available Quantity':''

export const maxLessAvail = (value,allValues,props)=>Number(allValues?.maximumOrderQty)>Number(allValues?.totalQuantity)?'Maximum Quantity should not be greater than Total Available Quantity':''

export const maxGreaterMin = (value,allValues,props)=>Number(allValues?.maximumOrderQty) == Number(allValues?.minimumOrderQty)?'':''

export const maxLessMin = (value,allValues,props)=>Number(allValues?.maximumOrderQty) < Number(allValues?.minimumOrderQty) ?
'Maximum Quantity should not be less than Lot size':''

export const adjustmentAmtS = (value,allValues,props)=> ( allValues.ogInvoiceAmt && Number(allValues.sellerAdjustedAmt) >= Number(allValues.ogInvoiceAmt)) ?
`Amount can't be greater than Orignal order amount`:''

export const adjustmentAmtB = (value,allValues,props)=> ( allValues.ogInvoiceAmt && Number(allValues.buyerAdjustedAmt) >= Number(allValues.ogInvoiceAmt)) ?
`Amount can't be greater than Orignal order amount`:''

export const adjustmentAmtS1 = (value,allValues,props)=> ( allValues.ogInvoiceAmt && Number(allValues.sellerAdjustmentAmt) >= Number(allValues.ogInvoiceAmt)) ?
`Amount can't be greater than Orignal order amount`:''

export const adjustmentAmtB1 = (value,allValues,props)=> ( allValues.ogInvoiceAmt && Number(allValues.buyerAdjustmentAmt) >= Number(allValues.ogInvoiceAmt)) ?
`Amount can't be greater than Orignal order amount`:''

export const dicount100 = (value,allValues,props)=> Number(allValues?.percentOff) == 100 ?
`Discount can't be 100 %`:''

export const dicount100product = (value) => {
  let val = value ? value.split(',') : [];
  let found = val.filter(e => (Number(e) >= 100 || Number(e) < 0));
  let alpha = val.filter(e => isNaN(e));
  if(found.length > 0){
    return `Discount should be in between 0 to 100`
  } else if (alpha.length > 0) {
    return `Discount should be number`
  } else {
    return ''
  }
}

export const emailField = (value) => {
  return (value ?
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
      'Invalid email address' : '' : 'Email is required')
}

export const prepaidOrder = (value, allValues, props)=>{
  return Number(value) <= Number(allValues.invoice2) ?
 '':`Please enter value less than ${allValues.invoice2}`}

export const tooOld = value =>
  value && value > 65 ? 'You might be too old for this' : undefined
export const aol = value =>
  value && /.+@aol\.com/.test(value) ?
    'Really? You still use AOL for your email?' : undefined
export const passwordMatch = matchName => (value, allValues, props) => {
 
  return allValues.confirmPassword !== allValues.password
    ? //? `This field must match with ${matchName} field`
    "Passwords do not match."
    : undefined;
}
// export const trackingVal = data => {
//  return data && /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(data) ? 'Enter valid URL' :
//   (data.indexOf("http://") == 0 || data.indexOf("https://") == 0) ? 
//        '' :
//      false
//   }

//   var res = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
//   if(res == null)
//       return false;
//   else
//       return true;
export const trackingVal = value => { 
  let validUrl = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(value) 
 
    if(validUrl){
        if (value.indexOf("http://") == 0 || value.indexOf("https://") == 0) {
         
            return ''
        }else{
        
            return false
        }
    }else{
     
        // return 'Enter valid URL'
        return ''
    }  
}
export const confirmPasswordMatch = matchName => (value, allValues, props) => {
 
  return allValues.confirmPassword !== allValues.password
    ? //? `This field must match with ${matchName} field`
    "Passwords do not match."
    : undefined;
}
export const fassaiLicNoRequired = matchName =>(value,allValues,props)=>{
 
  return allValues.fassaiLic?!allValues.fassaiLicNo?'This field is required':undefined:undefined
}

export const gstLicNoRequired = matchName =>(value,allValues,props)=>{
 
  return allValues.gstLic?!allValues.gstLicNo?'This field is required':undefined:undefined
}

export const accountNumber = (value) => {
  return (value === undefined ? 'This field is required' : isNaN(value) ? 'Please enter valid account number' : value.length < 10 ? 'Please enter valid account number' : '')
}

export const ifscCode = (value) => {
  return (value === undefined ? 'This field is required' : !/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/.test(value) ? 'Please enter valid IFSC Code ' : '')
}

export const emailPhone = (value) => {
  
  
  return (value === undefined ? 'This field is required' : isNaN(value) ? !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Please enter valid Email address' : '' : value.length < 10 ? 'Please enter valid Mobile number' : '')
  // return (value === undefined ? 'This field is required' : isNaN(value) ? !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Please enter valid Email address': value.length < 10 ? 'Please enter valid Mobile number' : '' : '') 
}

export const validatePassword = (value) => {
 
  return (value === undefined ? 'This field is required' : value.length < 6 ? 'Must contain at least 6 characters.' : '')
}

export const validateData = (values) => {
  const errors = {};
  const requiredFields = [
    'email'
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }
  return errors;
} 
export const noValidations = (value)=> {return ''}

export const percentageValidation = value => {
 
  if(parseInt(value) < 0) {
    return "% off cannot be less than 0."
  } else if(parseInt(value) > 100) {
    return "% off cannot be greater than 100."
  } else {
    return ""
  }
}
export const discountValidation = value => {
  let check = value/0.25;
  let checkNumber = Number.isInteger(check);
  if(checkNumber === false) {
    return "% should be multiple of 0.25"
  } 
  else {
    return ""
  }
}

export const noDecimal = value => {
  
  let val = Number(value)
 
  let checkNumber = Number.isInteger(val);

  if(checkNumber !== true) {
    return "Quantity should be integer."
  } 
  else {
    return ""
  }
}

export const twoDecimal = value => {
  let newRegex = new RegExp(/^(?:\d*\.\d{1,2}|\d+)$/)
  let val = Number(value)
  if(!newRegex.test(val)) {
    return "Should be two decimals."
  } 
  else {
    return ""
  }
}
export const invoiceValue = (value, allValues, props)=>{
  let newValue = Math.round(allValues.invoice2 + allValues.invoice2*0.04);
  let newValue1 = Math.round(allValues.invoice2 - allValues.invoice2*0.04);
 
  return Number(value) < Number(newValue1) || Number(value) > Number(newValue) ?
 `Please enter  value between ${newValue1} to ${newValue}`:''}

 export const invoiceValueOnline = (value, allValues, props)=>{
  let newValue = Math.round(allValues.invoice2 );
  let newValue1 = Math.round(allValues.invoice2 - allValues.invoice2*0.04);
 
  return Number(value) < Number(newValue1) || Number(value) > Number(newValue) ?
 `Please enter  value between ${newValue1} to ${newValue}`:''}
 
 export const gstValidation = (value) =>{
 
  return (value && value.length <= 15 ?
    !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(value) ?
      'Invalid GST' : '' : 'Please enter valid GST')
}