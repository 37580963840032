import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';

const SellersPopup = (props) => {
  const { medicineName } = props;

  const columns = [
    { label: 'Seller Name', align: 'left', minWidth: 170, },
    { label: 'Quantity Sold', align: 'center', minWidth: 100, },
    { label: 'Discount', align: 'left', minWidth: 100, }
  ]

  const { add } = props;

  return (
    <React.Fragment>
      <Dialog open={add} onClose={props.handelClose} maxWidth={'md'} >
        <div sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {medicineName && medicineName.seller
                  .map((value, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} >
                        <TableCell key={i} align={"left"}>
                          {value.company_name}
                        </TableCell>
                        <TableCell key={i} align={"center"}>
                          {value.quantity_sold}
                        </TableCell>
                        <TableCell key={i} align={"left"}>
                          {value.discount}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <DialogActions className="pr-4">
          <Button onClick={props.handelClose} color='secondary' >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default SellersPopup