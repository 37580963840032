import React, { Component, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import Avatar from '@mui/material/Avatar';
import { getEditPromotionsList } from 'actions/admin';
import { connect } from 'react-redux';
import ReactStrapTextField from '../../components/ReactStrapTextField/index';
import { Field, Form } from 'react-final-form'

function EditPromotions (props) {
   
  const[state ,setState] = useState( {
      redirect: '',
      promotion: '',
      promotion_id: '',
      Id: '',
  })

  useEffect(() => {
    const updateState = () => {
      setState({
        redirect: props.selectedData ? props.selectedData.redirect_url : state.redirect,
        promotion: props.selectedData ? props.selectedData.image_banner : state.promotion,
        Id: props.selectedData ? props.selectedData._id : '',
      });
    };

    updateState();
  }, [props.selectedData]); 

  const  handleRequestClose = () => {
    props.handleClick('edit');
    setState({ permission: [], email: '', name: '', phone: '', groupId: '' });
  };


 const onSubmit = (values) => {
   console.log(values)
    props.getEditPromotionsList({
      promotion_id: state.Id,
      redirect_url: values.redirect,
    })
    props.handleClick('edit')
  }

    const { redirect} = state;
    const { edit, title, selectedData, submitting } = props;

    return (
      <React.Fragment>
        <Dialog open={edit} onClose={handleRequestClose}
          fullWidth={true}
          maxWidth={'md'}>
          <Form
            onSubmit={onSubmit}
            initialValues={{redirect:redirect?redirect:''}}
            render={({ handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle>
                  {title}
                </DialogTitle>
                <DialogContent>
                  <Row>
                    <Col sm={12} md={12} lg={2} xs={12} xl={2} >
                      <Avatar className="size-80" alt="promotions" src={selectedData && selectedData.image_banner} />
                    </Col>
                    <Col xs={8} md={8} sm={8} xl={6} lg={6}>
                      <Field id="redirect" name="redirect" type="text"
                        component={ReactStrapTextField} label="Redirect URL"
                      />
                    </Col>
                  </Row>
                </DialogContent>
                <DialogActions className="pr-4">
                  <Button onClick={handleRequestClose} color='secondary' >
                    Cancel
                  </Button>
                  <Button type='submit' color='primary' disabled={submitting}>
                    Edit
                  </Button>
                </DialogActions>
              </form>
            )} />
        </Dialog>

      </React.Fragment>
    );
  }

const mapStateToProps = ({ admin }) => {
  const { getEditPromotionsList } = admin;
  return { getEditPromotionsList }
};

EditPromotions = connect(
  mapStateToProps,
  {
    getEditPromotionsList
  }
)(EditPromotions)

export default EditPromotions 
