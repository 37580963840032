// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNIN_ADMIN = 'signin_admin';
export const SIGNIN_ADMIN_SUCCESS = 'signin_admin_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const SIGNOUT_ADMIN = 'signout_admin';
export const SIGNOUT_ADMIN_SUCCESS = 'signout_admin_success';
export const INIT_URL = 'init_url';

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

export const CHANGE_TAB = 'change_tab'

//---------ADMIN---------------------------------------

export const API_FAILED = 'API_FAILED';

export const GET_ORDER_LIST = 'GET_ORDER_LIST';
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS';

export const GET_ALL_ORDER_LIST = 'GET_ALL_ORDER_LIST';
export const GET_ALL_ORDER_LIST_SUCCESS = 'GET_ALL_ORDER_LIST_SUCCESS';

export const GET_ORDER_HISTORY = 'GET_ORDER_HISTORY';
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS';

export const GET_INVENTORY = 'GET_INVENTORY';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';

export const GET_INVENTORY_POPUP = 'GET_INVENTORY_POPUP';
export const GET_INVENTORY_POPUP_SUCCESS = 'GET_INVENTORY_POPUP_SUCCESS';

export const GET_BUYER_SELLER_LIST = 'GET_BUYER_SELLER_LIST';
export const GET_BUYER_SELLER_LIST_SUCCESS = 'GET_BUYER_SELLER_LIST_SUCCESS';

export const GET_BUYER_SELLER_LIST_ADMIN = 'GET_BUYER_SELLER_LIST_ADMIN';
export const GET_BUYER_SELLER_LIST_SUCCESS_ADMIN = 'GET_BUYER_SELLER_LIST_SUCCESS_ADMIN';

export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';

export const GET_USER_APPROVE = 'GET_USER_APPROVE';
export const GET_USER_APPROVE_SUCCESS = 'GET_USER_APPROVE_SUCCESS';

export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';

export const GET_META_DATA = 'GET_META_DATA';
export const GET_META_DATA_SUCCESS = 'GET_META_DATA_SUCCESS';

export const GET_PREPAIDORDER_LIST = 'GET_PREPAIDORDER_LIST';
export const GET_PREPAIDORDER_LIST_SUCCESS = 'GET_PREPAIDORDER_LIST_SUCCESS';

// ---------------------STAFF / GROUPS-------------------------


export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';

export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';

export const LIST_GROUP = 'LIST_GROUP';
export const LIST_GROUP_SUCCESS = 'LIST_GROUP_SUCCESS';

export const ADD_STAFF = 'ADD_STAFF';
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';

export const DELETE_STAFF = 'DELETE_STAFF';
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS';

export const LIST_STAFF = 'LIST_STAFF';
export const LIST_STAFF_SUCCESS = 'LIST_STAFF_SUCCESS';

export const LIST_PERMISSION_MODULES = 'LIST_PERMISSION_MODULES';
export const LIST_PERMISSION_MODULES_SUCCESS = 'LIST_PERMISSION_MODULES_SUCCESS';


// ---------------------Product Request-------------------------

export const GET_PRODUCT_REQUEST_LIST = 'GET_PRODUCT_REQUEST_LIST';
export const GET_PRODUCT_REQUEST_LIST_SUCCESS = 'GET_PRODUCT_REQUEST_LIST_SUCCESS';

export const GET_APPROVE_PRODUCT_REQUEST_LIST = 'GET_APPROVE_PRODUCT_REQUEST_LIST';
export const GET_APPROVE_PRODUCT_REQUEST_LIST_SUCCESS = 'GET_APPROVE_PRODUCT_REQUEST_LIST_SUCCESS';

// ---------------------Promotions-------------------------

export const GET_PROMOTIONS_LIST = 'GET_PROMOTIONS_LIST';
export const GET_PROMOTIONS_LIST_SUCCESS = 'GET_PROMOTIONS_LIST_SUCCESS';

export const GET_ADD_PROMOTIONS_LIST = 'GET_ADD_PROMOTIONS_LIST';
export const GET_ADD_PROMOTIONS_LIST_SUCCESS = 'GET_ADD_PROMOTIONS_LIST_SUCCESS'

export const GET_EDIT_PROMOTIONS_LIST = 'GET_EDIT_PROMOTIONS_LIST';
export const GET_EDIT_PROMOTIONS_LIST_SUCCESS = 'GET_EDIT_PROMOTIONS_LIST_SUCCESS'

export const GET_DELETE_PROMOTIONS_LIST = 'GET_DELETE_PROMOTIONS_LIST';
export const GET_DELETE_PROMOTIONS_LIST_SUCCESS = 'GET_DELETE_PROMOTIONS_LIST_SUCCESS'

// -----------------------------scheduleNotification----------------
export const GET_SCHEDULE_NOTIFICATION_LIST = 'GET_SCHEDULE_NOTIFICATION_LIST';
export const GET_SCHEDULE_NOTIFICATION_LIST_SUCCESS = 'GET_SCHEDULE_NOTIFICATION_LIST_SUCCESS';

//---------------------------------OutOfStock----------------------------
export const GET_LOW_STOCK_SELLERWISE_LIST= "GET_LOW_STOCK_SELLERWISE_LIST";
export const GET_LOW_STOCK_SELLERWISE_LIST_SUCCESS= "GET_LOW_STOCK_SELLERWISE_LIST_SUCCESS";
//----------------------------------------------------------------

export const GET_LOW_STOCK_OVERALL_LIST = "GET_LOW_STOCK_OVERALL_LIST";
export const GET_LOW_STOCK_OVERALL_LIST_SUCCESS = "GET_LOW_STOCK_OVERALL_LIST_SUCCESS";
// --------------------------------------------------------------
// -----------------------------Change user status--------------------------

export const GET_CHANGE_USER_STATUS = 'GET_CHANGE_USER_STATUS';
export const GET_CHANGE_USER_STATUS_SUCCESS = 'GET_CHANGE_USER_STATUS_SUCCESS'

// ----------------------------------ADD/DEL GST-----------------------------------------

export const GET_ADD_GST = 'GET_ADD_GST';
export const GET_ADD_GST_SUCCESS = 'GET_ADD_GST_SUCCESS'

export const GET_DELETE_GST = 'GET_DELETE_GST';
export const GET_DELETE_GST_SUCCESS = 'GET_DELETE_GST_SUCCESS'

// -----------------------------------ADD/DEL PRODUCT TYPE---------------------------------------

export const GET_ADD_PRODUCT_TYPE = 'GET_ADD_PRODUCT_TYPE';
export const GET_ADD_PRODUCT_TYPE_SUCCESS = 'GET_ADD_PRODUCT_TYPE_SUCCESS'

export const GET_DELETE_PRODUCT_TYPE = 'GET_DELETE_PRODUCT_TYPE';
export const GET_DELETE_PRODUCT_TYPE_SUCCESS = 'GET_DELETE_PRODUCT_TYPE_SUCCESS'

// -----------------------------------ADD/DEL PRODUCT CATEGORY---------------------------------------

export const GET_ADD_PRODUCT_CATEGORY = 'GET_ADD_PRODUCT_CATEGORY';
export const GET_ADD_PRODUCT_CATEGORY_SUCCESS = 'GET_ADD_PRODUCT_CATEGORY_SUCCESS'

export const GET_DELETE_PRODUCT_CATEGORY = 'GET_DELETE_PRODUCT_CATEGORY';
export const GET_DELETE_PRODUCT_CATEGORY_SUCCESS = 'GET_DELETE_PRODUCT_CATEGORY_SUCCESS'

// -----------------------------------ADD/DEL COMPANY---------------------------------------

export const GET_ADD_COMPANY = 'GET_ADD_COMPANY';
export const GET_ADD_COMPANY_SUCCESS = 'GET_ADD_COMPANY_SUCCESS'

export const GET_DELETE_COMPANY = 'GET_DELETE_COMPANY';
export const GET_DELETE_COMPANY_SUCCESS = 'GET_DELETE_COMPANY_SUCCESS'

// ----------------------------------ADD PRODUCT--------------------------------------
// ADD is done in that component itself
export const GET_DELETE_PRODUCT = 'GET_DELETE_PRODUCT';
export const GET_DELETE_PRODUCT_SUCCESS = 'GET_DELETE_PRODUCT_SUCCESS'


export const GET_DASHBOARD_CARD = 'GET_DASHBOARD_CARD'
export const GET_DASHBOARD_CARD_SUCCESS = 'GET_DASHBOARD_CARD_SUCCESS';

export const ADMIN_DASHBOARD_STATS = 'ADMIN_DASHBOARD_STATS'
export const ADMIN_DASHBOARD_STATS_SUCCESS = 'ADMIN_DASHBOARD_STATS_SUCCESS';

// ---------------------------------- SELLER SALES STATS--------------------------------------
export const ADMIN_SALES_STATS ='ADMIN_SALES_STATS';
export const ADMIN_SALES_STATS_SUCCESS ='ADMIN_SALES_STATS_SUCCESS';

export const ADMIN_MONTHLY_SALES_STATS ='ADMIN_MONTHLY_SALES_STATS';
export const ADMIN_MONTHLY_SALES_STATS_SUCCESS ='ADMIN_MONTHLY_SALES_STATS_SUCCESS';


export const GET_SALES_STATISTICS = 'GET_SALES_STATISTICS'
export const GET_SALES_STATISTICS_SUCCESS = 'GET_SALES_STATISTICS_SUCCESS';

export const GET_TOP_FIVE_LEAST_FIVE = 'GET_TOP_FIVE_LEAST_FIVE'
export const GET_TOP_FIVE_LEAST_FIVE_SUCCESS = 'GET_TOP_FIVE_LEAST_FIVE_SUCCESS';

// -----------------------------------SETTLEMENTS---------------------------------------

export const GET_SETTLEMENTS = 'GET_SETTLEMENTS'
export const GET_SETTLEMENTS_SUCCESS = 'GET_SETTLEMENTS_SUCCESS';

// ----------------------------------Admin sidebar----------------------------------------

export const GET_SIDEBAR = 'GET_SIDEBAR';
export const GET_SIDEBAR_SUCCESS = 'GET_SIDEBAR_SUCCESS';

export const GET_COD_ON_OFF = 'GET_COD_ON_OFF';
export const GET_COD_ON_OFF_SUCCESS = 'GET_COD_ON_OFF_SUCCESS';

// -------------------------------------------------------------------------------

export const GET_GLOBAL_COD = 'GET_GLOBAL_COD';
export const GET_GLOBAL_COD_SUCCESS = 'GET_GLOBAL_COD_SUCCESS';

export const GET_CHANGE_GLOBAL_COD = 'GET_CHANGE_GLOBAL_COD';
export const GET_CHANGE_GLOBAL_COD_SUCCESS = 'GET_CHANGE_GLOBAL_COD_SUCCESS';

// -------------------------------------------------------------------------------------

export const GET_CHANGE_PROMOTION = 'GET_CHANGE_PROMOTION';
export const GET_CHANGE_PROMOTION_SUCCESS = 'GET_CHANGE_PROMOTION_SUCCESS';

export const GET_CHANGE_DEAL_OF_THE_DAY = 'GET_CHANGE_DEAL_OF_THE_DAY';
export const GET_CHANGE_DEAL_OF_THE_DAY_SUCCESS = 'GET_CHANGE_DEAL_OF_THE_DAY_SUCCESS';


export const GET_FAST_MOVING = 'GET_FAST_MOVING';
export const GET_FAST_MOVING_SUCCESS = 'GET_FAST_MOVING_SUCCESS';

// ==============================================================================

export const GET_VERIFY_PHONE = 'GET_VERIFY_PHONE';
export const GET_VERIFY_PHONE_SUCCESS = 'GET_VERIFY_PHONE_SUCCESS';

// ==============================================================================

export const GET_ALL_SETTLEMENT = 'GET_ALL_SETTLEMENT';
export const GET_ALL_SETTLEMENT_SUCCESS = 'GET_ALL_SETTLEMENT_SUCCESS';

// ==============================================================================

export const GET_SETTLEMENT_LIST = 'GET_SETTLEMENT_LIST';
export const GET_SETTLEMENT_LIST_SUCCESS = 'GET_SETTLEMENT_LIST_SUCCESS';

// ==============================================================================

export const GET_PENDING_SETTLEMENT = 'GET_PENDING_SETTLEMENT';
export const GET_PENDING_SETTLEMENT_SUCCESS = 'GET_PENDING_SETTLEMENT_SUCCESS';

// ==============================================================================

export const GET_GROUP_SETTLEMENT = 'GET_GROUP_SETTLEMENT';
export const GET_GROUP_SETTLEMENT_SUCCESS = 'GET_GROUP_SETTLEMENT_SUCCESS';

// ==============================================================================

export const GET_LIST_GROUP_SETTLEMENT = 'GET_LIST_GROUP_SETTLEMENT';
export const GET_LIST_GROUP_SETTLEMENT_SUCCESS = 'GET_LIST_GROUP_SETTLEMENT_SUCCESS';

export const GET_DELIVERY_ON_OFF = 'GET_DELIVERY_ON_OFF';
export const GET_DELIVERY_ON_OFF_SUCCESS = 'GET_DELIVERY_ON_OFF_SUCCESS';

export const GET_TRANSACTION_LIST = 'GET_TRANSACTION_LIST';
export const GET_TRANSACTION_LIST_SUCCESS = 'GET_TRANSACTION_LIST_SUCCESS';

export const GET_BUYER_TRANSACTION_LIST = 'GET_BUYER_TRANSACTION_LIST';
export const GET_BUYER_TRANSACTION_LIST_SUCCESS = 'GET_BUYER_TRANSACTION_LIST_SUCCESS';

export const GET_PROMOTIONS_LISTING = 'GET_PROMOTIONS_LISTING';
export const GET_PROMOTIONS_LISTING_SUCCESS = 'GET_PROMOTIONS_LISTING_SUCCESS';

export const GET_JUMBO_DEAL = 'GET_JUMBO_DEAL';
export const GET_JUMBO_DEAL_SUCCESS = 'GET_JUMBO_DEAL_SUCCESS';

export const GET_BNPL_ON_OFF = 'GET_BNPL_ON_OFF';
export const GET_BNPL_ON_OFF_SUCCESS = 'GET_BNPL_ON_OFF_SUCCESS';

// -------------------------------TICKET-----------------------------------

export const GET_TICKET_LIST = 'GET_TICKET_LIST';
export const GET_TICKET_LIST_SUCCESS = 'GET_TICKET_LIST_SUCCESS';

export const GET_ADD_TICKET = 'GET_ADD_TICKET';
export const GET_ADD_TICKET_SUCCESS = 'GET_ADD_TICKET_SUCCESS';

export const GET_TICKET_HISTORY_LIST = 'GET_TICKET_HISTORY_LIST';
export const GET_TICKET_HISTORY_LIST_SUCCESS = 'GET_TICKET_HISTORY_LIST_SUCCESS';

export const GET_BULK_LIST = 'GET_BULK_LIST';
export const GET_BULK_LIST_SUCCESS = 'GET_BULK_LIST_SUCCESS';

export const GET_BULK_ON_OFF = 'GET_BULK_ON_OFF';
export const GET_BULK_ON_OFF_SUCCESS = 'GET_BULK_ON_OFF_SUCCESS';

//------------------------------Menifest------------------------------

export const GET_ALL_MANIFEST_LIST = 'GET_ALL_MANIFEST_LIST';
export const GET_ALL_MANIFEST_SUCCESS = 'GET_ALL_MANIFEST_LIST_SUCCESS';

export const GET_MANIFEST_VERIFIED_LIST = 'GET_MANIFEST_VERIFIED_LIST';
export const GET_MANIFEST_VERIFIED_LIST_SUCCESS = 'GET_MANIFEST_VERIFIED_LIST_SUCCESS';

export const GET_MANIFEST_DISCREPANCY_LIST = 'GET_MANIFEST_DISCREPANCY_LIST';
export const GET_MANIFEST_DISCREPANCY_LIST_SUCCESS = 'GET_MANIFEST_DISCREPANCY_LIST_SUCCESS';

//------------------------------------------------------------------------------------------------