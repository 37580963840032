import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import DialogContentText from '@mui/material/DialogContentText';
import { getAddPromotionsList, getPromotionsList } from 'actions/admin';
import { connect } from 'react-redux';
import ReactStrapTextField from '../../components/ReactStrapTextField';
import { Field, Form, } from 'react-final-form'
import { required, requiredImage } from 'constants/validations';
import ImageValidate from './ImageValidate';
import * as moment from 'moment';




function AddPromotions(props) {

  const handleRequestClose = () => {
    props.handleClick('add');
  };

  const onSubmit = (values) => {
    props.getAddPromotionsList({
      name: values.prName,
      from_date: moment().format(),
      to_date: moment().add(30, 'days').format(),
      promo_code: values.promo,
      redirect_url: values.redirect,
      image_banner: values.promotion,
    })

    props.handleClick('add')
  }

  const { add, title } = props;

  return (
    <React.Fragment>
      <Dialog open={add} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'md'}>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            prName: '',
            redirect: '',
            promotion: '',
            promo: '',
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle>
                Add {title}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>

                </DialogContentText>
                <Row>
                  <Col sm={12} md={12} lg={2} xs={12} xl={2} >
                    <Field  name="promotion" id='promotion'
                      // onChange={(e) => handleChangeDocuments(e)}
                      component={ImageValidate}
                      label="Upload Image"
                      validate={requiredImage}
                    />

                  </Col>
                  <Col xs={12} md={12} sm={12} xl={10} lg={10}>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <Field id="prName" name="prName" type="text"
                          component={ReactStrapTextField} label="Name"
                          validate={required}
                        />
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <Field id="redirect" name="redirect" type="text"
                          component={ReactStrapTextField} label="Redirect URL"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <Field id="promo" name="promo" type="text"
                          component={ReactStrapTextField} label="Promo code"
                          validate={required}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions className="pr-4">
                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                <Button type='submit' color='primary' disabled={props.submitting}>
                  Add
                </Button>
              </DialogActions>
            </form>)}
        />
      </Dialog>


    </React.Fragment>
  );
}

const mapStateToProps = ({ admin }) => {
  const { getAddPromotionsList, getPromotionsList } = admin;
  return { getAddPromotionsList, getPromotionsList }
};


AddPromotions = connect(
  mapStateToProps,
  {
    getAddPromotionsList,

  }
)(AddPromotions)

export default AddPromotions 