import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './index.css'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getBulkList } from 'actions/admin';
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

const BulkListHistory = (props) => {
  const perPage = 100
  const [page, setPage] = useState(0)
  const [searchedText, setSearchedText] = useState('')
  const [popUp, setPopUp] = useState(false)
  const [expirePopup, setExpirePopup] = useState(false)
  const [dialogData, setDialogData] = useState('')

  useEffect((prevProps) => {
    let data = {
      page: page + 1,
      perPage: perPage,
      searchText: searchedText,
      close: '1'
    }
    if (props.tab === 1) {
      props.getBulkList({ data })
    }
  }, [])

  const changePage = (page) => {
    let data = {
      page: page + 1,
      perPage: perPage,
      searchText: searchedText,
      close: '1'
    }
    setPage(page)
    props.getBulkList({ data })
  };

  const handleSearch = (searchText) => {
    setSearchedText(searchText)
    setPage(0)
    let data = {
      searchText: searchText,
      page: 1,
      perPage,
      close: '1'
    }
    props.getBulkList({ data })
  };

  const handleLogClose = () => {
    setPopUp(false)
    setExpirePopup(false)
    setDialogData('')
  }

  const actions = (d, offers) => {
    if (d.status === 'FULFILLED') {
      return <span className='text-primary' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { setPopUp(true); setDialogData(d) }}>
        VIEW
      </span>
    } else {
      return <span className='text-primary' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { setExpirePopup(true); setDialogData(d) }}>
        VIEW
      </span>
    }
  }

  let { bulkList, loading } = props;

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: bulkList && bulkList.count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: searchedText,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  const columns = [
    {
      name: "product",
      label: "Product name",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "buyer",
      label: "Buyer",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "discount",
      label: "Additional expected discount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "quantity",
      label: "Expected quantity",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
      }
    }
  ];

  const statusStyle = (data) => {
    let status = data.toLowerCase();
    return status.includes("supplied") ? "#B45F06" : status.includes("requested") ? '#2B78E4' : status.includes("confirmed") ? "#38761D" : status.includes("offered") ? "#990000" : status.includes("expired") ? "#ea9a99" : status.includes("fulfilled") ? "#b6d8a8" : status.includes("closed") ? "#9fc4f7" : '';
  }

  const text = (data1) => {
    let data = data1.toLowerCase();
    return data.includes("expired") ? "#903230" : data.includes("fulfilled") ? '#698c57' : data.includes("closed") ? "#305d8b" : '';
  }

  let data = []
  bulkList && bulkList.data1.length > 0 && bulkList.data1.map(d => {
    data.push({
      product: d.product_name && (d.product_name).toUpperCase(),
      buyer: d.buyer_name && (d.buyer_name).toUpperCase(),
      discount: `${d.discount}%`,
      quantity: d.quantity,
      status: <div key={'recent'} className={`badge text-uppercase text-white`} style={{ backgroundColor: statusStyle(d.status) }}><span style={{ color: text(d.status) }}>{d.status}</span></div>,
      date: moment(d.createdAt).format('DD/MM/YYYY'),
      actions: actions(d, d.offers),
    })
  })

  const theme1 = createTheme({
    overrides: {
      MuiTableCell: {
        sizeSmall: {
          padding: '15px',
        },
      }
    }
  });

  return (
    <div>
      <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
        {loading ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> :
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
            style={{ borderRadius: '0px !important' }}
          />
        }
      </div>
      <Dialog open={popUp} onClose={handleLogClose} fullWidth={true} maxWidth={'md'}>
        <DialogTitle className='pb-0'>
          DETAILS
        </DialogTitle>
        <DialogContent>
          <Paper className={'tableRoot MuiPaper-rounded '} elevation={0}>
            <ThemeProvider theme={theme1}>
              <Table className={'tableMain MuiPaper-rounded'} aria-label="spanning table" >
                <TableHead>
                  <TableRow style={{ backgroundColor: '#dedede', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                    <TableCell align="center" size='small'>#</TableCell>
                    <TableCell align="center" size='small'>Quantity</TableCell>
                    <TableCell align="center" size='small'>Discount</TableCell>
                    <TableCell align="center" size='small'>Final cost</TableCell>
                    <TableCell align="center" size='small'>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dialogData && dialogData.offers.length > 0 && dialogData.offers.map((eachStatus, index) => (
                    <TableRow key={index} style={{ marginBottom: '5px' }}>
                      <TableCell align="center" size='small' style={{ fontSize: '12px' }}>{(eachStatus.name).toUpperCase()}</TableCell>
                      <TableCell align="center" size='small' style={{ fontSize: '12px' }}>{eachStatus.quantity}</TableCell>
                      <TableCell align="center" size='small' style={{ fontSize: '12px' }}>{eachStatus.discount}%</TableCell>
                      <TableCell align="center" size='small' style={{ fontSize: '12px' }}>₹{(eachStatus.amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                      {((dialogData?.status).toLowerCase() === 'offered') ? <TableCell align="center" size='small' className='text-primary' style={{ textDecoration: 'underline', fontSize: '12px' }} >
                        OFFERED
                      </TableCell> :
                        ((dialogData?.status).toLowerCase() === 'supplied' && eachStatus.isSupplied === true) ? <TableCell align="center" size='small' className={'text-primary'} style={{ textDecoration: 'underline', fontSize: '12px' }} >
                          SUPPLIED
                        </TableCell> :
                          ((dialogData?.status).toLowerCase() === 'confirmed' && eachStatus.isConfirmed === true) ? <TableCell align="center" size='small' className={'text-primary'} style={{ fontSize: '12px' }}>
                            CONFIRMED
                          </TableCell> :
                            ((dialogData?.status).toLowerCase() === 'fulfilled' && eachStatus.isSupplied === true) ? <TableCell align="center" size='small' className={'text-primary'} style={{ textDecoration: 'underline', fontSize: '12px' }}>
                              FULFILLED
                            </TableCell> : <TableCell align="center" size='small' className={'text-muted'} style={{ fontSize: '12px' }}>
                              {'N/A'}
                            </TableCell>
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table></ThemeProvider>
          </Paper>
        </DialogContent>
        <DialogActions className='mr-2'>
          <Button onClick={handleLogClose} color='secondary' >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={expirePopup} onClose={handleLogClose} fullWidth={true} maxWidth={'sm'}>
        <DialogContent>
          <h3>{dialogData.closed_reason}</h3>
        </DialogContent>
        <DialogActions className='mr-2 pt-0'>
          <Button onClick={handleLogClose} color='secondary' >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  );
}

const mapStateToProps = ({ admin }) => {
  const { bulkList, loading } = admin;
  return { bulkList, loading }
};

export default connect(mapStateToProps, { getBulkList })(BulkListHistory);