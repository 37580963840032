import React, { useEffect, useState } from "react";
import FullWidthTabs from '../../components/routes/tabs/fixed/FullWidthTabsForDetails';
import { getPendingSettlement } from 'actions/admin'
import { connect } from 'react-redux'
import SettlementDetailHead from './SettlementDetailHead'
import AxiosRequest from 'sagas/axiosRequest';
import { NotificationManager } from 'react-notifications';
import { Col, Row, Popover } from 'reactstrap';
import { useNavigate, useParams } from "react-router";
import AdminContainer from "containers/AdminContainer";
function SettlemetsDetailsTab(props) {
  const history = useNavigate()
  const { id } = useParams()
  const [state, setState] = useState({ sellerAdjustmentData: '', ticketDetails: '', openTickets: '', closedTickets: '', popoverOpen: false, popoverOpen1: false })


  const fetchApi = async () => {
    let data = { sellerId: id, Id: id }
    await props.getPendingSettlement({ data })
    hitApi();
    let result1 = await AxiosRequest.axiosHelperFunc('post', 'ticket/ticketSettlementDetails', '', data)
    if (result1.data.error) {
    } else {
      setState((prev) => ({ ...prev, ticketDetails: result1.data.data }));
      let data = result1.data.data1;
      let open = data && data.filter(e => e.status == 'open' && e?.settlement?.status == 'Pending');
      let close = data && data.filter(e => e?.settlement?.status != 'Pending');
      let closeIds = close.map((d, i) => {
        if (i < close.length - 1) {
          return <span>{d.ticketNo} (<a href={`/admin/orders/orderDetails/${d.order.orderNo}`} target='_blank'>{d.order.orderNo}</a>),<br></br> </span>
        } else {
          return <span>{d.ticketNo} (<a href={`/admin/orders/orderDetails/${d.order.orderNo}`} target='_blank'>{d.order.orderNo}</a>) </span>
        }
      });
      let openIds = open.map((d, i) => {
        if (i < open.length - 1) {
          return <span>{d.ticketNo} (<a href={`/admin/orders/orderDetails/${d.order.orderNo}`} target='_blank'>{d.order.orderNo}</a>),<br></br> </span>
        } else {
          return <span>{d.ticketNo} (<a href={`/admin/orders/orderDetails/${d.order.orderNo}`} target='_blank'>{d.order.orderNo}</a>) </span>
        }
      });
      setState((prev) => ({ ...prev, openTickets: openIds, closedTickets: closeIds }))
    }
  }
  useEffect(() => {
    fetchApi()
  }, [])
  const hitApi = async () => {
    let data = { sellerId: id, Id: id }
    let result = await AxiosRequest.axiosHelperFunc('post', 'ticket/getAdjustmentAmount', '', data)
    if (result.data.error) {
      NotificationManager.error(result.data.title);
    } else {
      setState((prev) => ({ ...prev, sellerAdjustmentData: result.data.data }));
    }
  }
  function toggle() {
    setState((prev) => ({ ...prev, popoverOpen: !state.popoverOpen }));
  }
  function toggle1() {
    setState((prev) => ({ ...prev, popoverOpen1: !state.popoverOpen1 }));
  }
  const { pendingSettlement } = props;
  let { ticketDetails, openTickets, closedTickets } = state;
  let data1 = pendingSettlement && pendingSettlement.data[0]
  console.log('uuuwwaq2', openTickets)
  return (
    <AdminContainer>
      <React.Fragment>
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <SettlementDetailHead match={props.match} data1={data1} sellerData={state.sellerAdjustmentData} />
          <div className="row">
            <div className="col-12">
              <div className="jr-card chart-user-statistics bg-primary darken-4 text-white">
                <div className="orderDetails px-4 mb-4">
                  <Row className="pb-4 pl-4">
                    <Col xs={12} xl={4} sm={12} md={4} lg={4} className="orderDetailsMainCol">
                      <h3 style={{ fontSize: 15, paddingLeft: '7px' }}> Commercials:</h3>
                      <div className="p-1 ml-1">
                        Settled Amount:  ₹{(data1 && data1.settled_amount ? data1.settled_amount : '').toLocaleString('en-IN', { maximumFractionDigits: 2 })}/-
                      </div>
                      <div className="p-1 ml-1">
                        Unsettled Amount:  ₹{(data1 && data1.pending_amount ? data1.pending_amount : '').toLocaleString('en-IN', { maximumFractionDigits: 2 })}/-
                      </div>
                    </Col>
                    <Col xs={12} xl={4} sm={12} md={4} lg={4} className="orderDetailsMainCol">
                      <h3 style={{ fontSize: 15, paddingLeft: '7px' }}> Open tickets:</h3>
                      <div className="p-1 ml-1">
                        No. of Tickets: {ticketDetails && ticketDetails?.openTicket?.no} &nbsp;&nbsp;
                        {ticketDetails && ticketDetails?.openTicket?.no > 0 && <i class="zmdi zmdi-info" variant="contained" color="primary" id={'Popover-' + id} onClick={toggle} />}
                      </div>
                      <div className="p-1 ml-1">
                        Total adjustment: {(ticketDetails && ticketDetails.openTicket && ticketDetails.openTicket.adjustment ? ticketDetails.openTicket.adjustment : 0).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                      </div>
                      <div className="p-1 ml-1">
                        Total RTO: {(ticketDetails && ticketDetails.openTicket && ticketDetails.openTicket.rto ? ticketDetails.openTicket.rto : 0).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                      </div>
                      <div className="p-1 ml-1">
                        Total GST: {(ticketDetails && ticketDetails.openTicket && ticketDetails.openTicket.gstOnRto ? ticketDetails.openTicket.gstOnRto : 0).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                      </div>
                    </Col>
                    <Col xs={12} xl={4} sm={12} md={4} lg={4} className="orderDetailsMainCol">
                      <h3 style={{ fontSize: 15, paddingLeft: '7px' }}> Tickets of undelivered and settled orders:</h3>
                      <div className="p-1 ml-1">
                        No. of Tickets: {ticketDetails && ticketDetails?.openTicketForSettled?.no}  &nbsp;&nbsp;
                        {ticketDetails && ticketDetails?.openTicketForSettled?.no > 0 && <i class="zmdi zmdi-info" variant="contained" color="primary" id={'Popover-' + id + '1'} onClick={toggle1} />}
                      </div>
                      <div className="p-1 ml-1">
                        Total adjustment: {(ticketDetails && ticketDetails.openTicketForSettled && ticketDetails.openTicketForSettled.adjustment ? ticketDetails.openTicketForSettled.adjustment : 0).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                      </div>
                      <div className="p-1 ml-1">
                        Total RTO: {(ticketDetails && ticketDetails.openTicketForSettled && ticketDetails.openTicketForSettled.rto ? ticketDetails.openTicketForSettled.rto : 0).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                      </div>
                      <div className="p-1 ml-1">
                        Total GST: {(ticketDetails && ticketDetails.openTicketForSettled && ticketDetails.openTicketForSettled.gstOnRto ? ticketDetails.openTicketForSettled.gstOnRto : 0).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
        {ticketDetails && ticketDetails?.openTicket?.no > 0 && <Popover placement={'bottom'} isOpen={state.popoverOpen} trigger="legacy" target={'Popover-' + id} toggle={toggle} >
          {openTickets}
        </Popover>}
        {ticketDetails && ticketDetails?.openTicketForSettled?.no > 0 && <Popover placement={'bottom'} isOpen={state.popoverOpen1} trigger="legacy" target={'Popover-' + id + '1'} toggle={toggle1}>
          {closedTickets}
        </Popover>}
        <FullWidthTabs history={history} match={props.match} tabFor={'settlementDetails'} sellerData={state.sellerAdjustmentData} hitApi={hitApi} />
      </React.Fragment>
    </AdminContainer>
  );
}

const mapStateToProps = ({ admin }) => {
  const { pendingSettlement } = admin;
  return { pendingSettlement }
}
export default connect(mapStateToProps, { getPendingSettlement })(SettlemetsDetailsTab);