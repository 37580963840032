import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import helper from 'constants/helperFunction';
import ButtonGroup from '@mui/material/ButtonGroup';
import SellerPopup from "../components/sellerPopUp";
import { getlowStockOverAll } from 'actions/admin';
import { connect } from 'react-redux'
import Button from '@mui/material/Button';
import AppConfig from "constants/config";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

const All = (props) => {
  const perPage = 100
  const [add, setAdd] = useState(false)
  const [searchedText, setSearchedText] = useState("")
  const [page, setPage] = useState(0)
  const [medicineName, setMedicineName] = useState("")

  const handelchange = (e) => {
    setAdd(true)
    setMedicineName(e)
  }

  const handelClose = () => {
    setAdd(false)
  }

  const changePage = (page) => {
    let data = {
      page: page + 1,
      perPage,
      searchText: searchedText,
    }
    props.getlowStockOverAll(data)
    setPage(page)
  };

  useEffect(() => {
    let data = {
      page: page + 1,
      perPage: 100,
      searchText: searchedText,
    }
    props.getlowStockOverAll(data)
  }, [])

  const handleSearch = (searchText) => {
    let data = { searchText: searchText, page: 1, perPage: perPage }
    props.getlowStockOverAll(data)
    setPage(0)
    setSearchedText(searchText)
  };

  const button = (product_name) => {
    return (<React.Fragment>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button variant="outlined" onClick={(e) => handelchange(product_name)} className={'text-success'}>
          Sellers
        </Button>
      </ButtonGroup>
    </React.Fragment>)
  }

  const handleSettelMent = async () => {
    window.open(`${AppConfig.baseUrl}inventory/lowStockOverAllCSV?token=${localStorage.getItem('admin_token')}`, '_blank');
  }

  const { loading, arr } = props;

  const columns = [
    {
      name: "Product Name",
      label: <span>&nbsp;&nbsp;&nbsp;&nbsp;Product Name</span>,
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "Quantity Sold",
      label: "Quantity Sold",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "Out Date",
      label: `Out Date`,
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "dropDown",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
      }
    },
  ];

  let data = [];
  arr && arr.data && arr.data.listData && arr.data.listData.map((dataM, index) => {
    data.push([
      <div style={{ marginLeft: '15px', cursor: 'pointer' }}>  <h4>{(dataM.name)}</h4></div>,
      <div><h4>{(dataM.quantity_sold)}</h4></div>,
      <div><h4>{(moment(dataM.out_date)).format("DD/MM/YYYY")}</h4></div>,
      button(dataM),
    ])
  })

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: true,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: arr && arr.data && arr.data.count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: searchedText,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onDownload: () => { handleSettelMent(); return false },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  return (
    <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
      {loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}
      {
        loading ?
          null :
          <div>
            <div style={{ width: '100%', float: "left" }}>
              <ThemeProvider theme={helper.getMuiTheme()}>
                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={options}
                  style={{ borderRadius: '0px !important' }}
                />
              </ThemeProvider>
            </div>
            <div style={{ width: '25%', float: "left" }}>
            </div>
          </div>
      }
      <SellerPopup medicineName={medicineName} add={add} handelClose={handelClose} />
    </div>
  );
}

const mapStateToProps = ({ admin }) => {
  const { arr } = admin;
  return { arr }
};

export default connect(mapStateToProps, { getlowStockOverAll })(All)

