import React, { Component } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { getInventoryList,  deleteGroup, deleteStaff } from 'actions/admin'
import AxiosRequest from 'sagas/axiosRequest'
import { NotificationManager } from 'react-notifications';


class AddInventory extends Component {
  state = {
    modal: false,

  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props !== nextProps) {
      return true;
    }
    if (this.state !== nextState) {
      return true;
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
   
    if (this.props.selectedData !== prevProps.selectedData) {
      if (this.props.deleteFor === 'group') {
        this.setState({ groupName: this.props.selectedData !== undefined ? this.props.selectedData.name : '' })
      } else if (this.props.deleteFor === 'staff') {
        this.setState({ staffId: this.props.selectedData !== undefined ? this.props.selectedData._id : '' })
      }
    }
  }

  handleRequestClose = () => {
    if (this.props.hideFor && this.props.hideFor === 'inventory') {
      this.setState({ open: false });
      return this.props.handleClick('hide');
    }
    this.setState({ open: false });
    this.props.handleClick('delete');
  }

  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  }

  handleSubmit = async () => {
    
    if (this.props.hideFor && this.props.hideFor === 'inventory') {
      let data = {
        inventoryId: this.props.editData._id,
        hide: !this.props.editData.isHidden
      }
      let response = await AxiosRequest.axiosHelperFunc('post', 'inventory/hideInventoryItem', '', data)
      
      if (response.data.error) {
        NotificationManager.error(response.data.title)
        this.setState({ open: false });
        this.props.handleClick('hide');
      } else {
        NotificationManager.success(response.data.title)
        let data2 = {
          page: this.props.page === 0 ? 1 : this.props.page,
          perPage: this.props.perPage
        }
        // this.props.getInventoryList({ data: data2 })
        this.setState({ open: false });
        this.props.handleClick('hide');
        this.props.callMount();
      }

    }
    if (this.props.deleteFor === 'group') {
     
      let memberCount = parseInt(this.props.selectedData.members);
      
      if (memberCount === 0) {
        this.props.deleteGroup({ history: this.props.history, name: this.state.groupName, listGroup: { searchText: '', page: 1, perPage: 10 } });
        this.setState({ open: false });
        this.props.handleClick('delete');
      } else {
        NotificationManager.error("This group contains active members, please remove the members and try again.")
        this.setState({ open: false });
        this.props.handleClick('delete');
      }
    }
    if (this.props.deleteFor === 'staff') {
      this.props.deleteStaff({ history: this.props.history, staffId: this.props.selectedData._id, listStaff: { searchText: '', page: 1, perPage: 10 } });
      this.setState({ open: false });
      this.props.handleClick('delete');
    }
    if (this.props.deleteFor === 'inventory') {
      let data = { inventoryId: this.props.editData._id }
      let response = await AxiosRequest.axiosHelperFunc('post', 'inventory/deleteInventoryItem', '', data)
      
      if (response.data.error) {
        NotificationManager.error(response.data.title)
        this.setState({ open: false });
        this.props.handleClick('delete');
      } else {
        NotificationManager.success(response.data.title)
        let data2 = {
          page: this.props.page === 0 ? 1 : this.props.page,
          perPage: this.props.perPage
        }
        // this.props.getInventoryList({ data: data2 })
        this.setState({ open: false });
        this.props.handleClick('delete');
        this.props.callMount();
      }
    }
  }

  render() {
    let { open } = this.state;
    return (
      <React.Fragment>
        <Dialog open={this.props.delete} onClose={this.handleRequestClose}
          fullWidth={true}
          maxWidth={'sm'}>
          <DialogTitle>
            {this.props.deleteFor === 'inventory' ? 'Remove item?' : ''}
            {this.props.deleteFor === 'staff' ? 'Remove Staff?' : ''}
            {this.props.deleteFor === 'group' ? 'Remove Group?' : ''}
            {this.props.deleteFor === 'productCategory' ? 'Remove Product Category?' : ''}
            {this.props.deleteFor === 'productType' ? 'Remove Product Type?' : ''}
            {this.props.deleteFor === 'manufacturer' ? 'Remove Manufacturer?' : ''}
            {this.props.deleteFor === 'gstValue' ? 'Remove GST Value?' : ''}
            {this.props.hideFor === 'inventory' ? this.props.editData ? this.props.editData.isHidden ? 'Unhide item?' : 'Hide item?' : '' : ''}

          </DialogTitle>
          <DialogContent>

            <form noValidate autoComplete="off">
              {
                this.props.deleteFor === 'inventory' ? <DialogContentText className='mt-3'>
                  This item will be removed from your inventory
              </DialogContentText> : ''
              }
              {
                this.props.deleteFor === 'staff' ? <DialogContentText className='mt-3'>
                  Are you sure, you want to remove this staff member?
            </DialogContentText> : ''
              }
              {
                this.props.deleteFor === 'group' ? <DialogContentText className='mt-3'>
                  Are you sure, you want to remove this group?
          </DialogContentText> : ''
              }

              {this.props.deleteFor === 'productCategory' ? <DialogContentText className='mt-3'>
                Are you sure, you want to remove this product category?
          </DialogContentText> : ''}
              {this.props.deleteFor === 'productType' ? <DialogContentText className='mt-3'>
                Are you sure, you want to remove this product type?
          </DialogContentText> : ''}
              {this.props.deleteFor === 'manufacturer' ? <DialogContentText className='mt-3'>
                Are you sure, you want to remove this manufacturer?
          </DialogContentText> : ''}

              {this.props.deleteFor === 'gstValue' ? <DialogContentText className='mt-3'>
                Are you sure, you want to remove this GST value?
          </DialogContentText> : ''}
          {
                this.props.hideFor === 'inventory' && this.props.editData &&
                <DialogContentText className='mt-3'>
                  {`Are you sure, you want to ${!this.props.editData.isHidden ? 'hide' : 'unhide'} this item ?`}
                </DialogContentText>
              }
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color='secondary' >
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color='primary'>
              {this.props.hideFor === 'inventory' ? 'Confirm' : 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ admin }) => {
  return {}
};

AddInventory = connect(
  mapStateToProps,
  {
    deleteGroup,
    deleteStaff,
    getInventoryList
  }           
)(AddInventory)

export default AddInventory;