import React from "react";
import FullWidthTabs from '../../components/routes/tabs/fixed/FullWidthTabs';
import OrderHeader from './OrderHeader';
import { useLocation, useNavigate } from "react-router";
import AdminContainer from "containers/AdminContainer";

function OrdersTab(props) {
  const history = useNavigate()
  const location = useLocation()
  return (
    <AdminContainer>
      <React.Fragment>
        <OrderHeader />
        <FullWidthTabs history={history} location={location} tabFor={'order'} />
      </React.Fragment>
    </AdminContainer>
  );
}

export default OrdersTab;