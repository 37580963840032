import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Col, Row, Label, Input } from 'reactstrap';
import { FormControl } from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';
import customHead from 'constants/customHead'
import { connect } from 'react-redux';
import { getTicketList } from 'actions/admin';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import helper from '../../../../constants/helperFunction';
import AppConfig from "constants/config";
import { Badge } from 'reactstrap';
import '../../orders/routes/index.css'
import axios from 'axios';
import { styles } from "constants/FilterStyle";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";


function Open(props) {
  const url = window.location.search
  let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''

  const [page1, setPage1] = useState(json ? json.pageT ? Number(json.pageT) : 0 : 0)
  const [state, setState] = useState({
    filter: [],
    perPage: 100,
    loader: false,
    searchedText: json ? json.searchT ? json.searchT : '' : '',
    statusTB: json ? json.statusTB ? json.statusTB : '' : '',
    statusTS: json ? json.statusTS ? json.statusTS : '' : '',
    statusTR: json ? json.statusTR ? json.statusTR : '' : '',
    seller: json ? json.sellerT ? json.sellerT : '' : '',
    buyer: json ? json.buyerT ? json.buyerT : '' : '',
    sort: json ? json.sort ? json.sort : '' : '',
    seller_id: json ? json.seller_id ? json.seller_id : '' : '',
    stateData: [],
    sellerData: [],
  })

  const componentdidMount = async () => {
    axios({
      method: 'get',
      url: `${AppConfig.baseUrl}ticket/getFilter`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      }
    }).then(result => {
      console.log("result", result)
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        setState((prev) => ({
          ...prev,
          sellerData: result.data.sellers
        }
        ))
      }
    }).catch(error => {
      console.log("error catch", error)
    });
    let data = {
      page: page1 + 1,
      perPage: 100,
      searchText: state.searchedText,
      statusB: state.statusTB,
      statusS: state.statusTS,
      statusR: state.statusTR,
      seller: state.seller,
      buyer: state.buyer,
      sort: state.sort,
      seller_id: state.seller_id,
    }

    if (props.tab == 0) {
      props.getTicketList({ data, history: props.history })
    }
  }
  useEffect(() => {
    setPage1(json ? json.pageT ? Number(json.pageT) : 0 : 0)
    componentdidMount()
  }, [window.location.search])

  const handleChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }))
  }
  const changePage = (page) => {
    setPage1(page)
    let obj = {
      pageT: page, searchT: state.searchedText, statusTB: state.statusTB, statusTS: state.statusTS, sort: state.sort,
      statusTR: state.statusTR, buyerT: state.buyer, sellerT: state.seller, seller_id: state.seller_id
    }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    props.history(`/admin/tickets?${url}`)
  };
  const handleSearch = (searchText) => {
    setState((prev) => ({ ...prev, searchedText: searchText ? searchText.replace(/"/g, '') : '' }));
    let obj = {
      pageT: 0, searchT: searchText ? searchText.replace(/"/g, '') : '', statusTB: state.statusTB, statusTS: state.statusTS, sort: state.sort,
      statusTR: state.statusTR, buyerT: state.buyer, sellerT: state.seller, seller_id: state.seller_id
    }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');

    props.history(`/admin/tickets?${url}`)
  };
  const handleCloseSearch = () => {
    setState((prev) => ({
      ...prev, searchedText: ''
    }))
    let obj = { pageT: 0, searchT: '', statusTB: state.statusTB, statusTS: state.statusTS, statusTR: state.statusTR, buyerT: state.buyer, sellerT: state.seller, sort: state.sort, seller_id: state.seller_id }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    props.history(`/admin/tickets?${url}`)
  }
  const handleResetFilter = () => {
    setState((prev) => ({
      ...prev, status: '', seller_id: '', statusTB: '', statusTS: '', statusTR: '',
      seller: '', buyer: '', sort: ''
    }));
    let obj = { pageT: 0, searchT: state.searchedText, sort: state.sort }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    props.history(`/admin/tickets?${url}`)
  }
  const applyFilter = () => {
    let obj = { pageT: 0, searchT: state.searchedText, statusTB: state.statusTB, statusTS: state.statusTS, statusTR: state.statusTR, buyerT: state.buyer, sellerT: state.seller, sort: state.sort, seller_id: state.seller_id }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    props.history(`/admin/tickets?${url}`)
  }
  const color = (data) => {
    return data ? data == 'open' ? 'warning' : 'success' : ''
  }

  const sort = (data) => {
    let sortBy = data.substr(33, 4);
    let obj = { pageT: 0, searchT: state.searchedText, statusTB: state.statusTB, statusTS: state.statusTS, statusTR: state.statusTR, buyerT: state.buyer, sellerT: state.seller, sort: state.sort == 'desc' ? 'asce' : sortBy, seller_id: state.seller_id }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    props.history(`/admin/tickets?${url}`)
  }


  const { ticketList, buyerData } = props;
  let { buyer, seller, stateData, seller_id, sellerData } = state;
  const columns = [
    {
      name: "ticketStatus",
      label: " ",
      options: {
        filter: false,
        status: false,
        sort: false
      }
    },
    {
      name: "Ticket ID",
      label: "Ticket ID",
      options: { filter: false, sort: false }
    }, {
      name: "Seller Name",
      label: "Seller Name",
      options: { filter: false, sort: false }
    }, {
      name: "Updated At",
      label: "Updated At",
      sortDirection: state.sort == 'desc' ? 'desc' : state.sort == 'asce' ? 'asc' : '',
      options: { filter: false }
    }, {
      name: "Order Id",
      label: "Order Id",
      options: { filter: false, sort: false }
    }, {
      name: "Buyer Name",
      label: "Buyer Name",
      options: { filter: false, sort: false }
    }, {
      name: "Issue",
      label: "Issue",
      options: { filter: false, sort: false }
    }, {
      name: "Buyer Status",
      label: "Buyer Status",
      options: { filter: false, sort: false }
    }, {
      name: "Seller Status",
      label: "Seller Status",
      options: { filter: false, sort: false }
    }, {
      name: "RTO Status",
      label: "RTO Status",
      options: { filter: false, sort: false }
    }, {
      name: "Created By",
      label: "Created By",
      options: {
        filter: true,
        sort: false,
        filterType: 'custom',
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        },
        customFilterListRender: v => {
          return false;
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, handleCustomChange, index, column) => (
            <React.Fragment>
              <Row form style={{ maxWidth: 400 }}>
                <Col md={6} xl={6} xs={6} lg={6}>
                  <FormControl className="mb-2">
                    <Label for="exampleSelect">Buyer Status</Label>
                    <Input type="select" value={state.statusTB} onChange={(e) => handleChange(e, 'statusTB')} name="statusTB" id="statusTB">
                      <option value="" >Select Status</option>
                      <option value="Buyer open" >Open</option>
                      <option value="Buyer closed" >Closed</option>
                    </Input>
                  </FormControl>
                </Col>
                <Col md={6} xl={6} xs={6} lg={6}>
                  <FormControl className="w-100 mb-2 categoryFilter">
                    <Label for="exampleSelect">Seller Status</Label>
                    <Input type="select" value={state.statusTS} onChange={(e) => handleChange(e, 'statusTS')} name="statusTS" id="statusTS">
                      <option value="" >Select Status</option>
                      <option value="Seller open" >Open</option>
                      <option value="Seller closed" >Closed</option>
                    </Input>
                  </FormControl>
                </Col>
                <Col md={6} xl={6} xs={6} lg={6}>
                  <FormControl className="w-100 mb-2 categoryFilter">
                    <Label for="exampleSelect">RTO Status</Label>
                    <Input type="select" value={state.statusTR} onChange={(e) => handleChange(e, 'statusTR')} name="statusTR" id="statusTR">
                      <option value="" >Select Status</option>
                      <option value="RTO open" >Open</option>
                      <option value="RTO closed" >Closed</option>
                    </Input>
                  </FormControl>
                </Col>

                <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                  <FormControl className="w-100 mb-2 categoryFilter">
                    <Label for="exampleSelect">Sellers</Label>
                    <Input type="select" name="seller" id="seller"
                      onChange={(e) => handleChange(e, 'seller_id')}>
                      <option selected={seller_id == ''} value={''}>Select Seller</option>
                      {
                        sellerData && sellerData.map((val) => {
                          console.log("---------------------------val", val)
                          return (<option selected={val._id == seller_id} value={val._id}>{val.company_name}({val.sellerId})</option>)


                        })
                      }

                    </Input>
                  </FormControl>
                </Col>

              </Row>
              <div style={{ paddingTop: 15 }} >
                <Button variant="contained" onClick={() => applyFilter()} className='filterButton' color='primary'>Apply Filter</Button>
                <Button variant="contained" onClick={() => handleResetFilter()} className='filterButton' color='primary'>Reset Filter</Button>
              </div>
            </React.Fragment>
          ),
          onFilterChange: () => {
            console.log('onFilterChange')
          }
        },
      },
    }
  ]
  let data = [];
  ticketList && ticketList.data1.map((d) => data.push(

    {

      ticketStatus: d.isTicketOpen && <Tooltip title={`Ready to close`} placement="top-start" data-container="body" >
        <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'red' }} />
      </Tooltip>,

      'Ticket ID': <a href={`/admin/tickets/ticketDetails/${d._id}`} className="text-primary" style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); props.history(`/admin/tickets/ticketDetails/${d._id}`) }} >{d.ticketNo}</a>,
      'Seller Name': <div>{d.seller && d.seller.sellerName ? d.seller.sellerName : 'N/A'} {d.seller && d.seller.sellerName && <a target='_blank' href={`/admin/sellers/details/${d.seller && d.seller.seller_id && d.seller.seller_id._id}`}>({d.seller && d.seller.seller_id && d.seller.seller_id.sellerId})</a>}</div>,
      'Updated At': <div>{moment(d.updatedAt).format('D MMM, YYYY h:mm a')}</div>,
      'Order Id': d.type == 'order' ? d.order.orderNo : 'N/A',
      'Buyer Name': <div>{d.buyer && d.buyer.buyerName ? d.buyer.buyerName : 'N/A'} {d.buyer && d.buyer.buyerName && <a target='_blank' href={`/admin/buyers/details/${d.buyer && d.buyer.buyer_id && d.buyer.buyer_id._id ? d.buyer.buyer_id._id : ""}`}>({d.buyer && d.buyer.buyer_id && d.buyer.buyer_id.buyerId ? d.buyer.buyer_id.buyerId : ""})</a>}</div>,
      'Issue': d.issue,
      'Buyer Status': d.type == 'order' ? <Badge color={color(d.buyerStatus)} pill className='text-white'>{helper.textCapitalize(d.buyerStatus == 'close' ? 'closed' : d.buyerStatus)}</Badge> : 'N/A',
      'Seller Status': d.type == 'order' ? <Badge color={color(d.sellerStatus)} pill className='text-white'>{helper.textCapitalize(d.sellerStatus == 'close' ? 'closed' : d.sellerStatus)}</Badge> : 'N/A',
      'RTO Status': d.type == 'order' ? <Badge color={color(d.rtoStatus)} pill className='text-white'>{helper.textCapitalize(d.rtoStatus == 'close' ? 'closed' : d.rtoStatus)}</Badge> : 'N/A',
      'Created By': d.createdBy
    }
  ));

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: false,
    selectableRows: false,
    rowsPerPage: 100,
    page: page1,
    fixedHeader: false,
    print: false,
    download: false,
    filter: true,
    count: ticketList && ticketList.count,
    serverSide: true,
    server: true,
    selectableRowsOnClick: false,
    selectableRows: 'none',
    fixedHeader: false,
    search: true,
    searchText: state.searchedText,
    rowsPerPageOptions: [],
    rangePage: true,

    onTableChange: (action, tableState) => {
      console.log('action=', action, tableState)
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key == 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleCloseSearch();
          break;
        case 'sort':
          sort(tableState.announceText);
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }
  const { loading } = props;
  return (
    <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
      {loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> :
        <ThemeProvider theme={createTheme({ components: styles })}>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
            style={{ borderRadius: '0px !important' }}
          />
        </ThemeProvider>
      }
    </div>
  );
}

const mapStateToProps = ({ admin }) => {
  const { ticketList, loading } = admin;
  return { ticketList, loading }
};

export default connect(mapStateToProps, { getTicketList })(Open);

