import {all} from 'redux-saga/effects';
import mailSagas from './Mail';
import toDoSagas from './Todo';
import contactSagas from './Contact';
import chatSagas from './Chat';
import authSagas from './Auth';
// import seller from './seller'
import notification from './notification'
import admin from './admin'
// import order from './order'
// import buyer from './buyer'

export default function* rootSaga(getState) {
  yield all([
    mailSagas(),
    toDoSagas(),
    contactSagas(),
    chatSagas(),
    authSagas(),
    // seller(),
    notification(),
    // order(),
    admin()
  ]);
}
