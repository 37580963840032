import React from 'react';
import { useNavigate } from "react-router-dom"
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { changeTab } from 'actions/tabAction';
import { connect } from 'react-redux';
import ShortbookList from '../../../../Shortbook/routes/ShortbookList';
import ShortbookHistory from '../../../../Shortbook/routes/ShortbookHistory';

function TabContainer({ children, value, tabValue }) {
  return (
    (value === tabValue) && <div>
      {children}
    </div>
  );
}

const FullWidthShortbookTabs = (props) => {
  const navigate = useNavigate()

  const handleChange = (event, value) => {
    props.changeTab(value)
    navigate(`/admin/shortBook`)
  };

  const { tabValue } = props;
  return (
    <div className="w-100" >
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
        >
          <Tab className="tab" label="Open" />
          <Tab className="tab" label="Fulfilled" />
        </Tabs>
      </AppBar>
      <TabContainer value={0} tabValue={tabValue}>
        <ShortbookList tab={tabValue} />
      </TabContainer>
      <TabContainer value={1} tabValue={tabValue}>
        <ShortbookHistory tab={tabValue} /> 
      </TabContainer>
    </div>
  );
}

const mapStateToProps = ({ tabAction }) => {
  const { tabValue } = tabAction;
  return { tabValue }
};

export default connect(mapStateToProps, { changeTab })(FullWidthShortbookTabs);