import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import { FormGroup, Label, Input } from 'reactstrap';
import moment from 'moment';
import DatePicker from "react-datepicker";
import AxiosRequest from 'sagas/axiosRequest'
import ReactStrapTextField from '../../components/ReactStrapTextField';
import ReactStrapSelectField from '../../components/reactstrapSelectField';
import { NotificationManager } from 'react-notifications';
import { Form, Field } from 'react-final-form'
import { getInventoryListPopUp, getInventoryList, getChangeDealOfTheDay, getChangeJumboDeal, getChangeFastMoving } from 'actions/admin'
import { connect } from 'react-redux'
import { required, dicount100, number, maxPurchase, percentageValidation, discountValidation, maxLength4, noDecimal, minLessAvail, maxLessAvail, number0, maxGreaterMin, twoDecimal, maxLessMin } from 'constants/validations';
import AutoCompleteSearch from 'components/asyncAutocmplete/index'
import TextBox from 'components/textBox';
import './index.css'
import Switch from '@mui/material/Switch';
import CircularProgress from "@mui/material/CircularProgress";

function EditInventory(props) {
  const [error, setError] = React.useState(null);
  let url = window.location.search
  let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''
  let dataForCat = json ? json.selectedCategoryI ? json.selectedCategoryI : ['Category'] : ['Category'];
  let dat = dataForCat.length > 0 && dataForCat[0] !== "Category" ? dataForCat.split(',') : [];
  let requestObj = {
    page: json ? json.pageI ? Number(json.pageI) : 1 : 1,
    perPage: 50,
    filter: json ? json.filterI ? json.filterI : 'none' : 'none',
    searchText: json ? json.searchI ? json.searchI : '' : '',
    selectedCategory: dat,
    visible: json ? json.visibleI === 'visible' ? false : json.visibleI === 'hidden' ? true : 'none' : 'none',
    sellerId: (json && json.sellerId && json.sellerId !== 'none') ? json.sellerId : '',
    companyId: (json && json.companyId && json.companyId !== 'none') ? json.companyId : ''
  }
  const [errorShow, setErrorShow] = useState('');
  const [state, setState] = useState({
    open: false,
    name: '',
    chemicalCombination: '',
    expiryDate: new Date(moment().add(3, 'months').startOf('month')),
    MRP: '',
    PTR: '',
    minimumOrderQty: '',
    maximumOrderQty: '',
    GST: '',
    type: '',
    productCategory: '',
    discount: 'nodiscount',
    discountCap: 0,
    percentOff: 1,
    buy: 1,
    get: 1,
    getProduct: '',
    companyName: '',
    totalQuantity: '',
    searchItem: '',
    options: [],
    selectedData: '',
    options2: [],
    otherSelectedProduct: '',
    otherName: '',
    searchOtherProdcut: '',
    checkedA: false,
    checkedAt: false,
    checkAt: false,
    prepaidInven: false,
    selectData: '',
    batchNumber: '',
  });

  useEffect(() => {
    let data4 = {
      inventory_id: props.Inventory_id,
    }
    props.getInventoryListPopUp(data4)
  }, [])

  const handleRequestClose = () => {
    setState({ ...state, open: false });
    handleClose(props.Detail ? 'edit' : 'add');
  };

  const onSubmit = async (values) => {
    if(expiryDate){
    let { discount, PTR, minimumOrderQty, get, percentOff, maximumOrderQty, MRP, totalQuantity, prepaidInven, batchNumber  } = values;
    let totalAmt = await (discount === 'Same' && PTR) ? ((Number(PTR) * Number(minimumOrderQty)) / (Number(minimumOrderQty) + (Number(get) ? Number(get) : ''))).toFixed(2) :
      (discount === 'Discount' && PTR) ? percentOff ? (Number(PTR) - (((percentOff ? Number(percentOff) : 100) / 100) * Number(PTR))).toFixed(2) < 0 ? 0 : (Number(PTR) - (((percentOff ? Number(percentOff) : 100) / 100) * Number(PTR))).toFixed(2) : (((percentOff ? Number(percentOff) : 100) / 100) * Number(PTR)).toFixed(2) : PTR ? Number(PTR).toFixed(2) : 0;
    let data = {
      Id: props.Detail ? props.Detail._id : '',
      product_id: props.Detail.product_id._id,
      company_id: props.Detail.company_id._id,
      chemicalCombination: state.chemicalCombination,
      MRP: MRP,
      PTR: PTR,
      quantity: totalQuantity,
      min_order_quantity: minimumOrderQty,
      max_order_quantity: maximumOrderQty,
      expiry_date: moment(state.expiryDate).endOf('month').format(),
      discount_name: discount ? discount !== 'nodiscount' ? discount : "" : "",
      discount_per: percentOff,
      purchase: minimumOrderQty,
      bonus: get,
      otherInventoryId: state.otherSelectedProduct ? state.otherSelectedProduct._id : '',
      discountId: props.Detail ? props.Detail.discount_id ? props.Detail.discount_id._id : '' : '',
      totalAmt: Number(totalAmt),
      prepaidInven: prepaidInven === true || prepaidInven === 'true' ? true : false,
      batchNumber,
    }
    if ((discount === 'Different' || discount === 'DifferentAndDiscount') && !state.otherSelectedProduct) {
      return NotificationManager.error("Please add bonus discount product")
    }
    let response = await AxiosRequest.axiosHelperFunc('post', 'inventory/editInventoryItem', '', data)
    handleClose()
    if (response.data.error) {
      NotificationManager.error(response.data.title)
    } else {
      NotificationManager.success(response.data.title)
      props.callMount();
    }
          
    }
  }

  const handleChange = async (e, key) => {
    const val = e.target.value
    if (key === "discount" && (val === "Different" || val === "DifferentAndDiscount")) {
      let body = { name: "" }
      let data = await AxiosRequest.axiosHelperFunc('post', 'inventory/searchInventory', '', body)
      let temp = []
      temp = data.data.detail
      setState({ ...state, options2: temp, otherName: "", [key]: val })
    } else {
      setState({ ...state, [key]: val });
    }
  }

  const setDetailState = async () => {
    if (props && props.Detail) {
      const { Detail } = props;
      const newState = {
        name: Detail.product_id?.name || state.name,
        countryOfO: Detail.product_id?.country_of_origin || state.country_of_origin,
        chemicalCombination: Detail.product_id?.chem_combination || state.chemicalCombination,
        MRP: Detail.MRP || state.MRP,
        PTR: Detail.PTR || state.PTR,
        GST: Detail.product_id?.GST?.name || state.GST,
        type: Detail.product_id?.Type?.name || state.type,
        productCategory: Detail.product_id?.product_cat_id?.name || state.productCategory,
        discount: Detail.discount_id?.name || state.discount,
        discountCap: Detail.product_id?.discountCap || state.discountCap,
        percentOff: Detail.discount_id?.discount_per || state.percentOff,
        buy: Detail.discount_id?.discount_on_product?.purchase || state.buy,
        get: Detail.discount_id?.discount_on_product?.bonus || state.get,
        totalQuantity: Detail.quantity || state.totalQuantity,
        companyName: Detail.company_id?.name || state.companyName,
        minimumOrderQty: Detail.min_order_quantity || state.minimumOrderQty,
        maximumOrderQty: Detail.max_order_quantity || state.maximumOrderQty,
        otherName: Detail.discount_id?.discount_on_product?.inventory_id?.productName || state.otherName,
        otherSelectedProduct: Detail.discount_id?.discount_on_product?.inventory_id || state.otherSelectedProduct,
        expiryDate: Detail.expiry_date ? new Date(Detail.expiry_date) : new Date(moment().add(3, 'months').startOf('month')),
        prepaidInven: Detail.prepaidInven || state.prepaidInven,
        checkedA: Detail ? Detail.isDealsOfTheDay : false,
        checkedAt: Detail ? Detail.isJumboDeal : false,
        checkAt: Detail ? Detail.isFastMoving : false,
        batchNumber : Detail ? Detail.batchNumber :''
      };
      setState({ ...state, ...newState });
      if (Detail.discount_id?.discount_on_product?.inventory_id?.productName) {
        let body = { name: Detail.discount_id.discount_on_product.inventory_id.productName }
        let data = await AxiosRequest.axiosHelperFunc('post', 'inventory/searchInventory', '', body)
        let temp = data.data.detail
        setState((prevState) => ({
          ...prevState,
          options2: temp,
          otherName: Detail.discount_id.discount_on_product.inventory_id.productName
        }));
      }
    }
  };

  useEffect(() => {
    setDetailState();
  }, [props.Detail]);

  const hamdleSerchAnotherProduct = async (e) => {
    if (e.target.value) {
      let body = { name: e.target.value };
      let data = await AxiosRequest.axiosHelperFunc('post', 'inventory/searchInventory', '', body);
      let temp = data.data.detail;
      setState({ ...state, options2: temp, otherName: e.target.value })
    } else {
      setState({ ...state, options2: [], otherName: e.target.value })
    }
  };

  const selectAnother = (data) => {
    setState({ ...state, otherSelectedProduct: data, otherName: data ? data.productName : '' })
  }

  const handleSearchChange = async (e, val) => {
    if (val) {
      let body = { name: val }
      let data = await AxiosRequest.axiosHelperFunc('post', 'product/searchProduct', '', body)
      let temp = []
      temp = data.data.detail
      setState({ ...state, options: temp, selectedData: '' })
    } else {
      setState({ ...state, options: [], selectedData: '' })
    }
  }

  const selectData = (data) => {
    if (data) {
      setState({
        ...state,
        selectData: data, chemicalCombination: data.chem_combination, companyName: data.company_id.name,
        GST: data.GST.name, productCategory: data.product_cat_id.name, type: data.Type.name, name: data.name
      })
    } else {
      setState({
        ...state,
        selectData: data, chemicalCombination: '', companyName: '',
        GST: '', productCategory: '', type: '', name: ''
      })
    }

  }

  const handleDateChange = (data) => {
    setState({ ...state, expiryDate: data })
  }

  function handleClose() {
    setState({
      ...state,
      open: false,
      name: '',
      chemicalCombination: '',
      expiryDate: new Date(moment().add(3, 'months').startOf('month')),
      MRP: '',
      PTR: '',
      minimumOrderQty: '',
      maximumOrderQty: '',
      GST: '',
      type: '',
      productCategory: '',
      discount: '',
      percentOff: '',
      buy: 1,
      get: 1,
      getProduct: '',
      companyName: '',
      totalQuantity: '',
      searchItem: '',
      options: [],
      selectedData: '',
      selectData: '',
      prepaidInven: false,
      batchNumber: '',
    })
    props.handleClick(props.Detail ? 'edit' : 'add', props.Detail)
  }

  const handleChangeForDeal = name => (event, checked) => {
    let data;
    if (checked) {
      setState({ ...state, checkedA: true });
      data = {
        id: props.editData && props.editData._id, isDeal: true
      }
      props.getChangeDealOfTheDay({ data })
      props.getInventoryList({ data: requestObj })
    } else {
      setState({ ...state, checkedA: false });
      data = {
        id: props.editData && props.editData._id, isDeal: false
      }
      props.getChangeDealOfTheDay({ data })
      props.getInventoryList({ data: requestObj })
    }
  };

  const handleChangeJumboDeal = name => (event, checked) => {
    let data;
    if (checked) {
      setState({ ...state, checkedAt: true });
      data = {
        id: props.editData && props.editData._id, isJumboDeal: true
      }
      props.getChangeJumboDeal({ data })
      props.getInventoryList({ data: requestObj })
    } else {
      setState({ ...state, checkedAt: false });
      data = {
        id: props.editData && props.editData._id, isJumboDeal: false
      }
      props.getChangeJumboDeal({ data })
      props.getInventoryList({ data: requestObj })
    }
  };

  const handleChangeFastMoving = name => (event, checked) => {
    let data;
    if (checked) {
      setState({ ...state, checkAt: true });
      data = {
        id: props.editData && props.editData._id, isFastMoving: true
      }
      props.getChangeFastMoving({ data })
      props.getInventoryList({ data: requestObj })
    } else {
      setState({ ...state, checkAt: false });
      data = {
        id: props.editData && props.editData._id, isFastMoving: false
      }
      props.getChangeFastMoving({ data })
      props.getInventoryList({ data: requestObj })
    }
  };

  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val === key)
    return index > -1 ? true : false
  }
    const setError1=()=>{
      if(expiryDate){
    setError(false)
      }else{
    setError(true)
      }
    }
  let { buttonType } = props;
  let { name, options, options2, otherName,
  chemicalCombination, expiryDate, MRP, PTR, minimumOrderQty, maximumOrderQty, GST, type, productCategory, discount, percentOff, buy, get, companyName, totalQuantity, countryOfO, prepaidInven, batchNumber, discountCap } = state;

  function calculateDiscountPercentage(values) {
    const { discount } = values;
    const PTR = Number(values.PTR || 0)
    const get = Number(values.get || 0)
    const minimumOrderQty = Number(values.minimumOrderQty || 0)
    const percentOff = Number(values.percentOff || 0)
    if (!discountCap) {
      setErrorShow(false)
      return;
    }
    switch (discount) {
      case "Discount":
        (discountCap < percentOff) ? setErrorShow(true) : setErrorShow(false);
        break;

      case "Same":
        const totalAmtSame = (minimumOrderQty + get) * PTR;
        const costofFreeSame = get * PTR;
        const discountPerSame = (costofFreeSame / totalAmtSame) * 100;
        (discountCap < discountPerSame) ? setErrorShow(true) : setErrorShow(false);
        break;

      case "Different":
        const priceOfOtherProductDiff = state.otherSelectedProduct && state.otherSelectedProduct.PTR ? state.otherSelectedProduct.PTR : 0;
        const totalAmtDiff = (minimumOrderQty * PTR) + (get * priceOfOtherProductDiff);
        const constofFreeProdDiff = get * priceOfOtherProductDiff;
        const discountPerDiff = (constofFreeProdDiff / totalAmtDiff) * 100;
        (discountCap < discountPerDiff) ? setErrorShow(true) : setErrorShow(false);
        break;

      case "SameAndDiscount":
        const totalAmtSameDiscount = (minimumOrderQty + get) * PTR;
        const calDisc = (minimumOrderQty * PTR * percentOff) / 100
        const costofFreeSameDiscount = get * PTR;
        const totalDiscountCost = calDisc + costofFreeSameDiscount;
        const discount1SameDiscount = (totalDiscountCost / totalAmtSameDiscount) * 100;
        (discountCap < discount1SameDiscount) ? setErrorShow(true) : setErrorShow(false);
        break;

      case "DifferentAndDiscount":
        const otherProductPTR = state.otherSelectedProduct && state.otherSelectedProduct.PTR ? state.otherSelectedProduct.PTR : 0;
        const totalAmt = (minimumOrderQty * PTR) + (get * otherProductPTR);
        const freeAmt = get * otherProductPTR;
        const calDisc1 = (minimumOrderQty * PTR * percentOff) / 100
        const discount1DiffDiscount = ((freeAmt + calDisc1) / totalAmt) * 100;
        (discountCap < discount1DiffDiscount) ? setErrorShow(true) : setErrorShow(false);
        break;

      default:
        setErrorShow(false);
    }
  }

  return (
    <React.Fragment>
      <Dialog open={props.buttonType === 'edit' ? props.edit : props.add} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'md'}>
        {props.loading ?
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
            <CircularProgress disableShrink />
          </div>
          :
        <Form
          onSubmit={onSubmit}
          initialValues={{
            MRP: MRP,
            PTR: PTR,
            totalQuantity: totalQuantity,
            GST: GST,
            minimumOrderQty: minimumOrderQty,
            maximumOrderQty: maximumOrderQty,
            discount: discount,
            percentOff: percentOff,
            get: get,
            buy: buy,
            prepaidInven: prepaidInven,
            batchNumber: batchNumber,
          }}
          render={({ handleSubmit, values, form }) => {
            calculateDiscountPercentage(values);
            let noDiscPrice = ((values.discount === 'nodiscount' || values.discount === 'Different') && values.PTR) ? Number(values.PTR) : Number(values.PTR);
            let sameAndD = (values.discount === 'SameAndDiscount' && values.PTR) ? Number((Number(values.PTR) * Number(values.minimumOrderQty)) / (Number(values.minimumOrderQty) + (Number(values.get) ? Number(values.get) : ''))).toFixed(2) : 0;
            let sameAndDiscount = (Number(sameAndD) - (Number(sameAndD) / 100 * Number(values.percentOff))).toFixed(2);
            let diffrentAndDiscount = (Number(noDiscPrice) - (Number(noDiscPrice) / 100 * Number(values.percentOff))).toFixed(2);

            return <form noValidate onSubmit={handleSubmit} autoComplete="off">
              <Row>
              <DialogTitle>
                {props.title}
                {isButtonAvailable('fastMoving') && <span
                  style={{ float: 'right' }}
                >Fast Moving
                  <Switch
                    color='primary'
                    checked={state.checkAt}
                    onChange={handleChangeFastMoving('checkAt')}
                    aria-label="checkAt"
                    className="codToggle"
                  />
                </span>}
                {isButtonAvailable('jumboDeal') && <span
                  style={{ float: 'right' }}
                >Jumbo Deal
                  <Switch
                    color='primary'
                    checked={state.checkedAt}
                    onChange={handleChangeJumboDeal('checkedA')}
                    aria-label="checkedA"
                    className="codToggle"
                  />
                </span>}
                {isButtonAvailable('dealOfTheDay') && <span
                  style={{ float: 'right' }}
                >Deal Of The Day
                  <Switch
                    color='primary'
                    checked={state.checkedA}
                    onChange={handleChangeForDeal('checkedA')}
                    aria-label="checkedA"
                    className="codToggle"
                  />
                </span>}
              </DialogTitle>
              </Row>
              <DialogContent style={{overflow:"hidden"}}>
                <Row>
                  <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <AutoCompleteSearch disabled={true} value={state.name} options={options} handleDataChange={selectData} handleChange={handleSearchChange} key='name' />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <FormGroup>
                      <Label for="chemicalCombination">Chemical Combination</Label>
                      <Input disabled type="text" name="chemicalCombination" value={chemicalCombination} id="chemicalCombination" />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <FormGroup>
                      <Label for="companyName">Company Name</Label>
                      <Input disabled type="text" name="companyName" id="companyName" value={companyName} />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={4} lg={4}>
                    <Field id="totalQuantity" name="totalQuantity" type="number"
                      component={ReactStrapTextField} label={"Total Available Quantity"}
                      validate={composeValidators(required, number, noDecimal)}
                    />
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={4} lg={4}>
                    <FormGroup>
                      <Label for="countryOfO">Country Of Origin</Label>
                      <Input disabled type="text" name="countryOfO" id="countryOfO" value={countryOfO} />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} sm={12} xl={4} lg={4}>
                    <Label for="expiryDate">Expiry Date</Label>
                    <FormGroup>
                      <DatePicker
                        selected={expiryDate}
                        onChange={date => {
                          handleDateChange(date)
                          form.change("batchNumber", '')
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        className="form-control"
                        value={expiryDate}
                        minDate={new Date(moment().add(3, 'months').startOf('month'))}
                        onBlur={setError1}
                        customInput={<input style={{ borderColor: error?'red':''}} />}
                      />
                      {error && <div style={{ color: 'red', fontSize: '0.875em', color: '#f44336', marginTop: '0.35rem' }}>This field is required</div>}
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} sm={12} xl={4} lg={4}>
                    <FormGroup>
                      <Label for="GST">GST</Label>
                      <Input disabled type="text" name="GST" id="GST" value={GST}
                      >
                        <option value=" ">Select GST</option>
                        <option value='GST_0'>GST_0</option>
                        <option value='GST_5'>GST_5</option>
                        <option value='GST_12'>GST_12</option>
                        <option value='GST_18'>GST_18</option>
                        <option value='GST_28'>GST_28</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6} xs={12} lg={4} xl={4}>
                    <FormGroup>
                      <Label for="type">Type</Label>
                      <Input disabled type="text" name="type" id="text" value={type}
                      >
                        <option value="">Select type</option>
                        <option value='Bottle'>Bottle</option>
                        <option value='Tuber'>Tuber</option>
                        <option value='Box'>Box</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} sm={12} xl={4} lg={4}>
                    {props.Detail && props.Detail.medicineTypeName &&
                      <FormGroup>
                        <Label for="chemicalCombination">Medicine type</Label>
                        <Input disabled type="text" name="chemicalCombination" value={props.Detail && props.Detail.medicineTypeName} id="chemicalCombination" />
                      </FormGroup>
                    }
                  </Col>
                  <Col xs={12} md={6} sm={12} xl={4} lg={4}>
                    <Field id="MRP" name="MRP" type="text"
                      component={ReactStrapTextField} label={"MRP"}
                      validate={composeValidators(required, number, twoDecimal)}
                    />

                  </Col>
                  <Col xs={12} md={6} sm={12} xl={4} lg={4}>
                    <Field id="PTR" name="PTR" type="text"
                      component={ReactStrapTextField} label={"PTR"}
                      validate={composeValidators(required, number, twoDecimal)}
                    />
                  </Col>
                  <Col xs={12} md={6} sm={12} xl={4} lg={4}>
                    <FormGroup>
                      <Field id="prepaidInven" name="prepaidInven" type="select"
                        component={ReactStrapSelectField} label={"Prepaid Inventory"}
                      >
                        <option selected={values.prepaidInven === true} value={true}>TRUE</option>
                        <option selected={values.prepaidInven === false} value={false}>FALSE</option>
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={3} xs={12} lg={3} xl={3}>
                    <Field id="minimumOrderQty" name="minimumOrderQty" type="number"
                      component={ReactStrapTextField} label={"Lot size"}
                      validate={composeValidators(required, number, noDecimal, minLessAvail)}
                    />
                  </Col>
                  <Col sm={12} md={3} xs={12} lg={3} xl={3}>
                    <Field id="maximumOrderQty" name="maximumOrderQty" type="number"
                      component={ReactStrapTextField} label={"Maximum order quantity"}
                      validate={composeValidators(required, number, maxLength4, noDecimal, maxPurchase, maxLessAvail, maxGreaterMin, maxLessMin)}
                      pattern="[0-9]"
                    />
                  </Col>
                  <Col sm={12} md={3} xs={12} lg={3} xl={3}>
                    <FormGroup>
                      <Label for="Surcharge">Product Surcharge</Label>
                      <Input disabled type="text" name="Surcharge" value={props.Detail && props.Detail.Products && props.Detail.Products.surcharge && props.Detail.Products.surcharge ? `${props.Detail.Products.surcharge} %` : '0 %'} id="Surcharge" />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={3} xs={12} lg={3} xl={3}>
                    <FormGroup>
                      <Label for="isPrepaid">Prepaid Product</Label>
                      <Input disabled type="text" name="isPrepaid" value={props.Detail && props.Detail.Products && props.Detail.Products.isPrepaid && props.Detail.Products.isPrepaid === true ? 'TRUE' : 'FALSE'} id="isPrepaid" />
                    </FormGroup>
                  </Col>

                  <Col sm={12} md={4} xs={12} lg={4} xl={4}>
                    <FormGroup>
                      <Label for="productCategory">Product Category</Label>
                      <Input disabled type="text" name="productCategory" id="productCategory" value={productCategory}
                        onChange={(e) => handleChange(e, 'productCategory')}>
                        <option value="">Select category</option>
                        <option value='Medicine'>Medicine</option>
                        <option value='Cosmetic'>Cosmetic</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={4} xs={12} lg={4} xl={4}>
                    <FormGroup>
                      <Field id="discount" name="discount" type="select"
                        component={ReactStrapSelectField} label={"Discount"}
                        validate={required}
                      >
                        <option selected={values.discount === 'nodiscount'} value="nodiscount">Select discount</option>
                        <option selected={values.discount === 'Discount'} value='Discount'>Discount on PTR</option>
                        <option selected={values.discount === 'Same'} value='Same'>Same product bonus</option>
                        <option selected={values.discount === 'Different'} value='Different'>Different product bonus</option>
                        <option selected={values.discount === 'SameAndDiscount'} value='SameAndDiscount'>Same product bonus and Discount</option>
                        <option selected={values.discount === 'DifferentAndDiscount'} value='DifferentAndDiscount'>Different product bonus and Discount</option>
                      </Field>

                    </FormGroup>
                  </Col>
                  {moment(moment(expiryDate).endOf('month').format('YYYY-MM-DD')).diff(moment(moment().startOf('month').format('YYYY-MM-DD')), 'months', true) < (props.Detail && props.Detail.product_id && props.Detail.product_id.shortExpiry ? props.Detail.product_id.shortExpiry : 9) && 
                  <Col sm={12} md={4} xs={12} lg={4} xl={4}>
                    <Field id="batchNumber" name="batchNumber" type="text"
                      component={ReactStrapTextField} label={"Batch number"}
                    />
                  </Col>
                  }
                </Row>
                {
                  values.discount === 'Discount' ?
                    <React.Fragment>
                      <Row>
                        <Col sm={12} md={12} xs={12} lg={12} xl={12}>
                          <FormGroup>
                            <span className='d-flex'>
                              <span className='ml-3 mr-3 mt-3'>Get flat </span>
                              <Field style={{ border: '1px solid #ccc' }} id="percentOff" name="percentOff" type="number"
                                component={TextBox}
                                label={""}
                                validate={composeValidators(required, number, percentageValidation, number0, discountValidation, dicount100)}
                                fullWidth={false}
                                width={(parseInt(values.percentOff) < 0 || parseInt(values.percentOff) > 100) ? 100 : 35}
                                margin='dense'
                              />
                              <span className='ml-3 mr-3 mt-3'>% Off </span>
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </React.Fragment>
                    : values.discount === 'Different' ? <React.Fragment>
                      <Col sm={12} md={12} xs={12} lg={12} xl={12} className='d-flex ml-1'>
                        <FormGroup className='sm-7 md-7 lg-7 xl-7'>
                          <div className='d-flex  pr-4'>
                            <p style={{ marginTop: 18 }} className=' mr-2'>Buy </p>
                            <p className='mt-3 ml-2 mr-2'> {`${values.minimumOrderQty}`}</p>
                            <p style={{ marginTop: 18 }} className='ml-2 mr-2'> {`${name} and get`}</p>
                            <Field id="get" name="get" type="number"
                              component={TextBox} label={''}
                              validate={composeValidators(required, number0, number, noDecimal)}
                              width={(values.buy < 0) ? 100 : 35}
                              margin='dense'
                              min={1}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className=' mt-2 sm-4 md-4 lg-4 xl-4' width={100} >
                          <AutoCompleteSearch standard={true} value={otherName} placeholder={'Select product'} options={options2} handleDataChange={selectAnother} handleChange={hamdleSerchAnotherProduct} key='getProduct' />
                          <br />
                          {!state.otherSelectedProduct ? <Label className='text-danger'>Please add discount product</Label> : ''}
                        </FormGroup>
                        <FormGroup className='ml-2 sm-1 md-1 lg-1 xl-1' style={{ marginTop: 18 }}>
                          <p>Free</p>
                        </FormGroup>
                      </Col>
                    </React.Fragment> : values.discount === 'Same' ? <React.Fragment>
                      <Col sm={12} md={12} xs={12} lg={12} xl={12}>
                        <FormGroup>
                          <div className='d-flex pr-4'>
                            <p className='mt-3 mr-2'>Buy </p>
                            <p className='mt-3 ml-2 mr-2'> {`${values.minimumOrderQty}`}</p>
                            <p className='mt-3 ml-2 mr-2'> {`"${name}" and get`}</p>
                            <Field id="get" name="get" type="number"
                              component={TextBox} label={''}
                              validate={composeValidators(required, number0, number, noDecimal)}
                              width={(values.get < 0) ? 100 : 35}
                              margin='dense'
                              normalize={0}
                            />
                            <p className='mt-3 ml-2'> {`free`}</p>
                          </div>
                        </FormGroup>
                      </Col>
                    </React.Fragment> :
                      values.discount === 'SameAndDiscount' ? <React.Fragment>
                        <Col sm={12} md={12} xs={12} lg={12} xl={12}>
                          <FormGroup>
                            <div className='d-flex'>
                              <p className='mt-3 mr-2'>Buy </p>
                              <p className='mt-3 ml-2 mr-2'> {`${values.minimumOrderQty}`}</p>
                              <p className='mt-3 ml-2 mr-2'> {`${name} and get`}</p>
                              <Field id="get" name="get" type="number"
                                component={TextBox} label={''}
                                validate={composeValidators(required, number, number0, noDecimal)}
                                width={(values.buy < 0) ? 100 : 35}
                                margin='dense'
                              />
                              <p className='mt-3 ml-2'> {`free`}</p>
                              <span className='ml-1 mr-1 mt-3'>and add-on </span>
                              <Field id="percentOff" name="percentOff" type="number"
                                component={TextBox} label={""}
                                validate={composeValidators(required, number, number0, percentageValidation, discountValidation, dicount100)}
                                fullWidth={false}
                                width={(parseInt(values.percentOff) < 0 || parseInt(values.percentOff) > 100) ? 100 : 35}
                                margin='dense'
                              />
                              <span className='ml-1 mr-1 mt-3'>% Off </span>
                            </div>

                          </FormGroup>
                        </Col>
                      </React.Fragment> :
                        values.discount === 'DifferentAndDiscount' ? <React.Fragment>
                          <Row>
                            <Col sm={12} md={12} xs={12} lg={12} xl={12} className='d-flex ml-1'>
                              <FormGroup className='sm-7 md-7 lg-7 xl-7'>
                                <div className='d-flex  pr-4'>
                                  <p className='mt-3 mr-2'>Buy </p>
                                  <p className='mt-3 ml-2 mr-2'> {`${values.minimumOrderQty}`}</p>
                                  <p className='mt-3 ml-2 mr-2'> {`${name} and get`}</p>
                                  <p className='mt-3 ml-2 mr-2'> {`${name} and get`}</p>
                                  <Field id="get" name="get" type="number"
                                    component={TextBox} label={''}
                                    validate={composeValidators(required, number, number0, noDecimal)}
                                    width={(values.buy < 0) ? 100 : 35}
                                    margin='dense'
                                    min={1}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={4} md={4} lg={4} xl={4} className='pr-0'>
                              <FormGroup className=' mt-2 ' width={100} >
                                <AutoCompleteSearch standard={true} value={otherName} placeholder={'Select product'} options={options2} handleDataChange={selectAnother} handleChange={hamdleSerchAnotherProduct} key='getProduct' />
                                {!state.otherSelectedProduct ? <Label className='text-danger'>Please add discount product</Label> : ''}
                              </FormGroup>
                            </Col>
                            <Col sm={8} md={8} lg={8} xl={8} className='pl-0'>
                              <FormGroup  >
                                <span className='d-flex'>
                                  <span className='ml-3 mr-3 mt-2'>Free and add-on </span>
                                  <Field id="percentOff" name="percentOff" type="number"
                                    component={TextBox} label={""}
                                    validate={composeValidators(required, number, number0, percentageValidation, discountValidation, dicount100)}
                                    fullWidth={false}
                                    width={(parseInt(values.percentOff) < 0 || parseInt(values.percentOff) > 100) ? 100 : 35}
                                    margin='dense'
                                  />
                                  <span className='ml-3 mr-3 mt-2'>% Off </span>
                                </span>
                              </FormGroup>
                            </Col>
                          </Row>
                        </React.Fragment> : ''
                }
                {errorShow ? <Label className='text-danger'>Discount should not be greater than {discountCap}%</Label> : ''}
                <div>
                  <span className="text-success float-right pr-4 pt-2" style={{ fontSize: 15 }}>Effective PTR price is {<span>&#8377;{
                    (values.discount === 'SameAndDiscount' && values.PTR) ? sameAndDiscount :
                      (values.discount === 'DifferentAndDiscount' && values.PTR) ? diffrentAndDiscount :
                        (values.discount === 'Same' && values.PTR) ? ((Number(values.PTR) * Number(values.minimumOrderQty)) / (Number(values.minimumOrderQty) + (Number(get) ? Number(get) : ''))).toFixed(2) :
                          (values.discount === 'Discount' && values.PTR) ? percentOff ? (Number(values.PTR) - (((percentOff ? Number(percentOff) : 100) / 100) * Number(values.PTR))).toFixed(2) < 0 ? 0 : (Number(values.PTR) - (((percentOff ? Number(percentOff) : 100) / 100) * Number(values.PTR))).toFixed(2) : (((percentOff ? Number(percentOff) : 100) / 100) * Number(values.PTR)).toFixed(2) : values.PTR ? Number(values.PTR).toFixed(2) : 0}</span>}</span>
                </div>
              </DialogContent>
              <DialogActions className="pr-4">

                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                {isButtonAvailable('editInventoryItem') && (
                  buttonType === 'edit' ? <Button disabled={errorShow} type='submit' color='primary'>
                    Submit
                  </Button> : <Button disabled={errorShow} type='submit' color='primary'>
                    Add
                  </Button>)
                }

              </DialogActions>
            </form>
          }}
        />}
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = ({ admin }) => {
  const { Detail,loading } = admin;
  return { Detail, loading }
};

EditInventory = connect(
  mapStateToProps,
  {
    getInventoryList,
    getChangeDealOfTheDay,
    getChangeJumboDeal,
    getChangeFastMoving,
    getInventoryListPopUp
  }
)(EditInventory)

export default EditInventory
