import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppConfig from 'constants/config'
import { connect } from 'react-redux';
import { getAllManifestList } from 'actions';
import './index.css';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';

const ManifestHeader = (props) => {
  const [file, setFile] = useState(null)
  const [open, setOpen] = useState(false)
  let uploadcsv = useRef(null)

  const onChange = (e) => {
    setFile(e.target.files[0])
  }

  const fileUpload = (file) => {
    const url = `${AppConfig.baseUrl}manifest/manifestByCsv`;
    const formData = new FormData();
    formData.append('fileUpload', file)
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: localStorage.getItem('admin_token')
      }
    }
    return axios.post(url, formData, config)
  }

  const handleRequestClose = () => {
    setOpen(false);
  }

  const onFormSubmit = () => {
    fileUpload(file).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title)
        let data = {
          page: 1,
          perPage: 100,
          from_date: '',
          to_date: '',
          seller_id: '',
          tab: "newManifest"
        }
        props.getAllManifestList({ data });
      }
      setOpen(false)
      setFile(null);
    })
  }

  const { tabValue } = props;
  
  return (
    <div className="page-heading buyerDetailsHeader d-flex justify-content-between align-items-sm-center">
      <h2 className="title mb-3 mb-sm-0">Manifest </h2>
      {tabValue === 0 && <Button variant="outlined" className={'text-primary'} onClick={() => { setOpen(true) }}>
        Upload CSV
      </Button>}
      <Dialog open={open} onClose={handleRequestClose}>
        <DialogTitle>Upload CSV</DialogTitle>
        <DialogContent>
          <form onSubmit={onFormSubmit}>
            <Row>
              <Col md={3} xl={3} xs={3} sm={3} lg={3} > </Col>
              <Col md={5} xl={5} xs={5} sm={5} lg={5}>
                <div className='text-center'>
                  <input
                    type='file'
                    accept='.csv'
                    style={{ display: 'none' }} required
                    onChange={(e) => onChange(e)} ref={(ref) => uploadcsv = ref}
                  />
                  <img alt="camera" src={file !== null ? require('../../../../assets/img/camera_3.png') : require('../../../../assets/img/camera_1.png')} className='d-block mx-auto' onClick={(e) => uploadcsv.click()} />
                  <label >Csv</label>
                </div>
              </Col>
            </Row>
            <DialogActions>
              <Button onClick={() => handleRequestClose()} color='secondary'>Cancel</Button>
              <Button type='submit' color='primary'>Upload</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const mapStateToProps = ({ admin, tabAction }) => {
  const { adminSettlements, actionList } = admin;
  const { tabValue } = tabAction;
  return { adminSettlements, actionList, tabValue }
};

export default connect(mapStateToProps, { getAllManifestList })((ManifestHeader));