import React from 'react';
import ContainerHeader from 'components/ContainerHeader';
import Button from '@mui/material/Button';
import { required, minLength6, confirmPasswordMatch } from 'constants/validations';
import { Field, Form } from 'react-final-form'
import renderTextField from 'components/textBox';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';
import AdminContainer from "containers/AdminContainer";

const ChangePAssword = (props) => {
  const onSubmit = async (values) => {
    let data = {
      password: values.password,
      confirmPassword: values.confirmPassword,
    }
    await axios.post('/users/changePassword', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      } else {
        NotificationManager.success(result.data.title);
      }
    }).catch(error => {
      console.log("error", error)
      NotificationManager.error('Something went wrong, Please try again')
    });
  }

  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  return (
    <AdminContainer>
      <div className="col-xl-12 col-lg-12">
        <ContainerHeader title="Change Password" />
        <div className="jr-card">
          <Form
            onSubmit={onSubmit}
            initialValues={{
              password: '',
              confirmPassword: ''
            }}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Field id="password" name="password" type="password"
                  component={renderTextField} label="New Password"
                  validate={composeValidators(required, minLength6)}
                />
                <Field name="confirmPassword" type="password"
                  component={renderTextField} label="Confirm Password"
                  validate={confirmPasswordMatch('password')}
                />
                <div className={'customButton'}>
                  <Button style={{ backgroundColor: '#072791' }} variant="contained" color="primary"
                    type="submit">Save</Button>
                </div>
              </form>
            )}
          />
        </div>
      </div >
    </AdminContainer>
  );
}

export default ChangePAssword