import React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { changeTab } from 'actions/tabAction';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom"
import New from '../../../../manifest/routes/New';
import Verified from '../../../../manifest/routes/Verified';
import Discarded from '../../../../manifest/routes/Discarded';

function TabContainer({ children, value, tabValue }) {
    return (
        (value === tabValue) && <div>
            {children}
        </div>
    );
}

const FullWidthTabForManifest = (props) => {
    const navigate = useNavigate()

    const handleChange = (event, value) => {
        props.changeTab(value)
        navigate(`/admin/manifest`, { replace: true })
    };

    const { tabValue } = props;
    return (
        <div className="w-100" >
            <AppBar position="static" color="default">
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    scrollButtons="on"
                >
                    <Tab className="tab" label="New" />
                    <Tab className="tab" label="Verified" />
                    <Tab className="tab" label="Discrepancy" />
                </Tabs>
            </AppBar>
            <TabContainer value={0} tabValue={tabValue}>
                <New tab={tabValue} />
            </TabContainer>
            <TabContainer value={1} tabValue={tabValue}>
                <Verified tab={tabValue} />
            </TabContainer>
            <TabContainer value={2} tabValue={tabValue}>
                <Discarded tab={tabValue} />
            </TabContainer>
        </div>
    );
}

const mapStateToProps = ({ tabAction }) => {
    const { tabValue } = tabAction;
    return { tabValue }
};

export default connect(mapStateToProps, { changeTab })(FullWidthTabForManifest);
