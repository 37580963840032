// import React from 'react';
// import { connect } from 'react-redux';
// import { TextField,CircularProgress , IconButton} from '@mui/material';
// import Button from '@mui/material/Button';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
// import { Col, Row } from 'reactstrap';
// import {
//   hideMessage,
//   showAuthLoader,
//   userSignIn,
// } from 'actions/Auth';

// class ForgotPassword extends React.Component {
//   constructor() {
//     super();
//     this.state = {}
//   }

//   componentDidUpdate() {
//     if (this.props.showMessage) {
//       setTimeout(() => {
//         this.props.hideMessage();
//       }, 100);
//     }
//     if (this.props.authUser !== null) {
//       this.props.history.push('/');
//     }
//   }

//   render() {
//     const { } = this.state;
//     const { showMessage, loader, alertMessage } = this.props;
//     return (
//       <div className="col-xl-12 col-lg-12 forgotPasswordContainer" >
//         <div className="jr-card forgotPasswordCard">
//           <div className="animated slideInUpTiny animation-duration-3">
//             {/* <div className="login-header">
//               <a className="app-logo" href='javascript:void(0);' title="Jambo">
//                 <h2>Medimny</h2>
//               </a>
//             </div> */}
//             <div className="mb-4">
//               <h3>Forgot Password</h3>
//             </div>
//             <form method="get" action="">
//               <Row form>
//                 <Col>
//                   <TextField
//                    variant='standard'
//                     type="email"
//                     id="required"
//                     label={'Email'}
//                     fullWidth={true}
//                     defaultValue=""
//                     margin="normal"
//                     className="mt-0 mb-4"
//                   />
//                 </Col>
//                 {/* <Col>
//                   <TextField
//                     type="password"
//                     id="required"
//                     label={'OTP'}
//                     fullWidth={true}
//                     defaultValue=""
//                     margin="normal"
//                     className="mt-0 mb-4"
//                   />
//                 </Col> */}
//               </Row>
//               <div className="mb-2">
//                 <Button color="primary" variant="contained" className="text-white"
//                 >
//                   Submit
//                    </Button>
//               </div>
//             </form>
//             <div>
//               {
//                 loader &&
//                 <div className="loader-view">
//                   <CircularProgress />
//                 </div>
//               }
//               {showMessage && NotificationManager.error(alertMessage)}
//               <NotificationContainer />
//             </div>
//           </div>
//         </div>
//       </div>

//     );
//   }
// }

// const mapStateToProps = ({ auth }) => {
//   const { loader, alertMessage, showMessage, authUser } = auth;
//   return { loader, alertMessage, showMessage, authUser }
// };

// export default connect(mapStateToProps, {
//   userSignIn,
//   hideMessage,
//   showAuthLoader,
// })(ForgotPassword);


import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, CircularProgress, Button } from '@mui/material';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Col, Row } from 'reactstrap';
import { hideMessage, showAuthLoader, userSignIn } from 'actions/Auth';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (props.showMessage) {
      setTimeout(() => {
        props.hideMessage();
      }, 100);
    }
    if (props.authUser !== null) {
      props.history.push('/');
    }
  }, [props.showMessage, props.authUser, props.history]);

  const handleSubmit = () => {
    // Add logic to handle form submission
    // You can use 'email' state to get the entered email
  };

  const { showMessage, loader, alertMessage } = props;

  return (
    <div className="col-xl-12 col-lg-12 forgotPasswordContainer">
      <div className="jr-card forgotPasswordCard">
        <div className="animated slideInUpTiny animation-duration-3">
          <div className="mb-4">
            <h3>Forgot Password</h3>
          </div>
          <form method="get" action="">
            <Row form>
              <Col>
                <TextField
                  variant="standard"
                  type="email"
                  id="required"
                  label={'Email'}
                  fullWidth={true}
                  defaultValue=""
                  margin="normal"
                  className="mt-0 mb-4"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </Row>
            <div className="mb-2">
              <Button
                color="primary"
                variant="contained"
                className="text-white"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </form>
          <div>
            {loader && (
              <div className="loader-view">
                <CircularProgress />
              </div>
            )}
            {showMessage && NotificationManager.error(alertMessage)}
            <NotificationContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
})(ForgotPassword);
