import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import moment from 'moment';
import { connect } from 'react-redux';
import { getVarifiedManifestList } from "actions/admin";
import CircularProgress from '@mui/material/CircularProgress';
import './index.css';
import { Col, Row } from "reactstrap";
import EditMEnifestData from "../components/EditMEnifestData";
import { Label, Input } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import axios from "axios";
import AppConfig from "constants/config";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Input1 from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

const payment_mode = { 'COD': "COD", "Prepaid": "Online", "Bulk Prepaid": "Bulk" }
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const Verified = (props) => {
  const perPage = 100
  const [seller_id, setSellerId] = useState('')
  const [searchedText, setSearchedText] = useState('')
  const [page, setPage] = useState(0)
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [type, setType] = useState([])
  const [images, setImages] = useState([])
  const [menifestId, setMenifestId] = useState('')
  const [editMenifest, setEditMenifest] = useState(false)
  const [filterData, setFilterData] = useState('')
  const [manifestData, setManifestData] = useState('')

  const handleSettelMent = async () => {
    window.open(`${AppConfig.baseUrl}manifest/manifestVarifiedCsv?token=${localStorage.getItem('admin_token')}&from_date=${from ? moment(from).format('YYYY-MM-DD') : ''}&to_date=${to ? moment(to).add(1, 'days').format('YYYY-MM-DD') : ''}&type=${type ? type : ''}&seller_id=${seller_id ? seller_id : ''}&tab=verified`, '_blank');
  }

  const changePage = (page) => {
    let data = {
      page: page + 1,
      perPage,
      searchText: searchedText,
      filter: '',
      from_date: from ? from : '',
      to_date: to ? to : '',
      type: type ? type : '',
      seller_id: seller_id ? seller_id : '',
      tab: "verified"
    }
    props.getVarifiedManifestList({ data })
    setPage(page)
  };

  const handleClick = (key, value) => {
    setEditMenifest(!editMenifest)
  }

  useEffect(() => {
    getFilters()
  }, [])

  const getFilters = async () => {
    let data = {
      page: page + 1,
      perPage: 100,
      filter: '',
      searchText: searchedText,
      from_date: '',
      to_date: '',
      seller_id: seller_id,
      type: type,
      tab: "verified",
    }
    if (props.tab === 1) {
      props.getVarifiedManifestList({ data })
      await axios({
        method: 'get',
        url: `${AppConfig.baseUrl}inventory/get_filters`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('admin_token')
        }
      }).then(result => {
        if (result.data.error) {
          console.log("error", result.data)
        } else {
          setFilterData(result.data.detail.sellers)
        }
      }).catch(error => {
        console.log("error catch", error)
      });
    }
  }

  const handleshowImg = (value) => {
    setImages(value.images)
    setEditMenifest(true)
    setMenifestId(value._id)
    setManifestData(value)
  }

  const throttleHandleChange = (edata) => {
    props.getVarifiedManifestList({ data: edata })
  }

  const handleSearch = (searchText) => {
    let data = {
      searchText: searchText, page: 1, perPage: perPage, from_date: from ? moment(from).format('YYYY-MM-DD') : "",
      to_date: to ? moment(to).add(1, 'days').format('YYYY-MM-DD') : "", seller_id: seller_id ? seller_id : '', type: type ? type : [], tab: "verified"
    }
    setPage(0)
    throttleHandleChange(data)
    setSearchedText(searchText)
  };

  const handleDateChange = (date, key) => {
    if (key === 'from') {
      setFrom(date)
    } else {
      setTo(date)
    }
  }

  const resetFilter = (filter) => {
    let data = {
      page: 1,
      perPage: perPage,
      filter: '',
      seller_id: '',
      from_date: '',
      to_date: '',
      tab: "verified"
    }
    props.getVarifiedManifestList({ data })
    setPage(0)
    setSellerId('')
    setFrom()
    setTo()
    setType('')
  }

  const applyFilter = (filter) => {
    let data = {
      page: 1,
      perPage: perPage,
      searchText: searchedText ? searchedText : '',
      from_date: from ? moment(from).format('YYYY-MM-DD') : '',
      to_date: to ? moment(to).add(1, 'days').format('YYYY-MM-DD') : '',
      seller_id: seller_id ? seller_id : '',
      type: type,
      tab: "verified"
    }
    props.getVarifiedManifestList({ data })
    setPage(0)
  }

  const handleSelectChangePaymentType = (e) => {
    let tempSelectedCat = e.target.value;
    let index = tempSelectedCat && tempSelectedCat.length > 0 ? tempSelectedCat.findIndex((e) => e === 'type') > -1 ? tempSelectedCat.findIndex((e) => e === 'type') : -1 : -1;
    index > -1 && tempSelectedCat.splice(index, 1);
    setType(tempSelectedCat);
  }

  const handleChanged = (e, key) => {
    setSellerId(e.target.value);
  }

  const { manifestVerified } = props;

  const columns = [
    {
      name: "Order ID",
      label: <span>&nbsp;&nbsp;&nbsp;&nbsp;Order ID</span>,
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "seller",
      label: "Seller",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "awb",
      label: "AWB",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "Order_amt",
      label: "Order Amount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "delivery date",
      label: "Delivery Date",
      options: {
        filter: true,
        sort: false,
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          display: () => (
            <React.Fragment >
              <Row form style={{ maxWidth: 300 }}>
                <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                  <FormControl className="w-100 mb-2 ">
                    <Label for="fromDate">FROM</Label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDatePicker
                        onChange={(date) => handleDateChange(date, 'from')}
                        name='from'
                        id="from"
                        value={from}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        slotProps={{ textField: { variant: 'standard' } }}
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Col>
                <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                  <FormControl >
                    <Label for="toDate">TO</Label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDatePicker
                        onChange={(date) => handleDateChange(date, 'to')}
                        name='to'
                        id="to"
                        value={to}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        slotProps={{ textField: { variant: 'standard' } }}
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Col>
                <Col md={8} xl={6} xs={6} sm={6} lg={6}>
                  <FormControl className="mb-2" >
                    <Label for="exampleSelect">Sellers</Label>
                    <Input type="select" className="form-control" disableUnderline={true} name="seller" id="seller"
                      value={seller_id}
                      onChange={handleChanged}
                    >
                      <option selected={seller_id === ''} value={''}>Select Seller</option>
                      {
                        filterData && filterData.map((val) => {
                          return (<option selected={String(val._id) === String(seller_id)} value={val._id}>{val.company_name}</option>)
                        })
                      }
                    </Input>
                  </FormControl>
                </Col>
                <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                  <Label for="exampleSelect">Payment Mode</Label>
                  <Select style={{ margin: "1px" }}
                    labelId="demo-multiple-checkbox-label"
                    multiple
                    id="demo-multiple-checkbox-label"
                    value={type}
                    onChange={(e) => handleSelectChangePaymentType(e)}
                    input={<Input1 disableUnderline={true} className="form-control " id="name-multiple" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      key={'select type'}
                      value={'type'}
                      style={{
                        fontWeight: '500',
                      }}
                    >
                      Select payment mode
                    </MenuItem>
                    {payment_mode && Object.keys(payment_mode).map(name => (
                      <MenuItem
                        key={name}
                        value={payment_mode[name]}
                        className='putIconAfter '
                        style={{
                          fontWeight: type.indexOf(name) !== -1 ? '500' : '400',
                        }}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Col>
              </Row>
            </React.Fragment>
          ),
        },
      },
    },
    {
      name: "length",
      label: "L (mm)",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "breadth",
      label: "B (mm)",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "height",
      label: "H (mm)",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "volumetric weight",
      label: `Volumetric weight`,
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "delivery weight charge",
      label: "Del Weight Charged",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "Freight charged",
      label: "Freight Charged",
      options: {
        filter: false,
        sort: false,
      }
    },
  ];


  let data = [];
  manifestVerified && manifestVerified.data1 && manifestVerified.data1[0] && manifestVerified.data1[0].totalData && manifestVerified.data1[0].totalData.map((dataM, index) => {
    let del_date = dataM && dataM.order_id && dataM.order_id.order_status && dataM.order_id.order_status[dataM.order_id.order_status.length - 1]
    let local1 = dataM && dataM.order_id.order_status && (dataM.order_id.order_status[dataM.order_id.order_status.length - 1].status === "Delivered") ? moment(del_date && del_date.date).format(' D MMM, YYYY') : "N/A";
    data.push([
      <a target='_blanck' href={`/admin/orders/orderDetails/${dataM.order.orderNo}`}> <div style={{ marginLeft: '15px', cursor: 'pointer' }} ><h4>{dataM.order.orderNo}</h4></div>  </a>,
      <h4> <div style={{ cursor: 'pointer' }} onClick={() => handleshowImg(dataM)}> {dataM.sellerCompName && dataM.sellerCompName} </div></h4>,
      <div style={{ cursor: 'pointer' }} onClick={() => handleshowImg(dataM)}><h4>{(dataM.AWB)}</h4></div>,
      <div style={{ cursor: 'pointer' }} onClick={() => handleshowImg(dataM)}><h4><p className={'ptrPriceFont1 priceColorInventory m-0'}>₹{((dataM.order.order_amount).toFixed(2))}</p></h4></div>,
      <div style={{ cursor: 'pointer', width: '100px' }} onClick={() => handleshowImg(dataM)}><h4>{local1}&nbsp;&nbsp;&nbsp;&nbsp;</h4>  </div>,
      <div style={{ cursor: 'pointer' }} onClick={() => handleshowImg(dataM)}><h4>{dataM.box_dimention.length} </h4></div>,
      <div style={{ cursor: 'pointer' }} onClick={() => handleshowImg(dataM)}><h4>{dataM.box_dimention.breadth} </h4></div>,
      <div style={{ cursor: 'pointer' }} onClick={() => handleshowImg(dataM)}><h4>{dataM.box_dimention.height} </h4> </div>,
      <div onClick={() => handleshowImg(dataM)}><h4>&nbsp;&nbsp;&nbsp;&nbsp;{(dataM && dataM.Volumetric_weight !== ("" || undefined) ? dataM.Volumetric_weight : "N/A")}&nbsp;&nbsp;&nbsp;&nbsp;</h4></div>,
      <div onClick={() => handleshowImg(dataM)}><h4>&nbsp;&nbsp;&nbsp;&nbsp;{(dataM && dataM.delhivery_weight_charge !== ("" || undefined) ? dataM.delhivery_weight_charge : "N/A")}&nbsp;&nbsp;&nbsp;&nbsp;</h4></div>,
      <div onClick={() => handleshowImg(dataM)}><h4>&nbsp;&nbsp;&nbsp;&nbsp;{(dataM && dataM.Freight_charged !== ("" || undefined) ? dataM.Freight_charged : "N/A")}</h4></div>,
    ])
  })

  const { loading } = props;
  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: true,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: true,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: manifestVerified && manifestVerified.count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: searchedText,
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onDownload: () => { handleSettelMent(); return false },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button type="button" variant="contained" className='filterButton' onClick={() => { applyFilter(); applyNewFilters() }}>Apply</Button>
            </Grid>
            <Grid item md={6}>
              <Button type="button" variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton'>Reset</Button>
            </Grid>
          </Grid>
        </div>
      );
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  return (
    <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
      {loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}
      {
        loading ?
          null :
          <div>
            <div style={{ width: '100%', float: "left" }}>
              <MUIDataTable
                identifier={'allSettlement'}
                handleSettelMent={handleSettelMent}
                data={data}
                columns={columns}
                options={options}
                style={{ borderRadius: '0px !important' }}
              />
            </div>
            <EditMEnifestData
              tab="verified"
              seller_id={seller_id ? seller_id : ''}
              type={type}
              manifestData={manifestData} images={images} menifestId={menifestId}
              getAllManifestList={props.getVarifiedManifestList}
              from={from} to={to}
              page={page}
              editMenifest={editMenifest} handleClick={handleClick}
            />
          </div>
      }
    </div>
  );
}

const mapStateToProps = ({ admin }) => {
  const { manifestVerified, loading } = admin;
  return { manifestVerified, loading }
};

export default connect(mapStateToProps, { getVarifiedManifestList })(Verified);

