import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { NotificationManager } from 'react-notifications';
import ReactStrapTextField from '../../routes/components/ReactStrapTextField';
import ReactStrapSelectField from '../../routes/components/reactstrapSelectField';
import { Col, Row } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import axios from 'constants/axios';
import { required, validatePincode } from 'constants/validations';
import { useParams } from 'react-router-dom';

function Form21(props) {
  const params = useParams()
  const [stateList, setStateList] = useState([])

  const Close = () => {
    props.handleClick({})
  }

  const getStates = async () => {
    await axios.get('admin/getStates', {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((result) => {
      const { detail } = result.data
      setStateList(detail)
    }).catch()
  }

  useEffect(() => {
    getStates()
  }, [])

  const onSave = async (values) => {
    let data = {
      id: params.id,
      user_address: values.userAddress,
      user_city: values.userCity,
      user_state: values.userState,
      user_pincode: values.pincode
    }
    axios.post('admin/updateAddress', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      },
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      } else {
        NotificationManager.success(result.data.title);
        let data = result.data.details
        props.newData(data)
        props.handleClick({})
      }
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
    });
  }
  const { edit } = props;
  const { user_address, user_pincode, user_city, user_state } = props.userDetails;

  return (
    <React.Fragment>
      <Dialog open={edit} onClose={Close} fullWidth={true}>
        <DialogTitle>Edit Address</DialogTitle>
        <Form
          onSubmit={onSave}
          initialValues={{
            userAddress: user_address || '',
            userCity: user_city || '',
            userState: user_state && user_state._id ? user_state._id : user_state ? user_state : '',
            pincode: user_pincode || '',
          }}
          render={({ handleSubmit, values }) => (
            <form
              onSubmit={handleSubmit}
              autoComplete="off" className='pl-5'>
              <Row >
                <Col sm={10} md={10} lg={10} xs={10} xl={10}>
                  <Field id="userAddress" name="userAddress" type="textarea"
                    component={ReactStrapTextField}
                    validate={required}
                    label='Address'
                  />
                </Col>
                <Col sm={10} md={10} lg={10} xs={10} xl={10}>
                  <Field id="userCity" name="userCity" type="text"
                    component={ReactStrapTextField}
                    validate={required}
                    label='City'
                  />
                </Col>
                <Col sm={10} md={10} lg={10} xs={10} xl={10}>
                  <Field id="userState" name="userState" type="select"
                    component={ReactStrapSelectField}
                    validate={required} label='State' >
                    <option selected={values.userState === ''} value={''}></option>
                    {
                      stateList && stateList.map((val) => {
                        return (<option selected={String(val._id) === String(values.userState)} value={val._id}>{val.name}</option>)
                      })
                    }
                  </Field>
                </Col>
                <Col sm={10} md={10} lg={10} xs={10} xl={10}>
                  <Field id="pincode" name="pincode" type="text"
                    component={ReactStrapTextField}
                    validate={validatePincode}
                    label='Pincode'
                  />
                </Col>
              </Row>
              <DialogActions>
                <Button
                  onClick={Close}
                  color='secondary' >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  color="primary" >
                  Save
                </Button>
              </DialogActions>
            </form>)}
        />
      </Dialog>
    </React.Fragment>
  )
}

export default Form21 