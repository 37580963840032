import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row, FormGroup } from 'reactstrap';
import ReactStrapTextField from '../../components/ReactStrapTextField';
import { Field, Form } from 'react-final-form'
import { required, requiredImage, number0 } from 'constants/validations';
import ImageValidate from './imagesvalidate';
import axios from 'axios';
import { NotificationManager } from 'react-notifications'
import AppConfig from 'constants/config';
import moment from 'moment';

const AddImages = (props) => {
  const [selectedValue, setSelectedValue] = useState("228");

  const predefinedDimensions = {
    "228": { length: 228, breadth: 190, height: 114 },
    "152": { length: 152, breadth: 127, height: 127 },
    "254": { length: 254, breadth: 203, height: 190 },
  };
  const handleChange = (event, form)  => {
    const value = event.target.value;
    setSelectedValue(value);

    if (value !== 'custom') {
      const dimensions = predefinedDimensions[value];
      form.change('length', dimensions.length);
      form.change('breadth', dimensions.breadth);
      form.change('height', dimensions.height);
    } else {
      form.change('length', 0);
      form.change('breadth', 0);
      form.change('height', 0);
    }
  };
  const handleRequestClose = () => {
    props.handleClick('add');
  };

  const onSubmit = (values) => {
    let data = {
      images: values.promotion,
      manifest_id: props.menifestId,
      length: Number(values.length) || 0,
      breadth: Number(values.breadth) || 0,
      height: Number(values.height) || 0,
    }
    axios.post(`${AppConfig.baseUrl}manifest/manifestImg`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title);
        let data = {
          page: props.page,
          perPage: 100,
          from_date: props.from && moment(props.from).format('YYYY-MM-DD'),
          to_date: props.to && moment(props.to).format('YYYY-MM-DD'),
          tab: 'newManifest',
          seller_id: props.seller_id ? props.seller_id : '',
          type: props.type,
        }
        props.getAllManifestList({ data })
        props.handleClick('add')
      }
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
    });
  }

  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  const { manifestData, add } = props;
  return (
    <React.Fragment>
      <Dialog open={add} onClose={handleRequestClose}
        maxWidth={'md'}
      >
        <Form
          initialValues={{
            length: 228,
            breadth: 190,
            height: 114
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting }) => (
            <form noValidate onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle>
                <div style={{ display: "flex", justifyContent: "space-between" }} >
                  <div style={{ fontSize: '21px' }}  > Order ID :  <a target='_blanck' href={`/admin/orders/orderDetails/${manifestData && manifestData.order.orderNo}`}>{manifestData && manifestData.order.orderNo}  </a>  </div>
                  <div style={{ fontSize: '21px' }} > AWB :{manifestData.AWB}</div>
                </div>
                <div style={{ fontSize: '18px' }}> Order Amount :₹{(manifestData && manifestData.order && (manifestData.order.order_amount).toFixed(2))}</div>
              </DialogTitle>
              <DialogContent>
                <Row>
                  <Col sm={2} md={2} lg={2} xs={2} xl={2} >   </Col>
                  <Col sm={7} md={7} lg={7} xs={7} xl={7} >
                    <Field name="promotion" id='promotion'
                      component={ImageValidate}
                      label="Upload Image"
                      validate={requiredImage}
                    />
                  </Col>
                  <Col sm={4} md={4} lg={4} xs={4} xl={4} >   </Col>
                </Row>
                <div className='mt-2 d-flex' style={{ flexDirection: "row", gap: "20px" }}>
                  <label>
                    <input
                      type="radio"
                      value="228"
                      checked={selectedValue === '228'}
                      onChange={(e) => handleChange(e, form)}
                    />
                    {" "}
                    228
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="152"
                      checked={selectedValue === '152'}
                      onChange={(e) => handleChange(e, form)}
                    />
                    {" "}
                    152
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="254"
                      checked={selectedValue === '254'}
                      onChange={(e) => handleChange(e, form)}
                    />
                    {" "}
                    254
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="custom"
                      checked={selectedValue === 'custom'}
                      onChange={(e) => handleChange(e, form)}
                    />
                    {" "}
                    Custom
                  </label>
                </div>
                <div className='mt-3 d-flex'>
                  <FormGroup className={'mt-1 ml-2'}>
                    <Field 
                      style={{ marginTop: '-15px' }}
                      type="number"
                      name="length"
                      id="length"
                      validate={composeValidators(required, number0)}
                      component={ReactStrapTextField}
                      label={"Length"}
                      disabled={selectedValue !== 'custom'}
                    >
                    </Field>
                  </FormGroup>
                  <FormGroup className={'mt-1 ml-1'}>
                    <Field
                      type="number"
                      name="breadth"
                      id="breadth"
                      validate={composeValidators(required, number0)}
                      component={ReactStrapTextField}
                      label={"Breadth"}
                      disabled={selectedValue !== 'custom'}
                      >
                    </Field>
                  </FormGroup>
                  <FormGroup className={'mt-1 ml-1'}>
                    <Field 
                      type="number"
                      name="height"
                      id="height"
                      validate={composeValidators(required, number0)}
                      component={ReactStrapTextField}
                      label={"Height"}
                      disabled={selectedValue !== 'custom'}
                      >
                    </Field>
                  </FormGroup>
                </div>
              </DialogContent>
              <DialogActions className="pr-4">
                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                <Button type='submit' color='primary' disabled={submitting}>
                  Submit
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </React.Fragment>
  );
}

export default AddImages