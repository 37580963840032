import React, { useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import ImageValidate from "./components/imagesvalidate";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment';
import { required, requiredImage, emailField, validatePhone, number0 } from 'constants/validations';
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER
} from 'constants/ActionTypes';
import { switchLanguage, toggleCollapsedNav } from 'actions/Setting';
import Menu from 'components/TopNav/Menu';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import Button from '@mui/material/Button';
import axios from 'constants/axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row, Badge, Label, Input, FormGroup } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import ReactStrapTextField from '../../app/routes/components/ReactStrapTextField';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const Header = ({ navCollapsed, toggleCollapsedNav, actionList }, ...props) => {
  const [state, setState] = useState({
    anchorEl: undefined,
    searchBox: false,
    searchText: '',
    mailNotification: false,
    userInfo: false,
    langSwitcher: false,
    appNotification: false,
    broadcast: false,
    brod: '',
    promo_img: [],
    user_type: "",
    count: false,
    selectedDateTime: moment(),
    SendStatus: "Now"
  });

  const location = useLocation()

  const onToggleCollapsedNav = useCallback(() => {
    const val = !navCollapsed;
    toggleCollapsedNav(val);
  }, [navCollapsed, toggleCollapsedNav]);

  const handleClose = useCallback(() => {
    setState((prev) => ({ ...prev, appNotification: false }));
  }, [state]);

  const Close = useCallback(() => {
    setState((prev) => ({ ...prev, broadcast: false, promo_img: [], brod: '' }));
  }, [state]);

  const message = useCallback(() => {
    setState((prev) => ({ ...prev, broadcast: true }));
  }, [state]);

  const handleChange = useCallback((e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
  }, [state]);

  const handelChangeStatus = useCallback((event, val) => {
    setState((prev) => ({ ...prev, SendStatus: val }));
  }, [state]);

  const handleDateChangeDate = useCallback((event, val) => {
    const adfasdf = moment(new Date()).diff(event._d, 'seconds');
    if (adfasdf < 0) {
      setState((prev) => ({ ...prev, selectedDateTime: moment(event._d) }));
    } else {
      NotificationManager.error("Please select date and time after the current time.");
    }
    setState((prev) => ({ ...prev, selectedDateTime: moment(event._d) }));
  }, [state]);

  const apiCall = useCallback((values) => {
    let data;
    console.log("xxcvxcvb", state)
    if (location.pathname.slice(0, 22) === '/admin/buyers/details/') {
      data = {
        id: location.pathname.slice(22, 46),
        message: values.brod,
        promo_img: values.promo_img,
        notificationType: state.SendStatus,
        date: state.SendStatus === "Now" ? "" : state.selectedDateTime
      };
    } else if (location.pathname.slice(0, 23) === '/admin/sellers/details/') {
      data = {
        id: location.pathname.slice(23, 47),
        message: values.brod,
        promo_img: values.promo_img,
        notificationType: state.SendStatus,
        date: state.SendStatus === "Now" ? "" : state.selectedDateTime
      };
    } else {
      data = {
        message: values.brod,
        promo_img: values.promo_img,
        notificationType: state.SendStatus,
        date: state.SendStatus === "Now" ? "" : state.selectedDateTime
      };
    }

    if (values.brod || values.promo_img && values.promo_img.length > 0) {
      axios.post('/admin/broadcastMessage', data, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('admin_token')
        },
      })
        .then(result => {
          setState((prev) => ({ ...prev, brod: '', user_type: '', promo_img: '' }));
          if (result.data.error) {
            NotificationManager.error(result.data.title);
          } else {
            NotificationManager.success('Message broadcasted successfully.');
          }
          setState((prev) => ({ ...prev, broadcast: false }));
        })
        .catch(error => {
          NotificationManager.error('Something went wrong. Please try again.');
        });
    } else {
      NotificationManager.error("Please add Image or Message");
    }
  }, [state, location]);

  const handleChangeDocuments = useCallback((e) => {
    setState((prev) => ({ ...prev, promo_img: e }));
  }, [state]);

  const isButtonAvailable = (key) => {
    let index = actionList && actionList.findIndex((val) => val === key);
    return index > -1 ? true : false;
  };
  const onUserInfoSelect = () => {
    setState((prevState) => ({
      userInfo: !prevState.userInfo
    }));
  };

  const composeValidators = (...validators) => (value, allValues) => validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  const { drawerType, locale, navigationStyle, horizontalNavPosition, handleSubmit } = props;
  const drawerStyle = drawerType && drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType && drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';


  return (
    <AppBar
      className={`app-main-header app-main-header-top `}
    >
      <Toolbar className="app-toolbar" disableGutters={false}>
        {/* {navigationStyle === HORIZONTAL_NAVIGATION ? ( */}
        <div className="d-block d-lg-none pointer mr-3" onClick={onToggleCollapsedNav}>
          <span className="jr-menu-icon">
            <span className="menu-icon" />
          </span>
        </div>
        {/* ) */}
        {/* :
          (
            <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu" onClick={onToggleCollapsedNav}>
              <span className="menu-icon" />
            </IconButton>
          )} */}

        <Link className="app-logo mr-2 d-none d-sm-block" to="/"></Link>

        {/* {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) && 
        <Menu />} */}

        <ul className="header-notifications list-inline ml-auto">
          <li className="list-inline-item app-tour">
            {
              isButtonAvailable('broadcastMessage') &&
              true && (
                <IconButton className="icon-btn" onClick={message}>
                  <i className="zmdi zmdi-comment-alt-text text-white" />
                </IconButton>
              )}
          </li>

          {navigationStyle === HORIZONTAL_NAVIGATION && (
            <li className="list-inline-item user-nav">
              <Dropdown className="quick-menu" isOpen={state.userInfo} toggle={onUserInfoSelect}>
                <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
                  <IconButton className="icon-btn size-30">
                    <Avatar alt='Medimny' src={require('assets/images/medical-logo.png')} className="size-30" />
                  </IconButton>
                </DropdownToggle>
                <DropdownMenu right>
                  <UserInfoPopup />
                </DropdownMenu>
              </Dropdown>
            </li>
          )}
        </ul>

        <div className="ellipse-shape"></div>

        {/* ----------------------------BROADCAST MSG------------------------------- */}
        <Dialog open={state.broadcast} onClose={Close} fullWidth={true}>
          <DialogTitle>Broadcast Message</DialogTitle>
          <Form
            onSubmit={apiCall}
            initialValues={{
              brod: '',
              promo_img: '',
            }}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>

                <DialogContent>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Field
                          label='Message'
                          type='textarea'
                          name='brod'
                          rows="8"
                          onChange={(e) => handleChange(e, 'brod')}
                          component={ReactStrapTextField}
                          // validate={composeValidators(required)}
                          value={values.brod}
                          id="brod"
                        />
                      </FormGroup>
                      <Row>
                        <Col sm={4} md={4} lg={4} xs={4} xl={4}>
                          <FormGroup>
                            <Field
                              value={values.promo_img}
                              name="promo_img"
                              id='promo_img'
                              onChange={(e) => handleChangeDocuments(e)}
                              component={ImageValidate}
                              label="Upload Image"
                            />
                          </FormGroup>
                        </Col>
                        {values.promo_img &&
                          values.promo_img.map((val) => {
                            return (
                              <Col sm={4} md={4} lg={4} xs={4} xl={4} key={val}>
                                <img height={100} width={300} src={val} alt={`promo-${val}`} />
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </Row>
                </DialogContent>

                <FormControl className='pl-4 mt-4'>
                  <FormLabel id="demo-row-radio-buttons-group-label">Send Status</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={state.SendStatus}
                    value={state.SendStatus}
                    onChange={handelChangeStatus}
                  >
                    <FormControlLabel value="Now" control={<Radio />} label="Now" />
                    <FormControlLabel value="Later" control={<Radio />} label="Later" />
                  </RadioGroup>
                </FormControl>
                {state.SendStatus === "Later" && (
                  <FormControl className='pl-4 mt-4'>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDateTimePicker
                        slotProps={{ textField: { variant: 'filled' } }}
                        label="Please select date and time"
                        value={state.selectedDateTime}
                        format='MMMM Do YYYY, h:mm:ss a'
                        onChange={handleDateChangeDate} />
                    </LocalizationProvider>
                  </FormControl>
                )}

                <DialogActions className="pr-4">
                  <Button onClick={Close} color='secondary'> Cancel </Button>
                  <Button type='submit' color='primary'> Send </Button>
                </DialogActions>
              </form>
            )}
          />
        </Dialog>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = ({ settings, admin }) => {
  const { drawerType, locale, navigationStyle, horizontalNavPosition } = settings;
  const { actionList } = admin;
  return { drawerType, locale, navigationStyle, horizontalNavPosition, actionList };
};

export default (connect(mapStateToProps, { toggleCollapsedNav })(Header))
