import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { deleteGroup, deleteStaff, getPromotionsList } from 'actions/admin';
import { getInventoryList } from 'actions/admin'
import AxiosRequest from 'sagas/axiosRequest'
import { NotificationManager } from 'react-notifications';

function AddInventory(props) {
  const [state, setState] = useState({
    modal: false,
    groupName: '',
    staffId: '',
    promotion_id: '',
  });

  useEffect(() => {
    if (props.selectedData !== undefined) {
      if (props.deleteFor === 'group') {
        setState((prevState) => ({ ...prevState, groupName: props.selectedData.name }));
      } else if (props.deleteFor === 'staff') {
        setState((prevState) => ({ ...prevState, staffId: props.selectedData._id }));
      } else if (props.deleteFor === 'promotions') {
        setState((prevState) => ({ ...prevState, promotion_id: props.selectedData._id }));
      }
    }
  }, [props.selectedData, props.deleteFor]);

  const handleRequestClose = () => {
    if (props.hideFor && props.hideFor === 'inventory') {
      return props.handleClick('hide');
    }
    props.handleClick('delete');
  }

  const handleSubmit = async () => {

    if (props.hideFor && props.hideFor === 'inventory') {
      let data = {
        inventoryId: props.editData._id,
        hide: !props.editData.isHidden
      }
      let response = await AxiosRequest.axiosHelperFunc('post', 'inventory/hideInventoryItem', '', data)

      if (response.data.error) {
        NotificationManager.error(response.data.title)
        props.handleClick('hide');
      } else {
        NotificationManager.success(response.data.title)
        let data2 = {
          page: props.page === 0 ? 1 : props.page,
          perPage: props.perPage
        }
        props.getInventoryList({ data: data2 })
        props.handleClick('hide');
      }

    }
    if (props.deleteFor === 'promotions') {

      let data = { promotion_id: props.selectedData._id }
      let response = await AxiosRequest.axiosHelperFunc('post', 'promotions/delete', '', data)

      if (response.data.error) {
        NotificationManager.error("Something went wrong")
        props.handleClick('delete');
      } else {
        NotificationManager.success("Promotion delted successfully")
        let data2 = {
          page: props.page === 0 ? 1 : props.page,
          perPage: props.perPage
        }
        props.getPromotionsList({ data: data2 })
        props.handleClick('delete');
      }
    }
    if (props.deleteFor === 'group') {

      let memberCount = parseInt(props.selectedData.members);
      if (memberCount === 0) {
        props.deleteGroup({ history: props.history, name: state.groupName, listGroup: { searchText: '', page: 1, perPage: 10 } });
        props.handleClick('delete');
      } else {
        NotificationManager.error("This group contains active members, please remove the members and try again.")
        props.handleClick('delete');
      }
    }
    if (props.deleteFor === 'staff') {
      props.deleteStaff({ history: props.history, staffId: props.selectedData._id, listStaff: { searchText: '', page: 1, perPage: 10 } });
      props.handleClick('delete');
    }
    if (props.deleteFor === 'inventory') {
      let data = { inventoryId: props.editData._id }
      let response = await AxiosRequest.axiosHelperFunc('post', 'inventory/deleteInventoryItem', '', data)

      if (response.data.error) {
        NotificationManager.error(response.data.title)
        props.handleClick('delete');
      } else {
        NotificationManager.success(response.data.title)
        let data2 = {
          page: props.page === 0 ? 1 : props.page,
          perPage: props.perPage
        }
        props.getInventoryList({ data: data2 })
        props.handleClick('delete');
      }
    }

  }

  return (
    <React.Fragment>
      <Dialog open={props.delete} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle>
          {props.hideFor === 'inventory' ? props.editData ? props.editData.isHidden ? 'Unhide item?' : 'Hide item?' : '' : ''}
          {props.deleteFor === 'inventory' ? 'Remove item ?' : ''}
          {props.deleteFor === 'promotions' ? 'Remove Promotion?' : ''}
          {props.deleteFor === 'staff' ? 'Remove Staff ?' : ''}
          {props.deleteFor === 'group' ? 'Remove Group ?' : ''}
        </DialogTitle>
        <DialogContent>

          <form noValidate autoComplete="off">
            {
              props.deleteFor === 'inventory' ? <DialogContentText className='mt-2'>
                This item will be removed from your inventory
              </DialogContentText> : ''
            }
            {
              props.deleteFor === 'promotions' ? <DialogContentText className='mt-2'>
                Are you sure, you want to remove this promotion.
              </DialogContentText> : ''
            }
            {
              props.deleteFor === 'staff' ? <DialogContentText className='mt-2'>
                Are you sure, you want to remove this staff member.
              </DialogContentText> : ''
            }
            {
              props.deleteFor === 'group' ? <DialogContentText className='mt-2'>
                Are you sure, you want to remove this Group.
              </DialogContentText> : ''
            }
            {
              props.hideFor === 'inventory' && props.editData &&
              <DialogContentText className='mt-3'>
                {`Are you sure, you want to ${!props.editData.isHidden ? 'hide' : 'unhide'} this item.`}
              </DialogContentText>
            }
          </form>
        </DialogContent>
        <DialogActions className="pr-4">
          <Button onClick={handleRequestClose} color='secondary' >
            Cancel
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            {props.hideFor === 'inventory' ? 'Confirm' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = ({ admin }) => {
  return {}
};

AddInventory = connect(
  mapStateToProps,
  {
    deleteGroup,
    deleteStaff,
    getInventoryList,
    getPromotionsList
  }           // bind account loading action creator
)(AddInventory)

export default AddInventory;