import axios from 'axios';
import AppConfig from '../constants/config'

/*
#purpose: Common function to call api
#
*/
const axiosRequest = async(method='post',url,type,data)=>{
    return axios({
        method,
        url:`${AppConfig.baseUrl}${url}`,
        headers:{
            token:localStorage.getItem('admin_token')
        },
        data:{
            email:data.email?data.email:'',
            password:data.password?data.password:'',
            user_type:data.user_type?data.user_type:'admin'
        }
    })
    .then(result=>result)
    .catch(error=>error)
}


const axiosHelperFunc = async(method='post',url,type,data)=>{
    
    

    return axios({
        method,
        url:`${AppConfig.baseUrl}${url}`,
        headers:{
            token: localStorage.getItem('admin_token')
        },
        data
    })
    .then(result=>result)
    .catch(error=>error)
}

const axiosHelperFunc1 = async(method,url,type,data)=>{
    
    

    return axios({
        method,
        url:`${AppConfig.baseUrl}${url}`,
        headers:{
            token: localStorage.getItem('admin_token')
        },
        data
    })
    .then(result=>result)
    .catch(error=>error)
}


export default {
    axiosRequest,
    axiosHelperFunc,
    axiosHelperFunc1
}
