import React, { Component, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import moment from 'moment';
import ContainerHeader from '../../../../components/ContainerHeader';
import { Col, Row, Label, Input } from 'reactstrap';
import { FormGroup } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import customHead from 'constants/customHead'
import { getScheduleNotificationsList } from 'actions/admin';
import AxiosRequest from 'sagas/axiosRequest';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux'
import AdminContainer from "containers/AdminContainer";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";
import CircularProgress from '@mui/material/CircularProgress';



const columns = [
  {
    name: "image",
    label: "Image",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "message",
    label: "message",
    options: {
      filter: false,
      sort: false,
      // search: true,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "Date Time",
    label: "Date Time",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },

  {
    name: "Action",
    label: "Action",
    options: {
      filter: true,
      filterType: 'custom',
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      },
      customFilterListRender: v => {
        return false;
      },
      filterOptions: {
        names: [],
        logic() {

        },
        display: () => (
          <React.Fragment>
            <Row form>
              <Col md={6} xl={6} xs={6} lg={6}>
                <FormGroup>
                  <Label for="exampleSelect">Select Month</Label>
                  <Input type="select" name="select" id="exampleSelect">
                    <option>January</option>
                    <option>February</option>
                    <option>March</option>
                    <option>April</option>
                    <option>May</option>
                    <option>June</option>
                    <option>July</option>
                    <option>August</option>
                    <option>September</option>
                    <option>October</option>
                    <option>November</option>
                    <option>December</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6} xl={6} xs={6} lg={6}>
                <FormGroup>
                  <Label for="exampleSelect">Select Month</Label>
                  <Input type="select" name="select" id="exampleSelect">
                    <option>2015</option>
                    <option>2016</option>
                    <option>2017</option>
                    <option>2018</option>
                    <option>2019</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <div style={{ paddingTop: 15 }} >
              <Button variant="contained" className='filterButton' color='primary'>Apply Filter</Button>
              <Button variant="contained" className='filterButton' color='primary'>Reset Filter</Button>
            </div>
          </React.Fragment>
        ),
        onFilterChange: () => {
          console.log('onFilterChange')
        }
      },
    },
  }
];


const options = {
  filterType: 'dropdown',
  print: false,
  filter: false,
  download: false,
  selectableRows: 'none',
  selectableRowsOnClick: false,
  selectableRowsHeader: false,
  responsive: 'scrollMaxHeight',
  search: false,
  viewColumns: false,
  rowHover: false,
  rowsPerPageOptions: [],
  customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
    return <div className="p-4 d-flex justify-content-end">
      <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
      <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
    </div>
  }
};


function ScheduleNotification(props) {

  const [state, setState] = useState({
    edit: false,
    hide: false,
    delete: false,
    add: false,
    val: '',
    selectedData: {},
    checkedA: false,
  })
  const [checkedA, setCheckedA] = useState(false);

  const handleDelete = async (key) => {
    let data = { schedule_id: key }
    let responce = await AxiosRequest.axiosHelperFunc('post', 'scheduleNotification/deleteSchedule', '', data)
    if (responce.data.error) {
      NotificationManager.error(responce.data.title);
      props.getScheduleNotificationsList({ page: 1, perPage: 50, type: 'admin' })
    } else {
      NotificationManager.success(responce.data.title);
      props.getScheduleNotificationsList({ page: 1, perPage: 50, type: 'admin' })
    }

  }

  useEffect(() => {
    props.getScheduleNotificationsList({ page: 1, perPage: 50, type: 'admin' })
  }, [])

  const { notificationList } = props;
  let data = [];
  notificationList && notificationList.detail && notificationList.detail.map((dataOne) => {
    data.push(
      {
        'image': <Avatar src={dataOne.image} />,
        'message': dataOne.message,
        'Date Time': moment(dataOne.date).format('DD/MM/YYYY hh:mm'),
        'Action':
          <Button variant="outlined" className={'text-success'} onClick={(e) => handleDelete(dataOne._id)}>Delete</Button>

      }
    )
  })

  return (
    <AdminContainer>
      <React.Fragment>
        <ContainerHeader title={"Schedule notification"} />
        {props.loading ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}
        {
          props.loading === true ?
            null :
            <MUIDataTable
              data={data}
              columns={columns}
              options={options}
            />
        }
      </React.Fragment>
    </AdminContainer>
  );
}


const mapStateToProps = ({ admin }) => {
  const { promotionsList, notificationList, loading } = admin;
  return { promotionsList, notificationList, loading }
};

ScheduleNotification = connect(
  mapStateToProps,
  {
    getScheduleNotificationsList
  }               // bind account loading action creator
)(ScheduleNotification)

export default ScheduleNotification;