import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { Col, Row, Label, Input } from 'reactstrap';
import { FormGroup, FormControl } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppConfig from 'constants/config'
import axios from 'axios';
import { Field } from 'react-final-form'
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import { getSettlements } from 'actions/admin';
import { connect } from 'react-redux';
import helperFunction from 'constants/helperFunction';
import RenderDatePicker from 'app/routes/components/RenderDatePicker/new_index';
import { Form } from 'react-final-form'

const monthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
let years = helperFunction.yearList();
let date = new Date();
function SettlementHeader(props) {
  const uploadcsv = useRef()
  const [state, setState] = useState({
    uploadedCsv: '',
    file: null,
    month: monthArray[date.getMonth()],
    year: date.getFullYear(),
    settleDate: moment(),
  })

  const handleRequestClose = () => {
    setState((prev) => ({
      ...prev,
      open: false
    }));
  }
  const handleRequestCloseForDownload = () => {
    setState((prev) => ({
      ...prev,
      openCSV: false
    }));
  }

  const onFormSubmit = (values) => {
    fileUpload(state.file, values).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title)
        let data = {
          page: 1,
          perPage: 50,
          from_date: state.from ? state.from : moment().subtract(1, 'months'),
          to_date: state.to ? state.to : moment().format(),
          seller_id: ''
        }
        props.getSettlements({ data });
      }
      setState((prev) => ({ ...prev, open: false, file: null }));
    }
    )
  }
  const onChange = (e) => {
    setState((prev) => ({ ...prev, file: e.target.files[0] }))
  }
  const fileUpload = (file) => {
    const url = `${AppConfig.baseUrl}settlements/settleByCsv`;
    const utcdate = new Date(state.settleDate)
    const formData = new FormData();
    formData.append('fileUpload', file)
    formData.append('settleDate', utcdate)
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: localStorage.getItem('admin_token')
      }
    }
    return axios.post(url, formData, config)
  }
  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val == key)
    return index > -1 ? true : false
  }

  const handleChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }))
  }
  const Submit = () => {
    window.open(`${AppConfig.baseUrl}groupSettlements/getSettledCsvForAllSellers?token=${localStorage.getItem('admin_token')}&month=${state.month}&year=${state.year} `, '_blank');
    setState((prev) => ({ ...prev, openCSV: false }));
  }

  let { month, year, file } = state;

  return (
    <div className="page-heading buyerDetailsHeader d-sm-flex justify-content-sm-between align-items-sm-center">
      <h2 className="title mb-3 mb-sm-0">Settlements </h2>
      <React.Fragment>
        {
          isButtonAvailable('settledCSVReport') && !isButtonAvailable('viewOnlySettlements') &&
          <Col style={{ textAlign: 'end' }}>
            <Button variant="outlined" className={'text-success'} style={{ padding: 'px 15px' }}
              onClick={(e) => setState((prev) => ({ ...prev, openCSV: true }))}>
              Csv Report
            </Button>
          </Col>
        }
      </React.Fragment>
      <Dialog open={state.openCSV} onClose={(e) => handleRequestCloseForDownload()} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle className='pb-0'>
          CSV Report for All Sellers
        </DialogTitle>
        <DialogContent>
          <Row>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="sellerName">Month</Label>
                <Input type="select" name="month" id="month"
                  value={month}
                  onChange={(e) => handleChange(e, 'month')}>
                  <option selected={month == ''} value={''}>Select Month</option>
                  {monthArray.map((data) => {
                    return (<option selected={data == month} value={data}>{data}</option>)
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="orderDate">Year</Label>
                <Input type="select" name="year"
                  value={year}
                  id="year" onChange={(e) => handleChange(e, 'year')} >
                  <option selected={year == ''} value={''}>Select Year</option>
                  {years.map((data) => {
                    return (<option selected={data == year} value={data}>{data}</option>)
                  })}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <DialogActions className='pt-0'>

            <Button onClick={(e) => handleRequestCloseForDownload()} color='secondary' >
              Close
            </Button>
            <Button onClick={(e) => Submit()} color='primary' >
              Download
            </Button>

          </DialogActions>

        </DialogContent>
      </Dialog>
      {
        isButtonAvailable('uploadSettlementCSV') && !isButtonAvailable('viewOnlySettlements') &&
        <Button variant="outlined" className={'text-primary'} onClick={() => { setState((prev) => ({ ...prev, open: true })) }}>
          Upload CSV
        </Button>
      }
      <Dialog open={state.open} onClose={handleRequestClose}>
        <DialogTitle>Upload CSV</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={onFormSubmit}
            initialValues={{ settleDate: state.settleDate }}
            render={({ handleSubmit, values }) => {
              if (values.settleDate !== state.settleDate) {
                setState({ ...state, settleDate: values.settleDate })
              }
              return <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} xl={6} xs={6} sm={6} lg={6} className='pt-2'>
                    <FormControl >
                      <Label for="toDate">Settlement Date</Label>
                      <Field className="form-control" placeholder='MM/DD/YYYY' autoComplete='off'
                        name="settleDate" id="settleDate"
                        component={RenderDatePicker} />
                    </FormControl>
                  </Col>

                  <Col md={5} xl={5} xs={5} sm={5} lg={5}>

                    <div className='text-center'>
                      <input
                        type='file'
                        accept='.csv'
                        style={{ display: 'none' }} required
                        onChange={(e) => onChange(e)} ref={uploadcsv}
                      />
                      <img src={file !== null ? require('../../../../assets/img/camera_3.png') : require('../../../../assets/img/camera_1.png')} className='d-block mx-auto' onClick={(e) => uploadcsv.current.click()} />
                      <label >Csv</label>
                    </div>
                  </Col>
                </Row>
                <DialogActions>
                  <Button onClick={() => handleRequestClose()} color='secondary'>Cancel</Button>
                  <Button type='submit' color='primary'>Upload</Button>
                </DialogActions>
              </form>
            }

            }
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

const mapStateToProps = ({ admin }) => {
  const { adminSettlements, actionList } = admin;
  return { adminSettlements, actionList }
};

export default connect(mapStateToProps, { getSettlements })((SettlementHeader)); 