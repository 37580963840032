import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './index.css'
import dayjs from "dayjs"

export default function BasicDatePicker({ label, handleDateChange, dateValue, state }) {
    const handleDateChange1 = (date, state) => {
        const parsedDate = dayjs(date);
        let date1 = parsedDate.format('YYYY-MM-DD');
        handleDateChange(date1, state)
    };
    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
        >
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    className='custom-datepicker'
                    format='DD/MM/YYYY'
                    value={dayjs(dateValue)}
                    onChange={(date) => handleDateChange1(date, state)}
                    label={label} />
            </DemoContainer>
        </LocalizationProvider>
    );
}
