import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Input, InputGroup } from 'reactstrap';
import './index.css'

const SearchBar = ({ placeholder, onSearch, handleSearch, searchText }) => {
  const handleSearch1 = (e) => {
    handleSearch(e.target.value);
  };

  return (
    <InputGroup>
      <Input
        type="text"
        style={{ fontSize: '18px' }}
        placeholder={placeholder}
        onChange={handleSearch1}
        className="search-input"
        value={searchText}
      />
      <div className="search-icon-container">
        <AiOutlineSearch style={{ fontSize: '20px' }} className="search-icon" />
      </div>
    </InputGroup>
  );
};

export default SearchBar;
