import React, { useEffect, useState } from 'react';

import moment from 'moment'
import { connect } from 'react-redux';
import { getSettlementList, getGroupSettlement, getListGroupSettlement } from 'actions/admin'
import AppConfig from 'constants/config'
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import customHead from 'constants/customHead'
import { FormGroup, Label, Input, Popover, Col, Row, } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
import { Field, Form } from 'react-final-form'
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReactstrapTextField from '../../components/ReactStrapTextField';
import { required, number, amt, numberCanBeNega } from 'constants/validations'
import ViewSellerSettlement from './ViewSellerSettlement';
import { ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import helper from 'constants/helperFunction'
import Pop from "./Pop";
import Input1 from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useParams } from 'react-router';
import { Grid, TextField } from '@mui/material';
import CustomeFilterForDate from 'app/routes/components/CustomFilter/customfilter';
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'app/routes/components/PaginationCount';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const payment_mode = ['COD', "Prepaid", "Bulk Prepaid"]

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function SettlementsDetails(props) {
  const { id } = useParams()
  let date = new Date()
  const [state, setState] = useState({
    detailCards: '',
    month: date.getMonth(),
    year: date.getFullYear(),
    tempMonth: date.getMonth(),
    tempYear: date.getFullYear(),
    page: 0,
    perPage: 500,
    from_date: '',
    to_date: '',
    searchText: '',
    open: false,
    IndividualData: '',
    tds: 0,
    tcs: 0,
    commission: 0,
    netAmount: '',
    data: '',
    view: false,
    settle: false,
    totalNetAmt: '',
    totalAmt: '',
    otherCharges: 0,
    gst: 0,
    totalTds: '',
    totalTcs: '',
    totalComm: '',
    tdsVal: 0,
    tcsVal: 0,
    commVal: 0,
    bulkVal: 0,
    settlementsIds: '',
    utrNo: '',
    remark: ' ',
    paymentDate: new Date(),
    settleArr: [],
    groupGst: '',
    remittance: 0,
    openCsv: false,
    from: moment().subtract(30, 'days').format("YYYY-MM-DD"),
    to: moment().format('YYYY-MM-DD'),
    set: false,
    remiId: '',
    otherChargesGst: 0,
    allChecked: false,
    unsettledChecked: false,
    prodSurge: '',
    rtoAmt: '',
    gstOnRto: '',
    adjustmentAmount: '',
    unsettledTickets: '',
    unsettledCheckedArr: [],
    ticketsAutoSettled: [],
    loader: false,
    type: [],
    searchedText: '',
    netAmount1: '',
  })
  const fetchApi = async (hitAxios) => {
    let data = {
      sellerId: id,
      from_date: state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format(),
      to_date: state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format()
    }
    props.getSettlementList({ data })
    if (!hitAxios) {
      await axios({
        method: 'post',
        url: `${AppConfig.baseUrl}systemConfig/getAllSystemConfigList`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('admin_token')
        }
      }
      ).then(result => {

        if (result.data.error) {
          console.log("error", result.data)
        } else {
          result.data.detail.map((data) => {
            if (data.key == "gst") {
              setState((prev) => ({
                ...prev, groupGst: data.value
              }))
            }
            if (data.key == "commission") {
              setState((prev) => ({
                ...prev, commVal: data.value
              }))
            }
            if (data.key == "tds") {
              setState((prev) => ({
                ...prev, tdsVal: data.value
              }))
            }
            if (data.key == "tcs") {
              setState((prev) => ({
                ...prev, tcsVal: data.value
              }))
            }
            if (data.key == "bulk_commission") {
              setState((prev) => ({
                ...prev, bulkVal: data.value
              }))
            }
          })
        }
      })
        .catch(error => {
          console.log("error catch", error)
        });
    }
  }
  useEffect(() => {
    fetchApi()
  }, [])
  const handleSettelMent = async () => {
    let seller_id = id
    window.open(`${AppConfig.baseUrl}settlements/settlementListCsv?token=${localStorage.getItem('admin_token')}&from_date=${state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format()}&to_date=${state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format()}&type=${state.type ? state.type : ''}&sellerId=${seller_id} `, '_blank');
    setState((prev) => ({
      ...prev,
      openFilter: false,
      status: '',
      seller: ''
    }))
  }
  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val == key)
    return index > -1 ? true : false
  }
  const handleDateChange = async (date, key) => {
    setState((prev) => ({
      ...prev, [key]: date
    }))

  }
  const handleDateChanged = (date, key) => {
    setState((prev) => ({
      ...prev, [key]: moment(date).format()
    }))
  }
  const handleSelectChangePaymentType = (e) => {

    let tempSelectedCat = e.target.value;

    let index = tempSelectedCat && tempSelectedCat.length > 0 ? tempSelectedCat.findIndex((e) => e === 'type') > -1 ? tempSelectedCat.findIndex((e) => e === 'type') : -1 : -1;
    index > -1 && tempSelectedCat.splice(index, 1);
    setState((prev) => ({
      ...prev, type: tempSelectedCat
    }));

  }
  const applyFilter = (filter) => {

    let data = {
      page: 1,
      perPage: state.perPage,
      sellerId: id,
      searchText: state.searchedText ? state.searchedText : '',
      from_date: state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format(),
      to_date: state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format(),
      type: state.type
    }
    props.getSettlementList({ data, history: props.history });
    setState((prev) => ({
      ...prev,
      page: 0, settleArr: [], allChecked: false, unsettledChecked: false, ticketsAutoSettled: [], unsettledTickets: '', unsettledCheckedArr: []
    }))
  }
  const resetFilter = (filter) => {
    let data = {
      page: 1,
      perPage: 500,
      sellerId: id,
      from_date: moment().format('YYYY-MM-DD'),
      to_date: moment().add(1, 'days').format('YYYY-MM-DD')
    }
    props.getSettlementList({ data, history: props.history })
    filter();
    setState((prev) => ({
      ...prev,
      filter: '',
      status: '',
      showFilter: false,
      page: 0,
      settleArr: [],
      from: moment().format(),
      to: moment().format(),
      allChecked: false,
      unsettledChecked: false,
      ticketsAutoSettled: [],
      unsettledTickets: '',
      unsettledCheckedArr: [],
      type: []
    }))
  }
  const changePage = (page) => {
    let pages = page + 1;
    let data = {
      page: pages,
      perPage: state.perPage,
      sellerId: id,
      searchText: state.searchedText ? state.searchedText : '',
      from_date: state.from ? state.from : moment(),
      to_date: state.to ? state.to : moment(),
      type: state.type

    }
    props.getSettlementList({ data, history: props.history })
    setState((prev) => ({
      ...prev, page
    }))
  };
  const handleSearch = (searchText) => {

    let data = {
      page: 1,
      perPage: state.perPage,
      searchText: searchText,
      sellerId: id,
      from_date: state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format(),
      to_date: state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format(),
      type: state.type
    }

    props.getSettlementList({ data, history: props.history })
    setState((prev) => ({
      ...prev, searchedText: searchText, page: 0
    }))
  };
  const getAction = (data) => {
    return <ButtonGroup color="primary" aria-label="outlined primary button group">
      {
        isButtonAvailable('editSettlements') && !isButtonAvailable('viewOnlySettlements') &&
        <Button variant="outlined" className={'text-primary'} onClick={(e) => handleClick('view', data)}>Edit</Button>
      }
    </ButtonGroup>
  }
  const buttonA = (data) => {

    setState({ open: true, IndividualData: data })
  }
  const handleRequestClose = () => {
    setState((prev) => ({
      ...prev,
      open: false, settle: false, settleArr: [], paymentDate: new Date(), allChecked: false, unsettledChecked: false,
      ticketsAutoSettled: [], unsettledTickets: '', unsettledCheckedArr: []
    }))
  }
  const getValue = (data, value) => {
    switch (data) {
      case 'totalTds':
        return totalAmountFormula(value, 0, 0, 0, 0)
      case 'totalTcs':
        return totalAmountFormula(0, value, 0, 0, 0)
      case 'totalComm':
        return totalAmountFormula(0, 0, value, 0, 0)
      case 'otherCharges':
        return totalAmountFormula(0, 0, 0, value, 0)
      case 'gst':
        return totalAmountFormula(0, 0, 0, 0, value)
    }
  }
  const totalAmountFormula = (values) => {

    return Math.round(Number(state.netAmount1) -
      (Number(values?.totalTcs) + Number(values?.totalTds) + Number(values?.otherCharges) + Number(values?.totalComm) + Number(values?.otherChargesGst)
        + Number((Number(values?.gst) * Number(values?.totalComm)) / 100) + Number(state?.netAmount1) / 100 * Number(values?.remittance)
        + Number(((state?.netAmount1) / 100 * Number(values?.remittance)) / 100 * Number(state?.groupGst)) + Number(state.prodSurge ? state?.prodSurge : 0)
        + Number(state?.adjustmentAmount) + Number(state?.gstOnRto) + Number(state?.rtoAmt))
    )
  }
  const handleChange = async (e, key) => {

    setState((prev) => ({
      ...prev, [key]: e.target.value
    }))

  }
  const handleChanged = (e, key) => {
    setState((prev) => ({
      ...prev, [key]: e.target.value
    }));
  }
  const onSettle = async (values) => {
    setState((prev) => ({
      ...prev, set: true
    }))
    const { totalTds, totalTcs, totalComm, otherCharges, utrNo, gst, remark, otherChargesGst } = values;
    const { settlementsIds, totalAmt, totalNetAmt, paymentDate } = state
    let ticketArr = [...state.ticketsAutoSettled, ...state.unsettledCheckedArr];
    let ticketId = ticketArr.map(e => e._id);
    let data = {
      sellerId: id,
      settlement: settlementsIds,
      tds: Number(totalTds),
      tcs: Number(totalTcs),
      commission: Number(totalComm),
      orderValue: Number(totalAmt),
      netAmt: Number(totalAmountFormula(values)),
      otherCharges: Number(otherCharges),
      utrNo: utrNo,
      paymentDate: paymentDate,
      gst: Number(state.gst),
      remark: remark,
      remittance: Number(values.remittance),
      customId: values.remittanceId,
      otherChargesGst: Number(otherChargesGst),
      prodSurChargeWithGst: Number(state.prodSurge),
      from_date: moment().subtract(30, 'day').format(''),
      to_date: moment().format(''),
      sellerAdjustmentAmt: Number(state.adjustmentAmount),
      rtoAmt: Number(state.rtoAmt),
      gstOnRto: Number(state.gstOnRto),
      ticketId: ticketId
    }

    await props.getGroupSettlement({ data })
    props.getListGroupSettlement({ data });
    props.hitApi();
    let data1 = {
      sellerId: id,
      from_date: state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format(),
      to_date: state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format()
    }
    await props.getSettlementList({ data: data1, history: props.history })
    await setState((prev) => ({
      ...prev,
      open: false, settle: false, settleArr: [], allChecked: false, unsettledTickets: '', unsettledChecked: false,
      ticketsAutoSettled: [], unsettledCheckedArr: []
    }))
  }
  const handleClick = (key, dataOne) => {
    setState((prev) => ({
      ...prev, [key]: !state[key], data: dataOne ? dataOne : ''
    }))

  }
  const handleSettle = async () => {
    setState((prev) => ({
      ...prev, loader: true
    }))
    const { settleArr } = state;
    const settlementId = []
    const bulkOrder = []
    const noBulkOrder = []

    await settleArr.map((data) => {
      settlementId.push(data._id)
      if (data.order.isBulk) {
        bulkOrder.push(data)
      } else {
        noBulkOrder.push(data)
      }
    })
    const reduNetAmt = settleArr && settleArr.reduce((accumulator, currentValue) => accumulator + currentValue.net_amt, 0); //net_amt
    const reduOrderVal = await settleArr && settleArr.reduce((accumulator, currentValue) => accumulator + currentValue.order_value, 0);
    const reduOrderNoBulk = await noBulkOrder && noBulkOrder.reduce((accumulator, currentValue) => accumulator + currentValue.commission, 0);
    const reduOrderBulk = await bulkOrder && bulkOrder.reduce((accumulator, currentValue) => accumulator + currentValue.commission, 0);
    const reduTotalTds = reduOrderVal * state.tdsVal;
    const reduTotalTcs = reduOrderVal * state.tcsVal;
    const reduComm = Number(reduOrderNoBulk) + Number(reduOrderBulk);
    let reduPordSurge = 0;
    await settleArr && settleArr.map((currentValue) => {
      if (currentValue && currentValue.commission_comp && currentValue.commission_comp.prod_surge_comm) {
        reduPordSurge += currentValue.commission_comp.prod_surge_comm;
      }
    })

    await axios({
      method: 'get',
      url: `${AppConfig.baseUrl}settlements/remittanceId?sellerId=${id}`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        setState((prev) => ({
          ...prev, remiId: result.data.data
        }))
      }
    }).catch(error => {
      console.log("error catch", error)
    });
    let prodSurgeWithGst = reduPordSurge > 0 ? (Number(reduPordSurge) + (Number(reduPordSurge) * 0.18)) : 0;
    let reduSellerAdjustment = settleArr && settleArr.reduce((accumulator, currentValue) => accumulator + (currentValue.sellerAdjustmentAmt ? Number(currentValue.sellerAdjustmentAmt) : 0), 0);
    let reduRto = settleArr && settleArr.reduce(function (previousValue, currentValue) {
      if (currentValue.rtoChargeable && currentValue.rtoChargeable == 'seller') {
        return previousValue + Number(currentValue.rtoAmt)
      } else {
        return previousValue + 0
      }
    }, 0);
    let reduGstOnRto = settleArr && settleArr.reduce(function (previousValue, currentValue) {
      if (currentValue.rtoChargeable && currentValue.rtoChargeable == 'seller') {
        return previousValue + Number(currentValue.gstOnRto)
      } else {
        return previousValue + 0
      }
    }, 0);


    setState((prev) => ({
      ...prev,
      paymentDate: new Date(),
      netAmount1: Number(reduOrderVal).toFixed(2),
      totalNetAmt: (reduNetAmt).toFixed(2),
      totalTds: (reduTotalTds).toFixed(2),
      totalTcs: (reduTotalTcs).toFixed(2),
      totalComm: (reduComm).toFixed(2),
      settlementsIds: settlementId,
      otherCharges: 0,
      gst: Number(state.groupGst),
      utrNo: state.utrNo,
      remark: state.remark,
      totalAmt: reduOrderVal,
      remittance: 0,
      set: false,
      remittanceId: state.remiId,
      otherChargesGst: 0,
      prodSurge: (prodSurgeWithGst).toFixed(2),
      rtoAmt: Number(Number(reduRto).toFixed(2)),
      gstOnRto: Number(Number(reduGstOnRto).toFixed(2)),
      adjustmentAmount: Number(Number(reduSellerAdjustment).toFixed(2))
    }));

    openPop();
  }
  const openPop = () => {
    setState((prev) => ({
      ...prev, settle: true
    }))
    setTimeout(() => setState((prev) => ({
      ...prev, loader: false
    })), 1000)
  }
  const showBar = (data) => {
    let found = props.sellerDataFromParent && props.sellerDataFromParent.filter((e) => e.order.orderNo == data.order.order_id);
    return found.length > 0 ?
      <Tooltip title={`${found[0].ticketNo}`} placement="top-start">
        <div className={`bg-dark`} style={{ cursor: 'default', maxHeight: '50%', width: "10%" }}>&nbsp;</div>
      </Tooltip>
      :
      <div style={{ maxHeight: '50%', width: "10%" }}>&nbsp;</div>
  }
  const checkBox = (data) => {
    return <div style={{ display: 'flex' }}>
      {showBar(data)}
      <div style={helper.styleForDiv}>
        <Checkbox
          name='chk'
          onClick={() => setState((prev) => ({
            ...prev, allChecked: false
          }))}
          checked={state.allChecked === true ? true : state.settleArr.findIndex(value => value._id == data._id) > -1 ? true : false}
          onChange={() => handleChangeSettle(data)}
          color='primary'
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
    </div>
  }
  const allCheckbox = () => {
    return <Checkbox
      checked={state.allChecked}
      onChange={() => allClicked()}
      color='primary'
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  }

  const allClicked = async () => {
    let { sellerDataFromParent, settlementList } = props;
    let temp = [];
    let temp1 = []
    if (!state.allChecked) {
      settlementList && settlementList.data[0].data.map((data) => {
        let found = sellerDataFromParent && sellerDataFromParent.filter(e => (e.order.order_id).toString() == (data.order_id).toString());
        if (found.length > 0) {
          let { sellerAdjustmentAmt, rtoAmt, gstOnRto, rtoChargeable } = found && found[0];
          let data1 = { sellerAdjustmentAmt, rtoAmt, gstOnRto, rtoChargeable }
          let data2 = { ...data, ...data1 }
          temp.push(data2);
          temp1.push(found[0])
        } else {
          temp.push(data)
        }
      })
      setState((prev) => ({
        ...prev, settleArr: temp, ticketsAutoSettled: temp1, allChecked: !state.allChecked
      }))
    }
    else {
      setState((prev) => ({
        ...prev, settleArr: [], ticketsAutoSettled: [], allChecked: !state.allChecked
      }))
    }
    createUnsettledArray();
  }
  const handleChangeSettle = (data) => {
    let temp = [...state.settleArr]
    let tempAmount = state.amount
    let temp1 = [...state.ticketsAutoSettled]
    let { sellerDataFromParent, settlementList } = props;
    let found = sellerDataFromParent && sellerDataFromParent.filter(e => (e.order.order_id).toString() == (data.order_id).toString())
    createUnsettledArray();
    let index = temp.findIndex(val => val._id == data._id)
    if (index > -1) {
      temp.splice(index, 1)
      if (found.length > 0) {
        let index2 = temp1.findIndex(val => val._id == found[0]._id)
        temp1.splice(index2, 1)
      }
    } else {
      if (found.length > 0) {
        let { sellerAdjustmentAmt, rtoAmt, gstOnRto, rtoChargeable } = found && found[0];
        let data1 = { sellerAdjustmentAmt, rtoAmt, gstOnRto, rtoChargeable }
        let data2 = { ...data, ...data1 }
        temp.push(data2)
        temp1.push(found[0])
      } else {
        temp.push(data)
      }
    }
    setState((prev) => ({
      ...prev, settleArr: temp, ticketsAutoSettled: temp1
    }))
  }
  const handleSearched = (searchText) => {
    let data = {
      page: 1,
      perPage: state.perPage,
      sellerId: id,
      from_date: state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format(),
      to_date: state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format(),
      type: state.type
    }

    props.getSettlementList({ data, history: props.history })
    setState((prev) => ({
      ...prev, searchedText: '', page: 0
    }))
  }
  const remittanceChrg = (values) => {
    return ((Number(state.netAmount1) / 100 * Number(values?.remittance)) / 100 * Number(state.groupGst))
  }
  const changeTick = (data) => {
    setState((prev) => ({
      ...prev, [data]: !state.data
    }))
  }
  const createUnsettledArray = async () => {
    let { sellerDataFromParent, settlementList } = props;
    let temp = [];
    let settlementArray = settlementList && settlementList.data[0] && settlementList.data[0].data;
    sellerDataFromParent && sellerDataFromParent.map(data => {
      let index = settlementArray.findIndex(e => String(e.order_id) === String(data.order.order_id))
      if (index === -1) {
        temp.push(data);
      }
    })
    setState((prev) => ({
      ...prev, unsettledTickets: temp
    }))
  }
  const checkBoxForUnsettled = (data) => {
    return <Checkbox
      name='chk'
      onClick={() => setState((prev) => ({
        ...prev, unsettledChecked: false
      }))}
      checked={state.unsettledChecked === true ? true : state.unsettledCheckedArr.findIndex(value => value._id == data._id) > -1 ? true : false}
      onChange={() => handleChangeUnsettle(data)}
      color='primary'
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  }
  const handleChangeUnsettle = (data) => { // add values when unsettled tikcet is checked
    let temp = [...state.unsettledCheckedArr];
    let index = temp.findIndex(val => val._id == data._id)
    if (index > -1) {
      temp.splice(index, 1);
      setState(prevState => ({
        ...prevState,
        adjustmentAmount: prevState.adjustmentAmount - Number(data.sellerAdjustmentAmt),
        rtoAmt: data.rtoChargeable == 'seller' ? prevState.rtoAmt - Number(data.rtoAmt) : prevState.rtoAmt,
        gstOnRto: data.rtoChargeable == 'seller' ? prevState.gstOnRto - Number(data.gstOnRto) : prevState.gstOnRto
      }));
    } else {
      temp.push(data)
      setState(prevState => ({
        ...prevState,
        adjustmentAmount: prevState.adjustmentAmount + Number(data.sellerAdjustmentAmt),
        rtoAmt: data.rtoChargeable == 'seller' ? prevState.rtoAmt + Number(data.rtoAmt) : prevState.rtoAmt,
        gstOnRto: data.rtoChargeable == 'seller' ? prevState.gstOnRto + Number(data.gstOnRto) : prevState.gstOnRto
      }));
    }
    setState((prevState) => ({ ...prevState, unsettledCheckedArr: temp }))

  }
  function toggle() {
    setState((prevState) => ({ ...prevState, popoverOpen: !state.popoverOpen }));
  }
  const pop = (data) => {
    return <><i class="zmdi zmdi-info" variant="contained" color="primary" id={"ust" + data.ticketNo} onClick={toggle} />
      <Popover placement={'bottom'} isOpen={state.popoverOpen} trigger="legacy" target={"ust" + data.ticketNo} toggle={toggle} >
        Order Id: {data?.order?.orderNo}, &nbsp;
        Buyer: {data?.buyer?.buyerName}
      </Popover></>
  }
  const { groupGst, set } = state
  const { from, to } = state
  let { otherCharges, gst, utrNo, tds, tcs, commission, transactionId, remark, paymentDate, netAmount1, netAmount, order_value, createdAt, totalNetAmt, totalAmt, totalTds,
    totalTcs, totalComm, remittance, remittanceId, otherChargesGst, rtoAmt, gstOnRto, adjustmentAmount, unsettledTickets, unsettledCheckedArr, type } = state
  const { settlementList } = props

  const options = {
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: false,
    selectableRows: false,
    // selectableRows: "multiple",
    selectableRowsHeader: false,
    page: state.page,
    rowsPerPage: settlementList && settlementList.data[0] && settlementList.data[0].metadata[0]
      && settlementList.data[0].metadata[0].total,
    fixedHeader: false,
    print: false,
    filter: true,
    sort: false,
    count: settlementList && settlementList.data[0] && settlementList.data[0].metadata[0]
      && settlementList.data[0].metadata[0].total,
    serverSide: true,
    server: true,
    search: true,
    searchText: state.searchedText,
    rowsPerPageOptions: [],
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      handleSettelMent()
      return false
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <div style={{ paddingTop: 15 }} >
            <Button variant="contained" onClick={() => { applyFilter(); applyNewFilters() }} className='filterButton' color='primary'>Apply Filter</Button>
            <Button variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton' color='primary'>Reset Filter</Button>
          </div>
        </div>
      );
    },
    customToolbar: () => {
      return (isButtonAvailable('settleOrder') && !isButtonAvailable('viewOnlySettlements') &&
        <React.Fragment>
          <Button variant="outlined" disableA={state.settleArr.length == 0} className={state.settleArr.length > 0 ? 'text-success' : 'text-grey'} onClick={(e) => handleSettle()} disabled={state.settleArr.length > 0 ? false : true}>Settle</Button>
        </React.Fragment>
      );
    },
    onTableChange: (action, tableState) => {

      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key == 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearched(tableState.searchText)
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count} />
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }
  let columns = [
    {
      name: "orderId",
      label: "Order Id",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "orderValue",
      label: "Order Value",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "tds",
      label: "TDS",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "tcs",
      label: "TCS",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "commission",
      label: "Commission",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "netAmt",
      label: "Net Amt",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "payment",
      label: "Payment mode",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "deliveryDate",
      label: "Delivery Date",
      options: {
        filter: true,
        filterType: 'custom',
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        },
        customFilterListRender: v => {
          return false;
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, handleCustomChange, applyFilter, index, column) => (
            <React.Fragment>
              <Row style={{ maxWidth: 350 }}>
                <CustomeFilterForDate handleDateChange={handleDateChanged} from={from} to={to} />
                <Col md={12} xl={12} xs={12} sm={12} lg={12}>
                  <Label for="exampleSelect">Payment Mode</Label>
                  <Select style={{ margin: "1px" }}
                    labelId="demo-multiple-checkbox-label"
                    multiple
                    id="demo-multiple-checkbox-label"
                    value={type}
                    onChange={(e) => handleSelectChangePaymentType(e)}
                    input={<Input1 disableUnderline={true} className="form-control " id="name-multiple" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      key={'select type'}
                      value={'type'}
                      style={{
                        fontWeight: '500',
                      }}
                    >
                      Select payment mode
                    </MenuItem>
                    {payment_mode && payment_mode.map(name => (
                      <MenuItem
                        key={name}
                        value={name && name == 'Bulk Prepaid' ? 'Bulk' : name == 'Prepaid' ? "Online" : name}
                        className='putIconAfter '
                        style={{
                          fontWeight: state.type.indexOf(name) !== -1 ? '500' : '400',
                        }}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>

                </Col>
              </Row>
            </React.Fragment>
          ),
          onFilterChange: () => {
            console.log('onFilterChange')
          }
        },
      }
    }
  ];
  let removed = isButtonAvailable('settlementAllCheckbox') && !isButtonAvailable('viewOnlySettlements') ? columns.splice(0, 0,
    {
      name: " ",
      label: allCheckbox(),
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
  ) : [];

  let removed1 = isButtonAvailable('settlementActions') && !isButtonAvailable('viewOnlySettlements') ? columns.splice(columns.length, 0,
    {
      name: "action",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        },
      },
    },
  ) : [];

  let data1 = []

  settlementList && settlementList.data[0] && settlementList.data[0].data.map((dataOne, index) => {

    data1.push({
      orderId: <a href={`/admin/orders/orderDetails/${dataOne.order.order_id}`} target="_blank" style={{ color: 'black' }}>
        <div style={{ width: '120px' }}>
          <span style={{ position: 'absolute', marginLeft: "-20px" }}>
            {dataOne.PCD != 0 ?
              <Tooltip title={`PCD Product`} >
                <span className="size-8 rounded-circle d-inline-block" style={{ backgroundColor: 'orange' }} />
              </Tooltip> : ''}


            {dataOne.order.order_status[dataOne.order.order_status.length - 1].status == 'LOST' ?
              <Tooltip title={`LOST`} placement="top-start">
                <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'red' }}>
                </span>
              </Tooltip> : ""}
          </span>
          <span className='ml-1'>
            {dataOne.order.order_id}
          </span>

        </div></a>,
      orderValue: "₹" + (dataOne.order_value).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
      tds: "₹" + (dataOne.tds).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
      tcs: "₹" + (dataOne.tcs).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
      commission: "₹" + (dataOne.commission).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
      netAmt: "₹" + (dataOne.net_amt).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
      payment: dataOne.order.paymentType && dataOne.order.isBulk === true && !dataOne.order.rawTransaction && dataOne.order.rawTransaction == undefined ? 'Bulk Prepaid' : dataOne.order.paymentType === "COD" ? "COD" : ((dataOne.order.paymentType === "Online" && dataOne.order.rawTransaction != undefined) ? dataOne.order.rawTransaction.payment_mode && dataOne.order_value - (dataOne.order.rawTransaction && dataOne.order.rawTransaction.amount) > 0 ? "MediWallet+" + dataOne.order.rawTransaction.payment_mode : dataOne.order.rawTransaction.payment_mode : "MediWallet"),

      deliveryDate: moment(dataOne.payment_due_date).format('DD/MM/YYYY'),
    })
    data1[index] = isButtonAvailable('settlementCheckbox') && !isButtonAvailable('viewOnlySettlements') ? { " ": checkBox(dataOne), ...data1[index] } : data1[index];
    data1[index] = isButtonAvailable('settlementActions') && !isButtonAvailable('viewOnlySettlements') ? { ...data1[index], action: getAction(dataOne) } : data1[index];
  })
  let sellerName = settlementList && settlementList.data[0] && settlementList.data[0].data[0] && settlementList.data[0].data[0].seller.company_name;

  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  const datePickerClasses = {
    root: {
      backgroundColor: 'white',
      marginTop: '-5px',
    },
  };
  return (
    <div className="dashboard animated slideInUpTiny animation-duration-3">
      {(props.loading == false && state.loader == false) ?
        <ThemeProvider theme={helper.getMuiThemeForSettlementD()}>
          <MUIDataTable
            identifier={'allSettlement'}
            handleSettelMent={handleSettelMent}
            title=''
            data={data1}
            columns={columns}
            options={options}
          />
        </ThemeProvider> :
        <div className="loader-view" style={{ textAlign: "center", marginTop: "150px" }}>
          <CircularProgress />
        </div>
      }
      <ViewSellerSettlement buttonType={'view'} dataFromParent={state.data} view={state.view} title={'View Settlement'} handleClick={handleClick} match={props.match}
        from={state.from} to={state.to} searchText={state.searchedText} />

      <Dialog open={state.settle} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'md'}>
        <Form
          onSubmit={onSettle}
          initialValues={{
            totalTds: state.totalTds,
            totalTcs: state.totalTcs,
            totalComm: state.totalComm,
            gst: state.gst,
            otherCharges: state.otherCharges,
            otherChargesGst: state.otherChargesGst,
            rtoAmt: state.rtoAmt,
            gstOnRto: state.gstOnRto,
            adjustmentAmount: state.adjustmentAmount,
            remittance: state.remittance || 0,
            utrNo: state.utrNo,
            remittanceId: state.remiId,
            remark: state.remark,
          }}
          render={({ handleSubmit, values }) => {
            return <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle className='pb-0'>
                Group Settlement
              </DialogTitle>
              <DialogContent>
                <Row>
                  <Col xs={12} md={12} sm={12} xl={9} lg={9}>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Label className='mb-0' for="sellerName">Seller Name</Label>
                          <Input type="text" name="sellerName" id="sellerName"
                            value={sellerName}
                            onChange={(e) => handleChange(e, 'sellerName')}
                            label={'Seller Name'} readOnly
                            component={ReactstrapTextField}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Label className='mb-0' for="orderDate">Order Amount</Label>
                          <Input type="text" name="orderDate" readOnly
                            value={totalAmt}
                            id="orderDate" label={'Order Date'} onChange={(e) => handleChange(e, 'orderAmt')} component={ReactstrapTextField} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Field type="text" name="totalTds" id="totalTds" label={'TDS '}
                            value={totalTds} disabled={true}
                            onChange={(e) => handleChange(e, 'totalTds')} validate={composeValidators(required, number)}
                            component={ReactstrapTextField} >

                          </Field>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Field type="text" name="totalTcs" id="totalTcs" label={'TCS '}
                            value={totalTcs} disabled={true}
                            marginBottomZero={true}
                            onChange={(e) => handleChange(e, 'totalTcs')} validate={composeValidators(required, number)}
                            component={ReactstrapTextField} >

                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Field type="text" name="totalComm" id="totalComm" label={'Commission'}
                            value={totalComm} disabled={true}
                            onChange={(e) => handleChange(e, 'totalComm')}
                            component={ReactstrapTextField} validate={composeValidators(required, number)} >

                          </Field>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Field type="text" name="gst" id="gst" label={'GST% on commission'}
                            onChange={(e) => handleChange(e, 'gst')}
                            validate={number} disabled={true}
                            component={ReactstrapTextField} />

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Field type="text" name="otherCharges" id="otherCharges" label={'Other Charges'} value={otherCharges}
                            onChange={(e) => handleChange(e, 'otherCharges')}
                            validate={numberCanBeNega}
                            component={ReactstrapTextField}  >

                          </Field>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Field type="text" name="otherChargesGst" id="otherChargesGst" label={'GST on Other Charges'} value={otherChargesGst} onChange={(e) => handleChange(e, 'otherChargesGst')}
                            validate={numberCanBeNega}
                            component={ReactstrapTextField}  >

                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Label for="rtoAmt">RTO</Label>
                          <Input type="text" name="rtoAmt" readOnly
                            value={Number(rtoAmt).toFixed(2)}
                            id="rtoAmt" label={'RTO'} />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>

                          <Label for="gstOnRto">GST on RTO</Label>
                          <Input type="text" name="gstOnRto" readOnly
                            value={Number(gstOnRto).toFixed(2)}
                            id="gstOnRto" label={'GST on RTO'} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Label for="adjustmentAmount">Adjustment Amount</Label>
                          <Input type="text" name="adjustmentAmount" readOnly
                            value={Number(adjustmentAmount).toFixed(2)}
                            id="adjustmentAmount" label={'Adjustment Amount'} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Field type="text" name="remittance" id="remittance" label={'Early remittance charge %'} value={remittance} onChange={(e) => handleChanged(e, 'remittance')}
                            component={ReactstrapTextField}  >

                          </Field>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Label for="remark">GST({groupGst}%) on Early remittance charge</Label>
                          <Field type="text" name="remittance" id="remittance" label={'Early remittance charge '} value={remittance} onChange={(e) => handleChanged(e, 'remittance')}
                            hide={true}
                            component={ReactstrapTextField} />
                          <div style={{ marginTop: '7px' }}>{(remittanceChrg(values)).toFixed(2)}</div>

                        </FormGroup>
                      </Col>


                    </Row>

                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Field type="text" name="utrNo" id="utrNo" label={'UTR NO'} value={utrNo} onChange={(e) => handleChanged(e, 'utrNo')}
                            component={ReactstrapTextField}  >
                          </Field>

                        </FormGroup>
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <Label className='mb-0' for="paymentDate">Payment Date</Label>
                        <FormGroup style={{ backgroundColor: 'white', marginTop: '-5' }}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              fullWidth
                              slotProps={{ textField: { variant: 'standard' } }}
                              renderInput={(params) => <TextField {...params} />}
                              selected={(paymentDate)}
                              onChange={date => handleDateChange(date, 'paymentDate')}
                              validate={required}
                              className="form-control"
                              value={moment(paymentDate)}
                            />
                          </LocalizationProvider>


                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Field type="text" name="remittanceId" id="remittanceId" label={'Remittance Id'} value={remittanceId} onChange={(e) => handleChanged(e, 'remittanceId')}
                            component={ReactstrapTextField} validate={required}>
                          </Field>

                        </FormGroup>
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <FormGroup>
                          <Label for="netAmount">Net Amount</Label>
                          <Field type="text" name="netAmount1" id="netAmount1" hide={true}
                            label={'Net Amount'}
                            component={ReactstrapTextField} />
                          <div style={{ marginTop: '7px' }}>{totalAmountFormula(values)}</div>
                          {totalAmountFormula(values) < 0 && <span className='text-danger'>Net Amount cannot be negative !</span>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                        <FormGroup>
                          <Field type="text" name="remark" id="remark" label={'Remark'} value={remark} onChange={(e) => handleChanged(e, 'remark')}
                            validate={required}
                            component={ReactstrapTextField} >
                          </Field>
                        </FormGroup>
                      </Col>


                    </Row>
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={3} lg={3} className='pt-4' style={{ height: '650px' }}>
                    <h3>Tickets of undelivered and settled orders</h3>
                    <div style={{ height: '590px', overflow: 'auto', overflowX: 'hidden' }}>
                      {unsettledTickets && unsettledTickets.map((data) =>
                        <Row>
                          <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                            {checkBoxForUnsettled(data)}
                            <Label for="netAmount">{data.ticketNo} &nbsp;&nbsp;<Pop data={data} /></Label>
                          </Col>
                        </Row>
                      )}
                    </div>
                    <Row>
                      <Col style={{ textAlign: 'right' }}>
                        <DialogActions className='pt-0'>
                          <Button onClick={handleRequestClose} color='secondary' >
                            Close
                          </Button>
                          {Number(totalAmountFormula()) < 0 ?
                            <Button disabled={true}>
                              Settle
                            </Button> :
                            <Button type='submit' color='primary' disabled={set}>
                              {set ? 'Processing' : 'Settle'}
                            </Button>}
                        </DialogActions>
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </DialogContent>

            </form>
          }}
        />

      </Dialog>

    </div>
  );
}

const mapStateToProps = ({ admin }) => {
  const { settlementList, groupSettle, loading, actionList } = admin

  return { settlementList, groupSettle, loading, actionList }
}

export default connect(mapStateToProps, { getSettlementList, getGroupSettlement, getListGroupSettlement })(SettlementsDetails)