import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import { getBuyerTransactionList } from 'actions/admin';
import customHead from 'constants/customHead';
import TransactionHeader from './TransactionHeader';
import { useNavigate } from 'react-router';
import AdminContainer from 'containers/AdminContainer';
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'app/routes/components/PaginationCount';
import CircularProgress from '@mui/material/CircularProgress';

function AllBuyers(props) {
  const history = useNavigate()
  let url = window.location.search
  let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''
  const [page, setPage] = useState(json && json.pageT ? Number(json.pageT || 0) : 0)
  const [searchedText, setSearchedText] = useState(json ? json.searchT ? json.searchT : '' : '')
  const perPage = 50

  const fetchData = async () => {
    let data = {
      page: page + 1,
      perPage: perPage,
      searchText: searchedText
    }
    await props.getBuyerTransactionList({ data })
  }

  useEffect(() => {
    setPage(json && json.pageT ? Number(json.pageT || 0) : 0)
    setSearchedText(json && json.searchT ? json.searchT : '')
    fetchData()
  }, [window.location.search])

  const changePage = (page) => {
    setPage(page)
    let obj = { pageT: page, searchT: searchedText }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/transaction?${url}`)
  };

  const handleSearch = (searchText) => {
    setSearchedText(searchText)
    let obj = { pageT: 0, searchT: searchText }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/transaction?${url}`)
  }
  const redirect = (data) => {
    window.open(`/admin/transaction/details/${data}`, '_blank')
  }

  const { allbuyer } = props;

  const columns = [
    {
      name: "buyerId",
      label: "Buyer Id",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "company",
      label: "Company Name",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "mWallet",
      label: "MediWallet",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    }]
  const options = {
    searchPlaceholder: 'Press Enter to Search by Company name OR Email',
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    responsive: 'scrollMaxHeight',
    search: true,
    viewColumns: false,
    rowHover: false,
    rowsPerPageOptions: [],
    server: true,
    serverSide: true,
    page: page,
    filter: false,
    searchText: searchedText,
    rowsPerPage: perPage,
    count: allbuyer && allbuyer.metadata[0] && allbuyer.metadata[0].total,
    textLabels: {
      filter: {
        all: "All",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  };
  let data1 = [];
  allbuyer && allbuyer.data.map((dataOne) => {
    data1.push({
      buyerId: <div style={{ cursor: 'pointer' }} onClick={(e) => redirect(dataOne._id)}>
        {dataOne.buyerId}</div>,
      company: <div style={{ textTransform: 'capitalize', cursor: 'pointer' }} onClick={(e) => redirect(dataOne._id)}>{dataOne.company_name}</div>,
      mWallet: <div style={{ cursor: 'pointer' }} onClick={(e) => redirect(dataOne._id)}>
        MNY {(dataOne.wallet_balance).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
    })
  })
  return (
    <AdminContainer>
      <React.Fragment>
        <TransactionHeader match={props.match} buyers={allbuyer} />
        {props.loading ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}
        {
          props.loading === true ?
            null :
            <MUIDataTable
              data={data1}
              columns={columns}
              options={options}
            />
        }
      </React.Fragment>
    </AdminContainer>
  )
}
const mapStateToProps = ({ admin }) => {
  const { allbuyer, actionList, loading} = admin;
  return { allbuyer, actionList, loading };
}
export default connect(mapStateToProps, { getBuyerTransactionList })(AllBuyers);