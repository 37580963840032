import React from 'react';
import moment from 'moment'
const WithIconTimeLineItem = ({ styleName, color, timeLine, children }) => {
  const { date, status, description, admin_description } = timeLine;
  return (
    <div className={`timeline-item timeline-time-item ${styleName}`}>
      <div className="timeline-time">{moment(date).format('DD MMM, YYYY')}</div>
      <div className={`timeline-badge bg-${color}`}>{children}</div>
      <div className="timeline-panel">
        <h4 className={`timeline-tile text-${color}`}>
          {status === "New" ?
            "Placed"
            :
            status
          }
        </h4>
        <p>{admin_description ? admin_description : description}</p>
      </div>
    </div>
  )
};
export default WithIconTimeLineItem;