import React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { changeTab } from 'actions/tabAction';
import { connect } from 'react-redux';
import All from '../../../../outOfStock/routes/All';
import Sellerwise from '../../../../outOfStock/routes/sellerwise';
import { useNavigate } from "react-router-dom"

function TabContainer({ children, value, tabValue }) {
    return (
        (value === tabValue) && <div>
            {children}
        </div>
    );
}

const FullWidthTabForOutOfStock = (props) => {
    const navigate = useNavigate()

    const handleChange = (event, value) => {
        props.changeTab(value)
        navigate(`/admin/outOfStock`)
    };

    const { tabValue } = props;

    return (
        <div className="w-100" >
            <AppBar position="static" color="default">
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    scrollButtons="on"
                >
                    <Tab className="tab" label="All" />
                    <Tab className="tab" label="sellerwise" />
                </Tabs>
            </AppBar>
            <TabContainer value={0} tabValue={tabValue}>
                <All tab={tabValue} />
            </TabContainer>
            <TabContainer value={1} tabValue={tabValue}>
                <Sellerwise tab={tabValue} />
            </TabContainer>
        </div>
    );
}

const mapStateToProps = ({ tabAction }) => {
    const { tabValue } = tabAction;
    return { tabValue }
};

export default connect(mapStateToProps, { changeTab })(FullWidthTabForOutOfStock);
