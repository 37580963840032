import React from "react";
import FullWidthTabForOutOfStock from "../components/routes/tabs/fixed/FullWidthTabForOutOfStock";
import AdminContainer from "containers/AdminContainer";

const OutOfStockTab = (props) => {
  return (
    <AdminContainer>
      <div className="page-heading buyerDetailsHeader d-sm-flex justify-content-sm-between align-items-sm-center">
        <h2 className="title mb-3 mb-sm-0">Out Of Stock</h2>
      </div>
      <FullWidthTabForOutOfStock />
    </AdminContainer>
  );
}

export default OutOfStockTab;