import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Col, Row, Label, Input } from 'reactstrap';
import { FormControl } from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';
import customHead from 'constants/customHead'
import { connect } from 'react-redux';
import { getTicketList } from 'actions/admin';
import CircularProgress from '@mui/material/CircularProgress';
import helper from '../../../../constants/helperFunction';
import { Badge } from 'reactstrap';
import '../../orders/routes/index.css'
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

function Closed(props) {
  let url = window.location.search
  let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''

  const [state, setState] = useState({
    filter: [],
    perPage: 100,
    loader: false,
    searchedText: json ? json.searchTC ? json.searchTC : '' : '',
    page: json ? json.pageTC ? Number(json.pageTC) : 0 : 0,
    status: json ? json.statusTC ? json.statusTC : '' : '',
    seller: json ? json.sellerTC ? json.sellerTC : '' : '',
    buyer: json ? json.buyerTC ? json.buyerTC : '' : '',
    sort: json ? json.sortC ? json.sortC : '' : '',
  })
  const [page1, setPage1] = useState(json ? json.pageTC ? Number(json.pageTC) : 0 : 0,)

  const handleChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }))
  }
  const componentdidMount = () => {
    let data = {
      page: page1 + 1,
      perPage: 100,
      searchText: state.searchedText,
      close: '1',
      // status: state.status,
      seller: state.seller,
      buyer: state.buyer,
      sort: state.sort
    }
    if (props.tab == 1) {
      props.getTicketList({ data, history: props.history })
    }
  }
  useEffect(() => {
    componentdidMount()
  }, [window.location.search])

  const changePage = (page) => {
    setPage1(page)
    let obj = { pageTC: page, searchTC: state.searchedText, buyerTC: state.buyer, sellerTC: state.seller, sortC: state.sort }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    props.history(`/admin/tickets?${url}`)
  };
  const handleSearch = (searchText) => {
    setState((prev) => ({ ...prev, searchedText: searchText ? searchText.replace(/"/g, '') : '' }))
    let obj = { pageTC: 0, searchTC: searchText ? searchText.replace(/"/g, '') : '', statusTC: state.status, buyerTC: state.buyer, sellerTC: state.seller, sortC: state.sort }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    props.history(`/admin/tickets?${url}`)
  };
  const handleCloseSearch = () => {
    setState((prev) => ({ ...prev, searchedText: '' }))
    let obj = { pageTC: 0, searchTC: '', statusT: state.status, buyerTC: state.buyer, sellerTC: state.seller, sortC: state.sort }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    props.history(`/admin/tickets?${url}`)
  }
  const handleResetFilter = () => {
    setState((prev) => ({ ...prev, status: '' }));
    let obj = { pageTC: 0, searchT: state.searchedText, statusT: '', sortC: state.sort }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    props.history(`/admin/tickets?${url}`)
  }
  const applyFilter = () => {
    let obj = { pageTC: 0, searchTC: state.searchedText, statusTC: state.status, buyerTC: state.buyer, sellerTC: state.seller, sortC: state.sort }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    props.history(`/admin/tickets?${url}`)
  }
  const color = (data) => {
    return data ? data == 'open' ? 'warning' : 'success' : ''
  }
  const sort = (data) => {
    let sortBy = data.substr(33, 4);
    console.log('asfamsfiunasfasf', data, sortBy)
    let obj = { pageTC: 0, searchTC: state.searchedText, statusTC: state.status, buyerTC: state.buyer, sellerTC: state.seller, sortC: state.sort == 'desc' ? 'asce' : sortBy }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    props.history(`/admin/tickets?${url}`)
  }

  const { ticketList, sellerData, buyerData } = props;

  let { buyer, seller } = state;
  const columns = [{
    name: "Ticket ID",
    label: "Ticket ID",
    options: { filter: false, sort: false }
  }, {
    name: "Seller Name",
    label: "Seller Name",
    options: { filter: false, sort: false }
  }, {
    name: "Updated At",
    label: "Updated At",
    options: { filter: false }
  }, {
    name: "Order Id",
    label: "Order Id",
    options: { filter: false, sort: false }
  }, {
    name: "Order Date",
    label: "Order Date",
    options: { filter: false, sort: false }
  }, {
    name: "Issue",
    label: "Issue",
    options: { filter: false, sort: false }
  }, {
    name: "Buyer Status",
    label: "Buyer Status",
    options: { filter: false, sort: false }
  }, {
    name: "Seller Status",
    label: "Seller Status",
    options: { filter: false, sort: false }
  }, {
    name: "RTO Status",
    label: "RTO Status",
    options: { filter: false, sort: false }
  }, {
    name: "Created By",
    label: "Created By",
    options: {
      filter: true,
      sort: false,
      filterType: 'custom',
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      },
      customFilterListRender: v => {
        return false;
      },
      filterOptions: {
        names: [],
        logic() {

        },
        display: (filterList, handleCustomChange, index, column) => (
          <React.Fragment>
            <Row form style={{ maxWidth: 300 }}>
              <Col md={12} xl={12} xs={12} lg={12}>
                <FormControl className={'mt-1 w-100 mb-2'} >
                  <Label for="exampleSelect">Buyers</Label>
                  <Input type="select" name="buyer" id="buyer"
                    value={buyer}
                    onChange={(e) => handleChange(e, 'buyer')}>
                    <option selected={buyer == ''} value={''}>Select Buyer</option>
                    {
                      buyerData && buyerData.map((val) => {
                        return (<option selected={val._id == buyer} value={val._id}>{val.company_name}</option>)
                      })
                    }
                  </Input>
                </FormControl>
              </Col>
              <Col md={12} xl={12} xs={12} lg={12}>
                <FormControl className={'mt-1 w-100 mb-2'}>
                  <Label for="exampleSelect">Sellers</Label>
                  <Input type="select" name="seller" id="seller"
                    value={seller}
                    onChange={(e) => handleChange(e, 'seller')}>
                    <option selected={seller == ''} value={''}>Select Seller</option>
                    {
                      sellerData && sellerData.map((val) => {
                        return (<option selected={val._id == seller} value={val._id}>{val.company_name}</option>)
                      })
                    }
                  </Input>
                </FormControl>
              </Col>
            </Row>
            <div style={{ paddingTop: 15 }} >
              <Button variant="contained" onClick={() => applyFilter()} className='filterButton' color='primary'>Apply Filter</Button>
              <Button variant="contained" onClick={() => handleResetFilter()} className='filterButton' color='primary'>Reset Filter</Button>
            </div>
          </React.Fragment>
        ),
        onFilterChange: () => {
          console.log('onFilterChange')
        }
      },
    },
  }
  ]
  let data = [];
  ticketList && ticketList.data1.map((d) => data.push(
    {
      'Ticket ID': <a href={`/admin/tickets/ticketDetails/${d._id}`} className="text-primary" style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); props.history(`/admin/tickets/ticketDetails/${d._id}`) }} >{d.ticketNo}</a>,
      'Updated At': <div>{moment(d.updatedAt).format('D MMM, YYYY h:mm a')}</div>,
      'Seller Name': d.seller && d.seller.sellerName ? d.seller.sellerName : 'N/A',
      'Order Id': d.type == 'order' ? d.order.orderNo : 'N/A',
      'Order Date': d.type == 'order' ? moment(d.order.order_id.createdAt).format('DD/MM/YYYY') : 'N/A',
      'Issue': d.issue,
      'Buyer Status': d.type == 'order' ? <Badge color={color(d.buyerStatus)} pill className='text-white'>{helper.textCapitalize(d.buyerStatus == 'close' ? 'closed' : d.buyerStatus)}</Badge> : 'N/A',
      'Seller Status': d.type == 'order' ? <Badge color={color(d.sellerStatus)} pill className='text-white'>{helper.textCapitalize(d.sellerStatus == 'close' ? 'closed' : d.sellerStatus)}</Badge> : 'N/A',
      'RTO Status': d.type == 'order' ? <Badge color={color(d.rtoStatus)} pill className='text-white'>{helper.textCapitalize(d.rtoStatus == 'close' ? 'closed' : d.rtoStatus)}</Badge> : 'N/A',
      'Created By': d.createdBy
    }
  ));

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: false,
    selectableRows: false,
    rowsPerPage: 100,
    page: page1,
    fixedHeader: false,
    print: false,
    download: false,
    filter: false,
    count: ticketList && ticketList.count,
    serverSide: true,
    server: true,
    selectableRowsOnClick: false,
    selectableRows: 'none',
    fixedHeader: false,
    search: true,
    searchText: state.searchedText,
    rowsPerPageOptions: [],
    rangePage: true,
    onTableChange: (action, tableState) => {
      console.log('action=', action, tableState)
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key == 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleCloseSearch();
          break;
        case 'sort':
          sort(tableState.announceText);
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }
  const { loading } = props;
  return (
    <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
      {loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}
      {
        loading ?
          null :
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
            style={{ borderRadius: '0px !important' }}
          />
      }
    </div>
  );
}

const mapStateToProps = ({ admin }) => {
  const { ticketList, loading } = admin;
  return { ticketList, loading }
};

export default connect(mapStateToProps, { getTicketList })(Closed);

