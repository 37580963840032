import React, { Component, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import ContainerHeader from '../../../../components/ContainerHeader';
import ButtonGroup from '@mui/material/ButtonGroup';
import customHead from 'constants/customHead';
import DeleteGroup from './component/deleteInventory';
import AddGroup from './component/addGroup';
import EditGroup from './component/editGroup';
import { listGroup } from 'actions/admin';
import { connect } from 'react-redux';
import AdminContainer from 'containers/AdminContainer';
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'app/routes/components/PaginationCount';
import CircularProgress from '@mui/material/CircularProgress';

const columns = [
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "noOfMember",
    label: "No Of Member",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  }
];

function Groups(props) {

  const [state, setState] = useState({
    editGroup: false,
    delete: false,
    addGroup: false,
    page: 0,
    searchedText: '',
    perPage: 50,
    selectedData: {}
  })

  const changePage = (page) => {
    let pages = page + 1
    props.listGroup({ userId: '', searchText: state.searchedText, page: pages, perPage: state.perPage })
    setState((prev) => ({ ...prev, page }))
  };

  const changeRowsPerPage = (perPage) => {
    props.listGroup({ page: 1, perPage })
    setState((prev) => ({ ...prev, page: 0, perPage }))
  }

  const handleSearch = (searchText) => {
    props.listGroup({ userId: '', searchText: searchText, page: state.page > 0 ? state.page : 1, perPage: state.perPage })
    setState((prev) => ({ ...prev, searchedText: searchText }))
  };

  const handleClick = (key, value) => {
    setState((prev) => ({ ...prev, [key]: !state[key], selectedData: value }))
  }

  useEffect(() => {
    props.listGroup({ searchText: state.searchedText, page: 1, perPage: state.perPage })
  }, [])

  const button = (data) => (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button className={'text-primary'} onClick={() => handleClick('editGroup', data)} > Edit</Button>
      <Button className={'text-danger'} onClick={() => handleClick('delete', data)}> Delete</Button>
    </ButtonGroup>
  )

  let { listGroupData } = props;
  let { selectedData } = state;
  const options = {
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: false,
    selectableRows: false,
    rowsPerPageOptions: [],
    page: state.page,
    fixedHeader: false,
    print: false,
    download: false,
    filter: false,
    sort: false,
    count: props.listGroupData.length > 0 ? props.listGroupData[0].metadata.length > 0 ? props.listGroupData[0].metadata[0].total : 0 : 0,
    serverSide: true,
    rowsPerPage: state.perPage,
    server: true,
    selectableRowsOnClick: false,
    searchText: state.searchedText,
    onTableChange: (action, tableState) => {

      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key == 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          handleSearch(tableState.searchText)
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  var groupData = [];
  listGroupData.length > 0 && listGroupData[0].data.length > 0 && listGroupData[0].data.map((data, index) => {
    groupData.push([data.name, data.members, button(data)]);
  });

  return (
    <AdminContainer>
      <React.Fragment>
        <ContainerHeader match={props.match} title={"Groups"} />
        {props.loading ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}
        {
          props.loading === true ?
            null :
            <MUIDataTable
              title={<Button className={'text-primary'} selectedData={selectedData} onClick={(e) => handleClick('addGroup', selectedData)}> Add Group +</Button>}
              data={groupData}
              columns={columns}
              options={options}
            />
        }
        <EditGroup selectedData={selectedData} editGroup={state.editGroup} title={'Edit Group'} handleClick={(e) => handleClick('editGroup', selectedData)} />
        <AddGroup buttonType={'addGroup'} add_group={state.addGroup} title={'Add Group'} handleClick={(e) => handleClick('addGroup', selectedData)} />
        <DeleteGroup delete={state.delete} selectedData={selectedData} deleteFor={'group'} handleClick={handleClick} />
      </React.Fragment>
    </AdminContainer>
  );
}

const mapStateToProps = ({ admin }) => {
  const { listGroupData, loading } = admin;
  return { listGroupData, loading }
};

Groups = connect(
  mapStateToProps,
  {
    listGroup
  }               // bind account loading action creator
)(Groups)

export default Groups;