import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { FormGroup, Label, Input } from 'reactstrap';
import AppConfig from 'constants/config'

import axios from 'axios';
import moment from 'moment';
import './index.css'
import CustomeFilterForDate from 'app/routes/components/CustomFilter/customfilter';

function OrderHeader(props) {


  const [state, setState] = useState({
    checkedA: false,
    selectedDate: moment().format(),
    openFilter: false,
    seller_id: '',
    from: moment().subtract(1, 'months'),
    to: moment().format()
  })

  const fetchApi = async () => {
    await axios({
      method: 'get',
      url: `${AppConfig.baseUrl}inventory/get_filters`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      }
    }
    ).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        setState((prev) => ({
          ...prev,
          filterData: result.data.detail.sellers
        }))
      }
    }).catch(error => {
      console.log("error catch", error)
    });
  }
  useEffect(() => {
    fetchApi()
  }, [])

  const handleDownload = (e) => {
    props.history.push(`/order/export?token=${localStorage.getItem('admin_token')}`);
  }
  const handleChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
  }
  const handleDateChange = (date, key) => {
    setState((prev) => ({ ...prev, [key]: date }))
    // moment(date).format('MM/DD/YYYY')
  }
  const applyFilter = async () => {
    window.open(`${AppConfig.baseUrl}order/export?token=${localStorage.getItem('admin_token')}&from_date=${state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format()}&to_date=${state.to ? moment(state.to).format('YYYY-MM-DD') : moment().format()}&status=${state.status ? state.status : ''}&seller_id=${state.seller_id ? state.seller_id : ''} `, '_blank');
    setState((prev) => ({
      ...prev,
      openFilter: false,
      status: '',
      seller_id: ''
    }))
  }
  const handleRequestClose = () => {
    setState((prev) => ({
      ...prev,
      openFilter: false,
      status: '',
      seller_id: '',
      from_date: '',
      to_date: '',
      from: moment().format(),
      to: moment().format()
    }));
  }

  let { status, filterData, seller_id, from, to } = state;
  return (
    <div className="page-heading buyerDetailsHeader d-flex justify-content-between align-items-sm-center">
      <h2 className="title mb-3 mb-sm-0">Orders </h2>
      <Button variant="outlined" className={'text-primary'} onClick={() => { setState((prev) => ({ ...prev, openFilter: true })) }}>
        Download
      </Button>
      <Dialog open={state.openFilter} onClose={handleRequestClose}>
        <DialogContent >

          <Row form >
            <CustomeFilterForDate handleDateChange={handleDateChange} to={to} from={from} />
          </Row>

          <Row>
            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
              <FormGroup className={'mt-1'}>
                <Label for="exampleSelect">Sellers</Label>
                <Input type="select" name="seller" id="seller"
                  value={seller_id}
                  onChange={(e) => handleChange(e, 'seller_id')}>
                  <option selected={seller_id == ''} value={''}>Select Seller</option>
                  {
                    filterData && filterData.map((val) => {
                      return (<option selected={val._id == seller_id} value={val._id}>{val.company_name}</option>)
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Col md={6} xl={6} xs={6} sm={6} lg={6}>
              <FormGroup className={'mt-1'}>
                <Label for="exampleSelect">Status</Label>
                <Input type="select" value={status} onChange={(e) => handleChange(e, 'status')} name="status" id="status">

                  <option value="" >All</option>
                  <option value="New" >New</option>
                  <option value="Processed" >Processed</option>
                  <option value="Requested" >Requested</option>
                  <option value="Manifested" >Manifested</option>
                  <option value="Cancelled" >Cancelled</option>
                  <option value="Delivered" >Delivered</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <DialogActions>
            <Button onClick={() => handleRequestClose()} color='secondary'>Cancel</Button>
            <Button onClick={() => applyFilter()} color='primary'>Download</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

    </div>
  )
}


// export default OrderHeader;
export default OrderHeader