import React, { Component, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import ContainerHeader from '../../../../components/ContainerHeader';
import ButtonGroup from '@mui/material/ButtonGroup';
import customHead from 'constants/customHead'
import AddStaff from '../component/addStaff';
import AddGroup from '../../groups/addGroup';
import EditStaff from '../component/editStaff';
import DeleteStaff from '../../adminInventory/component/deleteInventory';
import { listStaff } from 'actions/admin';
import { connect } from 'react-redux';
import AppConfig from 'constants/config'
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications';
import AdminContainer from 'containers/AdminContainer';
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'app/routes/components/PaginationCount';
import CircularProgress from '@mui/material/CircularProgress';

const columns = [
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "email",
    label: "Email ID",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "group",
    label: "Group",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  }
];

function StaffList(props) {

  const [state, setState] = useState({
    edit: false,
    permission: false,
    delete: false,
    add: false,
    addGroup: false,
    searchText: '',
    perPage: 50,
    page: 0,
    selectedData: {},
  })

  useEffect(() => {
    props.listStaff({ history: props.history, searchText: state.searchText, page: 1, perPage: 50 })
  }, [])

  const changePage = (page) => {
    let pages = page + 1
    props.listStaff({ userId: '', searchText: state.searchedText, page: pages, perPage: state.perPage })
    setState((prev) => ({ ...prev, page }))
  };

  const changeRowsPerPage = (perPage) => {
    props.listStaff({ page: 1, perPage })
    setState((prev) => ({ ...prev, page: 0, perPage }))
  }

  const handleSearch = (searchText) => {
    props.listStaff({ userId: '', searchText: searchText, page: state.page > 0 ? state.page : 1, perPage: state.perPage })
    setState((prev) => ({ ...prev, searchedText: searchText }))
  };

  const handleClick = (key, value) => {
    setState((prev) => ({ ...prev, [key]: !state[key], selectedData: value }))

  }


  const button = (data) => (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button className={'text-primary'} onClick={() => handleClick('edit', data)} > Edit</Button>
      <Button className={'text-danger'} onClick={() => handleClick('delete', data)}> Delete</Button>
      <Button className={'text-success'} onClick={() => handleReset('reset', data)}> Reset </Button>
    </ButtonGroup>
  )
  const addGroup = () => {

    setState((prev) => ({
      ...prev,
      addGroup: true,
      add: false
    }))
  }
  const handleReset = (key, data) => {
    axios({
      method: 'post',
      url: `${AppConfig.baseUrl}seller/addEditStaffMember`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      },
      data: {
        id: data._id,
        reset: true,
        email: data.email
      }
    }).then((result) => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      } else {
        NotificationManager.success(result.data.title);
      }
    })
  }

  const { staffList } = props;

  let { selectedData } = state;

  const options = {
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: false,
    selectableRows: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [],
    page: state.page,
    fixedHeader: false,
    print: false,
    download: false,
    filter: false,
    sort: false,
    selectableRows: false,
    count: props.staffList.length > 0 ? props.staffList[0].metadata.length > 0 ? props.staffList[0].metadata[0].total : 0 : 0,
    rowsPerPage: state.perPage,
    serverSide: true,
    server: true,
    selectableRowsOnClick: false,
    selectableRows: 'none',
    fixedHeader: false,
    searchText: state.searchedText,
    onTableChange: (action, tableState) => {

      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key == 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          handleSearch(tableState.searchText)
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  var listData = [];
  staffList.length > 0 && staffList[0].data.length > 0 && staffList[0].data.map((data, index) => {
    listData.push([data.first_name, data.email, data.Groups.name, button(data)]);
  });


  return (
    <AdminContainer>
      <React.Fragment>
        <ContainerHeader match={props.match} title={"Staff Members"} />
        {props.loading ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}
        {
          props.loading === true ?
            null :
            <MUIDataTable
              title={<Button className={'text-primary'} selectedData={selectedData} onClick={() => handleClick('add')}> Add Staff Member+</Button>}
              data={listData}
              columns={columns}
              options={options}
            />
        }
        {
          state.edit &&
          <EditStaff selectedData={selectedData} edit={state.edit} title={'Edit Staff Member'} handleClick={handleClick} />
        }
        {
          state.add &&
          <AddStaff addGroup={addGroup} selectedData={selectedData} add={state.add} title={'Add Staff Member'} handleClick={handleClick} />
        }
        {
          state.delete &&
          <DeleteStaff selectedData={selectedData} delete={state.delete} deleteFor={'staff'} handleClick={handleClick} />
        }
        {
          state.addGroup &&
          <AddGroup add_group={state.addGroup} permission={props.permission} title={'Add Group'} handleClick={handleClick} history={props.history} />
        }

      </React.Fragment>
    </AdminContainer>
  );
}

const mapStateToProps = ({ admin }) => {
  const { staffList, loading } = admin;
  return { staffList, loading }
};

StaffList = connect(
  mapStateToProps,
  {
    listStaff
  }               // bind account loading action creator
)(StaffList)

export default StaffList;