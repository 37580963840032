import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import { Col, Row, Label, Input } from 'reactstrap';
import { FormGroup } from '@mui/material';
import customHead from 'constants/customHead'
import { getTransactionList } from 'actions/admin'
import { connect } from "react-redux";
import moment from 'moment';
import helperFunction from 'constants/helperFunction';
import InsideTHeader from './InsideTHeader';
import CircularProgress from '@mui/material/CircularProgress';
import AppConfig from 'constants/config'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomeFilterForDate from "app/routes/components/CustomFilter/customfilter";
import { useNavigate, useParams } from "react-router";
import AdminContainer from "containers/AdminContainer";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

const monthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
let date = new Date();

function Transaction(props) {
  const { id } = useParams()
  const history = useNavigate()
  let url = window.location.search
  let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''

  const [state, setState] = useState({
    page: json && json.pageTD ? Number(json.pageTD) : 0,
    perPage: 50,
    month: json && json.monthTD ? json.monthTD : '',
    year: json && json.yearTD ? Number(json.yearTD) : '',
    searchedText: json && json.searchTD ? json.searchTD : '',
    openFilter: false,
    openFilterCsv: false,
    filterDataB: '',
    openTran: false,
    transDetail: '',
    radioValue: json && json.showTD === 'false' ? false : true,
    from: moment().subtract(1, 'months'),
    to: moment().format()
  })

  const handleTransaction = async () => {
    let buyer_id = id
    window.open(`${AppConfig.baseUrl}transactions/walletReport?token=${localStorage.getItem('admin_token')}&from_date=${state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format()}&to_date=${state.to ? moment(state.to).format('YYYY-MM-DD') : moment().format()}&buyer_id=${buyer_id} `, '_blank');
    setState((prev) => ({
      ...prev,
      openFilter: false,
    }))
  }

  const handleDateChange = (date, key) => {
    setState((prev) => ({
      ...prev, [key]: date
    }))
  }

  const fetchApi = async () => {
    let data = {
      Id: id,
      month: state.month,
      year: state.year,
      page: state.page + 1,
      perPage: state.perPage,
      show: state.radioValue
    }
    props.getTransactionList({ data })
    let years = helperFunction.yearList();
    setState((prev) => ({
      ...prev, yearList: years
    }))
  }

  useEffect(() => {
    fetchApi()
  }, [window.location.search])

  const handleClose = () => {
    setState((prev) => ({
      ...prev, openFilter: false
    }))
  }

  const applyFilter = () => {
    setState((prev) => ({
      ...prev, openFilter: false
    }))
    let obj = { pageTD: 0, searchTD: state.searchedText, monthTD: state.month, yearTD: Number(state.year), showTD: state.radioValue }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/transaction/details/${id}?${url}`)
  }

  const resetFilter = () => {
    setState((prev) => ({
      ...prev, openFilter: false, radioValue: false, month: monthArray[date.getMonth()], year: date.getFullYear()
    }))
    let obj = { pageTD: 0, searchTD: '', monthTD: '', yearTD: '', showTD: 'false' }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/transaction/details/${id}?${url}`)
  }

  const changePage = (page) => {
    setState((prev) => ({
      ...prev, page
    }))
    let obj = { pageTD: page, searchTD: state.searchedText, monthTD: state.month, yearTD: state.year, showTD: state.radioValue }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/transaction/details/${id}?${url}`)
  };

  const handleChange = (e, key) => {
    setState((prev) => ({
      ...prev, [key]: e.target.value
    }));
  }

  const handleSearch = (searchText) => {
    setState((prev) => ({
      ...prev, searchedText: searchText
    }))
    let obj = { pageTD: 0, searchTD: state.searchedText, monthTD: state.month, yearTD: state.year, showTD: state.radioValue }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/transaction/details/${id}?${url}`)
  }

  const handleRequestClose = () => {
    setState((prev) => ({
      ...prev, openTran: false
    }))
    setState((prev) => ({
      ...prev,
      openFilter: false,
      from: moment().format(),
      to: moment().format()
    }));
  }

  const handleRadioChange = (event, key) => {
    setState((prev) => ({
      ...prev, [key]: event.target.checked
    }));
  };

  const { transactionList } = props;
  const { month, year, yearList, radioValue, from, to } = state;

  const statusStyle = (status) => {
    return status.includes("Pending") ? "text-white bg-grey" : status.includes("Success") ? "text-white bg-success" : "text-white bg-grey";
  }

  const columns = [
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "narration",
      label: "Narration",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "running",
      label: "Closing Bal",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        filterType: 'custom',
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        },
        customFilterListRender: v => {
          return false;
        },
        filterOptions: {
          fullWidth: true,
          display: () => (
            <React.Fragment onOpen={state.openFilter} onClose={handleClose} >
              <Row form style={{ maxWidth: 350 }}>
                <Col md={6} xl={6} xs={6} lg={6} sm={6}>
                  <FormGroup>
                    <Label for="exampleSelect">Select Month</Label>
                    <Input type="select" name="month" value={month} onChange={(e) => handleChange(e, 'month')} id="month">
                      <option value=''>Select Month</option>
                      {
                        monthArray.map((value, key) => {
                          return <option value={value} >{value}</option>;
                        })
                      }
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6} xl={6} xs={6} lg={6} sm={6}>
                  <FormGroup>
                    <Label for="exampleSelect">Select Year</Label>
                    <Input type="select" value={year} onChange={(e) => handleChange(e, 'year')} name="year" id="year">
                      <option value=''>Select Year</option>
                      {
                        yearList.map((value, key) => {
                          return <option value={parseInt(value)} >{value}</option>
                        })
                      }
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4} xl={4} xs={4} lg={4} sm={4} style={{ paddingTop: 12 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={radioValue}
                        onChange={(e) => handleRadioChange(e, 'radioValue')}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Show&nbsp;COD"
                  />
                </Col>
                <Col md={8} xl={8} xs={8} lg={8} sm={8}>
                  <div style={{ paddingTop: 12, marginLeft: "15px" }} >
                    <Button variant="contained" onClick={() => {
                      setState((prev) => ({
                        ...prev, openFilter: true
                      }))
                    }} className='filterButton' color='primary'>
                      Download CSV
                    </Button>
                  </div>
                </Col>
                <Dialog open={state.openFilter} onClose={handleRequestClose}>
                  <DialogContent >
                    <Row form >
                      <CustomeFilterForDate handleDateChange={handleDateChange} from={from} to={to} />
                    </Row>
                    <DialogActions>
                      <Button onClick={() => handleRequestClose()} color='secondary'>Cancel</Button>
                      <Button onClick={() => handleTransaction()} color='primary'>Download</Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
              </Row>
            </React.Fragment>
          ),
          onFilterChange: (ee) => {
          }
        },
      }
    }
  ];

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    responsive: 'scrollMaxHeight',
    search: false,
    viewColumns: false,
    rowHover: false,
    rowsPerPageOptions: [],
    server: true,
    serverSide: true,
    page: state.page,
    filter: true,
    searchText: state.searchedText,
    rowsPerPage: 50,
    count: transactionList && transactionList.metadata[0] && transactionList.metadata[0].total,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <div style={{ paddingTop: 15 }} >
            <Button variant="contained" onClick={() => { applyFilter(); applyNewFilters() }} className='filterButton' color='primary'>Apply Filter</Button>
            <Button variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton' color='primary'>Reset Filter</Button>
          </div>
        </div>
      );
    },
    textLabels: {
      filter: {
        all: "All",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  };

  let data1 = [];
  transactionList && transactionList.data.length > 0 && transactionList.data.map((dataOne) => {
    data1.push({
      running: dataOne.closing_bal ? `MNY ${(dataOne.closing_bal).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'MNY 0',
      amount:
        <div>
          <span>MNY {(dataOne.settle_amount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
        </div>,
      narration: dataOne.narration,
      status: <div className={` badge text-uppercase ${statusStyle(dataOne.status === "Success" ? 'Success' : 'Pending')}`}>{dataOne.status}</div>,
      type: <div>{dataOne.type === 'Online' ? 'Payment Gateway' : dataOne.type}</div>,
      date: moment(dataOne.createdAt).format('DD/MM/YY')
    })
  })

  const { loading } = props;
  return (
    <AdminContainer>
      <React.Fragment>
        <div className="app-wrapper">
          <InsideTHeader show={state.radioValue} />
          <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
            {loading ? <div className="loader-view"
              style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
              <CircularProgress />
            </div> : null}
            {
              loading === true ?
                null :
                <MUIDataTable
                  data={data1}
                  columns={columns}
                  options={options}
                />
            }
          </div>
        </div>
      </React.Fragment>
    </AdminContainer>
  );
}

const mapStateToProps = ({ admin }) => {
  const { transactionList, loading } = admin;
  return { transactionList, loading };
}
export default connect(mapStateToProps, { getTransactionList })(Transaction);