import { getAllSettlement } from 'actions/admin';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import customHead from 'constants/customHead';
import ButtonGroup from '@mui/material/ButtonGroup';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router';
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'app/routes/components/PaginationCount';
import CircularProgress from '@mui/material/CircularProgress';

function Settlement(props) {
  const history = useNavigate()
  let date = new Date()
  const [state, setState] = useState({
    data: [],
    page: 0,
    perPage: 50,
    value: 0,
    month: date.getMonth(),
    year: date.getFullYear(),
    searchedText: ''
  })

  useEffect(() => {
    props.getAllSettlement({ page: state.page + 1, perPage: state.perPage }, history)
  }, [])

  const changePage = (page) => {
    let pages = page + 1
    let data = {
      page: pages,
      perPage: state.perPage,
      searchText: state.searchedText,

    }
    props.getAllSettlement({ data })
    setState((prev) => ({ ...prev, page }))

  };

  const getAction = (data) => {
    return <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button variant="outlined" className={'text-primary'} onClick={(e) => buttonA(data)}>Details</Button>
    </ButtonGroup>
  }
  const buttonA = (data) => {
    console.log('data===', data)
    history(`/admin/settlement/settlementDetails/${data.seller_id._id}`)
  }
  function throttleHandleChange(edata) {
    props.getAllSettlement({ data: edata, history: history })
  }
  const handleSearch = debounce((searchText) => {
    setState((prev) => ({ ...prev, searchedText: searchText, page: 0 }))
    let data = {
      page: 1,
      perPage: state.perPage,
      searchText: searchText,
    }
    throttleHandleChange(data)
  }, 500)

  const { allSettlement } = props;

  const columns = [
    {
      name: "Seller",
      label: "Seller",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "TotalEarning",
      label: "Total Earning",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "PendingAmount",
      label: "Pending Amount",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "SettledAmount",
      label: "Settled Amount",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "Actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    }
  ]
  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: false,
    selectableRows: false,
    page: state.page,
    rowsPerPage: 50,
    fixedHeader: false,
    print: false,
    download: false,
    filter: false,
    sort: false,
    serverSide: true,
    count: allSettlement && allSettlement.data[0] && allSettlement.data[0].metadata[0] && allSettlement.data[0].metadata[0].total,
    server: true,
    selectableRowsOnClick: false,
    search: true,
    searchText: state.searchedText,
    rowsPerPageOptions: [],
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }
  let data1 = []
  allSettlement && allSettlement.data[0] && allSettlement.data[0].data.map((dataOne, index) => {

    data1.push({
      Seller: dataOne.seller_id.company_name,
      TotalEarning: <div>₹ {(dataOne.total_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>,
      PendingAmount: <div>₹ {(dataOne.pending_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>,
      SettledAmount: <div>₹ {(dataOne.settled_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>,
      Actions: getAction(dataOne)
    })
  })

  return (
    <React.Fragment>
      {props.loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}
      {
        props.loading === true ?
          null :
          <MUIDataTable
            title=''
            data={data1}
            columns={columns}
            options={options}
          />
      }
    </React.Fragment>
  );
}

const mapStateToProps = ({ admin }) => {
  const { allSettlement, loading } = admin;
  return { allSettlement, loading }
};

export default connect(mapStateToProps, { getAllSettlement })(Settlement);