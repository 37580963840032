import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { changeTab } from '../../../../../../actions/tabAction';
import { connect } from 'react-redux';
import All from '../../../../orders/routes/AllOrders';
import OrderList from '../../../../orders/routes/OrderList';
import OrderHistory from '../../../../orders/routes/OrderHistory';
import StaleOrder from '../../../../orders/routes/StaleOrders';
import { useNavigate } from 'react-router';

function TabContainer({ children, dir }) {
  return (
    <div dir={dir}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

function FullWidthTabs(props) {
  const history = useNavigate()

  const handleChange = (event, value) => {
    props.changeTab(value)
    history(`/admin/orders`)
  };


  const { theme, tabValue, tabFor } = props;
  return (
    <div className="w-100" >
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
        >
          <Tab className="tab" label="Open" />
          <Tab className="tab" label="Stale" />
          <Tab className="tab" label="History" />
          <Tab className="tab" label="All" />
        </Tabs>
      </AppBar>

      {tabValue == 0 && <TabContainer >
        {
          tabFor === 'order' ? <OrderList tab={tabValue} history={props.history} location={props.location} /> : ''
        }
      </TabContainer>}
      {tabValue == 1 && <TabContainer >
        {
          tabFor === 'order' ? <StaleOrder tab={tabValue} location={props.location} /> : ''
        }
      </TabContainer>}
      {tabValue == 2 && <TabContainer >
        {
          tabFor === 'order' ? <OrderHistory tab={tabValue} location={props.location} /> : ''
        }
      </TabContainer>}
      {tabValue == 3 && <TabContainer>
        {
          tabFor === 'order' ? <All tab={tabValue} location={props.location} /> : ''
        }
      </TabContainer>}
    </div>
  );
}


FullWidthTabs.propTypes = {
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = ({ tabAction }) => {
  const { tabValue } = tabAction;
  return { tabValue }
};

export default connect(mapStateToProps, { changeTab })(FullWidthTabs);