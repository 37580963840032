import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogContentText from '@mui/material/DialogContentText';

class AddGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      permission: []
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      permission: nextProps.permission
    })
  }

  handleRequestClose = (e) => {
    e.preventDefault();
    this.props.buttonType === 'editGroup' ? this.props.handleClick('editGroup') : this.props.handleClick('addGroup')
  };

  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  }

  handleCheck = (e, index) => {
    e.preventDefault();
    let tempPermission = this.state.permission;
    tempPermission[index].isSelected = !tempPermission[index].isSelected;
    this.setState({ permission: tempPermission })
  }

  render() {
    const { name, permission } = this.state;
    const { buttonType, editGroup, addGroup, title } = this.props;
    return (
      <React.Fragment>
        <Dialog open={buttonType === 'editGroup' ? editGroup : addGroup} onClose={(e) => this.handleRequestClose(e)}
          fullWidth={true}
          maxWidth={'sm'}>
          <DialogTitle>
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              To subscribe to this website, please enter your email address here. We will send
              updates occationally.
            </DialogContentText>
            <form noValidate autoComplete="off">
              <Row>
                <Col >
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input type="text" name="name" id="name" value={name} onChange={(e) => this.handleChange(e, 'name')} placeholder="Name" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                  <Row>
                    {
                      (permission !== undefined && permission.length > 0) ? permission.map((value, key) => {
                       
                        return <Col xs={6} md={6} sm={6} xl={6} lg={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value.isSelected}
                                onChange={(e) => this.handleCheck(e, key)}
                                value={value.value}
                                color="primary"
                              />
                            }
                            label={value.name}
                          />
                        </Col>
                      }) : ''
                    }
                  </Row>
                </Col>
              </Row>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => this.handleRequestClose(e)} color='secondary' >
              Cancel
          </Button>
            {
              buttonType === 'edit' ? <Button onClick={(e) => this.handleRequestClose(e)} color='primary'>
                Edit
           </Button> : <Button onClick={(e) => this.handleRequestClose(e)} color='primary'>
                  Add
          </Button>
            }

          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default AddGroup;