import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import ContainerHeader from '../../../../components/ContainerHeader';
import IntlMessages from '../../../../util/IntlMessages';
import ButtonGroup from '@mui/material/ButtonGroup';
import customHead from 'constants/customHead'
import { getMetaData, getAddProductType, getDeleteProductType } from 'actions/admin';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormGroup } from 'reactstrap';
import { Col, Row } from 'reactstrap';
import CircularProgress from '@mui/material/CircularProgress';
import { Field, Form } from 'react-final-form';
import { required } from 'constants/validations';
import ReactstrapTextField from '../../components/ReactStrapTextField';
import AdminContainer from 'containers/AdminContainer';
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'app/routes/components/PaginationCount';


const columns = [

  {
    name: "title",
    label: "Title",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  }
];

const options = {
  filterType: 'dropdown',
  print: false,
  download: false,
  selectableRows: false,
  selectableRowsOnClick: false,
  selectableRowsHeader: false,
  responsive: 'scroll',
  search: true,
  viewColumns: false,
  rowHover: false,
  filter: false,
  rowsPerPage: 50,
  rowsPerPageOptions: [],
  customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
    return <div className="p-4 d-flex justify-content-end">
      <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
      <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
    </div>
  }
};

function StaffList(props) {

  const [state, setState] = useState({
    edit: false,
    permission: false,
    delete: false,
    add: false,
    name: '',
    loader: false,
    delId: ''
  })

  const handleClick = (key) => {
    setState((prev) => ({ ...prev, [key]: !state[key] }))
  }

  function componentdidMount() {
    props.getMetaData({});
  }
  useEffect(() => {
    componentdidMount()
  }, [])
  const handleAdd = (value) => {
    props.getAddProductType({ name: value.name ? value.name : '', });
    setState((prev) => ({
      ...prev,
      add: false,
      loader: true,
    }))
    const t = setTimeout(() => {
      setState((prev) => ({ ...prev, loader: false }))
    }, 3000);
  }
  const handleRequestClose = () => {
    setState((prev) => ({
      ...prev,
      add: false,
      delete: false,
      name: ''
    }))
  }
  const handleDelete = (e) => {
    setState((prev) => ({
      ...prev,
      delete: true,
      delId: e
    }))
  }
  const handleDel = () => {
    props.getDeleteProductType({ id: state.delId._id })
    setState((prev) => ({
      ...prev,
      delete: false,
    }))
  }

  const { metaData } = props
  let data = [];
  {
    metaData && metaData.detail && metaData.detail.types && metaData.detail.types.map((dataOne) => {
      data.push({
        title: dataOne.name, noOfProducts: "10", action:
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button className={'text-danger'} onClick={(e) => handleDelete(dataOne)}> Disable</Button>
          </ButtonGroup>
      })
    })
  }

  const { loader } = state;
  return (
    <AdminContainer>
      <React.Fragment>
        <ContainerHeader match={props.match} title={"Product Types"} />
        {loader ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}
        {
          loader == true ?
            null :
            <MUIDataTable
              title={<Button className={'text-primary'} onClick={() => handleClick('add')}> Add Product Type+</Button>}
              data={data}
              columns={columns}
              options={options}
            />
        }
        <Dialog open={state.delete} onClose={handleRequestClose} fullWidth={true}
          maxWidth={'sm'}>
          <DialogTitle>
            Delete Product Type
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {'Are you sure you want to delete this product type ?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color='secondary' >
              Cancel
            </Button>
            <Button color='primary' onClick={handleDel} >
              Disable
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog open={state.add}
          onClose={handleRequestClose}
          fullWidth={true}
          maxWidth={'sm'}>
          <DialogTitle>
            Add Product Type
          </DialogTitle>
          <Form
            onSubmit={handleAdd}
            initialValues={{
              name: '',
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <DialogContent>
                  <Row>
                    <Col xs={12} md={5}>
                      <FormGroup>
                        <Field type="text" name="name" id="name"
                          label={'Name'} component={ReactstrapTextField} validate={required} />
                      </FormGroup>
                    </Col>
                  </Row>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRequestClose} color='secondary' >
                    Cancel
                  </Button>
                  <Button type='submit' color='primary'>
                    {state.add ? 'ADD' : ''}
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </Dialog>

      </React.Fragment>
    </AdminContainer>
  );
}

const mapStateToProps = ({ admin }) => {
  const { metaData, addProductType, deleteProductType } = admin;
  return { metaData, addProductType, deleteProductType }
};

export default connect(mapStateToProps, { getMetaData, getAddProductType, getDeleteProductType })(StaffList); 
