import React, { useEffect, useState } from 'react';

import moment from 'moment'
import { connect } from 'react-redux';
import { getListGroupSettlement } from 'actions/admin'
import AppConfig from 'constants/config'
import MUIDataTable from "mui-datatables"
import Button from '@mui/material/Button';
import customHead from 'constants/customHead'
import { Row, } from 'reactstrap';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import CustomeFilterForDate from 'app/routes/components/CustomFilter/customfilter';
import { useParams } from 'react-router';
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'app/routes/components/PaginationCount';


function GroupSettlements(props) {
  const { id } = useParams()
  let date = new Date()
  const [state, setState] = useState({
    detailCards: '',
    month: date.getMonth(),
    year: date.getFullYear(),
    tempMonth: date.getMonth(),
    tempYear: date.getFullYear(),
    from: moment().subtract(30, 'day').format(''),
    to: moment().format(''),
    page: 0,
    perPage: 50,
    from_date: '',
    to_date: ''
  })

  const fetchApi = () => {
    let data = { page: 1, perPage: 50, sellerId: id, from_date: moment().subtract(30, 'day').format(''), to_date: moment().format('') }
    props.getListGroupSettlement({ data })
  }
  useEffect(() => {
    fetchApi()
  }, [])

  const handleDateChange = (date, key) => {
    setState((prev) => ({ ...prev, [key]: date }))
    console.log("selectedDate", key);
  }
  const applyFilter = (filter) => {
    let data = {
      page: 1,
      perPage: state.perPage,
      sellerId: id,
      searchText: state.searchedText ? state.searchedText : '',
      from_date: state.from ? state.from : moment(),
      to_date: state.to ? state.to : moment()

    }
    props.getListGroupSettlement({ data, history: props.history });
    setState((prev) => ({
      ...prev,
      page: 0
    }))
  }
  const resetFilter = (filter) => {
    let data = {
      page: 1,
      perPage: 50,
      sellerId: id,
      from_date: moment().subtract(30, 'day').format(''),
      to_date: moment().format('')
    }
    props.getListGroupSettlement({ data, history: props.history })
    setState((prev) => ({
      ...prev,
      filter: '',
      status: '',
      showFilter: false,
      page: 0,
      from: moment().subtract(30, 'day').format(''),
      to: moment().format('')
    }))
  }
  const changePage = (page) => {
    let pages = page + 1;
    let data = {
      page: pages,
      perPage: state.perPage,
      sellerId: id,
      from_date: state.from ? state.from : moment(),
      to_date: state.to ? state.to : moment()
    }
    props.getListGroupSettlement({ data, history: props.history })
    setState((prev) => ({ ...prev, page }))
  };
  const handleSearch = (searchText) => {
    let data = {
      page: 1,
      perPage: state.perPage,
      searchText: searchText,
      from_date: state.from ? state.from : moment(),
      to_date: state.to ? state.to : moment()
    }

    props.getListGroupSettlement({ data, history: props.history })
    setState((prev) => ({ ...prev, searchedText: searchText, page: 0 }))
  };
  const report = (data) => {
    return <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button variant="outlined" className={!data.isPublished ? 'text-warning' : 'text-danger'} onClick={(e) => send(data)}>
        {!data.isPublished ? 'Publish' : 'Conceal'}
      </Button>
      <Button variant="outlined" className={'text-primary'} onClick={(e) => redirect(data)}>Report</Button>
    </ButtonGroup>
  }
  const send = async (data) => {
    await axios({
      method: 'get',
      url: `${AppConfig.baseUrl}groupSettlements/sendReportEmail?Id=${data._id}&email=${data.seller.email}`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title)
        let data = { sellerId: id, from_date: state.from ? state.from : moment(), to_date: state.to ? state.to : moment() }
        props.getListGroupSettlement({ data })
      }
    }).catch(error => {
      console.log("error catch", error)
    });
  }
  const redirect = (data) => {
    console.log('opoeess', data)
    window.open(`${AppConfig.baseUrl}groupSettlements/generateRemittance?Id=${data._id}`, '_blank');
  }
  const { from, to } = state
  const { listGroupSettlement } = props
  const options = {
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: false,
    selectableRows: false,
    page: state.page,
    rowsPerPage: state.perPage,
    fixedHeader: false,
    print: false,
    download: false,
    filter: true,
    sort: false,
    selectableRows: false,
    serverSide: true,
    count: listGroupSettlement && listGroupSettlement.data[0] && listGroupSettlement.data[0].metadata[0] && listGroupSettlement.data[0].metadata[0].total ? listGroupSettlement.data[0].metadata[0].total : 0,
    server: true,
    selectableRowsOnClick: false,
    selectableRows: 'none',
    fixedHeader: false,
    search: false,
    rowsPerPageOptions: [],
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <div style={{ paddingTop: 15 }} >
            <Button variant="contained" onClick={() => { applyFilter(); applyNewFilters() }} className='filterButton' color='primary'>Apply Filter</Button>
            <Button variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton' color='primary'>Reset Filter</Button>
          </div>
        </div>
      );
    },
    onTableChange: (action, tableState) => {
      console.log('action=', action, tableState)
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key == 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          handleSearch(tableState.searchText)
          break;

      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }
  const columns = [
    {
      name: "utr",
      label: "UTR",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "orderValue",
      label: "Order Value",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "netAmt",
      label: "Net Amt",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "paymentDate",
      label: "Payment Date",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "report",
      label: "Report",
      options: {
        filter: true,
        filterType: 'custom',
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        },
        customFilterListRender: v => {
          return false;
        },
        filterOptions: {
          fullWidth: true,
          display: () => (
            <React.Fragment>
              <Row form style={{ maxWidth: 350 }}>
                <CustomeFilterForDate handleDateChange={handleDateChange} from={from} to={to} />
              </Row>
            </React.Fragment>
          ),
          onFilterChange: () => {
            console.log('onFilterChange')
          }
        },
      },
    },
  ];
  let data1 = []
  listGroupSettlement && listGroupSettlement.data[0] && listGroupSettlement.data[0].data.map((dataOne, index) => {

    data1.push({
      utr: dataOne.utrNo ? dataOne.utrNo : 'N/A',
      orderValue: <div>₹{(dataOne.order_value).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</div>,
      netAmt: <div>₹{(dataOne.net_amt).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</div>,
      paymentDate: moment(dataOne.payment_date).format('DD/MM/YYYY'),
      report: report(dataOne)
    })
  })

  return (
    <div className="dashboard animated slideInUpTiny animation-duration-3">
      {props.loading == false ?
        <MUIDataTable
          title=''
          data={data1}
          columns={columns}
          options={options}
        /> :
        <div className="loader-view" style={{ textAlign: "center", marginTop: "150px" }}>
          <CircularProgress />
        </div>
      } </div>
  );
}
const mapStateToProps = ({ admin }) => {
  const { listGroupSettlement, loading } = admin

  return { listGroupSettlement, loading }
}
export default connect(mapStateToProps, { getListGroupSettlement })(GroupSettlements)
