import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import SidenavContent from './SidenavContent';
import AdminInfo from 'components/AdminInfo';
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from 'actions/Setting';

const SideNav = (props) => {
  const { navCollapsed, drawerType, width, navigationStyle, toggleCollapsedNav, updateWindowWidth,sidebar } = props;

  const [drawerStyle, setDrawerStyle] = useState(drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex');
  const [type, setType] = useState('permanent');

  const onToggleCollapsedNav = () => {
    const val = !navCollapsed;
    toggleCollapsedNav(val);
  };

  useEffect(() => {
    const handleResize = () => {
      updateWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateWindowWidth]);

  useEffect(() => {
    let newDrawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
    let newType = 'permanent';

    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
      newType = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      newDrawerStyle = '';
      newType = 'temporary';
    }

    setDrawerStyle(newDrawerStyle);
    setType(newType);
  }, [drawerType, width, navigationStyle]);

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer className="app-sidebar-content"
        variant={type}
        open={type.includes('temporary') ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: 'side-nav',
        }}
      >
        <AdminInfo  />
        <SidenavContent sidebar={sidebar} />
        <div className='medilogo-container'>
          <img src={require("assets/images/medimny.png")} className='medilogo' alt="Medimny" title="Medimny" />
        </div>
      </Drawer>
    </div>
  );
};

const mapStateToProps = ({ settings, admin }) => {
  const { navCollapsed, drawerType, width, navigationStyle } = settings;
  const { sidebar } = admin;
  return { navCollapsed, drawerType, width, navigationStyle, sidebar }
};

export default connect(mapStateToProps, { toggleCollapsedNav, updateWindowWidth })(SideNav);
