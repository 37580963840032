import React from 'react';
import moment from 'moment'
import Avatar from '@mui/material/Avatar';
import helper from 'constants/helperFunction';

const WithIconTimeLineItem = ({ styleName, color, timeLine, children }) => {
    const { date, action, name } = timeLine;
    let newName = helper.textCapitalize(name);
    return (
        <div className="media social-list-line">
            <Avatar className={`${color} z-index-20 size-40 align-item-self mr-3`}>
                {children}
            </Avatar>
            <div className="media-body">
                <h5 className="mb-1"
                    style={{
                        color: color === 'bg-primary' ? '#361e86' :
                            color === 'bg-danger' ? '#dc3545' :
                                color === 'bg-info' ? '#17a2b8' :
                                    color === 'bg-warning' ? '#ffc107' :
                                        color === 'ng-success' ? '#28a745' : ''
                    }}
                >
                    {
                        action === 'Seller close' ? `Seller closed` :
                            action === 'Seller open' ? `Seller opened` :
                                action === 'Buyer close' ? `Buyer closed` :
                                    action === 'Buyer open' ? `Buyer opened` :
                                        action === 'RTO close' ? `RTO closed` :
                                            action === 'RTO open' ? `RTO opened` : action
                    }
                </h5>
                <p className="mb-0">
                    {
                        action === 'Ticket created' ? `Ticket has been created by ${newName}.` :
                            action === 'Ticket updated' ? `Ticket updated by ${newName}.` :
                                action === 'Ticket closed' ? `Ticket closed by ${newName}.` :
                                    action === 'Seller close' ? `${newName} closed Seller status.` :
                                        action === 'Seller open' ? `${newName} opened Seller status.` :
                                            action === 'Buyer close' ? `${newName} closed Buyer status.` :
                                                action === 'Buyer open' ? `${newName} opened Buyer status.` :
                                                    action === 'RTO close' ? `${newName} closed RTO status.` :
                                                        action === 'RTO open' ? `${newName} opened RTO status.` : ''
                    }
                </p>
                <span className="meta-date meta-date-light">{moment(date).format('DD MMM, YYYY h:mm a')}</span>
            </div>
        </div>
    )
};

export default WithIconTimeLineItem;