import React from 'react';
import { Breadcrumb } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';


const ContainerHeader = ({ title, match, getStats }) => {
  const path = 'location.pathname'
  const subPath = path.split('/');
  function getStatss() {
    getStats()
  }
  return (
    <div className="page-heading d-flex justify-content-between align-items-center">
      <h2 className="title mb-3 mb-sm-0">{title}</h2>

      <Breadcrumb className="mb-0" tag="nav">
        {subPath.map((sub, index) => {
          if (sub === 'dashboard')
            return <NavLink to="/seller/ratings">

            </NavLink>
        }
        )}
      </Breadcrumb>
      <Button variant="outlined" className={'text-primary'} onClick={() => getStatss()} >
        refresh
      </Button>

    </div>
  )
};

export default ContainerHeader;

