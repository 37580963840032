import React from "react";
import FullWidthTabsForBulk from '../components/routes/tabs/fixed/FullWidthTabsForBulk';
import AdminContainer from "containers/AdminContainer";

const BulkTab = (props) => {
  return (
    <AdminContainer>
      <div className="page-heading buyerDetailsHeader d-sm-flex justify-content-sm-between align-items-sm-center">
        <h2 className="title mb-3 mb-sm-0">Bulk Requests </h2>
      </div>
      <FullWidthTabsForBulk tabFor={'bulkRequests'} />
    </AdminContainer>
  );
}

export default BulkTab;