import React, { useRef } from 'react'
import FormHelperText from '@mui/material/FormHelperText'

const renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
        return
    } else {
        return <FormHelperText className='text-center' style={{ color: '#f44336' }}>{touched && error}</FormHelperText>
    }
}

const FieldFileInput = (props) => {
    let drugLic20B = useRef()

    const getBase64 = (file, newBase64, onChange) => {
        new Promise((resolve, reject) => {
            let reader = new FileReader();
            resolve(
                reader.readAsDataURL(file),
                reader.onload = () => {
                    newBase64.push(reader.result)
                    onChange(newBase64)
                }
            )
        })
    }


    const handleFileSelect = async (e) => {
        const { input: { onChange } } = props
        let document = [];
        for (let i = 0; i < e.target.files.length; i++) {
            getBase64(e.target.files[i], document, onChange)
        }
    }

    const { input: { value }, label, meta: { touched, error } } = props

    return (
        <div className='text-center'>
            <div>
                <input
                    multiple
                    type='file'
                    accept='.jpg, .png, .jpeg,.webp'
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileSelect(e)} ref={(ref) => drugLic20B = ref}
                />
                <img alt="camera" src={value !== '' ? require('../../../../assets/img/camera_3.png') : require('../../../../assets/img/camera_1.png')} className='d-block mx-auto' onClick={(e) => drugLic20B.click()} />
            </div>
            <label >{label}</label>
            {renderFromHelper({ touched, error })}
        </div>
    )
}

export default FieldFileInput