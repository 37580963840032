import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS
} from 'constants/ActionTypes';

export function getNotification(notification) {
  return {
      type: GET_NOTIFICATIONS,
      payload: notification
  }
}

export function getNotificationSuccess(notification) {
  return {
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: notification
  }
}