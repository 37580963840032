import {

  API_FAILED,

  GET_ORDER_LIST,
  GET_ORDER_LIST_SUCCESS,

  GET_INVENTORY,
  GET_INVENTORY_SUCCESS,

  GET_INVENTORY_POPUP,
  GET_INVENTORY_POPUP_SUCCESS,

  GET_ORDER_HISTORY,
  GET_ORDER_HISTORY_SUCCESS,

  GET_BUYER_SELLER_LIST,
  GET_BUYER_SELLER_LIST_SUCCESS,

  GET_BUYER_SELLER_LIST_ADMIN,
  GET_BUYER_SELLER_LIST_SUCCESS_ADMIN,

  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,

  GET_USER_APPROVE,
  GET_USER_APPROVE_SUCCESS,

  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,

  GET_META_DATA,
  GET_META_DATA_SUCCESS,

  // -----STAFF/GROUPD-----

  ADD_GROUP,
  ADD_GROUP_SUCCESS,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  LIST_GROUP,
  LIST_GROUP_SUCCESS,
  LIST_PERMISSION_MODULES,
  LIST_PERMISSION_MODULES_SUCCESS,
  ADD_STAFF,
  ADD_STAFF_SUCCESS,
  DELETE_STAFF,
  DELETE_STAFF_SUCCESS,
  LIST_STAFF,
  LIST_STAFF_SUCCESS,

  //PRODUCT-REQUEST

  GET_PRODUCT_REQUEST_LIST,
  GET_PRODUCT_REQUEST_LIST_SUCCESS,
  GET_APPROVE_PRODUCT_REQUEST_LIST,
  GET_APPROVE_PRODUCT_REQUEST_LIST_SUCCESS,

  GET_PROMOTIONS_LIST,
  GET_PROMOTIONS_LIST_SUCCESS,
  GET_SCHEDULE_NOTIFICATION_LIST,
  GET_SCHEDULE_NOTIFICATION_LIST_SUCCESS,
  GET_LOW_STOCK_SELLERWISE_LIST,
  GET_LOW_STOCK_SELLERWISE_LIST_SUCCESS,
  GET_LOW_STOCK_OVERALL_LIST,
  GET_LOW_STOCK_OVERALL_LIST_SUCCESS,

  GET_ADD_PROMOTIONS_LIST,
  GET_ADD_PROMOTIONS_LIST_SUCCESS,
  GET_EDIT_PROMOTIONS_LIST,
  GET_EDIT_PROMOTIONS_LIST_SUCCESS,
  GET_DELETE_PROMOTIONS_LIST,
  GET_DELETE_PROMOTIONS_LIST_SUCCESS,

  GET_CHANGE_USER_STATUS,
  GET_CHANGE_USER_STATUS_SUCCESS,

  // -------ADD/DEL GST---------------

  GET_ADD_GST,
  GET_ADD_GST_SUCCESS,
  GET_DELETE_GST,
  GET_DELETE_GST_SUCCESS,

  // -------------ADD/DEL PRODUCT TYPE---------------

  GET_ADD_PRODUCT_TYPE,
  GET_ADD_PRODUCT_TYPE_SUCCESS,
  GET_DELETE_PRODUCT_TYPE,
  GET_DELETE_PRODUCT_TYPE_SUCCESS,

  // ----------ADD/DEL PRODUCT CATEGORY-----------------

 GET_ADD_PRODUCT_CATEGORY,
 GET_ADD_PRODUCT_CATEGORY_SUCCESS ,
 GET_DELETE_PRODUCT_CATEGORY,
 GET_DELETE_PRODUCT_CATEGORY_SUCCESS,

// -----------------ADD/DEL COMPANY---------------

 GET_ADD_COMPANY,
 GET_ADD_COMPANY_SUCCESS ,
 GET_DELETE_COMPANY,
 GET_DELETE_COMPANY_SUCCESS,

 // -------------ADD PRODUCT-----------------

 GET_DELETE_PRODUCT,
 GET_DELETE_PRODUCT_SUCCESS,

 GET_DASHBOARD_CARD,
 GET_DASHBOARD_CARD_SUCCESS,

 ADMIN_DASHBOARD_STATS,
 ADMIN_DASHBOARD_STATS_SUCCESS,

  // ------------- SELLER SALES STATS-----------------
  ADMIN_SALES_STATS,
  ADMIN_SALES_STATS_SUCCESS,
  
  ADMIN_MONTHLY_SALES_STATS,
  ADMIN_MONTHLY_SALES_STATS_SUCCESS,

  GET_SALES_STATISTICS,
  GET_SALES_STATISTICS_SUCCESS,

  GET_TOP_FIVE_LEAST_FIVE,
  GET_TOP_FIVE_LEAST_FIVE_SUCCESS,

  // ------------SETTLEMENS-------------------

  GET_SETTLEMENTS,
  GET_SETTLEMENTS_SUCCESS,

  // --------ADMIN SIDEBAR------------------------

  GET_SIDEBAR,
  GET_SIDEBAR_SUCCESS,

  GET_COD_ON_OFF,
  GET_COD_ON_OFF_SUCCESS,

  // --------------------------

  GET_GLOBAL_COD ,
  GET_GLOBAL_COD_SUCCESS,

  GET_CHANGE_GLOBAL_COD ,
  GET_CHANGE_GLOBAL_COD_SUCCESS,

  // -------------------------

  GET_CHANGE_PROMOTION,
  GET_CHANGE_PROMOTION_SUCCESS,

  GET_CHANGE_DEAL_OF_THE_DAY,
  GET_CHANGE_DEAL_OF_THE_DAY_SUCCESS,

  GET_VERIFY_PHONE,
  GET_VERIFY_PHONE_SUCCESS,

  // -=-=-=-=================

  GET_ALL_SETTLEMENT,
  GET_ALL_SETTLEMENT_SUCCESS,

  GET_SETTLEMENT_LIST,
  GET_SETTLEMENT_LIST_SUCCESS,

  GET_PENDING_SETTLEMENT,
  GET_PENDING_SETTLEMENT_SUCCESS,

  GET_ALL_ORDER_LIST,
  GET_ALL_ORDER_LIST_SUCCESS,

  GET_ALL_MANIFEST_LIST,
  GET_ALL_MANIFEST_SUCCESS,

  GET_GROUP_SETTLEMENT,
  GET_GROUP_SETTLEMENT_SUCCESS,

  GET_LIST_GROUP_SETTLEMENT,
  GET_LIST_GROUP_SETTLEMENT_SUCCESS,

  GET_DELIVERY_ON_OFF,
  GET_DELIVERY_ON_OFF_SUCCESS,

  GET_TRANSACTION_LIST,
  GET_TRANSACTION_LIST_SUCCESS,

  GET_BUYER_TRANSACTION_LIST,
  GET_BUYER_TRANSACTION_LIST_SUCCESS,

  GET_PROMOTIONS_LISTING,
  GET_PROMOTIONS_LISTING_SUCCESS,

  GET_JUMBO_DEAL,
  GET_JUMBO_DEAL_SUCCESS,

  GET_FAST_MOVING,
  GET_FAST_MOVING_SUCCESS,

  GET_BNPL_ON_OFF,
  GET_BNPL_ON_OFF_SUCCESS,

  GET_TICKET_LIST,
  GET_TICKET_LIST_SUCCESS,

  GET_TICKET_HISTORY_LIST,
  GET_TICKET_HISTORY_LIST_SUCCESS,

  GET_ADD_TICKET,
  GET_ADD_TICKET_SUCCESS,

  GET_BULK_LIST,
  GET_BULK_LIST_SUCCESS,

  GET_BULK_ON_OFF,
  GET_BULK_ON_OFF_SUCCESS,


  GET_PREPAIDORDER_LIST,
  GET_PREPAIDORDER_LIST_SUCCESS,
  GET_MANIFEST_VERIFIED_LIST,
  GET_MANIFEST_VERIFIED_LIST_SUCCESS,

  GET_MANIFEST_DISCREPANCY_LIST,
  GET_MANIFEST_DISCREPANCY_LIST_SUCCESS



} from 'constants/ActionTypes';


export const apiFailed = (error) => {
  return {
    type: API_FAILED,
    payload: error
  };
};


export function getOrderList(data) {
  return {
    type: GET_ORDER_LIST,
    payload: data
  }
}

export function getOrderListSuccess(data) {
  return {
    type: GET_ORDER_LIST_SUCCESS,
    payload: data
  }
}

export function getOrderHistory(getOrderHistory) {
  return {
    type: GET_ORDER_HISTORY,
    payload: getOrderHistory
  }
}

export function getOrderHistorySuccess(getOrderHistory) {
  return {
    type: GET_ORDER_HISTORY_SUCCESS,
    payload: getOrderHistory
  }
}

export const getInventoryList = (data) => {
  return {
    type: GET_INVENTORY,
    payload: data
  };
};

export const getInventoryListSuccess = (data) => {
  return {
    type: GET_INVENTORY_SUCCESS,
    payload: data
  };
};

export const getInventoryListPopUp = (data) => {
  return {
    type: GET_INVENTORY_POPUP,
    payload: data
  };
};

export const getInventoryListPopUpSuccess = (data) => {
  return {
    type: GET_INVENTORY_POPUP_SUCCESS,
    payload: data
  };
};


export const getBuyerSellerList = (data) => {
  return {
    type: GET_BUYER_SELLER_LIST,
    payload: data
  };
};

export const getBuyerSellerListAdmin = (data) => {
  return {
    type: GET_BUYER_SELLER_LIST_ADMIN,
    payload: data
  };
};

export function getOrderDetails(orderDetails) {
  return {
    type: GET_ORDER_DETAILS,
    payload: orderDetails
  }
}

export function getOrderDetailsSuccess(orderDetails) {
  return {
    type: GET_ORDER_DETAILS_SUCCESS,
    payload: orderDetails
  }
}

export const getBuyerSellerListSuccess = (data) => {
  return {
    type: GET_BUYER_SELLER_LIST_SUCCESS,
    payload: data
  };
};

export const getBuyerSellerListSuccessAdmin = (data) => {
  return {
    type: GET_BUYER_SELLER_LIST_SUCCESS_ADMIN,
    payload: data
  };
};

export function getUserApprove(data) {
  return {
    type: GET_USER_APPROVE,
    payload: data
  }
}

export function getUserApproveSuccess(data) {
  return {
    type: GET_USER_APPROVE_SUCCESS,
    payload: data
  }
}

export function getProductList(data) {
  return {
    type: GET_PRODUCT_LIST,
    payload: data
  }
}

export function getProductListSuccess(data) {
  return {
    type: GET_PRODUCT_LIST_SUCCESS,
    payload: data
  }
}

export function getMetaData(data) {
  return {
    type: GET_META_DATA,
    payload: data
  }
}

export function getMetaDataSuccess(data) {
  return {
    type: GET_META_DATA_SUCCESS,
    payload: data
  }
}

// -------STAFF/GROUPS-----

export const addGroup = (data) => {
  return {
    type: ADD_GROUP,
    payload: data
  };
};

export const addGroupSuccess = (data) => {
  return {
    type: ADD_GROUP_SUCCESS,
    payload: data
  };
};

export const deleteGroup = (data) => {
  return {
    type: DELETE_GROUP,
    payload: data
  };
};

export const deleteGroupSuccess = (data) => {
  return {
    type: DELETE_GROUP_SUCCESS,
    payload: data
  };
};


export const listGroup = (data) => {
  return {
    type: LIST_GROUP,
    payload: data
  };
};

export const listGroupSuccess = (data) => {
  return {
    type: LIST_GROUP_SUCCESS,
    payload: data
  };
};

export const listPermissionModules = (data) => {
  return {
    type: LIST_PERMISSION_MODULES,
    payload: data
  };
};

export const listPermissionModulesSuccess = (data) => {
  return {
    type: LIST_PERMISSION_MODULES_SUCCESS,
    payload: data
  };
};

// export const getSidebar = (data) => {
//   return {
//     type: GET_SIDEBAR,
//     payload: data
//   };
// };

// export const getSidebarSuccess = (data) => {
//   return {
//     type: GET_SIDEBAR_SUCCESS,
//     payload: data
//   };
// };

// export const getSettlement = (data) => {
//   return {
//     type: GET_SETTLEMENT,
//     payload: data
//   };
// };

// export const getSettlementSuccess = (data) => {
//   return {
//     type: GET_SETTLEMENT_SUCCESS,
//     payload: data
//   };
// };


export const addStaff = (data) => {
  return {
    type: ADD_STAFF,
    payload: data
  };
};

export const addStaffSuccess = (data) => {
  return {
    type: ADD_STAFF_SUCCESS,
    payload: data
  };
};

export const deleteStaff = (data) => {
  return {
    type: DELETE_STAFF,
    payload: data
  };
};

export const deleteStaffSuccess = (data) => {
  return {
    type: DELETE_STAFF_SUCCESS,
    payload: data
  };
};


export const listStaff = (data) => {
  return {
    type: LIST_STAFF,
    payload: data
  };
};

export const listStaffSuccess = (data) => {
  return {
    type: LIST_STAFF_SUCCESS,
    payload: data
  };
};


export const getProductRequestList = (data) => {
  return {
    type: GET_PRODUCT_REQUEST_LIST,
    payload: data
  };
};

export const getProductRequestListSuccess = (data) => {
  return {
    type: GET_PRODUCT_REQUEST_LIST_SUCCESS,
    payload: data
  };
};

export const getApproveProductRequestList = (data) => {
  return {
    type: GET_APPROVE_PRODUCT_REQUEST_LIST,
    payload: data
  };
};

export const getApproveProductRequestListSuccess = (data) => {
  return {
    type: GET_APPROVE_PRODUCT_REQUEST_LIST_SUCCESS,
    payload: data
  };
};

export const getPromotionsList = (data) => {
  return {
    type: GET_PROMOTIONS_LIST,
    payload: data
  };
};

export const getPromotionsListSuccess = (data) => {
  return {
    type: GET_PROMOTIONS_LIST_SUCCESS,
    payload: data
  };
};

export const getScheduleNotificationsList = (data) => {
  return {
    type: GET_SCHEDULE_NOTIFICATION_LIST,
    payload: data
  };
};

export const getScheduleNotificationsListSuccess = (data) => {
  return {
    type: GET_SCHEDULE_NOTIFICATION_LIST_SUCCESS,
    payload: data
  };
};


export const getlowStockSellerwise = (data) => {
  return {
    type: GET_LOW_STOCK_SELLERWISE_LIST,
    payload: data
  };
};

export const getlowStockSellerwiseSuccess = (data) => {
  return {
    type: GET_LOW_STOCK_SELLERWISE_LIST_SUCCESS,
    payload: data
  };
};

export const getlowStockOverAll = (data) => {
  return {
    type: GET_LOW_STOCK_OVERALL_LIST,
    payload: data
  };
};

export const getlowStockOverAllSuccess = (data) => {
  return {
    type: GET_LOW_STOCK_OVERALL_LIST_SUCCESS,
    payload: data
  };
};



export const getAddPromotionsList = (data) => {
  return {
    type: GET_ADD_PROMOTIONS_LIST,
    payload: data
  };
};

export const getAddPromotionsListSuccess = (data) => {
  return {
    type: GET_ADD_PROMOTIONS_LIST_SUCCESS,
    payload: data
  };
};

export const getEditPromotionsList = (data) => {
  return {
    type: GET_EDIT_PROMOTIONS_LIST,
    payload: data
  };
};

export const getEditPromotionsListSuccess = (data) => {
  return {
    type: GET_EDIT_PROMOTIONS_LIST_SUCCESS,
    payload: data
  };
};

export const getDeletePromotionsList = (data) => {
  return {
    type: GET_DELETE_PROMOTIONS_LIST,
    payload: data
  };
};

export const getDeletePromotionsListSuccess = (data) => {
  return {
    type: GET_DELETE_PROMOTIONS_LIST_SUCCESS,
    payload: data
  };
};

export const getChangeUserStatus = (data) => {
  return {
    type: GET_CHANGE_USER_STATUS,
    payload: data
  };
};

export const getChangeUserStatusSuccess = (data) => {
  return {
    type: GET_CHANGE_USER_STATUS_SUCCESS,
    payload: data
  };
};

export const getAddGst = (data) => {
  return {
    type: GET_ADD_GST,
    payload: data
  };
};

export const getAddGstSuccess = (data) => {
  return {
    type: GET_ADD_GST_SUCCESS,
    payload: data
  };
};

export const getDeleteGst = (data) => {
  return {
    type: GET_DELETE_GST,
    payload: data
  };
};

export const getDeleteGstSuccess = (data) => {
  return {
    type: GET_DELETE_GST_SUCCESS,
    payload: data
  };
};

export const getAddProductType = (data) => {
  return {
    type: GET_ADD_PRODUCT_TYPE,
    payload: data
  };
};

export const getAddProductTypeSuccess = (data) => {
  return {
    type: GET_ADD_PRODUCT_TYPE_SUCCESS,
    payload: data
  };
};

export const getDeleteProductType = (data) => {
  return {
    type: GET_DELETE_PRODUCT_TYPE,
    payload: data
  };
};

export const getDeleteProductTypeSuccess = (data) => {
  return {
    type: GET_DELETE_PRODUCT_TYPE_SUCCESS,
    payload: data
  };
};

export const getAddProductCategory = (data) => {
  return {
    type: GET_ADD_PRODUCT_CATEGORY,
    payload: data
  };
};

export const getAddProductCategorySuccess = (data) => {
  return {
    type: GET_ADD_PRODUCT_CATEGORY_SUCCESS,
    payload: data
  };
};

export const getDeleteProductCategory = (data) => {
  return {
    type: GET_DELETE_PRODUCT_CATEGORY,
    payload: data
  };
};

export const getDeleteProductCategorySuccess = (data) => {
  return {
    type: GET_DELETE_PRODUCT_CATEGORY_SUCCESS,
    payload: data
  };
};

export const getAddCompany = (data) => {
  return {
    type: GET_ADD_COMPANY,
    payload: data
  };
};

export const getAddCompanySuccess = (data) => {
  return {
    type: GET_ADD_COMPANY_SUCCESS,
    payload: data
  };
};

export const getDeleteCompany = (data) => {
  return {
    type: GET_DELETE_COMPANY,
    payload: data
  };
};

export const getDeleteCompanySuccess = (data) => {
  return {
    type: GET_DELETE_COMPANY_SUCCESS,
    payload: data
  };
};

export const getDeleteProduct = (data) => {
  return {
    type: GET_DELETE_PRODUCT,
    payload: data
  };
};

export const getDeleteProductSuccess = (data) => {
  return {
    type: GET_DELETE_PRODUCT_SUCCESS,
    payload: data
  };
};

export const getDashboardCard = (data) => {
  return {
    type: GET_DASHBOARD_CARD,
    payload: data
  };
};

export const getDashboardCardSuccess = (data) => {
  return {
    type: GET_DASHBOARD_CARD_SUCCESS,
    payload: data
  };
};

export const getAdminDashboardStats = (data) => {
  return {
    type: ADMIN_DASHBOARD_STATS,
    payload: data
  };
};
export const getAdminsalesStats = (data) => {
  return {
    type: ADMIN_SALES_STATS,
    payload: data
  };
};
export const getAdminSalesStatsSuccess = (data) => {
  return {
    type: ADMIN_SALES_STATS_SUCCESS,
    payload: data
  };
};


export const getAdminmonthlySalesStats = (data) => {
  return {
    type: ADMIN_MONTHLY_SALES_STATS,
    payload: data
  };
};
export const getAdminmonthlySalesStatsSuccess = (data) => {
  
  return {
    type: ADMIN_MONTHLY_SALES_STATS_SUCCESS,
    payload: data
  };
};



export const getAdminDashboardStatsSuccess = (data) => {
  return {
    type: ADMIN_DASHBOARD_STATS_SUCCESS,
    payload: data
  };
};

export const getSalesStatistics = (data) => {
  return {
    type: GET_SALES_STATISTICS,
    payload: data
  };
};

export const getSalesStatisticsSuccess = (data) => {
  return {
    type: GET_SALES_STATISTICS_SUCCESS,
    payload: data
  };
};

export const getTopFiveLeastFive = (data) => {
  return {
    type: GET_TOP_FIVE_LEAST_FIVE,
    payload: data
  };
};

export const getTopFiveLeastFiveSuccess = (data) => {
  return {
    type: GET_TOP_FIVE_LEAST_FIVE_SUCCESS,
    payload: data
  };
};

export const getSettlements = (data) => {
  return {
    type: GET_SETTLEMENTS,
    payload: data
  };
};

export const getSettlementsSuccess = (data) => {
  return {
    type: GET_SETTLEMENTS_SUCCESS,
    payload: data
  };
};

export const getSidebar = (data) => {
  return {
    type: GET_SIDEBAR,
    payload: data
  };
};

export const getSidebarSuccess = (data) => {
  return {
    type: GET_SIDEBAR_SUCCESS,
    payload: data
  };
};

export const getCodOnOff = (data) => {
  return {
    type: GET_COD_ON_OFF,
    payload: data
  };
};

export const getCodOnOffSuccess = (data) => {
  return {
    type: GET_COD_ON_OFF_SUCCESS,
    payload: data
  };
};

export const getGlobalCod = (data) => {
  return {
    type: GET_GLOBAL_COD,
    payload: data
  };
};

export const getGlobalCodSuccess = (data) => {
  return {
    type: GET_GLOBAL_COD_SUCCESS,
    payload: data
  };
};

export const getChangeGlobalCod = (data) => {
  return {
    type: GET_CHANGE_GLOBAL_COD,
    payload: data
  };
};

export const getChangeGlobalCodSuccess = (data) => {
  return {
    type: GET_CHANGE_GLOBAL_COD_SUCCESS,
    payload: data
  };
};

export const getChangePromotion = (data) => {
  return {
    type: GET_CHANGE_PROMOTION,
    payload: data
  };
};

export const getChangePromotionSuccess = (data) => {
  return {
    type: GET_CHANGE_PROMOTION_SUCCESS,
    payload: data
  };
};

export const getChangeDealOfTheDay = (data) => {
  return {
    type: GET_CHANGE_DEAL_OF_THE_DAY,
    payload: data
  };
};

export const getChangeDealOfTheDaySuccess = (data) => {
  return {
    type: GET_CHANGE_DEAL_OF_THE_DAY_SUCCESS,
    payload: data
  };
};

export const getChangeJumboDeal = (data) => {
  return {
    type: GET_JUMBO_DEAL,
    payload: data
  };
};

export const getChangeJumboDealSuccess = (data) => {
  return {
    type: GET_JUMBO_DEAL_SUCCESS,
    payload: data
  };
};

export const getChangeFastMoving = (data) => {
  return {
    type: GET_FAST_MOVING,
    payload: data
  };
};

export const getChangeFastMovingSuccess = (data) => {
  return {
    type: GET_FAST_MOVING_SUCCESS,
    payload: data
  };
};

export const getVerifyPhone = (data) => {
  return {
    type: GET_VERIFY_PHONE,
    payload: data
  };
};

export const getVerifyPhoneSuccess = (data) => {
  return {
    type: GET_VERIFY_PHONE_SUCCESS,
    payload: data
  };
};

export const getAllSettlement = (data) => {
  return {
    type: GET_ALL_SETTLEMENT,
    payload: data
  };
};

export const getAllSettlementSuccess = (data) => {
  return {
    type: GET_ALL_SETTLEMENT_SUCCESS,
    payload: data
  };
};

export const getSettlementList = (data) => {
  return {
    type: GET_SETTLEMENT_LIST,
    payload: data
  };
};

export const getSettlementListSuccess = (data) => {
  return {
    type: GET_SETTLEMENT_LIST_SUCCESS,
    payload: data
  };
};

export const getPendingSettlement = (data) => {
  return {
    type: GET_PENDING_SETTLEMENT,
    payload: data
  };
};

export const getPendingSettlementSuccess = (data) => {
  return {
    type: GET_PENDING_SETTLEMENT_SUCCESS,
    payload: data
  };
};

export function getAllOrderList(data) {
  return {
    type: GET_ALL_ORDER_LIST,
    payload: data
  }
}

export function getAllOrderListSuccess(data) {
  return {
    type: GET_ALL_ORDER_LIST_SUCCESS,
    payload: data
  }
}


export function getAllManifestList(data) {
  return {
    type: GET_ALL_MANIFEST_LIST,
    payload: data
  }
}

export function getAllManifestListSuccess(data) {
  return {
    type: GET_ALL_MANIFEST_SUCCESS,
    payload: data
  }
}


export function getGroupSettlement(data) {
  return {
    type: GET_GROUP_SETTLEMENT,
    payload: data
  }
}

export function getGroupSettlementSuccess(data) {
  return {
    type: GET_GROUP_SETTLEMENT_SUCCESS,
    payload: data
  }
}

export function getListGroupSettlement(data) {
  return {
    type: GET_LIST_GROUP_SETTLEMENT,
    payload: data
  }
}

export function getListGroupSettlementSuccess(data) {
  return {
    type: GET_LIST_GROUP_SETTLEMENT_SUCCESS,
    payload: data
  }
}

export const getDeliveryOnOff = (data) => {
  return {
    type: GET_DELIVERY_ON_OFF,
    payload: data
  };
};

export const getDeliveryOnOffSuccess = (data) => {
  return {
    type: GET_DELIVERY_ON_OFF_SUCCESS,
    payload: data
  };
};

export const getTransactionList = (data) => {
  return {
    type: GET_TRANSACTION_LIST,
    payload: data
  };
};

export const getTransactionListSuccess = (data) => {
  return {
    type: GET_TRANSACTION_LIST_SUCCESS,
    payload: data
  };
};

export const getBuyerTransactionList = (data) => {
  return {
    type: GET_BUYER_TRANSACTION_LIST,
    payload: data
  };
};

export const getBuyerTransactionListSuccess = (data) => {
  return {
    type: GET_BUYER_TRANSACTION_LIST_SUCCESS,
    payload: data
  };
};

export const getPromotionsListing = (data) =>{
  return {
      type: GET_PROMOTIONS_LISTING,
      payload: data
  };
};

export const getPromotionsListingSuccess = (data) => {
  return {
      type: GET_PROMOTIONS_LISTING_SUCCESS,
      payload: data
  };
};

export const getBnplOnOff = (data) => {
  return {
    type: GET_BNPL_ON_OFF,
    payload: data
  };
};

export const getBnplOnOffSuccess = (data) => {
  return {
    type: GET_BNPL_ON_OFF_SUCCESS,
    payload: data
  };
};

export const getTicketList = (data) =>{
  return {
      type: GET_TICKET_LIST,
      payload: data
  };
};

export const getTicketListSuccess = (data) => {
  return {
      type: GET_TICKET_LIST_SUCCESS,
      payload: data
  };
};

 export const getTicketHistoryList = (data) =>{
  return {
      type: GET_TICKET_HISTORY_LIST,
      payload: data
  };
};

export const getTicketHistoryListSuccess = (data) => {
  return {
      type: GET_TICKET_HISTORY_LIST_SUCCESS,
      payload: data
  };
}; 

export const getAddTicket = (data) =>{
  return {
      type: GET_ADD_TICKET,
      payload: data
  };
};

export const getAddTicketSuccess = (data) => {
  return {
      type: GET_ADD_TICKET_SUCCESS,
      payload: data
  };
};

// --------------------------------- //

export const getBulkList = (data) =>{
  return {
      type: GET_BULK_LIST,
      payload: data
  };
};

export const getBulkListSuccess = (data) => {
  return {
      type: GET_BULK_LIST_SUCCESS,
      payload: data
  };
};

export const getBulkOnOff = (data) => {
  return {
    type: GET_BULK_ON_OFF,
    payload: data
  };
};

export const getBulkOnOffSuccess = (data) => {
  return {
    type: GET_BULK_ON_OFF_SUCCESS,
    payload: data
  };
};

export const getPrepaidOrder = (data) => {
  return {
    type: GET_PREPAIDORDER_LIST,
    payload: data
  };
};
export const getPrepaidOrderSuccess = (data) => {
  return {
    type: GET_PREPAIDORDER_LIST_SUCCESS,
    payload: data
  };
};
export function getVarifiedManifestList(data) {
  return {
    type: GET_MANIFEST_VERIFIED_LIST,
    payload: data
  }
}

export function getVarifiedManifestListSuccess(data) {
  return {
    type: GET_MANIFEST_VERIFIED_LIST_SUCCESS,
    payload: data
  }
}


export function getDiscrepancyManifestList(data) {
  return {
    type: GET_MANIFEST_DISCREPANCY_LIST,
    payload: data
  }
}

export function getDiscrepancyManifestListSuccess(data) {
  return {
    type: GET_MANIFEST_DISCREPANCY_LIST_SUCCESS,
    payload: data
  }
}



