import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { changeTab } from '../../../../../../actions/tabAction';
import { connect } from 'react-redux';
import BuyerRequestList from '../../../../productRequests/routes/buyerRequestList';
import ProductRequestList from '../../../../productRequests/routes/productRequestList';


function TabContainer({ children, dir }) {
  return (
    <div dir={dir}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

function FullWidthTabsForProductRequest(props) {

  const handleChange = (event, value) => {
    props.changeTab(value)
    props.history(`/admin/productRequests`)
  };

  useEffect(() => {
    if (props.tabValue > 1) {
      props.changeTab(0)
    }
  }, [])

  const { tabValue, tabFor } = props;
  return (
    <div className="w-100" >
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
        >
          <Tab className="tab" label="Buyer Requests" />
          <Tab className="tab" label="Seller Requests" />
        </Tabs>
      </AppBar>

      {tabValue === 0 && <TabContainer
      >
        {
          tabFor === 'productRequest' ? <BuyerRequestList tab={tabValue} history={props.history} location={props.location} /> : ''
        }
      </TabContainer>}
      {tabValue === 1 && <TabContainer >
        {
          tabFor === 'productRequest' ? <ProductRequestList tab={tabValue} history={props.history} location={props.location} /> : ''
        }
      </TabContainer>}
    </div>
  );
}

FullWidthTabsForProductRequest.propTypes = {
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = ({ tabAction }) => {
  const { tabValue } = tabAction;
  return { tabValue }
};

export default connect(mapStateToProps, { changeTab })(FullWidthTabsForProductRequest);