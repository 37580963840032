import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import customHead from 'constants/customHead'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import axios from '../../../../constants/axios';
import { NotificationManager } from 'react-notifications';
import { Autocomplete } from "@mui/material";
import { TextField } from '@mui/material';
import { Col, Row, Label } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

function BuyerProductRequest(props) {

  const [state, setState] = useState({
    loader: false,
    page: 0,
    perPage: 50,
    buyerProductRequestList: [],
    buyer: '',
    buyerFilters: [],
    count: 0,
    popOverOpen: false,
    requestData: {},
    message: '',
    from: dayjs().subtract(1, 'months'),
    to: dayjs(),
    error: false,
    searchText: ''
  })

  const resetFilter = async () => {
    await setState((prev) => ({
      ...prev,
      buyer: '',
      page: 0,
      perPage: 50,
      from: dayjs().subtract(1, 'months'),
      to: dayjs(),
    }));
    let data = {
      buyer: '',
      page: 1,
      perPage: 50,
      from_date: dayjs().subtract(1, 'months').format(),
      to_date: dayjs().format(),
      searchText: state.searchText
    }
    getBuyerProductRequest(data);
  }

  const applyFilter = async () => {
    setState((prev) => ({
      ...prev,
      page: 0,
      perPage: 50
    }));
    let data = {
      buyer: state.buyer,
      page: 1,
      perPage: 50,
      from_date: dayjs(state.from).format(),
      to_date: dayjs(state.to).format(),
      searchText: state.searchText
    }
    getBuyerProductRequest(data);
  }

  const handleBuyerChange = (e, buyer) => {
    if (buyer && buyer._id) {
      setState((prev) => ({
        ...prev, buyer: buyer._id
      }))
    }
    else {
      setState((prev) => ({
        ...prev, buyer: ''
      }))
    }
  }

  const getBuyerProductRequest = async (data) => {
    setState((prev) => ({
      ...prev, loader: true
    }))
    let token = localStorage.getItem('admin_token');
    if (props.tab === 0) {
      await axios({
        method: 'POST',
        url: 'buyerRequest/listBuyerRequest',
        data: data,
        headers: {
          'Content-Type': 'application/json',
          'token': token
        }
      }).then(result => {
        if (result.data.error) {
          console.log("error", result.data)
        } else {
          setState((prev) => ({
            ...prev,
            buyerProductRequestList: result.data.data[0].data,
            count: result.data.data[0].metadata[0] && result.data.data[0].metadata[0].total ? result.data.data[0].metadata[0].total : 0,
            loader: false
          }))
        }
      })
        .catch(error => {
          console.log("error catch", error)
          setState((prev) => ({
            ...prev, buyerProductRequestList: [], loader: false
          }))
        });
    }
  }

  const fetchApi = async () => {
    let data = {
      buyer: state.buyer,
      page: 1,
      perPage: 50,
      from_date: dayjs(state.from).format(),
      to_date: dayjs(state.to).format(),
      searchText: state.searchText
    }
    getBuyerProductRequest(data);
    await axios.post('/shortBook/getShortbookFilters', {}, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }
    ).then(result => {
      if (result.data.error) {
        NotificationManager.error('Something went wrong, Please try again');
      } else {
        setState((prev) => ({
          ...prev, buyerFilters: result.data.buyers
        }))
      }
    })
      .catch(error => {
        NotificationManager.error('Something went wrong, Please try again')
      });
  }
  useEffect(() => {
    fetchApi()
  }, [])
  const handleEdit = async (data, key) => {
    let status = ''
    if (key === 'APPROVE') {
      status = 'APPROVED'
    }
    else if (key === 'DENY') {
      status = 'DENIED'
    }
    else {
      status = 'PENDING'
    }
    let token = localStorage.getItem('admin_token');
    let changedData = {
      id: data._id,
      status: status,
    }
    await axios({
      method: 'POST',
      url: 'buyerRequest/updateBuyerRequest',
      data: changedData,
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title)
        let data = {
          buyer: state.buyer,
          page: state.page + 1,
          perPage: 50,
          from_date: dayjs(state.from).format(),
          to_date: dayjs(state.to).format(),
          searchText: state.searchText
        }
        getBuyerProductRequest(data);
      }
    })
      .catch(error => {
        NotificationManager.error('Something Went Wrong !')
      });
  }

  const changePage = async (page) => {
    setState((prev) => ({
      ...prev, page
    }))
    let data = {
      buyer: state.buyer,
      page: page + 1,
      perPage: 50,
      from_date: dayjs(state.from).format(),
      to_date: dayjs(state.to).format(),
      searchText: state.searchText
    }
    getBuyerProductRequest(data);
  };

  const handleChange = (e, key) => {
    setState((prev) => ({
      ...prev, [key]: e.target.value
    }));
  }

  const handleNotify = (data) => {
    setState((prev) => ({
      ...prev,
      popOverOpen: true,
      requestData: data,
      message: `${data.product_name} is back in stock on Medimny. You can order now !`
    }))
  }

  const onCloseNotify = () => {
    setState((prev) => ({
      ...prev, popOverOpen: false, requestData: {}
    }))
  }

  const handleConfirmNotify = () => {
    if (state.message === '' || state.message === undefined) {
      setState((prev) => ({
        ...prev, error: true
      }))
    }
    else {
      let data = {
        buyer_id: state.requestData.user_id._id,
        message: state.message
      }
      axios.post('buyerRequest/notifyUser', data, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('admin_token')
        }
      }).then(result => {
        if (result.data.error) {
          onCloseNotify()
          NotificationManager.error(result.data.title);
        } else {
          onCloseNotify()
          NotificationManager.success(result.data.title);
        }
      })
        .catch(error => {
          onCloseNotify()
          NotificationManager.error('Something went wrong, Please try again')
        });
    }
  }

  const handleDateChange = (date, key) => {
    setState((prev) => ({
      ...prev, [key]: dayjs(date)
    }))
  }

  const handleSearch = async (searchText) => {
    setState((prev) => ({
      ...prev, searchText: searchText, page: 0
    }))
    let data = {
      buyer: state.buyer,
      page: 1,
      perPage: 50,
      from_date: dayjs(state.from).format(),
      to_date: dayjs(state.to).format(),
      searchText: searchText
    }
    getBuyerProductRequest(data)
  };


  const columns = [
    {
      name: "buyer",
      label: "Buyer Name",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "product",
      label: "Product Name",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "company",
      label: "Company",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },

    {
      name: "quantity",
      label: "Quantity",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "requested",
      label: "Request Date",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: true,
        filterType: 'custom',
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        },
        customFilterListRender: v => {
          return false;
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, handleCustomChange, index, column) => (
            <React.Fragment >
              <Row style={{ maxWidth: 350 }} form>
                <Col md={6} xl={6} xs={12} sm={12} lg={6}>
                  <FormControl className="w-100 mb-2 ">
                    <Label for="fromDate">FROM</Label>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                    >
                      <DatePicker
                        slotProps={{ textField: { variant: 'standard' } }}
                        onChange={(date) => handleDateChange(date, 'from')}
                        name='from'
                        id="from"
                        value={dayjs(state.from)}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Col>
                <Col md={6} xl={6} xs={12} sm={12} lg={6}>
                  <FormControl >
                    <Label for="toDate">TO</Label>

                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                    >
                      <DatePicker
                        slotProps={{ textField: { variant: 'standard' } }}
                        onChange={(date) => handleDateChange(date, 'to')}
                        name='to'
                        id="to"
                        value={dayjs(state.to)}
                        fullWidth
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>

                  </FormControl>
                </Col>
              </Row>
              <Row form>
                <Col md={12} xl={12} xs={12} sm={12} lg={12}>
                  <FormControl className="w-100 mb-2 ">
                    <Label for="exampleSelect">Buyers</Label>
                    <Autocomplete
                      options={['Select Buyer', ...state.buyerFilters]}
                      id="auto-complete"
                      defaultValue={state.buyer && state.buyer !== '' && state.buyer !== 'Select Buyer' ? state.buyerFilters.find(v => v._id === state.buyer) : 'Select Buyer'}
                      getOptionLabel={(option) => { return option && option === 'Select Buyer' ? 'Select Buyer' : option ? option.company_name : 'Select Buyer' }}
                      autoComplete
                      onChange={(e, option) => handleBuyerChange(e, option)}
                      includeInputInList
                      renderInput={(params) => <TextField {...params}
                        name="buyer"
                        fullWidth={true} />}
                    />
                  </FormControl>
                </Col>
              </Row>
              <div style={{ paddingTop: 15 }} >
                <Button variant="contained" onClick={() => applyFilter()} className='filterButton' color='primary'>Apply Filter</Button>
                <Button variant="contained" onClick={() => resetFilter()} className='filterButton' color='primary'>Reset Filter</Button>
              </div>
            </React.Fragment>
          )
        }
      }
    }
  ];

  const { buyerProductRequestList } = state;

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    filter: true,
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    responsive: 'scrollMaxHeight',
    search: true,
    viewColumns: false,
    rowHover: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [],
    count: state.count,
    serverSide: true,
    server: true,
    searchText: state.searchText,
    page: state.page,
    rangePage: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  };

  let data = [];

  buyerProductRequestList && buyerProductRequestList.length > 0 && buyerProductRequestList.map((dataOne, index) => {

    let compName = dataOne && dataOne.user_id && dataOne.user_id.company_name
    let disabled = dataOne && dataOne.status === 'PENDING' ? false : true
    let status = dataOne && dataOne.isFulfilled === true ? <div className="text-success">FULFILLED</div> : dataOne.status === 'APPROVED' ? <div className="text-warning">{dataOne.status}</div>
      : dataOne.status === 'DENIED' ? <div className="text-danger">{dataOne.status}</div>
        : dataOne.status === 'CANCELLED' ? <div className="text-grey">{dataOne.status}</div>
          : <div className="text-primary">{dataOne.status}</div>
    let manufacturer = dataOne.manufacturer && dataOne.manufacturer.name ? dataOne.manufacturer.name : dataOne.otherManufacturer
    data.push([
      compName,
      dataOne.product_name,
      manufacturer,
      dataOne.quantity,
      <div style={{ margin: 0, padding: 0 }}>
        <span>{dayjs(dataOne.createdAt).format('DD/MM/YY')}</span>
        <p style={{ margin: 0, padding: 0 }}>{dayjs(dataOne.createdAt).format('HH:mm A')}</p>
      </div>,
      status,
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button className={'text-warning'} onClick={(e) => handleNotify(dataOne)}>MSG</Button>
        <Button className={disabled ? 'text-grey' : 'text-success'} disabled={disabled} onClick={(e) => handleEdit(dataOne, 'APPROVE')}>Approve</Button>
        <Button className={disabled ? 'text-grey' : 'text-danger'} disabled={disabled} onClick={(e) => handleEdit(dataOne, 'DENY')}>Deny</Button>
      </ButtonGroup>
    ])
  })

  const { loader } = state;
  return (
    <React.Fragment>
      {loader ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div>
        :
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
        />
      }

      <Dialog open={state.popOverOpen} onClose={onCloseNotify} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>
          Send Notification
        </DialogTitle>
        <DialogContent>
          <form>
            <Row>
              <Col xs={12} md={12} sm={12} xl={12} lg={12} style={{ paddingBottom: '15px' }}>
                <TextField
                  label="Message"
                  rows={5} required={true}
                  multiline={true} fullWidth={true}
                  error={state.error} helperText={state.error ? "Message is required" : ''}
                  style={{ color: "primary" }}
                  value={state.message} variant="outlined"
                  name="message"
                  onChange={(e) => handleChange(e, 'message')}
                />
              </Col>
            </Row>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseNotify} color='secondary' >
            Cancel
          </Button>
          <Button onClick={handleConfirmNotify} color='primary'>
            Notify
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


export default (BuyerProductRequest);