import React, { Component } from 'react';

class ProductTable extends Component {

  render() {
    const { value } = this.props;
    console.log("data........", value)
    return (
      <div className="table-responsive-material">
        <table className="default-table table-unbordered table table-sm table-hover">
          <thead className="th-border-b">
            <tr>
              <th>Name</th>
              {/* <th>Sold by</th> */}
              <th>Quantity</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {value && value.map(data => {
              console.log("qqqqqqqqqqqqqqqq",data)
              return (
                <tr
                  tabIndex={-1}
                
                >
                  <td>
                    <div className=" d-flex flex-row align-items-center">
                      <div className="user-detail">
                        <h5 className="user-name">{data.name} </h5>
                      </div>
                    </div>
                  </td>
                  <td>{data.qty}</td>
                  <td>&#x20B9;{((data.value)).toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ProductTable;