import React, { Component, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import ContainerHeader from '../../../../components/ContainerHeader';
import ButtonGroup from '@mui/material/ButtonGroup';
import customHead from 'constants/customHead'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMetaData, getAddGst, getDeleteGst } from 'actions/admin';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import AdminContainer from 'containers/AdminContainer';
import { FormGroup } from 'reactstrap';
import { Col, Row } from 'reactstrap';
import { required } from 'constants/validations';
import ReactstrapTextField from '../../components/ReactStrapTextField';
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'app/routes/components/PaginationCount';

const columns = [
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection, 'center');
      },
    }
  },
  {
    name: "value",
    label: "Value",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection,) => {
        return customHead(o, updateDirection, 'center');
      }
    }
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection, 'center');
      }
    }
  }
];

const options = {
  filterType: 'dropdown',
  print: false,
  download: false,
  selectableRows: false,
  selectableRowsOnClick: false,
  selectableRowsHeader: false,
  responsive: 'scroll',
  search: true,
  viewColumns: false,
  rowHover: false,
  filter: false,
  rowsPerPage: 50,
  rowsPerPageOptions: [],
  customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
    return <div className="p-4 d-flex justify-content-end">
      <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
      <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
    </div>
  }
};

function StaffList(props) {

  const [state, setState] = useState({
    delete: false,
    add: false,
    loader: false,
    delId: ''
  })

  const handleClick = (key) => {
    setState((prev) => ({ ...prev, [key]: !state[key] }))
  }

  useEffect(() => {
    props.getMetaData({});
  }, [])

  const handleAdd = (values) => {
    props.getAddGst({
      name: values.name ? values.name : '',
      value: values.value ? Number(values.value) : Number(''),
    });
    setState({
      ...state,
      add: false,
      loader: true,
    })
    const t = setTimeout(() => {
      setState((prev) => ({ ...prev, loader: false }))
    }, 3000);
  }
  const handleRequestClose = () => {
    setState({
      ...state,
      add: false,
      delete: false,
    })
  }
  const handleDelete = (e) => {
    setState({
      ...state,
      delete: true,
      delId: e
    })

  }
  const handleDel = () => {
    props.getDeleteGst({ id: state.delId._id })
    setState((prev) => ({
      ...prev,
      delete: false,
    }))
  }
  const { metaData, addGst, deleteGst, handleSubmit } = props;
  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: 'center',
          },
          head: {
            textAlign: 'center !important',
            width: '50%',
            fontSize: '0.875rem',

          },
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            textAlign: 'center !important',
            paddingBottom: 18,
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
          },
          head: {
            textAlign: 'center',
            width: '50%',
            fontSize: '0.875rem',

          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 400,
          }
        }
      }
    }

  });

  let data = [];
  {
    metaData && metaData.detail && metaData.detail.GST.map((dataOne) => {
      data.push({
        name: dataOne.name, value: dataOne.value, action:
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button className={'text-danger'} onClick={(e) => handleDelete(dataOne)}> Delete</Button>
          </ButtonGroup>
      })
    })
  }

  const { loader } = state;
  return (
    <AdminContainer>
      <React.Fragment>
        <ContainerHeader match={props.match} title={"GST Values"} />
        <ThemeProvider theme={theme}>
          {loader ? <div className="loader-view"
            style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
            <CircularProgress />
          </div> : null}
          {
            loader == true ?
              null :
              <MUIDataTable
                title={<Button className={'text-primary'} onClick={() => handleClick('add')}> Add GST Value +</Button>}
                data={data}
                columns={columns}
                options={options}
              />
          }
        </ThemeProvider>
        <Dialog open={state.delete} onClose={handleRequestClose} fullWidth={true}
          maxWidth={'sm'}>
          <DialogTitle>
            Delete GST value
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {'Are you sure yo want to delete GST value ?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color='secondary' >
              Cancel
            </Button>
            <Button color='primary' onClick={handleDel} >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={state.add}
          onClose={handleRequestClose}
          fullWidth={true}
          maxWidth={'sm'}>
          <DialogTitle>
            Add GST
          </DialogTitle>
          <Form
            onSubmit={handleAdd}
            initialValues={{ name: '', value: '' }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <DialogContent>
                  <Row>
                    <Col xs={12} md={12} sm={5} xl={5} lg={5}>
                      <FormGroup>
                        <Field type="text" name="name" id="name"
                          label={'Name'} component={ReactstrapTextField} validate={required} />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={12} sm={5} xl={5} lg={5}>
                      <FormGroup>
                        <Field type="text" name="value" id="value"
                          label={'Value'} component={ReactstrapTextField} validate={required} />
                      </FormGroup>
                    </Col>
                  </Row>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRequestClose} color='secondary' >
                    Cancel
                  </Button>
                  <Button type='submit' color='primary'>
                    {state.add ? 'ADD' : ''}
                  </Button>
                </DialogActions>
              </form>
            )}
          />

        </Dialog>
      </React.Fragment>
    </AdminContainer>
  );
}

const mapStateToProps = ({ admin }) => {
  const { metaData, getAddGst, deleteGst } = admin;
  return { metaData, getAddGst, deleteGst }
};

export default connect(mapStateToProps, { getMetaData, getAddGst, getDeleteGst })(StaffList);