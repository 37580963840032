import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS
} from "constants/ActionTypes";

import {
    getNotification,
    getNotificationSuccess
} from "actions/notification";

import { showAuthMessage } from "actions/Auth";

import Axios from './axiosRequest'

function* getNotificationFunction({ payload }) {
  const { history } = payload
  try {
    const getNotificationResponse = yield call(Axios.axiosRequest, 'post', 'users/getNotification', '', payload);

    if (getNotificationResponse.data.error) {
      yield put(showAuthMessage(getNotificationResponse.data.title));

    } else {
      yield put(getNotificationSuccess(getNotificationResponse.data))
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
export function* getNotificationDispatcher() {
  yield takeLatest(GET_NOTIFICATIONS, getNotificationFunction);
}


export default function* rootSaga() {
    yield all([
      fork(getNotificationDispatcher),
    ]);
  }