import React from 'react';

const ProductTable = (props) => {
  const { value } = props;

  return (
    <div className="table-responsive-material">
      <table className="default-table table-unbordered table table-sm table-hover">
        <thead className="th-border-b">
          <tr>
            <th>Name</th>
            <th>Quantity</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {value && value.map(data => {
            return (
              <tr tabIndex={-1}>
                <td>
                  <div className=" d-flex flex-row align-items-center">
                    <div className="user-detail">
                      <h5 className="user-name">{data.name} </h5>
                    </div>
                  </div>
                </td>
                <td>{data.qty}</td>
                <td>&#x20B9;{data.value ? ((data.value)).toFixed(2) : 0}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ProductTable;