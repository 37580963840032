import React, { Component, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogContentText from '@mui/material/DialogContentText';
import { listPermissionModules, addGroup } from 'actions/admin';
import { connect } from 'react-redux';
import ReactStrapTextField from '../../../components/ReactStrapTextField';
import { Field, Form} from 'react-final-form'
import { required } from 'constants/validations';
import { NotificationManager } from 'react-notifications';

var error = false
function AddGroup(props) {

	const [state, setState] = useState({
		name: '',
		permission: [],
		admin_access: false
	})

	useEffect(() => {
		props.listPermissionModules({ history: props.history })
	}, [])

	const handleRequestClose = (e) => {
		e.preventDefault();
		error = false;
		props.handleClick('addGroup')
	};

	const onSubmit = (values) => {
		let data = {
			permissions: state.permission,
			admin_access: state.admin_access,
			name: values.name
		}

		if (data.permissions.length > 0) {
			props.addGroup({ history: props.history, data, listGroup: { searchText: '', page: 1, perPage: 10 } });
			props.handleClick('addGroup')
			setState((prev)=>({...prev, permission: [], admin_access: false, name: '' }));
		} else {
			error = true
			NotificationManager.error("Please select at least one permission.")
		}
	}


	const handleChange = (e, key) => {
		setState((prev)=>({...prev,[key]: e.target.value }));
	}

	const handleAdminCheck = (e) => {
		e.preventDefault();
		if (state.admin_access === false) {
			setState((prev)=>({...prev, permission: props.listpermissionModulesdata, admin_access: true }));
		} else {
			setState((prev)=>({...prev, permission: [], admin_access: false }));
		}
	}

	const handleCheck = (e, index) => {
		e.preventDefault();
		if (!state.admin_access) {
			let tempPermission = [...state.permission];
			let permissionIndex = tempPermission.findIndex((e) => e._id == props.listpermissionModulesdata[index]._id);

			if (permissionIndex > -1) {
				tempPermission.splice(permissionIndex, 1);
				setState((prev)=>({...prev, permission: tempPermission }));
			} else {
				tempPermission.push(props.listpermissionModulesdata[index]);
				setState((prev)=>({...prev, permission: tempPermission }));
			}

			if (tempPermission.length === props.listpermissionModulesdata.length) {
				setState((prev)=>({...prev, permission: tempPermission, admin_access: true }));
			}
		}


	}

	const { name, permission } = state;
	const { buttonType, add_group, title, listpermissionModulesdata, handleSubmit, selectedData } = props;

	return (
		<React.Fragment>
			<Dialog open={add_group} onClose={(e) => handleRequestClose(e)}
				fullWidth={true}
				maxWidth={'sm'}>
				<Form
					onSubmit={onSubmit}
					initialValues={
						{
							name: '',
						}
					}
					render={({ handleSubmit, values, form }) => (
						<form onSubmit={handleSubmit}>
							<DialogTitle>
								{title}
							</DialogTitle>
							<DialogContent>
								<DialogContentText>
								</DialogContentText>
								<Row>
									<Col >
										<Field id="name" name="name" type="text"
											component={ReactStrapTextField} label="Name"
											validate={required}
											value={name}
											onChange={(event) => handleChange(event, 'name')}
										/>
									</Col>
								</Row>
								<Row>
									<Col xs={12} md={12} sm={12} xl={12} lg={12}>
										<Row>
											<Col xs={6} md={6} sm={6} xl={6} lg={6}>
												<FormControlLabel
													onClick={(e) => handleAdminCheck(e)}
													control={
														<Checkbox
															checked={listpermissionModulesdata !== undefined && listpermissionModulesdata.length === permission.length}
															value={'admin_access'}
															color="primary"
														/>
													}
													label={'Admin Access'}
												/>
											</Col>
											{
												(listpermissionModulesdata !== undefined && listpermissionModulesdata.length > 0) ? listpermissionModulesdata.map((value, key) => {
													let index = permission.findIndex((e) => e._id === value._id)
													return <Col xs={6} md={6} sm={6} xl={6} lg={6}>
														<FormControlLabel
															onClick={(e) => handleCheck(e, key)}
															disabled={listpermissionModulesdata.length === permission.length ? true : false}
															control={
																<Checkbox
																	checked={index > -1 ? true : false}
																	value={value.value}
																	color="primary"
																	disabled={listpermissionModulesdata.length === permission.length ? true : false}
																/>
															}
															label={value.name == 'Ticketing View' ? 'Ticket Maker' : value.name == 'Ticketing' ? 'Ticket Checker' : value.name}
														/>
													</Col>
												}) : ''
											}
										</Row>
									</Col>
								</Row>
							</DialogContent>
							<DialogActions className="pr-4">
								<Button onClick={(e) => handleRequestClose(e)} color='secondary' >	Cancel </Button>
								<Button type="submit" color='primary'>Add </Button>
							</DialogActions>
						</form>)}
				/>
			</Dialog>
		</React.Fragment >
	);
}

const mapStateToProps = ({ admin }) => {
	const { listpermissionModulesdata } = admin;
	return { listpermissionModulesdata }
};


export default connect(
	mapStateToProps,
	{
		listPermissionModules,
		addGroup
	}           
)(AddGroup)