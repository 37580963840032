import React, { useEffect, useState } from "react";
// import MUIDataTable from "mui-datatables";
import MUIDataTable from "mui-datatables"
import { Col, Row, Label, Input } from 'reactstrap';
import moment from 'moment';
import customHead from 'constants/customHead';
import Button from '@mui/material/Button';
import ViewSettlement from '../ViewSettlement';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getSettlements } from 'actions/admin';
import { connect } from 'react-redux';
import Input1 from '@mui/material/Input';
import '../../../../../node_modules/react-datepicker/dist/react-datepicker.css';
import '../index.css'
import axios from 'axios';
import AppConfig from 'constants/config'
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import CustomeFilterForDate from "app/routes/components/CustomFilter/customfilter";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const payment_mode = { 'COD': "COD", "Prepaid": "Online", "Bulk Prepaid": "Bulk" }

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function AllSettlement(props) {

  const [state, setState] = useState({
    edit: false,
    hide: false,
    delete: false,
    add: false,
    view: false,
    yearList: [],
    data: '',
    to: moment(),
    from: moment(),
    filterData: '',
    perPage: 50,
    page: 0,
    searchedText: '',
    loader: false,
    type: []
  })

  const handleSettelMent = async () => {
    window.open(`${AppConfig.baseUrl}settlements/filterData?token=${localStorage.getItem('admin_token')}&from_date=${state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}&to_date=${state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}&type=${state.type ? state.type : ''}&seller_id=${state.seller ? state.seller : ''} `, '_blank');
    setState((prev) => ({
      ...prev,
      openFilter: false,
      status: '',
    }))
  }
  const resetFilter = () => {
    let data = {
      page: 1,
      perPage: state.perPage,
      filter: '',
      seller_id: '',
      from_date: moment().format('YYYY-MM-DD'),
      to_date: moment().add(1, 'days').format('YYYY-MM-DD')
    }
    props.getSettlements({ data, history: props.history })
    setState((prev) => ({
      ...prev,
      filter: '',
      to: moment(),
      from: moment(),
      type: [],
      seller: '',
      page: 0
    }));

  }
  const applyFilter = (filter) => {
    let data = {
      page: 1,
      perPage: state.perPage,
      searchText: state.searchedText ? state.searchedText : '',
      from_date: moment(state.from).format('YYYY-MM-DD'),
      to_date: moment(state.to).add(1, 'days').format('YYYY-MM-DD'),
      seller_id: state.seller ? state.seller : '',
      type: state.type ? state.type : [],
    }
    props.getSettlements({ data, history: props.history })
    setState((prev) => ({
      ...prev,
      page: 0,
    }))

  }


  const changePage = (page) => {
    let pages = page + 1;
    let data = {
      page: pages,
      perPage: state.perPage,
      searchText: state.searchedText,
      filter: '',
      from_date: state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      to_date: state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      seller_id: state.seller ? state.seller : '',
      type: state.type ? state.type : []
    }
    props.getSettlements({ data, history: props.history })
    setState((prev) => ({ ...prev, page }))
    setState((prev) => ({ ...prev, loader: true }))
    setTimeout(() => {
      setState((prev) => ({ ...prev, loader: false }))
    }, 2000);
  };

  function throttleHandleChange(edata) {
    props.getSettlements({ data: edata, history: props.history })
  }
  const handleSearch = (searchText) => {
    let data = {
      searchText: searchText, page: 1, perPage: state.perPage, from_date: state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      to_date: state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format(), seller_id: state.seller ? state.seller : '', type: state.type ? state.type : ''
    }
    throttleHandleChange(data)
    setState((prev) => ({ ...prev, searchedText: searchText, page: 0 }))
  };


  const handleClick = (key, dataOne) => {
    setState((prev) => ({ ...prev, [key]: !state[key], data: dataOne ? dataOne : '' }))
    console.log("handleResetFilter called")

  }
  const handleChanged = (e, key) => {
    console.log('keady', e.target.value, key, e.target.value == null);
    setState((prev) => ({ ...prev, [key]: e.target.value }));
  }
  const handleSelectChangePaymentType = (e) => {
    let tempSelectedCat = e.target.value;
    let index = tempSelectedCat && tempSelectedCat.length > 0 ? tempSelectedCat.findIndex((e) => e === 'type') > -1 ? tempSelectedCat.findIndex((e) => e === 'type') : -1 : -1;
    index > -1 && tempSelectedCat.splice(index, 1);
    setState((prev) => ({ ...prev, type: tempSelectedCat }));

  }

  const fetchApi = async () => {
    let data = {
      page: 1,
      perPage: 50,
      from_date: moment(state.from).format('YYYY-MM-DD'),
      to_date: moment(state.to).add(1, 'days').format('YYYY-MM-DD'),
      seller_id: '',
      type: []
    }
    await props.getSettlements({ data });
    await axios({
      method: 'get',
      url: `${AppConfig.baseUrl}inventory/get_filters`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      }
    }).then(result => {
      console.log("result", result)
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        setState((prev) => ({
          ...prev,
          filterData: result.data.detail.sellers,

        }))
      }
    }).catch(error => {
      console.log("error catch", error)
    });
  }

  useEffect(() => {
    fetchApi()
  }, [])

  const handleDateChange = (date, key) => {
    setState((prev) => ({ ...prev, [key]: moment(date).format() }))
  }
  let { from, to, seller, filterData, type } = state;
  const { adminSettlements } = props;
  const columns = [
    {
      name: "orderId",
      label: "Order ID",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "orderDate",
      label: "Order Date",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "deliveryDate",
      label: "Delivery Date",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "buyerName",
      label: "Buyer",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "sellerName",
      label: "Seller",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "orderValue",
      label: "Order Value",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "payment",
      label: "Payment mode",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "paymentDueDate",
      label: "Settlement Date",
      options: {
        filter: true,
        filterType: 'custom',
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        },
        filterOptions: {
          fullWidth: true,
          display: () => (
            <React.Fragment >
              <Row form style={{ maxWidth: 350 }}>
                <CustomeFilterForDate handleDateChange={handleDateChange} from={from} to={to} />
                <Col md={8} xl={6} xs={6} sm={6} lg={6}>
                  <FormControl className="mb-2" >
                    <Label for="exampleSelect">Sellers</Label>
                    <Input type="select" className="form-control" disableUnderline={true} name="seller" id="seller"
                      value={seller}
                      onChange={(e) => handleChanged(e, 'seller')}
                      style={{
                        height: '47px',
                      }}

                    >
                      <option selected={seller === ''} value={''}>Select Seller</option>
                      {
                        filterData && filterData.map((val) => {
                          return (<option selected={val._id === seller} value={val._id}>{val.company_name}</option>)
                        })
                      }
                    </Input>
                  </FormControl>
                </Col>
                <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                  <Label for="exampleSelect">Payment Mode</Label>
                  <Select style={{ margin: "1px" }}
                    labelId="demo-multiple-checkbox-label"
                    multiple
                    id="demo-multiple-checkbox-label"
                    value={type}
                    onChange={(e) => handleSelectChangePaymentType(e)}
                    input={<Input1 disableUnderline={true} className="form-control " id="name-multiple" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      key={'select type'}
                      value={'type'}
                      style={{
                        fontWeight: '500',
                      }}
                    >
                      Select payment mode
                    </MenuItem>
                    {payment_mode && Object.keys(payment_mode).map(name => (
                      <MenuItem
                        key={name}
                        value={payment_mode[name]}
                        className='putIconAfter '
                        style={{
                          fontWeight: state.type.indexOf(name) !== -1 ? '500' : '400',
                        }}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Col>
              </Row>
            </React.Fragment>
          ),
          onFilterChange: () => {
            console.log('onFilterChange')
          }
        },
      },
    },
    {
      name: "logisticDate",
      label: "Logistic Date",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    }

  ];
  let data12 = []

  adminSettlements && adminSettlements.settlements && adminSettlements.settlements[0] && adminSettlements.settlements[0].data.map((dataOne, index) => {
    let dDate = dataOne && dataOne.payment_due_date_ft
    data12.push({
      orderId: <>
        <a rel="noreferrer" target='_blank' style={{ color: 'inherit' }} href={`/admin/orders/orderDetails/${dataOne.order.order_id}`}>
          <div style={{ width: '120px' }}>
            <span style={{ marginRight: "3px" }}>
              {dataOne.order.order_status[dataOne.order.order_status.length - 1].status === 'LOST' ?
                <Tooltip title={`LOST`} placement="top-start">
                  <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'red' }}>
                  </span>
                </Tooltip> : ""}
            </span>
            <span >{dataOne.order.order_id}</span>
            {dataOne.status === 'Settled' ?
              <Tooltip title={<span>Settle Date: {dataOne.seller_settlement_date ? moment(dataOne.seller_settlement_date).format('DD/MM/YYYY') : ''}</span>} placement="top-start">
                <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'green' }}>
                </span>
              </Tooltip> :
              dataOne.status === 'Pending' ?
                <Tooltip title={`Settle Date: ${dataOne.seller_settlement_date ? dataOne.seller_settlement_date : ''}`} placement="top-start">
                  <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'orange' }}>
                  </span>
                </Tooltip> : ''
            }
          </div></a></>,
      orderDate: <div onClick={(e) => handleClick('view', dataOne)}>{dataOne.order.createdAt}</div>,
      deliveryDate: <div onClick={(e) => handleClick('view', dataOne)}>{dDate}</div>,
      sellerName: <div onClick={(e) => handleClick('view', dataOne)}>{dataOne.seller && dataOne.seller.company_name}</div>,
      buyerName: <div onClick={(e) => handleClick('view', dataOne)}>{dataOne.buyer && dataOne.buyer.company_name}</div>,
      orderValue: <div onClick={(e) => handleClick('view', dataOne)}>{"₹" + (dataOne.order_value).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>,
      paymentDueDate: <div onClick={(e) => handleClick('view', dataOne)}>{dataOne.seller_settlement_date ? moment(dataOne.seller_settlement_date).format('DD/MM/YYYY') : 'N/A'}</div>,
      logisticDate: <div onClick={(e) => handleClick('view', dataOne)}>{dataOne.logistic_settlement_date ? moment(dataOne.logistic_settlement_date).format('DD/MM/YYYY') : ''}</div>,
      payment: dataOne.order.source ? dataOne.order.source : dataOne.order.paymentType && dataOne.order.isBulk === true && !dataOne.order.rawTransaction && dataOne.order.rawTransaction === undefined ? 'Bulk Prepaid' : dataOne.order.paymentType === "COD" ? "COD" : ((dataOne.order.paymentType === "Online" && dataOne.order.rawTransaction) ? dataOne.order.rawTransaction.payment_mode && dataOne.order_value - (dataOne.order.rawTransaction && dataOne.order.rawTransaction.amount) > 0 ? "MediWallet+" + dataOne.order.rawTransaction.payment_mode : dataOne.order.rawTransaction.payment_mode : "MediWallet"),
    })
  })

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    print: false,
    download: true,
    selectableRows: 'none',
    selectableRowsOnClick: true,
    selectableRowsHeader: true,
    responsive: 'scrollMaxHeight',
    search: true,
    viewColumns: false,
    rowHover: true,
    rowsPerPage: 50,
    count: adminSettlements && adminSettlements.settlements[0] && adminSettlements.settlements[0].metadata && adminSettlements.settlements[0].metadata[0] && adminSettlements.settlements[0].metadata[0].total,
    serverSide: true,
    server: true,
    page: state.page,
    searchText: state.searchedText,
    rowsPerPageOptions: [],
    textLabels: {
      filter: {
        all: "All",
        title: "FILTERS",
      },
    },

    onTableChange: (action, tableState) => {
      console.log('action=', action, tableState)
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'download':
          handleSettelMent();
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <div style={{ paddingTop: 15 }} >
            <Button variant="contained" onClick={() => { applyFilter(); applyNewFilters() }} className='filterButton' color='primary'>Apply Filter</Button>
            <Button variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton' color='primary'>Reset Filter</Button>
          </div>
        </div>
      );
    },
    onDownload: () => {
      handleSettelMent()
      return false
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  };

  return (
    <React.Fragment>
      {props.loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}
      {
        props.loading === true ?
          null :
          <MUIDataTable
            identifier={'allSettlement'}
            handleSettelMent={handleSettelMent}
            data={data12}
            columns={columns}
            options={options}
          />
      }
      <ViewSettlement buttonType={'view'} dataFromParent={state.data} view={state.view} title={'View Settlement'} handleClick={handleClick} />
    </React.Fragment>
  );
}

const mapStateToProps = ({ admin }) => {
  const { adminSettlements, loading } = admin;
  return { adminSettlements, loading }
};

export default connect(mapStateToProps, { getSettlements })(AllSettlement);