import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { changeTab } from '../../../../../../actions/tabAction';
import { connect } from 'react-redux';
import Settlement from 'app/routes/settlement/routes/settlement';
import AllSettlement from 'app/routes/settlement/routes/AllSettlement';
import PrepaidOrder from 'app/routes/settlement/routes/prepaidOrder';

function TabContainer({ children, dir }) {
  return (
    <div dir={dir}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

function FullWidthTabsSettlement(props) {

  const handleChange = (event, value) => {
    props.changeTab(value)
  };

  useEffect(() => {
    if (props.tabValue > 1) {
      props.changeTab(0)
    }
  }, [])

  const { tabValue, tabFor } = props;
  return (
    <div className="w-100" >
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
        >
          <Tab className="tab" label="All" />
          <Tab className="tab" label="Sellerwise" />
          <Tab className="tab" label="Prepaid Orders" />
        </Tabs>
      </AppBar>

      {tabValue === 0 && <TabContainer index={tabValue}>
        {
          tabFor === 'settlement' ? <AllSettlement history={props.history} /> : ''
        }
      </TabContainer>}
      {tabValue === 1 && <TabContainer index={tabValue}>
        {
          tabFor === 'settlement' ? <Settlement history={props.history} /> : ''
        }
      </TabContainer>}
      {tabValue === 2 && <TabContainer index={tabValue}>
        {
          tabFor === 'settlement' ? <PrepaidOrder history={props.history} /> : ''
        }
      </TabContainer>}
    </div>
  );
}

FullWidthTabsSettlement.propTypes = {
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = ({ tabAction }) => {
  const { tabValue } = tabAction;
  return { tabValue }
};

export default connect(mapStateToProps, { changeTab })(FullWidthTabsSettlement);