import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { FormGroup } from '@mui/material';
import { Col, Row, Label, Input } from 'reactstrap';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ProductTable from '../components/productTable';

import helperFunction from 'constants/helperFunction';
import axios from 'constants/axios';
let monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
let date = new Date();
function TabContainer({ children, dir }) {
  return (
    <div dir={dir}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

function SellingProductTab(props) {

  const [state, setState] = useState({
    open: false,
    anchorEl: null,
    filterValueTop: monthArray[date.getMonth()] + ' ' + date.getFullYear(),
    filterValueLeast: monthArray[date.getMonth()] + ' ' + date.getFullYear(),
    month: monthArray[date.getMonth()],
    year: date.getFullYear(),
    month1: monthArray[date.getMonth()],
    year1: date.getFullYear(),
    selectedTab: '',
    yearList: [],
    topFive: '',
    leastFive: ''
  })

  const componentdidMount = async () => {
    let years = helperFunction.yearList();
    setState((prev) => ({ ...prev, yearList: years }))
    let data = {
      user_type: 'seller',
      id: props.id,
      month: monthArray[date.getMonth()],
      year: date.getFullYear()
    }
    await axios.post('product/top5least5', data).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        console.log("successtopandleast", result.data)
        setState((prev) => ({
          ...prev,
          topFive: result.data.top5,
          leastFive: result.data.least5,
          anchorEl: null
        }))
      }
    })
      .catch(error => {
        console.log("error catch", error)
      });
  }
  useEffect(() => {
    componentdidMount()
  }, [])

  const handleClick = (event, key) => {
    setState((prev) => ({ ...prev, anchorEl: event.currentTarget, selectedTab: key }))
  }

  const handleClose = (e) => {
    e.preventDefault();

    if (state.selectedTab === 'top') {

      setState((prev) => ({ ...prev, anchorEl: null, filterValueTop: monthArray[date.getMonth()] + ' ' + date.getFullYear(), month: monthArray[date.getMonth()], year: date.getFullYear() }))
    } else if (state.selectedTab === 'least') {
      setState((prev) => ({ ...prev, anchorEl: null, filterValueLeast: monthArray[date.getMonth()] + ' ' + date.getFullYear(), month1: monthArray[date.getMonth()], year1: date.getFullYear() }))
    }
  }

  const handleChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }))
  }
  const handleApplyFilter = async (e) => {
    e.preventDefault();

    let data = {
      user_type: 'seller',
      id: props.id,
      month: state.month,
      year: state.year
    }
    await axios.post('product/top5least5', data).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        console.log("successtopandleast", result.data)
        setState((prev) => ({
          ...prev,
          topFive: result.data.top5,
          leastFive: result.data.least5,
          filterValueTop: state.month + ' ' + state.year,
          filterValueLeast: state.month + ' ' + state.year,
          anchorEl: null
        }))
      }
    })
      .catch(error => {
        console.log("error catch", error)
      });

  }
  const handleResetFilter = async (e) => {
    e.preventDefault();
    // filter();
    setState((prev) => ({
      ...prev,
      month: monthArray[date.getMonth()],
      year: date.getFullYear(),
      filterValueTop: monthArray[date.getMonth()] + ' ' + date.getFullYear(),
      filterValueLeast: monthArray[date.getMonth()] + ' ' + date.getFullYear()
    }));
    let data = {
      user_type: 'seller',
      id: props.id,
      month: monthArray[date.getMonth()],
      year: date.getFullYear()
    }
    await axios.post('product/top5least5', data).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        console.log("successtopandleast", result.data)
        setState((prev) => ({
          ...prev,
          topFive: result.data.top5,
          leastFive: result.data.least5,
          anchorEl: null
        }))
      }
    })
      .catch(error => {
        console.log("error catch", error)
      });
  }

  const { topFive, leastFive, filterValueTop, anchorEl, filterValueLeast, month, year, month1, year1, selectedTab, yearList } = state;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xl-6 col-12">
          <div className="jr-card">
            <div className="jr-card-header d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="d-inline-block mb-0">Top 10 Selling Products</h3>
                {
                  filterValueTop === '' ? null : <span className="text-white badge badge-success">{filterValueTop}</span>
                }
              </div>
              <IconButton className="icon-btn" onClick={(e) => handleClick(e, 'top')}>
                <i className="zmdi  zmdi-filter-list" />
              </IconButton>
            </div>
            <ProductTable value={topFive} />
          </div>
        </div>

        <div className="col-xl-6 col-12">
          <div className="jr-card">
            <div className="jr-card-header d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="d-inline-block mb-0">Least 10 Selling Products</h3>
                {
                  filterValueLeast === '' ? null : <span className="text-white badge badge-success">{filterValueLeast}</span>
                }
              </div>
              <IconButton className="icon-btn" onClick={(e) => handleClick(e, 'top')}>
                <i className="zmdi  zmdi-filter-list" />
              </IconButton>
            </div>
            <ProductTable value={leastFive} />
          </div>
        </div>
      </div>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(e) => handleClose(e)}
      >
        <MenuItem className="filterBody">
          <div className="flex">
            <Row form>
              <Col md={6} xl={6} xs={6} lg={6}>
                <FormGroup>
                  <Label for="exampleSelect">Select Month</Label>
                  <Input type="select" value={selectedTab === 'top' ? month : month1} name="select" onChange={(e) => handleChange(e, selectedTab === 'top' ? 'month' : 'month1')} id="exampleSelect">
                    {
                      monthArray.map((value, key) => {
                        return <option key={key} value={value}>{value}</option>
                      })
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6} xl={6} xs={6} lg={6}>
                <FormGroup>
                  <Label for="exampleSelect">Select Year</Label>
                  <Input type="select" value={selectedTab === 'top' ? year : year1} onChange={(e) => handleChange(e, selectedTab === 'top' ? 'year' : 'year1')} name="select" id="exampleSelect">
                    {
                      yearList.map((year, key) => {
                        return <option key={key} value={year}>{year}</option>
                      })
                    }
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <div style={{ paddingTop: 15 }} >
              <Button variant="contained" className='filterButton' onClick={(e) => handleApplyFilter(e)} color='primary'>Apply Filter</Button>
              <Button variant="contained" className='filterButton' onClick={(e) => handleResetFilter(e)} color='primary'>Reset Filter</Button>
            </div>
          </div>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

SellingProductTab.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default SellingProductTab;