import React from "react";
import FullWidthShortbookTabs from '../components/routes/tabs/fixed/FullWidthShortbookTabs';
import AdminContainer from "containers/AdminContainer";

const Title = {
  fontSize: '1.25rem',
  fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  fontWeight: 500,
  lineHeight: 1.6,
  letterSpacing: '0.0075em',
  marginBottom: '25px'
}

const ShortbookTabs = (props) => {
  return (
    <AdminContainer>
      <div style={Title} >
        Buyer Shortbook
      </div>
      <FullWidthShortbookTabs />
    </AdminContainer>
  );
}

export default ShortbookTabs;