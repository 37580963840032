import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'constants/axios';
import Button from '@mui/material/Button';
import { NotificationManager } from 'react-notifications';
import DatePickerForBuyerPerfomance from "./datepicker";
import SearchBar from "./searchbar";
import AdminContainer from "containers/AdminContainer";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "../components/PaginationCount";

const SellerPerformance = (props) => {
  const [loader, setLoader] = useState(false)
  const [from, setFrom] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'))
  const [to, setTo] = useState(moment().format('YYYY-MM-DD'))
  const [performance, setPerformance] = useState('')
  const [allPerformance, setAllPerformance] = useState('')
  const [count, setCount] = useState(0)
  const [searchedText, setSearchedText] = useState('')

  const getSellerPerformance = async () => {
    let data = {
      from_date: moment(from).format('YYYY-MM-DD'),
      to_date: moment(to).add(1, 'days').format('YYYY-MM-DD'),
    }
    setLoader(true)
    await axios.post('/reports/getSellerPerformance', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error('Something went wrong, Please try again');
      } else {
        setPerformance(result.data.data)
        setAllPerformance(result.data.data)
        setLoader(false)
        setCount(result.data.length)
        if (searchedText) {
          const allData = result && result.data && result.data.data ? result.data.data : []
          const filteredData = allData.filter(v => new RegExp(searchedText, 'ig').test(v.sellerName))
          setPerformance(filteredData)
        }
      }
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
    });
  }

  useEffect(() => {
    getSellerPerformance()
  }, [])

  const applyFilter = () => {
    getSellerPerformance();
  }

  const handleDateChange = (date, key) => {
    if (key === 'from') {
      setFrom(moment(date).format('YYYY-MM-DD'))
    } else {
      setTo(moment(date).format('YYYY-MM-DD'))
    }
  }

  const handleSearch = async (searchText) => {
    setSearchedText(searchText)
    const allData = [...allPerformance]
    if (searchText) {
      const filteredData = allData.filter(v => new RegExp(searchText, 'ig').test(v.sellerName))
      setPerformance(filteredData)
    } else {
      setPerformance(allPerformance)
    }
  };

  const redirect = (data, type) => {
    if (type === 'cancelled') {
      window.open(`/admin/sellerPerformance/orders?seller=${data._id}&type=${type}&from_date=${from}&to_date=${to}&cancel=seller`, '_blank')
    } else {
      window.open(`/admin/sellerPerformance/orders?seller=${data._id}&type=${type}&from_date=${from}&to_date=${to}`, '_blank')
    }
  }

  let totalOpen = 0;
  let totalProcessed = 0;
  let totalRejected = 0;
  let data = [];
  let index = 1;
  performance && performance.map(dataOne => {
    data.push({
      index: <div>{index}</div>,
      seller: <div>{dataOne.sellerName}</div>,
      ordersReceived: <div style={{ cursor: 'pointer' }} className='text-primary' onClick={(e) => redirect(dataOne, 'open')}>{dataOne.open}</div>,
      ordersRejected: <div style={{ cursor: 'pointer' }} className='text-primary' onClick={(e) => redirect(dataOne, 'cancelled')}>{dataOne.rejected}</div>,
      ordersProcessed: <div style={{ cursor: 'pointer' }} className='text-primary' onClick={(e) => redirect(dataOne, 'processed')}>{dataOne.processed}</div>,
      totalEarned: <div>₹{(dataOne.amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
    })
    index += 1;
    totalOpen += dataOne.open;
    totalRejected += dataOne.rejected;
    totalProcessed += dataOne.processed;
  })
  const columns = [
    {
      name: "index",
      label: "Index",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "seller",
      label: "Seller",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "ordersReceived",
      label: `Open(${totalOpen})`,
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "ordersRejected",
      label: `Cancelled(${totalRejected})`,
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "ordersProcessed",
      label: `Processed(${totalProcessed})`,
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "totalEarned",
      label: "Total",
      options: {
        filter: false,
        sort: false,
      }
    }
  ];

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: false,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: 0,
    rowsPerPage: count ? count : 200,
    rowsPerPageOptions: [],
    count: count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: '',
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  return (
    <AdminContainer>
      <div className={'title'}>
        Seller Performance Report
      </div>
      <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
        {loader ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}
        {
          loader ?
            null :
            <MUIDataTable
              title={
                <div className="header">
                  <div className="Row-header d-flex w-100" >
                    <div style={{ marginRight: '20px', marginTop: '9px' }}>
                      <SearchBar handleSearch={handleSearch} searchText={searchedText} placeholder="Search" />
                    </div>
                    <div style={{ marginRight: '20px' }}>
                      <DatePickerForBuyerPerfomance state='from' dateValue={from} handleDateChange={handleDateChange} label={"From"} />
                    </div>
                    <div style={{ marginRight: '20px' }}>
                      <DatePickerForBuyerPerfomance state="to" label={"To"} dateValue={to} handleDateChange={handleDateChange} />
                    </div>
                    <div style={{ marginTop: '9px' }}>
                      <Button style={{ paddingTop: '9px', paddingBottom: '9px' }} onClick={() => applyFilter()} variant="contained" className='filterButton' color='primary'>Search</Button>
                    </div>
                  </div>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
              style={{ borderRadius: '0px !important' }}
            />
        }
      </div>
    </AdminContainer>
  );
}

export default SellerPerformance;

