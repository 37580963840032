// import React from 'react';
// import Avatar from '@mui/material/Avatar'
// import { connect } from 'react-redux'
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import { adminSignOut } from 'actions/Auth';
// import { NavLink } from 'react-router-dom';

// class AdminInfo extends React.Component {

//   state = {
//     anchorEl: null,
//     open: false,
//   };

//   handleClick = event => {
//     this.setState({ open: true, anchorEl: event.currentTarget });
//   };

//   handleRequestClose = () => {
//     this.setState({ open: false });
//   };

//   signOut = () =>{
//     localStorage.removeItem('admin_token');
//     localStorage.clear();
//     setTimeout(() => {
//       window.location.reload();
//       console.log("signedOUT..............")
//     }, 1000);
//   }

//   render() {
//     return (
//       <div className="user-profile d-flex flex-row align-items-center">
//         <Avatar
//           alt='Admin'
//           src={require('assets/images/medical-logo.png')}
//           className="user-avatar "
//         />
//         <div className="user-detail">
//           <h4 className="user-name" onClick={this.handleClick}>Admin<i
//             className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
//           </h4>
//         </div>
//         <Menu className="user-info"
//           id="simple-menu"
//           anchorEl={this.state.anchorEl}
//           open={this.state.open}
//           onClose={this.handleRequestClose}
//           PaperProps={{
//             style: {
//               minWidth: 120,
//               paddingTop: 0,
//               paddingBottom: 0
//             }
//           }}
//         >
          
//           <MenuItem onClick={this.handleRequestClose}>
//             <NavLink  to="/admin/change-Password" className={'text-black NavLink'}>
//               <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
//               Change Password
//             </NavLink>
//           </MenuItem>

//           <MenuItem onClick={() => {
//             this.handleRequestClose();
//             this.props.adminSignOut();
//             this.signOut()
//           }}>

//             <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

//            Logout

//           </MenuItem>

//         </Menu>
//       </div>
//     );
//   }
// }

// const mapStateToProps = ({ settings }) => {
//   const { locale } = settings;
//   return { locale }
// };
// export default connect(mapStateToProps, { adminSignOut })(AdminInfo);


import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { connect } from 'react-redux';
import { adminSignOut } from 'actions/Auth';
import { NavLink, useNavigate } from 'react-router-dom';

const AdminInfo = ({ adminSignOut }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
 const Navigate = useNavigate()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleRequestClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const signOut = () => {
    localStorage.removeItem('admin_token');
    localStorage.clear();
    Navigate('/')
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Avatar
        alt='Admin'
        src={require('assets/images/medical-logo.png')}
        className="user-avatar "
      />
      <div className="user-detail">
        <h4 className="user-name" onClick={handleClick}>
          Admin
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
        </h4>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0
          }
        }}
      >
        <MenuItem onClick={handleRequestClose}>
          <NavLink to="/admin/change-Password" className={'text-black NavLink'}>
            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
            Change Password
          </NavLink>
        </MenuItem>

        <MenuItem onClick={() => {
          handleRequestClose();
          adminSignOut();
          signOut();
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};

export default connect(mapStateToProps, { adminSignOut })(AdminInfo);
