
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import {
  hideMessage,
  showAuthLoader,
  adminSignIn,
} from 'actions/Auth';
import { useNavigate } from 'react-router';

const AdminSignIn = (props) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    user_type: 'admin',
  });
  const Navigate = useNavigate()
  useEffect(() => {
    if (props.showMessage) {
      setTimeout(() => {
        props.hideMessage();
      }, 100);
    }
    if (props.authUser !== null) {
      console.log("props.authUser", props.authUser)
      Navigate('/');
    }
  }, [props.showMessage, props.authUser]);

  const gotoSignup = () => {
    Navigate('/signup');
  };

  const gotoForgotPassword = () => {
    Navigate('/forgotPassword');
  };

  const handleSigninOnEnter = (e) => {
    const { email, password, user_type } = formData;
    if (e.key === 'Enter' && email !== '' && password !== '') {
      props.showAuthLoader();
      props.adminSignIn({ email, password, user_type, history: Navigate});
    }
  };

  const handleSignin = async(e) => {
    e.preventDefault();
    const { email, password, user_type } = formData;
    await props.showAuthLoader();
    await props.adminSignIn({ email, password, user_type, history: Navigate });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const { email, password } = formData;
  const { showMessage, loader, alertMessage } = props;

  return (
    <div className="col-xl-8 col-lg-8 signinContainer">
      <div className="jr-card signinCard">
        <div className="animated slideInUpTiny animation-duration-3">
          <div className="login-header mb-0">
            <a className="app-logo" href='javascript:void(0);' title="Jambo">
              <img src={require("assets/images/medimny.png")} className='medilogo' alt="Medimny" title="Medimny" />
            </a>
          </div>
          <Row>
            <Col sm={12} md={12} lg={12} xs={12} xl={12}>
              <TextField
                variant="standard"
                id="email"
                label="Email"
                name={'email'}
                onChange={handleChange}
                onKeyDown={handleSigninOnEnter}
                defaultValue={email}
                margin="normal"
                fullWidth={true}
              />
            </Col>
            <Col sm={12} md={12} lg={12} xs={12} xl={12}>
              <TextField
                variant="standard"
                id="password"
                label="Password"
                name={'password'}
                type='password'
                onChange={handleChange}
                onKeyDown={handleSigninOnEnter}
                defaultValue={password}
                margin="normal"
                fullWidth={true}
              />
            </Col>
            <Col sm={6} md={6} lg={6} xs={6} xl={6}>
              <Button variant='contained' color='primary' className='m-2 signin' size='medium' onClick={handleSignin}>
                SIGN IN
              </Button>
            </Col>
          </Row>
          <div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
  adminSignIn,
  hideMessage,
  showAuthLoader,
})(AdminSignIn);
