import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import ContainerHeader from 'components/ContainerHeader';
import ButtonGroup from '@mui/material/ButtonGroup';
import customHead from 'constants/customHead'
import { getBuyerSellerList, getBuyerSellerListAdmin, getUserApprove, getChangeUserStatus } from 'actions/admin'
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { FormGroup, Label, Input, Col, Row, } from 'reactstrap';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import debounce from 'lodash.debounce';
import AdminContainer from 'containers/AdminContainer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationCount from '../components/PaginationCount';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function StaffList(props) {
  const [state, setState] = useState({
    edit: false,
    permission: false,
    delete: false,
    add: false,
    action: '',
    name: '',
    loader: false,
    info: '',
    selectedDate: moment(new Date()).format('YYYY-MM-DD'),
    dataForSuspend: '',
    filter: '',
    status: '',
    deny: false,
    perPage: 50,
    pageNo: 0,
    size: 50,
    searchedText: ''
  })

  function actualHandleChange(edata) {
    props.getBuyerSellerListAdmin({ data: edata, history: props.history });
  }

  const throttledHandleChange = debounce(async (data) => {
    await actualHandleChange(data)
  }, 500);

  const handleChanged = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }))
  }

  const handleSub = (data) => {
    setState((prev) => ({
      ...prev,
      delete: true,
      info: data
    }))
  }

  const handleDetail = (id) => {
    window.open(`/admin/sellers/details/${id}`, '_blank')
  }

  const handledClicked = (key) => {
    setState((prev) => ({ ...prev, id: key }))
    props.getUserApprove({ user_id: key, user_type: 'seller' });
    setState({ loader: true })
    setTimeout(() => {
      setState({ loader: false })
    }, 3000);
  }

  const handleDeny = (data) => {
    setState((prev) => ({
      ...prev,
      deny: true,
      info: data
    }))
  }

  const handleDenied = () => {
    props.getChangeUserStatus({ id: state.info._id, status: 'denied' })
    setState((prev) => ({
      ...prev,
      deny: false,
    }))
  }

  const handleBan = (data) => {
    props.getChangeUserStatus({ id: data._id, status: 'blocked' })
  }

  const handleActivate = (data) => {
    props.getChangeUserStatus({ id: data._id, status: 'active' })
  }

  const handleSuspend = () => {
    props.getChangeUserStatus({ id: state.info._id, status: 'suspend', date: state.selectedDate })
    setState((prev) => ({
      ...prev,
      delete: false,
    }))
  }

  const handleDateChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
  }

  const handleRequestClose = () => {
    setState((prev) => ({
      ...prev,
      delete: false,
      deny: false
    }))
  }

  useEffect(() => {
    let data = {
      pageNo: 1,
      size: 50,
      filter: { user_type: 'seller', mainUser: null, user_status: { '$ne': 'denied' } },
      name: state.searchedText ? state.searchedText : ''
    }
    props.getBuyerSellerListAdmin({ data });
  }, [])

  const applyFilter = (filter) => {
    console.log('asdwasdxx', filter)
    let data = {
      pageNo: 1,
      size: state.size,
      searchText: state.searchedText ? state.searchedText : '',
      filter: !state.status ? { user_type: 'seller', mainUser: null, user_status: { '$ne': 'denied' } } : { user_type: 'seller', mainUser: null, user_status: state.status },
      name: state.searchedText ? state.searchedText : ''
    }
    props.getBuyerSellerListAdmin({ data, history: props.history });
    setState((prev) => ({
      ...prev,
      pageNo: 0
    }))
  }

  const resetFilter = (filter) => {
    let data = {
      pageNo: 1,
      size: 50,
      filter: !state.status === '' ? '' : { user_type: 'seller', mainUser: null, user_status: { '$ne': 'denied' } },
      name: state.searchedText ? state.searchedText : ''
    }
    props.getBuyerSellerListAdmin({ data, history: props.history })
    setState((prev) => ({
      ...prev,
      filter: '',
      status: '',
      showFilter: false,
      pageNo: 0
    }))
  }

  const changePage = (pageNo) => {
    let pages = pageNo + 1;
    let data = {
      pageNo: pages,
      size: state.size,
      searchText: state.searchedText ? state.searchedText : '',
      filter: !state.status ? { user_type: 'seller', mainUser: null, user_status: { '$ne': 'denied' } } : { user_type: 'seller', mainUser: null, user_status: state.status },
      name: state.searchedText ? state.searchedText : ''
    }
    props.getBuyerSellerListAdmin({ data, history: props.history })
    setState((prev) => ({ ...prev, pageNo }))
  };

  const handleSearch = debounce((searchText) => {
    let data = {
      pageNo: 1,
      size: state.size,
      filter: !state.status ? { user_type: 'seller', mainUser: null, user_status: { '$ne': 'denied' } } : { user_type: 'seller', mainUser: null, user_status: state.status },
      name: searchText
    }
    throttledHandleChange(data)
    setState((prev) => ({ ...prev, searchedText: searchText, pageNo: 0 }))
  }, 500)

  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val === key)
    return index > -1 ? true : false
  }
  const { buyerSellerList } = props;
  let { selectedDate } = state;

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    responsive: 'scrollMaxHeight',
    rowsPerPage: 50,
    page: state.pageNo,
    count: props.buyerSellerList && props.buyerSellerList.users && props.buyerSellerList.users.length >= 0 && props.buyerSellerList.users[0] && props.buyerSellerList.users[0].metadata && props.buyerSellerList.users[0].metadata.length >= 0 ?
      props.buyerSellerList.users[0].metadata[0] && props.buyerSellerList.users[0].metadata[0].total : 0,
    rowsPerPageOptions: [],
    search: true,
    viewColumns: false,
    rowHover: false,
    filter: true,
    serverSide: true,
    server: true,
    searchText: state.searchedText,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <div style={{ paddingTop: 15 }} >
            <Button variant="contained" onClick={() => { applyFilter(); applyNewFilters() }} className='filterButton' color='primary'>Apply Filter</Button>
            <Button variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton' color='primary'>Reset Filter</Button>
          </div>
        </div>
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    textLabels: {
      filter: {
        all: "All",
        title: "FILTERS",
      },
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "contact",
      label: "Contact No.",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "email",
      label: "Email ID",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: true,
        filterType: 'custom',
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        },
        customFilterListRender: v => {
          return false;
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, handleCustomChange, index, column) => (
            <React.Fragment>
              <Row form style={{ maxWidth: 400 }}>
                <Col md={12} xl={12} xs={12} lg={12}>
                  <FormControl className="w-100 mb-2 categoryFilter">
                    <Label for="Status">Status</Label>
                    <Input name="status" id="status" type="select"
                      value={state.status}
                      onChange={(e) => handleChanged(e, 'status')}
                      input={<Input disableUnderline={true} className="form-control" id="Status" />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 200
                          },
                        },
                      }}
                    >
                      <option value="">
                        All
                      </option>
                      <option value='active'>Active </option>
                      <option value='blocked'>Blocked </option>
                      <option value='hold'>Hold </option>
                      <option value='inactive'>Inactive </option>
                      <option value='pending'>Pending </option>
                      <option value='suspend'>Suspend </option>
                    </Input>
                  </FormControl>
                </Col>
              </Row>
            </React.Fragment>
          ),
          onFilterChange: () => {
            console.log('onFilterChange')
          }
        },
      },
    },
  ];

  let data = []

  buyerSellerList && buyerSellerList.users && buyerSellerList.users.length > 0 && buyerSellerList.users && buyerSellerList.users[0] && buyerSellerList.users[0].data.map((dataOne) => {
    data.push({
      id: <Tooltip className="d-inline-block" id="tooltip-right"
        title={
          <span className="text-white" style={{ marginTop: "13px" }}>
            {dataOne && dataOne.otp}
          </span>
        }
        placement="left">
        <span>{dataOne.sellerId ? dataOne.sellerId : ''}</span></Tooltip>,
      name: dataOne.company_name ? dataOne.company_name : '',
      email: <Tooltip className="d-inline-block" id="tooltip-right"
        title={
          <span className="text-white" style={{ marginTop: "13px" }}>
            {dataOne && dataOne.isVerifiedEmail === true ? 'Verified' : 'Not verified'}
          </span>
        }
        placement="top">
        <span>{dataOne.email}</span></Tooltip>,
      contact: <Tooltip className="d-inline-block" id="tooltip-right"
        title={
          <span className="text-white" style={{ marginTop: "13px" }}>
            {dataOne && dataOne.isVerifiedPhone === true ? 'Verified' : 'Not verified'}
          </span>
        }
        placement="top">
        <span>{dataOne.phone}</span></Tooltip>,
      address: dataOne.user_address,
      action:
        (dataOne.user_status === "inactive" || dataOne.user_status === "hold") ?

          <ButtonGroup color="primary" aria-label="outlined primary button group">
            {isButtonAvailable('approve') && <Button className={'text-success'} onClick={() => handledClicked(dataOne._id)} > Allow</Button>}
            {isButtonAvailable('changeUserStatus') && <Button className={'text-danger'} onClick={() => handleDeny(dataOne)}> Deny</Button>}
            <Button onClick={(e) => handleDetail(dataOne._id)} className={'MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary text-primary MuiButton-outlinedPrimary'} >
              Details
            </Button>
          </ButtonGroup>
          :
          (dataOne.user_status === "suspend" || dataOne.user_status === "blocked") ?
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              {isButtonAvailable('changeUserStatus') && <Button className={'text-success'} onClick={() => handleActivate(dataOne)}>
                Activate
              </Button>}
            </ButtonGroup>
            :
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              {isButtonAvailable('changeUserStatus') && <Button className={'text-danger'} onClick={() => handleBan(dataOne)}>
                Ban
              </Button>}
              {isButtonAvailable('changeUserStatus') && <Button className={'text-warning'} onClick={(e) => handleSub(dataOne)}>
                Suspend
              </Button>}
              <Button className={'text-primary'} onClick={() => handleDetail(dataOne._id)} >
                Details
              </Button>
            </ButtonGroup>
    })
  })

  const theme = createTheme({
    components: {
      MUIDataTableFilter: {
        styleOverrides: {
          reset: {
            display: "none"
          }
        }
      }
    }
  });

  const { loader } = state;
  return (
    <AdminContainer>
      <React.Fragment>
        <ContainerHeader match={props.match} title={"Sellers"} title1="selleronly" />
        {props.loading ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}
        {
          props.loading === true ?
            null :
            <ThemeProvider theme={theme}>
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
        }
        <Dialog open={state.deny} onClose={handleRequestClose} fullWidth={true}
          maxWidth={'sm'}>
          <DialogTitle>
            Deny Seller
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {'Are you sure you want to deny this user ?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color='secondary' >
              Cancel
            </Button>
            <Button color='primary' onClick={handleDenied} >
              Deny
            </Button>
          </DialogActions>
        </Dialog>
        {/* ------------------------------------------------------------------------- */}
        <Dialog open={state.delete}
          onClose={handleRequestClose}
          fullWidth={true}
          maxWidth={'sm'}>
          <DialogTitle>
            {state.delete ? 'Suspend Seller' : ''}
            {state.delete ? <DialogContentText className='mt-3'>
              Are you sure, you want to suspend this seller ?
            </DialogContentText> : ''}
          </DialogTitle>
          <DialogContent>
            <form noValidate autoComplete="off">
              {state.delete ?
                <FormGroup>
                  <Label for="selectedDate">Date</Label>
                  <Input type="date" name="selectedDate" min={moment().format('YYYY-MM-DD')} value={selectedDate} id="selectedDate" onChange={(e) => handleDateChange(e, 'selectedDate')} />
                </FormGroup>
                : ''
              }
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color='secondary' >
              Cancel
            </Button>
            <Button onClick={handleSuspend} color='primary'>
              {state.delete ? 'Suspend' : ''}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </AdminContainer>
  );
}

const mapStateToProps = ({ admin }) => {
  const { buyerSellerList, getUserApprove, changeUserStatus, actionList, loading } = admin;
  return { buyerSellerList, getUserApprove, changeUserStatus, actionList, loading }
};

export default connect(mapStateToProps, { getBuyerSellerList, getBuyerSellerListAdmin, getUserApprove, getChangeUserStatus })(StaffList);