import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { getOrderDetails, getOrderList } from 'actions/admin';
import { FormGroup, Col, Row, Label } from 'reactstrap';
import moment from 'moment';
import ReactStrapTextField from '../../components/ReactStrapTextField';
import ReactstrapSelectField from '../../components/reactstrapSelectField';
import TextField from '@mui/material/TextField';
import { Field, Form } from 'react-final-form'
import { required, number, trackingVal, prepaidOrder, requiredddd } from '../../../../constants/validations';
import axios from '../../../../constants/axios';
import { NotificationManager } from 'react-notifications';
import InvoiceValidate from '../routes/InvoiceValidate';
import { useLocation, useNavigate, useParams } from 'react-router';

function ChangeStatusAdmin(props) {
  const { id } = useParams()
  const history = useNavigate()
  const location = useLocation()
  const [state, setState] = useState({
    open: false,
    counter: 0,
    deliDate: '',
    showOTP: '',
    showEmail: ''
  });

  useEffect(() => {
    props.getOrderDetails({ history: history, data: { orderId: id } })
  }, [])

  const handleRequestClose = () => {
    setState((prev) => ({ ...prev, open: false, deliDate: '', showOTP: '', showEmail: ''}));
  };

  const handleChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
  }

  const onSubmit = async (values) => {
    let data = {
      invoice: Number(values.invoice),
      weight: values.weight,
      invoiceNumber: values.invoiceNumber,
      selectedDate: moment().format(),
      deliveryDate: state.deliDate ? state.deliDate : moment().format(),
      orderId: props.orderId !== undefined ? props.orderId : id,
      status: values.orderStatus,
      length: values.length,
      breadth: values.breadth,
      height: values.height,
      uploadedInvoice: values.uploadedInvoice,
      docket: values.uploadedDocket,
      trackingUrl: values.tracking ? values.tracking : '',
      paymentType: values.type === 'Bulk Prepaid' ? 'Online' : values.type,
      awbNo: values.awbNo ? values.awbNo : '',
      otp: Number(values.otp),
      isBulk: values.type === 'Bulk Prepaid' ? true : false,
    }
    await axios.post('/order/adminProcessOrder', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
        setState((prev) => ({ ...prev, counter: state.counter + 1 }))
      } else {
        NotificationManager.success(result.data.title);
        props.getOrderDetails({ history: history, data: { orderId: props.orderId !== undefined ? props.orderId : id } })
        setState((prev) => ({ ...prev, counter: 0, open: false, showOTP: '', showEmail: '' }))
      }
    }).catch(error => {
      console.log("error", error)
      NotificationManager.error('Something went wrong, Please try again')
    });
  }

  const getOtp = async (values) => {
    let data = {
      type: 'Order',
      action: (values.orderStatus === 'Processed') ? 'Process order' : (values.orderStatus === 'Cancelled') ? 'Cancel order' : (values.orderStatus === 'LOST') ? 'LOST order' : (values.orderStatus === 'RTO') ? 'RTO order' : (values.orderStatus === 'RTO after Delivery') ? 'RTO after Delivery order' : 'Modify order',
      counter: Number(state.counter),
      typeId: props.orderDetails._id
    }
    axios.post(`/systemActivity/addActivity`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then((result) => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title);
        setState((prev) => ({ ...prev, showOTP: result?.data?.otp, showEmail: result?.data?.email }))
      }
    })
  }

  let { orderDetails } = props;

  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  return (
    <div>
      {
        location.pathname.includes('orderDetails') ? <Button className={'jr-btn-xs'} variant="contained" color="primary" onClick={() => setState((prev) => ({ ...prev, open: true }))}>{'Modify Order'}</Button> : ''}
      <Dialog open={state.open} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'sm'}>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            length: orderDetails.length ? orderDetails.length : '25',
            breadth: orderDetails.breadth ? orderDetails.breadth : '25',
            height: orderDetails.height ? orderDetails.height : '25',
            invoice: orderDetails.total_amount,
            uploadedInvoice: orderDetails.uploadedInvoice ? orderDetails.uploadedInvoice : '',
            uploadedDocket: orderDetails.uploadedDocket ? orderDetails.uploadedDocket : '',
            invoice2: orderDetails.total_amount,
            tracking: orderDetails.trackingUrl ? orderDetails.trackingUrl : '',
            type: orderDetails.paymentType ? orderDetails.paymentType.toLowerCase() === 'bnpl' ? 'BNPL' : (orderDetails.paymentType === 'Online' && orderDetails.isBulk) ? 'Bulk Prepaid' : orderDetails.paymentType : '',
            invoiceNumber: orderDetails?.seller_invoice ? orderDetails?.seller_invoice : '',
            awbNo: orderDetails.shipping_awb ? orderDetails.shipping_awb : '',
            weight: orderDetails.weight ? orderDetails.weight : '',
            orderStatus: ''
          }}
          render={({ handleSubmit, values }) => {
            return <form onSubmit={handleSubmit}>
              <DialogTitle className='pb-1'>
                {'Modify Order'}
              </DialogTitle>
              <DialogContent className='pb-0 pt-0'>
                <React.Fragment>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Field id="invoice" name="invoice" type="text"
                          component={ReactStrapTextField} label={"Value of invoice"}
                          validate={orderDetails.paymentType === 'Online' ? composeValidators(number, required, prepaidOrder) : composeValidators(number, required)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Field id="invoiceNumber" name="invoiceNumber" type="text"
                          component={ReactStrapTextField} label="Invoice Number"
                          validate={(values.orderStatus === 'Cancelled') ? '' : composeValidators(required, requiredddd)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Field id="length" name="length" type="text"
                          component={ReactStrapTextField} label="Length (cm)"
                          validate={required}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Field id="breadth" name="breadth" type="text"
                          component={ReactStrapTextField} label="Breadth (cm)"
                          validate={required}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Field id="height" name="height" type="text"
                          component={ReactStrapTextField} label="Height (cm)"
                          validate={required}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Field
                          name="weight"
                          component={ReactstrapSelectField}
                          label="Weight" id="weight"
                          validate={(values.orderStatus === 'Cancelled') ? '' : required}
                          type="select"
                        >
                          <option value=" ">Select weight</option>
                          <option value="0.5">0.5 kg</option>
                          <option value="1">1.0 kg</option>
                          <option value="1.5">1.5 kg</option>
                          <option value="2">2.0 kg</option>
                          <option value="2.5">2.5 kg</option>
                          <option value="3">3.0 kg</option>
                          <option value="3.5">3.5 kg</option>
                          <option value="4">4.0 kg</option>
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Field
                          name="type"
                          id={"type"}
                          component={ReactstrapSelectField}
                          label="Payment Method"
                          validate={required}
                          type="select"
                        >
                          <option value="Bulk Prepaid">Bulk Prepaid</option>
                          <option value="BNPL">BNPL</option>
                          <option value="COD">COD</option>
                          <option value="Online">Prepaid</option>
                        </Field>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Field id="awbNo" name="awbNo" type="text"
                          component={ReactStrapTextField} label="AWB number"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Field id="tracking" name="tracking" type="text"
                          component={ReactStrapTextField} label="Tracking URL"
                          validate={trackingVal}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Field
                          name="orderStatus"
                          component={ReactstrapSelectField}
                          label="Status" id="orderStatus"
                          validate={required}
                          type="select"
                        >
                          <option value="">Select Status</option>
                          <option value="Processed">Processed</option>
                          <option value="Delivered">Delivered</option>
                          <option value="LOST">LOST</option>
                          <option value="RTO">RTO</option>
                          <option value="RTO after Delivery">RTO after Delivery</option>
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label >{
                          values.orderStatus === 'Delivered' ? 'Delivery Date' :
                            values.orderStatus === 'Processed' ? 'Processed Date' :
                              values.orderStatus === 'Cancelled' ? 'Cancelled Date' : 'Date'
                        }</Label><br />
                        <TextField
                          onChange={(date) => handleChange(date, 'deliDate')}
                          name='deliDate'
                          id="deliDate"
                          fullWidth
                          variant='standard'
                          type="date"
                          error={values.orderStatus === 'Delivered' && !values.deliDate ? true : false}
                          required={values.orderStatus === 'Delivered' ? true : false}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ paddingTop: '19px' }}>
                      <Button onClick={() => getOtp(values)} color='primary' variant="outlined" fullWidth={true} disabled={(values.invoiceNumber && values.weight && values.orderStatus && values.uploadedInvoice) ? false : true}>
                        Generate OTP
                      </Button>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                      <FormGroup>
                        <Label className='p-0 m-0'>Enter OTP</Label>
                        <Field id="otp" name="otp" type="text"
                          component={ReactStrapTextField}
                          validate={values.orderStatus === "Processed" || values.orderStatus === "Delivered" || values.orderStatus === "RTO" || values.orderStatus === "LOST" || values.orderStatus === "RTO after Delivery" ? composeValidators(number, required) : ''} label={"Insert OTP"}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {state.open && state.showOTP &&
                      <div className='text-start'>OTP <span className='underline'>{state.showOTP}</span> has been sent to {state.showEmail}</div>
                    }
                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      <FormGroup>
                        <Field
                          name="uploadedInvoice"
                          component={InvoiceValidate}
                          validate={(orderDetails.requested === 'Processed' || values.orderStatus === 'Cancelled') ? '' : required}
                          label="Upload Invoice"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Field
                          name="uploadedDocket"
                          component={InvoiceValidate}
                          label="Upload Docket"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </React.Fragment>
              </DialogContent>
              <DialogActions className='pt-0'>
                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                <Button type="submit" color='primary'>
                  {'Modify'}
                </Button>
              </DialogActions>
            </form>
          }}
        />
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ admin }) => {
  const { orderDetails } = admin;
  return { orderDetails }
};

ChangeStatusAdmin = connect(
  mapStateToProps,
  {
    getOrderDetails,
    getOrderList
  }               // bind account loading action creator
)(ChangeStatusAdmin)

export default ChangeStatusAdmin 