import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import { FormGroup, Label, Input } from 'reactstrap';
import moment from 'moment';
import ReactstrapTextField from '../components/ReactStrapTextField';
import { required, number } from 'constants/validations'

function AddInventory(props) {
  const [state, setState] = useState({
    open: false,
    name: '',
    chemicalCombination: '',
    expiryDate: moment(new Date()).format('YYYY-MM-DD'),
    MRP: '',
    PTR: '',
    minimumOrderQty: '',
    maximumOrderQty: '',
    GST: '',
    type: '',
    productCategory: '',
    discount: '',
    percentOff: 50,
    buy: 1,
    get: 1,
    getProduct: '',
    companyName: '',
    totalQuantity: '',
    netAmount: '',
    paymentDate: new Date(),
    tds: '',
    tcs: '',
    netAmount1: ''
  });

  const handleRequestClose = () => {
    setState({ open: false });
    props.buttonType === 'edit' ? props.handleClick('edit') : props.handleClick('view')

  }
  const totalAmountFormula = (tds, tcs, commission = 0) => {
    console.log('ooooo', commission, typeof commission)
    return (Number(state.netAmount1) - Number(Number(state.tcs) +
      Number(state.tds) + Number(state.commission)))
  }
  const handleChange = (e, key) => {
    console.log('key', e.target.value, key, e.target.value == null, typeof key);
    setState({ [key]: e.target.value });
  }

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      netAmount: props.dataFromParent ? props.dataFromParent.net_amt : state.netAmount,
      netAmount1: props.dataFromParent && props.dataFromParent.net_amt
    }))
  }, [])

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      netAmount: props.dataFromParent ? props.dataFromParent.net_amt : state.netAmount,
      paymentDate: moment().toDate(),
      remark: props.dataFromParent ? props.dataFromParent.remark : '',
      transactionId: props.dataFromParent ? props.dataFromParent.transaction_id : '',
      tcs: props.dataFromParent ? (props.dataFromParent.tcs) : state.tcs,
      tds: props.dataFromParent ? (props.dataFromParent.tds) : state.tds,
      netAmount1: props.dataFromParent && props.dataFromParent.order_value,
      commission: props.dataFromParent ? (props.dataFromParent.commission) : state.commission,
      status: props.dataFromParent ? props.dataFromParent.status : state.status,
      logisticDate: props.dataFromParent && props.dataFromParent.logistic_settlement_date ? moment(props.dataFromParent.logistic_settlement_date).format("DD/MM/YYYY") : ''
    }));
  }, [props.dataFromParent])

  const { tds, tcs, status, commission, logisticDate } = state;
  const { dataFromParent } = props;
  let data = [];
  let data1 = []
  dataFromParent && dataFromParent.order && dataFromParent.order.order_status.map((dataOne) => {
    if (dataOne.status === 'Delivered') {
      data.push([
        data1 = dataOne.date
      ])
    }
  })

  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  return (
    <React.Fragment>
      <Dialog open={props.view} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle style={{ marginBottom: '15px' }}>
          {dataFromParent.seller ? dataFromParent.seller.company_name : ''}
        </DialogTitle>
        <DialogContent>
          <Row>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="orderid">Order ID</Label>
                <Input type="text" name="orderid" id="orderid" value={dataFromParent && dataFromParent.order.order_id}
                  label={'Order ID'} readOnly
                  component={ReactstrapTextField}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="orderDate">Order Date</Label>
                <Input type="text" name="orderDate" readOnly
                  value={dataFromParent && dataFromParent.order.createdAt}
                  id="orderDate" label={'Order Date'} component={ReactstrapTextField} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="orderValue">Order Value</Label>
                <Input type="text" name="orderValue" id="orderValue" label={'Order Value'} value={dataFromParent && dataFromParent.order_value}
                  component={ReactstrapTextField} readOnly />
              </FormGroup>
            </Col>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="paymentDueDate">Delivery Date</Label>
                <Input type="text" name="paymentDueDate" id="paymentDueDate" value={moment(data1).format('DD/MM/YYYY')}
                  component={ReactstrapTextField} readOnly >
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="orderid">TDS</Label>
                <Input type="text" name="tds" id="tds" label={'TDS '} value={tds} onChange={(e) => handleChange(e, 'tds')} validate={composeValidators(required, number)} disabled={true}
                  component={ReactstrapTextField} >
                </Input>
              </FormGroup>
            </Col>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="orderid">TCS</Label>
                <Input type="text" name="tcs" id="tcs" label={'TCS '} value={tcs} onChange={(e) => handleChange(e, 'tcs')} validate={composeValidators(required, number)} disabled={true}
                  component={ReactstrapTextField} >
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="orderid">Commission</Label>
                <Input type="text" name="commission" id="commission" label={'Commission'} value={commission} onChange={(e) => handleChange(e, 'commission')} disabled={true}
                  component={ReactstrapTextField} validate={composeValidators(required, number)} >
                </Input>
              </FormGroup>
            </Col>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="orderid">Status</Label>
                <Input type="text" name="status" id="status" label={'Status'} value={status} onChange={(e) => handleChange(e, 'status')} disabled={true}
                  component={ReactstrapTextField} validate={required} >
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="orderid">Logistic Date</Label>
                <Input type="text" name="logisticDate" id="logisticDate" label={'Logistic Date'} value={logisticDate} onChange={(e) => handleChange(e, 'logisticDate')}
                  component={ReactstrapTextField} disabled={true} >
                </Input>
              </FormGroup>
            </Col>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="netAmount">Net Amount</Label>
                <div style={{ marginTop: '3px' }}>{(totalAmountFormula()).toFixed(2)}</div>
              </FormGroup>
            </Col>
          </Row>
          <DialogActions>
            <Button onClick={handleRequestClose} color='secondary' >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}




export default (AddInventory);