import React, { useEffect, useState } from 'react';
import { Breadcrumb, Input, Label } from 'reactstrap';
import Button from '@mui/material/Button';
import ChangeStatusDailog from 'app/routes/orders/component/changeStatus';
import CancelOrderDailog from '../component/orderCancelDailog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getOrderDetails } from 'actions/admin';
import { connect } from 'react-redux';
import AppConfig from 'constants/config'
import './index.css'
import moment from 'moment';

import RenderReactDatePicker from '../../components/RenderDatePicker/indexForAll';
// import RenderDatePicker from 'app/routes/components/RenderDatePicker';
import renderTextField from "../../../../components/textBox"
import { Field, Form } from 'react-final-form'
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { Col, Row, Badge, FormGroup, } from 'reactstrap';
import helper from 'constants/helperFunction';
import { Switch } from '@mui/material';
import AxiosRequest from 'sagas/axiosRequest';
import { useLocation, useNavigate, useParams } from 'react-router';
function OrderDetailsHeader(props) {
	const history = useNavigate()
	const { id } = useParams()
	const location = useLocation()

	const [state, setState] = useState({
		open: false,
		title: '',
		productData: [],
		openProcess: false,
		selectedDate: '',
		length: Number,
		breadth: Number,
		height: Number,
		uploadedInvoice: '',
		weight: '',
		invoiceNumber: '',
		acceptButton: false,
		newDetail: '',
		openStatus: false,
		isOnHold: false,
		isPopupForOnHold: false,
		selected_date: null,
		source: '',
		order_IDD: ''
	})

	useEffect(() => {
		setState((prev) => ({ ...prev, productData: props.updatedProduct }));
		disableChangeRequest()
		setState((prev) => ({ ...prev, isOnHold: props.orderDetails.requested == 'OnHold' ? true : false, acceptButton: props.orderDetails && props.orderDetails.requested === 'Requested' ? true : false }))
	}, [props.updatedProduct, props.orderDetails])


	const handleChange = (e, key) => {
		setState((prev) => ({ ...prev, [key]: e.target.value }));
	}

	const handleRequestClose = () => {
		setState((prev) => ({ ...prev, open: false, openStatus: false, isPopupForOnHold: false }));
	};
	const handleDateChange = (e, key) => {
		setState((prev) => ({ ...prev, [key]: moment(e).format() }));
	}

	const handleSubmitRequest = (e) => {
		e.preventDefault();
		props.updateOrder({ history: history, data: { products: state.productData, orderId: id, status: 'Requested' } });
		setState((prev) => ({ ...prev, open: false }));
		props.getOrderDetails({ history: history, data: { orderId: id } })

	}

	const disableChangeRequest = () => {
		let { status } = props
		if (props.orderDetails && props.orderDetails.user_id && props.orderDetails.user_id.user_status !== 'active') {
			return true
		}
		else if (status === 'Accepted') {
			return true
		}
		else if ((status === 'Requested' || status === 'Approved' || status === 'New' || status === 'Processed' || status === "Cancelled" || status === "Delivered")) {
			return true
		}
		else {
			return false
		}
	}
	const redirectTo = (e) => {
		if (e !== '' && (e.uploadedInvoice !== "" && e.uploadedInvoice.includes("https://s3.ap-south-1.amazonaws.com"))) {
			window.open(`${e.uploadedInvoice}`, '_blank');
		} else {
			window.open(`${AppConfig.baseUrl}invoice/${e.uploadedInvoice}`, '_blank');
		}
	}

	const acceptRequest = async (e) => {
		let data = {
			orderId: e.order_id,
			acceptFromBuyer: 'accept',
			status: "accept"
		}
		await axios.post(`${AppConfig.baseUrl}order/updateOrder`, data, {
			headers: {
				'Content-Type': 'application/json',
				'token': localStorage.getItem('admin_token')
			}
		}).then(result => {
			if (result.data.error) {
				console.log("error", result.data)
			} else {
				NotificationManager.success(result.data.title);
				setState((prev) => ({ ...prev, newDetail: result.data.detail, acceptButton: false }))
			}
		})
			.catch(error => {
				console.log("error catch", error)
			});
	}
	const rejectRequest = async (e) => {
		let data = {
			orderId: e.order_id,
			status: "Cancelled",
			reason: "Cancelled from admin"
		}
		await axios.post(`${AppConfig.baseUrl}order/updateOrder`, data, {
			headers: {
				'Content-Type': 'application/json',
				'token': localStorage.getItem('admin_token')
			}
		}).then(result => {
			if (result.data.error) {
				console.log("error", result.data)
			} else {
				NotificationManager.success(result.data.title);
				setState((prev) => ({ ...prev, newDetail: result.data.detail, acceptButton: false }))
			}
		})
			.catch(error => {
				console.log("error catch", error)
			});
	}
	const isButtonAvailable = (key) => {
		let index = props.actionList.findIndex((val) => val == key)
		return index > -1 ? true : false
	}
	const settle = async (data) => {
		await axios.get(`${AppConfig.baseUrl}settlements/settleIndividualOrder?id=${data._id}`, {
			headers: {
				'Content-Type': 'application/json',
				'token': localStorage.getItem('admin_token')
			}
		}).then(result => {
			if (result.data.error) {
				NotificationManager.error(result.data.title);
			} else {

				NotificationManager.success(result.data.title);
			}
		}).catch(error => {
			console.log("error catch", error)
		});
	}
	const setOrderAsNew = async () => {
		handleRequestClose();
		let orderId = props.orderDetails.unique_invoice
		await axios.get(`${AppConfig.baseUrl}order/markOrderNew?uniqueIn=${orderId}&status=new`, {
			headers: {
				'Content-Type': 'application/json',
				'token': localStorage.getItem('admin_token')
			}
		}).then(result => {
			if (result.data.error) {
				NotificationManager.error(result.data.title);
			} else {
				NotificationManager.success(result.data.title);
				hitApi();
			}
		}).catch(error => {
			console.log("error catch", error)
		});
	}
	const setOrderAsCancel = async () => {
		handleRequestClose();
		let orderId = props.orderDetails.unique_invoice
		await axios.get(`${AppConfig.baseUrl}order/markOrderNew?uniqueIn=${orderId}&status=cancel`, {
			headers: {
				'Content-Type': 'application/json',
				'token': localStorage.getItem('admin_token')
			}
		}).then(result => {
			if (result.data.error) {
				NotificationManager.error(result.data.title);
			} else {
				NotificationManager.success(result.data.title);
				hitApi();
			}
		}).catch(error => {
			console.log("error catch", error)
		});
	}

	const hitApi = async () => {
		await props.getOrderDetails({ history: history, data: { orderId: id } })
	}

	const onhold = async (e, idd, orderDetails) => {
		if (orderDetails.requested == "OnHold" && props.isSuperAdmin == ('admin' || "Finance")) {
			setState((prev) => ({ ...prev, isPopupForOnHold: true, order_IDD: idd }))
		} else if (orderDetails.requested != "OnHold") {
			let status = !state.isOnHold ? "OnHold" : "New";
			let result = await AxiosRequest.axiosHelperFunc('post', 'order/onHold', '', { id: idd, status });
			if (result.data.error) {
				NotificationManager.error(result.data.title);
			} else {
				await props.getOrderDetails({ history: history, data: { orderId: id } })
				NotificationManager.success(result.data.title);
			}
		}
		else {
			NotificationManager.error("Unable to change since you are not permitted for change.");
		}
	}
	const RemovingOnHold = async (idd) => {
		let status = !state.isOnHold ? "OnHold" : "New";
		let result = await AxiosRequest.axiosHelperFunc('post', 'order/onHold', '', { id: idd, status });
		if (result.data.error) {
			NotificationManager.error(result.data.title);
		} else {
			props.getOrderDetails({ history: history, data: { orderId: id } })
			NotificationManager.success(result.data.title);
		}
	}
	const handleSourceLogisticUpdateData = async (values) => {
		let data = {
			orderId: state.order_IDD,
			logistic_date: values.selected_date,
			source: state.source
		}
		await axios.post(`${AppConfig.baseUrl}order/sourceLogisticUpdateData`, data, {
			headers: {
				'Content-Type': 'application/json',
				'token': localStorage.getItem('admin_token')
			}
		}).then(async (result) => {
			if (result.data.error) {
				console.log("error41651651651651651651651651652222222222", result)
			} else {
				NotificationManager.success(result.data.title);
				RemovingOnHold(state.order_IDD)
				handleRequestClose()
			}
		})
			.catch(error => {
				console.log("error catch", error)
			});
	}

	const path = location.pathname.substr(1);
	const subPath = path.split('/');
	let { orderDetails } = props;

	return (
		<div className="page-heading d-sm-flex  justify-content-sm-between align-items-sm-center"
			style={{ position: 'relative' }}>
			<span style={{ display: 'flex' }}>
				<span><h2 className="title mb-3 mb-sm-0">Order Detail</h2></span>&nbsp;&nbsp;
				<span className="badge text-uppercase text-white" style={{ backgroundColor: `${helper.getProdColor(orderDetails.orderType)}` }}>
					{orderDetails.orderType ? orderDetails.orderType : 'N/A'}
				</span>
			</span>

			<Breadcrumb className="mb-0" tag="nav">
				{subPath.map((sub, index) => {
					if (sub === 'orderDetails') {
						return (
							<div className='d-flex' >
								{(orderDetails.requested == 'New' || orderDetails.requested == 'OnHold') &&
									<div
										style={{ fontWeight: 600, fontSize: '15px', textAlignLast: 'right' }}
									> ON HOLDs
										<Switch
											color='primary'
											checked={state.isOnHold}
											onChange={(e) => onhold(e, orderDetails._id, orderDetails)}
											aria-label="onhold"
											className="codToggle"
										/>
									</div>
								}
								{state.acceptButton === true ? isButtonAvailable('updateOrder') &&
									<React.Fragment>
										<Col>
											<Button variant="outlined" className={'text-danger'} style={{ padding: '2px 15px' }}
												onClick={(e) => rejectRequest(orderDetails)}>
												Reject
											</Button>
										</Col>
										<Col>
											<Button variant="outlined" className={'text-success'} style={{ padding: '2px 15px' }}
												onClick={(e) => acceptRequest(orderDetails)}>
												Accept
											</Button>
										</Col></React.Fragment> : ''}
								{props.orderDetails && props.orderDetails.uploadedInvoice ?
									<Col>
										<Button variant="outlined" className={'text-primary'} style={{ padding: '2px 15px' }}
											onClick={(e) => redirectTo(orderDetails)}>
											Download
										</Button>
									</Col> : ''}
								{state.acceptButton === false ? isButtonAvailable('adminProcessOrder') &&
									<Col className="text-nowrap p-0 mr-2 col">
										<ChangeStatusDailog orderDetails={props.orderDetails} status={props.status}
											color='primary' />
									</Col> : ''}
								{state.acceptButton === false && props.orderDetails.requested !== 'Delivered' ? isButtonAvailable('proccessCancelOrder') &&
									<Col className='text-nowrap p-0 col'>
										<CancelOrderDailog orderDetails={props.orderDetails} status={props.status}
											color='primary' />
									</Col> : ''}
								{props.orderDetails.requested == 'Delivered' && isButtonAvailable('settleIndividualOrder') && <Col className='text-nowrap p-0 col'>
									<Button variant="outlined" className={'text-success'} style={{ padding: '2px 15px' }}
										onClick={(e) => settle(orderDetails)}>
										Settle
									</Button>
								</Col>}
								{props.orderDetails?.paymentStatus == 'pending' && props.orderDetails?.paymentType == 'Online' && <Col className='text-nowrap p-0 pl-2 col'>
									<Button className={'jr-btn-xs ml-1'} variant="contained" color="primary" style={{ padding: '2px 15px' }} onClick={(e) => setState((prev) => ({ ...prev, openStatus: true }))}>
										Status
									</Button>
								</Col>}
							</div>
						)
					}
				}
				)}
			</Breadcrumb>

			<Dialog open={state.open} onClose={handleRequestClose}
				fullWidth={true}
				maxWidth={'sm'}>
				<DialogTitle>
					Change Request
					<DialogContentText className="mt-3">
						Are you sure you want to change the request ?
					</DialogContentText>
				</DialogTitle>
				<DialogContent>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleRequestClose} color='secondary' >
						No
					</Button>
					<Button onClick={(e) => handleSubmitRequest(e)} color='primary'>
						Yes
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={state.openStatus} onClose={handleRequestClose} maxWidth={'xs'}>
				<DialogTitle>
					Mark order as:
				</DialogTitle>
				<DialogContent>
					<Row>
						<Col>
							<Button variant="outlined" className={'text-success'} onClick={setOrderAsNew}>
								New
							</Button>
						</Col>
						<Col>
							<Button variant="outlined" className={'text-danger'} onClick={setOrderAsCancel}>
								Cancel
							</Button>
						</Col>
					</Row>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleRequestClose} color='secondary' >Close</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={state.isPopupForOnHold}
				onClose={handleRequestClose}
				maxWidth={'md'}

			>
				<Form
					onSubmit={handleSourceLogisticUpdateData}
					initialValues={{
						selected_date: null
					}}
					render={({ handleSubmit }) => {
						return <form onSubmit={handleSubmit} autoComplete="off">
							<DialogTitle>
								Change Request
								<DialogContent className='pb-0 pt-0'>
								</DialogContent>
							</DialogTitle>
							<DialogContent>
								<React.Fragment>
									<Row >
										<FormGroup>
											<Field id="selected_date" name="selected_date" type="text"
												component={RenderReactDatePicker}
												label="Date"
												value={state.selected_date}
												onChange={(date) => handleDateChange(date, 'selected_date')}
											/>
										</FormGroup>
									</Row>
									<Row>
										<FormGroup>
											<Label>
												Source
											</Label>
											<Input label='Source' type='textarea' name='source'
												onChange={(e) => handleChange(e, 'source')}
												value={state.source}
												component={renderTextField}
												id="source"
											/>
										</FormGroup>
									</Row>
								</React.Fragment>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleRequestClose} color='secondary' >
									No
								</Button>
								<Button
									type='submit'
									color='primary'>
									Yes
								</Button>
							</DialogActions>
						</form>
					}} />

			</Dialog>
		</div >
	)
}

const mapStateToProps = ({ admin }) => {
	const { actionList, orderDetails } = admin;
	return { actionList, orderDetails }
}
OrderDetailsHeader = connect(mapStateToProps, { getOrderDetails })(OrderDetailsHeader);

export default OrderDetailsHeader