import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'constants/axios';
import { Col, Row } from 'reactstrap';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { NotificationManager } from 'react-notifications'
import ButtonGroup from '@mui/material/ButtonGroup';
import Autocomplete from '@mui/lab/Autocomplete';
import { TextField } from '@mui/material';
import NotifyPopup from './NotifyPopup';
import SearchBar from "components/New Filter/SearchBar";
import DatePickerForShortbook from "components/New Filter/DatePicker";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

const ShortbookList = (props) => {
  const perPage = 50
  const [loader, setLoader] = useState(false)
  const [buyer, setBuyer] = useState('')
  const [shortBook, setShortBook] = useState('')
  const [page, setPage] = useState(0)
  const [buyerFilters, setBuyerFilters] = useState('')
  const [sellerFilters, setSellerFilters] = useState([])
  const [count, setCount] = useState(0)
  const [notifyPopupOpen, setNotifyPopupOpen] = useState(false)
  const [buyerData, setBuyerData] = useState({})
  const [from, setFrom] = useState(moment().subtract(1, 'months').format())
  const [to, setTo] = useState(moment().format())
  const [userType, setUserType] = useState('')
  const [searchText, setSearchText] = useState('')

  const getShortbook = async () => {
    setLoader(true)
    if (props.tab === 0) {
      let data = {
        buyer: buyer,
        page: page + 1,
        perPage: perPage,
        from_date: from,
        to_date: to,
        tab: 'open',
        searchText: searchText
      }
      await axios.post('/shortBook/listShortBook', data, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('admin_token')
        }
      }).then(result => {
        if (result.data.error) {
          NotificationManager.error('Something went wrong, Please try again');
        } else {
          setLoader(false)
          setShortBook(result.data.result[0].data)
          setCount(result.data.result[0].metadata[0] && result.data.result[0].metadata[0].total ? result.data.result[0].metadata[0].total : 0)
        }
      }).catch(error => {
        NotificationManager.error('Something went wrong, Please try again')
      });
    }
  }

  const handleNotifyClicked = (data, user) => {
    setNotifyPopupOpen(true)
    setBuyerData(data)
    setUserType(user)
  }

  const handleClose = () => {
    setNotifyPopupOpen(false)
    setBuyerData({})
    setUserType('')
  }

  const getFilterData = async () => {
    if (props.tab === 0) {
      await axios.post('/shortBook/getShortbookFilters', {}, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('admin_token')
        }
      }).then(result => {
        if (result.data.error) {
          NotificationManager.error('Something went wrong, Please try again');
        } else {
          setBuyerFilters(result.data.buyers)
          setSellerFilters(result.data.sellers)
        }
      }).catch(error => {
        NotificationManager.error('Something went wrong, Please try again')
      });
    }
  }

  useEffect(() => {
    getShortbook()
    getFilterData()
  }, [])

  const statusStyle = (status) => {
    return status.includes("ACTIVE") ? "text-primary" : status.includes("FULFILLED") ? "text-success" : status.includes("IN-STOCK") ? "text-danger" : "text-info";
  }

  const applyFilter = async () => {
    setPage(0)
    getShortbook()
  }

  const handleBuyerChange = (e, buyer) => {
    if (buyer && buyer._id) {
      setBuyer(buyer._id)
    }
    else {
      setBuyer('')
    }
  }

  const changePage = async (page) => {
    setPage(page)
    getShortbook()
  }

  const handleDateChange = (date, key) => {
    if (key === "from") {
      setFrom(moment(date).format())
    } else {
      setTo(moment(date).format())
    }
  }

  const handleSearch = async (searchText) => {
    setSearchText(searchText)
  };

  const columns = [
    {
      name: "buyer",
      label: "Buyer",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "product",
      label: "Product",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "company",
      label: "Company",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "quantity",
      label: "Quantity",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "notify",
      label: "Notify",
      options: {
        filter: true,
        sort: false,
      }
    }
  ];

  let data = [];
  shortBook && shortBook.map(dataOne => {
    let status = dataOne.isFulfilled ? 'FULFILLED' : dataOne.inStock ? 'IN-STOCK' : 'ACTIVE'
    data.push({
      buyer: <div>{dataOne.User.company_name}</div>,
      product: <div>{dataOne.product_name}</div>,
      company: <div>{dataOne.Company ? dataOne.Company.name : 'N/A'}</div>,
      quantity: <div>{dataOne.quantity}</div>,
      date:
        <div style={{ margin: 0, padding: 0 }}>
          <span>{moment(dataOne.createdAt).format('DD/MM/YY')}</span>
          <p style={{ margin: 0, padding: 0 }}>{moment(dataOne.createdAt).format('HH:mm A')}</p>
        </div>,
      status: <div key={'recent'} className={` badge text-uppercase ${statusStyle(status)}`}>{status}</div>,
      notify: <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button variant="outlined" onClick={(e) => handleNotifyClicked(dataOne, 'Buyer')} className={'text-warning'}>
          B
        </Button>
        <Button variant="outlined" onClick={(e) => handleNotifyClicked(dataOne, 'Seller')} className={'text-success'}>
          S
        </Button>
      </ButtonGroup>
    })
  })
  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: false,
    selectableRows: false,
    rowsPerPage: perPage ? perPage : 50,
    page: page,
    fixedHeader: false,
    print: false,
    download: false,
    filter: false,
    sort: false,
    count: count,
    serverSide: true,
    server: true,
    selectableRowsOnClick: false,
    search: false,
    rangePage: true,
    rowsPerPageOptions: [],
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  return (
    <React.Fragment>
      <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
        {loader ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}
        {
          loader ?
            null :
            <MUIDataTable
              title={
                <div className="header d-flex">
                  <div className="Row-header d-flex w-100" style={{ marginTop: '-9px' }}>
                    <div style={{ marginRight: '20px', marginTop: '9px' }}>
                      <SearchBar handleSearch={handleSearch} searchText={searchText} placeholder="Search" />
                    </div>
                    <div style={{ marginRight: '20px' }}>
                      <DatePickerForShortbook state='from' dateValue={from} handleDateChange={handleDateChange} label={"From"} />
                    </div>
                    <div style={{ marginRight: '20px' }}>
                      <DatePickerForShortbook state="to" label={"To"} dateValue={to} handleDateChange={handleDateChange} />
                    </div>
                    <div style={{ marginRight: '20px', marginTop: '5px', width: window.innerWidth > 800 ? '40%' : '100%' }}>
                      <FormControl className="w-100 mb-2 ">
                        <Autocomplete
                          options={['Select Buyer', ...buyerFilters]}
                          id="auto-complete"
                          defaultValue={buyer && buyer !== '' && buyer !== 'Select Buyer' ? buyerFilters.find(v => v._id === buyer) : 'Select Buyer'}
                          getOptionLabel={(option) => { return option && option === 'Select Buyer' ? 'Select Buyer' : option ? option.company_name : 'Select Buyer' }}
                          autoComplete
                          onChange={(e, option) => handleBuyerChange(e, option)}
                          includeInputInList
                          renderInput={(params) => <TextField style={{ padding: '2.5px 4px' }} variant="outlined" id="outlined-required"  {...params}
                            name="buyer"
                          />}
                        />
                      </FormControl>
                    </div>
                    <div style={{ marginTop: '9px' }}>
                      <Button style={{ paddingTop: '9px', paddingBottom: '9px' }} onClick={() => applyFilter()} variant="contained" className='filterButton' color='primary'>Search</Button>
                    </div>
                  </div>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
              style={{ borderRadius: '0px !important' }}
            />
        }
      </div>
      <NotifyPopup
        open={notifyPopupOpen}
        onClose={handleClose}
        data={buyerData}
        userType={userType}
        sellers={sellerFilters}
      />
    </React.Fragment>
  );
}

export default ShortbookList;

