import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import customHead from 'constants/customHead'
import { connect } from 'react-redux'
import { getProductRequestList, getApproveProductRequestList } from 'actions'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ViewProduct from './viewProductRequest'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

const columns = [
  {
    name: "seller",
    label: "Seller",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "title",
    label: "Product Name",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "company",
    label: "Company",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "requestedAt",
    label: "Request Date",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },

  {
    name: "status",
    label: "Status",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  }
];

function ProductRequest(props) {
  const [state, setState] = useState({
    add: false,
    view: false,
    value: '',
    viewData: '',
    loader: false,
    page: 0,
    perPage: 50,
    searchedText: ''
  })

  async function fetchApi() {
    if (props.tab === 1) {
      await props.getProductRequestList({ page: 1, perPage: 50 })
    }
  }

  useEffect(() => {
    fetchApi()
  }, [])

  function handleChange(dataOne) {
    setState((prev) => ({
      ...prev,
      view: true,
      value: dataOne,
    }))
  }
  const handleChanged = (data) => {
    props.getApproveProductRequestList({ productRequest_id: data._id, action: 'approved' })

  }
  const handleChanges = (data) => {
    props.getApproveProductRequestList({ productRequest_id: data._id, action: 'declined' })

  }
  const handleClose = () => {
    setState((prev) => ({ ...prev, view: !state.view }))
  }
  const changePage = (page) => {
    props.getProductRequestList({
      page: page + 1,
      perPage: state.perPage,
      searchText: state.searchedText ? state.searchedText : '',
    })
    setState((prev) => ({ ...prev, page }))
  };
  const handleSearch = (searchText) => {
    props.getProductRequestList({
      page: 1,
      perPage: 50,
      searchText: searchText
    })
    setState((prev) => ({ ...prev, searchedText: searchText, page: 0 }))
  };

  const { productRequestList } = props;

  const statusStyle = (status) => {
    return status.includes("Pending") ? "text-white bg-grey" : status.includes("Approved") ? "text-white bg-success" : status.includes("Declined") ? "text-white bg-danger" : "text-white bg-grey";
  }

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    filter: false,
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    responsive: 'scrollMaxHeight',
    search: true,
    viewColumns: false,
    rowHover: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [],
    count: productRequestList && productRequestList.metadata.length >= 0 && productRequestList.metadata[0] ? productRequestList.metadata[0].total ? productRequestList.metadata[0].total : 0 : 0,
    serverSide: true,
    server: true,
    page: state.page,
    searchText: state.searchedText,
    fixedHeader: false,
    sort: false,
    rangePage: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;

      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  };

  let data = [];

  productRequestList && productRequestList.data.length > 0 && productRequestList.data.map((dataOne, index) => {
    data.push([
      dataOne.addedBy.company_name,
      dataOne.name,
      dataOne.companyName,
      <div style={{ margin: 0, padding: 0 }}>
        <span>{moment(dataOne.createdAt).format('DD/MM/YY')}</span>
        <p style={{ margin: 0, padding: 0 }}>{moment(dataOne.createdAt).format('HH:mm A')}</p>
      </div>,
      <div className={` badge text-uppercase ${statusStyle(dataOne.status)}`}>{dataOne.status}</div>,
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button className={'text-warning'} onClick={(e) => handleChange(dataOne)}>View</Button>
        <Button className={'text-success'} onClick={(e) => handleChanged(dataOne)}>Accept</Button>
        <Button className={'text-danger'} onClick={(e) => handleChanges(dataOne)}> Reject</Button>
      </ButtonGroup>
    ])
  })

  const { loader } = state;
  return (
    <React.Fragment>
      {loader ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}

      {
        loader ?
          null :
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
          />
      }

      <Dialog open={state.view} onClose={handleClose} fullWidth={true}>
        <DialogTitle>
          View Product Request
        </DialogTitle>
        <DialogContent className="mb-0" style={{ overflow: "hidden" }}>
          <ViewProduct value={state.value} />
        </DialogContent>
        <DialogActions className="pr-4 pt-0">
          <Button type="submit" color='primary' onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


const mapStateToProps = ({ admin }) => {
  const { productRequestList, approveRequestedProducts } = admin;
  return { productRequestList, approveRequestedProducts }
};

export default connect(mapStateToProps, { getProductRequestList, getApproveProductRequestList })(ProductRequest);