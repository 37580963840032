import {
  CHANGE_TAB,
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  tabValue : 0,
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_TAB: {
      return {
        ...state,
        loader: false,
        tabValue : action.tabValue
      }
    }
    default:
      return state;
  }
}
