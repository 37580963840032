import React, { Component, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import { FormGroup, Label, Input, FormText } from 'reactstrap';
import moment from 'moment';
import AxiosRequest from 'sagas/axiosRequest';
import { NotificationManager } from 'react-notifications';

import ReactstrapTextField from '../../components/ReactStrapTextField';
import { getSettlementList } from 'actions/admin';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form'

import { required, number, amt } from 'constants/validations'

function EditSellerSettlement(props) {
  const [state, setState] = useState({
    open: false,
    name: '',
    chemicalCombination: '',
    expiryDate: moment(new Date()).format('YYYY-MM-DD'),
    MRP: '',
    PTR: '',
    minimumOrderQty: '',
    maximumOrderQty: '',
    GST: '',
    type: '',
    productCategory: '',
    discount: '',
    percentOff: 50,
    buy: 1,
    get: 1,
    getProduct: '',
    companyName: '',
    totalQuantity: '',
    netAmount: '',
    paymentDate: new Date(),
    tds: '',
    tcs: '',
    tdsValue: 0,
    commissionValue: 0,
    tcsValue: 0,
    netAmount1: ''
  });

  const handleRequestClose = () => {
    setState((prev) => ({ ...prev, open: false }));
    props.buttonType === 'edit' ? props.handleClick('edit') : props.handleClick('view')

  }
  const totalAmountFormula = (values) => {
    return (Number(values?.netAmount1) - Number(Number(values?.netAmount1) / 100 * Number(state.tcs) +
      Number(values?.netAmount1) / 100 * Number(state.tds) + Number(values?.netAmount1) / 100 * Number(values?.commission)))
  }

  const tdsValueFor = (values) => {
    return Number(values?.netAmount1) / 100 * Number(state.tds)
  }
  const tcsValueFor = (values) => {
    return Number(values?.netAmount1) / 100 * Number(state.tcs)
  }
  const commissionValueFor = (values) => {
    return Number(values?.netAmount1) / 100 * Number(values?.commission)
  }

  const handleChange = (e, key) => {
    console.log('key', e.target.value, key, e.target.value == null, typeof key);
    setState((prev) => ({ ...prev, [key]: e.target.value }));
    let a, b, c;

    console.log('key--', a, b, c)
    console.log('asdmtob--12', state.netAmount, state.netAmount1)
  }
  const onSave = async (values) => {
    console.log('qwdasdasdad')
    props.handleClick('view')
    setState((prev) => ({ ...prev, open: false }));
    let data = {
      settlementId: props.dataFromParent && props.dataFromParent._id,
      net_amt: Number(totalAmountFormula(values).toFixed(2)),
      commission: Number(commissionValueFor(values).toFixed(2)),
      tds: Number(tdsValueFor(values).toFixed(2)),
      tcs: Number(tcsValueFor(values).toFixed(2)),
      orderVal: Number(values?.netAmount1)
    }
    let response = await AxiosRequest.axiosHelperFunc('post', 'settlements/editSettlement', '', data)
    if (response.data.error) {
      NotificationManager.error("Something went wrong")
    } else {
      NotificationManager.success(response.data.title)
      let data = {
        page: 1,
        from_date: props.from ? moment(props.from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        to_date: props.to ? moment(props.to).add(1, 'days').format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD'),
        searchText: props.searchText != '' ? props.searchText : '',
        sellerId: props.match.params.id
      }
      props.getSettlementList({ data })

    }
  }


  useEffect(() => {
    setState((prev) => ({
      ...prev,
      netAmount1: props.dataFromParent && props.dataFromParent.order_value,
      netAmount: props.dataFromParent ? props.dataFromParent.net_amt : state.netAmount,
      paymentDate: moment().toDate(),
      remark: props.dataFromParent ? props.dataFromParent.remark : '',
      transactionId: props.dataFromParent ? props.dataFromParent.transaction_id : '',
      tcs: Number((props.dataFromParent.tcs) / Number(props.dataFromParent.order_value) * 100).toFixed(2),
      tds: Number((props.dataFromParent.tds) / Number(props.dataFromParent.order_value) * 100).toFixed(2),
      tdsValue: props.dataFromParent ? Number(props?.dataFromParent?.tds).toFixed(2) : Number(state.tdsValue).toFixed(2),
      tcsValue: props.dataFromParent ? (props.dataFromParent.tcs).toFixed(2) : Number(state.tcsValue).toFixed(2),
      commissionValue: props.dataFromParent ? (props.dataFromParent.commission).toFixed(2) : Number(state.commissionValue).toFixed(2),
      commission: Number((props.dataFromParent.commission) / Number(props.dataFromParent.order_value) * 100).toFixed(2),
    }));
  }, [props.dataFromParent])


  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)


  const { tds, tcs, commission, tdsValue, tcsValue, commissionValue, netAmount1 } = state;

  const { dataFromParent } = props;

  let data = [];
  let data1 = []
  dataFromParent && dataFromParent.order && dataFromParent.order.order_status.map((dataOne) => {
    if (dataOne.status == 'Delivered') {
      data.push([
        data1 = dataOne.date
      ])
    }
  })

  return (
    <React.Fragment>
      <Dialog open={props.buttonType === 'edit' ? props.edit : props.view} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle style={{ marginBottom: '15px' }}>
          {dataFromParent.seller ? dataFromParent.seller.company_name : ''}
        </DialogTitle>
        <DialogContent>
          <Form
            onSubmit={onSave}
            initialValues={{
              commission: state.commission || 0,
              netAmount1: state.netAmount1 || 0
            }}
            render={({ handleSubmit, values }) => {
              return <form onSubmit={handleSubmit} autoComplete="off">
                <Row>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <FormGroup>
                      <Label for="orderid">Order ID</Label>
                      <Input type="text" name="orderid" id="orderid" value={dataFromParent && dataFromParent.order.order_id}
                        onChange={(e) => handleChange(e, 'orderid')}
                        label={'Order ID'} readOnly
                        component={ReactstrapTextField}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <FormGroup>
                      <Label for="orderDate">Order Date</Label>
                      <Input type="text" name="orderDate" readOnly
                        value={moment(dataFromParent && dataFromParent.order.createdAt).format('DD/MM/YYYY')}
                        id="orderDate" label={'Order Date'} onChange={(e) => handleChange(e, 'orderDate')} component={ReactstrapTextField} />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <FormGroup>
                      <Label for="orderid"> TDS % </Label>
                      <Input type="text" name="tds" id="tds" label={'TDS %'} value={tds} onChange={(e) => handleChange(e, 'tds')} disabled={true}
                        component={ReactstrapTextField} >
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <FormGroup>
                      <Label for="tds">TDS Value</Label>
                      <Field type="text" name="tdsValue" id="tdsValue" label={'TDS Value'} value={tdsValueFor()} hide={true}
                        component={ReactstrapTextField} />
                      <div style={{ marginTop: '7px' }}>{tdsValueFor(values).toFixed(2)}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <FormGroup>
                      <Label for="tds">{'TCS %'}</Label>
                      <Input type="text" name="tcs" id="tcs" value={tcs} disabled={true}
                        component={ReactstrapTextField} >
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <FormGroup>
                      <Label for="commission">TCS Value</Label>
                      <div style={{ marginTop: '7px' }}>{tcsValueFor(values).toFixed(2)}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <FormGroup>
                      <Field type="text" name="commission" id="commission" label={'Commission %'} value={commission} onChange={(e) => handleChange(e, 'commission')}
                        component={ReactstrapTextField} validate={composeValidators(required, number)} >
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <FormGroup>
                      <Label for="commission">Commission Value</Label>
                      <div style={{ marginTop: '7px' }}>{commissionValueFor(values).toFixed(2)}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <FormGroup>
                      <Field type="text" name="netAmount1" id="netAmount1" label={'Order Value'}
                        component={ReactstrapTextField} validate={composeValidators(required, number)} />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                    <FormGroup>
                      <Label for="netAmount">Net Amount</Label>
                      <div style={{ marginTop: '7px' }}>{totalAmountFormula(values).toFixed(2)}</div>
                    </FormGroup>
                  </Col>
                </Row>
                <DialogActions>
                  <Button onClick={handleRequestClose} color='secondary' >
                    Close
                  </Button>
                  <Button type='submit' color='primary' >
                    Update
                  </Button>

                </DialogActions>
              </form>
            }}
          />
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = ({ admin }) => {
  const { getSettlements } = admin;
  return { admin }
};

export default connect(mapStateToProps, { getSettlementList })(EditSellerSettlement);