import React, { Component, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import { FormGroup, Label, Input } from 'reactstrap';
import AxiosRequest from 'sagas/axiosRequest';
import { NotificationManager } from 'react-notifications';
import ReactstrapTextField from '../../components/ReactStrapTextField';
import ReactStrapSelectField from '../../components/reactstrapSelectField';
import { getProductList } from 'actions/admin';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form'
import { required, number, dicount100product } from 'constants/validations'
import AppConfig from 'constants/config'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { isMobile } from 'react-device-detect';
import CircularProgress from "@mui/material/CircularProgress";

function EditBank(props) {
  const previewRef = useRef(null);
  const [state, setState] = useState({
    id: "",
    name: "",
    manufacturer: "",
    type: "",
    productCategory: "",
    gst: "",
    chemicalCombination: "",
    uploadedImage: "",
    countryOfO: "",
    newFileUpload: [],
    selectedFiles: null,
    preview: [],
    medicineCategory: "",
    surcharge: 0,
    isPrepaid: false,
    page: 0,
    perPage: 100,
    searchText: "",
    description: "",
    selectedSeller: [],
    minOrder: "",
    discount: "",
    discountCap: "",
    shortSearch: 2,
    shortSurcharge: 3.5,
  });

  useEffect(() => {
    let images = []
    if ( product && product.images) {
      props.product.images.map(data => {
        if (data !== '' && data !== undefined) {
          data.includes('amazonaws') ? images.push(data) : images.push(AppConfig.productImageUrl + '' + data)
        }
      })
    }
    setState((prev) => ({
      ...prev,
      id: props.product._id ? props.product._id : "",
      name: props.product.name ? props.product.name : "",
      manufacturer: props.product?.Company?._id
        ? props.product?.Company?._id
        : "",
      type: props.product.Type?._id ? props.product.Type?._id : "",
      productCategory: props.product.ProductCategory?._id
        ? props.product.ProductCategory?._id
        : "",
      gst: props.product.GST?._id ? props.product.GST?._id : "",
      chemicalCombination: props.product.chem_combination
        ? props.product.chem_combination
        : "",
      uploadedImage: "",
      countryOfO: props.product.country_of_origin
        ? props.product.country_of_origin
        : "",
      newFileUpload: images,
      selectedFiles: null,
      preview: [],
      medicineCategory: props.product.MedicineType?._id
        ? props.product.MedicineType?._id
        : "",
      surcharge: props.product.surcharge ? props.product.surcharge : 0,
      isPrepaid: props.product.isPrepaid ? props.product.isPrepaid : false,
      page: props.page ? props.page : 0,
      perPage: props.perPage ? props.perPage : 100,
      searchText: props.searchText ? props.searchText : "",
      description: props.product.description ? props.product.description : "",
      selectedSeller: props.product.sellerList ? props.product.sellerList : [],
      minOrder: props.product.minOrder ? props.product.minOrder : "",
      discount: props.product.discount ? props.product.discount.toString() : '',
      discountCap: props.product.discountCap ? props.product.discountCap.toString() : '',
      shortExpiry: props.product.shortExpiry ? props.product.shortExpiry : 9,
      shortSurcharge: props.product.shortSurcharge
        ? props.product.shortSurcharge
        : 0,
    }));
  }, [props.product])

  const handleRequestClose = () => {
    setState((prev) => ({
      ...prev,
      id: "",
      name: "",
      manufacturer: "",
      type: "",
      productCategory: "",
      gst: "",
      chemicalCombination: "",
      uploadedImage: "",
      countryOfO: "",
      newFileUpload: [],
      selectedFiles: null,
      preview: [],
      medicineCategory: "",
      surcharge: 0,
      isPrepaid: false,
      page: 0,
      perPage: 100,
      searchText: "",
      description: "",
      selectedSeller: [],
      minOrder: "",
      discount: "",
      discountCap: "",
      shortSearch: 2,
      shortSurcharge: 0,
    }));
    props.handleClick('edit', '')
  };

  const handleChange = (e, key, form) => {
    if (key === "medicineCategory") {
      form.change("medicineCategory", e.target.value);
    }
  };

  const removeImage = (fileToRemove) => {
    const updatedFiles = [...state.newFileUpload].filter(file => file !== fileToRemove);
    setState({ ...state, newFileUpload: updatedFiles });
  };

  const onSubmit = async (values) => {
    let data = {
      id: state.id ? state.id : "",
      name: values.name ? values.name : "",
      company_id: values.manufacturer ? values.manufacturer : "",
      Type: values.type ? values.type : "",
      product_cat_id: values.productCategory ? values.productCategory : "",
      GST: values.gst ? values.gst : "",
      chem_combination: values.chemicalCombination
        ? values.chemicalCombination
        : "",
      images: state.newFileUpload ? state.newFileUpload : "",
      sku: values.name ? values.name : "",
      countryOfOrigin: values.countryOfO ? values.countryOfO : "India",
      medicineCategory: values.medicineCategory,
      surcharge: values.surcharge ? Number(values.surcharge) : 0,
      isPrepaid: values.isPrepaid ? values.isPrepaid : false,
      description: values.description ? values.description : "",
      sellerList: selectedSeller.length > 0 ? selectedSeller : [],
      minOrder: values.minOrder ? Number(values.minOrder) : 0,
      discount: values.discount ? values.discount : "",
      discountCap: values.discountCap ? values.discountCap : "",
      shortExpiry: values.shortExpiry ? Number(values.shortExpiry) : 2,
      shortSurcharge: values.shortSurcharge ? Number(values.shortSurcharge) : 0,
    };
    let response = await AxiosRequest.axiosHelperFunc('post', 'product/editProductDetail', '', data)

    if (response.data.error) {
      NotificationManager.error(response.data.title)
      handleRequestClose()
    } else {
      NotificationManager.success(response.data.title)
      props.getProductList({
        page: state.page + 1,
        perPage: state.perPage,
        filter: state.searchText && state.searchText !== '' ? { 'name': state.searchText.toUpperCase() } : {},
      });
      handleRequestClose()
    }
  }

  const handleMultipleFileUpload = (event) => {
    let files = Array.from(event.target.files);
    let readers = files.map(file => {
      let reader = new FileReader();
      return new Promise(resolve => {
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(readers).then((results) => {
      setState((prev) => ({
        ...prev,
        newFileUpload: [...prev.newFileUpload].concat(results),
      }));
    });

    setState((prev) => ({
      ...prev,
      preview: files,
    }));
  };

  const handleChangeNew = (value) => {
    setState((prev) => ({
      ...prev, selectedSeller: value
    }));
  };

  const {
    chemicalCombination,
    name,
    manufacturer,
    gst,
    type,
    productCategory,
    countryOfO,
    medicineCategory,
    surcharge,
    isPrepaid,
    description,
    selectedSeller,
    minOrder,
    discount,
    discountCap,
    shortSearch,
    shortExpiry,
    shortSurcharge,
  } = state;
  const { productMetaData, mediCategory, open, product, sellerData } = props;
  const surchargeArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const shortexpiryArr = [2, 3, 4, 5, 6, 7, 8, 9];
  const shortsearchArr = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
  let meditype = (state.medicineCategory !== null && mediCategory.length > 0) ? mediCategory.filter(e => e._id == state.medicineCategory) : '';

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>Edit Product</DialogTitle>
        {props.viewloader ?
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
            <CircularProgress disableShrink />
          </div>
          :
        <Form
          onSubmit={onSubmit}
          initialValues={{
            name: name || "",
            chemicalCombination: chemicalCombination || "",
            manufacturer: manufacturer || "",
            surcharge: surcharge || "",
            gst: gst || "",
            countryOfO: countryOfO || "India",
            type: type || "",
            productCategory: productCategory || "",
            isPrepaid: isPrepaid || "",
            medicineCategory: medicineCategory || "",
            description: description || "",
            discount: discount || "",
            discountCap: discountCap || "",
            minOrder: minOrder || "",
            shortExpiry: shortExpiry || "",
            shortSurcharge: shortSurcharge || 0,
          }}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <DialogContent>
                <Row>
                  <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                    <Row>
                      <Col xs={8} md={10} sm={8} xl={10} lg={10}>
                        {[...state.newFileUpload].map((file) => {
                          return (
                            <div key={file} style={{ position: 'relative', display: 'inline-block'}}>
                            <img
                              src={file}
                              style={{marginBottom: "20px", marginRight: "20px", marginLeft: "20px", borderStyle: "solid", borderRadius: "10px", borderColor: "#dee2e6", borderWidth: "thin", padding: "10px", maxHeight: "auto", maxWidth: "100%", width: "150px",
                              }}
                            />
                              <button
                                onClick={() => removeImage(file)}
                                style={{
                                  position: 'absolute', right: isMobile ? '0px' : '18px', top: '-3px', backgroundColor: 'transparent', color: 'red', border: 'none', width: '20px', height: '20px', textAlign: 'center', fontSize: '35px', lineHeight: '24px', padding: '0', cursor: 'pointer',
                                }}
                              >
                                &times;
                              </button>
                            </div>
                          );
                        })}
                      </Col>
                      <br />

                      <Col xs={4} md={2} sm={4} xl={2} lg={2} className={"d-flex align-items-center"} >
                        <input
                          type="file"
                          name="file"
                          accept="image/*"
                          multiple
                          onChange={(e) => handleMultipleFileUpload(e)}
                          ref={previewRef}
                          style={{ display: "none" }}
                        />
                        <figure>
                          <img
                            src={
                              [...state.newFileUpload].length == 0
                                ? require("../../../../assets/img/camera_1.png")
                                : require("../../../../assets/img/camera_3.png")
                            }
                            className="d-block mx-auto"
                            onClick={(e) => previewRef.current.click()}
                          />
                          <figcaption>Upload Images</figcaption>
                        </figure>
                      </Col>
                      <Col xs={12} md={4} sm={6} xl={4} lg={4}>
                        <FormGroup>
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            label={"Name"}
                            component={ReactstrapTextField}
                            validate={required}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={4} sm={12} xl={4} lg={4}>
                        <FormGroup>
                          <Field
                            type="text"
                            name="chemicalCombination"
                            id="chemicalCombination"
                            label={"Chemical Combination"}
                            component={ReactstrapTextField}
                            validate={required}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3} md={3} xs={6} lg={3} xl={3}>
                        <FormGroup>
                          <Field
                            type="select"
                            name="surcharge"
                            id="surcharge"
                            label={"Surcharge (%)"}
                            component={ReactStrapSelectField}
                          >
                            {surchargeArr.map((surchrg) => {
                              return (
                                <option
                                  selected={surcharge == surchrg}
                                  value={surchrg}
                                >
                                  {surchrg}
                                </option>
                              );
                            })}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col xs={6} md={4} sm={4} xl={4} lg={4}>
                        <FormGroup>
                          <Field
                            type="select"
                            name="manufacturer"
                            id="manufacturer"
                            label={"Manufacturer"}
                            validate={required}
                            component={ReactStrapSelectField}
                          >
                            <option
                              selected={manufacturer == ""}
                              value={""}
                            ></option>
                            {productMetaData && productMetaData.companies && productMetaData.companies.map((val) => {
                                return (
                                  <option
                                    selected={val._id == manufacturer}
                                    value={val._id}
                                  >
                                    {val.name}
                                  </option>
                                );
                              })}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col xs={6} md={4} sm={4} xl={4} lg={4}>
                        <FormGroup>
                          <Field
                            type="select"
                            name="gst"
                            id="gst"
                            label={"GST"}
                            component={ReactStrapSelectField}
                            validate={required}
                          >
                            <option selected={gst == ""} value={""}></option>
                            {productMetaData && productMetaData.GST && productMetaData.GST.map((val) => {
                                return (
                                  <option
                                    selected={val._id == gst}
                                    value={val._id}
                                  >
                                    {val.value}
                                  </option>
                                );
                              })}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col xs={6} md={4} sm={4} xl={4} lg={4}>
                        <FormGroup>
                          <Field
                            type="text"
                            name="countryOfO"
                            id="countryOfO"
                            label={"Country Of Origin"}
                            component={ReactstrapTextField}
                            validate={required}
                          ></Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3} md={3} xs={6} lg={3} xl={3}>
                        <FormGroup>
                          <Field
                            type="select"
                            name="type"
                            label={"Type"}
                            id="type"
                            component={ReactStrapSelectField}
                            validate={required}
                          >
                            <option selected={type == ""} value={""}></option>
                            {productMetaData && productMetaData.types && productMetaData.types.map((val) => {
                                return (
                                  <option
                                    selected={val._id == type}
                                    value={val._id}
                                  >
                                    {val.name}
                                  </option>
                                );
                              })}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col sm={3} md={3} xs={6} lg={3} xl={3}>
                        <FormGroup>
                          <Field
                            type="select"
                            name="productCategory"
                            label={"Product Category"}
                            id="productCategory"
                            component={ReactStrapSelectField}
                            validate={required}
                          >
                            <option
                              selected={productCategory == ""}
                              value={""}
                            ></option>
                            {productMetaData && productMetaData.categories && productMetaData.categories.map((val) => {
                                return (
                                  <option
                                    selected={val._id == productCategory}
                                    value={val._id}
                                  >
                                    {val.name}
                                  </option>
                                );
                              })}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col sm={3} md={3} xs={6} lg={3} xl={3}>
                        <FormGroup>
                          <Field
                            type="select"
                            name="isPrepaid"
                            label={"Prepaid Only"}
                            id="isPrepaid"
                            component={ReactStrapSelectField}
                          >
                            <option
                              selected={isPrepaid === false}
                              value={false}
                            >
                              FALSE
                            </option>
                            <option selected={isPrepaid === true} value={true}>
                              TRUE
                            </option>
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col sm={3} md={3} xs={6} lg={3} xl={3}>
                        <FormGroup>
                          <Field
                            type="select"
                            name="medicineCategory"
                            label={"Medicine Type"}
                            id="medicineCategory"
                            onChange={(e) =>
                              handleChange(e, "medicineCategory", form)
                            }
                            component={ReactStrapSelectField}
                            validate={required}
                          >
                            <option
                              selected={medicineCategory == ""}
                              value={""}
                            ></option>
                            {mediCategory &&
                              mediCategory.map((val) => {
                                return (
                                  <option
                                    selected={val._id == medicineCategory}
                                    value={val._id}
                                  >
                                    {val.name == "Others" ? "PCD" : val.name}
                                  </option>
                                );
                              })}
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    {meditype.length > 0 && medicineCategory !== "" ? (
                      <Row>
                        <Col xs={6} md={2} sm={2} xl={2} lg={2}>
                          <FormGroup>
                            <Label for="chemicalCombination">Commission</Label>
                            <Input
                              disabled
                              type="text"
                              name="chemicalCombination"
                              value={meditype && meditype[0].commission}
                              id="chemicalCombination"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={2} sm={2} xl={2} lg={2}>
                          <FormGroup>
                            <Label for="chemicalCombination">Exclusive</Label>
                            <Input
                              disabled
                              type="text"
                              name="chemicalCombination"
                              value={meditype && meditype[0].exclusive}
                              id="chemicalCombination"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={3} sm={3} xl={3} lg={3}>
                          <FormGroup>
                            <Label for="chemicalCombination">Refundable</Label>
                            <Input
                              disabled
                              type="text"
                              name="chemicalCombination"
                              value={meditype && meditype[0].refundable}
                              id="chemicalCombination"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={3} sm={3} xl={3} lg={3}>
                          <FormGroup>
                            <Label for="chemicalCombination">
                              Only Prepaid
                            </Label>
                            <Input
                              disabled
                              type="text"
                              name="chemicalCombination"
                              value={meditype && meditype[0].onlyPrepaid}
                              id="chemicalCombination"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={2} sm={2} xl={2} lg={2}>
                          <FormGroup>
                            <Label for="chemicalCombination">Colour Code</Label>
                            <Input
                              disabled
                              type="text"
                              name="chemicalCombination"
                              value={meditype && meditype[0].colourCode}
                              id="chemicalCombination"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Field
                        label="Product description"
                        type="textarea"
                        name="description"
                        rows="2"
                        component={ReactstrapTextField}
                        id="description"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="Filters">Sellers</Label>
                      <Autocomplete
                        onChange={(e, value) => handleChangeNew(value)}
                        limitTags={2}
                        multiple
                        id="checkboxes-tags-demo"
                        options={sellerData && sellerData}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.company_name || ""}
                        defaultValue={selectedSeller}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search"
                          />
                        )}
                        size="small"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Field
                        type="text"
                        name="minOrder"
                        label={"Minimum bulk order amount"}
                        id="minOrder"
                        validate={number}
                        component={ReactstrapTextField}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Field
                        type="text"
                        name="discount"
                        label={"Discount (Comma seperated values only)"}
                        id="discount"
                        validate={dicount100product}
                        component={ReactstrapTextField}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Field
                        type="number"
                        name="discountCap"
                        label={`Discount Cap (%)`}
                        id="discountCap"
                        validate={dicount100product}
                        component={ReactstrapTextField}
                      ></Field>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Field
                        type="text"
                        name="shortExpiry"
                        label={"Short expiry months"}
                        id="shortExpiry"
                        // validate={number}
                        component={ReactStrapSelectField}
                      >
                        {shortexpiryArr.map((shortexpiry) => {
                          return (
                            <option
                              selected={shortExpiry == shortexpiry}
                              value={shortexpiry}
                            >
                              {shortexpiry}
                            </option>
                          );
                        })}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Field
                        type="text"
                        name="shortSurcharge"
                        label={`Short expiry surcharge (%)`}
                        id="shortSurcharge"
                        component={ReactStrapSelectField}
                      >
                        {shortsearchArr.map((shortsearch) => {
                          return (
                            <option
                              selected={shortSearch == shortsearch}
                              value={shortsearch}
                            >
                              {shortsearch}
                            </option>
                          );
                        })}
                      </Field>
                    </FormGroup>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRequestClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        />}
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = ({ admin }) => {
  const { productList } = admin;
  return { productList }
};

export default connect(mapStateToProps, { getProductList })(EditBank);