import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import moment from 'moment';
import customHead from 'constants/customHead'
import { connect } from 'react-redux';
import { getAllOrderList } from 'actions/admin';
import CustomFilter from '../../../../components/Filter';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import helper from '../../../../constants/helperFunction';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css';
import AppConfig from "constants/config";
import { useNavigate } from "react-router";
import Pagination from '@mui/material/Pagination';
import { Typography } from "@mui/material";


const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
let date = new Date();




function AllOrder(props) {
  const history = useNavigate()
  let url = window.location.search
  let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''

  const [state, setState] = useState({
    filter: [],
    perPage: 100,
    loader: false,
    seller_id: json ? json.seller_idA ? json.seller_idA : '' : '',
    buyer_id: json ? json.buyer_idA ? json.buyer_idA : '' : '',
    searchedText: json ? json.searchA ? json.searchA : '' : '',
    page: json ? json.pageA ? Number(json.pageA) : 0 : 0,
    status: json ? json.statusA ? json.statusA : '' : '',
    type: json ? json.typeA ? json.typeA : '' : '',
    from: json ? json.from_dateA ? json.from_dateA : moment().subtract(2, 'months').format() : moment().subtract(2, 'months').format(),
    to: json ? json.to_dateA ? json.to_dateA : moment().format() : moment().format(),
    orderType: json ? json.orderTypeA ? json.orderTypeA : '' : '',
  })
  const [filter, setFilter] = useState(false)

  const button = (orderId, status) => {
    return (<React.Fragment>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button variant="outlined" onClick={(e) => handleTrackOrderClick(orderId)} className={'text-success'}>
          Track
        </Button>
        {orderId.docket ?
          <Button variant="outlined" onClick={() => openNewWindow(orderId)}>
            Docket
          </Button>
          : ''
        }
      </ButtonGroup>
    </React.Fragment>)
  }
  const handleTrackOrderClick = (data) => {
    history(`/admin/orders/trackOrder/${data.order_id}`);
  }
  const openNewWindow = (data) => {
    if (data) {
      window.open(`${AppConfig.baseUrl}users/generateLabel1?orderId=${data.order_id}`, '_blank');

    }
  }

  function fetchApi() {
    let dataForCat = json ? json.statusA ? json.statusA : '' : ''

    let dat = dataForCat?.length > 0 ? dataForCat.split(',') : [];
    let pageno = json ? json.pageA ? Number(json.pageA) + 1 : 1 : 1
    let fromDate = json ? json.from_dateA ? json.from_dateA : moment().subtract(2, 'months').format() : moment().subtract(2, 'months').format()
    let toDate = json ? json.to_dateA ? json.to_dateA : moment().format() : moment().format()
    let data = {
      page: pageno,
      perPage: 100,
      filter: '',
      searchText: json ? json.searchA ? json.searchA : '' : '',
      from_date: moment(fromDate).format('YYYY-MM-DD'),
      to_date: moment(toDate).add(1, 'days').format('YYYY-MM-DD'),
      seller_id: json ? json.seller_idA ? json.seller_idA : '' : '',
      buyer_id: json ? json.buyer_idA ? json.buyer_idA : '' : '',
      status: dat,
      type: json ? json.typeA ? json.typeA : '' : '',
      orderType: json ? json.orderTypeA ? json.orderTypeA : '' : ''
    }
    if (props.tab == 3) {
      props.getAllOrderList({ data, history: history })
    }
  }
  useEffect(() => {
    if (props.tab == 3) {
      setState({
        ...state,
        seller_id: json ? json.seller_idA ? json.seller_idA : '' : '',
        buyer_id: json ? json.buyer_idA ? json.buyer_idA : '' : '',
        searchedText: json ? json.searchA ? json.searchA : '' : '',
        page: json ? json.pageA ? Number(json.pageA) : 0 : 0,
        status: json ? json.statusA ? json.statusA : '' : '',
        type: json ? json.typeA ? json.typeA : '' : '',
        from: json ? json.from_dateA ? json.from_dateA : moment().subtract(2, 'months').format() : moment().subtract(2, 'months').format(),
        to: json ? json.to_dateA ? json.to_dateA : moment().format() : moment().format(),
        orderType: json ? json.orderTypeA ? json.orderTypeA : '' : '',
      })
      fetchApi()
    }
  }, [window.location.search])

  const statusStyle = (status) => {
    return status.includes("Processed") ? "text-white bg-primary" : status.includes("pending") ? 'text-white bg-dark' : status.includes("Ready For Dispatch") ? "text-white bg-amber" : status.includes("Placed") ? "text-white bg-yellow" : status.includes("Cancelled") ? "text-white bg-grey" : status.includes("Delivered") ? "text-white bg-green" : status.includes("Requested") ? "text-white bg-magenta" : status.includes("New") ? "text-white bg-danger" : status.includes("LOST") ? "text-white bg-black" : status.includes("RTO after Delivery") ? "text-white bg-brown" : status.includes("RTO") ? "text-white bg-cyan" : status.includes("OnHold") ? "text-white bg-orange" : "text-white bg-info";
  }

  const changePage = (page) => {
    setState((prev) => ({ ...prev, page: page }))
    let obj = { from_dateA: state.from, to_dateA: state.to, pageA: page, searchA: state.searchedText, statusA: state.status, seller_idA: state.seller_id, buyer_idA: state.buyer_id, typeA: state.type, orderTypeA: state.orderType }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)
    setState((prev) => ({ ...prev, loader: true }))
    const t = setTimeout(() => {
      setState((prev) => ({ ...prev, loader: false }))
    }, 3000);
  };
  const handleSettelMent = async () => {
    let dataForCat = state.status ? state.status : ''
    window.open(`${AppConfig.baseUrl}order/all_order_csv_data?token=${localStorage.getItem('admin_token')}&from_date=${state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}&to_date=${state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}&type=${state.type ? state.type : ''}&seller_id=${state.seller_id ? state.seller_id : ''}&buyer_id=${state.buyer_id ? state.buyer_id : ''}&status=${dataForCat}&orderType=${state.orderType ? state.orderType : ''}`, '_blank');
    setState((prev) => ({
      ...prev,
      openFilter: false,
      seller: ''
    }))
  }

  const handleSearch = (searchText) => {
    setState((prev) => ({ ...prev, searchedText: searchText ? searchText.replace(/"/g, '') : '' }))
    let obj = { from_dateA: state.from, to_dateA: state.to, pageA: 0, searchA: searchText ? searchText.replace(/"/g, '') : '', statusA: state.status, seller_idA: state.seller_id, buyer_idA: state.buyer_id, typeA: state.type, orderTypeA: state.orderType }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)

  };
  const handleCloseSearch = () => {
    setState((prev) => ({ ...prev, searchedText: '' }))
    let obj = { from_dateA: state.from, to_dateA: state.to, pageA: 0, searchA: '', statusA: state.status, seller_idA: state.seller_id, buyer_idA: state.buyer_id, typeA: state.type }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)
  }
  const handleResetFilter = (e, filter) => {
    e.preventDefault();
    filter();
    setState((prev) => ({
      ...prev,
      month: monthNames[date.getMonth()],
      year: date.getFullYear(),
      filter: '',
      status: '',
      seller_id: '',
      buyer_id: '',
      from: '',
      to: '',
      selectedDated: ''
    }));

    let obj = { from_dateA: '', to_dateA: '', pageA: 0, searchA: state.searchedText, statusA: '', seller_idA: '', buyer_idA: '', typeA: '', orderTypeA: '' }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)
  }
  const sellerFromFilter = (e) => {
    setState((prev) => ({ ...prev, seller_id: e }))
  }
  const buyerFromFilter = (e) => {
    setState((prev) => ({ ...prev, buyer_id: e }))
  }
  const dateFilterFrom = (e) => {
    setState((prev) => ({ ...prev, from: e }))
  }
  const dateFilterTo = (e) => {
    setState((prev) => ({ ...prev, to: e }))
  }
  const onFilterChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
  }

  function applyFilterClose() {
    setFilter(true)
    options.customFilterDialogFooter(null, options.applyNewFilters);
  }

  const { allOrder } = props;

  const columns = [
    {
      name: "buyerName",
      label: " ",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "orderId",
      label: "Order ID",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "payment",
      label: "Payment mode",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Latest Date",
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListRender: v => {
          return false;
        },
        filterOptions: {
          names: [],
          logic() {

          },
          display: () => {
            return (<CustomFilter onFilterChange={onFilterChange} applyFilter={applyFilterClose}
              searchText={state.searchedText} handleResetFilter={handleResetFilter} month={state.month} status={state.status} seller_id={state.seller_id} sellerFromFilter={(e) => sellerFromFilter(e)} from={state.from} to={state.to}
              buyer_id={state.buyer_id} buyerFromFilter={(e) => buyerFromFilter(e)}
              dateFilterFrom={(e) => dateFilterFrom(e)} dateFilterTo={(e) => dateFilterTo(e)}
              year={state.year} page={state.page} location={props.location}
              history={history} filterFor='AllOrderList' />)
          },
        },
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
      }
    }
  ];

  let data = [];
  allOrder && allOrder.detail && allOrder.detail.length > 0 && allOrder.detail.map((data1, index) => {
    const ShortExp = data1.products.filter(data => data.expiry_status === 'Short Expiry') 
    let local = moment(data1.createdAt).format(' D MMM, YYYY h:mm a');
    let local1 = moment(data1.order_status[data1.order_status.length - 1].date).format(' D MMM, YYYY h:mm a');
    data.push([
      <div style={helper.divFlex}>
        {ShortExp.length > 0 && <Tooltip title={`Short expiry`} placement="top-start">
          <span style={helper.styleForDiv} className={`${helper.styleDivLine('Short Expiry')}`}>&nbsp;&nbsp;</span>
        </Tooltip>}
        {data1.orderType && <Tooltip title={`${data1.orderType}`} placement="top-start">
          <div style={helper.styleForDiv} className={`${helper.styleDivLine(data1.orderType)}`}>&nbsp;&nbsp;</div>
        </Tooltip>}
        {data1.serviceName ? <Tooltip title={`${data1.serviceName}`} placement="top-start">
          <div style={helper.styleForDiv} className={`${helper.styleDivLine2(data1.serviceName)}`}>&nbsp;&nbsp;</div>
        </Tooltip> :
          <div style={helper.styleForDiv}>&nbsp;&nbsp;</div>
        }
      </div>,
      <div>
        <h4>
          <a style={{ color: 'inherit' }} href={`/admin/orders/orderDetails/${data1.order_id}`} onClick={(e) => { e.preventDefault(); history(`/admin/orders/orderDetails/${data1.order_id}`) }}>
            <span className="font-weight-bolder">{data1.products.length}
              &nbsp;{data1.products.length > 1 ? 'items' : 'item'} by </span>{data1.user_id && data1.user_id.company_name}
          </a>
          <a rel="noreferrer" target='_blank' href={`/admin/buyers/details/${data1.user_id._id}`}><span className='h6'>({data1.user_id && data1.user_id.buyerId})</span></a>
        </h4>
        {data1.seller_id &&
          (data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '').length > 25 ?
          <span className="small font-weight-bolder">{(data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '').slice(0, 25) + "..."} | </span>
          :
          <span className="small font-weight-bolder">{(data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '')} | </span>
        }
        <span className="small font-weight-bolder">{data1.order_id} | {data1.isBulk ? 'Bulk Prepaid' : data1.paymentType == 'Online' ? 'Prepaid' : data1.paymentType} |
          {data1.paymentStatus === 'pending' ?
            <Tooltip title={`Pending `} placement="top-start">
              <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'red' }} />
            </Tooltip> : ''}

          {moment(data1.createdAt).format(' D MMM, YYYY h:mm a')} </span>
        {(moment().diff(moment(data1.createdAt), 'hours')) <= 48 ?
          <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'green' }}></span> :
          (moment().diff(moment(local), 'hours')) >= 48 && (moment().diff(moment(local), 'hours')) <= 72 ?
            <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'orange' }}></span> :
            (moment().diff(moment(local), 'hours')) >= 72 && data1.order_status[data1.order_status.length - 1].status === 'New' ?
              <span className="size-8 rounded-circle d-inline-block ml-1 blink_me" style={{ backgroundColor: 'red' }}></span> : ''
        }
      </div>,

      <div><p className={'ptrPriceFont1 priceColorInventory m-0'}>
        ₹{((data1.total_amount).toFixed(2))}
      </p></div>,

      <div key={'recent'} className={` badge text-uppercase ${statusStyle(data1.isOnHold ? "On Hold" : data1.requested !== "Requested" ? data1.order_status[data1.order_status.length - 1].status : 'Requested')}`}>{data1.isOnHold ? "On Hold" : data1.requested === "Requested" ? 'Requested' : data1.order_status[data1.order_status.length - 1].status}</div>,
      <div>
        {
          data1.paymentStatus && data1.paymentStatus == "pending" ? "N/A" : data1.paymentType && data1.isBulk === true && !data1.rawTransaction && data1.rawTransaction == undefined ? 'Bulk Prepaid' : data1.paymentType === "COD" ? "COD" : ((data1.paymentType === "Online" && data1.rawTransaction != undefined) ? data1.rawTransaction.payment_mode && data1.total_amount - (data1.rawTransaction && data1.rawTransaction.amount) > 0 || (data1.rawTransaction && data1.rawTransaction.amount) < 0 ? "MediWallet+" + data1.rawTransaction.payment_mode : data1.rawTransaction.payment_mode == "null" ? "N/A" : data1.rawTransaction.payment_mode : "MediWallet")
        }
      </div>,
      (local1),

      button(data1, data1.requested)
    ])
  })
  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: false,
    selectableRows: false,
    rowsPerPage: 100,
    page: state.page,
    fixedHeader: false,
    print: false,
    download: true,
    filter: true,
    sort: false,
    count: allOrder && allOrder.count,
    serverSide: true,
    server: true,
    selectableRowsOnClick: false,
    selectableRows: 'none',
    fixedHeader: false,
    search: true,
    searchText: state.searchedText,
    rangePage: true,
    rowsPerPageOptions: [],
    customFilterDialogFooter: (currentFilter, applyNewFilters) => {
      if (filter) {
        setFilter(false)
        return applyNewFilters();
      }
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key == 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleCloseSearch();
          break;
      }
    },
    onDownload: () => {
      handleSettelMent();
      return false
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
         <Typography className="p-2" variant="h7">
         { (page) * rowsPerPage + 1} - {Math.min((page+1) * rowsPerPage, count)} of {count}
        </Typography>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }

  }
  const theme = createTheme({
    components: {

      MuiTableHead: {
        styleOverrides: {
          root: {
            paddingLeft: '0px'
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontSize: '1rem',
            paddingLeft: '0px',
            fontWeight: '600'
          },
          root: {
            paddingLeft: '0px',
          },
        }
      },
      MUIDataTableFilter: {
        styleOverrides: {
          reset: {
            display: "none"
          }
        }
      }
    }
  });
  const { loading } = props;
  return (
    <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
      {loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}
      {
        loading ?
          null :
          <ThemeProvider theme={theme}>
            <div className="mt-4 ms-4">{allOrder.userName == 'admin' ? <h3 style={{ padding: "12px 5px", marginBottom: '0px' }}>Total Amount : ₹ {(allOrder && allOrder.sum).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h3> : ''}</div>
            <MUIDataTable
              title=''
              // amount={allOrder && allOrder.sum}
              // isAdmin={allOrder.userName}
              handleSettelMent={handleSettelMent}
              // identifier={['allOrderListing', "allSettlements"]}
              data={data}
              columns={columns}
              options={options}
              style={{ borderRadius: '0px !important' }}
            />
          </ThemeProvider>
      }
    </div>
  );
}






const mapStateToProps = ({ admin }) => {
  const { allOrder, loading } = admin;
  return { allOrder, loading }
};

export default connect(mapStateToProps, { getAllOrderList })(AllOrder);

