import React, { Component, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import EditOrder from './editOrderDetail';
import { connect } from 'react-redux';
import { getOrderDetails } from 'actions/admin'
import moment from 'moment';

function OrderDetailTable(props) {

    const [state, setState] = useState({
        orderProductData: [],
        status: ''
    })

    const handleClick = (e, value, ptr, quantity, index) => {
        if (quantity !== '' && ptr !== '') {
            let tempOrderProduct = state.orderProductData;
            tempOrderProduct[index].paid = ptr;
            tempOrderProduct[index].quantity = quantity;
            setState((prev)=>({...prev, orderProductData: tempOrderProduct }));
            props.onClickHandle(e, value, tempOrderProduct);
        } else {
            props.onClickHandle(e, value, '');
        }
    }

    useEffect(() => {
        setState((prev)=>({...prev, orderProductData: props.orderDetails.products, status: props.orderDetails.requested }));
    }, [])

    useEffect(() => {
        setState((prev)=>({...prev, orderProductData: props.orderDetails?.products, status: props.orderDetails?.requested }));
    }, [props.orderDetails])

    const button = (title, qty, index, cost) => {
        if (title !== undefined, qty !== undefined, index !== undefined, cost !== undefined) {
            return (
                < div className={'action'} >
                </div >
            )
        }
    }
    const getValue = (data) => {
        if (!data.discount_on_product && data.discount_per > 0) {
            let totalPtr = (data.PTR - ((data.PTR / 100) * data.discount_per))
            return totalPtr * Number(data.quantity)
        } else if (data.discount_on_product && data.discount_per > 0) {
            let totalPtr = data.inventory_id.ePTR
            return totalPtr * Number(data.quantity)
        } else {
            return data.PTR * Number(data.quantity)
        }
    }

    const getSingleItemFinalValue = (data) => {
        if (!data.discount_on_product && data.discount_per > 0) {
            let totalPtrSingle = (data.PTR - ((data.PTR / 100) * data.discount_per))
            return totalPtrSingle
        } else if (data.discount_on_product && data.discount_per > 0) {
            let totalPtr = data.inventory_id.ePTR
            return totalPtr
        } else {
            return data.PTR
        }
    }

    const getFinalValue = (data) => {
        let tempValue = data.discount_name && (data.discount_name == 'Same' || data.discount_name == 'SameAndDiscount') ?
            (((data.quantity / data.discount_on_product.purchase) * data.discount_on_product.bonus) + Number(data.quantity)) * (data.ePTR ? data.ePTR : data.PTR)
            : (data.ePTR ? data.ePTR : data.PTR) * data.quantity
        let finalValue = ((Number(data.GST) / 100) * tempValue) + tempValue;
        return finalValue
    }

    let { orderProductData, status } = state;
    return (
        <Paper className={'tableRoot'}>
            {orderProductData && orderProductData.length > 0 ?
                <Table className={'tableMain'} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Title</TableCell>
                            <TableCell align="left">Packing</TableCell>
                            <TableCell align="left">Expiry</TableCell>
                            <TableCell align="left">Offer</TableCell>
                            <TableCell align="left">MRP</TableCell>
                            <TableCell align="left">PTR</TableCell>

                            <TableCell align="left">Qty</TableCell>
                            <TableCell align="left">Effective PTR</TableCell>
                            <TableCell align="left">GST</TableCell>
                            <TableCell align="left">Final Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderProductData.map((eachProduct, index) => (
                            <TableRow key={eachProduct.title + '' + index}>

                                <TableCell align="left">
                                    {eachProduct.inventory_id.medicineTypeName === 'Others' ?
                                        <Tooltip title={`PCD Product`}>
                                            <span className="size-8 rounded-circle d-inline-block" style={{ backgroundColor: 'orange', marginLeft: "-10px", marginRight: "3px" }} />
                                        </Tooltip> : ''}

                                    {eachProduct.name} <br />
                                    {eachProduct.surCharge && eachProduct.surCharge > 0 ? <span className='text-white bg-danger' style={{ padding: "1px 5px" }}>Surcharge ({eachProduct.surCharge}%)</span> : ''}
                                </TableCell>
                                <TableCell align="left">{eachProduct.inventory_id.product_id.Type.name}</TableCell>
                                <TableCell align="left">{moment(eachProduct && eachProduct.expiry_date).format('MM/YYYY')}</TableCell>
                                <TableCell align="left">
                                    {
                                        eachProduct.discount_name && eachProduct.discount_name == 'SameAndDiscount' ?
                                            eachProduct.discount_on_product.purchase + '+' + eachProduct.discount_on_product.bonus + ' (Bonus) and ' + eachProduct.discount_per + '% Off' :

                                            eachProduct.discount_name && eachProduct.discount_name == 'DifferentAndDiscount' ?
                                                eachProduct.discount_on_product.purchase + '+' + eachProduct.discount_on_product.bonus + ' ' + eachProduct.discount_on_product.name + ' and ' + eachProduct.discount_per + '% Off'
                                                :

                                                eachProduct.discount_name && eachProduct.discount_name == 'Same' ?
                                                    eachProduct.discount_on_product.purchase + '+' + eachProduct.discount_on_product.bonus + ' (Bonus)' :

                                                    eachProduct.discount_name && eachProduct.discount_name == 'Different' ?
                                                        eachProduct.discount_on_product.purchase + '+' + eachProduct.discount_on_product.bonus + ' ' + eachProduct.discount_on_product.name
                                                        :
                                                        eachProduct.discount_per + '% discount'
                                    }
                                </TableCell>
                                <TableCell align="left">&#x20B9;{(Number(eachProduct.MRP)).toFixed(2)}</TableCell>
                                <TableCell align="left">&#x20B9;{(Number(eachProduct.PTR)).toFixed(2)}</TableCell>

                                <TableCell align="left">{eachProduct.quantity}</TableCell>
                                <TableCell align="left">{(eachProduct.ePTR ? eachProduct.ePTR : eachProduct.PTR).toFixed(2)}</TableCell>
                                <TableCell align="left">{eachProduct.inventory_id.product_id.GST.value + "%"}</TableCell>
                                <TableCell align="left">{getFinalValue(eachProduct).toFixed(2)}</TableCell>

                                {
                                    status === "New" ?
                                        <TableCell align="left">
                                            {button(eachProduct.name, eachProduct.quantity, index, eachProduct.paid)}
                                        </TableCell>
                                        :
                                        ''
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                : 'No Items Found'}

        </Paper>
    );
}

const mapStateToProps = ({ admin }) => {
    const { orderDetails } = admin;
    return { orderDetails }
};

export default connect(mapStateToProps, { getOrderDetails })(OrderDetailTable);
