import React from "react";
import FullWidthTabsForProductRequest from '../../components/routes/tabs/fixed/FullWidthTabsForProductRequest';
import { useLocation, useNavigate } from "react-router";
import AdminContainer from 'containers/AdminContainer';

function ProductRequestTabs() {
  const history = useNavigate()
  const location = useLocation()
  return (
    <AdminContainer>
      <div className="page-heading buyerDetailsHeader d-sm-flex justify-content-sm-between align-items-sm-center">
        <h2 className="title mb-3 mb-sm-0">Product Requests</h2>
      </div>
      <FullWidthTabsForProductRequest history={history} location={location} tabFor={'productRequest'} />
    </AdminContainer>
  );
}

export default ProductRequestTabs;