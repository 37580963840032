import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

const CardBox = (props) => {
    const { title, children, className } = props

    return (
        <Card className={className}>
            {title && <CardHeader title={title}>{title}</CardHeader>}
            <CardContent>
                {children}
            </CardContent>

        </Card>
    );
}

export default CardBox;