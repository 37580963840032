// import React from 'react';
// import { connect } from 'react-redux';
// // import TextField from '@material-ui/core/TextField';
// // import IconButton from '@material-ui/core/IconButton';
// // import Button from '@material-ui/core/Button';
// import { TextField } from '@mui/material';
// import Button from '@mui/material/Button';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
// // import CircularProgress from '@material-ui/core/CircularProgress';
// import { CircularProgress } from '@mui/material';
// import { Link } from 'react-router-dom';
// import IntlMessages from 'util/IntlMessages';
// import {
//   hideMessage,
//   showAuthLoader,
//   userFacebookSignIn,
//   userGithubSignIn,
//   userGoogleSignIn,
//   userSignUp,
//   userTwitterSignIn
// } from 'actions/Auth';

// import { Col, Row, Form, FormGroup, Label, Input } from 'reactstrap';

// // stepper, text field, icons
// // import Grow from '@material-ui/core/Grow';
// import { Grow } from '@mui/material';
// import { Step } from '@mui/material';
// import { Stepper } from '@mui/material';
// import { StepLabel } from '@mui/material';
// // import Step from '@material-ui/core/Step';
// // import Stepper from '@material-ui/core/Stepper';
// // import StepLabel from '@material-ui/core/StepLabel';

// // Stepper label
// const getSteps = () => {
//   return ['Welcome', 'Compliance Form', 'Bank Details'];
// }

// class SignUp extends React.Component {
//   constructor() {
//     super();
//     this.state = {
//       password: '',
//       accountNumber: '',
//       activeStep: 0,
//       confirmPassword: '',
//       email: '',
//       firstName: '',
//       ifscCode: '',
//       lastName: '',
//       licenceId: '',
//       phone: '',
//       recipientName: ''
//     }
//     this.handleChange = this.handleChange.bind(this)
//   }

//   componentDidUpdate() {
//     if (this.props.showMessage) {
//       setTimeout(() => {
//         this.props.hideMessage();
//       }, 3000);
//     }
//     if (this.props.authUser !== null) {
//       this.props.history.push('/');
//     }
//   }

//   getStepContent = (step) => {
//     switch (step) {
//       case 0: return this.getPersonalInformation();
//       case 1: return this.getComplianceForm();
//       case 2: return this.getBankForm();
//       default: return <p>You dont have any step</p>
//     }
//   }

//   getPersonalInformation = () => {
//     return (
//       <div in={true}>
//         <Row form>
//           <Col sm={12} md={6} lg={6} xs={12} xl={6}>
//             <TextField
//               id="name"
//               label="First Name"
//               name={'firstName'}
//               value={this.state.firstName}
//               //onChange={this.handleChange}
//               margin="normal"
//               fullWidth={true}
//             />
//             {/* <FormGroup>
//               <Label for="name">First Name</Label>
//               <Input type="text" name="email" id="name" placeholder="firstName" value={this.state.firstName}
//                 //onChange={this.handleChange} />
//             </FormGroup> */}
//           </Col>
//           <Col sm={12} md={6} lg={6} xs={12} xl={6}>
//             <TextField
//               id="lName"
//               label="Last Name"
//               name={'lastName'}
//               value={this.state.lastName}
//               //onChange={this.handleChange}
//               margin="normal"
//               fullWidth={true}
//             />
//           </Col>
//           <Col sm={12} md={6} lg={6} xs={12} xl={6}>
//             <TextField
//               id="email"
//               label="Email"
//               name={'email'}
//               value={this.state.email}
//               //onChange={this.handleChange}
//               margin="normal"
//               fullWidth={true}
//             />
//           </Col>
//           <Col sm={12} md={6} lg={6} xs={12} xl={6}>
//             <TextField
//               id="contact"
//               label="Contact No."
//               name={'phone'}
//               value={this.state.phone}
//               //onChange={this.handleChange}
//               margin="normal"
//               fullWidth={true}
//             />
//           </Col>
//           <Col sm={12} md={6} lg={6} xs={12} xl={6}>
//             <TextField
//               id="password"
//               label="Password"
//               name={'password'}
//               type='password'
//               value={this.state.password}
//               //onChange={this.handleChange}
//               margin="normal"
//               fullWidth={true}
//             />
//           </Col>
//           <Col sm={12} md={6} lg={6} xs={12} xl={6}>
//             <TextField
//               id="confirmPassword"
//               label="Confirm Password"
//               name={'confirmPassword'}
//               type='password'
//               value={this.state.confirmPassword}
//               //onChange={this.handleChange}
//               margin="normal"
//               fullWidth={true}
//             />
//           </Col>

//         </Row>
//       </div>
//     )
//   }

//   getComplianceForm = () => {
//     return (
//       <div in={true}>
//         <Row>
//           <Col sm={12} md={6} lg={6} xs={6} xl={6} className='mt-2'>
//             <TextField
//               id="name"
//               label="License Number"
//               name={'licenceId'}
//               value={this.state.licenceId}
//               //onChange={this.handleChange}
//               margin="normal"
//               className='mt-4'
//               fullWidth={true}
//             />
//           </Col>
//           <Col sm={12} md={6} lg={6} xs={6} xl={6} className='d-flex justify-content-center' >
//             <div style={{ backgroundRepeat: 'none' }}>
//               <img src={require('../assets/img/camera_1.png')} className='d-block mx-auto' />
//               <p>Upload document</p>
//             </div>

//           </Col>
//         </Row>
//       </div>
//     )
//   }

//   getBankForm = () => {
//     return (
//       <div in={true}>
//         <Row>
//           <Col sm={12} md={6} lg={6} xs={12} xl={6}>
//             <TextField
//               id="name"
//               label="Account Number"
//               name={'accountNumber'}
//               value={this.state.accountNumber}
//               //onChange={this.handleChange}
//               margin="normal"
//               fullWidth={true}
//             />
//           </Col>
//           <Col sm={12} md={6} lg={6} xs={12} xl={6}>
//             <TextField
//               id="lName"
//               label="IFSC"
//               name={'ifscCode'}
//               value={this.state.ifscCode}
//               //onChange={this.handleChange}
//               margin="normal"
//               fullWidth={true}
//             />
//           </Col>
//           <Col sm={12} md={6} lg={6} xs={12} xl={6}>
//             <TextField
//               id="email"
//               label="Recipient name"
//               name={'recipientName'}
//               value={this.state.recipientName}
//               //onChange={this.handleChange}
//               margin="normal"
//               fullWidth={true}
//             />
//           </Col>
//         </Row>
//       </div>
//     )
//   }

//   handleChange = (e, key) => {
//     this.setState({ [key]: e.target.value });
//   }

//   handleBack = () => {
//     this.setState((prevState) => ({
//       ...prevState,
//       activeStep: prevState.activeStep - 1
//     }))
//   }

//   handleClick = () => {
//     const step = getSteps().length
//     if (this.state.activeStep === step - 1) {
//       return this.props.history.push('/verifyToken')
//     }
//     this.setState((prevState) => ({
//       ...prevState,
//       activeStep: prevState.activeStep + 1
//     }))
//   }

//   goToSignIN = () => {
//     this.props.history.push('/signin')
//   }

//   render() {
//     const {
//       activeStep
//     } = this.state;

//     const { showMessage, loader, alertMessage } = this.props;

//     return (
//       <div className="col-xl-12 col-lg-12 signinContainer" >
//         <div className="jr-card signinCard">
//           <React.Fragment>
//             <div className="login-header">
//               <a className="app-logo" href='javascript:void(0);' title="Jambo">
//                 <h2>Medimny</h2>
//               </a>
//             </div>
//             <Stepper activeStep={activeStep} alternativeLabel={true} className="horizontal-stepper-linear">
//               {getSteps().map(label => (
//                 <Step key={label}>
//                   <StepLabel>{label}</StepLabel>
//                 </Step>
//               ))}

//             </Stepper>
//             {this.getStepContent(activeStep)}
//             <div className='list-action mt-3'>
//               {
//                 activeStep !== 0 &&
//                 <Button variant='contained' size='medium' className='m-2' onClick={this.handleBack}> Back</Button>
//               }

//               <Button variant='contained' color='primary' className='m-2' size='medium' onClick={this.handleClick}> {activeStep + 1 === getSteps().length ? 'Finish' : 'Next'}</Button>

//             </div>
//             <div style={{ textAlign: 'end' }}>
//               <p>Already have an account? <a href='javascript:void(0);' onClick={this.goToSignIN}>Sign in</a></p>
//             </div>
//           </React.Fragment>
//         </div>
//         <div>
//           {
//             loader &&
//             <div className="loader-view">
//               <CircularProgress />
//             </div>
//           }
//           {showMessage && NotificationManager.error(alertMessage)}
//           <NotificationContainer />
//         </div>
//       </div>
//     )
//   }
// }

// const mapStateToProps = ({ auth }) => {
//   const { loader, alertMessage, showMessage, authUser } = auth;
//   return { loader, alertMessage, showMessage, authUser }
// };

// export default connect(mapStateToProps, {
//   userSignUp,
//   hideMessage,
//   showAuthLoader,
//   userFacebookSignIn,
//   userGoogleSignIn,
//   userGithubSignIn,
//   userTwitterSignIn
// })(SignUp);



import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, CircularProgress } from '@mui/material';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { userSignUp, hideMessage, showAuthLoader, userFacebookSignIn, userGoogleSignIn, userGithubSignIn, userTwitterSignIn } from 'actions/Auth';
import { Grow, Step, Stepper, StepLabel } from '@mui/material';

const SignUp = (props) => {
  const [formData, setFormData] = useState({
    password: '',
    accountNumber: '',
    activeStep: 0,
    confirmPassword: '',
    email: '',
    firstName: '',
    ifscCode: '',
    lastName: '',
    licenceId: '',
    phone: '',
    recipientName: ''
  });

  const history = useNavigate();

  useEffect(() => {
    if (props.showMessage) {
      setTimeout(() => {
        props.hideMessage();
      }, 3000);
    }
    if (props.authUser !== null) {
      history('/');
    }
  }, [props.showMessage, props.authUser, history]);

  const getSteps = () => ['Welcome', 'Compliance Form', 'Bank Details'];

  const getPersonalInformation = () => (
    <div in={true}>
      <Row form>
        <Col sm={12} md={6} lg={6} xs={12} xl={6}>
          <TextField
            id="name"
            label="First Name"
            name={'firstName'}
            variant='standard'
            value={formData.firstName}
            onChange={(e) => handleChange(e, 'firstName')}
            margin="normal"
            fullWidth={true}
          />
        </Col>
        <Col sm={12} md={6} lg={6} xs={12} xl={6}>
          <TextField
            id="lName"
            label="Last Name"
            name={'lastName'}
            variant='standard'
            value={formData.lastName}
            onChange={(e) => handleChange(e, 'lastName')}
            margin="normal"
            fullWidth={true}
          />
        </Col>
        <Col sm={12} md={6} lg={6} xs={12} xl={6}>
          <TextField
            id="email"
            label="Email"
            name={'email'}
            variant='standard'
            value={formData.email}
            onChange={(e) => handleChange(e, 'email')}
            margin="normal"
            fullWidth={true}
          />
        </Col>
        <Col sm={12} md={6} lg={6} xs={12} xl={6}>
          <TextField
            id="contact"
            label="Contact No."
            name={'phone'}
            variant='standard'
            value={formData.phone}
            onChange={(e) => handleChange(e, 'phone')}
            margin="normal"
            fullWidth={true}
          />
        </Col>
        <Col sm={12} md={6} lg={6} xs={12} xl={6}>
          <TextField
            id="password"
            label="Password"
            variant='standard'
            name={'password'}
            type='password'
            value={formData.password}
            onChange={(e) => handleChange(e, 'password')}
            margin="normal"
            fullWidth={true}
          />
        </Col>
        <Col sm={12} md={6} lg={6} xs={12} xl={6}>
          <TextField
            id="confirmPassword"
            label="Confirm Password"
            variant='standard'
            name={'confirmPassword'}
            type='password'
            value={formData.confirmPassword}
            onChange={(e) => handleChange(e, 'confirmPassword')}
            margin="normal"
            fullWidth={true}
          />
        </Col>
      </Row>
    </div>
  );

  const getComplianceForm = () => (
       <div in={true}>
        <Row>
           <Col sm={12} md={6} lg={6} xs={6} xl={6} className='mt-2'>
            <TextField
              id="name"
              variant='standard'
              label="License Number"
              name={'licenceId'}
              value={formData.licenceId}
              //onChange={this.handleChange}
              margin="normal"
              className='mt-4'
              fullWidth={true}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xs={6} xl={6} className='d-flex justify-content-center' >
            <div style={{ backgroundRepeat: 'none' }}>
              <img src={require('../assets/img/camera_1.png')} className='d-block mx-auto' />
              <p>Upload document</p>
            </div>

          </Col>
        </Row>
      </div>
  );

  const getBankForm = () => (
    <div in={true}>
         <Row>
           <Col sm={12} md={6} lg={6} xs={12} xl={6}>
         <TextField
              id="name"
              label="Account Number"
              name={'accountNumber'}
              variant='standard'
              value={formData.accountNumber}
              //onChange={this.handleChange}
              margin="normal"
              fullWidth={true}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xs={12} xl={6}>
            <TextField
            variant='standard'
              id="lName"
              label="IFSC"
              name={'ifscCode'}
              value={formData.ifscCode}
              //onChange={this.handleChange}
              margin="normal"
              fullWidth={true}
            />
          </Col>
          <Col sm={12} md={6} lg={6} xs={12} xl={6}>
            <TextField
            variant='standard'
              id="email"
              label="Recipient name"
              name={'recipientName'}
              value={formData.recipientName}
              //onChange={this.handleChange}
              margin="normal"
              fullWidth={true}
            />
          </Col>
        </Row>
      </div>
  );

  const handleChange = (e, key) => {
    setFormData((prevState) => ({ ...prevState, [key]: e.target.value }));
  };

  const handleBack = () => {
    setFormData((prevState) => ({ ...prevState, activeStep: prevState.activeStep - 1 }));
  };

  const handleClick = () => {
    const step = getSteps().length;
    if (formData.activeStep === step - 1) {
      return history('/verifyToken');
    }
    setFormData((prevState) => ({ ...prevState, activeStep: prevState.activeStep + 1 }));
  };

  const goToSignIn = () => {
    history('/signin');
  };

  const { activeStep } = formData;
  const { showMessage, loader, alertMessage } = props;

  return (
    <div className="col-xl-12 col-lg-12 signinContainer">
      <div className="jr-card signinCard">
        <div className="login-header">
          <a style={{textDecoration:'none'}} className="app-logo " href='javascript:void(0);' title="Jambo">
            <h2>Medimny</h2>
          </a>
        </div>
        <Stepper activeStep={activeStep} alternativeLabel={true} className="horizontal-stepper-linear">
          {getSteps().map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && getPersonalInformation()}
        {activeStep === 1 && getComplianceForm()}
        {activeStep === 2 && getBankForm()}
        <div className='list-action mt-3'>
          {activeStep !== 0 && <Button variant='contained' size='medium' className='m-2' onClick={handleBack}> Back</Button>}
          <Button variant='contained' color='primary' className='m-2' size='medium' onClick={handleClick}>
            {activeStep + 1 === getSteps().length ? 'Finish' : 'Next'}
          </Button>
        </div>
        <div style={{ textAlign: 'end' }}>
          <p>Already have an account? <a href='javascript:void(0);' onClick={goToSignIn}>Sign in</a></p>
        </div>
      </div>
      <div>
        {loader && <div className="loader-view"><CircularProgress /></div>}
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(SignUp);

