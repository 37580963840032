import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import ProductTable from 'components/productTable/index2';
import { FormGroup } from '@mui/material';
import { Col, Row, Label, Input } from 'reactstrap';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import helperFunction from 'constants/helperFunction';
import axios from 'constants/axios';

let monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
let date = new Date();

const SellingProductTab = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [filterValueTop, setFilterValueTop] = useState(monthArray[date.getMonth()] + ' ' + date.getFullYear())
  const [month, setMonth] = useState(monthArray[date.getMonth()])
  const [year, setYear] = useState(date.getFullYear())
  const [yearList, setYearList] = useState([])
  const [topBuyers, setTopBuyers] = useState('')

  useEffect(() => {
    let years = helperFunction.yearList();
    setYearList(years)
    let data = {
      user_type: 'buyer',
      id: props.id,
      month: monthArray[date.getMonth()],
      year: date.getFullYear()
    }
    axios.post('order/getBuyerSellerByRevenue', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        setTopBuyers(result.data.buyer)
        setAnchorEl(null)
      }
    }).catch(error => {
      console.log("error catch", error)
    });
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null)
  }

  const handleApplyFilter = async (e) => {
    e.preventDefault();
    let data = {
      user_type: 'buyer',
      id: props.id,
      month: month,
      year: year
    }
    await axios.post('order/getBuyerSellerByRevenue', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        setTopBuyers(result.data.buyer)
        setAnchorEl(null)
        setFilterValueTop(month + ' ' + year)
      }
    }).catch(error => {
      console.log("error catch", error)
    });
  }

  const handleResetFilter = async (e, filter) => {
    e.preventDefault();
    setMonth(monthArray[date.getMonth()])
    setYear(date.getFullYear())
    setFilterValueTop(monthArray[date.getMonth()] + ' ' + date.getFullYear())
    let data = {
      user_type: 'buyer',
      id: props.id,
      month: monthArray[date.getMonth()],
      year: date.getFullYear()
    }
    await axios.post('order/getBuyerSellerByRevenue', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        setTopBuyers(result.data.buyer)
        setAnchorEl(null)
      }
    }).catch(error => {
      console.log("error catch", error)
    });
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xl-12 col-12">
          <div className="jr-card">
            <div className="jr-card-header d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="d-inline-block mb-0">Top 10 Sellers</h3>
                {
                  filterValueTop === '' ? null : <span className="text-white badge badge-success">{filterValueTop}</span>
                }
              </div>
              <IconButton className="icon-btn" onClick={handleClick}>
                <i className="zmdi  zmdi-filter-list" />
              </IconButton>
            </div>
            <ProductTable value={topBuyers} />
          </div>
        </div>
      </div>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(e) => handleClose(e)}
      >
        <MenuItem className="filterBody">
          <div className="flex">
            <Row form>
              <Col md={6} xl={6} xs={6} lg={6}>
                <FormGroup>
                  <Label for="exampleSelect">Select Month</Label>
                  <Input type="select" value={month} name="select" onChange={(e) => setMonth(e.target.value)} id="exampleSelect">
                    {
                      monthArray.map((value, key) => {
                        return <option key={key} value={value}>{value}</option>
                      })
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6} xl={6} xs={6} lg={6}>
                <FormGroup>
                  <Label for="exampleSelect">Select Year</Label>
                  <Input type="select" value={year} onChange={(e) => setYear(e.target.value)} name="select" id="exampleSelect">
                    {
                      yearList.map((year, key) => {
                        return <option key={key} value={year}>{year}</option>
                      })
                    }
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <div style={{ paddingTop: 15 }} >
              <Button variant="contained" className='filterButton' onClick={(e) => handleApplyFilter(e)} color='primary'>Apply Filter</Button>
              <Button variant="contained" className='filterButton' onClick={(e) => handleResetFilter(e)} color='primary'>Reset Filter</Button>
            </div>
          </div>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default SellingProductTab;