import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { changeTab } from '../../../../../../actions/tabAction';
import { connect } from 'react-redux';
import GroupSettlements from 'app/routes/settlementDetails/routes/GroupSettlements';
import SettlementDetails from 'app/routes/settlementDetails/routes/SettlementDetails';


function TabContainer({ children, dir }) {
  return (
    <div dir={dir}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class FullWidthTabsForDetails extends Component {
  state = {
    tabValue: 0,
  };

  handleChange = (event, value) => {
    this.setState({ tabValue: value })
  };

  handleChangeIndex = index => {
    this.setState({ tabValue: index })
  };
  componentDidMount = () => {
    this.setState({ tabValue: 0 })
  }
  render() {
    const { theme, tabFor } = this.props;
    const { tabValue } = this.state;
    return (
      <div className="w-100" >
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="on"
          >
            <Tab className="tab" label="Open Settlements" />
            <Tab className="tab" label="Closed Settlements" />
          </Tabs>
        </AppBar>

        {tabValue == 0 && <TabContainer  >
          {
            tabFor === 'settlementDetails' ? <SettlementDetails match={this.props.match} history={this.props.history} sellerDataFromParent={this.props.sellerData} hitApi={this.props.hitApi} /> : ''
          }
        </TabContainer>}
        {tabValue == 1 &&  <TabContainer  >
          {
            tabFor === 'settlementDetails' ? <GroupSettlements match={this.props.match} history={this.props.history} /> : ''
          }
        </TabContainer>}
      </div>
    );
  }
}

FullWidthTabsForDetails.propTypes = {
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = ({ tabAction }) => {
  const { tabValue } = tabAction;
  return { tabValue }
};

export default connect(mapStateToProps, { changeTab })(FullWidthTabsForDetails);