import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { FormGroup, Label, Input } from 'reactstrap';
import { Field, Form } from 'react-final-form'
import '../../orders/routes/index.css'
import DialogTitle from '@mui/material/DialogTitle';
import ReactstrapSelectField from '../../components/reactstrapSelectField';
import ReactStrapTextField from '../../components/ReactStrapTextField';
import Drop from '../../components/Dropzone';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { connect } from 'react-redux';
import axios from 'axios';
import AppConfig from 'constants/config';
import { required, adjustmentAmtS, adjustmentAmtB } from 'constants/validations';
import AxiosRequest from 'sagas/axiosRequest';
import { getAddTicket } from 'actions/admin';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

function TicketHeader(props) {

  const [state, setState] = useState({
    orderDate: '',
    orderId: '',
    invoiceAmt: '',
    problem: '',
    rtoCharge: '',
    rtoDate: '',
    rtoSeller: '',
    actionBuyer: ' ',
    actionSeller: ' ',
    buyerId: '',
    revisedAmt: '',
    ogInvoiceAmt: '',
    selectedDate: moment().format(),
    sellerId: ' ',
    rtoChargeable: ' ',
    rtoAmt: 0,
    gstOnRto: 0,
    countData: '',
    submitting: false,
    loader: false
  })

  const [uploadedFileArr, setUploadedFileArr] = useState([])
  const [newFileUpload, setnewFileUpload] = useState([])
  const [error, setError] = useState('')

  const handleRequestClose = () => {
    setUploadedFileArr([])
    setnewFileUpload([])
    setError('')
    setState((prev) => ({
      ...prev,
      orderDate: '', buyerName: '', sellerName: '', order_id: '', openFilter: false, submitting: false, rtoAmt: 0, gstOnRto: 0, sellerAdjustedAmt: '', buyerAdjustedAmt: ''
      , rtoDeliveryDate: '', loader: false, orderId: '', rtoChargeable: ' '
    }))

  }
  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val === key)
    return index > -1 ? true : false
  }
  const handleChange = async (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
    if (key === 'orderId') {
      setError('')
    }
    if (key === 'orderId' && e.target.value.length > 2) {
      let data = {
        orderId: e.target.value
      }
      let result = await AxiosRequest.axiosHelperFunc('post', 'ticket/getOrderForTicket', '', data)

      if (result.data.error) {
        setError(result.data.title)
        setState((prev) => ({ ...prev, orderDate: '', sellerName: '', ogInvoiceAmt: '', order_id: '', submitting: true }))
      } else {
        setState((prev) => ({ ...prev, orderDate: moment(result.data.data.createdAt).format('DD/MM/YYYY'), order_id: result.data.data._id, sellerName: result.data.data.sellerCompName, buyerName: result.data.data.buyerCompName, ogInvoiceAmt: result.data.data.total_amount, submitting: false }));
      }
    }
    if (key === 'rtoChargeable') {
      if (e.target.value === 'buyer') {
        setState((prev) => ({ ...prev, rtoAmt: 212, gstOnRto: 0 }));
      } else if (e.target.value === 'seller') {
        setState((prev) => ({ ...prev, rtoAmt: 180, gstOnRto: 32.4 }));
      } else {
        setState((prev) => ({ ...prev, rtoAmt: 0, gstOnRto: 0 }));
      }
    }
  }

  const onSubmit = async (values) => {
    await setState((prev) => ({ ...prev, submitting: true, loader: true }))
    let { order_id, rtoDeliveryDate, rtoAmt, gstOnRto } = state;
    let data = {
      type: values.type,
      orderId: state.orderId,
      issue: values.issue,
      ogInvoiceAmt: state.ogInvoiceAmt,
      order_id: order_id,
      supportings: newFileUpload,
      issueDescription: values.type === 'order' ? values.issueDescriptionOR : values.issueDescriptionO,
      revisedInvoiceAmt: values.revisedInvoiceAmt ? values.revisedInvoiceAmt : 0,
      rtoDeliveryDate: rtoDeliveryDate,
      rtoChargeable: state.rtoChargeable,
      rtoAmt: rtoAmt ? rtoAmt : 0,
      gstOnRto: gstOnRto ? gstOnRto : 0,
      buyerAdjustedAmt: values.buyerAdjustedAmt ? values.buyerAdjustedAmt : 0,
      newBuyerAdjustedAmt: values.rtoChargeable === 'buyer' ? Number(values.buyerAdjustedAmt ? values.buyerAdjustedAmt : 0) - 212 : values.buyerAdjustedAmt ? values.buyerAdjustedAmt : 0,
      sellerAdjustedAmt: values.sellerAdjustedAmt ? values.sellerAdjustedAmt : 0
    }
    props.getAddTicket({ data, history: props.history })
    setTimeout(() => handleRequestClose(), 1000);
  }
  const getFiles = async (data1) => {
    setUploadedFileArr((prev) => [...prev, ...data1])
    data1.map(data => {
      let reader;
      let file;
      file = data;
      reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        let joined = newFileUpload.concat(e.target.result);
        setnewFileUpload(joined)
      }
    })
  }
  const remove = (file, i) => {
    let index = uploadedFileArr.findIndex(e => e.name === file.name);
    let temp = [...uploadedFileArr]
    temp.splice(index, 1)
    setUploadedFileArr(temp)
    let temp1 = [newFileUpload]
    temp1.splice(i, 1)
    setnewFileUpload(temp1)
  }
  const ApiCall = async () => {
    await axios({
      method: 'get',
      url: `${AppConfig.baseUrl}ticket/ticketCount`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
      } else {
        setState((prev) => ({ ...prev, countData: result.data.data }))
      }
    }).catch(error => { });
  }
  useEffect(() => {
    ApiCall()
  }, [])

  let { orderId, rtoChargeable, orderDate, buyerName, selectedDate, sellerName, ogInvoiceAmt, countData, rtoAmt, gstOnRto, submitting, loader } = state;

  return (
    <div className="page-heading buyerDetailsHeader d-flex justify-content-between align-items-sm-center">
      <h2 className="mb-3 mb-sm-0 font-weight-bold">No of open tickets: {countData && countData} </h2>
      {(isButtonAvailable('addTicket') || isButtonAvailable('addTicketStaff')) &&
        <Button variant="outlined" className={'text-primary'} onClick={() => { setState((prev) => ({ ...prev, openFilter: true })) }}>
          Add +
        </Button>
      }

      <Dialog open={state.openFilter} onClose={handleRequestClose} fullWidth={true} maxWidth='md'>
        <DialogTitle className='pb-0'>
          Add Ticket
        </DialogTitle>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            type: 'order',
            issue: '',
            revisedInvoiceAmt: '',
            ogInvoiceAmt: '',
            rtoChargeable: '',
            buyerAdjustedAmt: '',
            sellerAdjustedAmt: '',
            issueDescriptionOR: '',
            issueDescriptionO: ''

          }}
          render={({ handleSubmit, values }) => {
            return <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <DialogContent >
                {values.type === 'order' ?
                  <>
                    <Row>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>
                          <Field
                            name="type"
                            component={ReactstrapSelectField}
                            id="type"
                            type="select"
                            label='Ticket type'
                          >
                            <option value="order">Order Related</option>
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>
                          <Field
                            name="issue"
                            component={ReactstrapSelectField}
                            id="issue" sellerName
                            type="select"
                            label='Issue type'
                            validate={required}
                          >
                            <option value="">Select issue</option>
                            <option value="Short supply">Short supply</option>
                            <option value="Damaged products">Damaged products</option>
                            <option value="Wrong invoice">Wrong invoice</option>
                            <option value="Diff in Invoice and Docket">Diff in Invoice and Docket</option>
                            <option value="Short Expiry">Short Expiry</option>
                            <option value="Order by mistake">Order by mistake</option>
                            <option value="Extra product received">Extra product received</option>
                            <option value="Damaged package received">Damaged package received</option>
                            <option value="Rescheduling related">Rescheduling related</option>
                            <option value="Order not processed">Order not processed</option>
                            <option value="Excess weight">Excess weight</option>
                            <option value="Other issue">Other issue</option>
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>
                          <Label for="orderDate">Order Id</Label>
                          <Input
                            onChange={(value) => handleChange(value, 'orderId')}
                            type="text" name="orderDate" value={orderId} id="orderDate" />
                        </FormGroup>
                        {error && error.length > 0 && <span className='text-danger'>{error}</span>}
                      </Col>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>
                          <Label for="orderDate">Order Date</Label>
                          <Input disabled type="text" name="orderDate" value={orderDate} id="orderDate" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>
                          <Label for="buyerName">Buyer Name</Label>
                          <Input disabled type="text" name="buyerName" value={buyerName} id="buyerName" />
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>

                        <FormGroup>
                          <Label for="sellerName">Seller Name</Label>
                          <Input disabled type="text" name="sellerName" value={sellerName} id="sellerName" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>
                          <Label for="ogInvoiceAmt">Original Amount</Label>
                          <Input disabled type="text" name="ogInvoiceAmt" value={ogInvoiceAmt} id="ogInvoiceAmt" />
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>
                          <Field id="revisedInvoiceAmt" name="revisedInvoiceAmt" type="text"
                            component={ReactStrapTextField} label="Revised Invoice Amount"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Drop handleFiles={getFiles} accept="image/*, application/pdf" />
                        {uploadedFileArr && uploadedFileArr.length > 0 && <div className="dropzone-content">
                          <ul className="mb-2" style={{ listStyleType: 'none' }}>
                            {
                              uploadedFileArr.map((file, i) => <li key={file.path}>
                                <IconButton className='clear mr-2'><ClearIcon onClick={(e) => remove(file, i)} /></IconButton>{file.path} - {file.size} bytes
                              </li>
                              )
                            }
                          </ul>
                        </div>
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>
                          <Label for="rtoChargeable">RTO Chargeable</Label>
                          <Input type="select" value={rtoChargeable}
                            name="rtoChargeable" id="rtoChargeable"
                            onChange={(value) => handleChange(value, 'rtoChargeable')}
                          >
                            <option value=" ">Non-chargeable</option>
                            <option value="buyer">Buyer</option>
                            <option value="seller">Seller</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>
                          <Label >RTO Delivery date</Label><br />
                          <TextField
                            onChange={(date) => handleChange(date, 'rtoDeliveryDate')}
                            name='rtoDeliveryDate'
                            id="rtoDeliveryDate"
                            variant='standard'
                            fullWidth
                            type="date"
                            defaultValue={selectedDate}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormGroup>
                      </Col>

                    </Row>
                    <Row>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>

                          <Label for="rtoAmt">RTO Amount</Label>
                          <Input disabled type="text" name="rtoAmt" value={rtoAmt} id="rtoAmt" />
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>

                          <Label for="gstOnRto">GST on RTO</Label>
                          <Input disabled type="text" name="gstOnRto" value={gstOnRto} id="gstOnRto" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>
                          <Field id="buyerAdjustedAmt" name="buyerAdjustedAmt" type="text"
                            component={ReactStrapTextField} label="Buyer Adjustment Amount" validate={adjustmentAmtB}

                          />
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                        <FormGroup>
                          <Field id="sellerAdjustedAmt" name="sellerAdjustedAmt" type="text"
                            component={ReactStrapTextField} label="Seller Adjustment Amount" validate={adjustmentAmtS}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup >
                          <Field label='Issue Description' type='textarea' name='issueDescriptionOR'
                            rows="3"
                            component={ReactStrapTextField} validate={required}
                            id="issueDescriptionOR"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                  :
                  <div style={{ fontSize: 'larger' }}> Please select ticket type</div>
                }
                <DialogActions>
                  <Button onClick={() => handleRequestClose()} color='secondary'>Cancel</Button>
                  {(values.type !== '' && !submitting && !loader) ?
                    <Button type='submit' color='primary' disabled={state.submitting}>Add</Button> :
                    loader ? <CircularProgress size={30} /> :
                      <Button disabled={true} color='primary'>Add</Button>
                  }
                </DialogActions>
              </DialogContent>
            </form>
          }}
        />

      </Dialog>

    </div>
  )
};

const mapStateToProps = ({ admin, form }) => {
  const { actionList } = admin
  return { actionList }
}
export default connect(mapStateToProps, { getAddTicket })(TicketHeader)