import React, { useState } from "react";
import FullWidthTabs from '../../components/routes/tabs/fixed/FullWidthTabsSettlement';
import SettlementHeader from './SettlementHeader'
import { useNavigate } from "react-router";
import AdminContainer from "containers/AdminContainer";

function SettlemetsTab() {
  const history = useNavigate()
  return (
    <AdminContainer>
      <React.Fragment>
        <SettlementHeader />
        <FullWidthTabs history={history} tabFor={'settlement'} />
      </React.Fragment >
    </AdminContainer>
  );
}


export default SettlemetsTab;