import React, { useEffect, useState } from 'react'
import ContainerHeader from '../../../../components/ContainerHeader';
import IntlMessages from '../../../../util/IntlMessages';
// import WithIconTimeLineItem from 'components/timeline/WithIconTimeLineItem';
import { NewReleases, CreditCard } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getOrderDetails } from 'actions/admin';
import WithIconTimeLineItem from './component';
import { useNavigate, useParams } from 'react-router';
import AdminContainer from 'containers/AdminContainer';


function TrackOrders(props) {

  const [state, setState] = useState({
    activeStep: 3
  })
  const history = useNavigate()
  const { id } = useParams()
  function fetchApi() {
    props.getOrderDetails({ history: history, data: { orderId: id } })
  }
  useEffect(() => {
    fetchApi()
  }, [])

  let { orderDetails } = props;
  return (
    <AdminContainer>
      <div >
        <ContainerHeader match={props.match} title={`Order ID: ${id}`} />
        <div className="timeline-section timeline-center clearfix animated slideInUpTiny animation-duration-3" >
          {
            orderDetails && Object.keys(orderDetails).length > 0 && orderDetails.order_status.length > 0 ?
              orderDetails.order_status.map((value, key) => {
                return <WithIconTimeLineItem styleName={(key === 0 || key === 2 || key === 4 || key === 6 || key === 8 || key === 10) ? 'timeline-inverted' : ''} timeLine={value} color={value.status === 'Processed' ? 'blue' : value.status === 'New' ? 'red' : value.status === 'Cancelled' ? 'grey' : value.status === 'Ready For Dispatch' ? 'orange' : value.status === 'Delivered' ? 'green' : 'info'}>
                  {
                    value.status === 'Processed' ? <CreditCard /> : value.status === 'New' ? <NewReleases /> : value.status === 'Cancelled' ? <div><i style={{ fontSize: '1.5rem', paddingTop: 14 }} class="zmdi zmdi-close"></i></div> : value.status === 'Ready For Dispatch' ? <div><i style={{ fontSize: '1.5rem', paddingTop: 14 }} class="zmdi zmdi-run"></i></div> : value.status === 'Delivered' ? <div><i style={{ fontSize: '1.5rem', paddingTop: 14 }} class="zmdi zmdi-check"></i></div> : <div><i style={{ fontSize: '1.5rem', paddingTop: 14 }} class="zmdi zmdi-truck"></i></div>}
                </WithIconTimeLineItem>
              }) : ''
          }
        </div>
      </div >
    </AdminContainer>
  );
}

const mapStateToProps = ({ admin }) => {
  const { orderDetails } = admin;
  return { orderDetails }
};

export default connect(mapStateToProps, { getOrderDetails })(TrackOrders);