import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from "tss-react/mui";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import axios from '../../../../constants/axios';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { getOrderDetails } from 'actions/admin';
import { FormGroup, Col, Row, Label, Input } from 'reactstrap';
import { useParams } from 'react-router';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

function ChangeStatus(props) {
  const { id } = useParams()
  const [state, setState] = useState({
    open: false,
    counter: 0,
    otp: '',
    selectedValue: 'Admin has cancelled this order.',
    set: false,
    showOTP: '',
    showEmail: '',
  });

  const handleRequestClose = () => {
    setState({ open: false, status: '', set: false, showOTP: '', showEmail: ''});
  };

  const handleSubmit = async () => {
    let data = {
      order_cancel_reason: state.selectedValue,
      status: 'Cancelled',
      orderId: id,
      otp: Number(state.otp),
    }

    await axios.post('/order/proccessCancelOrder', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }
    ).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
        setState((prev) => ({ ...prev, counter: state.counter + 1 }))
      } else {
        NotificationManager.success(result.data.title);
        props.getOrderDetails({ history: '', data: { orderId: id } })
        setState((prev) => ({ ...prev, counter: 0, otp: '', showOTP: '', showEmail: ''}))
      }
    })
      .catch(error => {
        console.log("error", error)
        NotificationManager.error('Something went wrong, Please try again')
      });
    setState((prev) => ({ ...prev, open: false }));
  }

  const d = (event) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, selectedValue: event.target.value }))
  }
  const handleChanged = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }))
  }
  const getOtp = async () => {
    await setState((prev) => ({ ...prev, set: true }))
    let data = {
      type: 'Order',
      action: 'Cancel order',
      counter: Number(state.counter),
      typeId: props.orderDetails._id
    }
    await axios.post('/systemActivity/addActivity', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then((result) => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title);
        setState((prev) => ({ ...prev, showOTP: result?.data?.otp, showEmail: result?.data?.email }))
      }
    })
  }
  let { selectedValue, set } = state;
  let { status } = props;
  return (
    <div>
      <Button className={'jr-btn-xs ml-1'} disabled={(status === 'Requested' || status === 'Cancelled' || status === 'Delivered') ? true : false} variant="contained" color="primary" onClick={() => setState((prev) => ({ ...prev, open: true }))}> CANCEL ORDER </Button>

      <Dialog open={state.open} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle>
          Cancel Order
          <DialogContentText className="mt-0 mb-0">
            Are you sure you want to cancel the order ?
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup aria-label="reason" name="customized-radios" value={selectedValue} onChange={(e) => d(e)}>
              <FormControlLabel value="Admin has cancelled this order." control={<StyledRadio />} label="Admin has cancelled this order." />
            </RadioGroup>
          </FormControl>
          <Row>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ paddingTop: '19px' }}>
              <Button onClick={getOtp} color='primary' variant="outlined" fullWidth={true} disabled={set}>
                Generate OTP
              </Button>
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ paddingTop: '19px' }}>
              <FormGroup>
                {/* <Label className='p-0 m-0'>Enter OTP</Label> */}
                <Input id="otp" name="otp" type="text" placeholder="Insert OTP"
                  onChange={(event) => handleChanged(event, 'otp')}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {set && 
              <div className='text-start'>OTP <span className='underline'>{state.showOTP}</span> has been sent to {state.showEmail}</div>
            }
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color='secondary' >
            No
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const mapStateToProps = ({ admin }) => {
  const { orderDetails } = admin;
  return { orderDetails }
};


export default connect(mapStateToProps, { getOrderDetails })(ChangeStatus)