import React, { Component } from 'react';
import { salesStatisticData } from "../../../app/routes/dashboard/routes/Listing/data";
import SalesGauge from "components/dashboard/eCommerce/SalesGauge";
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { getSalesStatistics } from '../../../actions/admin'
// import {withRouter} from 'react-router-dom'
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import axios from '../../../constants/axios';
import Menu from '@mui/material/Menu';
import { FormGroup } from '@mui/material';
import { Col, Row, Label, Input } from 'reactstrap';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

let date = new Date();



class SalesStatistic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: [],
      open: false,
      anchorEl: null,
      year: date.getMonth() <= 2 ? date.getFullYear() - 1 : date.getFullYear(),
      renderYear: date.getMonth() <= 2 ? date.getFullYear() - 1 : date.getFullYear(),
      yearList: []
    };
  }

  componentDidMount = async () => {
    let data = {
      id: this.props.id,
      year: this.state.year
    }
    await axios.post('order/dashboardSalesStats', data, {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      },
    }
    ).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        console.log("success", result.data)
        this.setState({
          graphData: result.data.montlyRevenueOrders,
        })
      }
    })
      .catch(error => {
        console.log("error catch", error)
      });
    let yearArr = [];
    for (let i = date.getFullYear(); i >= 2019; i--) {
      yearArr.push({ year: i, string: `${i}-${i + 1}` });
    }
    this.setState({ yearList: yearArr })
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.salesStatistics !== this.props.salesStatistics) {
      const { salesStatistics } = this.props;
      this.setState({ graphData: salesStatistics.montlyRevenueOrders });
    }
  }


  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleCloseMenu = (e) => {
    e.preventDefault();
    this.setState({
      anchorEl: null
    })
  }
  handleClose = async (e) => {
    e.preventDefault();
    await this.setState({
      anchorEl: null, year: date.getMonth() <= 2 ? date.getFullYear() - 1 : date.getFullYear(),
      renderYear: date.getMonth() <= 2 ? date.getFullYear() - 1 : date.getFullYear()
    })
    let data = {
      id: this.props.id,
      year: date.getMonth() <= 2 ? date.getFullYear() - 1 : date.getFullYear()
    }
    await axios.post('order/dashboardSalesStats', data, {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      }
    }
    ).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        console.log("success", result.data)
        this.setState({
          graphData: result.data.montlyRevenueOrders,
        })
      }
    })
      .catch(error => {
        console.log("error catch", error)
      });
  }

  handleApplyFilter = async (e) => {
    e.preventDefault();
    let data = {
      id: this.props.id,
      year: this.state.year
    }
    this.setState({ anchorEl: null, renderYear: Number(this.state.year) })
    await axios.post('order/dashboardSalesStats', data, {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      }
    }
    ).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        console.log("success", result.data)
        this.setState({
          graphData: result.data.montlyRevenueOrders,
        })
      }
    })
      .catch(error => {
        console.log("error catch", error)
      });
  }


  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value })
  }
  render() {
    let { lastWeekRevenueOrders, lastThirtyDaysRevenueOrders, totalRevenueOrders, montlyRevenueOrders, salesStatistics } = this.props;
    let { graphData, year, anchorEl, yearList, renderYear } = this.state;
    let finalGraphData = [];

    let data = [
      { Month: 'Apr', Orders: 0, Amount: 0 },
      { Month: 'May', Orders: 0, Amount: 0 },
      { Month: 'Jun', Orders: 0, Amount: 0 },
      { Month: 'Jul', Orders: 0, Amount: 0 },
      { Month: 'Aug', Orders: 0, Amount: 0 },
      { Month: 'Sep', Orders: 0, Amount: 0 },
      { Month: 'Oct', Orders: 0, Amount: 0 },
      { Month: 'Nov', Orders: 0, Amount: 0 },
      { Month: 'Dec', Orders: 0, Amount: 0 },
      { Month: 'Jan', Orders: 0, Amount: 0 },
      { Month: 'Feb', Orders: 0, Amount: 0 },
      { Month: 'Mar', Orders: 0, Amount: 0 },
    ];

    graphData && graphData.sort(function (a, b) {
      return parseFloat(a._id) - parseFloat(b._id);
    }).map((dataOne, index) => {

      if (dataOne._id == '04') {
        data[0] = { Month: 'Apr', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '05') {
        data[1] = { Month: 'May', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '06') {
        data[2] = { Month: 'Jun', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '07') {
        data[3] = { Month: 'Jul', Orders: dataOne.orders, Amount: ((dataOne.amount).toFixed(2)) }
      }
      if (dataOne._id == '08') {
        data[4] = { Month: 'Aug', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '09') {
        data[5] = { Month: 'Sep', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '10') {
        data[6] = { Month: 'Oct', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '11') {
        data[7] = { Month: 'Nov', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '12') {
        data[8] = { Month: 'Dec', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '01') {
        data[9] = { Month: 'Jan', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '02') {
        data[10] = { Month: 'Feb', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
      if (dataOne._id == '03') {
        data[11] = { Month: 'Mar', Orders: dataOne.orders, Amount: (dataOne.amount).toFixed(2) }
      }
    })
    //  toLocaleString('en-IN')
    let n1;
    let maxValue;
    graphData && graphData.sort(function (a, b) {
      return parseFloat(a.amount) - parseFloat(b.amount);
    }).map((dataOne, index) => {
      n1 = dataOne.amount / 2;
      maxValue = parseInt(dataOne.amount + n1);
    })
    return (
      <React.Fragment>
        <div className="jr-card">
          <div className="row">

            <div className="jr-card-header d-flex align-items-center col-6 col-sm-6 col-md-6 col-lg-6">
              <h3 className="mb-0">Sales Statistics For {renderYear} - {Number(renderYear) + 1}</h3>
            </div>
            <div className="jr-card-header d-flex col-6 col-sm-6 col-md-6 col-lg-6">
              <IconButton className="icon-btn" style={{ marginRight: "0px", marginLeft: "auto" }} onClick={(e) => this.handleClick(e)}>
                <i className="zmdi  zmdi-filter-list" />
              </IconButton>
            </div>

          </div>

          <div className="row">
            <div className="col-lg-12 col-12 mb-12 mb-lg-12">
              <ResponsiveContainer width="100%" height={350}>
                <BarChart
                  width={500}
                  height={350}
                  data={data}
                  margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Month" />
                  <YAxis yAxisId="left" domain={[0, 1000]} orientation="left" stroke="#193298" />
                  <YAxis yAxisId="right" domain={[0, maxValue]} orientation="right" stroke="#f0a500" />
                  <Tooltip formatter={(value) => new Intl.NumberFormat('en-IN').format(value)} />
                  <Legend />
                  <Bar yAxisId="left" dataKey="Orders" fill="#193298" />
                  <Bar yAxisId="right" dataKey="Amount" fill="#f0a500" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={(e) => this.handleCloseMenu(e)}
        >
          <MenuItem className="filterBody">
            <div className="flex">
              <Row form>
                <Col sm={12} md={12} xl={12} xs={12} lg={12}>
                  <FormGroup>
                    <Label for="exampleSelect">Select Year</Label>
                    <Input type="select" value={year} onChange={(e) => this.handleChange(e, 'year')} name="year" id="year">
                      {
                        yearList.map((value, key) => {
                          return <option value={parseInt(value.year)} >{value.string}</option>
                        })
                      }
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <div style={{ paddingTop: 15 }} >
                <Button variant="contained" className='filterButton' onClick={(e) => this.handleApplyFilter(e)} color='primary'>Apply Filter</Button>
                <Button variant="contained" className='filterButton' onClick={(e) => this.handleClose(e)} color='primary'>Reset Filter</Button>
              </div>
            </div>
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
};

const mapStateToProps = ({ admin }) => {
  let { lastWeekRevenueOrders,
    lastThirtyDaysRevenueOrders,
    totalRevenueOrders,
    montlyRevenueOrders,
    salesStatistics,
  } = admin;
  return {
    lastWeekRevenueOrders,
    lastThirtyDaysRevenueOrders,
    totalRevenueOrders,
    montlyRevenueOrders,
    salesStatistics
  }
}

export default connect(mapStateToProps, { getSalesStatistics })((SalesStatistic));
