import React, { Component, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddGroup from '../../groups/addGroup'
import DialogContentText from '@mui/material/DialogContentText';
import { listGroup, addStaff, listPermissionModules } from 'actions/admin';
import { connect } from 'react-redux';
import ReactStrapTextField from '../../components/ReactStrapTextField/index';
import ReactstrapSelectField from '../../components/reactstrapSelectField/index';
import { Switch } from '@mui/material';
import { Field, Form } from 'react-final-form'
import { required, emailField, validatePhone } from '../../../../constants/validations';
import Axios from '../../../../sagas/axiosRequest';
import CircularProgress from '@mui/material/CircularProgress';

function EditStaff(props) {
    const [state, setState] = useState({
        name: '',
        email: '',
        phone: '',
        groupId: '',
        permission: [],
        groups: [],
        addGroup: false,
        isAllPermission: false,
        isRM: false,
        staffId: ''
    })

    const IsRM = async (e) => {
        if (state.isRM) {
            setState((prev) => ({ ...prev, isRM: false }))
            let data = { staffId: state.staffId, isRM: false }

            let IsRM = await Axios.axiosHelperFunc('post', 'users/IsRm', "", data);

        } else {
            setState((prev) => ({ ...prev, isRM: true }))
            let data = { staffId: state.staffId, isRM: true }

            let IsRM = await Axios.axiosHelperFunc('post', 'users/IsRm', "", data);

        }
    };

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            name: props.selectedData ? props.selectedData.first_name : state.name,
            email: props.selectedData ? props.selectedData.email : state.email,
            phone: props.selectedData ? props.selectedData.phone : state.phone,
            staffId: props.selectedData ? props.selectedData._id : state.staffId,
            groupId: props.selectedData ? props.selectedData.Groups._id : state.groupId,
            permission: props.selectedData ? props.selectedData.Groups.permissions : state.permission
        }));
    }, [props.selectedData])

    const fetchApi = async () => {
        props.listPermissionModules({ history: props.history })
        let groupListData = await Axios.axiosHelperFunc('get', 'seller/getGroupList', '', {});
        setState((prev) => ({
            ...prev,
            name: props.selectedData ? props.selectedData.first_name : state.name,
            email: props.selectedData ? props.selectedData.email : state.email,
            phone: props.selectedData ? props.selectedData.phone : state.phone,
            staffId: props.selectedData ? props.selectedData._id : state.staffId,
            isRM: props.selectedData && props.selectedData.isRM,
            groupId: props.selectedData ? props.selectedData.Groups._id : state.groupId,
            isAllPermission: props.selectedData ? (props.selectedData.Groups.permissions.length === props.listpermissionModulesdata.length) ? true : false : false,
            permission: props.selectedData ? props.selectedData.Groups.permissions : state.permission,
            groups: groupListData.data.detail
        }));
    }

    useEffect(() => {
        fetchApi()
    }, [])

    const handleClick = (key) => {
        setState((prev) => ({
            ...prev, [key]: !state[key]
        }));
    }

    const handleAddGroupClick = (key) => {
        props.handleClick('add')
        setState((prev) => ({
            ...prev, [key]: !state[key]
        }));
    }

    const handleRequestClose = () => {
        props.handleClick('edit');
        setState((prev) => ({
            ...prev, permission: [], email: '', name: '', phone: '', groupId: ''
        }));
    };

    const handleChange = (event, key) => {
        if (key === 'group') {
            let index = state.groups.findIndex((e) => e._id === event.target.value);

            if (index > -1) {
                let tempPermission = state.permission;
                tempPermission = state.groups[index].permissions;

                let isAdminAccess = tempPermission.length === props.listpermissionModulesdata.length ? true : false;
                setState((prev) => ({
                    ...prev, permission: tempPermission,
                    groupId: event.target.value, isAllPermission: isAdminAccess
                }));
            } else {
                setState((prev) => ({ ...prev, groupId: '' }));
            }
        } else {
            setState((prev) => ({ ...prev, [key]: event.target.value }));
        }
    }

    const onSubmit = (values) => {
        let data = {
            first_name: values.name,
            email: values.email,
            phone: values.phone,
            groupId: values.groupId,
            staffId: state.staffId,
            isAllPermission: state.isAllPermission
        }
        console.log("Dddddddd65465465dddddddd", data)
        props.handleClick('edit')
        props.addStaff({ history: props.history, data, listStaff: { searchText: '', page: 1, perPage: 50 } })
        setState((prev) => ({ ...prev, permission: [], email: '', name: '', phone: '', groupId: '' }));
    }

    const { email, name, groups, phone, permission, groupId, staffId } = state;
    const { edit, add, title, handleSubmit, listpermissionModulesdata, submitting } = props;

    return (
        <React.Fragment>
            <Dialog open={edit} onClose={handleRequestClose}
                fullWidth={true}
                maxWidth={'md'}>
                <Form
                    onSubmit={onSubmit}
                    initialValues={
                        {
                            name: name || '',
                            email: email || '',
                            phone: phone || '',
                            groupId: groupId || ''
                        }
                    }
                    render={({ handleSubmit, values }) => (
                        <form noValidate onSubmit={handleSubmit} autoComplete="off">
                            {props.loading === true ?
                                <div className="loader-view"
                                    style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
                                    <CircularProgress />
                                </div> :
                                <>
                                    <DialogTitle>
                                        <span>
                                            {title}
                                            {
                                                <span className='status'
                                                    style={{ marginLeft: '65%', fontWeight: 600, fontSize: '15px', textAlignLast: 'right' }}
                                                > RM
                                                    <Switch
                                                        color='primary'
                                                        checked={state.isRM}
                                                        onChange={(e) => IsRM(e)}
                                                        aria-label="isRM"
                                                        className="codToggle"
                                                    />
                                                </span>}
                                        </span>
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                        </DialogContentText>
                                        <Row>
                                            <Col xs={12} md={12} sm={12} xl={2} lg={2} className="addStaffAvatar">
                                                <Avatar className="size-100" alt="Remy Sharp" src={require('assets/productImage/avatar.png')} />
                                            </Col>
                                            <Col xs={12} md={12} sm={12} xl={10} lg={10}>
                                                <Row>
                                                    <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                                                        <Field id="name" name="name" type="text"
                                                            component={ReactStrapTextField} label="Name"
                                                            validate={required}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                                                        <Field id="email" name="email" type="text"
                                                            component={ReactStrapTextField} label="Email ID"
                                                            validate={emailField}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                                                        <Field id="phone" name="phone" type="text"
                                                            component={ReactStrapTextField} label="Phone"
                                                            validate={validatePhone}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                                                        <Row>
                                                            {

                                                            }
                                                            <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                                                                <Field
                                                                    name="groupId"
                                                                    component={ReactstrapSelectField}
                                                                    label="Group"
                                                                    defaultValue={groupId}
                                                                    id='groupId'
                                                                    type="select"
                                                                    onChange={(event) => handleChange(event, 'group')}
                                                                >
                                                                    {
                                                                        state.groups && state.groups.length > 0 && state.groups.map((value, key) => {
                                                                            return <option key={'group_' + key} value={value._id}>{value.name}</option>
                                                                        })
                                                                    }
                                                                </Field>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                {
                                                    groupId !== '' ? <Row>
                                                        {
                                                            listpermissionModulesdata !== undefined && listpermissionModulesdata.length === permission.length ? <Col xs={6} md={6} sm={6} xl={6} lg={6}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={listpermissionModulesdata !== undefined && listpermissionModulesdata.length === permission.length}
                                                                            value={'admin_access'}
                                                                            color="primary"
                                                                            disabled={true}
                                                                        />
                                                                    }
                                                                    label={'Admin Access'}
                                                                />
                                                            </Col> : ''
                                                        }
                                                        {
                                                            (listpermissionModulesdata !== undefined && listpermissionModulesdata.length > 0) ? listpermissionModulesdata.map((value, key) => {
                                                                let index;

                                                                if (staffId !== '') {
                                                                    if (typeof (permission[0]) === 'string') {
                                                                        index = permission.findIndex((e) => e === value._id)
                                                                    } else {
                                                                        index = permission.findIndex((e) => e._id === value._id)
                                                                    }
                                                                } else {
                                                                    index = permission.findIndex((e) => e._id === value._id)
                                                                }

                                                                return index > -1 ? <Col xs={6} md={6} sm={6} xl={6} lg={6}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={index > -1 ? true : false}
                                                                                value={value.value}
                                                                                color="primary"
                                                                                disabled={true}
                                                                            />
                                                                        }
                                                                        label={value.name}
                                                                    />
                                                                </Col> : ''
                                                            }) : ''
                                                        }
                                                    </Row> : ''
                                                }
                                            </Col>
                                        </Row>
                                    </DialogContent>
                                    <DialogActions className="pr-4">
                                        <Button onClick={handleRequestClose} color='secondary' >
                                            Cancel
                                        </Button>
                                        <Button type='submit' color='primary' disabled={submitting}>
                                            Edit
                                        </Button>
                                    </DialogActions>
                                </>
                            }
                        </form>
                    )}
                />
            </Dialog>
            {
                props.permission && props.permission.length > 0 ? <AddGroup add_group={state.addGroup} permission={props.permission} title={'Add Group'} handleClick={handleClick} /> : ''
            }
        </React.Fragment>
    );
}

const mapStateToProps = ({ admin }) => {
    const { listGroupData, listpermissionModulesdata, loading } = admin;
    return { listGroupData, listpermissionModulesdata, loading }
};

export default connect(
    mapStateToProps,
    {
        listGroup,
        listPermissionModules,
        addStaff
    }               // bind account loading action creator
)(EditStaff) 