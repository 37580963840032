import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormGroup, Input } from 'reactstrap';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from 'axios'
import AppConfig from 'constants/config'
import { connect } from 'react-redux';
import Switch from '@mui/material/Switch';
import { getCodOnOff, getDeliveryOnOff, getChangeUserStatus, getUserApprove, getBnplOnOff, getBuyerSellerList, getBulkOnOff } from 'actions/admin';
import { NotificationManager } from 'react-notifications';
import './index.css'
import ReactStrapTextField from '../../routes/components/ReactStrapTextField';
import { Field, Form } from 'react-final-form';
import { required, number } from 'constants/validations'
import AxiosRequest from 'sagas/axiosRequest';
import { listStaff } from 'actions/admin';

const BuyerDetailsHeader = (props) => {
  const [checkedA, setCheckedA] = useState(false)
  const [checkedDeli, setCheckedDeli] = useState(false)
  const [checkedBnpl, setCheckedBnpl] = useState(false)
  const [checkedBulk, setCheckedBulk] = useState(false)
  const [open, setOpen] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)
  const [name, setName] = useState('')
  const [configPopup, setConfigPopup] = useState(false)
  const [relationalManager1, setRelationalManager1] = useState('')
  const [relationalManager2, setRelationalManager2] = useState('')
  const [staffListed, setStaffListed] = useState([])
  const [deny, setDeny] = useState('')
  const [title, setTitle] = useState('')

  useEffect(() => {
    setCheckedA(props.userDetails ? props.userDetails.cod : false)
    setCheckedDeli(props.userDetails ? props.userDetails.isDeliveryCharge : false)
    setCheckedBnpl(props.userDetails ? props.userDetails.isBnpl : false)
    setCheckedBulk(props.userDetails ? props.userDetails.bulkRequest : false)
    setRelationalManager1(props.userDetails ? props.userDetails.manager_1 && props.userDetails.manager_1._id : '')
    setRelationalManager2(props.userDetails ? props.userDetails.manager_2 && props.userDetails.manager_2._id : '')
  }, [props.userDetails])

  const handleChange = name => (event, checked) => {
    let data;
    if (checked) {
      setCheckedA(true);
      data = { user_id: props.userDetails && props.userDetails._id, status: true }
      props.getCodOnOff({ data })
    } else {
      setCheckedA(false);
      data = { user_id: props.userDetails && props.userDetails._id, status: false }
      props.getCodOnOff({ data })
    }
  };

  const gettingManagerList = () => {
    props.listStaff({ searchText: '', page: 1, perPage: 50 })
  }

  const handleChange1 = (e, manager) => {
    if (e.target.value !== '') {
      if (manager === 'rMId1') {
        setRelationalManager1(e.target.value)
      } else {
        setRelationalManager2(e.target.value)
      }
      handleSubmitUSerData1(e.target.value, manager)
    }
  }

  const handleChangeForDeli = name => (event, checked) => {
    let data;
    if (checked) {
      setCheckedDeli(true);
      data = { user_id: props.userDetails && props.userDetails._id, status: true }
      props.getDeliveryOnOff({ data })
    } else {
      setCheckedDeli(false);
      data = { user_id: props.userDetails && props.userDetails._id, status: false }
      props.getDeliveryOnOff({ data })
    }
  };

  const handleChangeForBnpl = name => (event, checked) => {
    let data;
    if (checked) {
      setCheckedBnpl(true);
      data = { user_id: props.userDetails && props.userDetails._id, status: true }
      props.getBnplOnOff({ data })
    } else {
      setCheckedBnpl(false);
      data = { user_id: props.userDetails && props.userDetails._id, status: false }
      props.getBnplOnOff({ data })
    }
  };

  const handleChangeForBulk = name => (event, checked) => {
    let data;
    if (checked) {
      setCheckedBulk(true);
      data = { Id: props.userDetails && props.userDetails._id, status: true }
      props.getBulkOnOff({ data })
    } else {
      setCheckedBulk(false);
      data = { Id: props.userDetails && props.userDetails._id, status: false }
      props.getBulkOnOff({ data })
    }
  };

  const reset = async () => {
    await axios({
      method: 'post',
      url: `${AppConfig.baseUrl}users/resetPassAdmin`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      },
      data: { user_id: props.userDetails && props.userDetails._id }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.message)
      } else {
        NotificationManager.success(result.data.message)
      }
    }).catch(error => {
      console.log("error catch", error)
    })
  }

  const Close = () => {
    setOpen(false)
  }

  const onSave = async (values) => {
    await axios({
      method: 'post',
      url: `${AppConfig.baseUrl}users/rtoCharge`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      },
      data: {
        buyerId: props.userDetails._id,
        msg: values.message,
        amt: Number(values.amount)
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title)
      }
    }).catch(error => {
      console.log("error catch", error)
    })
    setOpen(false)
  }

  const handleSubmitUSerData1 = async (val, rMId) => {
    let buyer_id = props.userDetails._id
    let data = { [rMId]: val, Id: buyer_id }
    await axios.post(`${AppConfig.baseUrl}users/addRelationshipManager`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      },
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      } else {
        NotificationManager.success(result.data.title);
        let data = { filter: { user_type: 'buyer', _id: buyer_id } }
        props.getBuyerSellerList({ data })
      }
    })
  }

  const staffListData = async () => {
    let data = {}
    await axios.post(`${AppConfig.baseUrl}seller/getStaffListForRm`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      },
    }).then(result => {
      setStaffListed(result.data.detail)
    })
  }

  useEffect(() => {
    gettingManagerList()
    staffListData()
  }, [])

  const handleDenyApprove = (data) => {
    setDeny(true)
    setTitle(data)
  }

  const handleDenied = () => {
    if (title === 'deny') {
      props.getChangeUserStatus({ id: props.userDetails._id, status: 'denied', user_type: 'buyer', _id: props.userDetails._id })
    } else {
      props.getUserApprove({ fromDetail: true, user_id: props.userDetails._id, user_type: 'buyer', _id: props.userDetails._id });
    }
    setDeny(false)
  }

  const handleRequestClose = () => {
    setDeny(false)
    setOpenUpdate(false)
    setName('')
    setConfigPopup(false)
  }

  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val === key)
    return index > -1 ? true : false
  }

  const editCompname = (data) => {
    setOpenUpdate(true)
    setName(data.company_name ? data.company_name : '')
  }

  const onUpdateName = async (values) => {
    let data = { user_id: props.userDetails._id, name: values.name, filter: { user_type: 'buyer', _id: props.userDetails._id } }
    let result = await AxiosRequest.axiosHelperFunc('post', 'admin/updateCompanyName', '', data)
    if (result.data.error) {
      NotificationManager.error(result.data.title);
    } else {
      NotificationManager.success(result.data.title);
      props.getBuyerSellerList({ data })
    }
    handleRequestClose();
  }

  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  const { userDetails } = props;
  return (
    <div className="page-heading buyerDetailsHeader d-sm-flex justify-content-sm-between align-items-sm-center">
      <div style={{ width: "50%" }}>
        <h2 className="title mb-3 mb-sm-0">Buyer <ChevronRightIcon /> {userDetails && userDetails.company_name}
          {isButtonAvailable('editCompanyName') && <> <i class="zmdi zmdi-edit zmdi-hc-fw" onClick={() => editCompname(userDetails)} /> <a rel="noreferrer" target='_blank' href={`/admin/transaction/details/${userDetails ? userDetails._id : ''}`}>< i class="zmdi zmdi-balance-wallet text-#072791" /></a> </>}</h2>
      </div>
      <div className=" justify-content-flex-end">{
        userDetails && (userDetails.user_status === 'inactive' || userDetails.user_status === 'hold') && <>
          {isButtonAvailable('approve') && <Button onClick={(e) => handleDenyApprove('allow')} variant="outlined" className={'text-success mr-2'} >Allow</Button>}
          {isButtonAvailable('changeUserStatus') && <Button onClick={(e) => handleDenyApprove('deny')} variant="outlined" className={'text-danger mr-2'} >Deny</Button>}
        </>
      }
        {isButtonAvailable('resetPassAdmin') && <Button onClick={(e) => reset(e)} variant="outlined" className={'text-primary mr-3'} >Reset Pass</Button>}
        <Button variant="outlined" onClick={() => setConfigPopup(true)} className={'text-warning mr-3'} >Config</Button>
      </div>
      <Dialog open={configPopup} onClose={handleRequestClose} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>
          Buyer Config
        </DialogTitle>
        <DialogContent className='pt-0'>
          <Row>
            {isButtonAvailable('deliveryChargeOnOff') && <Col>
              {userDetails && userDetails.user_status === 'active' && <span
                style={{ fontWeight: 600, fontSize: '20px', textAlignLast: 'right' }}
              >BNPL
                <Switch
                  color='primary'
                  checked={checkedBnpl}
                  onChange={handleChangeForBnpl('checkedBnpl')}
                  aria-label="checkedBnpl"
                  className="codToggle"
                />
              </span>}
            </Col>}
            {isButtonAvailable('deliveryChargeOnOff') && <Col> {userDetails && userDetails.user_status === 'active' && <span
              style={{ fontWeight: 600, fontSize: '20px', textAlignLast: 'right' }}
            >Delivery charge
              <Switch
                color='primary'
                checked={checkedDeli}
                onChange={handleChangeForDeli('checkedDeli')}
                aria-label="checkedDeli"
                className="codToggle"
              />
            </span>}</Col>}
          </Row>
          <Row>
            {isButtonAvailable('codOnOff') && <Col> {userDetails && userDetails.user_status === 'active' && <span
              style={{ fontWeight: 600, fontSize: '20px', textAlignLast: 'right' }}
            >COD
              <Switch
                color='primary'
                checked={checkedA}
                onChange={handleChange('checkedA')}
                aria-label="checkedA"
                className="codToggle"
              />
            </span>}</Col>}
            {isButtonAvailable('bulkRequestOnOff') && <Col> {userDetails && userDetails.user_status === 'active' && <span
              style={{ fontWeight: 600, fontSize: '20px', textAlignLast: 'right' }}
            >Bulk Request
              <Switch
                color='primary'
                checked={checkedBulk}
                onChange={handleChangeForBulk('checkedBulk')}
                aria-label="checkedBulk"
                className="codToggle"
              />
            </span>}</Col>}
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col xs='5'>
              <span
                style={{ fontWeight: 600, fontSize: '20px', textAlignLast: 'right' }}
              >Relational&nbsp;Manager 1 &nbsp;:</span>
            </Col>

            <Col xs='6' >
              <FormGroup style={{ textAlignLast: 'left' }} >
                <Input
                  name="orderStatus"
                  label="Status" id="orderStatus"
                  value={relationalManager1}
                  type="select"
                  onChange={(event) => handleChange1(event, 'rMId1')}
                >
                  <option value="">Select Manager</option>
                  {staffListed && staffListed && staffListed.map((val, i) => {
                    return <option key={i} value={val._id}>{val.first_name}</option>
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col xs='5'>
              <span
                style={{ fontWeight: 600, fontSize: '20px', textAlignLast: 'right' }}
              >Relational&nbsp;Manager 2 &nbsp;:</span>
            </Col>
            <Col xs='6' >
              <FormGroup style={{ textAlignLast: 'left' }} >
                <Input
                  name="orderStatus"
                  label="Status" id="orderStatus"
                  value={relationalManager2}
                  type="select"
                  onChange={(event) => handleChange1(event, 'rMId2')}
                >
                  <option value="">Select Manager</option>
                  {staffListed && staffListed && staffListed.map((val, i) => {
                    return <option key={i} value={val._id}>{val.first_name
                    }</option>
                  })}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfigPopup(false)} color='secondary' >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={Close}>
        <DialogTitle>RTO Charge</DialogTitle>
        <Form
          onSubmit={onSave}
          render={({ handleSubmit, values, form, errors }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Row>
                  <Col md={12} xl={12} xs={12} sm={12} lg={12} >
                    <FormGroup>
                      <Field label='Message' type='text' name='message'
                        component={ReactStrapTextField} id="Message"
                        validate={required}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                    <FormGroup>
                      <Field label='Amount' type='text' id="Amount"
                        validate={composeValidators(required, number)} component={ReactStrapTextField}
                        name='amount'
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions className="pr-4">
                <Button onClick={Close} color='secondary' > Cancel </Button>
                <Button type='submit' color='primary'> Save </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
      <Dialog open={deny} onClose={handleRequestClose} fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle>
          {title === 'deny' ? 'Deny User' : 'Allow User'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to ${title === 'deny' ? 'deny' : 'allow'} this user ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color='secondary' >
            Cancel
          </Button>
          <Button color='primary' onClick={handleDenied} >
            {title === 'deny' ? 'Deny' : 'Allow'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openUpdate} onClose={handleRequestClose} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>
          Update Company name
        </DialogTitle>
        <Form
          onSubmit={onUpdateName}
          initialValues={{ name: userDetails && userDetails.company_name }}
          render={({ handleSubmit, values, form, errors }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogContent className='pt-0'>
                <Row>
                  <Col>
                    <FormGroup>
                      <Field id="name" name="name" type="text"
                        component={ReactStrapTextField} label={"Company name"}
                        validate={required}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                <Button color='primary' type='submit' >
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </div>
  )
}

const mapStateToProps = ({ admin }) => {
  const { codOnOff, deliveryOnOff, actionList, staffList } = admin;
  return { codOnOff, deliveryOnOff, actionList, staffList }
}
export default connect(mapStateToProps, { getCodOnOff, getDeliveryOnOff, getChangeUserStatus, getUserApprove, getBnplOnOff, getBuyerSellerList, getBulkOnOff, listStaff })(BuyerDetailsHeader)