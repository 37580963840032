import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables"
import { Col, Row, Label, Input } from 'reactstrap';
import moment from 'moment';
import customHead from 'constants/customHead';
import Button from '@mui/material/Button';
import ViewSettlement from '../ViewSettlement';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getSettlements } from 'actions/admin';
import { connect } from 'react-redux';
import Input1 from '@mui/material/Input';
import '../../../../../node_modules/react-datepicker/dist/react-datepicker.css';
import '../index.css'
import axios from 'axios';
import AppConfig from 'constants/config'
import CircularProgress from '@mui/material/CircularProgress';
import { getAllOrderList } from "actions/admin";
import { getPrepaidOrder } from "actions/admin";
import CustomeFilterForDate from "app/routes/components/CustomFilter/customfilter";
import dayjs from "dayjs";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const payment_mode = { New: "New", Processes: "Processed", Requested: "Requested", Cancelled: "Cancelled", Delivered: "Delivered", OnHold: "OnHold", "Not Picked": "Not Picked" }

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function PrepaidOrders(props) {
  const [state, setState] = useState({
    view: false,
    yearList: [],
    data: '',
    filterData: '',
    perPage: 50,
    page: 0,
    searchedText: '',
    loader: false,
    to: moment(),
    from: moment(),
    type: [],
    prepaidData: ''
  })

  const handleSettelMent = async () => {
    window.open(`${AppConfig.baseUrl}order/settlementOrderCsv?token=${localStorage.getItem('admin_token')}&from_date=${state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format()}&to_date=${state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format()}&status=${state.type ? state.type : ''}&seller_id=${state.seller ? state.seller : ''}`, '_blank');
    setState((prev) => ({
      ...prev,
      openFilter: false,
      status: '',
    }))
  }
  const resetFilter = (filter) => {
    let data = {
      page: 1,
      perPage: state.perPage,
      filter: '',
      seller_id: '',
      from_date: moment().format('YYYY-MM-DD'),
      to_date: moment().add(1, 'days').format('YYYY-MM-DD')
    }
    props.getPrepaidOrder({ data, history: props.history })
    setState((prev) => ({
      ...prev,
      filter: '',
      to: moment(),
      from: moment(),
      type: [],
      seller: '',
      page: 0
    }));
  }

  const applyFilter = (filter) => {
    let data = {
      page: 1,
      perPage: state.perPage,
      searchText: state.searchedText ? state.searchedText : '',
      from_date: moment(state.from).format('YYYY-MM-DD'),
      to_date: moment(state.to).add(1, 'days').format('YYYY-MM-DD'),
      seller_id: state.seller ? state.seller : '',
      status: state.type ? state.type : [],
    }
    props.getPrepaidOrder({ data, history: props.history })
    setState((prev) => ({
      ...prev,
      page: 0,
    }))
  }

  const changePage = (page) => {
    let pages = page + 1;
    let data = {
      page: pages,
      perPage: state.perPage,
      searchText: state.searchedText,
      filter: '',
      from_date: state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      to_date: state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      seller_id: state.seller ? state.seller : '',
      status: state.type ? state.type : []
    }
    props.getPrepaidOrder({ data, history: props.history })
    setState((prev) => ({ ...prev, page }))
    setState((prev) => ({ ...prev, loader: true }))
    setTimeout(() => {
      setState((prev) => ({ ...prev, loader: false }))
    }, 2000);
  };

  function throttleHandleChange(edata) {
    props.getPrepaidOrder({ data: edata, history: props.history })
  }

  const handleSearch = (searchText) => {
    let data = {
      searchText: searchText, page: 1, perPage: state.perPage, from_date: state.from ? moment(state.from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      to_date: state.to ? moment(state.to).add(1, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), seller_id: state.seller ? state.seller : '', type: state.type ? state.type : ''
    }
    throttleHandleChange(data)
    setState((prev) => ({ ...prev, searchedText: searchText, page: 0 }))
  };

  const handleClick = (key, dataOne) => {
    setState((prev) => ({ ...prev, [key]: !state[key], data: dataOne ? dataOne : '' }))
  }

  const handleChanged = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
  }

  const handleSelectChangePaymentType = (e) => {
    let tempSelectedCat = e.target.value;
    let index = tempSelectedCat && tempSelectedCat.length > 0 ? tempSelectedCat.findIndex((e) => e === 'type') > -1 ? tempSelectedCat.findIndex((e) => e === 'type') : -1 : -1;
    index > -1 && tempSelectedCat.splice(index, 1);
    setState((prev) => ({ ...prev, type: tempSelectedCat }));
  }

  const fetchData = async () => {
    await axios({
      method: 'get',
      url: `${AppConfig.baseUrl}inventory/get_filters`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        setState((prev) => ({
          ...prev,
          filterData: result.data.detail.sellers,

        }))
      }
    }).catch(error => {
      console.log("error catch", error)
    });
    let data = {
      from_date: moment(state.from).format('YYYY-MM-DD'),
      to_date: moment(state.to).add(1, 'days').format('YYYY-MM-DD'),
      status: [''],
      type: "Online,Bulk"
    }
    props.getPrepaidOrder({ data, history: props.history })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleDateChange = (date, key) => {
    setState((prev) => ({ ...prev, [key]: dayjs(date).format() }))
  }

  let { from, to, seller, filterData, type } = state;

  const { prepaidOrderList } = props;

  const columns = [
    {
      name: "orderId",
      label: "Order ID",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "orderDate",
      label: "Order Date",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "deliveryDate",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "buyerName",
      label: "Buyer",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "sellerName",
      label: "Seller",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "orderValue",
      label: "Order Value",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "paymentDueDate",
      label: "Settlement Date",
      options: {
        filter: true,
        filterType: 'custom',
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        },
        customFilterListRender: v => {
          return false;
        },
        filterOptions: {
          fullWidth: true,
          display: () => (
            <React.Fragment >
              <Row form style={{ maxWidth: 350 }}>
                <CustomeFilterForDate handleDateChange={handleDateChange} from={from} to={to} />
                <Col md={8} xl={6} xs={6} sm={6} lg={6}>
                  <FormControl className="mb-2" >
                    <Label for="exampleSelect">Sellers</Label>
                    <Input style={{ height: '47px' }} type="select" className="form-control" disableUnderline={true} name="seller" id="seller"
                      value={seller}
                      onChange={(e) => handleChanged(e, 'seller')}
                    >
                      <option selected={seller === ''} value={''}>Select Seller</option>
                      {
                        filterData && filterData.map((val) => {
                          return (<option selected={val._id === seller} value={val._id}>{val.company_name}</option>)
                        })
                      }
                    </Input>
                  </FormControl>
                </Col>
                <Col md={6} xl={6} xs={6} sm={6} lg={6}>
                  <Label for="exampleSelect">Status</Label>
                  <Select style={{ margin: "1px" }}
                    labelId="demo-multiple-checkbox-label"
                    multiple
                    id="demo-multiple-checkbox-label"
                    value={type}
                    onChange={(e) => handleSelectChangePaymentType(e)}
                    input={<Input1 disableUnderline={true} className="form-control " id="name-multiple" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      key={'select type'}
                      value={'type'}
                      style={{
                        fontWeight: '500',
                      }}
                    >
                      Select payment mode
                    </MenuItem>
                    {payment_mode && Object.keys(payment_mode).map(name => (
                      <MenuItem
                        key={name}
                        value={payment_mode[name]}
                        className='putIconAfter '
                        style={{
                          fontWeight: state.type.indexOf(name) !== -1 ? '500' : '400',
                        }}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Col>
              </Row>
              <div style={{ paddingTop: 15 }} >
                <Button variant="contained" onClick={() => applyFilter()} className='filterButton' color='primary'>Apply Filter</Button>
                <Button variant="contained" onClick={() => resetFilter()} className='filterButton' color='primary'>Reset Filter</Button>
              </div>
            </React.Fragment>
          ),
          onFilterChange: () => {
            console.log('onFilterChange')
          }
        },
      },
    },
    {
      name: "logisticDate",
      label: "Logistic Date",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    }
  ];

  let data = []

  prepaidOrderList && prepaidOrderList && prepaidOrderList.detail.length > 0 && prepaidOrderList.detail.map((dataOne, index) => {
    data.push({
      payment: dataOne.paymentType,
      orderId: <div >{dataOne.order_id}</div>,
      orderDate: <div >{moment(dataOne.createdAt).format('DD/MM/YYYY')}</div>,
      deliveryDate: <div >{dataOne && dataOne.order_status && dataOne.order_status[dataOne.order_status && dataOne.order_status.length - 1].status}</div>,
      sellerName: <div >{dataOne.sellerCompName}</div>,
      buyerName: <div >{dataOne.buyerCompName}</div>,
      logisticDate: <div >{dataOne.logistic_settlement_date ? moment(dataOne.logistic_settlement_date).format('DD/MM/YYYY') : ''}</div>,
      orderValue: <div >{"₹" + (dataOne.total_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>,
      paymentDueDate: <div >{dataOne.seller_settlement_date ? moment(dataOne.seller_settlement_date).format('DD/MM/YYYY') : 'N/A'}</div>,
    })
  })

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    selectableRowsOnClick: true,
    selectableRowsHeader: true,
    responsive: 'scrollMaxHeight',
    search: true,
    viewColumns: false,
    rowHover: true,
    rowsPerPage: 50,
    count: prepaidOrderList ? prepaidOrderList.count : 0,
    serverSide: true,
    server: true,
    page: state.page ? state.page : 0,
    searchText: state.searchedText,
    rowsPerPageOptions: [],
    textLabels: {
      filter: {
        all: "All",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  };
  const { loading } = props;

  return (
    <React.Fragment>
      {loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}
      {
        loading === true ?
          null :
          <MUIDataTable
            identifier={'allSettlement'}
            handleSettelMent={handleSettelMent}
            data={data}
            columns={columns}
            options={options}
          />
      }
      <ViewSettlement buttonType={'view'} dataFromParent={state.data} view={state.view} title={'View Settlement'} handleClick={handleClick} />
    </React.Fragment>
  );
}

const mapStateToProps = ({ admin }) => {
  const { adminSettlements, allOrder, loading, prepaidOrderList } = admin;
  return { adminSettlements, allOrder, loading, prepaidOrderList }
};

export default connect(mapStateToProps, { getSettlements, getAllOrderList, getPrepaidOrder })(PrepaidOrders);