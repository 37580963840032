// import React, { Component } from 'react'
// import FormHelperText from '@mui/material/FormHelperText'

// const renderFromHelper = ({ touched, error }) => {
//     if (!(touched && error)) {
//         return
//     } else {
//         return <FormHelperText className='text-center' style={{color: '#f44336'}}>{touched && error}</FormHelperText>
//     }
// }

// export default class FieldFileInput extends Component {
//     constructor(props) {
//         console.log("ddddddddddddddddd6546584dd",props)
//         super(props)
//     }

//    getBase64 = (file,newBase64,onChange) =>{
//     new Promise((resolve, reject) => {
//         let reader = new FileReader();
//         resolve( 
//         reader.readAsDataURL(file),
//         reader.onload = () =>( 
//            newBase64.push(reader.result),
//            onChange(newBase64)
//             )
//        )
      
//       },
//       );
//    }


//      handleFileSelect=async(e)=> {
//         let newBase64 = []
//         const { input: { onChange } } = this.props
//         let document = [];
//         // let reader = new FileReader();
//         // console.log('e.target.files', e.target.files.length);
      
//     for (let i = 0; i < e.target.files.length; i++) {
//            this.getBase64(e.target.files[i],document,onChange)
//         //    onChange(document)
//     }
   


//         // reader.readAsDataURL(e.target.files[0]);
//         // reader.onload = () => {
//         //     document = reader.result;
//         //     onChange(document)
//         //     console.log('e.....', document);
//         // };
//         // reader.onerror = function (error) {
//         //     console.log('Error: ', error);
//         //     console.log('e.....', document);
//         // };
//     }

//     render() {
//         const { input: { value } } = this.props
//         const { input, label, required, meta: { touched, error, warning } } = this.props  //whatever props you send to the component from redux-form Field
//         console.log("value254......", value)
//         let val = (touched && error) || (warning ? true : false)
//         return (
//             <div className='text-center'>
//                 <div>
//                     <input
//                        multiple
//                         type='file'
//                         accept='.jpg, .png, .jpeg,.webp'
//                         style={{ display: 'none' }}
//                         onChange={(e) => this.handleFileSelect(e)} ref={(ref) => this.drugLic20B = ref}
//                     />
//                     <img src={value !== '' ? require('../../../assets/img/camera_3.png') : require('../../../assets/img/camera_1.png')} className='d-block mx-auto' onClick={(e) => this.drugLic20B.click()} />
//                 </div>
//                 <label >{label}</label>
//                 {renderFromHelper({ touched, error })}
//             </div>
//         )
//     }
// }



import React, { useState, useEffect, useRef } from 'react';
import FormHelperText from '@mui/material/FormHelperText';

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return null;
  } else {
    return <FormHelperText className='text-center' style={{ color: '#f44336' }}>{touched && error}</FormHelperText>;
  }
};

const FieldFileInput = (props) => {
  const [base64Array, setBase64Array] = useState([]);
  const drugLic20BRef = useRef(null);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileSelect = async (e) => {
    let newBase64Array = [];
    const { input: { onChange } } = props;

    for (let i = 0; i < e.target.files.length; i++) {
      const base64 = await getBase64(e.target.files[i]);
      newBase64Array.push(base64);
    }

    setBase64Array(newBase64Array);
    onChange(newBase64Array);
  };

  const { input: { value }, label, required, meta: { touched, error, warning } } = props;

  return (
    <div className='text-center'>
      <div>
        <input
          multiple
          type='file'
          accept='.jpg, .png, .jpeg, .webp'
          style={{ display: 'none' }}
          onChange={(e) => handleFileSelect(e)}
          ref={drugLic20BRef}
        />
        <img src={value !== '' ? require('../../../assets/img/camera_3.png') : require('../../../assets/img/camera_1.png')} className='d-block mx-auto' onClick={() => drugLic20BRef.current.click()} />
      </div>
      <label>{label}</label>
      {renderFromHelper({ touched, error })}
    </div>
  );
};

export default FieldFileInput;

