import React, { Component, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import moment from 'moment';
import customHead from 'constants/customHead'
import { connect } from 'react-redux';
import { getOrderList } from 'actions/admin';
import CustomFilter from '../../../../components/Filter';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import helper from '../../../../constants/helperFunction';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css';
import AppConfig from "constants/config";
import { useNavigate } from "react-router";
import Pagination from '@mui/material/Pagination';
import { Typography } from "@mui/material";


const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
let date = new Date();

function OrderList(props) {
  const history = useNavigate()
  let url = window.location.search
  let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''

  const [state, setState] = useState({
    filter: [],
    perPage: 100,
    loader: false,
    seller_id: json ? json.seller_idO ? json.seller_idO : '' : '',
    searchedText: json ? json.searchO ? json.searchO : '' : '',
    page: json ? json.pageO ? Number(json.pageO) : 0 : 0,
    from: json ? json.from_dateO ? json.from_dateO : moment().subtract(15, 'days').format() : moment().subtract(15, 'days').format(),
    to: json ? json.to_dateO ? json.to_dateO : moment().format() : moment().format(),
    type: json ? json.typeO ? json.typeO : '' : '',
    orderType: json ? json.orderTypeO ? json.orderTypeO : '' : '',
  });
  const [status, setStatus] = useState(json ? json.statusO ? json.statusO : '' : '')
  const [filter, setFilter] = useState(false)

  const button = (orderId, status) => {
    return (<React.Fragment>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button variant="outlined" onClick={(e) => handleTrackOrderClick(orderId)} className={'text-success'}>
          Track
        </Button>
        {orderId.docket ?
          <Button variant="outlined" onClick={() => openNewWindow(orderId)}>
            Docket
          </Button>
          : ''
        }
      </ButtonGroup>
    </React.Fragment>)
  }
  const handleTrackOrderClick = (data) => {
    history(`/admin/orders/trackOrder/${data.order_id}`);
  }
  const openNewWindow = (data) => {
    if (data) {
      window.open(`${AppConfig.baseUrl}users/generateLabel1?orderId=${data.order_id}`, '_blank');
    }
  }
  function fetchData() {
    const subDat = json ? json.statusO ? json.statusO : '' : ''
    let dat = subDat?.split(',')
    let toDate = json ? json.to_dateO ? json.to_dateO : moment().format() : moment().format()
    let data = {
      page: json ? json.pageO ? Number(json.pageO) + 1 : 1 : 1,
      perPage: 100,
      filter: '',
      searchText: json ? json.searchO ? json.searchO : '' : '',
      from_date: json ? json.from_dateO ? json.from_dateO : moment().subtract(15, 'days').format() : moment().subtract(15, 'days').format(),
      to_date: moment(toDate).add(1, 'days').format('YYYY-MM-DD'),
      seller_id: json ? json.seller_idO ? json.seller_idO : '' : '',
      status: dat,
      type: json ? json.typeO ? json.typeO : '' : '',
      tab: 'orderListing',
      orderType: json ? json.orderTypeO ? json.orderTypeO : '' : ''
    }
    if (props.tab == 0) {
      props.getOrderList({ data, history: history })
    }
  }
  useEffect(() => {
    if (props.tab == 0) {
      fetchData()
      setState({
        ...state,
        seller_id: json ? json.seller_idO ? json.seller_idO : '' : '',
        searchedText: json ? json.searchO ? json.searchO : '' : '',
        page: json ? json.pageO ? Number(json.pageO) : 0 : 0,
        from: json ? json.from_dateO ? json.from_dateO : moment().subtract(15, 'days').format() : moment().subtract(15, 'days').format(),
        to: json ? json.to_dateO ? json.to_dateO : moment().format() : moment().format(),
        type: json ? json.typeO ? json.typeO : '' : '',
        orderType: json ? json.orderTypeO ? json.orderTypeO : '' : '',
      })
      setStatus(json ? json.statusO ? json.statusO : '' : '')
    }
  }, [window.location.search])
  const statusStyle = (status) => {
    return status.includes("Processed") ? "text-white bg-primary" : status.includes("Ready For Dispatch") ? "text-white bg-amber" : status.includes("Placed") ? "text-white bg-yellow" : status.includes("Cancelled") ? "text-white bg-grey" : status.includes("Delivered") ? "text-white bg-green" : status.includes("Requested") ? "text-white bg-magenta" : status.includes("New") ? "text-white bg-danger" : status.includes("OnHold") ? "text-white bg-orange" : "text-white bg-info";
  }

  const changePage = (page) => {
    setState((prev) => ({ ...prev, page: page }))
    let obj = { from_dateO: state.from, to_dateO: state.to, pageO: page, searchO: state.searchedText, statusO: status, seller_idO: state.seller_id, typeO: state.type, orderTypeO: state.orderType }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)
  };

  const handleSearch = (searchText) => {
    let text = searchText?.replace(/"/g, '')
    setState((prev) => ({ ...prev, searchedText: searchText?.replace(/"/g, '') }))
    let obj = { from_dateO: state.from, to_dateO: state.to, pageO: 0, searchO: text, statusO: status, seller_idO: state.seller_id, typeO: state.type, orderTypeO: state.orderType }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)

  };
  const handleCloseSearch = () => {
    setState((prev) => ({
      ...prev,
      searchedText: '',
    }))
    let obj = { from_dateO: state.from, to_dateO: state.to, pageO: 0, searchO: '', statusO: status, seller_idO: state.seller_id, typeO: state.type, orderTypeO: state.orderType }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)
  }
  const handleResetFilter = (e, filter) => {
    e.preventDefault();
    filter();
    setState((prev) => ({
      ...prev,
      searchedText: '',
      month: monthNames[date.getMonth()],
      year: date.getFullYear(),
      filter: '',
      status: '',
      seller_id: '',
      from: '',
      to: '',
      page: 0
    }));
    setStatus('')
    let obj = { from_dateO: '', to_dateO: '', pageO: 0, searchO: state.searchedText, statusO: '', seller_idO: '', typeO: '', orderTypeO: '' }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)
  }
  const sellerFromFilter = (e) => {
    setState((prev) => ({ ...prev, seller_id: e }))
  }
  const dateFilterFrom = (e) => {
    setState((prev) => ({ ...prev, from: e }))
  }
  const dateFilterTo = (e) => {
    setState((prev) => ({ ...prev, to: e }))
  }
  const onFilterChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
  }

  const { loading } = props;

  const { orderList } = props;

  let data = [];

  // const startIndex = (currentPage - 1) * itemsPerPage + 1;
  // const endIndex = Math.min(currentPage * itemsPerPage, totalItems);

  orderList && orderList.detail && orderList.detail.length > 0 && orderList.detail.map((data1, index) => {
    const ShortExp = data1.products.filter(data => data.expiry_status === 'Short Expiry') 
    let local = moment(data1.createdAt).format(' D MMM, YYYY h:mm a');
    let local1 = moment(data1.order_status[data1.order_status.length - 1].date).format(' D MMM, YYYY h:mm a');
    data.push([
      <div style={helper.divFlex}>
        {ShortExp.length > 0 && <Tooltip title={`Short expiry`} placement="top-start">
          <span style={helper.styleForDiv} className={`${helper.styleDivLine('Short Expiry')}`}>&nbsp;&nbsp;</span>
        </Tooltip>}
        {data1.orderType ? <Tooltip title={`${data1.orderType}`} placement="top-start">
          <div style={helper.styleForDiv} className={`${helper.styleDivLine(data1.orderType)}`}>&nbsp;&nbsp;</div>
        </Tooltip> : <div style={helper.styleForDiv}>&nbsp;&nbsp;</div>}
        {data1.serviceName ? <Tooltip title={`${data1.serviceName}`} placement="top-start">
          <div style={helper.styleForDiv} className={`${helper.styleDivLine2(data1.serviceName)}`}>&nbsp;&nbsp;</div>
        </Tooltip> :
          <div style={helper.styleForDiv}>&nbsp;&nbsp;</div>
        }
      </div>,
      <div>
        <h4>
          <a style={{ color: 'inherit' }} href={`/admin/orders/orderDetails/${data1.order_id}`} onClick={(e) => { e.preventDefault(); history(`/admin/orders/orderDetails/${data1.order_id}`) }}>
            <span className="font-weight-bolder">{data1.products.length}
              &nbsp;{data1.products.length > 1 ? 'items' : 'item'} by </span>{data1.user_id && data1.user_id.company_name}
          </a>
          <a target='_blank' href={`/admin/buyers/details/${data1.user_id._id}`}><span className='h6'>({data1.user_id && data1.user_id.buyerId})</span></a>
        </h4>
        {data1.seller_id &&
          (data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '').length > 25 ?
          <span className="small font-weight-bolder">{(data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '').slice(0, 25) + "..."} | </span>
          :
          <span className="small font-weight-bolder">{(data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '')} | </span>
        }
        <span className="small font-weight-bolder">{data1.order_id} | {data1.isBulk === true ? 'Bulk Prepaid' : data1.paymentType == 'Online' ? 'Prepaid' : data1.paymentType} | {moment(data1.createdAt).format(' D MMM, YYYY h:mm a')} </span>
        {(moment().diff(moment(data1.createdAt), 'hours')) <= 48 ?
          <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'green' }}></span> :
          (moment().diff(moment(local), 'hours')) >= 48 && (moment().diff(moment(local), 'hours')) <= 72 ?
            <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'orange' }}></span> :
            (moment().diff(moment(local), 'hours')) >= 72 && data1.order_status[data1.order_status.length - 1].status === 'New' ?
              <span className="size-8 rounded-circle d-inline-block ml-1 blink_me" style={{ backgroundColor: 'red' }}></span> : ''
        }
      </div>,
      <div><p className={'ptrPriceFont1 priceColorInventory m-0'}>
        ₹{((data1.total_amount).toFixed(2))}
      </p></div>,
      <div style={{ fontSize: '13px' }} key={'recent'} className={` badge text-uppercase ${statusStyle(data1.isOnHold ? "On Hold" : data1.requested !== "Requested" ? data1.order_status[data1.order_status.length - 1].status : 'Requested')}`}>{data1.isOnHold ? "On Hold" : data1.requested === "Requested" ? 'Requested' : data1.order_status[data1.order_status.length - 1].status}</div>,
      (local1),
      button(data1, data1.requested)
    ])
  })
  const options = {
    searchPlaceholder: 'Press Enter to Search',
    loader: loading,
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: false,
    selectableRows: false,
    rowsPerPage: 100,
    page: state.page,
    fixedHeader: false,
    print: false,
    download: false,
    filter: true,
    sort: false,
    count: orderList && orderList.count,
    serverSide: true,
    server: true,
    selectableRowsOnClick: false,
    selectableRows: 'none',
    fixedHeader: false,
    search: true,
    searchText: state.searchedText,
    rangePage: true,
    rowsPerPageOptions: [],
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key == 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleCloseSearch();
          break;
      }
    },
    customFilterDialogFooter: (currentFilter, applyNewFilters) => {
      if (filter) {
        setFilter(false)
        return applyNewFilters();
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <Typography className="p-2" variant="h7">
         { (page) * rowsPerPage + 1} - {Math.min((page+1) * rowsPerPage, count)} of {count}
        </Typography>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }
  function applyFilterClose() {
    setFilter(true)
    options.customFilterDialogFooter(null, options.applyNewFilters);
  }

  const columns = [
    {
      name: "buyerName",
      label: " ",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "orderId",
      label: "Order ID",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Latest Date",
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListRender: v => {
          return false;
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, onChange, filterOptions, next) => {
            return (
              <CustomFilter onFilterChange={onFilterChange} applyFilter={applyFilterClose}
                searchText={state.searchedText} handleResetFilter={handleResetFilter} month={state.month} status={status} seller_id={state.seller_id} sellerFromFilter={(e) => sellerFromFilter(e)} from={state.from} to={state.to}
                dateFilterFrom={(e) => dateFilterFrom(e)} dateFilterTo={(e) => dateFilterTo(e)}
                year={state.year} page={state.page}
                history={history} location={props.location} filterFor='OrderList' />
            )
          },
        },
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    }
  ];

  const theme = createTheme({
    components: {

      MuiTableHead: {
        styleOverrides: {
          root: {
            paddingLeft: '0px'
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontSize: '1rem',
            paddingLeft: '0px',
            fontWeight: '600'
          },
          root: {
            paddingLeft: '0px',
          },
        }
      },
      MUIDataTableFilter: {
        styleOverrides: {
          reset: {
            display: "none"
          }
        }
      }
    }
  });
  return (
    <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
      {loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}
      {
        loading ?
          null :
          <ThemeProvider theme={theme}>
            <div className="mt-4 ms-4">{orderList.userName == 'admin' ? <h3 style={{padding:"12px 5px", marginBottom:'0px'}}>Total Amount : ₹ {(orderList && orderList.sum).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h3> : ''}</div>
            <MUIDataTable
              title=""
              data={data}
              columns={columns}
              options={options}
              style={{ borderRadius: '0px !important' }}
            />
          </ThemeProvider>
      }
    </div>
  );
}


const mapStateToProps = ({ admin }) => {
  const { orderList, loading } = admin;
  return { orderList, loading }
};

export default connect(mapStateToProps, { getOrderList })(OrderList);

