import React, { Component } from 'react';


class ProductTable2 extends Component {
  
  render() {
    const { value } = this.props;
    return (
      <div className="table-responsive-material">
        <table className="default-table table-unbordered table table-sm table-hover">
          <thead className="th-border-b">
            <tr>
              <th>Company Name</th>
              <th></th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {value && value .map(data => {
              return (
                <tr
                  tabIndex={-1}
                >
                  <td>
                    <div className=" d-flex flex-row align-items-center">
                      <div className="user-detail">
                        <h5 className="user-name">{data._id} </h5>
                      </div>
                    </div>
                  </td>
                  <td></td>
                  <td>₹{(data.total).toLocaleString('en-IN',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ProductTable2;