import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row, FormGroup } from 'reactstrap';
import ReactStrapTextField from '../../components/ReactStrapTextField';
import AxiosRequest from 'sagas/axiosRequest';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form'
import { required, number0 } from 'constants/validations';

const EditMenifestData = (props) => {
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    setSelectedValue(props.manifestData && props.manifestData.box_dimention && props.manifestData.box_dimention.length ? (props.manifestData.box_dimention.length === 228 || props.manifestData.box_dimention.length === 152 || props.manifestData.box_dimention.length === 254 ? `${props.manifestData.box_dimention.length}` : "custom") : "228")
  }, [props.manifestData]);

  const predefinedDimensions = {
    "228": { length: 228, breadth: 190, height: 114 },
    "152": { length: 152, breadth: 127, height: 127 },
    "254": { length: 254, breadth: 203, height: 190 },
  };
  const handleChange = (event, form) => {
    const value = event.target.value;
    setSelectedValue(value);

    if (value !== 'custom') {
      const dimensions = predefinedDimensions[value];
      form.change('length', dimensions.length);
      form.change('breadth', dimensions.breadth);
      form.change('height', dimensions.height);
    } else {
      form.change('length', 0);
      form.change('breadth', 0);
      form.change('height', 0);
    }
  };
  const handleRequestClose = () => {
    props.handleClick('editMenifest');
    setSelectedValue(props.manifestData && props.manifestData.box_dimention && props.manifestData.box_dimention.length ? (props.manifestData.box_dimention.length === 228 || props.manifestData.box_dimention.length === 152 || props.manifestData.box_dimention.length === 254 ? `${props.manifestData.box_dimention.length}` : "custom") : "228")
  }

  const onSave = async (values) => {
    let data = {
      length: Number(values.length) || 0,
      breadth: Number(values.breadth) || 0,
      height: Number(values.height) || 0,
      manifest_id: props.menifestId
    }
    let response = await AxiosRequest.axiosHelperFunc('post', 'manifest/boxDimentionLBH', '', data)
    if (response.data.error) {
      NotificationManager.error(response.data.title)
    } else {
      NotificationManager.success(response.data.title)
      let data = {
        page: props.page + 1,
        perPage: 100,
        from_date: props.from || '',
        to_date: props.to || '',
        tab: props.tab,
        seller_id: props.seller_id ? props.seller_id : '',
        type: props.type,
      }
      props.handleClick('editMenifest')
      props.getAllManifestList({ data })
    }
  }

  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  const { manifestData } = props;

  return (
    <React.Fragment>
      <Dialog open={props.editMenifest} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'md'}>
        <DialogTitle style={{ marginBottom: '15px' }}>
          <div style={{ display: "flex", justifyContent: "space-between" }} >
            <div style={{ fontSize: '21px' }}  > Order ID :  <a target='_blanck' href={`/admin/orders/orderDetails/${manifestData && manifestData.order.orderNo}`}>{manifestData && manifestData.order.orderNo}  </a>  </div>
            <div style={{ fontSize: '21px' }} > AWB :{manifestData.AWB}</div>
          </div>
          <div style={{ fontSize: '18px' }}> Order Amount :₹{(manifestData && manifestData.order && (manifestData.order.order_amount).toFixed(2))}</div>
        </DialogTitle>
        <DialogContent>
          <Form
            initialValues={{
              breadth: props.manifestData && props.manifestData.box_dimention && props.manifestData.box_dimention.breadth ? props.manifestData.box_dimention.breadth : 190,
              height: props.manifestData && props.manifestData.box_dimention && props.manifestData.box_dimention.height ? props.manifestData.box_dimention.height : 114,
              length: props.manifestData && props.manifestData.box_dimention && props.manifestData.box_dimention.length ? props.manifestData.box_dimention.length : 228,
            }}
            onSubmit={onSave}
            render={({ handleSubmit, values, form, errors }) => (
              <form noValidate onSubmit={handleSubmit} autoComplete="off">
                <Row>
                  {props.images && props.images.map((val) => {
                    return <Col xs={12} md={4} sm={12} xl={4} lg={4} className='mt-2'>
                      <a rel="noreferrer" target='_blank' href={val}>
                        <img alt="manifest" height={200} width={300} src={val} />
                      </a>
                    </Col>
                  })}
                </Row>
                <div className='mt-2 d-flex' style={{flexDirection: "row", gap: "20px"}}>
                  <label>
                    <input
                      type="radio"
                      value="228"
                      checked={selectedValue === '228'}
                      onChange={(e) => handleChange(e, form)}
                    />
                    {" "}
                    228
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="152"
                      checked={selectedValue === '152'}
                      onChange={(e) => handleChange(e, form)}
                    />
                    {" "}
                    152
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="254"
                      checked={selectedValue === '254'}
                      onChange={(e) => handleChange(e, form)}
                    />
                    {" "}
                    254
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="custom"
                      checked={selectedValue === 'custom'}
                      onChange={(e) => handleChange(e, form)}
                    />
                    {" "}
                    Custom
                  </label>
                </div>
                <div className='mt-2 d-flex'>
                  <Row>
                  <Col xs={12} md={4} className='mt-1'>
                  <FormGroup className={'mt-1 ml-2'}>
                    <Field style={{ marginTop: '-15px' }}
                      type="number"
                      validate={composeValidators(required, number0)}
                      component={ReactStrapTextField}
                      label={"Length"}
                      name="length" id="length"
                      disabled={selectedValue !== 'custom'}>
                    </Field>
                  </FormGroup>
                  </Col>
                  <Col xs={12} md={4} className='mt-1'>
                  <FormGroup className={'mt-1 ml-1'}>
                    <Field
                      type="number"
                      validate={composeValidators(required, number0)}
                      component={ReactStrapTextField}
                      label={"Breadth"}
                      name="breadth" id="breadth"
                      disabled={selectedValue !== 'custom'}>
                    </Field>
                  </FormGroup>
                  </Col>
                  <Col xs={12} md={4} className='mt-1'>
                  <FormGroup className={'mt-1 ml-1'}>
                    <Field type="number"
                      validate={composeValidators(required, number0)}
                      component={ReactStrapTextField}
                      label={"Height"}
                      name="height" id="height"
                      disabled={selectedValue !== 'custom'}>
                    </Field>
                  </FormGroup>
                  </Col>
                  </Row>
                  <Col xs={6} md={4} className='mt-1'>
                  <FormGroup className={'mt-4 ml-5'}>
                    <Button onClick={handleRequestClose} color='secondary' >
                      Close
                    </Button>
                    <Button type='submit' color='primary' >
                      Submit
                    </Button>
                  </FormGroup>
                  </Col>
                </div>
                <DialogActions>
                </DialogActions>
              </form>
            )}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = ({ admin }) => {
  return { admin }
};

export default connect(mapStateToProps)(EditMenifestData);