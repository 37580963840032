import React from "react";
import { Table } from 'reactstrap';
import { Menu, IconButton, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import { FormGroup, FormLabel, TextField } from '@mui/material';
import { Col, Row, Form, Badge, Label, Input } from 'reactstrap';
import helperFunction from 'constants/helperFunction'
import { getAdminDashboardStats } from 'actions/admin';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import axios from 'constants/axios';
import { useState, useEffect } from "react";

const monthArray = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
];

const SendMoney = (props) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterValue, setFilterValue] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [yearList, setYearList] = useState([]);
    const [dataFromParent, setDataFromParent] = useState('');

    useEffect(() => {
        // Your componentDidMount logic here
        const fetchData = async () => {
            try {
                const result = await axios.post('/admin/getTopSeller', {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('admin_token')
                    },
                });

                if (result.data.error) {
                    NotificationManager.error(result.data.title);
                } else {
                    setDataFromParent(result.data.detail);
                }
            } catch (error) {
                NotificationManager.error('Something went wrong, Please try again');
            }
        };

        fetchData();

        setMonth(props.month ? props.month : monthArray[new Date().getMonth()]);
        setYear(props.year ? props.year : new Date().getFullYear());
        setYearList(helperFunction.yearList());
    }, [props.month, props.year]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (e) => {
        e.preventDefault();
        setAnchorEl(null);
    };

    const handleClose = async (e) => {
        e.preventDefault();
        setAnchorEl(null);
        const data = {
            month: monthArray[new Date().getMonth()],
            year: new Date().getFullYear()
        };

        try {
            const result = await axios.post('/admin/getTopSeller', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('admin_token')
                },
            });

            if (result.data.error) {
                NotificationManager.error(result.data.title);
            } else {
                setDataFromParent(result.data.detail);
            }
        } catch (error) {
            NotificationManager.error('Something went wrong, Please try again');
        }
    };

    const handleApplyFilter = async (e) => {
        e.preventDefault();
        const data = { month: month, year: year };
        const filter = `${month} ${year}`;
        setAnchorEl(null);
        setFilterValue(filter);

        try {
            const result = await axios.post('/admin/getTopSeller', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('admin_token')
                },
            });

            if (result.data.error) {
                NotificationManager.error(result.data.title);
            } else {
                setDataFromParent(result.data.detail);
            }
        } catch (error) {
            NotificationManager.error('Something went wrong, Please try again');
        }
    };

    const handleChange = (e, key) => {
        if (key === 'month') {
            setMonth(e.target.value);
        } else if (key === 'year') {
            setYear(e.target.value);
        }
    };

    return (
        <React.Fragment>
            <div className="jr-card-header d-flex align-items-center">
                <div className="mr-auto">
                    <h3 className="d-inline-block mb-0">{props.title}</h3>
                    {
                        filterValue === '' ? null : <span className="text-white badge badge-success">{filterValue} </span>
                    }
                </div>
                <IconButton className="icon-btn" onClick={(e) => handleClick(e)}>
                    <i className="zmdi  zmdi-filter-list" />
                </IconButton>
            </div>
            <div className="table-responsive-material" >
                <Table className="default-table table-unbordered table table-sm table-hover">
                    <thead className="table-head-sm th-border-b">
                        <tr>
                            <th>Name</th>
                            <th>Total Earned</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataFromParent && dataFromParent.top5Seller && dataFromParent.top5Seller.map((data, index) => {
                            return <tr key={index}>
                                <td>
                                    <a target='_blank' href={`/admin/sellers/details/${data._id}`}>
                                        <div className="d-flex align-items-center">
                                            <div className="user-detail">
                                                <h5 className="user-name text-primary">{data.buyer}</h5>
                                            </div>
                                        </div></a>
                                </td>
                                {/* <td>{data.noOfOrder}</td> */}
                                <td><a target='_blank' href={`/admin/sellers/details/${data._id}`}>
                                    <div>
                                        <span className="d-inline-block text-muted">&#x20B9;{Math.round(data.total_amount).toLocaleString('en-IN')}</span>
                                    </div></a>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>

            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={(e) => handleCloseMenu(e)}
            >
                <MenuItem className="filterBody">
                    <div className="flex">
                        <Row form>
                            <Col md={6} xl={6} xs={12} lg={6}>
                                <FormGroup>
                                    <Label for="exampleSelect">Select Month</Label>
                                    <Input type="select" value={month} name="select" onChange={(e) => handleChange(e, 'month')} id="exampleSelect">
                                        {
                                            monthArray.map((value, key) => {
                                                return <option key={key} value={value}>{value}</option>
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={6} xl={6} xs={12} lg={6}>
                                <FormGroup>
                                    <Label for="exampleSelect">Select Year</Label>
                                    <Input type="select" value={year} onChange={(e) => handleChange(e, 'year')} name="year" id="year">
                                        {
                                            yearList.map((value, key) => {
                                                return <option value={parseInt(value)} >{value}</option>
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div style={{ paddingTop: 15 }} >
                            <Button variant="contained" className='filterButton' onClick={(e) => handleApplyFilter(e)} color='primary'>Apply Filter</Button>
                            <Button variant="contained" className='filterButton' onClick={(e) => handleClose(e)} color='primary'>Reset Filter</Button>
                        </div>
                    </div>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

const mapStateToProps = ({ admin }) => {
    const { adminStats } = admin;
    return { adminStats };
};

export default connect(mapStateToProps, { getAdminDashboardStats })(SendMoney);
