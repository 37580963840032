import React, { Component, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import ContainerHeader from '../../../../components/ContainerHeader';
import ButtonGroup from '@mui/material/ButtonGroup';
import customHead from 'constants/customHead'

import { getMetaData, getAddProductCategory, getDeleteProductCategory } from 'actions/admin';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Label, Input, FormText } from 'reactstrap';
import { Col, Row } from 'reactstrap';
import { Field, Form } from 'react-final-form'
import ReactStrapTextField from '../../components/ReactStrapTextField';
import CircularProgress from '@mui/material/CircularProgress';
import { required } from 'constants/validations';
import AdminContainer from 'containers/AdminContainer';
import { useNavigate } from 'react-router';
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'app/routes/components/PaginationCount';

const columns = [
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  },
  {
    name: "action",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      customHeadRender: (o, updateDirection) => {
        return customHead(o, updateDirection);
      }
    }
  }
];

const options = {
  filterType: 'dropdown',
  print: false,
  download: false,
  selectableRows: false,
  selectableRowsOnClick: false,
  selectableRowsHeader: false,
  responsive: 'scroll',
  search: true,
  viewColumns: false,
  rowHover: false,
  filter: false,
  rowsPerPage: 50,
  rowsPerPageOptions: [],
  customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
    return <div className="p-4 d-flex justify-content-end">
      <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
      <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
    </div>
  }
};

function StaffList(props) {

  const [state, setState] = useState(
    {
      edit: false,
      permission: false,
      delete: false,
      add: false,
      name: '',
      description: '',
      photo: '',
      status: '',
      isFeatured: '',
      loader: false,
      delId: ''
    }
  )

  const handleClick = (key) => {
    setState((pre) => ({ ...pre, [key]: !state[key] }))
  }

  const handleChange = (key, event) => {
    setState((pre) => ({ ...pre, [key]: event.target.value }));
  }
  useEffect(() => {
    props.getMetaData({});
  }, [])

  const handleAdd = (values) => {
    props.getAddProductCategory({
      name: values.name ? values.name : '',
      description: state.description ? state.description : '',
      isFeatured: state.isFeatured ? state.isFeatured : '',
      status: state.status ? state.status : '',
    });
    setState({
      add: false,
      loader: true,
      name: '',
      isFeatured: ''
    })
    const t = setTimeout(() => {
      setState({ loader: false })
    }, 3000);
    props.getMetaData({});
  }

  const handleRequestClose = () => {
    setState({
      ...state,
      add: false,
      delete: false,
      isFeatured: ''
    })
  }
  const handleDelete = (e) => {
    setState({
      ...state,
      delete: true,
      delId: e
    })

  }
  const handleDel = () => {
    props.getDeleteProductCategory({ id: state.delId._id })
    setState((pre) => ({
      ...pre,
      delete: false,
    }))
  }

  const { metaData } = props;
  const { submitting } = props;
  const { name, isFeatured } = state;

  let data = []
  {
    metaData && metaData.detail && metaData.detail.categories.map((dataOne) => {
      data.push(
        {
          name: dataOne.name, noOfProducts: "10", action:
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              <Button className={'text-danger'} onClick={(e) => handleDelete(dataOne)}> Delete</Button>
            </ButtonGroup>
        }
      )
    }
    )
  }
  const { loader } = state;
  return (
    <AdminContainer>
      <React.Fragment>
        <ContainerHeader match={props.match} title={"Product Categories"} />
        {loader ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}

        {
          loader == true ?
            null :
            <MUIDataTable
              title={<Button className={'text-primary'} onClick={() => handleClick('add')}> Add Product Category+</Button>}
              data={data}
              columns={columns}
              options={options}
            />
        }

        <Dialog open={state.delete} onClose={handleRequestClose} fullWidth={true}
          maxWidth={'sm'}>
          <DialogTitle>
            Delete Product Category
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {'Are you sure yo want to delete this product category ?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color='secondary' >
              Cancel
            </Button>
            <Button type='button' color='primary' onClick={handleDel} >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={state.add}
          onClose={handleRequestClose}
          fullWidth={true}
          maxWidth={'sm'}>
          <Form
            onSubmit={handleAdd}
            initialValues={{
              name: '',
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <DialogTitle>
                  Add Product Category
                </DialogTitle>
                <DialogContent>
                  <Row>
                    <Col xs={12} md={6} sm={6} xl={6} lg={6}>
                      <Field type="text" name="name" label="Name" id="name" onChange={(e) => handleChange('name', e)} component={ReactStrapTextField}
                        value={name} validate={required} />
                    </Col>
                    <Col xs={12} md={6} sm={6} xl={6} lg={6}>
                      <Label for="isFeatured">isFeatured</Label>
                      <Input type="select" name="isFeatured" id="isFeatured" value={isFeatured}
                        onChange={(e) => handleChange('isFeatured', e)}>
                        <option value=" ">Select </option>
                        <option value='true'>TRUE</option>
                        <option value='false'>FALSE</option>
                      </Input>
                    </Col>
                  </Row>

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRequestClose} color='secondary' >
                    Cancel
                  </Button>
                  <Button type='submit' color='primary' disabled={submitting}>
                    {state.add ? 'ADD' : ''}
                  </Button>
                </DialogActions>
              </form>)} />
        </Dialog>

      </React.Fragment>
    </AdminContainer>
  );
}



const mapStateToProps = ({ admin }) => {
  const { metaData, addProductCategory, deleteProductCategory } = admin;
  return { metaData, addProductCategory, deleteProductCategory }
};


StaffList = connect(
  mapStateToProps,
  {
    getMetaData, getAddProductCategory, getDeleteProductCategory
  }
)(StaffList)

export default StaffList