import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'constants/axios'
import { NotificationManager } from 'react-notifications'
import './index.css';
import AdminContainer from "containers/AdminContainer";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "../components/PaginationCount";

const SellerOrderList = (props) => {
  const url = window.location.search
  const json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''
  const from = json && json.from_date ? moment(json.from_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
  const to = json && json.to_date ? moment(json.to_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
  const type = json && json.type ? json.type : 'open'
  const seller = json && json.seller ? json.seller : ''
  const cancel = json && json.cancel ? json.cancel : ''
  const perPage = 100
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(0)
  const [orderList, setOrderList] = useState({})

  const getSellerPerformanceOrders = async (data) => {
    setLoader(true)
    await axios.post('/order/getSellerPerformanceOrders', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error('Something went wrong, Please try again');
        setOrderList({})
        setLoader(false)
      } else {
        setOrderList(result.data.data)
        setLoader(false)
      }
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
      setOrderList({})
      setLoader(false)
    });
  }

  useEffect(() => {
    let data = {
      page: page + 1,
      perPage: perPage,
      from_date: from ? moment(from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      to_date: to ? moment(to).add(1, 'days').format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD'),
      seller_id: seller ? seller : '',
      type: type ? type : 'open',
      cancel: cancel
    }
    getSellerPerformanceOrders(data)
  }, [])

  const statusStyle = (status) => {
    return status.includes("Processed") ? "text-white bg-primary" : status.includes("Ready For Dispatch") ? "text-white bg-amber" : status.includes("Placed") ? "text-white bg-yellow" : status.includes("Cancelled") ? "text-white bg-grey" : status.includes("Delivered") ? "text-white bg-green" : status.includes("Requested") ? "text-white bg-magenta" : status.includes("New") ? "text-white bg-danger" : "text-white bg-info";
  }

  const changePage = (page) => {
    let data = {
      page: page + 1,
      perPage: perPage,
      from_date: moment(from).format('YYYY-MM-DD'),
      to_date: moment(to).add(1, 'days').format('YYYY-MM-DD'),
      type: type,
      seller_id: seller ? seller : ''
    }
    getSellerPerformanceOrders(data)
    setPage(page)
  };

  const columns = [
    {
      name: "orderId",
      label: "Order ID",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Latest Date",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    }
  ];

  let data = [];
  orderList && orderList.detail && orderList.detail.length > 0 && orderList.detail.map((data1, index) => {
    let local = moment(data1.createdAt).format(' D MMM, YYYY h:mm a');
    let local1 = moment(data1.order_status[data1.order_status.length - 1].date).format(' D MMM, YYYY h:mm a');
    data.push([<div>
      <a href={`/admin/orders/orderDetails/${data1.order_id}`}>
        <h4><span className="font-weight-bolder">{data1.products.length}
          &nbsp;{data1.products.length > 1 ? 'items' : 'item'} by </span>{data1.user_id && data1.user_id.company_name} <a rel="noreferrer" target='_blank' href={`/admin/buyers/details/${data1.user_id._id}`}><span className='h6'>({data1.user_id && data1.user_id.buyerId})</span></a></h4>
      </a>
      {data1.seller_id &&
        (data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '').length > 25 ?
        <span className="small font-weight-bolder">{(data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '').slice(0, 25) + "..."} | </span>
        :
        <span className="small font-weight-bolder">{(data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '')} | </span>
      }
      <span className="small font-weight-bolder">{data1.order_id} | {data1.isBulk === true ? 'Bulk Prepaid' : data1.paymentType === 'Online' ? 'Prepaid' : data1.paymentType} | {moment(data1.createdAt).format(' D MMM, YYYY h:mm a')} </span>
      {(moment().diff(moment(data1.createdAt), 'hours')) <= 48 ?
        <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'green' }}></span> :
        (moment().diff(moment(local), 'hours')) >= 48 && (moment().diff(moment(local), 'hours')) <= 72 ?
          <span className="size-8 rounded-circle d-inline-block ml-1" style={{ backgroundColor: 'orange' }}></span> :
          (moment().diff(moment(local), 'hours')) >= 72 && data1.order_status[data1.order_status.length - 1].status === 'New' ?
            <span className="size-8 rounded-circle d-inline-block ml-1 blink_me" style={{ backgroundColor: 'red' }}></span> : ''
      }
    </div>,
    <div><p className={'ptrPriceFont1 priceColorInventory m-0'}>
      ₹{((data1.total_amount).toFixed(2))}
    </p></div>,
    (local1),
    <div key={'recent'} className={` badge text-uppercase ${statusStyle(data1.requested !== "Requested" ? data1.order_status[data1.order_status.length - 1].status : 'Requested')}`}>{data1.requested === "Requested" ? 'Requested' : data1.order_status[data1.order_status.length - 1].status}</div>
    ])
  })

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: false,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: page,
    rowsPerPage: perPage,
    rowsPerPageOptions: [],
    count: orderList && orderList.count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: '',
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  return (
    <AdminContainer>
      <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
        {loader ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}
        {
          loader ?
            null :
            <MUIDataTable
              title={`Showing ${type.toUpperCase()} Orders For ${orderList.seller} Between ${moment(from).format('DD-MMM-YYYY')} To ${moment(to).format('DD-MMM-YYYY')}`}
              data={data}
              columns={columns}
              options={options}
              style={{ borderRadius: '0px !important' }}
            />
        }
      </div>
    </AdminContainer>
  );
}

export default SellerOrderList;
