import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';

import { increamentData } from "./mdata";
import { connect } from 'react-redux';

import { getBuyerSellerList, getDashboardCard, getTopFiveLeastFive, getVerifyPhone } from '../../../actions/admin'
import SellerDetailsHeader from './SellerDetailsHeader'
import moment from 'moment'
import AppConfig from '../../../constants/config'
import SalesStatistic from './SalesStatistic';
import TopFive from './TopFive';
import TopBuyer from './TopBuyer';
import axios from '../../../constants/axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InvoiceValidate from '../orders/routes/InvoiceValidate';
import { Field, Form } from 'react-final-form';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { required, gstValidation } from 'constants/validations';
import { NotificationManager } from 'react-notifications';
import ReactStrapTextField from '../../routes/components/ReactStrapTextField';
import RenderReactDatePicker from '../../routes/components/RenderDatePicker';
import renderTextField from 'components/textBox';
import { Col, Row, FormGroup } from 'reactstrap';
import Checkbox from '@mui/material/Checkbox';
import EditProfile from './Form21'
import AdminContainer from 'containers/AdminContainer';
import CircularProgress from '@mui/material/CircularProgress';

function Intranet(props) {
  const { id } = useParams();
  const [state, setState] = useState({
    anchorEl: undefined,
    menuState: false,
    activeSelling: 0,
    outOfStock: 0,
    shortExpireProduct: 0,
    slowMoving: 0,
    topFive: [],
    leastFive: [],
    form20: false,
    form21: false,
    fssai: false,
    gst: false,
    userDetails: '',
    complaice: '',
    checkedA: false,
    edit: false,
    remark: false
  })

  const onOptionMenuSelect = event => {
    setState((prev) => ({ ...prev, menuState: true, anchorEl: event.currentTarget }));
  };
  const handleRequestClose = () => {
    setState((prev) => ({ ...prev, menuState: false }))
  };

  const componentdidMount = async () => {
    let data = {
      filter: { user_type: 'seller', _id: id }
    }
    await axios.get('seller/getDashBoardCardDetails', {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      },
      params: {
        id: id
      }
    }
    ).then(result => {
      if (result.data.error) {
        console.log("error", result.data)
      } else {
        console.log("success", result.data)
        setState((prev) => ({
          ...prev,
          activeSelling: result.data.activeSelling,
          outOfStock: result.data.outOfStock,
          shortExpireProduct: result.data.shortExpireProduct,
          slowMoving: result.data.slowMoving
        }))
      }
    })
      .catch(error => {
        console.log("error catch", error)
      });
    props.getBuyerSellerList({ data })

    const { buyerSellerList } = props;
    setState((prev) => ({ ...prev, userDetails: buyerSellerList && buyerSellerList.users && buyerSellerList.users[0] && buyerSellerList.users[0] && buyerSellerList.users[0].data && buyerSellerList.users[0].data[0] }))

    if (state.userDetails && state.userDetails.isVerifiedPhone === false) {
      setState((prev) => ({ ...prev, checkedA: false }))
    } else {
      setState((prev) => ({ ...prev, checkedA: true }))
    }
  }

  useEffect(() => {
    componentdidMount()
  }, [])

  const Close = () => {
    setState(
      (prev) => ({
        ...prev,
        form20: false,
        form21: false,
        fssai: false,
        gst: false,
        remark: false
      }));
  };
  const handleChangeDocuments = (e) => {
    setState((prev) => ({ ...prev, uploadedInvoice: e }))
  }
  const handleChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
  }
  const newData = (e) => {
    setState((prev) => ({ ...prev, complaice: e }))
  }
  const apiCallForm20 = (value) => {
    let data = { userType: 'admin', id: id }
    let { uploadedInvoice, expiryDate20, drugLic20BLicNo, drugLic21BLicNo, expiryDate21, expiryDateff, fassaiLicNo, gstLicNo } = value;
    if (drugLic20BLicNo) {
      data['drugLic20B'] = uploadedInvoice.length > 200 ? uploadedInvoice : '';
      data['drugLic20BExpiry'] = expiryDate20;
      data['drugLic20BLicNo'] = drugLic20BLicNo;
    } else if (drugLic21BLicNo) {
      data['drugLic21B'] = uploadedInvoice.length > 200 ? uploadedInvoice : '';
      data['drugLic21BExpiry'] = expiryDate21;
      data['drugLic21BLicNo'] = drugLic21BLicNo;
    } else if (fassaiLicNo) {
      data['fassaiLic'] = uploadedInvoice.length > 200 ? uploadedInvoice : '';
      data['fassaiLicExpiry'] = expiryDateff;
      data['fassaiLicNo'] = fassaiLicNo;
    } else if (gstLicNo) {
      data['gstLic'] = uploadedInvoice.length > 200 ? uploadedInvoice : '';
      data['gstLicNo'] = gstLicNo;
    }
    setState((prev) => ({
      ...prev,
      form20: false,
      form21: false,
      fssai: false,
      gst: false,
      uploadedInvoice: '', expiryDate20: '', drugLic20BLicNo: '', drugLic21BLicNo: '', expiryDate21: '', expiryDateff: '', fassaiLicNo: '', gstLicNo: ''
    }));
    axios.post('/users/updateComplainceForm', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      },
    }
    ).then(result => {
      if (result.data.error) {
        NotificationManager.error(result.data.title);
      } else {
        NotificationManager.success('Compliance details updated successfully.');
        setState((prev) => ({ ...prev, complaice: result.data.detail }))
      }
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
    });
    if (state.userDetails && state.userDetails.isVerifiedPhone === false) {
      setState((prev) => ({ ...prev, checkedA: false }))
    } else {
      setState((prev) => ({ ...prev, checkedA: true }))
    }
  }
  function componentdidUpdate() {
    const { sellerStats, buyerSellerList } = props
    setState((prev) => ({ ...prev, userDetails: buyerSellerList && buyerSellerList.users && buyerSellerList.users[0] && buyerSellerList.users[0].data && buyerSellerList.users[0].data[0] }))

    if (state.userDetails !== state.complaice && state.complaice) {
      setState((prev) => ({
        ...prev,
        userDetails: state.complaice,
        complaice: ''
      }))
    }
  }

  useEffect(() => {
    componentdidUpdate()
  }, [props.buyerSellerList])

  const handleDateChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e }));
  }
  const handleChangeForPhone = name => (event, checked) => {
    let data;
    if (checked) {
      setState((prev) => ({ ...prev, checkedA: true }));
      data = {
        id: id, status: true
      }
      props.getVerifyPhone({ data })
    } else {
      setState((prev) => ({ ...prev, checkedA: false }));
      data = {
        id: id, status: false
      }
      props.getVerifyPhone({ data })
    }
  };
  const handleClick = (e) => {
    setState((prev) => ({ ...prev, edit: false }))
  }
  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val == key)
    return index > -1 ? true : false
  }
  const submitRemark = async (e) => {
    let data = {
      remark: state.remark,
      userId: id
    }
    await axios.post('/users/addRemark', data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then((result) => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title);
        setState((prev) => ({ ...prev, userDetails: result.data.data }))
      }
    })
    setState((prev) => ({ ...prev, remark: false }))
  }
  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  const { activeSelling, outOfStock, shortExpireProduct, slowMoving, userDetails, selectedDate, checkedA } = state;
  return (
    <AdminContainer>
      <React.Fragment>
        {props.loadingDetails ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> :
          <>
            <div className="app-wrapper" style={{ marginTop: "-10px" }}>
              <div className="dashboard animated slideInUpTiny animation-duration-3">
                <SellerDetailsHeader
                  userDetails={userDetails}
                />
                <div className="row">
                  <div className="col-12">
                    <div className="jr-card chart-user-statistics bg-primary darken-4 text-white">
                      <div className="orderDetails px-4 mb-4">
                        <Row className="pb-4 ml-2">
                          <Col xs={12} xl={4} sm={12} md={4} lg={4} className="orderDetailsMainCol">
                            <h3 style={{ fontSize: 15 }}>Seller Details</h3>
                            <div className="p-1">
                              <i className="zmdi zmdi-account-o zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                              <span className="align-self-center ml-1">
                                {
                                  userDetails && userDetails.first_name && userDetails.first_name !== undefined ? userDetails.first_name + ' ' + userDetails.last_name : 'N/A'
                                }
                              </span>
                            </div>
                            <div className="p-1">
                              <i className="zmdi zmdi-email zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                              <span className="align-self-center ml-1">
                                {
                                  userDetails && userDetails.email !== undefined ? userDetails.email : 'N/A'
                                }
                              </span>
                            </div>
                            <div className="p-1">
                              <i className="zmdi zmdi-pin zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                              <span className="align-self-center ml-1">
                                {
                                  userDetails !== undefined ? userDetails.user_address : 'N/A'
                                }
                              </span>&nbsp;
                              {isButtonAvailable('updateAddress') && <i class="zmdi zmdi-edit zmdi-hc-fw text-white"
                                onClick={() => setState((prev) => ({ ...prev, edit: true }))} />}
                              {
                                state.edit &&
                                <EditProfile
                                  userDetails={state.userDetails}
                                  edit={state.edit}
                                  newData={(e) => newData(e)}
                                  handleClick={(e) => handleClick(e)} />
                              }
                            </div>
                            <div className="p-1">
                              <i className="zmdi zmdi-city zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                              <span className="align-self-center ml-1">
                                {
                                  userDetails && userDetails.user_city !== undefined ? userDetails.user_city : 'N/A'
                                }
                              </span>
                              <p className="ml-4 mb-0">
                                {
                                  userDetails !== undefined ? userDetails.user_pincode : 'N/A'
                                }
                              </p>
                            </div>
                            <div className="p-1">
                              <i className="zmdi zmdi-phone zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                              <span className="align-self-center ml-1">
                                {
                                  userDetails !== undefined ? userDetails.phone : 'N/A'
                                }
                              </span>
                              {isButtonAvailable('verifyPhoneUser') && <Checkbox className="text-white"

                                checked={checkedA}
                                onChange={handleChangeForPhone('checkedA')}
                                value="SelectMail" />}
                            </div>
                          </Col>

                          <Col xs={12} xl={4} sm={12} md={4} lg={4} className="orderDetailsMainCol">

                            <h3 style={{ fontSize: 15 }}>KYC Details</h3>

                            {userDetails && userDetails.drugLic20B && userDetails.drugLic20B.name ?
                              <div className="p-1">
                                {isButtonAvailable('updateComplainceForm') && <span onClick={() => setState((prev) => ({ ...prev, form20: true }))} >
                                  <i class="zmdi zmdi-upload zmdi-hc-fw text-white"></i>
                                </span>}
                                <span className="align-self-center ml-1">
                                  FORM 20&nbsp;<span className="small">&#40;{userDetails && userDetails.drugLic20B && userDetails.drugLic20B.expires ? `${userDetails.drugLic20B.lic ? userDetails.drugLic20B.lic : ''} \u007C ` + moment(userDetails.drugLic20B.expires).format('DD/MM/YYYY') : ''}&#41;</span>
                                </span>
                                <span className="align-self-center ml-4 pull-right">
                                  <a target='blank' download href={`${AppConfig.baseUrl}users/${userDetails && userDetails.drugLic20B && userDetails.drugLic20B.name ? userDetails.drugLic20B.name : ''}`}>
                                    <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center pull-right" />
                                  </a>
                                </span>
                              </div>
                              :
                              <div className="p-1">
                                {isButtonAvailable('updateComplainceForm') && <span onClick={() => setState((prev) => ({ ...prev, form20: true }))} >
                                  <i class="zmdi zmdi-upload zmdi-hc-fw text-white"></i>
                                </span>}
                                <span className="align-self-center ml-1 text-grey">
                                  FORM 20&nbsp;
                                </span>
                              </div>}

                            {userDetails && userDetails.drugLic21B && userDetails.drugLic21B.name ?
                              <div className="p-1">
                                {isButtonAvailable('updateComplainceForm') && <span onClick={() => setState((prev) => ({ ...prev, form21: true }))} >
                                  <i class="zmdi zmdi-upload zmdi-hc-fw text-white"></i>
                                </span>}
                                <span className="align-self-center ml-1">
                                  FORM 21&nbsp;<span className="small">&#40;{userDetails && userDetails.drugLic21B && userDetails.drugLic21B.expires ? `${userDetails.drugLic21B.lic ? userDetails.drugLic21B.lic : ''} \u007C ` + moment(userDetails.drugLic21B.expires).format('DD/MM/YYYY') : ''}&#41;</span>
                                </span>
                                <span className="align-self-center ml-4 pull-right">
                                  <a target='blank' download href={`${AppConfig.baseUrl}users/${userDetails && userDetails.drugLic21B && userDetails.drugLic21B.name ? userDetails.drugLic21B.name : ''}`}>
                                    <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center pull-right" />
                                  </a>
                                </span>
                              </div>
                              :
                              <div className="p-1">
                                {isButtonAvailable('updateComplainceForm') && <span onClick={() => setState((prev) => ({ ...prev, form21: true }))} >
                                  <i class="zmdi zmdi-upload zmdi-hc-fw text-white"></i>
                                </span>}
                                <span className="align-self-center ml-1 text-grey">
                                  FORM 21&nbsp;
                                </span>
                              </div>}

                            {userDetails && userDetails.fassaiLic && userDetails.fassaiLic.name ?
                              <div className="p-1">
                                {isButtonAvailable('updateComplainceForm') && <span onClick={() => setState((prev) => ({ ...prev, fssai: true }))} >
                                  <i class="zmdi zmdi-upload zmdi-hc-fw text-white"></i>
                                </span>}
                                <span className="align-self-center ml-1">
                                  FASSAI&nbsp;
                                  {userDetails && userDetails.fassaiLic &&
                                    <span className="small">
                                      &#40;
                                      {userDetails && userDetails.fassaiLic && userDetails.fassaiLic.expires ?
                                        `${userDetails.fassaiLic.lic ? userDetails.fassaiLic.lic : ""} \u007C ` +
                                        moment(userDetails.fassaiLic.expires).format('DD/MM/YYYY')
                                        + '' : ''
                                      }
                                      &#41;</span>}
                                </span>

                                <span className="align-self-center ml-4 pull-right">
                                  <a target='blank' download href={`${AppConfig.baseUrl}users/${userDetails && userDetails.fassaiLic && userDetails.fassaiLic.name ? userDetails.fassaiLic.name : ''}`}>
                                    <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center pull-right" />
                                  </a>
                                </span>

                              </div>
                              :
                              <div className="p-1">
                                {isButtonAvailable('updateComplainceForm') && <span onClick={() => setState((prev) => ({ ...prev, fssai: true }))} >
                                  <i class="zmdi zmdi-upload zmdi-hc-fw text-white"></i>
                                </span>}
                                <span className="align-self-center ml-1 text-grey">
                                  FASSAI&nbsp;
                                </span>
                              </div>}

                            {userDetails && userDetails.gstLic && userDetails.gstLic.name ?
                              <div className="p-1">
                                {isButtonAvailable('updateComplainceForm') && <span onClick={() => setState((prev) => ({ ...prev, gst: true }))} >
                                  <i class="zmdi zmdi-upload zmdi-hc-fw text-white"></i>
                                </span>}
                                <span className="align-self-center ml-1">
                                  GSTN&nbsp;

                                  {userDetails && userDetails.gstLic &&
                                    <span className="small">

                                      {userDetails.gstLic.lic ?
                                        <React.Fragment>
                                          &#40;
                                          {userDetails && userDetails.gstLic ?
                                            `${userDetails.gstLic.lic ? userDetails.gstLic.lic : " "}` : ''}
                                          &#41;
                                        </React.Fragment>
                                        : null}

                                    </span>}
                                </span>
                                <span className="align-self-center ml-4 pull-right">
                                  <a target='blank' download href={`${AppConfig.baseUrl}users/${userDetails && userDetails.gstLic && userDetails.gstLic.name ? userDetails.gstLic.name : ''}`}>
                                    <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center pull-right" />
                                  </a>
                                </span>
                              </div>
                              :
                              <div className="p-1">
                                {isButtonAvailable('updateComplainceForm') && <span onClick={() => setState((prev) => ({ ...prev, gst: true }))} >
                                  <i class="zmdi zmdi-upload zmdi-hc-fw text-white"></i>
                                </span>}
                                <span className="align-self-center ml-1 text-grey">
                                  GSTN&nbsp;
                                </span>
                              </div>}

                            {userDetails && userDetails.user_bank_details && userDetails.user_bank_details.account_no ?
                              <div className="p-1">
                                <span className="align-self-center ml-1">
                                  Bank&nbsp;

                                  {userDetails && userDetails.user_bank_details &&
                                    <span className="small">

                                      {userDetails.user_bank_details.account_no ?
                                        <React.Fragment>
                                          &#40;
                                          {userDetails && userDetails.user_bank_details ?
                                            `${userDetails.user_bank_details.account_no ? userDetails.user_bank_details.account_no : " "}` : ''}
                                          &#41;
                                        </React.Fragment>
                                        : null}

                                    </span>}
                                </span>
                                <span className="align-self-center ml-4 pull-right">
                                  <a target='blank' download href={`${AppConfig.baseUrl}users/${userDetails && userDetails.user_bank_details && userDetails.user_bank_details.cancelledCheck ? userDetails.user_bank_details.cancelledCheck : ''}`}>
                                    <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center pull-right" />
                                  </a>
                                </span>
                              </div>
                              : ''}
                            {userDetails && userDetails.user_bank_details && userDetails.user_bank_details.ifsc_code ?
                              <div className="p-1">
                                <span className="align-self-center ml-1">
                                  IFSC&nbsp;

                                  {userDetails && userDetails.user_bank_details &&
                                    <span className="small">

                                      {userDetails.user_bank_details.ifsc_code ?
                                        <React.Fragment>
                                          &#40;
                                          {userDetails && userDetails.user_bank_details ?
                                            `${userDetails.user_bank_details.ifsc_code ? userDetails.user_bank_details.ifsc_code : " "}` : ''}
                                          &#41;
                                        </React.Fragment>
                                        : null}

                                    </span>}
                                </span>
                              </div> : ''}
                            {userDetails && userDetails.user_bank_details && userDetails.user_bank_details.recipient_name ?
                              <div className="p-1">
                                <span className="align-self-center ml-1">
                                  Recipient Name&nbsp;

                                  {userDetails && userDetails.user_bank_details &&
                                    <span className="small">

                                      {userDetails.user_bank_details.recipient_name ?
                                        <React.Fragment>
                                          &#40;
                                          {userDetails && userDetails.user_bank_details ?
                                            `${userDetails.user_bank_details.recipient_name ? userDetails.user_bank_details.recipient_name : " "}` : ''}
                                          &#41;
                                        </React.Fragment>
                                        : null}

                                    </span>}
                                </span>
                              </div> : ''}
                          </Col>
                          <Col xs={12} xl={4} sm={12} md={4} lg={4} className="orderDetailsMainCol">

                            <h3 style={{ fontSize: 15 }}>Insight's</h3>

                            {userDetails && userDetails.ApprovedBy ?
                              <div className="p-1">
                                <span>
                                  <i class="zmdi zmdi-account zmdi-hc-fw text-white"></i>
                                </span>
                                <span className="align-self-center ml-1">
                                  Approved By&nbsp;-<span > {userDetails && userDetails.ApprovedBy.first_name ? `${userDetails.ApprovedBy.first_name}` : ''}&nbsp;
                                    {userDetails && userDetails.ApprovedBy.last_name ? `${userDetails.ApprovedBy.last_name}` : ''}</span>
                                </span>
                              </div>
                              : ''}

                            {userDetails && userDetails.createdAt ?
                              <div className="p-1">
                                <span>
                                  <i class="zmdi zmdi-format-align-justify zmdi-hc-fw text-white"></i>
                                </span>
                                <span className="align-self-center ml-1">
                                  Joined At&nbsp;-<span > {userDetails && userDetails.createdAt ? `${moment(userDetails.createdAt).format('DD/MM/YYYY')}` : ''}</span>
                                </span>
                              </div>
                              : ''}

                            {userDetails && userDetails.approvedDate ?
                              <div className="p-1">
                                <span>
                                  <i class="zmdi zmdi-check zmdi-hc-fw text-white"></i>
                                </span>
                                <span className="align-self-center ml-1">
                                  Approved At&nbsp;-<span > {userDetails && userDetails.approvedDate ? `${moment(userDetails.approvedDate).format('DD/MM/YYYY')}` : ''}</span>
                                </span>
                              </div>
                              : ''}

                            {userDetails && userDetails.manager_1 ?
                              <div className="p-1">

                                <span className="align-self-center ml-1">
                                  Relational Manager 1 &nbsp;-<span > {userDetails && userDetails.manager_1 ? `${userDetails.manager_1.first_name}` : ''}&nbsp;
                                  </span>
                                </span>
                              </div>
                              : ""
                            }
                            {
                              userDetails && userDetails.manager_2 ?
                                <div className="p-1">

                                  <span className="align-self-center ml-1">
                                    Relational Manager 2 &nbsp;-<span > {userDetails && userDetails.manager_2 ? `${userDetails.manager_2.first_name}` : ''}&nbsp;
                                    </span>
                                  </span>
                                </div>
                                : ""
                            }
                            <h3 style={{ fontSize: 15, marginTop: '10px', marginBottom: '0px' }}>Remark&nbsp;
                              {isButtonAvailable('addRemark') && <i class="zmdi zmdi-edit zmdi-hc-fw text-white" onClick={() => setState((prev) => ({ ...prev, remark: true }))} />}</h3>
                            {userDetails && userDetails.remark && userDetails.remark.length > 0 ?
                              <div className="pt-1 pl-1 pr-3">
                                <span className="align-self-center ml-1">
                                  {userDetails && userDetails.remark && userDetails.remark[userDetails.remark.length - 1].msg}
                                </span><br />
                                <span className="align-self-center ml-1" style={{ fontSize: '13px' }}>
                                  Date:&nbsp;{moment(userDetails && userDetails.remark && userDetails.remark[userDetails.remark.length - 1].date).format('DD/MM/YYYY')}
                                </span>
                              </div>
                              : ''}
                            {userDetails && userDetails.current_remark && Object.keys(userDetails.current_remark).length > 0 ?
                              <span className="align-self-center ml-1 pl-1" style={{ fontSize: '13px' }}>
                                Remark By:&nbsp;{userDetails && userDetails.current_remark.remarkBy &&
                                  userDetails.current_remark.remarkBy.first_name}&nbsp;
                                {userDetails && userDetails.current_remark.remarkBy &&
                                  userDetails.current_remark.remarkBy.last_name}
                              </span>
                              : userDetails && userDetails.remark && userDetails.remark.length > 0 ?
                                <span className="align-self-center ml-1 pl-1" style={{ fontSize: '13px' }}>
                                  Remark By:&nbsp;{userDetails && userDetails.remark[userDetails.remark.length - 1].remarkBy.first_name}&nbsp;
                                  {userDetails && userDetails.remark[userDetails.remark.length - 1].remarkBy.last_name}
                                </span>
                                : ''}

                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">

                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <NavLink to={{ pathname: "/admin/inventory/", title: 'activeSelling', id: id }} style={{ cursor: "pointer" }}>
                      <div className="jr-card jr-card-full">
                        <div className={`jr-fillchart bg-success jr-overlay-fillchart`}>
                          <div className="card-title mb-3">
                            <h5 style={{ color: 'white', marginBottom: '0px' }}>Active Selling Products</h5>
                          </div>
                          <ResponsiveContainer width="100%" height={75}>
                            <AreaChart data={increamentData}
                              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                              <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#3a983e' fill='#3a983e'
                                fillOpacity={1} />
                            </AreaChart>
                          </ResponsiveContainer>
                          <div className="jr-fillchart-content">
                            <div className="card-title mb-4">
                              <h5 style={{ color: 'white', marginBottom: '0px' }}>Active Selling Products</h5>
                            </div>
                            <h2 className="mb-2 jr-fs-xl jr-font-weight-medium">{activeSelling}</h2>
                            <p className="mb-0 jr-fs-sm">
                              <span
                                className={`jr-font-weight-medium jr-fs-md jr-chart-up`}>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>



                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <NavLink to={{ pathname: "/admin/inventory/", title: 'slowMoving', id: id }} style={{ cursor: "pointer" }}>
                      <div className="jr-card jr-card-full">
                        <div className={`jr-fillchart bg-info jr-overlay-fillchart`}>
                          <div className="card-title mb-3">
                            <h5 style={{ color: 'white', marginBottom: '0px' }}>Slow Moving Products</h5>
                          </div>
                          <ResponsiveContainer width="100%" height={75}>
                            <AreaChart data={increamentData}
                              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                              <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#0c8e9f' fill='#0c8e9f'
                                fillOpacity={1} />
                            </AreaChart>
                          </ResponsiveContainer>
                          <div className="jr-fillchart-content">
                            <div className="card-title mb-4">
                              <h5 style={{ color: 'white', marginBottom: '0px' }}>Slow Moving Products</h5>
                            </div>
                            <h2 className="mb-2 jr-fs-xl jr-font-weight-medium">{slowMoving}</h2>
                            <p className="mb-0 jr-fs-sm">
                              <span
                                className={`jr-font-weight-medium jr-fs-md jr-chart-up`}>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <NavLink to={{ pathname: "/admin/inventory/", title: 'outOfStock', id: id }} style={{ cursor: "pointer" }}>
                      <div className="jr-card jr-card-full">
                        <div className={`jr-fillchart bg-indigo jr-overlay-fillchart`}>
                          <div className="card-title mb-3">
                            <h5 style={{ color: 'white', marginBottom: '0px' }}>Out Of Stock Products</h5>
                          </div>
                          <ResponsiveContainer width="100%" height={75}>
                            <AreaChart data={increamentData}
                              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                              <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#273894' fill='#273894'
                                fillOpacity={1} />
                            </AreaChart>
                          </ResponsiveContainer>
                          <div className="jr-fillchart-content">
                            <div className="card-title mb-4">
                              <h5 style={{ color: 'white', marginBottom: '0px' }}>Out Of Stock Products</h5>
                            </div>
                            <h2 className="mb-2 jr-fs-xl jr-font-weight-medium">{outOfStock}</h2>
                            <p className="mb-0 jr-fs-sm">
                              <span
                                className={`jr-font-weight-medium jr-fs-md jr-chart-up`}>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <NavLink to={{ pathname: "/admin/inventory/", title: 'shortExpiry', id: id }} style={{ cursor: "pointer" }}>
                      <div className="jr-card jr-card-full">
                        <div className={`jr-fillchart bg-pink jr-overlay-fillchart`}>
                          <div className="card-title mb-3">
                            <h5 style={{ color: 'white', marginBottom: '0px' }}>Short Expiry Products</h5>
                          </div>
                          <ResponsiveContainer width="100%" height={75}>
                            <AreaChart data={increamentData}
                              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                              <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#ae1c4e' fill='#ae1c4e'
                                fillOpacity={1} />
                            </AreaChart>
                          </ResponsiveContainer>
                          <div className="jr-fillchart-content">
                            <div className="card-title mb-4">
                              <h5 style={{ color: 'white', marginBottom: '0px' }}>Short Expiry Products</h5>
                            </div>
                            <h2 className="mb-2 jr-fs-xl jr-font-weight-medium">{shortExpireProduct}</h2>
                            <p className="mb-0 jr-fs-sm">
                              <span
                                className={`jr-font-weight-medium jr-fs-md jr-chart-up`}>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>

                </div>
                <div className="row">
                  <div className="col-12">
                    <SalesStatistic id={id} />
                  </div>
                </div>
                <TopFive
                  id={id}
                />
                <TopBuyer
                  id={id}
                />
              </div>
            </div>

            <Dialog open={state.form20} onClose={Close}>
              <DialogTitle>FORM 20</DialogTitle>
              <Form
                onSubmit={apiCallForm20}
                initialValues={{
                  drugLic20BLicNo: '',
                  expiryDate20: '',
                  uploadedInvoice: ''
                }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <DialogContent>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Field label='Name' type='text' name='drugLic20BLicNo'
                              component={renderTextField} id="drugLic20BLicNo"
                              validate={required}
                            />
                          </FormGroup>
                        </Col>
                        <Col style={{ marginTop: '-7px' }}>
                          <FormGroup>
                            <Field label='Date' type='date' id="date"
                              validate={required} component={RenderReactDatePicker}
                              name='expiryDate20'
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field
                            name="uploadedInvoice"
                            component={InvoiceValidate}
                            validate={required}
                            id="uploadedInvoice1"
                            label="Upload FORM 20"
                          />
                        </Col>
                      </Row>
                    </DialogContent>
                    <DialogActions className="pr-4">
                      <Button onClick={Close} color='secondary' > Cancel </Button>
                      <Button type='submit' color='primary'> Save </Button>
                    </DialogActions>
                  </form>
                )} />
            </Dialog>
            {/* ---------------------------------FORM 21------------------------------------ */}
            <Dialog open={state.form21} onClose={Close}>
              <DialogTitle>FORM 21</DialogTitle>
              <Form
                onSubmit={apiCallForm20}
                initialValues={{
                  drugLic21BLicNo: '',
                  expiryDate21: '',
                  uploadedInvoice: ''
                }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <DialogContent>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Field label='Name' type='text' name='drugLic21BLicNo'
                              component={renderTextField}
                              validate={required} id="drugLic21BLicNo"
                            />
                          </FormGroup>
                        </Col>
                        <Col style={{ marginTop: '-7px' }}>
                          <FormGroup>
                            <Field label='Date' type='date' name='expiryDate21'
                              validate={required} id="date" component={RenderReactDatePicker}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field
                            name="uploadedInvoice"
                            component={InvoiceValidate}
                            validate={required}
                            id="uploadedInvoice2"
                            label="Upload FORM 21"
                          />
                        </Col>
                      </Row>
                    </DialogContent>
                    <DialogActions className="pr-4">
                      <Button onClick={Close} color='secondary' > Cancel </Button>
                      <Button type='submit' color='primary'> Save </Button>
                    </DialogActions>
                  </form>)}
              />
            </Dialog>
            {/* ---------------------------------FSSAI------------------------------------ */}
            <Dialog open={state.fssai} onClose={Close}>
              <DialogTitle>FSSAI</DialogTitle>
              <Form
                onSubmit={apiCallForm20}
                initialValues={{
                  fassaiLicNo: '',
                  expiryDateff: '',
                  uploadedInvoice: ''
                }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <DialogContent>
                      <Row>
                        <Col >
                          <FormGroup>
                            <Field label='Name' type='text' name='fassaiLicNo'
                              component={renderTextField}
                              validate={required} id="fassaiLicNo"
                            />
                          </FormGroup>
                        </Col>
                        <Col style={{ marginTop: '-7px' }}>
                          <FormGroup >
                            <Field label='Date' type='date' name='expiryDateff'
                              validate={required} id="expiryDateff" component={RenderReactDatePicker}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field
                            name="uploadedInvoice"
                            component={InvoiceValidate}
                            validate={required}
                            id="uploadedInvoice3"
                            label="Upload FSSAI"
                          />
                        </Col>
                      </Row>
                    </DialogContent>
                    <DialogActions className="pr-4">
                      <Button onClick={Close} color='secondary' > Cancel </Button>
                      <Button type='submit' color='primary'> Save </Button>
                    </DialogActions>
                  </form>)}
              />
            </Dialog>
            {/* ---------------------------------GST------------------------------------ */}
            <Dialog open={state.gst} onClose={Close}>
              <DialogTitle>GST</DialogTitle>
              <Form
                onSubmit={apiCallForm20}
                initialValues={{
                  gstLicNo: '',
                  uploadedInvoice: ''
                }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <DialogContent>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Field label='Number' type='text' name='gstLicNo'
                              component={renderTextField}
                              validate={composeValidators(required, gstValidation)}
                              id="gstLicNo"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field
                            name="uploadedInvoice"
                            component={InvoiceValidate}
                            validate={required}
                            id="uploadedInvoice"
                            label="Upload GST"
                          />
                        </Col>
                      </Row>
                    </DialogContent>
                    <DialogActions className="pr-4">
                      <Button onClick={Close} color='secondary' > Cancel </Button>
                      <Button type='submit' color='primary'> Save </Button>
                    </DialogActions>
                  </form>)}
              />
            </Dialog>
            {/* -------------------Remark--------------------------------- */}
            <Dialog open={state.remark} onClose={Close}
              fullWidth={true}
              maxWidth={'md'}>
              <DialogTitle className='pb-0'>
                Add Remark
              </DialogTitle>
              <Form
                onSubmit={submitRemark}
                initialValues={{
                  remark: '',
                }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <React.Fragment>
                      <DialogContent>
                        <Row>
                          <Col>
                            <FormGroup >
                              <Field label='Remark' type='textarea' name='remark'
                                component={ReactStrapTextField}
                                validate={required} id="remark"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </DialogContent>
                      <DialogActions className='pr-3'>
                        <Button onClick={Close} color='secondary' >
                          Cancel
                        </Button>
                        <Button color='primary' type='submit'>
                          Submit
                        </Button>
                      </DialogActions>
                    </React.Fragment>
                  </form>)}
              />
            </Dialog>
          </>
        }
      </React.Fragment>
    </AdminContainer>
  );
}

const mapStateToProps = ({ admin }) => {
  const { buyerSellerList, sellerStats, topFive, leastFive, verifyPhoneUser, actionList, loadingDetails } = admin;
  return { buyerSellerList, sellerStats, topFive, leastFive, verifyPhoneUser, actionList, loadingDetails }
};


export default connect(
  mapStateToProps,
  {
    getBuyerSellerList, getDashboardCard, getTopFiveLeastFive, getVerifyPhone
  }
)(Intranet)