import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AdminHeader from 'components/AdminHeader/index';
import AdminSidebar from 'containers/AdminSideNav/index';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import ColorOption from 'containers/Customizer/ColorOption';
import { isIOS, isMobile } from 'react-device-detect';
import { getSidebar } from 'actions/admin';
import TopNav from 'components/TopNav';
import { useLocation, useNavigate } from 'react-router';

const App = (props) => {
    const history = useNavigate()
    const location = useLocation()
    const { drawerType, navigationStyle, horizontalNavPosition } = props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    // set default height and overflow for iOS mobile Safari 10+ support.
    useEffect(() => {
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height');
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height');
        }
    }, []);

    useEffect(() => {
        if (!props.sidebar || props.sidebar.length === 0) {
            props.getSidebar({ history, location });
        }
    }, [location.pathname]);
    
    return (
        <div className={`app-container ${drawerStyle}`}>
            <AdminSidebar sidebar={props.sidebar} />
            <div className="app-main-container">
                <div className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                    {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) && <TopNav styleName="app-top-header" />}
                    <AdminHeader />
                    {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) && <TopNav />}
                </div>
                <main className="app-main-content-wrapper">
                    <div className="app-main-content">
                        <div className='app-wrapper'>
                            {props.children}
                        </div>
                    </div>
                </main>
            </div>
            <ColorOption />
        </div>
    );
};

const mapStateToProps = ({ settings, admin }) => {
    const { sidebar } = admin;
    const { drawerType, navigationStyle, horizontalNavPosition } = settings;
    return { drawerType, navigationStyle, horizontalNavPosition, sidebar };
};

export default connect(mapStateToProps, { getSidebar })(App);
