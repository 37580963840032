import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { changeTab } from '../../../../../../actions/tabAction';
import { connect } from 'react-redux';
import Open from '../../../../ticket/routes/Open';
import { useLocation, useNavigate } from 'react-router';
import Closed from '../../../../ticket/routes/Closed';

function TabContainer({ children, dir }) {
  return (
    <div dir={dir}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

function FullWidthTabsForTicket(props) {
  const [value, setValue] = useState(0)
  const history = useNavigate()
  const location = useLocation()

  const handleChange = (event, value) => {
    props.changeTab(value)
    // props.history.replace(`/admin/tickets/list`)
  };

  const handleChangeIndex = index => {
    props.changeTab(index)
  };
  const { tabValue, tabFor, sellerData, buyerData } = props;

  return (
    <div className="w-100" >
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
        >
          <Tab className="tab" label="Open" />
          <Tab className="tab" label="Closed" />
        </Tabs>
      </AppBar>
      {tabValue==0 && <TabContainer >
        {
          tabFor === 'ticket' ? <Open tab={tabValue} history={history} location={location} buyerData={buyerData} sellerData={sellerData} /> : ''
        }
      </TabContainer>}
     {tabValue==1 &&  <TabContainer >
        {
          tabFor === 'ticket' ? <Closed tab={tabValue} history={history} location={location} buyerData={buyerData} sellerData={sellerData} /> : ''
        }
      </TabContainer>}
    </div>
  );
}

FullWidthTabsForTicket.propTypes = {
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = ({ tabAction }) => {
  const { tabValue } = tabAction;
  return { tabValue }
};

export default connect(mapStateToProps, { changeTab })(FullWidthTabsForTicket);