import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import { Label } from 'reactstrap';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TextField, Checkbox, Paper } from '@mui/material';
import axios from 'constants/axios';
import { NotificationManager } from 'react-notifications'
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const NotifyPopup = (props) => {
  const [seller, setSeller] = useState([])
  const [loader, setLoader] = useState(false)
  const [err, setErr] = useState(false)
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)
  const [showSellers, setShowSellers] = useState(false)
  const [buyer, setBuyer] = useState('')
  const [selected, setSelected] = useState('All')

  const handleSellerChange = (e) => {
    if (e && e.target.value === 'All') {
      let allSellers = []
      props.sellers.map(eachSeller => allSellers.push(eachSeller._id))
      setSeller([...allSellers])
      setErr(false)
      setShowSellers(false)
      setSelected('All')
    } else if (e && e.target.value === 'Select Seller') {
      setSeller([])
      setErr(false)
      setShowSellers(true)
      setSelected('Select Seller')
    }
  }

  const cancelClicked = () => {
    setSeller([])
    setLoader(false)
    setErr(false)
    setMessage('')
    setError(false)
    setShowSellers(false)
    setBuyer('')
    setSelected('All')
    props.onClose()
  }

  const handleChange = (e, key) => {
    setMessage(e.target.value)
    setError(false)
  }

  useEffect(() => {
    let allSellers = []
    props.sellers.map(eachSeller => allSellers.push(eachSeller._id))
    setMessage(props.userType === 'Buyer' && props.data ? `${props.data.product_name} is back in stock on Medimny. You can order now !` : '')
    setSeller(props.userType === 'Seller' ? allSellers : [])
    setBuyer(props.userType === 'Buyer' && props.data && props.data.User ? props.data.User._id : '')
  }, [props.sellers, props.data])

  const handleCheck = (e, index) => {
    e.preventDefault();
    let tempSellers = [...seller];
    let sellerIndex = tempSellers.findIndex((e) => String(e) === String(props.sellers[index]._id));
    if (sellerIndex > -1) {
      tempSellers.splice(sellerIndex, 1);
      setSeller(tempSellers);
    } else {
      tempSellers.push(props.sellers[index]._id);
      setSeller(tempSellers);
    }
    if (tempSellers.length === props.sellers.length) {
      setSeller(tempSellers);
    }
  }

  const handleNotify = async () => {
    if (props.userType === 'Seller' && seller.length < 1) {
      setErr(true)
    } else if (props.userType === 'Buyer' && message === '') {
      setError(true)
    } else {
      let data = {
        seller: seller,
        data: props.data,
        buyer: buyer,
        message: message
      }
      setLoader(true)
      await axios.post('/shortBook/notifyUsers', data, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('admin_token')
        }
      }).then(result => {
        if (result.data.error) {
          NotificationManager.error(result.data.title);
          cancelClicked()
        } else {
          NotificationManager.success(result.data.title);
          cancelClicked()
        }
      }).catch(error => {
        NotificationManager.error('Something went wrong, Please try again')
        cancelClicked()
      });
    }
  }

  const { open, sellers, userType } = props;
  console.log(sellers)
  return (
    <React.Fragment>
      <Dialog open={open}
        onClose={cancelClicked}
        fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle>
          Send Notification To {userType}
        </DialogTitle>
        {loader ?
          <div className="loader-view"
            style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
            <CircularProgress />
          </div>
          :
          <React.Fragment>
            <DialogContent>
              <form>
                {userType === 'Seller' ?
                  <React.Fragment>
                    <div hidden={!err} className='text-danger'>Please select seller !</div>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={12} lg={12} style={{ paddingBottom: '15px' }}>
                        <Label>Seller</Label>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selected}
                          fullWidth={true}
                          onChange={(e) => handleSellerChange(e)}
                        >
                          <MenuItem value={'All'}>All</MenuItem>
                          <MenuItem value={'Select Seller'}>Select Seller</MenuItem>
                        </Select>
                      </Col>
                    </Row>
                    <Paper style={{ maxHeight: 300, overflow: 'auto' }} hidden={!showSellers}>
                      {
                        (sellers && sellers.length > 0) ? sellers.map((value, key) => {
                          let index = seller.findIndex((e) => String(e) === String(value._id))
                          return <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                            <FormControlLabel
                              onClick={(e) => handleCheck(e, key)}
                              control={
                                <Checkbox
                                  checked={index > -1 ? true : false}
                                  value={value._id}
                                  color="primary"
                                />
                              }
                              label={value.company_name}
                            />
                          </Col>
                        }) : ''
                      }
                    </Paper>
                  </React.Fragment>
                  : userType === 'Buyer' ?
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={12} lg={12} style={{ paddingBottom: '15px' }}>
                        <TextField
                          label="Message"
                          rows={5} required={true}
                          multiline={true} fullWidth={true}
                          error={error} helperText={error ? "Message is required" : ''}
                          style={{ color: "primary" }}
                          value={message} variant="outlined"
                          name="message"
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    : ''
                }
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelClicked} color='secondary' >
                Cancel
              </Button>
              <Button onClick={handleNotify} color='primary'>
                Notify
              </Button>
            </DialogActions>
          </React.Fragment>
        }
      </Dialog>
    </React.Fragment>
  );
}

export default NotifyPopup 