import React from 'react';
import FormHelperText from '@mui/material/FormHelperText'
import moment from 'moment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText style={{ color: '#f44336' }}>{touched && error}</FormHelperText>
  }
}

const RenderDatePicker = ({ label, input: { onChange, value, name }, meta: { touched, error } }) => {
  const selectedDate = value ?
    moment(value) : null
  return (
    <React.Fragment>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
      >
        <DatePicker
         slotProps={{ textField: { variant: 'standard' } }}
          onChange={onChange}
          name={name}
          label={label}
          value={moment(selectedDate)}
          fullWidth
          leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
          rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
          format="MM/DD/YYYY"
        />
      </LocalizationProvider>
      {renderFromHelper({ touched, error })}
    </React.Fragment>
  );
}

export default RenderDatePicker;