import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Col, Row } from 'reactstrap';
import { FormGroup } from 'reactstrap';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import DialogContentText from '@mui/material/DialogContentText';
import { listGroup, addStaff, listPermissionModules } from '../../../../actions/admin';
import { connect } from 'react-redux';
import ReactStrapTextField from '../../components/ReactStrapTextField/index';
import ReactstrapSelectField from '../../components/reactstrapSelectField/index';
import { Field, Form } from 'react-final-form'
import { required, emailField, validatePhone } from '../../../../constants/validations';
import Axios from '../../../../sagas/axiosRequest';
import helperFunction from '../../../../constants/helperFunction';

function AddStaff(props) {

  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    groupId: '',
    permission: [],
    groups: [],
    addGroup: false,
    isAllPermission: false,
    red: 0,
    blue: 0,
    green: 0
  })

  const fetchData = async () => {
    let groupListData = await Axios.axiosHelperFunc('get', 'seller/getGroupList', '', {});
    setState((prev) => ({ ...prev, groups: groupListData.data.detail }));

    let rgbValue = helperFunction.getRGB();
    setState((prev) => ({ ...prev, red: rgbValue[0], green: rgbValue[1], blue: rgbValue[2] }))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleClick = (key) => {
    setState((prev) => ({ ...prev, [key]: !state[key] }));
  }

  const handleAddGroupClick = (key) => {
    props.addGroup('add')
    setState((prev) => ({ ...prev, addGroup: !state.addGroup }));
  }

  const handleRequestClose = () => {
    props.handleClick('add');
    setState((prev) => ({ ...prev, permission: [], email: '', name: '', phone: '', groupId: '' }));
  };

  const handleChange = (event, key, form) => {
    if (key === 'group') {
      let index = state.groups.findIndex((e) => e._id === event.target.value);
      if (index > -1) {
        let tempPermission = state.permission;
        tempPermission = state.groups[index].permissions;

        let isAdminAccess = tempPermission.length === props.listpermissionModulesdata.length ? true : false;
        form.change('groupId', event.target.value);
        setState((prev) => ({ ...prev, permission: tempPermission, groupId: event.target.value, isAllPermission: isAdminAccess }));
      } else {
        setState((prev) => ({ ...prev, groupId: '' }));
      }
    } else {
      setState((prev) => ({ ...prev, [key]: event.target.value }));
    }
  }

  const onSubmit = (values) => {
    let data = {
      first_name: values.name,
      email: values.email,
      phone: values.phone,
      groupId: values.groupId,
      isAllPermission: state.isAllPermission,
      red: state.red,
      green: state.green,
      blue: state.blue,
    }
    props.handleClick('add')
    props.addStaff({ history: props.history, data, listStaff: { searchText: '', page: 1, perPage: 10 } })
    setState((prev) => ({ ...prev, permission: [], email: '', name: '', phone: '', groupId: '' }));
  }

  const { email, name, phone, permission, groupId } = state;
  const { buttonType, add, title, handleSubmit, listpermissionModulesdata, submitting } = props;

  return (
    <React.Fragment>
      <Dialog open={add} onClose={handleRequestClose}
        fullWidth={true}
        maxWidth={'md'}>
        <Form
          onSubmit={onSubmit}
          initialValues={
            {
              name: '',
              email: '',
              phone: '',
              groupId: ''
            }
          }
          render={({ handleSubmit, values, form }) => (
            <form noValidate onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle>
                {title}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <Row>
                  <Col xs={12} md={12} sm={12} xl={2} lg={2} className="addStaffAvatar">
                    <Avatar className="size-100" alt="Remy Sharp" src={require('assets/productImage/avatar.png')} />
                  </Col>
                  <Col xs={12} md={12} sm={12} xl={10} lg={10}>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <Field id="name" name="name" type="text"
                          component={ReactStrapTextField} label="Name"
                          validate={required}
                        />
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <Field id="email" name="email" type="text"
                          component={ReactStrapTextField} label="Email ID"
                          validate={emailField}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <Field id="phone" name="phone" type="text"
                          component={ReactStrapTextField} label="Phone"
                          validate={validatePhone}
                        />
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={6} lg={6}>
                        <Row>
                          <Col xs={10} md={10} sm={10} xl={10} lg={10}>
                            <Field
                              name="groupId"
                              component={ReactstrapSelectField}
                              label="Group"
                              validate={required}
                              value={groupId}
                              type="select"
                              id={'groupId'}
                              onChange={(event, newValue, previousValue) => handleChange(event, 'group', form)}
                            >
                              <option value=""> Select Group </option>
                              {
                                state.groups && state.groups.length > 0 && state.groups.map((value, key) => {
                                  return <option key={'group_' + key} value={value._id}>{value.name}</option>
                                })
                              }
                            </Field>
                          </Col>
                          <Col xs={2} md={2} sm={2} xl={2} lg={2} className="addGroupButton cursor-pointer">
                            <FormGroup>
                              <Icon color="primary" onClick={() => handleAddGroupClick('addGroup')} >add_circle</Icon>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {
                      groupId !== '' ? <Row>
                        {
                          listpermissionModulesdata && listpermissionModulesdata.length === permission.length ? <Col xs={6} md={6} sm={6} xl={6} lg={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={listpermissionModulesdata !== undefined && listpermissionModulesdata.length === permission.length}
                                  value={'admin_access'}
                                  color="primary"
                                  disabled={true}
                                />
                              }
                              label={'Admin Access'}
                            />
                          </Col> : ''
                        }
                        {
                          (permission !== undefined && permission.length > 0) ? permission.map((value, key) => {
                            return <Col xs={6} md={6} sm={6} xl={6} lg={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={true}
                                    value={value.value}
                                    color="primary"
                                    disabled={true}
                                  />
                                }
                                label={value.name}
                              />
                            </Col>
                          }) : ''
                        }
                      </Row> : ''
                    }
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions className="pr-4">
                <Button onClick={handleRequestClose} color='secondary' >
                  Cancel
                </Button>
                <Button type='submit' color='primary' disabled={submitting}>
                  Add
                </Button>
              </DialogActions>
            </form>)}
        />
      </Dialog>


    </React.Fragment>
  );
}


const mapStateToProps = ({ admin }) => {
  const { listGroupData, listpermissionModulesdata } = admin;
  return { listGroupData, listpermissionModulesdata }
};


export default connect(
  mapStateToProps,
  {
    listGroup,
    listPermissionModules,
    addStaff
  }              
)(AddStaff)