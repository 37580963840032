// import {combineReducers} from 'redux';
// import {connectRouter} from 'connected-react-router'
// import Settings from './Settings';
// import ChatData from './Chat';
// import Contact from './Contact';
// import Mail from './Mail';
// import ToDo from './ToDo';
// import Auth from './Auth';
// import TabAction from './tabReducers';

// import admin from './admin'

// export default (history) => combineReducers({
//   router: connectRouter(history),
//   form:reduxFormReducer,
//   settings: Settings,
//   chatData: ChatData,
//   contacts: Contact,
//   mail: Mail,
//   toDo: ToDo,
//   auth: Auth,
//   tabAction : TabAction,
//   admin
// });

import {combineReducers} from 'redux';
// import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import ChatData from './Chat';
import Contact from './Contact';
import Mail from './Mail';
// import ToDo from './ToDo';
import Auth from './Auth';
// import seller from './seller'
import TabAction from './tabReducers';
import notification from './notification';
import admin from './admin'
// import buyer from './buyer';
// import order from './order';
// import { reducer as reduxFormReducer } from 'redux-form';
export default (history) => combineReducers({
  // router: connectRouter(history),
  // form:reduxFormReducer,
  settings: Settings,
  chatData: ChatData,
  contacts: Contact,
  mail: Mail,
  // toDo: ToDo,
  auth: Auth,
  tabAction : TabAction,
  // seller,
  admin,
  notification,
  // buyer,
  // order
});
