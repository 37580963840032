import React, { useEffect, useState } from "react";
import moment from 'moment';
import { getOrderHistory } from 'actions/admin'
import { connect } from 'react-redux';
import CustomFilter from '../../../../components/Filter';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import helper from '../../../../constants/helperFunction';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router";
import Pagination from '@mui/material/Pagination';
import { Typography } from "@mui/material";
import PaginationCount from "app/routes/components/PaginationCount";


const customStyles = {
  BusinessAnalystRow: {
    '& td': { backgroundColor: "#00000012" }
  }
};

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

let date = new Date();


function OrderHistory(props) {
  const history = useNavigate()
  let url = window.location.search
  let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''

  const [state, setState] = useState({
    filter: [],
    perPage: 100,
    loader: false,
    seller_id: json ? json.seller_idOH ? json.seller_idOH : '' : '',
    searchedText: json ? json.searchOH ? json.searchOH : '' : '',
    page: json ? json.pageOH ? Number(json.pageOH) : 0 : 0,
    from: json ? json.from_dateOH ? json.from_dateOH : moment().subtract(15, 'days').format() : moment().subtract(15, 'days').format(),
    to: json ? json.to_dateOH ? json.to_dateOH : moment().format() : moment().format(),
    status: json ? json.statusOH ? json.statusOH : '' : '',
    type: json ? json.typeOH ? json.typeOH : '' : '',
    orderType: json ? json.orderTypeOH ? json.orderTypeOH : '' : '',
  });
  const [filter, setFilter] = useState(false)

  const onFilterChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
  }

  const fetchApi = () => {
    let stat = json ? json.statusOH ? json.statusOH : '' : ''
    let fromDate = json ? json.from_dateOH ? json.from_dateOH : moment().subtract(15, 'days').format() : moment().subtract(15, 'days').format()
    let toDate = json ? json.to_dateOH ? json.to_dateOH : moment().format() : moment().format()
    let dat = stat.split(",")
    let data = {
      page: json ? json.pageOH ? Number(json.pageOH) + 1 : 1 : 1,
      perPage: 100,
      filter: '',
      searchText: json ? json.searchOH ? json.searchOH : '' : '',
      from_date: moment(fromDate).format('YYYY-MM-DD'),
      to_date: moment(toDate).add(1, 'days').format('YYYY-MM-DD'),
      seller_id: json ? json.seller_idOH ? json.seller_idOH : '' : '',
      status: dat,
      type: json ? json.typeOH ? json.typeOH : '' : '',
      tab: 'orderHistory',
      orderType: json ? json.orderTypeOH ? json.orderTypeOH : '' : ''
    }

    if (props.tab == 2) {
      props.getOrderHistory({ data, history: history })
    }
  }
  useEffect(() => {
    if (props.tab == 2) {
      fetchApi()
      setState({
        ...state,
        seller_id: json ? json.seller_idOH ? json.seller_idOH : '' : '',
        searchedText: json ? json.searchOH ? json.searchOH : '' : '',
        page: json ? json.pageOH ? Number(json.pageOH) : 0 : 0,
        from: json ? json.from_dateOH ? json.from_dateOH : moment().subtract(15, 'days').format() : moment().subtract(15, 'days').format(),
        to: json ? json.to_dateOH ? json.to_dateOH : moment().format() : moment().format(),
        status: json ? json.statusOH ? json.statusOH : '' : '',
        type: json ? json.typeOH ? json.typeOH : '' : '',
        orderType: json ? json.orderTypeOH ? json.orderTypeOH : '' : '',
      })
    }
  }, [window.location.search])

  const statusStyle = (status) => {
    return status.includes("Processed") ? "text-white bg-primary" : status.includes("Ready for dispatch") ? "text-white bg-amber" : status.includes("Placed") ? "text-white bg-danger" : status.includes("Cancelled") ? "text-white bg-grey" : status.includes("Delivered") ? "text-white bg-green" : status.includes("RTO after Delivery") ? "text-white bg-brown" : status.includes("LOST") ? "text-white bg-black" : status.includes("RTO") ? "text-white bg-cyan" : "text-white bg-danger";
  }

  const handleSearch = (searchText) => {
    setState((prev) => ({ ...prev, searchedText: searchText ? searchText.replace(/"/g, '') : '' }))
    let obj = { from_dateOH: state.from, to_dateOH: state.to, pageOH: 0, searchOH: searchText ? searchText.replace(/"/g, '') : '', statusOH: state.status, seller_idOH: state.seller_id, typeOH: state.type, orderTypeOH: state.orderType }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)

  };
  const handleCloseSearch = () => {
    setState((prev) => ({ ...prev, searchedText: '' }))
    let obj = { from_dateOH: state.from, to_dateOH: state.to, pageOH: 0, searchOH: '', statusOH: state.status, seller_idOH: state.seller_id, typeOH: state.type, orderTypeOH: state.orderType }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)
  }
  const handleResetFilter = (e, filter) => {
    e.preventDefault();
    filter();
    setState((prev) => ({
      ...prev,
      month: monthNames[date.getMonth()],
      year: date.getFullYear(),
      filter: '',
      status: '',
      seller_id: '',
      from: '',
      to: '',
    }));

    let obj = {
      from_dateOH: '', to_dateOH: '', pageOH: 0, searchOH: state.searchedText, statusOH: '', seller_idOH: '',
      typeOh: '', orderTypeOH: ''
    }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)
  }

  const changePage = (page) => {
    setState((prev) => ({ ...prev, page: page }))
    let obj = { from_dateOH: state.from, to_dateOH: state.to, pageOH: page, searchOH: state.searchedText, statusOH: state.status, seller_idOH: state.seller_id, typeOH: state.type, orderTypeOH: state.orderType }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/orders?${url}`)

    setState((prev) => ({ ...prev, loader: true }))
    const t = setTimeout(() => {
      setState((prev) => ({ ...prev, loader: false }))
    }, 3000);
  };

  const sellerFromFilter = (e) => {
    setState((prev) => ({ ...prev, seller_id: e }))
  }
  const dateFilterFrom = (e) => {
    setState((prev) => ({ ...prev, from: e }))
  }
  const dateFilterTo = (e) => {
    setState((prev) => ({ ...prev, to: e }))
  }


  const { orderHistoryList } = props;

  function applyFilterClose() {
    setFilter(true)
    options.customFilterDialogFooter(null, options.applyNewFilters);
  }

  const columns = [
    {
      name: "buyerName",
      label: " ",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "orderId",
      label: "Order ID",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "buyer",
      label: "Buyer",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "seller",
      label: "Seller",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "reason",
      label: "Reason",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListRender: v => {
          return false;
        },
        filterOptions: {
          names: [],
          logic() {

          },
          display: () => {
            return (<CustomFilter onFilterChange={onFilterChange} applyFilter={applyFilterClose}
              searchText={state.searchedText} handleResetFilter={handleResetFilter} month={state.month} status={state.status} seller_id={state.seller_id} sellerFromFilter={(e) => sellerFromFilter(e)} from={state.from} to={state.to}
              dateFilterFrom={(e) => dateFilterFrom(e)} dateFilterTo={(e) => dateFilterTo(e)}
              year={state.year} page={state.page} location={props.location}
              history={history} filterFor='orderHistoryAdmin' />)
          },
        },
      }
    }
  ];

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: false,
    selectableRows: false,
    rowsPerPage: 100,
    page: state.page,
    fixedHeader: false,
    print: false,
    download: false,
    filter: true,
    sort: false,
    selectableRows: false,
    count: orderHistoryList && orderHistoryList.count,
    serverSide: true,
    server: true,
    selectableRowsOnClick: false,
    selectableRows: 'none',
    fixedHeader: false,
    search: true,
    searchText: state.searchedText,
    rowsPerPageOptions: [],
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    customFilterDialogFooter: (currentFilter, applyNewFilters) => {
      if (filter) {
        setFilter(false)
        return applyNewFilters();
      }
    },
    rangePage: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key == 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleCloseSearch();
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }


  let data = []
  orderHistoryList && orderHistoryList.detail && orderHistoryList.detail.length > 0 && orderHistoryList.detail.map((data1, index) => {
    const ShortExp = data1.products.filter(data => data.expiry_status === 'Short Expiry') 
    let d1 = (data1.user_id && data1.user_id.company_name).length > 30 ? (data1.user_id && data1.user_id.company_name).slice(0, 30) + "..." : data1.user_id && data1.user_id.company_name
    let d2 = (data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '').length > 30 ? (data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : '').slice(0, 30) + "..." : data1.seller_id && data1.seller_id.company_name ? data1.seller_id.company_name : ''
    data.push([
      <div style={helper.divFlex}>
        {ShortExp.length > 0 && <Tooltip title={`Short expiry`} placement="top-start">
          <span style={helper.styleForDiv} className={`${helper.styleDivLine('Short Expiry')}`}>&nbsp;&nbsp;</span>
        </Tooltip>}
        {data1.orderType && <Tooltip title={`${data1.orderType}`} placement="top-start">
          <div style={helper.styleForDiv} className={`${helper.styleDivLine(data1.orderType)}`}>&nbsp;&nbsp;</div>
        </Tooltip>}
        {data1.serviceName ? <Tooltip title={`${data1.serviceName}`} placement="top-start">
          <div style={helper.styleForDiv} className={`${helper.styleDivLine2(data1.serviceName)}`}>&nbsp;&nbsp;</div>
        </Tooltip> :
          <div style={helper.styleForDiv}>&nbsp;&nbsp;</div>
        }
      </div>,
      <a style={{ color: 'inherit' }} href={`/admin/orders/orderDetails/${data1.order_id}`} onClick={(e) => { e.preventDefault(); history(`/admin/orders/orderDetails/${data1.order_id}`) }}>{data1.order_id}</a>,
      <a style={{ color: 'inherit' }} href={`/admin/orders/orderDetails/${data1.order_id}`} onClick={(e) => { e.preventDefault(); history(`/admin/orders/orderDetails/${data1.order_id}`) }}>{d1}</a>,
      <a style={{ color: 'inherit' }} href={`/admin/orders/orderDetails/${data1.order_id}`} onClick={(e) => { e.preventDefault(); history(`/admin/orders/orderDetails/${data1.order_id}`) }}>{d2}</a>,
      <div><p className={'m-0'}>&#8377;{(data1.total_amount).toFixed(2)}</p></div>,
      <div key={'recent'} className={` badge text-uppercase ${statusStyle(data1.order_status[data1.order_status.length - 1].status)}`}>{data1.order_status[data1.order_status.length - 1].status}</div>, data1.order_cancel_reason,
      moment(data1.updatedAt).format(' D MMM, YYYY h:mm a')
    ])
  })
  const theme = createTheme({
    components: {

      MuiTableHead: {
        styleOverrides: {
          root: {
            paddingLeft: '0px'
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontSize: '1rem',
            paddingLeft: '0px',
            fontWeight: '600'
          },
          root: {
            paddingLeft: '0px',
          },
        }
      },
      MUIDataTableFilter: {
        styleOverrides: {
          reset: {
            display: "none"
          }
        }
      }
    }
  });
  const { loading } = props;
  return (
    <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
      {loading ? <div className="loader-view"
        style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
        <CircularProgress />
      </div> : null}
      {
        loading ?
          null :
          <ThemeProvider theme={theme}>
            <div className="mt-4 ms-4">{orderHistoryList.userName == 'admin' ? <h3 style={{ padding: "12px 5px", marginBottom: '0px' }}>Total Amount : ₹ {(orderHistoryList && orderHistoryList.sum).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h3> : ''}</div>
            <MUIDataTable
              title=''
              data={data}
              identifier={'allOrderListing'}
              columns={columns}
              options={options}
              style={{ borderRadius: 0 }}
            />
          </ThemeProvider>
      }
    </div>
    // </CustomScrollbars>
  );
}


const mapStateToProps = ({ admin }) => {
  const { orderHistoryList, loading } = admin;
  return { orderHistoryList, loading }
};

export default connect(mapStateToProps, { getOrderHistory })(OrderHistory);