import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider
} from "../firebase/firebase";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,

  SIGNIN_ADMIN,

  SIGNOUT_USER,
  SIGNUP_USER,
  SIGNOUT_ADMIN
} from "constants/ActionTypes";
import { showAuthMessage, userSignInSuccess, adminSignInSuccess, userSignOutSuccess, adminSignOutSuccess, userSignUpSuccess } from "actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess
} from "../actions/Auth";

import { apiFailed } from 'actions/admin'

import AxiosRequest from './axiosRequest'
import { NotificationManager } from 'react-notifications';


//--------------------------------------------------------------------------------------------

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth.createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await auth.signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signOutRequest = async () =>
  await auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);

const adminSignOutRequest = async () =>
  await auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGoogleRequest = async () =>
  await auth.signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await auth.signInWithPopup(facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await auth.signInWithPopup(githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await auth.signInWithPopup(twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}


function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}


function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}


function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage('Your request has been canceled.'));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password, history } = payload;
  try {
    localStorage.setItem('user_id', email);
    console.log('email', email);
    if (email === '@buyers') {
      history.push('/buyers/home');
      yield put(userSignInSuccess(payload));
    } if (email === '@seller') {
      history.push('/seller');
      yield put(userSignInSuccess(payload));
    } else {
      history.push('/signin')
    }

    // const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    // if (signInUser.message) {
    //   yield put(showAuthMessage(signInUser.message));
    // } else {

    // }
  } catch (error) {
    yield put(showAuthMessage(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

//---------Original admin signin function----------------------------------------------------

// function* signInAdminWithEmailPassword({ payload }) {
//   const { email, password, history } = payload;
//   try {
//     localStorage.setItem('user_id', email);
//     console.log('email', email);
//     if (email === '@admin') {
//       history.push('/admin/dashboard');
//       yield put(adminSignInSuccess(payload));
//     } else {
//       history.push('/signin')
//     }

//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }

//--------------------------------------------------------------------------------------------



function* signInAdminWithEmailPassword({ payload }) {
  const { email, password, history, user_type } = payload;
  try {

    const signInAdmin = yield call(AxiosRequest.axiosHelperFunc, 'post', 'users/login', '', payload);
    console.log('signInAdminasds', signInAdmin);

    if (signInAdmin.data.error) {
        // NotificationManager.error(signInAdmin.data.title)
        yield put(apiFailed(signInAdmin.data.title));
        console.log("apiFailed", signInAdmin.data.title)
    }     
    else {
      localStorage.setItem('Name', signInAdmin.data.detail.first_name);
      localStorage.setItem('admin_token', signInAdmin.data.token);
      NotificationManager.success(signInAdmin.data.title)
      yield put(adminSignInSuccess(signInAdmin.data));
      history('/admin/dashboard');
      window.location.reload()
      console.log('signInAdminsaga', signInAdmin);
      console.log('historyinsaga', history);
    }
  } 
  
  catch (error) {
    yield put(showAuthMessage(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}


//--------------------------------------------------------------------------------------------




function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    console.log("SIGNOUT_USER called", signOutUser);
    if (signOutUser === undefined) {
      localStorage.removeItem('admin_token');
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}

function* adminSignOut({payload}) {
  let { history } = payload
  console.log("SIGNOUTADminsadasdsdcalled", signOutAdmin);

  try {
    const signOutAdmin = yield call(adminSignOutRequest);
    console.log("SIGNOUT_ADmin called", signOutAdmin);
    if (signOutAdmin === undefined) {
      localStorage.removeItem('admin_token');
      yield put(adminSignOutSuccess(signOutAdmin));
      history.push('/signin')
    } else {
      yield put(showAuthMessage(signOutAdmin.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error && error.message ? error.message : "Something went wrong, please try again!"));
  }
}


export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}
export function* signInAdmin() {
  yield takeEvery(SIGNIN_ADMIN, signInAdminWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* signOutAdmin() {
  yield takeEvery(SIGNOUT_ADMIN, adminSignOut);
}


export default function* rootSaga() {
  yield all([fork(signInUser),
  fork(signInAdmin),
  fork(createUserAccount),
  fork(signInWithGoogle),
  fork(signInWithFacebook),
  fork(signInWithTwitter),
  fork(signInWithGithub),
  fork(signOutUser),
  fork(signOutAdmin)]);
}