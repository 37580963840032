
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { MenuBook } from '@mui/icons-material';
import CustomScrollbars from 'util/CustomScrollbars';
import { connect } from 'react-redux';

const SidenavContent = (props) => {
  const [metadataOpen, setMetadataOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()
  const closest = (el, selector) => {
    try {
      let matchesFn;
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) { }

    return null;
  };

  const navigationAvailable = (key) => {
    const available = props.sidebar.findIndex((val) => val.name === key);
    return available > -1 ? true : false;
  };

  useEffect(() => {
    const pathname = `${location.pathname}`;
    const activeLi = document.querySelector('a[href="' + pathname + '"]');
    try {
      const activeNav = closest(activeLi, 'ul');
      if (activeNav.classList.contains('sub-menu')) {
        closest(activeNav, 'li').classList.add('open');
      } else {
        closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) { }
  }, [location.pathname]);

  useEffect(() => {
    if (navigationAvailable('Metadata') && location.pathname.includes('undefined')) {
      navigate('/admin/productCategories');
    }
  }, [location.pathname]);

  return (
    <CustomScrollbars className=" scrollbar">
      <ul className="nav-menu">
        {props.sidebar &&
          props.sidebar.length > 0 &&
          props.sidebar.map((data, index) => {
            if (index === 0 && data.name !== 'Metadata') {
              return (
                <li
                  key={data.name}
                  className={
                    location.pathname.includes(data.pathname)
                      ? 'open menu no-arrow'
                      : 'menu no-arrow'
                  }
                  onClick={() => setMetadataOpen(false, setReportOpen(false))}
                >
                  <NavLink to={data.pathname}>
                    <i className={`zmdi ${data.icon} zmdi-hc-fw`} />
                    <span className="nav-text">{data.name}</span>
                  </NavLink>
                </li>
              );
            }
          })}
        {props.sidebar && props.sidebar.length > 0 && navigationAvailable('Metadata') && (
          <ul className="nav-menu">
            <li className={metadataOpen ? 'menu collapse-box no-arrow open' : 'menu collapse-box no-arrow'}>
              <Button onClick={() => setMetadataOpen(!metadataOpen, setReportOpen(false))}>
                <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                <span className="nav-text">Metadata</span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon" to="/admin/productCategories">
                    <span className="nav-text">
                      Product Categories
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon" to="/admin/productTypes">
                    <span className="nav-text"> Product Types </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon" to="/admin/manufacturers">
                    <span className="nav-text">
                      Manufacturers
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon" to="/admin/gstValues">
                    <span className="nav-text">
                      GST Values
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        )}
        {props.sidebar && props.sidebar.length > 0 && navigationAvailable('Reports') && (
          <ul className="nav-menu">
            <li className={reportOpen ? 'menu collapse-box no-arrow open' : 'menu collapse-box no-arrow'}>
              <Button onClick={() => setReportOpen(!reportOpen, setMetadataOpen(false))}>
                <i className="zmdi zmdi-file-text zmdi-hc-fw" />
                <span className="nav-text"> Reports</span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon" to="/admin/sellerPerformance">
                    <span className="nav-text"> Seller Performance </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon" to="/admin/outOfStock">
                    <span className="nav-text"> Out Of Stock</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon" to="/admin/salesReport">
                    <span className="nav-text">Seller Sales</span>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        )}
        {props.sidebar &&
          props.sidebar.length > 0 &&
          props.sidebar.map((data, index) => {
            if (index > 0 && data.name !== 'Metadata' && data.name !== 'Reports') {
              return (
                <li
                  key={data.name}
                  className={
                    location.pathname.includes('admin/sellerPerformance/orders') &&
                      data.name === 'Orders'
                      ? 'open menu no-arrow'
                      : location.pathname.includes(data.pathname)
                        ? 'open menu no-arrow'
                        : 'menu no-arrow'
                  }
                  onClick={() => setMetadataOpen(false, setReportOpen(false))}
                >
                  <NavLink to={data.pathname}>
                    {data.name === 'Shortbook' ? <MenuBook style={{ fontSize: 15, marginRight: '15px' }} /> : <i className={`zmdi ${data.icon} zmdi-hc-fw`} />}
                    <span className="nav-text">
                      {data.name}
                    </span>
                  </NavLink>
                </li>
              );
            }
          })}
      </ul>
    </CustomScrollbars>
  );
};

const mapStateToProps = ({ admin }) => {
  const { sidebar } = admin;
  return { sidebar };
};

export default connect(mapStateToProps, {})(SidenavContent);
