import React, { Component, useEffect, useState } from 'react';
// import MUIDataTable from "components/DataTable";
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import IntlMessages from '../../../../util/IntlMessages';
import ButtonGroup from '@mui/material/ButtonGroup';
import customHead from 'constants/customHead'
import { getBuyerSellerList, getBuyerSellerListAdmin, getUserApprove, getChangeUserStatus } from '../../../../actions/admin'
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

import { Form, FormGroup, Label, Input, FormText, Col, Row, } from 'reactstrap';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import BuyerHeaders from '../component/BuyerHeader';
import Tooltip from '@mui/material/Tooltip';
import debounce from 'lodash.debounce';
import AdminContainer from 'containers/AdminContainer';
import { ThemeProvider, createTheme } from '@mui/material';
import { styles } from 'constants/FilterStyle';
import Pagination from '@mui/material/Pagination';
import PaginationCount from 'app/routes/components/PaginationCount';




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const [anchorEl, setAnchorEl] = React.useState(null);


function BuyerList(props) {
  const [state, setState] = React.useState(
    {
      edit: false,
      permission: false,
      delete: false,
      add: false,
      action: '',
      name: '',
      id: '',
      loader: false,
      info: '',
      selectedDate: moment().format("YYYY-MM-DD"),
      dataForSuspend: '',
      status: '',
      deny: false,
      perPage: 50,
      pageNo: 0,
      size: 50,
      searchedText: '',
    })

  // throttleHandleChange = debounce(throttleHandleChange.bind(this), 500);
  // }

  const handleDetail = (id) => {
    window.open(`/admin/buyers/details/${id}`, "_blank");
  }

  const handleSub = (data) => {
    setState(prevState => ({
      ...prevState,
      delete: true,
      info: data
    }));
  }

  const handledClick = (key, name) => {
    props.getUserApprove({ user_id: key, user_type: 'buyer' });
    setState(prevState => ({
      ...prevState,
      loader: true
    }));
    const t = setTimeout(() => {
      setState(prevState => ({
        ...prevState,
        loader: false
      }));
    }, 3000);

  }
  const handleDeny = (data) => {
    setState(prevState => ({
      ...prevState,
      deny: true,
      info: data
    }));

  }
  const handleDenied = () => {
    props.getChangeUserStatus({ id: state.info, status: 'denied', type: 'buyer' })
    setState(prevState => ({
      ...prevState,
      deny: false,
    }));
  }
  const handleBan = (data) => {
    props.getChangeUserStatus({ id: data._id, status: 'blocked', type: 'buyer' })
    setState(prevState => ({
      ...prevState,
      loader: true
    }));
    const t = setTimeout(() => {
      setState(prevState => ({
        ...prevState,
        loader: false
      }));
    }, 3000);
  }
  const handleSuspend = () => {
    props.getChangeUserStatus({ id: state.info._id, status: 'suspend', date: state.selectedDate, type: 'buyer' })
    setState(prevState => ({
      ...prevState,
      delete: false, loader: true
    }));

    const t = setTimeout(() => {
      setState(prevState => ({
        ...prevState,
        loader: false
      }));

    }, 3000);
  }
  const handleDateChange = (e, key) => {
    console.log(e.target.value, "56566565656565656565")
    setState(prevState => ({
      ...prevState,
      [key]: e.target.value
    }));

  }
  const handleRequestClose = () => {
    setState(prevState => ({
      ...prevState,
      delete: false,
      deny: false
    }));

  }
  const handleActivate = (data) => {
    props.getChangeUserStatus({ id: data._id, status: 'active', type: 'buyer' })
  }

  useEffect(() => {
    let data = {
      pageNo: 1,
      size: 50,
      filter: { user_type: 'buyer', user_status: { '$ne': 'denied' } },
      name: state.searchedText ? state.searchedText : ''
    }
    props.getBuyerSellerListAdmin({ data })
  }, [])

  const applyFilter1 = (filter) => {
    let data = {
      pageNo: 1,
      size: state.size,
      searchText: state.searchedText ? state.searchedText : '',
      filter: !state.status ? { user_type: 'buyer', user_status: { '$ne': 'denied' } } : { user_type: 'buyer', user_status: state.status },
      name: state.searchedText ? state.searchedText : ''

    }
    props.getBuyerSellerListAdmin({ data });
    setState(prevState => ({
      ...prevState,
      pageNo: 0
    }));
  }
  const resetFilter = () => {
    let data = {
      pageNo: 1,
      size: 50,
      filter: !state.status === '' ? '' : { user_type: 'buyer', user_status: { '$ne': 'denied' } },
      name: state.searchedText ? state.searchedText : ''
    }
    props.getBuyerSellerListAdmin({ data, history: props.history })
    setState(prevState => ({
      ...prevState,
      filter: '',
      showFilter: false,
      status: '',
      pageNo: 0
    }));
  }
  const handleChanged = (e, key) => {
    setState(prevState => ({
      ...prevState,
      [key]: e.target.value
    }));
  }
  const changePage = (pageNo) => {
    let pages = pageNo + 1;

    let data = {
      pageNo: pages,
      size: state.size,
      searchText: state.searchedText ? state.searchedText : '',
      filter: !state.status ? { user_type: 'buyer', user_status: { '$ne': 'denied' } } : { user_type: 'buyer', user_status: state.status },
      name: state.searchedText ? state.searchedText : ''
    }
    props.getBuyerSellerListAdmin({ data, history: props.history })
    setState(prevState => ({
      ...prevState,
      pageNo,
      loader: true
    }));
    const t = setTimeout(() => {
      setState(prevState => ({
        ...prevState,
        loader: false
      }));
    }, 2000);
  };

  function throttleHandleChange(edata) {
    props.getBuyerSellerListAdmin({ data: edata })
  }
  const handleSearch = (searchText) => {
    let data = {
      pageNo: 1,
      size: state.size,
      filter: !state.status ? { user_type: 'buyer', user_status: { '$ne': 'denied' } } : { user_type: 'buyer', user_status: state.status },
      name: searchText
    }

    throttleHandleChange(data)
    setState({ searchedText: searchText, pageNo: 0 })
  };
  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val == key)
    return index > -1 ? true : false
  }
  const actions = (dataOne) => {
    return (

      (dataOne.user_status == "inactive" || dataOne.user_status == "hold") ?

        <ButtonGroup color="primary" aria-label="outlined primary button group">
          {isButtonAvailable('approve') && <Button className={'text-success'} onClick={() => handledClick(dataOne._id)} > Allow</Button>}
          {isButtonAvailable('changeUserStatus') && <Button className={'text-danger'} onClick={() => handleDeny(dataOne._id)}> Deny</Button>}
          <Button onClick={(e) => handleDetail(dataOne._id)} className={'MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary text-primary MuiButton-outlinedPrimary'} >
            Details
          </Button>
        </ButtonGroup>
        :
        (dataOne.user_status == "suspend" || dataOne.user_status == "blocked") ?
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            {isButtonAvailable('changeUserStatus') && <Button className={'text-success'} onClick={() => handleActivate(dataOne)}>
              Activate
            </Button>}
          </ButtonGroup>
          :
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            {isButtonAvailable('changeUserStatus') && <Button className={'text-danger'} onClick={() => handleBan(dataOne)}>
              Ban
            </Button>}
            {isButtonAvailable('changeUserStatus') && <Button className={'text-warning'} onClick={(e) => handleSub(dataOne)}>
              Suspend
            </Button>}
            <Button className={'text-primary'} onClick={() => handleDetail(dataOne._id)} >
              Details
            </Button>
          </ButtonGroup>
    )
  }

  const { buyerSellerList, userApprove, changeUserStatus, status } = props;
  let { open, selectedDate } = state;

  let options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    responsive: 'scroll',
    search: true,
    rowsPerPage: 50,
    page: state.pageNo,
    count: props.buyerSellerList && props.buyerSellerList.users && props.buyerSellerList.users.length >= 0 && props.buyerSellerList.users[0] && props.buyerSellerList.users[0].metadata && props.buyerSellerList.users[0].metadata.length >= 0 ?
      props.buyerSellerList.users[0].metadata[0] && props.buyerSellerList.users[0].metadata[0].total : 0,
    rowsPerPageOptions: [],
    viewColumns: false,
    rowHover: false,
    filter: true,
    serverSide: true,
    server: true,
    searchText: state.searchedText,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div>
          <div style={{ paddingTop: 15, display: 'flex' }} >
            <Button variant="contained" onClick={() => { applyFilter1(); applyNewFilters() }} className='filterButton' color='primary'>Apply Filter</Button>
            <Button variant="contained" onClick={() => { resetFilter(); applyNewFilters() }} className='filterButton' color='primary'>Reset Filter</Button>
          </div>
        </div>
      )
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText)
            }
          }
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "contact",
      label: "Contact No.",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "email",
      label: "Email ID",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: true,
        filterType: 'custom',
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        },
        customFilterListRender: v => {
          return false;
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, handleCustomChange, applyFilter, index, column) => (
            <React.Fragment>
              <Row form style={{ maxWidth: 300 }}>
                <Col md={12} xl={12} xs={12} lg={12}>
                  <FormControl className="w-100 mb-2 categoryFilter">
                    <Label for="Status">Status</Label>
                    <Input name="status" id="status" type="select"
                      value={state.status}
                      onChange={(e) => handleChanged(e, 'status')}
                      input={<Input disableUnderline={true} className="form-control" id="Status" />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 200
                          },
                        },
                      }}
                    >
                      <option value="">
                        All
                      </option>
                      <option value='active'>Active </option>
                      <option value='blocked'>Blocked </option>
                      <option value='hold'>Hold </option>
                      <option value='inactive'>Inactive </option>
                      <option value='pending'>Pending </option>
                      <option value='suspend'>Suspend </option>
                    </Input>
                  </FormControl>
                </Col>
              </Row>
              {/* <div style={{ paddingTop: 15 }} >
                <Button variant="contained" onClick={() => applyFilter1()} className='filterButton' color='primary'>Apply Filter</Button>
                <Button variant="contained" onClick={() => resetFilter()} className='filterButton' color='primary'>Reset Filter</Button>
              </div> */}
            </React.Fragment>
          ),
          onFilterChange: () => {

          }
        },
      },
    }
  ];

  let data = []
  {
    buyerSellerList && buyerSellerList.users && buyerSellerList.users.length > 0 && buyerSellerList.users && buyerSellerList.users[0] && buyerSellerList.users[0].data.map((dataOne) => {
      data.push({
        id: <Tooltip className="d-inline-block" id="tooltip-right"
          title={
            <span className="text-white" style={{ marginTop: "13px" }}>
              {dataOne && dataOne.otp}
            </span>
          }
          placement="left">
          <span>{dataOne.buyerId ? dataOne.buyerId : ''}</span></Tooltip>,
        name: dataOne.company_name ? dataOne.company_name : '',
        email: <Tooltip className="d-inline-block" id="tooltip-right"
          title={
            <span className="text-white" style={{ marginTop: "13px" }}>
              {dataOne && dataOne.isVerifiedEmail == true ? 'Verified' : 'Not verified'}
            </span>
          }
          placement="top">
          <span>{dataOne.email}</span></Tooltip>,
        contact: <Tooltip className="d-inline-block" id="tooltip-right"
          title={
            <span className="text-white" style={{ marginTop: "13px" }}>
              {dataOne && dataOne.isVerifiedPhone == true ? 'Verified' : 'Not verified'}
            </span>
          }
          placement="top">
          <span>{dataOne.phone}</span></Tooltip>,
        address: dataOne.user_address,
        action: actions(dataOne)

      })
    })
  }

  const { loader } = state;
  const theme = createTheme({
    components: {
      MUIDataTableFilter: {
        styleOverrides: {
          reset: {
            display: "none"
          }
        }
      }
    }
  });
  return (
    <React.Fragment>
      <AdminContainer>
        <BuyerHeaders match={props.match} title={<IntlMessages id="Buyers" />} />
        {props.loading ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}

        {
          props.loading == true ?
            null :
            <ThemeProvider theme={theme}>
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
        }
        <Dialog open={state.deny} onClose={handleRequestClose} fullWidth={true}
          maxWidth={'sm'}>
          <DialogTitle>
            Deny Buyer
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {'Are you sure you want to deny this user ?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color='secondary' >
              Cancel
            </Button>
            <Button color='primary' onClick={handleDenied} >
              Deny
            </Button>
          </DialogActions>
        </Dialog>

        {/* ------------------------------------------------------------------------- */}
        <Dialog open={state.delete}
          onClose={handleRequestClose}
          fullWidth={true}
          maxWidth={'sm'}>
          <DialogTitle>
            {state.delete ? 'Suspend Buyer' : ''}
            {state.delete ? <DialogContentText className='mt-3'>
              Are you sure, you want to suspend this buyer ?
            </DialogContentText> : ''}
          </DialogTitle>
          <DialogContent>
            <form noValidate autoComplete="off">
              {state.delete ?
                <FormGroup>
                  <Label for="selectedDate">Date</Label>
                  <Input type="date" name="selectedDate" min={moment().format("YYYY-MM-DD")} value={selectedDate} id="selectedDate" onChange={(e) => handleDateChange(e, 'selectedDate')} />
                </FormGroup>
                : ''
              }
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color='secondary' >
              Cancel
            </Button>
            <Button onClick={handleSuspend} color='primary'>
              {state.delete ? 'Suspend' : ''}
            </Button>
          </DialogActions>
        </Dialog>
      </AdminContainer>

    </React.Fragment>
  );
}



const mapStateToProps = ({ admin }) => {
  const { buyerSellerList, userApprove, changeUserStatus, actionList, loading } = admin;
  return { buyerSellerList, userApprove, changeUserStatus, actionList, loading }
};

export default connect(mapStateToProps, { getBuyerSellerList, getBuyerSellerListAdmin, getUserApprove, getChangeUserStatus })(BuyerList);