import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import { Input } from 'reactstrap';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { NotificationManager } from 'react-notifications';
import SearchBar from "./searchbar";
import axios from "axios";
import AppConfig from "constants/config";
import AdminContainer from "containers/AdminContainer";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "../components/PaginationCount";

const months = [
  'January', 'February', 'March', 'April',
  'May', 'June', 'July', 'August',
  'September', 'October', 'November', 'December'
];

const SellerSalesReport = (props) => {
  const [loader, setLoader] = useState(false)
  const [performance, setPerformance] = useState('')
  const [allPerformance, setAllPerformance] = useState('')
  const [count, setCount] = useState(0)
  const [searchedText, setSearchedText] = useState('')
  const [filterData, setFilterData] = useState('')
  const [seller, setSeller] = useState('')
  const [columns1, setColumns1] = useState([])

  const getSellerSalesReport = async () => {
    let data = { seller_id: seller }
    setLoader(true)
    await axios.post(`${AppConfig.baseUrl}reports/getSalesReport`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
        NotificationManager.error('Something went wrong, Please try again');
        setLoader(false)
      } else {
        let Salesdata = []
        result.data.data && result.data.data.map((dataOne, i) => {
          let obj = { Product: dataOne._id }
          dataOne.data.map((monthVal) => {
            let a = months[monthVal.month - 1]
            obj[a] = (monthVal.total ? monthVal.total.toFixed(2) : 0)
          })
          Salesdata.push(obj)
        })
        setPerformance(Salesdata)
        setAllPerformance(Salesdata)
        setLoader(false)
        setCount(result.data.data.length)
      }
    }).catch(error => {
      NotificationManager.error('Something went wrong, Please try again')
      setLoader(false)
    });
  }

  const getSellerListing = async () => {
    await axios({
      method: 'get',
      url: `${AppConfig.baseUrl}inventory/get_filters`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      }
    }).then(result => {
      if (result.data.error) {
      } else {
        setFilterData(result.data.detail.sellers)
      }
    }).catch(error => {
      console.log("error catch", error)
    });
  }

  useEffect(() => {
    getSellerListing()
    const nextSixMonths = getNextSixMonths();
    setColumns1(nextSixMonths)
  }, [])

  const getNextSixMonths = () => {
    const today = new Date();
    const months = [];
    for (let i = 5; i >= 0; i--) {
      const nextMonth = new Date(today.getFullYear(), today.getMonth() - i - 1, 1);
      months.push({
        name: nextMonth.toLocaleString('default', { month: 'long' }),
      });
    }
    months.push('Product')
    return months.reverse();
  }

  const applyFilter = async (filter) => {
    getSellerSalesReport();
  }

  const handleSearch = async (searchText) => {
    setSearchedText(searchText)
    const allData = [...allPerformance]
    if (searchText) {
      const filteredData = allData.filter(v => new RegExp(searchText, 'ig').test(v.Product))
      setPerformance(filteredData)
    } else {
      setPerformance(allPerformance)
    }
  }

  const handleChanged = (e, key) => {
    setSeller(e.target.value);
  }

  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: false,
    viewColumns: false,
    rowHover: false,
    filter: false,
    page: 0,
    rowsPerPage: count ? count : 200,
    rowsPerPageOptions: [],
    count: count,
    fixedHeader: false,
    sort: false,
    serverSide: true,
    searchText: '',
    textLabels: {
      filter: {
        all: "",
        title: "FILTERS",
      },
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  }

  return (
    <AdminContainer>
      <div className={'title'}>
        Seller Sales Report
      </div>
      <div style={{ padding: '0px 1px 0 1px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}>
        {loader ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}
        {
          loader ?
            null :
            <MUIDataTable
              title={
                <div className="header">
                  <div className="Row-header d-flex w-100" style={{ marginTop: '-9px' }}>
                    <div style={{ marginRight: '20px', marginTop: '9px' }}>
                      <SearchBar handleSearch={handleSearch} searchText={searchedText} placeholder="Search" />
                    </div>
                    <div style={{ marginRight: '20px', marginTop: '9px', width: window.innerWidth > 800 ? '40%' : '96%' }}>
                      <FormControl className="mb-2" >
                        <Input style={{ height: '45px' }} type="select" className="form-control" disableUnderline={true} name="seller" id="seller"
                          value={seller}
                          onChange={handleChanged}
                        >
                          <option selected={seller === ''} value={''}>Select Seller</option>
                          {
                            filterData && filterData.map((val) => {
                              return (<option selected={val._id === seller} value={val._id}>{val.company_name}</option>)
                            })
                          }
                        </Input>
                      </FormControl>
                    </div>
                    <div style={{ marginTop: '9px' }}>
                      <Button style={{ paddingTop: '9px', paddingBottom: '9px' }} onClick={() => applyFilter()} variant="contained" className='filterButton' color='primary'>Generate</Button>
                    </div>
                  </div>
                </div>
              }
              data={performance ? performance : []}
              columns={columns1}
              options={options}
              style={{ borderRadius: '0px !important' }}
            />
        }
      </div>
    </AdminContainer>
  );
}

export default SellerSalesReport;
