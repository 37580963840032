import React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { changeTab } from '../../../../../../actions/tabAction';
import { connect } from 'react-redux';
import BulkList from '../../../../bulkRequests/routes/BulkList';
import BulkListHistory from '../../../../bulkRequests/routes/BulkListHistory';
import { useNavigate } from "react-router-dom"

function TabContainer({ children, value, tabValue }) {
  return (
    (value === tabValue) && <div>
      {children}
    </div>
  );
}

const FullWidthTabsForBulk = (props) => {
  const navigate = useNavigate()

  const handleChange = (event, value) => {
    props.changeTab(value)
    navigate(`/admin/bulkrequests`, { replace: true })
  };

  const { tabValue, tabFor } = props;
  return (
    <div className="w-100" >
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="on"
        >
          <Tab className="tab" label="Open" />
          <Tab className="tab" label="History" />
        </Tabs>
      </AppBar>
      <TabContainer value={0} tabValue={tabValue}>
        <BulkList tab={tabValue} />
      </TabContainer>
      <TabContainer value={1} tabValue={tabValue}>
        <BulkListHistory tab={tabValue} />
      </TabContainer>
    </div>
  );
}

const mapStateToProps = ({ tabAction }) => {
  const { tabValue } = tabAction;
  return { tabValue }
};

export default connect(mapStateToProps, { changeTab })(FullWidthTabsForBulk);