import React, { Component, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppConfig from 'constants/config'
import './index.css'
import { Col, Row, FormGroup, Label, Input, } from 'reactstrap';
import helperFunction from 'constants/helperFunction';
import { connect } from 'react-redux';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useParams } from "react-router-dom";

const monthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
let years = helperFunction.yearList();
let date = new Date();

function SettlementDetailHead(props) {
  const { id } = useParams()
  const [state, setState] = useState({
    open: false,
    month: monthArray[date.getMonth()],
    year: date.getFullYear()
  })


  const handleRequestClose = () => {
    setState((prev) => ({ ...prev, open: false }));
  };

  const Submit = () => {
    window.open(`${AppConfig.baseUrl}groupSettlements/getSettledCsv?token=${localStorage.getItem('admin_token')}&sellerId=${id}&month=${state.month}&year=${state.year} `, '_blank');
    setState((prev) => ({ ...prev, open: false }));
  }

  const handleChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }))
  }

  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val == key)
    return index > -1 ? true : false
  }

  const { data1, sellerData } = props;
  let { month, year, } = state;
  let adjustmentAmt = sellerData && sellerData.reduce((acc, current) => acc + (current.sellerAdjustmentAmt ? Number(current.sellerAdjustmentAmt) : 0), 0);
  console.log('asoamsfoiamsf', sellerData)
  return (
    <div className="page-heading d-sm-flex  justify-content-sm-between align-items-sm-center"
      style={{ position: 'relative' }}>
      <h2 className="title mb-3 mb-sm-0">Settlement <ChevronRightIcon /> {data1 && data1.seller} </h2>
      <React.Fragment>
        {
          isButtonAvailable('settledCSVReport') && !isButtonAvailable('viewOnlySettlements') &&
          <Col style={{ textAlign: 'end' }}>
            <Button variant="outlined" className={'text-success'} style={{ padding: '2px 15px' }}
              onClick={(e) => setState((prev) => ({ ...prev, open: true }))}>
              Csv Report
            </Button>
          </Col>
        }
      </React.Fragment>

      <Dialog open={state.open} onClose={(e) => handleRequestClose()} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle className='pb-0'>
          CSV Report - {data1 && data1.seller}
        </DialogTitle>
        <DialogContent>
          <Row>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="sellerName">Month</Label>
                <Input type="select" name="month" id="month"
                  value={month}
                  onChange={(e) => handleChange(e, 'month')}>
                  <option selected={month == ''} value={''}>Select Month</option>
                  {monthArray.map((data) => {
                    return (<option selected={data == month} value={data}>{data}</option>)
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col xs={12} md={12} sm={12} xl={6} lg={6}>
              <FormGroup>
                <Label for="orderDate">Year</Label>
                <Input type="select" name="year"
                  value={year}
                  id="year" onChange={(e) => handleChange(e, 'year')} >
                  <option selected={year == ''} value={''}>Select Year</option>
                  {years.map((data) => {
                    return (<option selected={data == year} value={data}>{data}</option>)
                  })}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <DialogActions className='pt-0'>

            <Button onClick={(e) => handleRequestClose()} color='secondary' >
              Close
            </Button>
            <Button onClick={(e) => Submit()} color='primary' >
              Download
            </Button>

          </DialogActions>

        </DialogContent>
      </Dialog>
    </div >
  )
}

const mapStateToProps = ({ admin }) => {
  const { actionList } = admin

  return { actionList }
}

export default connect(mapStateToProps)(SettlementDetailHead)

