import React, {useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import './index.css'

function Drop(props) {
  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: props.accept
  });
  const remove = (index) => {
    acceptedFiles.splice(index,1)
    props.handleFiles(acceptedFiles)
  }
  const files = acceptedFiles.map((file, index) => {
    return <li key={file.path}> 
      <IconButton className='clear mr-2'><ClearIcon onClick={(e) => remove(index)}/></IconButton>{file.path} - {file.size} bytes
    </li>
  });
  
  useEffect(()=>{
    props.handleFiles(acceptedFiles)
  },[acceptedFiles])

  return (
    <div className="dropzone-card">
      <div className="dropzone" onClick={open}>
        <div {...getRootProps({className: 'dropzone-file-btn drop'})}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here</p>
        </div>
      </div>
      
    </div>
  );
}

export default Drop;