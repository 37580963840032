import React, { useState } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import  { NavLink, useLocation } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

const getDisplayString = (sub) => {
  const arr = sub.split("-");
  if (arr.length > 1) {
    return arr[0].charAt(0).toUpperCase() + arr[0].slice(1) + " " + arr[1].charAt(0).toUpperCase() + arr[1].slice(1)
  } else {
    return sub.charAt(0).toUpperCase() + sub.slice(1)
  }

};
const getUrlString = (path, sub, index) => {
  if (index === 0) {
    return '#/';
  } else {
    return '#/' + path.split(sub)[0] + sub;
  }
};

const ContainerHeader = ({ title, title1, setDialogOpen}) => {
  // const path = match.path.substr(1);
const location = useLocation()
  const path =  location.pathname
  const isMobile = window.innerWidth <= 508;
  
  const subPath = path.split('/');
  return (
    <div className="page-heading d-flex justify-content-between align-items-sm-center">
     <Tooltip className='d-inline-block' id='tooltip-right' placement='left' title={
                      <h6 className="text-white"
                        // style={{ marginTop: '80%' }}
                        >
                        {title}
                      </h6>
                    }> 
                    <h2 className="title mb-3 mb-sm-0"style={{overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%'}} >{title}</h2>
    </Tooltip>
    { title1=="selleronly" && <a href='https://medimny.com/signup' target="_blank">
    <Button variant="outlined" className={'text-primary'}  >
               Add Seller
             </Button>
       </a>}
       {!path.includes('/admin/sellers') &&
      <Breadcrumb className="mb-0" tag="nav">
        {subPath.map((sub, index) => {
          
          if (sub === 'dashboard')
            return <NavLink to="/seller/ratings">
              <Avatar style={{backgroundColor:"#4caf50"}}>
                <i className="zmdi zmdi-thumb-up zmdi-hc-fw rating text-white" /></Avatar>
              {/* <div className="ratingIcon" >
                <i className="zmdi zmdi-thumb-up zmdi-hc-fw rating" />
              </div> */}
            </NavLink>
          /* return <BreadcrumbItem active={subPath.length === index + 1}
                                 tag={subPath.length === index + 1 ? "span" : "a"} key={index}
                                 href={getUrlString(path, sub, index)}>{getDisplayString(sub)}</BreadcrumbItem> */
        }
        )}
      </Breadcrumb>}
      {path.includes('/admin/promotions') &&
       (<Button variant="outlined" className={'text-primary'} onClick={() => setDialogOpen(true)}  >
               Add Marquee
        </Button>)}
    </div>
  )
};


export default ContainerHeader;

