import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getGlobalCod, getChangeGlobalCod } from 'actions/admin';

import './index.css'

class BuyerHeader extends Component {

	render() {

		return (
			<div className="page-heading buyerDetailsHeader d-sm-flex justify-content-sm-between align-items-sm-center">
				<h2 className="title mb-3 mb-sm-0">Buyer </h2>	
			</div>
		)
	}
};

const mapStateToProps = ({ admin }) => {
  const { globalCod, changeCod } = admin;
  return { globalCod, changeCod }
}
export default connect(mapStateToProps,{ getGlobalCod, getChangeGlobalCod })(BuyerHeader);