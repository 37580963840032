

import React, { Component, useState, useEffect } from "react";
import EditInventory from '../component/editInventory';
import DeleteInventory from '../component/deleteInventory';
import { getInventoryList } from 'actions/admin'
import axios from 'axios'
import AppConfig from 'constants/config'
import { connect } from 'react-redux';
import customHead from 'constants/customHead'
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ContainerHeader from '../../../../components/ContainerHeader';
import MUIDataTable from "mui-datatables";

// import MUIDataTable from "components/DataTable"
import Input from '@mui/material/Input';
import { Col, Row, Form, Badge, Label } from 'reactstrap';

// import Avatar from '@mui/material/Avatar';
// import buyerImage from 'app/sellers/buyerImage';
// import InputLabel from '@mui/material/InputLabel';

import './index.css'

import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import AdminContainer from "containers/AdminContainer";
import { useLocation, useNavigate } from "react-router";
import Pagination from '@mui/material/Pagination';
import PaginationCount from "app/routes/components/PaginationCount";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const Inventory = (props) => {
  const history = useNavigate()
  let url = window.location.search
  let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''
  const [state, setState] = useState({
    edit: false,
    hide: false,
    delete: false,
    add: false,
    perPage: 50,
    editData: '',
    Inventory_id: '',
    filterData: '',
    categoriesData: '',
    loader: false,
    filterComp: []
  });
  const [loader, setLoader] = useState(false)
  const [filter, setFilter] = useState(json ? (json.filterI ? json.filterI : 'none') : 'none');
  const [searchedText, setSearchedText] = useState(json ? (json.searchI ? json.searchI : '') : '');
  const [perPage, setPerPage] = useState(50);
  const [visible, setVisible] = useState(json ? (json.visibleI ? json.visibleI : 'none') : 'none');
  const [seller, setSeller] = useState(json ? (json.sellerId ? json.sellerId : 'none') : 'none');
  const [company, setCompany] = useState(json ? (json.companyId ? json.companyId : 'none') : 'none');
  const [selectedCategoryy, setSelectedCategory] = useState(
    json ? (json.selectedCategoryI ? json.selectedCategoryI : ['Category']) : ['Category']
  )
  const [page1, setPage] = useState(0)
  const [showFilter, setShowFilter] = useState(false)

  useEffect(() => {
    let Obj = {
      selectedCategory: json ? json.selectedCategoryI ? json.selectedCategoryI : ['Category'] : ['Category'],
      filter: json ? json.filterI ? json.filterI : 'none' : 'none',
      page: json ? json.pageI ? Number(json.pageI) : 0 : 0,
      searchedText: json ? json.searchI ? json.searchI : '' : '',
      visible: json ? json.visibleI ? json.visibleI : 'none' : 'none',
      seller: json ? json.sellerId ? json.sellerId : 'none' : 'none',
      company: json ? json.companyId ? json.companyId : 'none' : 'none',
      perPage: 50,
    }
    setShowFilter(false)
    setFilter(Obj.filter)
    setVisible(Obj.visible)
    setSeller(Obj.seller)
    setCompany(Obj.company)
    setPerPage(50)
    setSearchedText(Obj.searchedText)
    setPage(Obj.page)
    setSelectedCategory(Obj.selectedCategory)
    fetchData(Obj);
  }, [window.location.search])

  const handleClick = (key, data) => {
    setState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
      editData: data,
      Inventory_id: data?._id,
    }));
  };

  const button = (data) => {
    let disabled = (data.expiry_status === 'Delist' || data.expiry_status === 'Expired') ? true : false;

    return (
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button className={disabled ? 'text-grey' : 'text-primary'} disabled={disabled} onClick={() => handleClick('edit', data)}>
          {isButtonAvailable('editInventoryItem') ? 'Edit' : 'View'}
        </Button>
        {isButtonAvailable('hideInventoryItem') && <Button className={disabled ? 'text-grey' : 'text-warning'} disabled={disabled} onClick={() => handleClick('hide', data)}>
          {data.isHidden ? 'Unhide' : 'Hide'}
        </Button>}
        {isButtonAvailable('hideInventoryItem') && <Button className={disabled ? 'text-grey' : 'text-danger'} disabled={disabled} onClick={() => handleClick('delete', data)}>
          Delete
        </Button>}
      </ButtonGroup>
    );
  };


  const handleSelectChange = (e) => {
    e.preventDefault();
    let tempSelectedCat = e.target.value;
    let index = tempSelectedCat && tempSelectedCat.length > 0 ? tempSelectedCat.findIndex((e) => e === 'Category') > -1 ? tempSelectedCat.findIndex((e) => e === 'Category') : -1 : -1;
    index > -1 && tempSelectedCat.splice(index, 1);
    setSelectedCategory(tempSelectedCat)

  }

  const handleChanged = (e, setKey) => {
    setKey(e.target.value)
  }

  const handleFiltersChange = (e) => {
    e.preventDefault();
    setFilter(e.target.value)
  }

  async function fetchData(obj) {
    if (props.location && props.location.title) {
      let filter = '';

      switch (props.location.title) {
        case 'activeSelling':
          filter = 'Active Selling Products';
          break;
        case 'shortExpiry':
          filter = 'Short expire products';
          break;
        case 'outOfStock':
          filter = 'Out of stock Products';
          break;
        case 'slowMoving':
          filter = 'Slow moving Products';
          break;
        default:
          filter = 'none';
      }
      setFilter(filter)

      if (props.location.id && props.location.id !== '') {
        setSeller(props.location.id)
      }

      if (props.location.compId && props.location.compId !== '') {
        setCompany(props.location.compId)
      }
    }

    let dataForCat = obj.selectedCategory;
    let dat = dataForCat.length > 0 && dataForCat[0] !== "Category" ? dataForCat.split(',') : [];

    let data = {
      page: obj.page === 0 ? 1 : obj.page,
      perPage: 50,
      filter: obj.filter,
      searchText: obj.searchedText,
      selectedCategory: dat,
      visible: obj.visible === 'visible' ? false : obj.visible === 'hidden' ? true : 'none',
      sellerId: (obj.seller && obj.seller !== 'none') ? obj.seller : '',
      companyId: (obj.company && obj.company !== 'none') ? obj.company : ''
    };

    setSelectedCategory(dat)
    props.getInventoryList({ data, history: props.history });

    try {
      const result = await axios({
        method: 'get',
        url: `${AppConfig.baseUrl}inventory/get_filters`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('admin_token')
        }
      });

      if (result.data.error) {
        console.log("error", result.data);
      } else {
        setState((prev) => ({
          ...prev,
          filterData: result.data.detail.sellers,
          filterComp: result.data.detail.comapnies,
          categoriesData: result.data.detail.categories
        }));
      }
    } catch (error) {
      console.log("error catch", error);
    }
  };

  async function fetchApi(obj) {
    if (props.location && props.location.title) {
      let filter = '';

      switch (props.location.title) {
        case 'activeSelling':
          filter = 'Active Selling Products';
          break;
        case 'shortExpiry':
          filter = 'Short expire products';
          break;
        case 'outOfStock':
          filter = 'Out of stock Products';
          break;
        case 'slowMoving':
          filter = 'Slow moving Products';
          break;
        default:
          filter = 'none';
      }
      setFilter(filter)

      if (props.location.id && props.location.id !== '') {
        setSeller(props.location.id)
      }

      if (props.location.compId && props.location.compId !== '') {
        setCompany(props.location.compId)
      }
    }

    let dataForCat = selectedCategoryy;
    let dat = dataForCat.length > 0 && dataForCat[0] !== "Category" ? dataForCat.split(',') : [];

    let data = {
      page: page1 === 0 ? 1 : page1,
      perPage: 50,
      filter: filter,
      searchText: searchedText,
      selectedCategory: dat,
      visible: visible === 'visible' ? false : visible === 'hidden' ? true : 'none',
      sellerId: (seller && seller !== 'none') ? seller : '',
      companyId: (company && company !== 'none') ? company : '',
    };

    setSelectedCategory(dat)
    props.getInventoryList({ data, history: props.history });

    try {
      const result = await axios({
        method: 'get',
        url: `${AppConfig.baseUrl}inventory/get_filters`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('admin_token')
        }
      });

      if (result.data.error) {
        console.log("error", result.data);
      } else {
        setState((prev) => ({
          ...prev,
          filterData: result.data.detail.sellers,
          filterComp: result.data.detail.comapnies,
          categoriesData: result.data.detail.categories
        }));
      }
    } catch (error) {
      console.log("error catch", error);
    }
  };

  const changePage = (page) => {
    let tempSelectedCat = [...selectedCategoryy];
    let index = tempSelectedCat && tempSelectedCat.length > 0 ? tempSelectedCat.findIndex((e) => e === 'Category') > -1 ? tempSelectedCat.findIndex((e) => e === 'Category') : -1 : -1;
    index > -1 && tempSelectedCat.splice(index, 1);

    let obj = {
      pageI: page,
      searchI: searchedText,
      filterI: filter ? filter : '',
      visibleI: visible,
      selectedCategoryI: tempSelectedCat,
      sellerId: seller ? seller : '',
      companyId: company ? company : '',
    }
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/inventory?${url}`)
    setPage(page)
    setLoader(true)

    const t = setTimeout(() => {
      setLoader(false)
    }, 2000);
  };


  const handleSearch = async (searchText) => {
    let searchT = searchText ? searchText.replace(/"/g, '') : ''
    setSearchedText(searchT)

    let obj = {
      pageI: 0,
      searchI: searchT,
      filterI: filter ? filter : '',
      visibleI: visible,
      selectedCategoryI: selectedCategoryy,
      sellerId: seller ? seller : '',
      companyId: company ? company : ''
    };

    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/inventory?${url}`);
  };

  const resetFilter = () => {
    let obj = {
      pageI: 0,
      searchI: searchedText,
      filterI: '',
      visibleI: '',
      selectedCategoryI: '',
      sellerId: '',
      companyId: ''
    };
    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/inventory?${url}`);
    setSelectedCategory(['Category'])
    setShowFilter(false)
    setFilter('none')
    setSeller('none')
    setCompany('none')
    setVisible('none')
  };

  const applyFilter = () => {
    let tempSelectedCat = [selectedCategoryy];
    let index = tempSelectedCat && tempSelectedCat.length > 0 ? tempSelectedCat.findIndex((e) => e === 'Category') > -1 ? tempSelectedCat.findIndex((e) => e === 'Category') : -1 : -1;

    index > -1 && tempSelectedCat.splice(index, 1);

    let obj = {
      pageI: 0,
      searchI: searchedText,
      filterI: filter ? filter : '',
      visibleI: visible,
      selectedCategoryI: selectedCategoryy,
      sellerId: seller ? seller : '',
      companyId: company ? company : ''
    };

    let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    history(`/admin/inventory?${url}`);
    setShowFilter(true)
    setLoader(true)

    const t = setTimeout(() => {
      setLoader(false)
    }, 2000);
  };

  const handleForHidden = (e, key) => {
    setVisible(e.target.value)
  };

  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val === key);
    return index > -1 ? true : false;
  };
  const callMount = () => {
    fetchApi(state)
  }

  const { listInventory } = props;
  const options = {
    searchPlaceholder: 'Press Enter to Search',
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: false,
    selectableRows: false,
    rowsPerPage: 50,
    page: page1,
    rangePage: false,
    fixedHeader: false,
    print: false,
    download: false,
    filter: true,
    search: true,
    sort: false,
    count: props.listInventory.length > 0 ? props.listInventory[0].metadata.length > 0 ? props.listInventory[0].metadata[0].total : 0 : 0,
    serverSide: true,
    server: true,
    selectableRowsOnClick: true,
    searchText: searchedText,
    rowsPerPageOptions: [],
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePage(tableState.page);
          break;
        case 'search':
          onkeypress = (e) => {
            if (e.key === 'Enter') {
              handleSearch(tableState.searchText);
            }
          };
          break;
        case 'onSearchClose':
          handleSearch();
          break;
        default:
          break;
      }
    },
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return <div className="p-4 d-flex justify-content-end">
        <PaginationCount page={page} rowsPerPage={rowsPerPage} count={count}/>
        <Pagination count={Math.ceil(count / rowsPerPage)} page={page + 1} onChange={(e, page) => { changePage(page - 1) }} color="primary" />
      </div>
    }
  };
  const statusStyle = (status) => {
    return status.includes("Out of Stock") ? "text-white bg-danger" : status.includes("In stock") ? "text-white bg-success" : status.includes("Expired") ? "text-white bg-grey" : status.includes("Active") ? "text-white bg-info" : "text-white bg-grey";
  }
  const columns = [
    {
      name: "title",
      label: "Title",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "Seller",
      label: "Seller",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "MRP",
      label: "MRP",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "PTR",
      label: "PTR",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "quantity",
      label: "Quantity",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        }
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: true,
        filterType: 'custom',
        customHeadRender: (o, updateDirection) => {
          return customHead(o, updateDirection);
        },
        customFilterListRender: v => {
          return false;
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, handleCustomChange, applyFilter1, index, column) => (
            <React.Fragment>
              <Row form style={{ maxWidth: 350 }}>
                <Col md={12} xl={12} xs={12} lg={12}>
                  <FormControl className="w-100 mb-2 categoryFilter">
                    <Label for="Filters">Filter by</Label>
                    <Select
                      value={filter}
                      onChange={(e) => handleFiltersChange(e)}
                      input={<Input disableUnderline={true} className="form-control" id="Filters" />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 200
                          },
                        },
                      }}
                    >
                      <MenuItem value="none">
                        All
                      </MenuItem>
                      <MenuItem value='Active Selling Products'>Active Selling </MenuItem>
                      <MenuItem value='Slow moving Products'>Slow Moving </MenuItem>
                      <MenuItem value='Short expire products'>Short Expire </MenuItem>
                      <MenuItem value='Out of stock Products'>Out Of Stock </MenuItem>
                      <MenuItem value='Expired'>Expired </MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col md={12} xl={12} xs={12} lg={12}>
                  <FormControl className="w-100 categoryFilter">
                    <Label for="Filters">Hidden</Label>
                    <Select
                      value={visible}
                      onChange={(e) => handleForHidden(e, 'visible')}
                      input={<Input disableUnderline={true} className="form-control" id="Filters" />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 200
                          },
                        },
                      }}
                    >
                      <MenuItem value="none">
                        All
                      </MenuItem>
                      <MenuItem value='visible'>Visible </MenuItem>
                      <MenuItem value='hidden'>Hidden </MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row form style={{ maxWidth: 350 }}>
                <Col md={12} xl={12} xs={12} lg={12}>
                  <FormControl className="w-100 categoryFilter mt-1 mb-1">
                    <Label for="category">Category</Label>
                    <Select
                      multiple
                      id="selectCategory"
                      value={selectedCategoryy}
                      onChange={(e) => handleSelectChange(e)}
                      input={<Input disableUnderline={true} className="form-control " id="name-multiple" />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 200
                          },
                        },
                      }}
                    >
                      <MenuItem
                        value='Category'
                      >
                        Select Category
                      </MenuItem>
                      {categoriesData && categoriesData.map(name => (
                        <MenuItem
                          key={name._id}
                          value={name._id}
                          className='putIconAfter '
                          style={{
                            fontWeight: selectedCategoryy.indexOf(name.name) !== -1 ? '500' : '400',
                          }}
                        >
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col md={12} xl={12} xs={12} lg={12}>
                  <FormControl className="w-100  mt-1 mb-1" >
                    <Label for="sellers">Sellers</Label>
                    <Select
                      value={seller}
                      input={<Input disableUnderline={true} className="form-control mt-1" id="seller" />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 200
                          },
                        },
                      }}
                      onChange={(e) => handleChanged(e, setSeller)}
                    // component={ReactstrapTextField}
                    >
                      <MenuItem value='none'>Select Seller</MenuItem>
                      {
                        filterData && filterData.map((val) => {
                          return (<MenuItem selected={val._id == seller} value={val._id}>{val.company_name}</MenuItem>)
                        })
                      }
                    </Select>
                  </FormControl>
                </Col>
                <Col md={12} xl={12} xs={12} lg={12}>
                  <FormControl className="w-100  mt-1 mb-1" >
                    <Label for="companies">Companies</Label>
                    <Select
                      value={company}
                      input={<Input disableUnderline={true} className="form-control mt-1" id="company" />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 200
                          },
                        },
                      }}
                      onChange={(e) => handleChanged(e, setCompany)}
                    // component={ReactstrapTextField}
                    >
                      <MenuItem value='none'>Select Company</MenuItem>
                      {/* <MenuItem key={'select Category'}
                        value={'Category'}>Select Seller</MenuItem> */}
                      {
                        state.filterComp && state.filterComp.map((val) => {
                          return (<MenuItem selected={val._id == company} value={val._id}>{val.name}</MenuItem>)
                        })
                      }
                    </Select>
                  </FormControl>
                </Col>

              </Row>
              <div style={{ paddingTop: 15 }} >
                <Button variant="contained" onClick={() => applyFilter(applyFilter1)} className='filterButton' color='primary'>Apply Filter</Button>
                <Button variant="contained" onClick={() => resetFilter(applyFilter1)} className='filterButton' color='primary'>Reset Filter</Button>
              </div>
            </React.Fragment>
          ),
          onFilterChange: () => {

          }
        },
      },
    }
  ];

  const data = listInventory.length > 0 && listInventory[0].data.length > 0
    ? listInventory[0].data.map((data1, index) => (
      [
        data1.productName ? data1.productName : 'N.A.',
        data1.Seller && data1.Seller.company_name, "₹" + (Number(data1.MRP)).toFixed(2),
        data1.Discounts ? data1.Discounts.type === 'Discount' ?
          <div>
            <span className={'originalPrice '}>₹{(Number(data1.PTR).toFixed(2))}</span>
            <span >₹{((Number(data1.PTR) - (Number(data1.PTR) / 100 * Number(data1.Discounts.discount_per)))).toFixed(2)}</span></div> :
          <div>
            <span >₹{(Number(data1.PTR)).toFixed(2)}</span>
          </div> : "₹" + (Number(data1.PTR)).toFixed(2),
        <div>{(data1.quantity < data1.min_order_quantity) ? <span className={` badge text-uppercase ${statusStyle("Out of Stock")}`}>Out of Stock</span> : data1.quantity}</div>,
        <div key={'recent'} >
          {(data1.expiry_status === 'Usable') ?
            <span className={` badge text-uppercase ${statusStyle("In stock")}`}>{data1.expiry_status}</span> :
            (data1.expiry_status === 'Short Expiry') ?
              <span className={` badge text-uppercase ${statusStyle("Out of Stock")}`}>{data1.expiry_status}</span> :
              (data1.expiry_status === 'Delist' || data1.expiry_status === 'Expired') ?
                <span className={` badge text-uppercase ${statusStyle("Expired")}`}>{data1.expiry_status}</span> : ''
          }
        </div>,
        button(data1),
      ])) : [];

  const { loading } = props;
  const { filterData, categoriesData } = state
  const catNameArr = []
  if (Array.isArray(categoriesData)) {
    for (const categoryId of selectedCategoryy) {
      const category = categoriesData.find(cat => cat._id == categoryId);
      if (category) {
        catNameArr.push(category.name);
      }
    }
  }
  return (
    <AdminContainer>
      <React.Fragment>
        <ContainerHeader title={showFilter ? (catNameArr.findIndex((e) => e === 'Category') > -1 && filter === 'none') ? "Inventory" : (catNameArr.findIndex((e) => e === 'Category') > -1 && filter !== 'none') ? `Inventory (Showing ${filter.toLowerCase()})` : (catNameArr.findIndex((e) => e === 'Category') < 0 && filter === 'none') ? `Inventory (Showing results for ${catNameArr.join(', ').toLowerCase()})` :
          (catNameArr.length == 0 && filter) ?
            `Inventory (Showing ${filter.toLowerCase()}) ` :
            `Inventory (Showing ${filter.toLowerCase()} for ${catNameArr.join(', ').toLowerCase()})` : 'Inventory'} />
        {loading ? <div className="loader-view"
          style={{ height: props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
          <CircularProgress />
        </div> : null}
        {
          loading ?
            null :
            <MUIDataTable
              data={data}
              columns={columns}
              options={options}
            />
        }

        {
          state.edit &&
          <EditInventory actionList={props.actionList} editData={state.editData} Inventory_id={state.Inventory_id} page={state.page} perPage={state.perPage} buttonType={'edit'} edit={state.edit} title={'Inventory'} handleClick={handleClick} callMount={callMount} />
        }
        {
          state.delete &&
          <DeleteInventory page={state.page} perPage={state.perPage} delete={state.delete} editData={state.editData} deleteFor={'inventory'} handleClick={handleClick} callMount={callMount} />
        }

        {
          state.hide &&
          <DeleteInventory page={state.page} perPage={state.perPage} delete={state.hide} editData={state.editData} hideFor={'inventory'} handleClick={handleClick} callMount={callMount} />
        }

      </React.Fragment>
    </AdminContainer>
  );
}

const mapStateToProps = ({ admin }) => {
  const { listInventory, productCategories, actionList, loading } = admin;
  return { listInventory, productCategories, actionList, loading }
};

export default connect(mapStateToProps, { getInventoryList })(Inventory);